
import { PaginatedCollection } from 'core/models/paginated.collection.js';
import { PopulationModel } from './population.model.js';

/**
 * Collection of populations
 */
export var PopulationColl = PaginatedCollection.extend({

  model: PopulationModel,
  url: Configuration.restRoot + "/rest/population/menu",

  initialize: function() {
    PaginatedCollection.prototype.initialize.call(this);
    this.sortings = { libelle: true };
    this.paginated = false;
  }
});