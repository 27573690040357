import { CalendaccModel } from './calendacc.model.js';
import { ReadOnlyModel } from 'core/models/readOnly.model.js';
import { SYNC } from '../../../../utils/sync.js';

/**
 * Workflow for UC Calendrier du collaborateur
 */
export var CalendaccWorkflow = ReadOnlyModel.extend({

  initialize: function(options) {
    // Initialize the constant
    this.EMPTY = new CalendaccModel();
    this.formModel = null;
    this.customBarModel = null;

    this.set("ready", false);

    //for default put the current year
    var actualDate = SYNC.getServerDate();
    this.yearDate = actualDate.getFullYear();
  },

  /**
   * Set up the workflow. Link the models between them
   */
  setUp: function(callback) {
    var self = this;


    var datedeb = this.yearDate + "0101";
    var datefin = this.yearDate + "1231";

    self.formModel.datedeb = datedeb;
    self.formModel.datefin = datefin;

    //collection fetch
    self.formModel.fetch({
      success: function(fresh) {
        var context = {
          ctxHabilitation: "",
          ctxDateSynoptique: {
            ctxDateDeb: datedeb,
            ctxDateFin: datefin
          },
          ctxDateEnEvidence: "",
          ctxCollabMatricule: "",
          ctxAfficheAnnee: false
        };

        self.form.vueannuelle.setContext({ context: context, mainModel: fresh });

        if (callback) {
          callback();
        }
      }
    });

    this.set("ready", true);
    this.formModel.on("changeYear", this._setYear, this);
  },

  _setYear: function(year) {
    var self = this;

    this.yearDate = year;
    var datedeb = this.yearDate + "0101";
    var datefin = this.yearDate + "1231";

    self.formModel.datedeb = datedeb;
    self.formModel.datefin = datefin;

    //collection fetch
    self.formModel.fetch({
      success: function(fresh) {
        var context = {
          ctxHabilitation: "",
          ctxDateSynoptique: {
            ctxDateDeb: datedeb,
            ctxDateFin: datefin
          },
          ctxDateEnEvidence: "",
          ctxCollabMatricule: "",
          ctxAfficheAnnee: false
        };

        self.form.vueannuelle.setContext({ context: context, mainModel: fresh });
      }
    });
  }

});
