import { ReadOnlyModel } from 'core/models/readOnly.model.js';

/**
 * Population model
 */
export var PopulationModel = ReadOnlyModel.extend({
  defaults: {
    "ident": null,
    "code": null,
    "libelle": "",
    "type": "D"
  },

  url: function() {
    return Configuration.restRoot + "/rest/population/" + this.id;
  },

  parse: function(response) {
    if (response.id === undefined && response.ident !== undefined) {
      this.id = response.ident;
    }
    return response;
  }
});