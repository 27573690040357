import { BaseModel } from '../../../../../core/models/base.model.js';

export var PersoBriquesDeleteModel = BaseModel.extend({

  defaults: {},

  /**
   * This method is executed when a webservice is called from this model
   */
  url: function() {
    var url = Configuration.restRoot + "/rest/personnalisation/definition/brique/rescpt/" + encodeURIComponent(this.id); //Creation
    //		if (!STR.isBlank(this.espaceid)){
    //			url+="?espaceid="+encodeURIComponent(this.espaceid);
    //		}
    return url;
  }
});