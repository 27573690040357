
import { PaginatedCollection } from 'core/models/paginated.collection.js';
import { WkfHistoModel } from './wkfHisto.model.js';

export var HistoEventModelColl = PaginatedCollection.extend({

  model: WkfHistoModel,

  url: function() {
    var newUrl = Configuration.restRoot + "/rest/wkf/histo/evenement/" + encodeURIComponent(this.ctxTypeEvenement + "," + this.ctxRefEvenement);
    return newUrl;
  },

  initialize: function() {
    PaginatedCollection.prototype.initialize.call(this);
    this.paginated = false;
  }
});