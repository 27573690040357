module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<div class="phx-populations-menu">\n  <div class="phx-menu phx-menu-hover">\n    <span class="phx-population-menu-header" tabindex="0">'+
((__t=(i18n.t("populationsmenu.tous")))==null?'':__t)+
'</span>\n    <span class="phx-population-menu-icon ui-corner-all ui-icon ui-icon-triangle-1-s"></span>\n  </div>\n  <ul class="phx-populations-menu-list ui-menu ui-corner-all">\n    <span class="generated-list-elements-container"></span>\n    ';
if(habilitation_gerer){ 
__p+='\n    <li class=\'phx-menu-link phx-hover ui-corner-all ui-menu-divider\'></li>\n    <li class=\'ui-menu-item phx-populations-menu-select-collab phx-menu-link phx-hover ui-corner-all\'>\n      <a href="javascript:void(0)" tabindex="-1" class=\'select-collab\' style=\'cursor:pointer\'>\n        '+
((__t=(i18n.t("populationsmenu.select_collab")))==null?'':__t)+
'...</a>\n    </li>\n    <span class="structure-elements-container"></span>\n    <li class=\'phx-menu-link phx-hover ui-corner-all ui-menu-divider\'></li>\n    <li class=\'ui-menu-item phx-menu-link phx-hover ui-corner-all\'>\n      <a href=\'#z/resp/uc/populations\' tabindex="-1" class=\'\'>\n        '+
((__t=(i18n.t("populationsmenu.accessPopulations")))==null?'':__t)+
'\n      </a>\n    </li>\n    <li class=\'ui-menu-item phx-menu-link phx-hover ui-corner-all create-population-opt\'>\n      <a href=\'#z/resp/uc/populations/new\' tabindex="-1" class=\'ewf\' target=\'\'>\n        '+
((__t=(i18n.t("populationsmenu.create")))==null?'':__t)+
'...</a>\n    </li>\n    ';
 } 
__p+='\n  </ul>\n</div>\n';
}
return __p;
};
