import { BaseModel } from '../../../../../../core/models/base.model.js';
import { FormView } from '../../../../../../core/views/form.view.js';
import { GererBadgeagesGestion } from '../../gererbadgeages/models/gererBadgeagesGestion.js';
import { i18n } from '../../../../../../i18n.js';
import { STR } from '../../../../../../utils/str.js';
import TPL_gereronebadgeage_popUp from '../popUp.tpl.html';

export var GererOneBadgeagePopUpView = FormView.extend({

  dummy: "",
  /**
   * Event launched when a non-readonly input changes its value
   */
  /**
   * Event launched when a non-readonly input event keyup happens
   */
  /**
   * Event launched when button is clicked
   */

  /**
   * Constructor
   * View used for gerer one badgeage
   */
  initialize: function(options) {
    this.template = TPL_gereronebadgeage_popUp;

    this.model = new BaseModel({
      value: new GererBadgeagesGestion()
    });

    if (options.parent) {
      this.parent = options.parent;
    }
    if (options.nombre) {
      this.nombre = options.nombre;
    }
    this.action = options.action;
    this.statut = options.statut;
    this.utilisateur = options.utilisateur;
  },

  /**
   * Renders this view
   */
  render: function() {
    var json = { "i18n": i18n };
    this.$el.append(this.template(json));

    // Render buttons
    $(this.el).find(".btnAnnuler").button();
    $(this.el).find(".btnOK").button();

    switch (this.action) {
      case "accepter":
        if (this.statut == "D") {
          $(this.el).find(".spanComment").html(i18n.t('common:gereronebadgeage.commentaire_AcceptDemande'));
        } else if (this.statut == "I") {
          $(this.el).find(".spanComment").html(i18n.t('common:gereronebadgeage.commentaire_AcceptCours'));
        }
        break;
      case "refuser":
        if (this.statut == "D") {
          $(this.el).find(".spanComment").html(i18n.t('common:gereronebadgeage.commentaire_RefusDemande'));
        } else if (this.statut == "I") {
          $(this.el).find(".spanComment").html(i18n.t('common:gereronebadgeage.commentaire_RefusCours'));
        }
        break;
      case "suppression":
        if (this.statut == "I") {
          $(this.el).find(".spanComment").html(i18n.t('common:gereronebadgeage.commentaire_SuppresionCours'));
        } else if (this.statut == "A") {
          $(this.el).find(".spanComment").html(i18n.t('common:gereronebadgeage.commentaire_SuppresionAccept'));
        } else {
          $(this.el).find(".spanComment").html(i18n.t('common:gereronebadgeage.commentaire_SuppresionSupp'));
        }
        break;

      case "suppressionTous":
        if (this.statut == "I") {
          $(this.el).find(".spanComment").html(i18n.t('common:gereronebadgeage.commentaire_SuppresionCours'));
        } else if (this.statut == "A") {
          $(this.el).find(".spanComment").html(i18n.t('common:gereronebadgeage.commentaire_SuppresionAccept'));
        } else {
          if (this.nombre > 1) {
            $(this.el).find(".spanComment").html(i18n.t('common:gereronebadgeage.commentaire_SuppresionSuppPlus'));
          } else {
            $(this.el).find(".spanComment").html(i18n.t('common:gereronebadgeage.commentaire_SuppresionSupp'));
          }
        }

        break;
      case "accepterTous":
        if (this.statut == "D") {
          $(this.el).find(".spanComment").html(i18n.t('common:gereronebadgeage.commentaire_AcceptDemande'));
        } else if (this.statut == "I") {
          $(this.el).find(".spanComment").html(i18n.t('common:gereronebadgeage.commentaire_AcceptCours'));
        }
        break;
      case "refuserTous":
        if (this.statut == "D") {
          $(this.el).find(".spanComment").html(i18n.t('common:gereronebadgeage.commentaire_RefusDemande'));
        } else if (this.statut == "I") {
          $(this.el).find(".spanComment").html(i18n.t('common:gereronebadgeage.commentaire_RefusCours'));
        }
        break;
    }

    if (this.utilisateur == "Responsable" && (this.action == "refuser" || this.action == "refuserTous")) {

      this.$el.find("[for=commentaire]").addClass("required");
      //this.model.get("value").off("invalid");
      //this.model.get("value").on("invalid", this._showValidationErrors, this);
    }

    return this;
  },
  /**
   * Load the internal events of the view
   */
  events: {
    "change :input:not([readonly])": "_change",
    "keyup :input:not([readonly])": "_notifyEdition",
    "click button": "_clickListener"
  },
  /**
   * Manages buttons actions
   */
  _clickListener: function(event) {
    var btnClicked = event.currentTarget.value;
    var model = this.model.get("value");

    switch (btnClicked) {
      case "btnOK":
        //validation
        var errors = {};
        if (this.action == "refuser" || this.action == "refuserTous") {
          if (STR.isBlank(model.get("commentaire"))) {
            errors.commentaire = i18n.t('common:required', { "0": i18n.t('common:gereronebadgeage.comment') });
          }
        }
        if (!_.isEmpty(errors)) {
          var errorMap = {
            errorValidation: STR.formatMessage(errors),
            errors: errors
          };
          this._showValidationErrors(this.model.get("value"), errorMap);
        } else {
          this.parent.model.set("action", "OK");
          this.parent.model.set("commentaire", model.get("commentaire"));
          this.parent.close();
        }

        break;
      case "btnAnnuler":
        this.parent.model.set("action", "annuler");
        this.parent.close();
        break;
      default:
    }
  }
});