import { BaseModel } from 'core/models/base.model.js';
import { EtatsModel } from '../models/etats.model.js';
import { FormView } from 'core/views/form.view.js';
import { i18n } from '../../../../i18n.js';
import { STR } from '../../../../utils/str.js';
import TPL_lanceetat_detailHeader from '../detailHeader.tpl.html';

export var HeaderView = FormView.extend({

  initialize: function() {
    this.template = TPL_lanceetat_detailHeader;

    this.model = new BaseModel({
      value: new EtatsModel()
    });
    this.model.on("change:value", this._mapToForm, this);
  },

  _mapToForm: function() {
    var value = this.model.get("value");
    if (!STR.isBlank(value.get("libelle"))) {
      this.$el.find(".phx-lanceetat-header-selected").html(value.get("libelle"));
    } else {
      this.$el.find(".phx-lanceetat-header-selected").html("");
    }
    this.model.trigger("resize");
  },

  render: function() {
    var json = { 'i18n': i18n };
    this.$el.append(this.template(json));

    return this;
  }
});