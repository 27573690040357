import { GLOBAL_DATA } from '../../../../../globalData.js';
import { PaginatedCollection } from '../../../../../core/models/paginated.collection.js';

/**
 * Collection for Brique 'Mes Demandes'
 */
export var MesDemandesColl = PaginatedCollection.extend({

  initialize: function() {
    this.habilitationV = "N";
    this.habilitationG = "N";
    this.usecase = "bdem";
    PaginatedCollection.prototype.initialize.call(this);
    this.pagination.size = parseInt(GLOBAL_DATA.paramDivers.get("BRCnbElt").get("valeur"));
  },

  url: function() {
    var base = Configuration.restRoot + "/rest/brique/demande";
    var params = this.ajaxRequests;
    var urlParams = "";
    if (_.keys(params).length > 0) {
      urlParams = "?" + $.param(params);
    }
    //LOG.debug("cid: "+this.cid+" "+base+urlParams);
    return base + urlParams;
  },

  fetch: function(options) {
    var options = options || {};
    var datos = {
      "ABSENCE": "COL_ABS.V",
      "REGULARISATION": "COL_REG.V",
      "BADGEAGE": "COL_BADG.V",
      "ACTRECAP": "COL_RECAP.V",
      "EVTGEN": "COL_EVTGEN.V",
      "ACTPREV": "COL_ACT_PREV.V",
      "ACTREAL": "COL_ACT_REAL.V"
    };
    options.data = JSON.stringify(datos);
    options.type = "POST";
    options.dataType = "json";
    options.contentType = 'application/json';
    options.processData = false;

    return PaginatedCollection.prototype.fetch.call(this, options);
  },

  fetchPagination: function(options) {
    var options = options || {};
    var datos = {
      "ABSENCE": "COL_ABS.V",
      "REGULARISATION": "COL_REG.V",
      "BADGEAGE": "COL_BADG.V",
      "ACTRECAP": "COL_RECAP.V",
      "ACTPREV": "COL_ACT_PREV.V",
      "ACTREAL": "COL_ACT_REAL.V"
    };
    options.data = JSON.stringify(datos);
    options.type = "POST";
    options.dataType = "json";
    options.contentType = 'application/json';
    options.processData = false;

    PaginatedCollection.prototype.fetchPagination.call(this, options);
  },

  parse: function(response) {
    response.list = response.demande;

    delete response.demande;

    return PaginatedCollection.prototype.parse.call(this, response);
  }

});