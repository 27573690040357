import { FormView } from '../../views/form.view.js';
import { i18n } from '../../../i18n.js';
import { objs } from '../../../objectsRepository.js';
import { ReadOnlyModel } from '../../models/readOnly.model.js';
import { STR } from '../../../utils/str.js';
import TPL_common_filter_layout from './filter_layout.tpl.html';

export var FilterView = Backbone.View.extend({

  dummy: "",

  /**
   * Alias for the messages to show in this view
   */
  //REVIEW: 	i18n : phx.i18n.common,

  /**
   * Name of the container class of the view
   */
  className: "phx-filter-view",

  /**
   * Constructor
   * View which has the general structure to show a set of inputs used to filter data. It needs at least one
   * child view which contains the inputs to paint
   */
  initialize: function(options) {
    var self = this;

    /**
     * HTML template of the view
     */
    this.template = TPL_common_filter_layout;

    if (!options.viewPart1) {
      throw new Error("You must define at least a view with a set of fields to use the filter");
    }

    /**
     * View to show with the single search inputs
     */
    this.viewPart1 = options.viewPart1;

    /**
     * View to show with the advance search inputs
     */
    this.viewPart2 = options.viewPart2;

    /**
     * Model of the view (mode 0 -> collapsed, mode 1 -> expanded)
     */
    this.model = new ReadOnlyModel({ mode: 0, moreCriterias: 0 });
    this.model.on("change:mode", this.filterVisualisation, this);
    this.model.on("change:moreCriterias", this.filterMoreCriteriasVisualisation, this);
    this.model.on("btn:click", this._manageClick, this);
    this.listenTo(this.model, "clean:filter", function() {
      self._manageClick("clear");
    });

    if (options && options.expandedByDefault === true) {
      this.model.set("mode", 1);
    }
  },

  /**
   * Event launched when the filter button is clicked
   *
   */
  /**
   * Event launched when the button clear is clicked
   *
   */
  /**
   * Event launched when the button search is clicked
   *
   */
  /**
   * Event launched when the button more options is clicked
   *
   */
  /**
   * Event launched when the enter key is pressed in the filter
   *
   */
  /**
   * Event launched when the filter is resized
   *
   */

  events: {
    "click .filter-view-btn": "_changeVisualisationMode",
    "click .clear": "_fireClick",
    "click .search": "_fireClick",
    "click .filter-more-btn": "_changeMoreCriteriasVisualisationMode",
    "keypress :input:not([readonly])": "_enterPress"
  },

  _enterPress: function(event) {
    if (event.keyCode === 13) {
      $(event.currentTarget).trigger("blur");
      $(this.el).find(".search").click();
    }
  },

  _getModel: function() {
    return this.model;
  },

  /**
   * Change filter model visualisation mode in order to trigger the change attributes event
   */
  _changeVisualisationMode: function() {
    if (this.model.get("mode") === 1) {
      this.model.set("mode", 0);
    } else {
      this.model.set("mode", 1);
    }
  },
  /**
   * Change filter model more criterias visualisation mode in order to trigger the change attributes event
   */
  _changeMoreCriteriasVisualisationMode: function() {
    if (this.model.get("moreCriterias") === 1) {
      this.model.set("moreCriterias", 0);
    } else {
      this.model.set("moreCriterias", 1);
    }
  },

  _fireClick: function(event) {
    var btnClicked = event.currentTarget.value;
    this.model.trigger("btn:click", btnClicked);
  },

  _manageClick: function(buttonId) {
    if (buttonId === "clear") {
      if (this.viewPart1 && this.viewPart1 instanceof FormView) {
        this.viewPart1._cleanValidationErrors();
      }
      if (this.viewPart2 && this.viewPart2 instanceof FormView) {
        this.viewPart2._cleanValidationErrors();
      }
      this.clearFilterFields();

    } else if (buttonId === "search") {
      var isValid = true;
      if (this.viewPart1 && this.viewPart1 instanceof FormView && !this.viewPart1._getModel().isValid()) {
        isValid = false;
      }

      if (this.viewPart2 && this.viewPart2 instanceof FormView && !this.viewPart2._getModel().isValid()) {
        isValid = false;
      }

      if (isValid) {
        this.triggerFilter();
      }

    }
  },

  /**
   * Update filter view in response to the change event in the filter model
   */
  filterVisualisation: function() {
    if (this.model.get("mode") === 1) { //Filter expanded
      $(this.el).find(".filter-view-btn").button({ icons: { primary: "ui-icon-zoomout" } });
      $(".filter-content", this.el).show();
    } else {
      $(this.el).find(".filter-view-btn").button({ icons: { primary: "ui-icon-zoomin" } });
      $(".filter-content", this.el).hide();
    }

    objs.appRt.workflow.trigger("resize");
    objs.appRt.workflow.trigger("dialogresize");
  },

  /**
   * Update filter more criterias view in response to the change event in the filter model
   */
  filterMoreCriteriasVisualisation: function() {
    if (this.model.get("moreCriterias") === 1) { //Filter more criterias expanded
      $(this.el).find(".filter-more-btn").html(i18n.t('common:lessfilters'));
      $(".filter-more-fields", this.el).show();
    } else {
      $(this.el).find(".filter-more-btn").html(i18n.t('common:morefilters'));
      $(".filter-more-fields", this.el).hide();
    }

    objs.appRt.workflow.trigger("resize");
  },

  /**
   * Triggers the search action to any subscribe views
   */
  triggerFilter: function() {
    var values = null;

    if (this.customMapFieldSet) {
      values = this.customMapFieldSet($(this.el).find(".filter-content"));
    } else {
      values = this._mapFieldSet($(this.el).find(".filter-content"));
      // Clean Empty Values #142458
      _.each(values, function(val, name) {
        if (STR.isBlank(val)) {
          delete values[name];
        }
      });
    }

    this.model.trigger("search", values);
  },

  render: function() {

    var json = { "i18n": i18n };
    $(this.el).html(this.template(json));

    // configure the buttons representation
    $(this.el).find(".filter-view-btn").button({ icons: { primary: "ui-icon-zoomout" }, text: false });
    $(this.el).find(".search").button({ icons: { primary: "ui-icon-check" }, text: false });
    $(this.el).find(".clear").button({ icons: { primary: "ui-icon-close" }, text: false });

    if (this.viewPart1) {
      $(this.el).find(".filter-simple-fields").html(this.viewPart1.render().el);
    }
    if (this.viewPart2) {
      $(this.el).find(".filter-more-fields").html(this.viewPart2.render().el);
    } else {
      $(this.el).find(".filter-more-btn").hide();
    }

    this.filterVisualisation();
    this.filterMoreCriteriasVisualisation();

    return this;
  },

  /**
   * Clears all the fields
   */
  clearFilterFields: function() {
    if (this.customClearFieldSetValues) {
      this.customClearFieldSetValues($(this.el).find(".filter-content"));
    } else {
      this._clearFieldSetValues($(this.el).find(".filter-content"));
    }
    this.model.trigger("clear");
  },

  /**
   * Clears all the fields of the specified fieldset
   *
   */
  _clearFieldSetValues: function(fieldset) {
    if (this.viewPart1 instanceof FormView) {
      this.viewPart1._getModel().clear();
    }
    if (this.viewPart2 instanceof FormView) {
      this.viewPart2._getModel().clear();
    }

    // used for button, checkbox, input and textarea elements
    fieldset.find(":input").each(function(index, element) {
      var domEl = $(element);
      var value = "";
      if (element.type === "select-one" || element.type === "textarea") {
        //Select case
        domEl.val(value);
        $(domEl).css("color", "");
        $(domEl).css("background-color", "");
      } else {
        //other fields case
        switch (domEl.attr("type")) {
          case "text":
            domEl.val(value);
            break;
          case "checkbox":
            domEl.val([value]);
            break;
          case "radio":
            domEl.val([value]);
            break;
          default:
            break;
        }
      }
    });
  },

  /**
   * Map the values of some fields included in a Fieldset in an array
   * The inputs must have class="model.property"
   *
   */
  _mapFieldSet: function(fieldset) {
    var values = {};

    values = this._getViewPartFieldValues(fieldset, this.viewPart1);
    if (this.viewPart2) {
      values = _.extend(values, this._getViewPartFieldValues(fieldset, this.viewPart2));
    }

    return values;
  },

  /**
   * Gets the values of some fields included in a part of the fieldset in an array
   * The inputs must have class="model.property"
   *
   */
  _getViewPartFieldValues: function(fieldset, viewPart) {
    var values = {};
    if (viewPart && viewPart instanceof FormView) {
      values = viewPart._getModel().toJSON();
    } else {
      // used for button, checkbox, input and textarea elements
      fieldset.find(":input").each(function(index, element) {
        var domEl = $(element);
        var name = domEl.attr("class").split(" ")[0];
        var value = null;

        if (element.type === "select-one" || element.type === "textarea") {
          //Select case
          if (domEl.hasClass("phx-combobox-input") && domEl.prop("data-code")) {
            value = domEl.prop("data-code");
          } else {
            value = domEl.val();
          }
          values[name] = value.trim();
        } else {
          //other fields case
          switch (domEl.attr("type")) {
            case "text":
              value = domEl.val();
              values[name] = value.trim();
              break;
            case "checkbox":
              value = domEl.is(':checked');
              values[name] = value;
              break;
            case "radio":
              if (domEl.is(':checked')) {
                value = domEl.val();
                values[name] = value;
              }
              break;
            default:
              break;
          }
        }
      });
    }

    return values;
  }

});
