import { ButtonBarView } from 'core/controls/buttonBar.view.js';
import { DataGridModel } from 'core/grids/data_grid/dataGrid.model.js';
import { DataGridView } from 'core/grids/data_grid/dataGrid.view.js';
import { DelegationsTableColl } from '../models/delegationsTable.collection.js';
import { DelegueRenderView } from './delegueRender.view.js';
import { FilterContentView } from './filterContent.view.js';
import { FilterView } from 'core/components/filter/filter.view.js';
import { FormView } from './form.view.js';
import { HabilitationContext } from 'core/models/habilitationContext.js';
import { i18n } from '../../../../i18n.js';
import { ListeDetailPanelView } from 'core/layouts/listeDetailPanel.view.js';
import { LOG } from '../../../../utils/log.js';
import { NavigationView } from 'core/components/navigation/navigation.view.js';
import { objs } from '../../../../objectsRepository.js';
import { STR } from '../../../../utils/str.js';
import { TYPE } from '../../../../tda/tda.js';
import { DroitsdelWorkflow } from '../models/droitsdel.workflow.js';

export var GeneralView = Backbone.View.extend({
  /**
   * it sets the workflow pointer
   */
  className: 'droitsdel',

  /**
   * Constructor
   * It will paint all the tab for the UC.
   */
  initialize: function(options) {
    if (options && options.parent) {
      this.parent = options.parent;
    }
    if (options && options.usecase) {
      this.usecase = options.usecase;
    }

    //utilCode of the aplication
    var utilCode = objs.appRt.workflow.authModel.get("user");
    var utilMatricule = null;

    if (options.utilMatricule) {
      utilCode = options.utilCode;
      utilMatricule = options.utilMatricule;
    }

    var ctxPeriode = null;

    if (options.ctxPeriode) {
      ctxPeriode = options.ctxPeriode;
    }

    //datefin : TYPE.DATE.dateToStr(datefin, TYPE.DATE.DEFAULT_MASK_BBDD),

    // Initializes the main parts of the UI and its workflow
    this.workflow = new DroitsdelWorkflow({
      utilCode: utilCode,
      ctxPeriode: ctxPeriode,
    });

    this.workflow.setHabContext(new HabilitationContext({
      onglet: "droitsdel",
      fonc_cour: options.habilitation,
      nat_gest: ""
    }));

    if (options.usecase === "suivicollab") {
      this.workflow.getHabContext().update({ fonc_cour: "RES_DELEG", nat_gest: "" });

      this.ctxEcran = "suivicollab";
      this.idButtonBar = "resp_droitsdel_buttonbar";
      //General view that gather all the other
      this.layout = new ListeDetailPanelView({ position: 22 });

    } else if (options.usecase === "utilisateur") {
      this.workflow.getHabContext().update({ fonc_cour: "ADM_DELEG", nat_gest: "" });

      this.ctxEcran = "utilisateur";
      this.idButtonBar = "adm_droitsdel_buttonbar";
      //General view that gather all the other
      this.layout = new ListeDetailPanelView({ position: 22 });
    } else {
      this.workflow.getHabContext().update({ fonc_cour: "COL_DELEG", nat_gest: "" });

      this.ctxEcran = "droitsdel";
      this.idButtonBar = "coll_droitsdel_buttonbar";
      //General view that gather all the other
      this.layout = new ListeDetailPanelView({ position: 28 });
    }

    this.filter = this._initFilter();
    this.workflow.filterModel = this.filter.model;
    this.table = this._initTable();
    this.workflow.tableModel = this.table.model;
    this.btnBar = new ButtonBarView({ id: this.idButtonBar });
    this.btnBar.model.setHabContext(this.workflow.getHabContext());
    this.workflow.btnBarModel = this.btnBar.model;
    this.workflow.btnBar = this.btnBar;
    this.navigationView = this._initNavigationView();
    this.workflow.navigationModel = this.navigationView.model;
    var form = this._initForm();
    this.workflow.formModel = form.model;
    this.workflow.form = form;

    this.workflow.form.referentielRestriction(utilMatricule);
    this.filter.viewPart1.referentielRestriction(utilMatricule);
  },

  /**
   * Function to change the collaborateur, with the utilisateur joined
   */
  _changeCollab: function(utilCode, utilMatricule) {
    var self = this;
    this.workflow.utilCode = utilCode;

    this.workflow.form.referentielRestriction(utilMatricule);
    this.filter.viewPart1.referentielRestriction(utilMatricule);

    this.workflow._loadData(utilCode, function() {
      self.workflow.tableModel.selectFirstRow();
      self.workflow.isCreation = false;
    });

  },

  /**
   * Load the diferent elements in the layout
   */
  _initLayoutUC: function(callback) {
    var self = this;
    if (this.workflow.get("ready")) {
      callback();
    } else {
      this.workflow.setUp(function() {
        // When the workflow is ready, we can render the view without
        // concerns
        self.layout.$el.find(".phx-panelA1").html(self.filter.render().el);
        self.filter.viewPart1.renderData();
        self.layout.$el.find(".phx-panelA2").html(self.table.el);
        self.table.render();
        self.layout.$el.find(".phx-panelB1").html(self.btnBar.render().el);

        //get the habilitation in options
        self.layout.$el.find(".phx-panelB2").html(self.navigationView.render().el);
        self.layout.$el.find(".phx-panelB4").html(self.workflow.form.render().el);
        self.layout.$el.find(".phx-panelB4").resize();

        // Function call to recalculate the size of the panels once the
        // burden of everything you need to show the use case
        if (callback) {
          callback();
        }

        self.trigger("uc:ready");
      });
    }
  },

  /**
   * Renders the view to the form
   */
  render: function() {
    this.$el.html(this.layout.render().el);

    // allow auto height of dialog
    if (this.parent) {
      this.parent._setTitle(i18n.t('droitsdel.usecase_title'));
    }
    if (this.usecase != "suivicollab") {
      this.renderView();
    }

    return this;
  },

  /**
   * Renders the view to the form
   */
  renderView: function(callback) {
    var self = this;
    this._initLayoutUC(function() {
      var tableMd = self.workflow.tableModel;
      if (tableMd.coll.length > 0) {
        //If the WF have ctxPeriode and we have this periode select this periode if not select the first row
        if (self.workflow.ctxPeriode) {
          var model = _.find(tableMd.coll.models, function(item) {
            LOG.debug(item);
            if (TYPE.DATE.strToDate(item.get("datedeb")) <= TYPE.DATE.strToDate(self.workflow.ctxPeriode.datedeb) &&
              TYPE.DATE.strToDate(item.get("datefin")) >= TYPE.DATE.strToDate(self.workflow.ctxPeriode.datefin)) {
              return item;
            }
            return null;
          });
          if (!STR.isBlank(model)) {
            tableMd.trigger("row:click", model);
          } else {
            var firstRow = tableMd.coll.at(0);
            tableMd.trigger("row:click", firstRow);
          }
        } else {
          var firstRow = tableMd.coll.at(0);
          tableMd.trigger("row:click", firstRow);
        }
      } else {
        self.workflow.trigger("resetForm");
      }

      if (self.parent) {
        self.parent.resize();
      }
      //habilitations des boutons du menu selon l'écran
      if (self.ctxEcran === "droitsdel") {
        self.workflow.btnBar.habilitations("COL_DELEG");
      } else if (self.ctxEcran === "suivicollab") {
        self.workflow.btnBar.habilitations("RES_DELEG");
      } else {
        self.workflow.btnBar.habilitations("ADM_DELEG");
      }

      if (callback) {
        callback();
      }
    });
  },

  /**
   * Creating the filter for the table
   */
  _initFilter: function() {
    var part1View = new FilterContentView({ workflow: this.workflow, ctxEcran: this.ctxEcran, habilitation: this.workflow.getHabContext() });
    var filter = new FilterView({ id: "droitsdelfilter", viewPart1: part1View });
    return filter;
  },

  /**
   * Creating the table that contains the data from the collection
   */
  _initTable: function() {
    var tableModel = new DataGridModel({ coll: new DelegationsTableColl() });
    tableModel.coll.usecase = this.ctxEcran;

    var tblColumns = [
      { title: i18n.t('droitsdel.du'), property: "datedeb", width: 80 },
      { title: i18n.t('droitsdel.au'), property: "datefin", width: 80 },
      { title: i18n.t('droitsdel.delegues'), property: "delegue", width: 180 },
      { title: i18n.t('droitsdel.retro'), property: "retro", width: 100 }
    ];
    var table = new DataGridView({
      id: "droitsdel_table",
      columns: tblColumns,
      model: tableModel,
      title: i18n.t('droitsdel.usecase_title'),
      showFilteredRowsInTitle: true,
      gridHeightType: "auto",
      parentContainer: this.parent,
      habilitations: this.workflow.getHabContext()
    });

    var self = this;
    table.cellRenderers["datedeb"] = function(model) {
      return TYPE.DATE.format(model.get("datedeb"), TYPE._getFormatByCode("DATE"));
    };
    table.cellRenderers["datefin"] = function(model) {
      return TYPE.DATE.format(model.get("datefin"), TYPE._getFormatByCode("DATE"));
    };
    table.cellRenderers["delegue"] = function(model) {
      return new DelegueRenderView({ model: model, workflow: self.workflow });
    };
    table.cellRenderers["retro"] = function(model) {
      // The same method than "delegue" to show title (infobulle)
      var resp = $("<span>");
      var text = TYPE.BOOLEAN.format(model.get("retro"));
      var title = i18n.t('droitsdel.retrocession_title');
      resp.text(text).attr("title", title);
      return resp;
    };

    table.setVisibleColumns(["datedeb", "datefin", "delegue"]);
    table.setSortableColumns(["datedeb", "datefin"]);

    // set habilitations context that the table collection will use
    tableModel.coll.setHabContext(this.workflow.getHabContext());
    tableModel.setHabContext(this.workflow.getHabContext());

    return table;
  },

  /**
   * Creating the form to the data
   */
  _initForm: function() {
    var form = new FormView({ id: "droitsdel_form", workflow: this.workflow, ctxEcran: this.ctxEcran, habilitation: this.workflow.getHabContext(), parentDialog: this.parent });
    return form;
  },

  /**
   * Creating the navigation view for the table
   */
  _initNavigationView: function() {
    var page = new NavigationView({ text: i18n.t('droitsdel.usecase') });
    return page;
  }

});
