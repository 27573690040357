import { HABILITATION } from '../../../../../utils/habilitation.js';
import { HabilitationContext } from '../../../../../core/models/habilitationContext.js';
import { i18n } from '../../../../../i18n.js';
import { InfosMemosModel } from '../models/infosMemos.model.js';
import { STR } from '../../../../../utils/str.js';
import { ViserInfoMemosModel } from '../models/viserInfoMemos.model.js';
import TPL_binfmem_briqueInfosMemos from '../briqueInfosMemos.tpl.html';

/**
 * View of Brique 'Infos / Memos'
 */
export var InfosMemosView = Backbone.View.extend({

  //REVIEW: 	i18n : phx.i18n.common.binfmem,
  _maxLines: 10,

  events: {
    "click .phx-icon-elementNonVu": "_setMessageView",
    "mouseenter .phx-icon-elementNonVu": "_hoverVuIcon",
    "mouseleave .phx-icon-elementNonVu": "_hoverVuIcon"
  },

  initialize: function(options) {
    this.usePopulation = false;
    if (options && options.usePopulation) {
      this.usePopulation = options.usePopulation;
    }
    this.template = TPL_binfmem_briqueInfosMemos;

    this.habContext = new HabilitationContext({
      onglet: "binfmem",
      fonc_cour: "COL_MESS.V",
      nat_gest: ""
    });

    this.model = new InfosMemosModel();
    this.model.usePopulation = this.usePopulation;
    this.model.setHabContext(this.habContext);

    this.parent = null;

    if (options && options.parent) {
      this.parent = options.parent;
    }
  },

  render: function() {
    var json = { 'i18n': i18n };
    $(this.el).html(this.template(json));

    $(this.el).find(".table_BriqueInfosMemos").html(i18n.t('common:binfmem.noMessageAffiche'));

    return this;
  },

  refreshBrique: function() {
    var self = this;

    this.$el.attr("aria-busy", "true");

    $(this.el).find(".table_BriqueInfosMemos").html(i18n.t('common:binfmem.noMessageAffiche'));

    this.model.fetch({
      success: function(fresh) {
        self.renderTable();
        self.$el.attr("aria-busy", "false");
      }
    });

    this.delegateEvents();

    return this;
  },

  renderTable: function() {
    var allColl = this.model.get("message");
    var coll = [];

    _.each(allColl, function(model, index) {
      var mesgen = model.genere; //MesNotifChgt
      var acquitte = model.acquitte; //MesNotifChgtVu

      if (mesgen === false || (mesgen === true && acquitte == false)) {
        coll.push(model);
      }

    });

    if (coll.length > 0) {
      //create the columns for the table
      var tblColumns = [
        { title: "Message", property: "message", width: 200 }
      ];
      //create the table witdh the collection data and collection titles
      var table = this._createTableOfColl(coll, tblColumns, "tableBriqueInfosMemos");

      $(this.el).find(".table_BriqueInfosMemos").html(table);
      //create the tooltips
      var rows = $(this.el).find(".table_BriqueInfosMemos table tbody tr");
      for (var i = 0; i < coll.length; i++) {
        var rowModel = coll[i];
        if (rowModel.datedeb != rowModel.datefin) {
          $(rows[i]).attr("title", rowModel.tooltip);
        }
      }

      var rowHeight = $(this.el).find(".table_BriqueInfosMemos tr").outerHeight();

      //Value for Default
      if (rowHeight < 5) {
        rowHeight = 19;

      }
      if (rowHeight !== null && this.parent !== null) {
        this.parent.setContentHeight(rowHeight * this._maxLines);
      }
    }
  },

  _createTableOfColl: function(coll, tblColumns, id) {
    var showTitle = false;
    var table = "";

    table = "<table id='" + id + "' class='ui-grid-table phx-grid ui-grid'>";

    //paint the titles
    if (showTitle) {
      table += "<thead class='ui-grid-header'>";
      table += "<tr>";
      _.each(tblColumns, function(column, index) {
        table += "<th class='phx-th" + index + " ui-grid-th " +
          "ui-grid-th-RightBorder'style='width:" + column.width + "px;'>";
        table += "<div style='text-align:left'><span>" + column.title + "</span></div>";
        table += "</th>";

      });
      table += "</tr>";
      table += "</thead>";
    }
    //paint the data
    table += "<tbody class='ui-grid-body'>";
    for (var i = 0; i < coll.length; i++) {
      var mesgen = coll[i]["genere"]; //MesNotifChgt
      var acquitte = coll[i]["acquitte"]; //MesNotifChgtVu
      if (mesgen === false || (mesgen === true && acquitte == false)) {
        table += "<tr class='ui-grid-row'>";
        for (var j = 0; j < tblColumns.length; j++) {
          table += "<td class='phx-td" + j + "' style='width:" + tblColumns[j].width + "px; white-space: normal;'>";
          table += this._prepareMessageText(coll[i][tblColumns[j].property]);
          //Add icons
          if (mesgen === true && acquitte === false && HABILITATION.canView("COL_MESS.V")) {
            table += "<span class='phx-icon-changeMessage phx-icon-elementNonVu' title='" + i18n.t('common:binfmem.infobulle_message_vu') + "' data_code='" + coll[i].code + "'></span>";
          }
          table += "</td>";
        }
        table += "</tr>";
      }
    }
    table += "</tbody>";

    return table;
  },
  /*
   * concatenate an array of messages
   */
  _prepareMessageText: function(message) {
    var messageText = "";

    for (var i = 0; i < message.length; i++) {
      messageText += message[i];
      if (i + 1 < message.length && !STR.isBlank(message[i + 1])) {
        messageText += " ";
      }
    }

    return messageText;
  },

  _setMessageView: function(event) {
    var target = event.target;

    var code = target.attributes.data_code.value;

    var self = this;

    var messageSaveModel = new ViserInfoMemosModel({ code: code });

    messageSaveModel.setHabContext(new HabilitationContext({
      onglet: "suivicollab",
      fonc_cour: "RES_MESS.V"
    }));

    var self = this;

    messageSaveModel.save(null, {
      silent: true,
      success: function(refresh) {
        self.refreshBrique();
      }
    });
  },

  _hoverVuIcon: function(event, ui) {
    var target = event.target;
    if (event.type == "mouseenter") {
      $(target).addClass("phx-icon-viser");
    } else if (event.type == "mouseleave") {
      $(target).removeClass("phx-icon-viser");
    }
  }
});