module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="phx-menu ui-corner-all phx-hover" role="menuitem" tabindex="'+
((__t=(tabindex))==null?'':__t)+
'" data-value="'+
((__t=(index))==null?'':__t)+
'">\n  <span class="phx-hover ui-corner-all"><a  href="'+
((__t=(menu.href))==null?'':__t)+
'" tabindex="-1" class="'+
((__t=(menu.id))==null?'':__t)+
' ui-corner-all" target="'+
((__t=(menu.target))==null?'':__t)+
'">'+
((__t=(menu.nom))==null?'':__t)+
'</a></span>\n</div>';
}
return __p;
};
