import { BaseModel } from 'core/models/base.model.js';


/**
 * Model for the main table
 */
export var SuvietatModel = BaseModel.extend({

  defaults: {
    "code": "",
    "libelle": "",
    "heuredebut": null,
    "datedebut": "",
    "heurefin": null,
    "datefin": "",
    "etat": {
      "code": "",
      "libelle": ""
    },
    "edition": null,
    "theme": "",
    "type": "",
    "matricule": null,
    "url": ""
  },

  url: function() {
    return Configuration.restRoot + "/rest/edition/" + encodeURIComponent(this.id);
  },

  parse: function(response) {
    if (response.code !== undefined) {
      response.id = response.code;
    }
    return response;
  }
});