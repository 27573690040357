import { MonRespModel } from './monResp.model.js';

import { PaginatedCollection } from '../../../../../core/models/paginated.collection.js';

/**
 * Collection for Brique 'Affectations en cours ou à venir'
 * */
export var MonRespCollection = PaginatedCollection.extend({

  model: MonRespModel,

  url: function() {

    return Configuration.restRoot + "/rest/ficheperso/" + this.matric + "/histostruct/" + this.structid

  },

  initialize: function() {
    PaginatedCollection.prototype.initialize.call(this);
    this.paginated = false;
  },

  parse: function(response) {
    response.list = response.affectation;

    delete response.affectation;

    return PaginatedCollection.prototype.parse.call(this, response);
  }
});
