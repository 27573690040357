import { BaseModel } from 'core/models/base.model.js';
import { DelegantRenderView } from './delegantRender.view.js';
import { DelegationsModel } from '../models/delegations.model.js';
import { ElementColl } from '../models/element.collection.js';
import { ElementLevel3Model } from '../models/elementLevel3.model.js';
import { FORMS } from '../../../../utils/forms.js';
import { FormView as CommonFormView } from 'core/views/form.view.js';
import { HabilitationContext } from 'core/models/habilitationContext.js';
import { i18n } from '../../../../i18n.js';
import { LOG } from '../../../../utils/log.js';
import { MSGS } from '../../../../utils/msgs.js';
import { SelectUtilisateurView } from 'common/referentiel/referentielutilisateur/views/selectUtilisateur.view.js';
import { STR } from '../../../../utils/str.js';
import { TooltipDelegView } from './tooltipDeleg.view.js';
import { TreeTypeGridModel } from 'core/grids/treetypegrid/treeTypeGrid.model.js';
import { TreeTypeGridView } from 'core/grids/treetypegrid/treeTypeGrid.view.js';
import { TYPE } from '../../../../tda/tda.js';
import { GLOBAL_DATA } from '../../../../globalData.js';
import TPL_droitsdel_detail from '../detail.tpl.html';

export var FormView = CommonFormView.extend({
  /**
   * Event launch for Backbone when a inputs of fieldset 'droitsdel-detail-panel' change the property value
   *
   */
  /**
   * Event launch for Backbone when a key up in the inputs in  fieldset 'droitsdel-detail-panel'
   *
   */
  /**
   * Event launch for Backbone when a checkbox change the value
   *
   */
  /**
   * Event launch for Backbone when a mouse is over to span the class 'nivel3'
   *
   */
  /**
   * Event launch for Backbone when a click the link of class 'masquerAfficherDroits'
   *
   */
  /**
   * Event launch for Backbone when a change the value of input of class 'datedeb'
   *
   */

  /**
   * Constructor
   * View for render a delegations in a form
   */
  initialize: function(options) {
    this.model = new BaseModel({
      value: new DelegationsModel(),
      mode: "C"
    });
    this.template = TPL_droitsdel_detail;

    this.habilitation = null;
    this.context = null;
    if (options.context) {
      this.context = options.context;
    }

    if (options.habilitation) {
      this.habilitation = options.habilitation;
    }

    this.habContext = new HabilitationContext({
      onglet: this.habilitation.get("onglet"),
      fonc_cour: this.habilitation.get("fonc_cour"),
      nat_gest: this.habilitation.get("nat_gest")
    });
    this.ctxEcran = options.ctxEcran;

    if (options && options.workflow) {
      this.workflow = options.workflow;
    }
    this.parentDialog = options.parentDialog;

    // Create table editable
    this.table = this._initTable();

    // When the value is changed, we have to map the new value to the form
    this.model.on("change:value", this._mapToForm, this);
    this.model.on("change:mode", this._manageMode, this);
    this.listenTo(this.workflow, 'resetForm', this._mapToForm);

    this.table.model.off("change:mode");
    this.listenTo(this.table.model, "change:mode", this._manageTableMode);

    this.typeMaskByClass = {};
    this.typeMaskByClass["datedeb"] = "DATE_A";
    this.typeMaskByClass["datefin"] = "DATE_A";

    // listen HideShow the form View
    this.listenTo(this.model, "hide:form", this._hideForm);
    this.listenTo(this.model, "show:form", this._showForm);
  },

  /**
   * Load the internal events of the view, and the events of TDA Date
   */
  events: _.extend({
    "change .droitsdel-detail-panel :input:not([readonly])": "_change", // See jQuery api, it means
    // input,select,textarea
    "keyup .droitsdel-detail-panel :input:not([readonly])": "_notifyEdition",
    "change :checkbox": "_clickListenerCheck",
    "mouseover span.nivel3": "_tooltipListener",
    "click a.masquerAfficherDroits": "_manageViewDroids",
    "change .droitsdel-detail-panel :input.datedeb": "_searchDroitsFromDatedeb"
  }, TYPE.DATE.events()),

  _hideForm: function() {
    this.$el.hide();
  },

  _showForm: function() {
    this.$el.show();
  },

  /**
   * Load the Base Droits of the data base when change the datedeb
   */
  _searchDroitsFromDatedeb: function(event) {
    if (this.model.isValid()) {

      if (this.ctxEcran === "suivicollab" || this.ctxEcran === "utilisateur" || this.ctxEcran === "droitsdel") {
        this.workflow.droitsBase.datefrom = this.model.get("value").get("datedeb");
        this.workflow.droitsBase.queretro = true;
        this.workflow.droitsBase.delegation = (this.workflow.formModel.get("value").get("code") !== null) ? this.workflow.formModel.get("value").get("code") : this.workflow.formModel.get("value").get("codeDelegation");
      }

      this.workflow.droitsBase.utilCode = this.workflow.utilCode;

      var self = this;

      for (var i = 0; i < self.model.attributes.value.attributes.profils.length; i++) {
        var profils = self.model.attributes.value.attributes.profils[i];

        for (var j = 0; j < profils.roles.length; j++) {
          var roles = profils.roles[j];
          LOG.debug("Tiene check -> " + roles.libelle);

          for (var z = 0; z < roles.groupes.length; z++) {
            if (roles.groupes[z].delegue == true) {
              LOG.debug("Tiene check -> " + roles.groupes[z].libelle);
            }
          }
        }
      }

      this.workflow.droitsBase.fetch({
        success: function(fresh) {
          self.workflow.collectionBase = self.workflow._arrayToCollection(fresh.get("profils"));
          self.table.model.coll.reset(self.workflow.collectionBase.models);
          self._manageInitChecks();

          //change the view droits to masquer
          $(self.el).find(".masquerAfficherDroits").text(i18n.t('droitsdel.masquer'));
          self.workflow.fullDroids = true;
        }
      });
    }

    var resp = TYPE.DATE.parse(event.target.value);
    if (!STR.isBlank(GLOBAL_DATA.paramDivers.get("SPEValideu")) && GLOBAL_DATA.paramDivers.get("SPEValideu").get("valeur") === "1") {
      this.model.get("value").set("datefin", resp.val);
      this._mapToForm("chgDatefin");
    }

  },

  /**
   * See or not see the droits no delegues
   */
  _manageViewDroids: function(event) {
    var textLink = (event.currentTarget.text || event.currentTarget.innerText);

    if (textLink == i18n.t('droitsdel.masquer')) {
      $(this.$el).find(".masquerAfficherDroits").text(i18n.t('droitsdel.afficher'));
      this.workflow._masquerDroits("masquer");
    } else if (textLink == i18n.t('droitsdel.afficher')) {
      $(this.$el).find(".masquerAfficherDroits").text(i18n.t('droitsdel.masquer'));
      this.workflow._masquerDroits("afficher");
    }
  },

  /**
   * Create and view the tooltip of the doits of nivel 3, tooltip value send for parametre
   */
  _tooltipListener: function(event) {
    //create the tooltip width the attribute tooltip send for parametre
    var txt = event.currentTarget.attributes.tooltip.value;
    if (!STR.isBlank(txt)) {
      $(event.currentTarget).tooltip({ content: "" });
      $(event.currentTarget).tooltip("option", "content", txt);
      $(event.currentTarget).prop("title", "");

      $(event.currentTarget).tooltip({ position: { my: "left-10 top", at: "right bottom" } });
      $(event.currentTarget).tooltip("open");
    }

  },

  /**
   * Check or not check the checkbox of groups and the general
   */
  _clickListenerCheck: function(event) {
    var element = event.currentTarget.className.split(" ")[0];
    var objDom = $(event.currentTarget);
    var value = objDom.is(":checked");
    var code = "";
    var allChecked = "";
    //select the groupe of check
    if (element === "checkAll") {
      if (value) {
        $(this.el).find(":checkbox[n1],[n2],[n3]").each((idx, ele) => {
          if ($(ele).attr("n1").indexOf("AGENT") < 0) {
            $(ele).val([true]);
          }
        });
        $(this.el).find(":checkbox[class='checkN2']").attr('disabled', 'disabled');
        $(this.el).find(":checkbox[class='checkN3']").attr('disabled', 'disabled');
      } else {
        $(this.el).find(":checkbox[n1],[n2],[n3]").val([false]);
        $(this.el).find(":checkbox[class='checkN2']").each((idx, ele) => {
          if ($(ele).attr("n1").indexOf("AGENT") < 0) {
            $(ele).removeAttr('disabled');
          }
        });
        $(this.el).find(":checkbox[class='checkN3']").each((idx, ele) => {
          if ($(ele).attr("n1").indexOf("AGENT") < 0) {
            $(ele).removeAttr('disabled');
          }
        });
      }
    } else if (element === "checkN1") {
      code = objDom.attr("n1");
      //check o uncheck the checkboxs
      $(this.el).find(":checkbox[class='checkN2'][n1='" + code + "']").removeAttr('disabled');
      $(this.el).find(":checkbox[class='checkN3'][n1='" + code + "']").removeAttr('disabled');

      if (value) {
        $(this.el).find(":checkbox[class='checkN2'][n1='" + code + "']").val([true]);
        $(this.el).find(":checkbox[class='checkN3'][n1='" + code + "']").val([true]);

        $(this.el).find(":checkbox[class='checkN2'][n1='" + code + "']").attr('disabled', 'disabled');
        $(this.el).find(":checkbox[class='checkN3'][n1='" + code + "']").attr('disabled', 'disabled');

      } else {
        $(this.el).find(":checkbox[class='checkN2'][n1='" + code + "']").val([false]);
        $(this.el).find(":checkbox[class='checkN3'][n1='" + code + "']").val([false]);
      }
      //find the all check of all profils
      allChecked = this._ifAllChecked(element);
      if (allChecked === "all") {
        $(this.el).find(":checkbox[class*='checkAll']").val([true]);
      } else if (allChecked === "none") {
        $(this.el).find(":checkbox[class*='checkAll']").val([false]);
      } else {
        $(this.el).find(":checkbox[class*='checkAll']").val([false]);
      }

    } else if (element === "checkN2") {
      code = objDom.attr("n2");
      if (value) {
        $(this.el).find(":checkbox[class='checkN3'][n2='" + code + "']").val([true]);
      } else {
        $(this.el).find(":checkbox[class='checkN3'][n2='" + code + "']").val([false]);
      }
      //find the all check of profil
      allChecked = this._ifAllChecked(element, objDom.attr("n1"));
      if (allChecked === "all") {
        $(this.el).find(":checkbox[class='checkN1'][n1='" + objDom.attr("n1") + "']").val([true]);
        $(this.el).find(":checkbox[class='checkN1'][n1='" + objDom.attr("n1") + "']").change();
      } else {
        $(this.el).find(":checkbox[class='checkN1'][n1='" + objDom.attr("n1") + "']").val([false]);
        //$(this.el).find(":checkbox[class='checkN1'][n1='" + objDom.attr("n1") + "']").change();
      }
      //$(this.el).find(":checkbox[class='checkN1'][n1='" + objDom.attr("n1") + "']").change();
    } else if (element === "checkN3") {
      code = objDom.attr("n3");
      allChecked = this._ifAllChecked(element, objDom.attr("n1"));
      //find the all check of groupe
      var upperCheckbox = [];
      upperCheckbox = $(this.el).find(":checkbox[class='checkN2'][n2='" + objDom.attr("n2") + "']");
      if (upperCheckbox.length === 0) {
        upperCheckbox = $(this.el).find(":checkbox[class*='checkN1'][n1='" + objDom.attr("n1") + "']");
      }

      if (allChecked === "all") {
        upperCheckbox.val([true]);
        upperCheckbox.change();
      } else {
        upperCheckbox.val([false]);
        $(this.el).find(":checkbox[class*='checkAll']").val([false])
        //$(this.el).find(":checkbox[class='checkN2'][n2='" + objDom.attr("n2") + "']").change();
      }

    }
    //checked in the models
    this._checkedModels();

    this.workflow.btnBarModel.set("mode", "E");
  },

  /**
   * Find the all check of groupe and see if are checked o no
   */
  _ifAllChecked: function(element, parentCode) {

    var checks, checksChecked = [];

    if (element === "checkN1") {
      checks = $(this.el).find(":checkbox[class*='" + element + "']");
      checksChecked = $(this.el).find(":checkbox[class*='" + element + "']:checked");
    } else if (element === "checkN2") {
      checks = $(this.el).find(":checkbox[class='" + element + "'][n1='" + parentCode + "']");
      checksChecked = $(this.el).find(":checkbox[class='" + element + "'][n1='" + parentCode + "']:checked");
    } else if (element === "checkN3") {
      checks = $(this.el).find(":checkbox[class='" + element + "'][n1='" + parentCode + "']");
      checksChecked = $(this.el).find(":checkbox[class='" + element + "'][n1='" + parentCode + "']:checked");
      // in case of no groups or deleNiveau=1
      if (checks.length === 0) {
        if ($(this.el).find(":checkbox[n1='" + parentCode + "']")[0].checked) {
          return "all";
        } else {
          return "none";
        }
      }
    }
    //view if all are checked
    if (checks.length === checksChecked.length) { return "all"; }
    //view if all are not checked
    if (checksChecked.length === 0) { return "none"; }

    return false;

  },

  /**
   * Check or not check the checkbox of groups and the general, when init the component
   */
  _manageInitChecks: function() {
    var self = this;
    var checks = $(this.el).find(":checkbox[class*='checkN1']");
    var allChecked = false;
    _.each(checks, function(obj) {
      var objDom = $(obj);
      allChecked = self._ifAllChecked("checkN3", objDom.attr("n1"));
      if (allChecked === "all") {
        $(self.el).find(":checkbox[class='checkN1'][n1='" + objDom.attr("n1") + "']").val([true]);
        $(self.el).find(":checkbox[class='checkN2'][n1='" + objDom.attr("n1") + "']").attr('disabled', 'disabled');
        $(self.el).find(":checkbox[class='checkN3'][n1='" + objDom.attr("n1") + "']").attr('disabled', 'disabled');
      } else if (allChecked === "none") {
        $(self.el).find(":checkbox[class='checkN1'][n1='" + objDom.attr("n1") + "']").val([false]);
        if (!self._panelStateIsReadonly()) {
          $(self.el).find(":checkbox[class='checkN2'][n1='" + objDom.attr("n1") + "']").removeAttr('disabled');
          $(self.el).find(":checkbox[class='checkN3'][n1='" + objDom.attr("n1") + "']").removeAttr('disabled');
        }
      }
    });
    allChecked = self._ifAllChecked("checkN1");
    if (allChecked === "all") {
      $(this.el).find(":checkbox[class='checkAll']").val([true]);
    } else if (allChecked === "none" || allChecked == false) {
      $(this.el).find(":checkbox[class='checkAll']").val([false]);
    }

  },

  /**
   * Change the value of delegue for all models when click the checkbox
   */
  _checkedModels: function() {
    var self = this;
    _.each(this.table.model.coll.models, function(obj) {
      if (obj.get("nivel") == 1) {
        var complet = $(self.el).find(":checkbox[class*='checkN1'][n1='" + obj.get("id") + "']").is(":checked");
        obj.set("complet", complet);
      } else if (obj.get("nivel") == 2 && obj.levelType === "group") {
        var checked = $(self.el).find(":checkbox[class='checkN3'][n1='" + obj.get("id1") + "'][n2='" + obj.get("id") + "'][item='" + obj.item + "']").is(":checked");
        obj.set("delegue", checked, { silent: true });
      } else if (obj.get("nivel") == 3) {
        var checked = $(self.el).find(":checkbox[class='checkN3'][n1='" + obj.get("id1") + "'][n2='" + obj.get("id2") + "'][item='" + obj.item + "']").is(":checked");
        obj.set("delegue", checked, { silent: true });
      }
    });
  },

  /**
   * Only to don't have the exception because it's not implemented
   */
  _enablePk: function() {},

  /**
   * Creating the table that contains the data from the droits
   */
  _initTable: function() {
    var tableModel = new TreeTypeGridModel({
      coll: new ElementColl()
    });

    tableModel.coll.usecase = this.ctxEcran;

    var checkHead = "<input type='checkbox' class='checkAll' value=true style='margin-right: 5px;'></input>";
    var tblColumns = [
      { title: checkHead, property: "check", width: 30 },
      { title: i18n.t('droitsdel.doitsTable'), property: "libelle", width: 270, treecol: true },
      { title: i18n.t('droitsdel.perimetre'), property: "perimetre.code", width: 180 },
      { title: i18n.t('droitsdel.du'), property: "datedeb", width: 70 },
      { title: i18n.t('droitsdel.au'), property: "datefin", width: 70 },
      { title: i18n.t('droitsdel.deleguePar'), property: "delegant", width: 160 }
    ];

    var table = new TreeTypeGridView({
      columns: tblColumns,
      model: tableModel,
      showFilteredRowsInTitle: true,
      title: i18n.t('droitsdel.listeDesDroits'),
      rowAltPersonalised: true,
      expCollIcons: { expandable: null, collapsible: null }
      //habilitations : this.model.getHabContext()
    });

    table.height = 200;

    var self = this;
    this.isAllChecked = true;
    //insert the checks for select the profils and groupes
    table.cellRenderers["check"] = function(model) {
      var element = $("<input>").attr({ type: 'checkbox', value: true });
      self.isAllChecked = true;

      switch (model.get("nivel")) {
        case 1:
          if (model.get("code").indexOf("AGENT") >= 0) {
            element.prop('disabled', true);
          }
          element.addClass("checkN1");
          element.attr("N1", model.get("id"));
          element.attr("checked", model.get("complet"));
          if (model.get("complet") == false) {
            self.isAllChecked = false;
          }
          break;
        case 2:
          if (model.get("nivel1").indexOf("AGENT") >= 0) {
            element.prop('disabled', true);
          }
          if (model.levelType === "group" && self.workflow.deleNiveau == "0") {
            element.addClass("checkN3");
            element.attr("N1", model.get("id1"));
            element.attr("N2", model.get("id"));
            element.attr("item", model.item);
            element.attr("checked", model.get("delegue"));
            if (model.get("delegue") == false) {
              self.isAllChecked = false;
            }
          } else {
            element = "";
          }
          break;
        case 3:
          if (model.get("nivel1").indexOf("AGENT") >= 0) {
            element.prop('disabled', true);
          }
          if (!STR.isBlank(model.get("nivel2")) && self.workflow.deleNiveau == "0") {
            element.addClass("checkN3");
            element.attr("N1", model.get("id1"));
            element.attr("N2", model.get("id2"));
            element.attr("N3", model.get("id"));
            element.attr("item", model.item);
            element.attr("checked", model.get("delegue"));
            if (model.get("delegue") == false) {
              self.isAllChecked = false;
            }
          } else {
            element = "";
          }
          break;
        case 4:
          element = "";
          break;
        default:
      }

      //view if is readOnly
      if (element) {
        var isReadonly = self._panelStateIsReadonly();
        FORMS.setFieldReadonly(element, isReadonly, true);
      }

      return element;
    };
    table.cellRenderers["libelle"] = function(model) {
      var resp = "";
      resp = $("<span>");

      if (model.get("nivel1").indexOf("AGENT") >= 0) {
        resp.addClass("grey-color");
      }

      if (model.get("nivel") == 1) {
        resp.html(model.get("libelle"));
        resp.css("font-weight", "bold");
        //if is delegue view in style delegation
        if (model.get("droit") === "D") {
          resp.addClass("ui-phx-delegation");
        }
      } else if (model.get("nivel") == 2) {
        resp.html(model.get("libelle"));
        resp.css("font-weight", "bold");
        //if is delegue view in style delegation
        if (model.get("droit") === "D") {
          resp.addClass("ui-phx-delegation");
        }
      } else if (model.get("nivel") == 3) {
        resp.html(model.get("libelle"));
        resp.css("font-weight", "bold");
        //if is delegue view in style delegation
        if (model.get("droit") === "D") {
          resp.addClass("ui-phx-delegation");
        }
      } else if (model.get("nivel") == 4) {
        resp.html(model.get("libelle"));
        resp.attr("class", "nivel3");
        //When there is information that should be shown as a tooltip
        if (model.get("vue") || (!STR.isBlank(model.get("profilwkf"))) ||
          model.get("ajout") === true || model.get("modification") === true ||
          model.get("suppression") === true) {
          var tool = new TooltipDelegView({ model: model });
          var toolRender = tool.render().el;
          var toolText = toolRender.innerHTML;
        } else {
          var toolText = "";
        }
        resp.attr("tooltip", toolText);
      }
      return resp;
    };
    table.cellRenderers["perimetre.code"] = function(model) {
      var resp = "";
      resp = $("<span>");
      if (model.get("nivel1").indexOf("AGENT") >= 0) {
        resp.addClass("grey-color");
      }

      if (model.get("perimetre")) {
        return resp.html(model.get("perimetre").libelle);
      } else if (model.get("perimetres") && model.get("perimetres")[0]) {
        var perimetres = _.pluck(model.get("perimetres"), 'libelle');
        return resp.html(perimetres.join('; '));
      }
      return "";
    };
    table.cellRenderers["datedeb"] = function(model) {
      var resp = "";
      resp = $("<span>");
      if (model.get("nivel1").indexOf("AGENT") >= 0) {
        resp.addClass("grey-color");
      }

      if (model.get("datedeb") !== TYPE.DATE.INITIAL) {
        resp.html(TYPE.DATE.format(model.get("datedeb"), TYPE._getFormatByCode("DATE")));
      }
      return resp;
    };
    table.cellRenderers["datefin"] = function(model) {
      var resp = "";
      resp = $("<span>");
      if (model.get("nivel1").indexOf("AGENT") >= 0) {
        resp.addClass("grey-color");
      }

      return resp.html(TYPE.DATE.manageInfinity(model.get("datefin"), TYPE._getFormatByCode("DATE")));
    };
    table.cellRenderers["delegant"] = function(model) {
      var resp = "";
      resp = $("<span>");
      if (model.get("nivel1").indexOf("AGENT") >= 0) {
        resp.addClass("grey-color");
      }

      return resp.html(new DelegantRenderView({ model: model, workflow: self.workflow, ctxEcran: self.ctxEcran }));
    };

    table.setColumnsAlignment({
      "check": "center"
    });

    table.setSortableColumns();

    table.model.coll.off("row:click");

    //coll for fetch the nivel 3
    table.fetchSubTree = function(model, callback) {
      var code = model.get("nivel1") + "," + model.get("nivel2") + "," + model.get("code");
      var modelNivel4 = new ElementLevel3Model({ code: code }); // change to level 4

      /*modelNivel3.usecase = self.ctxEcran;
      modelNivel3.habilitationV = self.habilitation;
      modelNivel3.habilitationG = self.habilitation;*/

      modelNivel4.setHabContext(self.getHabContext());

      modelNivel4.fetch({
        success: function(fresh) {
          var collection = new Backbone.Collection();
          var arrayResp = _.toArray(fresh.attributes);
          _.each(arrayResp, function(obj4) {
            if (obj4 instanceof Object) {
              var model4 = new BaseModel();
              model4.set("nivel", 4);
              model4.set("libelle", obj4.libelle);
              model4.set("filtres", obj4.filtres);
              model4.set("vue", obj4.vue);
              model4.set("profilwkf", obj4.profilwkf);
              model4.set("ajout", obj4.ajout);
              model4.set("modification", obj4.modification);
              model4.set("suppression", obj4.suppression);

              //atributes for tree table view
              model4.isExpanded = false;
              model4.level = 4;
              model4.collapsible = false;
              model4.branch = model.branch;
              //have a children
              model.hasChild = false;

              collection.add(model4);
            }
          });

          callback(model, collection, table.model.coll);
        }
      });

    };
    //We prevent removing the "check" column from the columns that are shown.
    table.setOmittedColumns(["check"]);

    // set habilitations context that the table collection will use
    tableModel.coll.setHabContext(this.model.getHabContext());
    //tableModel.setHabContext(this.model.getHabContext());

    return table;
  },

  /**
   * See if the new mode is suport and change the mode in the buttonBarModel in the table
   */
  _manageTableMode: function() {
    var newMode = this.table.model.get("mode");
    if (!_.contains(["E", "R", "C"], newMode)) {
      throw new Error("Mode not supported in EditableGrid : " + newMode);
    }
    this.table.model.btnBarModel.set("mode", newMode);
  },

  /**
   * Loading of data in the table, which it depends of the code of Delegation.
   * Launch when change the value of left table
   */
  _chargeDataTable: function() {

    var formModel = this.model.get("value");
    var collectionData = this.workflow._arrayToCollection(formModel.get("profils"));
    var models = collectionData.models;

    //unchecked te check for all
    $(this.el).find(":checkbox[class='checkAll']").val([false]);

    if (formModel && formModel.isNew()) {
      this.table.model.coll.reset();
    } else {
      this.table.model.coll.reset(models);
      this._manageInitChecks();
    }

  },

  /**
   * Renders the view to the form
   */
  render: function() {
    var json = { "i18n": i18n };

    $(this.el).html(this.template(json));

    var context = {};
    context = {
      Habilitation: this.habilitation.get("fonc_cour"),
      name: "delegue.matricule",
      ctxEcran: this.ctxEcran
    };

    this.referentiel = new SelectUtilisateurView({ host: this, idComponent: "refutiliz", context: context });

    this.$el.find(".droisDeleguesA").html(this.referentiel.render().el);
    this.$el.find(".droisDeleguesA .phx-selecteur-referentiel").css("width", "335px");

    this.referentiel.filterOut(this.referentielFilter);

    FORMS.setDatepicker(this, ".datedeb");
    FORMS.setDatepicker(this, ".datefin");

    $(this.el).find(".table_droits").html(this.table.render().el);

    return this;
  },

  /**
   * Action performed when choosing a data or close a referentiel
   *
   */
  _responseCallback: function(data) {
    var model = this.model.get("value");
    if (_.isNull(model.get("delegue"))) {
      model.set("delegue", {});
    }

    model.get("delegue").matricule = data.matricule;
    model.get("delegue").matriculeaux = data.matriculeaux;
    model.get("delegue").nom = data.nom;
    model.get("delegue").prenom = data.prenom;
    model.get("delegue").utilcode = data.utilcode;
    model.get("delegue").utilnom = data.utilnom;
  },

  /**
   * Map the value of the model to form
   */
  _mapToForm: function(opt) {
    var pal = this.model.get("value");

    // Set the mode of the form
    var newMode = pal.isNew() ? "C" : "E";
    this.model.set("mode", newMode);

    // Error management
    pal.off("invalid");
    pal.on("invalid", this._showValidationErrors, this);
    // Clear current validation erros
    this._cleanValidationErrors();

    var self = this;

    if (!STR.isBlank(GLOBAL_DATA.paramDivers.get("SPEValideu")) && GLOBAL_DATA.paramDivers.get("SPEValideu").get("valeur") === "1") {
      if (this.workflow.ctxPeriode && this.workflow.isCreation) {
        pal.set("datedeb", this.workflow.ctxPeriode.datedeb);
        pal.set("datefin", this.workflow.ctxPeriode.datefin);
      }
    } else {
      //values for default (creation)
      if (this.workflow.isCreation) {
        //pal.defaults();
        if (this.workflow.ctxPeriode) {
          pal.set("datedeb", this.workflow.ctxPeriode.datedeb);
          pal.set("datefin", this.workflow.ctxPeriode.datefin);
        } else {
          var today = TYPE.DATE.getCurrentDate();
          pal.set("datedeb", today);
          pal.set("datefin", TYPE.DATE.INFINITY);
        }
      }
    }

    //no view the datefin if is a 31/12/2100
    var renderers = {};
    renderers["datefin"] = function(value) {
      if (value == TYPE.DATE.INFINITY) {
        return "";
      } else {
        return value;
      }
    };

    var pDiversSPEValideu = GLOBAL_DATA.paramDivers.get("SPEValideu");

    if (newMode === "C" && STR.isBlank(this.workflow.get("ctxPeriode"))) {
      if (!STR.isBlank(pDiversSPEValideu) && pDiversSPEValideu.get("valeur") === "1") {
        if (opt !== "chgDatefin") {
          pal.set("datedeb", TYPE.DATE.getCurrentDate());
          pal.set("datefin", TYPE.DATE.getCurrentDate());
        }
      }
    }

    renderers["delegue.matricule"] = function() {
      var resp = "";
      if (!STR.isBlank(pal.get("delegue"))) {
        if (!STR.isBlank(pal.get("delegue").nom)) { // if have a collaborateur
          if (self.workflow.paramIdCollab == "matric" || STR.isBlank(self.workflow.paramIdCollab)) {
            resp = pal.get("delegue").nom + " " + pal.get("delegue").prenom + " (" + pal.get("delegue").matricule + ")";
          } else if (self.workflow.paramIdCollab == "matric_aux") {
            resp = pal.get("delegue").nom + " " + pal.get("delegue").prenom + " (" + pal.get("delegue").matriculeaux + ")";
          }
        } else { // if have a utilisateur
          resp = i18n.t('droitsdel.utilisateur') + " " + pal.get("delegue").utilnom;
        }
      }
      self.$el.find(".delegue\\.matricule").attr("title", resp);
      return resp;
    };

    // Map the inputs, checkboxes, selects, textareas from the model
    var fieldset = this.$el.find(".droitsdel-detail-panel");
    this._mapModelToForm(fieldset, pal, renderers);

    this._chargeDataTable();

    this._checkVisibility(pal);
  },

  _checkVisibility: function() { //Customer #183246
    var pal = this.model.get("value");
    if (!STR.isBlank(this.workflow.pDiversModifDeleg) && (this.workflow.pDiversModifDeleg.get("valeur") == 1)) {
      if (!STR.isBlank(pal.get("datedeb")) && !this.workflow._isPostDate(pal.get("datedeb")) && !this.workflow.isCreation) {
        FORMS.setFieldReadonly($(this.el).find(".datedeb"), true);
      } else {
        FORMS.setFieldReadonly($(this.el).find(".datedeb"), false);
      }

      if (!STR.isBlank(pal.get("datefin")) && !this.workflow._isPostDate(pal.get("datefin")) && !this.workflow.isCreation) {
        FORMS.setFieldReadonly($(this.el).find(".datefin"), true);
      } else {
        FORMS.setFieldReadonly($(this.el).find(".datefin"), false);
      }

      if ((!this.workflow._isPostDate(pal.get("datedeb")) || !this.workflow._isPostDate(pal.get("datefin"))) && !this.workflow.isCreation) {
        FORMS.setFieldReadonly($(this.el).find(".delegue\\.matricule"), true);
      } else {
        FORMS.setFieldReadonly($(this.el).find(".delegue\\.matricule"), false);
      }

    }
  },

  _showSupprimerError: function() {
    MSGS.showError(i18n.t('messages:GT_2108'));
  },

  /**
   * Filter the data return of the referentiel
   */
  referentielRestriction: function(matricule) {
    if (this.referentiel) {
      if (!STR.isBlank(matricule)) {
        this.referentiel.filterOut([matricule]);
      } else {
        this.referentiel.filterOut([]);
      }
    } else {
      this.referentielFilter = [matricule];
    }
  },

  _panelStateRenderCustomFields: function(isReadonly) {
    var element = this.$el.find(".checkAll");
    if (element != null && element.length > 0) {
      FORMS.setFieldReadonly(element, isReadonly, true);
    }
  }
});
