import { DataGridModel } from '../../../../../core/grids/data_grid/dataGrid.model.js';
import { DataGridView } from '../../../../../core/grids/data_grid/dataGrid.view.js';
import { i18n } from '../../../../../i18n.js';
import { MesDemandesColl } from '../models/mesDemandes.collection.js';
import { STR } from '../../../../../utils/str.js';
import { TYPE } from '../../../../../tda/tda.js';
import TPL_bdem_briqueMesDemandes from '../briqueMesDemandes.tpl.html';

export var MesDemandesView = Backbone.View.extend({

  _maxLines: 10,

  initialize: function(options) {
    this.usePopulation = false;
    if (options && options.usePopulation) {
      this.usePopulation = options.usePopulation;
    }
    this.template = TPL_bdem_briqueMesDemandes;
    this.tablebriqueMesDemandes = null;
    this.styleLabel = "phx-label-style";
    this.styleDate = "phx-bold-input-style";
    this.styleHoure = "phx-input-style";
    this.styleDemande = "ui-phx-statut-demande";
    this.styleEnCours = "ui-phx-statut-en-cours";
    this.demandeSuppression = "ui-phx-demande-suppression";
    this.parent = null;
    this.table = this._initTable();

    if (options && options.parent) {
      this.parent = options.parent;
    }

    var self = this;

    this.listenTo(this.table.model.coll, "sync", function() {
      self.parent.setTitle(i18n.t('common:bdem.mesDemandes') +
        " (" + self._indexRows(self.table.model.coll) + "/" + self.table.model.coll.totalRecords + ")");
      //update margins
      self.$el.parents(".phx-portlet-div").css({
        "padding": "0px 0px 0px 2px",
        "width": "calc(100% - 4px)"
      });

      self.table.removeGridStyle = true; //disable grid mode

    }, this);
  },

  render: function() {
    $(this.el).html(this.template());

    $(this.el).find(".table_BriqueMesDemandes").html(this.table.el);
    this.table.render();
    this._hideZonesGrid();

    return this;
  },

  refreshBrique: function() {
    var self = this;

    this.table.model.canViewTreatment = true;
    if (isNaN(this.table.model.coll.pagination.size)) {
      this.table.model.coll.pagination = {
        startIndex: 0,
        size: Configuration.pageSize
        // Default size per page
      };
    }
    this.table.model.coll.fetch({
      success: function(fresh) {
        self.parent.setTitle(i18n.t('common:bdem.mesDemandes') +
          " (" + self._indexRows(fresh) + "/" + fresh.totalRecords + ")");
      },
      error: function() {
        self.parent.setTitle(i18n.t('common:bdem.mesDemandes'));
      }
    });

    var lastScrollPosition = 0;
    $(self.el).parents(".phx-portlet-container").off("sortstart.scrollTop");
    $(self.el).parents(".phx-portlet-container").on("sortstart.scrollTop", function(event, ui) {
      lastScrollPosition = self.table.$el.find(".phx-grid-scroll").scrollTop();
    });
    $(self.el).parents(".phx-portlet-container").off("sortstop.scrollTop");
    $(self.el).parents(".phx-portlet-container").on("sortstop.scrollTop", function(event, ui) {
      self.table.$el.find(".phx-grid-scroll").scrollTop(lastScrollPosition);
      lastScrollPosition = 0;
    });

    this.delegateEvents();

    return this;
  },

  _indexRows: function(modelcoll) {
    var totalRecords = modelcoll.totalRecords;
    var startIndex = modelcoll.pagination.startIndex;
    var size = modelcoll.pagination.size;
    var rowNumber = Math.min(startIndex + size, totalRecords);

    if (modelcoll.paginated === false) {
      totalRecords = modelcoll.length;
      rowNumber = totalRecords;
      startIndex = 0;
    }

    return rowNumber;
  },

  _hideZonesGrid: function() {
    //header
    $(this.el).find(".table_BriqueMesDemandes .phx-hd-btn").hide();
    $(this.el).find(".table_BriqueMesDemandes .phx-grid-title-bar").hide();
    $(this.el).find(".table_BriqueMesDemandes .phx-grid-header-scroll").hide();

    //footer
    $(this.el).find(".table_BriqueMesDemandes .phx-grid-footer-left").hide();
    $(this.el).find(".table_BriqueMesDemandes .phx-grid-footer-right").hide();
  },

  _formatedParametres: function(obj, styleDate, styleHoure) {
    var self = this;

    if (obj.type == "A") {
      //@absHeureDebut
      var value = obj.parametres["@absHeureDebut"];
      if (value > 0) {
        value = TYPE.HOUR_MINUTE_NIGHTLY.format(value);
        value = self._textToStyle(value, styleHoure);
        obj.parametres["@absHeureDebut"] = value;
      }
      //@absHeureFin
      var value = obj.parametres["@absHeureFin"];
      if (value > 0) {
        value = TYPE.HOUR_MINUTE_NIGHTLY.format(value);
        value = self._textToStyle(value, styleHoure);
        obj.parametres["@absHeureFin"] = value;
      }
      //@absLibelleMotif
      var value = obj.parametres["@absLibelleMotif"];
      if (!STR.isBlank(value)) {
        value = self._textToStyle(value, styleHoure);
        obj.parametres["@absLibelleMotif"] = value;
      }
      //@absDateDebut
      var value = obj.parametres["@absDateDebut"];
      if (!STR.isBlank(value)) {
        value = TYPE.DATE.format(value, TYPE._getFormatByCode("DATE_A"));
        value = self._textToStyle(value, styleDate);
        obj.parametres["@absDateDebut"] = value;
      }
      //@absDateFin
      var value = obj.parametres["@absDateFin"];
      if (!STR.isBlank(value)) {
        value = TYPE.DATE.format(value, TYPE._getFormatByCode("DATE_A"));
        value = self._textToStyle(value, styleDate);
        obj.parametres["@absDateFin"] = value;
      }
      //@absHeureDuree
      var value = obj.parametres["@absHeureDuree"];
      if (value > 0) {
        value = TYPE.DURATION.HOUR_MINUTE.format(value);
        value = self._textToStyle(value, styleHoure);
        obj.parametres["@absHeureDuree"] = value;
      }
    } else if (obj.type == "B") {
      //@bdgDate
      var value = obj.parametres["@bdgDate"];
      if (!STR.isBlank(value)) {
        value = TYPE.DATE.format(value, TYPE._getFormatByCode("DATE_A"));
        value = self._textToStyle(value, styleDate);
        obj.parametres["@bdgDate"] = value;
      }
      //@bdgHeure
      var value = obj.parametres["@bdgHeure"];
      if (!STR.isBlank(value)) {
        value = self._arrayHouresToStr(value);
        value = self._textToStyle(value, styleHoure);
        obj.parametres["@bdgHeure"] = value;
      }
    } else if (obj.type == "R") {
      //@regValeur
      var value = obj.parametres["@regValeur"];
      if (!STR.isBlank(value)) {
        value = TYPE.LONG.format(value, obj.parametres["@regFormat"]);
        value = self._textToStyle(value, styleHoure);
        obj.parametres["@regValeur"] = value;
      }
      //@regDateDebut
      var value = obj.parametres["@regDateDebut"];
      if (!STR.isBlank(value)) {
        value = TYPE.DATE.format(value, TYPE._getFormatByCode("DATE_A"));
        value = self._textToStyle(value, styleDate);
        obj.parametres["@regDateDebut"] = value;
      }
      //@regDateFin
      var value = obj.parametres["@regDateFin"];
      if (!STR.isBlank(value)) {
        value = TYPE.DATE.format(value, TYPE._getFormatByCode("DATE_A"));
        value = self._textToStyle(value, styleDate);
        obj.parametres["@regDateFin"] = value;
      }
      //@absLibelleMotif
      var value = obj.parametres["@regLibelleMotif"];
      if (!STR.isBlank(value)) {
        value = self._textToStyle(value, styleHoure);
        obj.parametres["@regLibelleMotif"] = value;
      }
    } else if (obj.type == "RA") { //new case
      //@recapDateDebut
      var value = obj.parametres["@recapDateDebut"];
      if (!STR.isBlank(value)) {
        value = TYPE.DATE.format(value, TYPE._getFormatByCode("DATE_A"));
        value = self._textToStyle(value, styleDate);
        obj.parametres["@recapDateDebut"] = value;
      }
      //@recapDateFinDateFin
      var value = obj.parametres["@recapDateFin"];
      if (!STR.isBlank(value)) {
        value = TYPE.DATE.format(value, TYPE._getFormatByCode("DATE_A"));
        value = self._textToStyle(value, styleDate);
        obj.parametres["@recapDateFin"] = value;
      }
    } else if (obj.type == "EG") {
      var value = obj.parametres["@EvtGenDateDebut"];
      if (!STR.isBlank(value)) {
        value = TYPE.DATE.format(value, TYPE._getFormatByCode("DATE_A"));
        value = self._textToStyle(value, styleDate);
        obj.parametres["@EvtGenDateDebut"] = value;
      }

      var value = obj.parametres["@EvtGenDateFin"];
      if (!STR.isBlank(value)) {
        value = TYPE.DATE.format(value, TYPE._getFormatByCode("DATE_A"));
        value = self._textToStyle(value, styleDate);
        obj.parametres["@EvtGenDateFin"] = value;
      }

      //var value = obj.parametres["@EvtGenlntitule"];
      //if(!STR.isBlank(value)) {
      //	value = self._textToStyle(value,styleHoure);
      //	obj.parametres["@regLibelleMotif"] = value;
      //}
      var value = obj.parametres["@EvtGenHeureDebut"];
      if (!STR.isBlank(value)) {
        value = TYPE.DURATION.HOUR_MINUTE.format(value);
        value = self._textToStyle(value, styleDate);
        obj.parametres["@EvtGenHeureDebut"] = value;
      }

      var value = obj.parametres["@EvtGenHeurefin"];
      if (!STR.isBlank(value)) {
        value = TYPE.DURATION.HOUR_MINUTE.format(value);
        value = self._textToStyle(value, styleDate);
        obj.parametres["@EvtGenHeurefin"] = value;
      }

      var value = obj.parametres["@EvtGenHeureDuree"];
      if (!STR.isBlank(value)) {
        value = TYPE.DURATION.HOUR_MINUTE.format(value);
        value = self._textToStyle(value, styleDate);
        obj.parametres["@EvtGenHeureDuree"] = value;
      }

    } else if (obj.type == "AC") {

      var value = obj.parametres["@actDateDebut"];
      if (!STR.isBlank(value)) {
        value = TYPE.DATE.format(value, TYPE._getFormatByCode("DATE_A"));
        value = self._textToStyle(value, styleDate);
        obj.parametres["@actDateDebut"] = value;
      }

      var value = obj.parametres["@actDateFin"];
      if (!STR.isBlank(value)) {
        value = TYPE.DATE.format(value, TYPE._getFormatByCode("DATE_A"));
        value = self._textToStyle(value, styleDate);
        obj.parametres["@actDateFin"] = value;
      }

      var value = obj.parametres["@actUniteDeb"];
      if (!STR.isBlank(value)) {
        value = self._textToStyle(value, styleHoure);
        obj.parametres["@actUniteDeb"] = value;
      }

      var value = obj.parametres["@actUniteFin"];
      if (!STR.isBlank(value)) {
        value = self._textToStyle(value, styleHoure);
        obj.parametres["@actUniteFin"] = value;
      }

      var value = obj.parametres["@messageDeb"];
      if (!STR.isBlank(value)) {
        value = self._textToStyle(value, styleHoure);
        obj.parametres["@messageDeb"] = value;
      }

      var value = obj.parametres["@messageFin"];
      if (!STR.isBlank(value)) {
        //value = TYPE.DURATION.HOUR_MINUTE.format(value);
        value = self._textToStyle(value, styleHoure);
        obj.parametres["@messageFin"] = value;
      }

      var value = obj.parametres["@actHeureDebut"];
      if (!STR.isBlank(value)) {
        value = TYPE.HOUR_MINUTE.format(value);
        value = self._textToStyle(value, styleHoure);
        obj.parametres["@actHeureDebut"] = value;
      }

      var value = obj.parametres["@actHeureFin"];
      if (!STR.isBlank(value)) {
        value = TYPE.HOUR_MINUTE.format(value);
        value = self._textToStyle(value, styleHoure);
        obj.parametres["@actHeureFin"] = value;
      }

      var value = obj.parametres["@actDuree"];
      if (!STR.isBlank(value)) {
        value = TYPE.DURATION.HOUR_MINUTE_24.format(value).trim();
        value = self._textToStyle(value, styleHoure);
        obj.parametres["@actDuree"] = value;
      } else {
        obj.parametres["@actDuree"] = " ";
      }

      var value = obj.parametres["@actPourcentage"];
      if (!STR.isBlank(value)) {
        //value = TYPE.DURATION.HOUR_MINUTE.format(value);
        value = TYPE.PERCENTAGE_0.format(value);
        value = self._textToStyle(value, styleHoure);
        obj.parametres["@actPourcentage"] = value;
      } else {
        obj.parametres["@actPourcentage"] = "-";
      }

      var value = obj.parametres["@actLibelle"];
      if (!STR.isBlank(value)) {
        value = self._textToStyle(value, styleHoure);
        obj.parametres["@actLibelle"] = value;
      } else {
        obj.parametres["@actLibelle"] = "-";
      }

      var value = obj.parametres["@actCode"];
      if (!STR.isBlank(value)) {
        value = self._textToStyle(value, styleHoure);
        obj.parametres["@actCode"] = value;
      } else {
        obj.parametres["@actCode"] = "-";
      }
    }
    return obj;
  },

  _arrayHouresToStr: function(args) {
    var msgProcessed = "";

    _.each(args, function(value) {
      value = TYPE.HOUR_MINUTE.format(value, "&&h&&");
      msgProcessed = msgProcessed + value + ",";
    });
    //quit the last letter
    msgProcessed = msgProcessed.substring(0, msgProcessed.length - 1);

    return msgProcessed;
  },

  _textToStyle: function(txt, style) {
    var newTxt = "";
    newTxt = "<span class='" + style + "'>" + txt + "</span>";

    return newTxt;
  },

  _initTable: function() {
    var tableModel = new DataGridModel({ coll: new MesDemandesColl() });

    var tblColumns = [
      { title: "Libelle", property: "libelle", width: 650 },
      { title: "Statut", property: "statut", width: 200 }
    ];
    var table = new DataGridView({
      id: "bdem_table",
      columns: tblColumns,
      model: tableModel,
      title: i18n.t('common:bdem.mesDemandes_title'),
      showFilteredRowsInTitle: false,
      gridHeightType: "auto",
      maxVisibleRows: 10,
      removeGridStyle: true,
      selectable: false
    });
    tableModel.coll.usePopulation = this.usePopulation;
    var self = this;

    table.cellRenderers["libelle"] = function(model) {
      return self._libelleFormated(model);
    };

    table.cellRenderers["statut"] = function(model) {
      return self._statutFormated(model);
    };
    return table;
  },

  _libelleFormated: function(obj) {
    var attributes = obj.toJSON();
    //render the date
    var dateFormated = TYPE.DATE.format(attributes.datedeb) + " - " + TYPE.DATE.format(attributes.datefin);

    attributes.datedeb = dateFormated;

    //render the libelle
    var libelle = "";
    var commentaire = "";
    var strFormated = "";

    //format the values of model
    attributes = this._formatedParametres(attributes, this.styleDate, this.styleHoure);

    libelle = STR.buildMessageParametres(attributes.libelle, attributes.parametres);
    libelle = this._textToStyle(libelle, this.styleLabel);

    if (attributes.commentaire == "X") {
      commentaire = i18n.t('common:bdem.demandeSuppression');
      commentaire = this._textToStyle("(" + commentaire + ")", this.demandeSuppression);
      strFormated = libelle + " " + commentaire;
    } else {
      if (attributes.type != "B") {
        commentaire = STR.buildMessageParametres(attributes.commentaire, attributes.parametres);
        if (!STR.isBlank(commentaire)) {
          if (attributes.commentaire == "D") {
            var commentaire = "";
            commentaire = this._textToStyle(commentaire, this.styleLabel);
            strFormated = libelle;
          } else {
            commentaire = this._textToStyle(commentaire, this.styleLabel);
            strFormated = libelle + " " + commentaire;
          }
        } else {
          strFormated = libelle;
        }
      } else {
        strFormated = libelle;
      }
    }

    //fix the style width the "(" and ")"
    strFormated = strFormated.replace("(", this._textToStyle("(", this.styleHoure));
    strFormated = strFormated.replace(")", this._textToStyle(")", this.styleHoure));

    return strFormated;
  },

  _statutFormated: function(obj) {
    //render the state
    var ls_statutFormated = "";
    var attributes = obj.toJSON().statut;

    if (attributes.code == "I") {
      ls_statutFormated = this._textToStyle(attributes.libelle, this.styleEnCours);
    } else if (attributes.code == "D") {
      ls_statutFormated = this._textToStyle(attributes.libelle, this.styleDemande);
    } else {
      ls_statutFormated = this._textToStyle(attributes.libelle, this.styleHoure);
    }

    return ls_statutFormated;
  }
});
