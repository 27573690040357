import { BaseModel } from '../../models/base.model.js';

/**
 * Model used to retrieve the licences
 *
 */
export var LoginLicenseModel = BaseModel.extend({
  /**
   * Add this to have Outline
   *
   */

  habilitationV: "N",
  habilitationG: "N",
  usecase: "app",

  defaults: {
    libelle: ""
  },

  url: Configuration.restRoot + "/rest/authentification/licence",

  initialize: function(options) {
    if (options && options.param) {
      this.param = options.param;
    }
  }
});