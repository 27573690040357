import { ZoneView } from './zone.view.js';

/**
 * View to paint the body of the home. It stores a reference to every
 * zoneView and manages the swapping of zone hiding or
 * showing the zone.
 */
export var BodyView = Backbone.View.extend({

  initialize: function() {

    this.zViews = {};
    this.model.on("change:zone", this._zonesSwapping, this);
  },

  _zonesSwapping: function() {
    var oldZone = this.model.previous("zone");
    var newZone = this.model.get("zone");
    if (oldZone !== "" && !_.isUndefined(oldZone)) {
      this.zViews[oldZone].$el.hide();
    }
    if (newZone !== "" && !_.isUndefined(newZone)) {
      this.zViews[newZone].$el.show();
    }

    this.model.trigger("changed:zone", newZone);
  },

  render: function() {
    _.each(this.model.configuration.get("confignav").get("zones"), function(zone) {
      var zView = new ZoneView({
        id: "phx-zone-" + zone.id,
        model: this.model,
        zJSON: zone
      });
      this.zViews[zone.id] = zView;
      this.$el.append(zView.render().el);
    }, this);
    return this;
  }

});