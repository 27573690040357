import { BaseModel } from 'core/models/base.model.js';

import { i18n } from '../../../../i18n.js';
import { STR } from '../../../../utils/str.js';

/**
 * Model for load the elements with the droids table (table rigth)
 */
export var DelegationsTableModel = BaseModel.extend({

  usecase: "droitsdel",

  urlRoot: Configuration.restRoot + "/rest/delegation",

  /**
   * Default data model
   *		id : null,
   *		code : "",
   *		datedeb : "",
   *		datefin : "",
   *		retro : null,
   *		datetrace : "",
   *		heuretrace : "",
   *		profil : "",
   *		delegue :
   *		{
   *			matricule : null,
   *			matriculeaux : "",
   *			nom : "",
   *			prenom : "",
   *			utilcode : "",
   *			utilnom : ""
   *		},
   *		auteur :
   *		{
   *			matricule : null,
   *			matriculeaux : "",
   *			nom : "",
   *			prenom : "",
   *			utilcode : "",
   *			utilnom : ""
   *		},
   *		delegant :
   *		{
   *			matricule : null,
   *			matriculeaux : "",
   *			nom : "",
   *			prenom : "",
   * 			utilcode : "",
   *			utilnom : ""
   *		}
   * }
   */
  defaults: {
    "id": null,
    "code": "",
    "datedeb": "",
    "datefin": "",
    "retro": null,
    "datetrace": "",
    "heuretrace": "",
    "profil": "",
    "delegue": {
      "matricule": null,
      "matriculeaux": "",
      "nom": "",
      "prenom": "",
      "utilcode": "",
      "utilnom": ""
    },
    "auteur": {
      "matricule": null,
      "matriculeaux": "",
      "nom": "",
      "prenom": "",
      "utilcode": "",
      "utilnom": ""
    },
    "delegant": {
      "matricule": null,
      "matriculeaux": "",
      "nom": "",
      "prenom": "",
      "utilcode": "",
      "utilnom": ""
    }

  },

  validate: function(attrs) {
    var errors = {};

    if (!_.isEmpty(errors)) {
      var errorMap = {
        errorValidation: STR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
    return null;
  },

  /**
   * Parsed the results returned by the REST,
   * Copy the return code in the object id
   */
  parse: function(response) {
    if (response) {
      response.id = response.code;
    }
    return response;
  }

});