import { ProfilesColl } from './profiles.collection.js';
import { ReadOnlyModel } from 'core/models/readOnly.model.js';
import { STR } from '../../../../utils/str.js';

/**
 * Profile model
 */
export var ProfileModel = ReadOnlyModel.extend({

  parse: function(response) {
    if (response.id === undefined) {
      if (response.code !== undefined) {
        this.id = response.code;
      }

      if (response.codedele !== undefined) {
        this.id = response.codedele;
      }

    }

    response.enfants = new ProfilesColl(response.enfants, { parse: true });

    return response;
  },

  profilesNumber: function() {
    var result = 0;
    if (STR.isBlank(this.get("codedele"))) {
      result = 1;
    }
    result += this.get("enfants").profilesNumber();
    return result;
  },

  getProfile: function(code) {
    var element = this.get("enfants").getProfile(code);
    return element;
  }
});