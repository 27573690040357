import { ReadOnlyModel } from 'core/models/readOnly.model.js';
import { BaseCollection } from 'core/models/base.collection.js';

export var EvenementsGeneriqueModel = BaseCollection.extend({
  urlRoot: Configuration.restRoot + "/rest/brique/respo/demande/evtgen/detail",

  model: ReadOnlyModel,

  url: function() {
    return this.urlRoot;
  },

  parse: function(response) {
    return response;
  }
});