import { BaseModel } from 'core/models/base.model.js';
import { i18n } from '../../../../i18n.js';
import { STR } from '../../../../utils/str.js';

/**
 * Parametrage Model
 */
export var ParametrageModel = BaseModel.extend({

  initialize: function(attributes) {

  },

  url: function() {
    var id = this.isNew() === true ? "" : encodeURIComponent(this.id);
    var url = "";
    if (!STR.isBlank(this.formerID)) {
      url = Configuration.restRoot + "/rest/personnalisation/definition/brique/rescpt/" + encodeURIComponent(this.formerID);
    } else {
      url = Configuration.restRoot + "/rest/personnalisation/definition/brique/rescpt/" + id;
    }
    if (!STR.isBlank(this.action) && this.action == "GET" && !STR.isBlank(this.espaceid)) {
      url += "?espaceid=" + encodeURIComponent(this.espaceid);
    }
    return url;
  },

  defaults: {
    "id": null,
    "userid": "",
    "codebrique": "",
    "libelle": "",
    "typebrique": "",
    "visibilite": false,
    "datereference": "J",
    "typegroupe": "BR_CPT", //TODO change to dynamic for v > 2.1
    "active": false
  },

  validate: function(attrs) {

    var errors = {};

    if (STR.isBlank(attrs.codebrique)) {
      errors.codebrique = i18n.t('common:required', { "0": i18n.t('common:parametragebresultats.code_label') });
    }

    if (STR.isBlank(attrs.libelle)) {
      errors.libelle = i18n.t('common:required', { "0": i18n.t('common:parametragebresultats.titre_label') });
    }

    if (STR.isBlank(attrs.datereference)) {
      errors.datereference = i18n.t('common:required', { "0": i18n.t('common:parametragebresultats.date_label') });
    }
    if (!_.isEmpty(errors)) {
      var errorMap = {
        errorValidation: STR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
  },

  parse: function(response) {

    if (response.userid && response.codebrique) {
      response.id = response.userid + "," + response.codebrique;
    }
    return response;
  }
});