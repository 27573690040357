import { HEADERS } from '../../../utils/headers.js';
import { STR } from '../../../utils/str.js';

export var SelecteurCheminItemModel = Backbone.Model.extend({
  /**
   * Add this to have Outline
   *
   */
  dummy: "",
  /**
   * urlRoot
   */
  urlRoot: Configuration.restRoot + "/rest/configuration",

  /**
   * Constructor
   * Paginated collection for the application. Manage also filtering and sorting
   */
  initialize: function(params) {
    params = params || {};
    /**
     * Data used to form URL
     */
    this.data = "structid=" + params.structid;
    this.data += "&datedebut=" + params.date;
    this.data += "&datefin=" + params.date;
    this.data += "&multiple=false&intermediaire=true&partial=true";
    if (!STR.isBlank(params.nonrattachable)) {
      this.data += "&nonrattachable=" + params.nonrattachable;
    }
    if (!STR.isBlank(params.nonrattactiv)) {
      this.data += "&nonrattactiv=" + params.nonrattactiv;
    }
    if (!STR.isBlank(params.chemin)) {
      this.data += "&chemin=" + params.chemin;
    // Début spécifique VDP
    } else if (!STR.isBlank(params.elementCodef)) {
        this.data += "&elementcodef=" + params.elementCodef;
    // Fin spécifique VDP
    } else {
      this.data += "&elementcode=" + params.elementCode;
    }
    this.urlRoot = Configuration.restRoot + "/rest/cheminstructure/simple?" + this.data;
  },

  /**
   * Process to manage the response
   */
  parse: function(response) {
    if (STR.isBlank(response.id) && !STR.isBlank(response.code)) {
      response.id = response.code;
    }

    return response;
  },

  fetch: function(options) {
    var options = options || {};

    if (this.ecran && !this.habContext) {
      options.headers = HEADERS.habilitationContext(this.ecran, "N", "");
    }
    return Backbone.Model.prototype.fetch.call(this, options);
  }
});