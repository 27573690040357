import { BaseModel } from '../../../../../core/models/base.model.js';

export var CollaborateursModel = BaseModel.extend({

  defaults: {
    "matric": "",
    "nom": "",
    "prenom": "",
    "matricaux": ""
  },

  urlRoot: Configuration.restRoot + "/rest/cycle/collaborateurs/",

  parse: function(response) {
    if (response.id === undefined && response.matric !== undefined) {
      response.id = response.matric;
    }
    return response;
  }
});