import { CombosColl } from 'core/components/combo/combos.collection.js';
import { PaginatedCollection } from 'core/models/paginated.collection.js';
import { PopulationComboModel } from './populationCombo.model.js';

/**
 * Collection for Combo 'Critere' in 'Planning par criteres'
 */
export var CritidComboColl = CombosColl.extend({

  model: PopulationComboModel,

  fetch: function(options) {
    options.headers = { "gfi-ecran": '"RES_PLANNING"' };
    return PaginatedCollection.prototype.fetch.call(this, options);
  }
});