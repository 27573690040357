import { LOG } from '../../../utils/log.js';

export var DataGridCellThView = Backbone.View.extend({

  dummy: "",
  tagName: "th",

  /**
   * Event when click on a column header.
   */
  /**
   * Event when click on a checkbox on the header of the multisel column.
   */
  events: {
    "click": "_clickListener",
    "click .ui-resizable-handle": function() {
      // If I'm trying to resize and I do a silly click then it nothing happens.
      return false;
    },
    "click .phx-tristate-box": "_selectUnselectAll"
  },

  /**
   * View of the Headers Cells.
   */
  initialize: function(options) {
    this.text = options.text;
    this.hIndex = options.hIndex;
    this.cellIndex = options.cellIndex;
    this.last = options.last;
    this.dataGrid = options.dataGrid;
    this.columnCode = options.columnCode;
    this.sortButton = null;
    this.sortButtonIcon = null;
    this.multiselection = options.multiselection ? true : false;
    this.listenTo(this.dataGrid.model, "rows:checked", this._manageChecking);
    this.minWidth = options.minWidth || 45;
    this.minWidthMode = options.minWidthMode;
  },

  /**
   * Manages the Header click and sorting if is enabled for that column.
   */
  _clickListener: function() {
    if (this.columnCode !== "phx-last") {
      var currentColumn = this.dataGrid.currentVue._columns[this.columnCode];

      if (currentColumn.sortable) {
        if (!_.isNull(currentColumn.sort)) {
          this.sortButton.toggleClass("phx-sort-state-desc");
          this.sortButtonIcon.toggleClass("ui-icon-triangle-1-s");
          this.sortButton.toggleClass("phx-sort-state-asc");
          this.sortButtonIcon.toggleClass("ui-icon-triangle-1-n");
          currentColumn.sort = !currentColumn.sort;
        } else {
          var otherColumns = null;

          this.dataGrid.currentVue._columns[this.columnCode].sort = true;
          this.sortButton.show();
          otherColumns = _.difference(_.keys(this.dataGrid.currentVue._columns), [this.columnCode]);
          _.each(otherColumns, function(column) {
            this.dataGrid.currentVue._columns[column].sort = null;
          }, this);
        }
      }
      this.trigger("click", this.columnCode);
    }
  },

  /**
   * Hide the Sorting button.
   */
  hideSortButton: function() {
    if (this.sortButton && _.isNull(this.dataGrid.currentVue._columns[this.columnCode].sort)) {
      this.sortButton.hide();
      this.sortButton.addClass("phx-sort-state-asc");
      this.sortButtonIcon.addClass("ui-icon-triangle-1-n");
      this.sortButton.removeClass("phx-sort-state-desc");
      this.sortButtonIcon.removeClass("ui-icon-triangle-1-s");
    }
  },

  /**
   * Makes the columns resizeables.
   */
  resizable: function(brother) {
    var column = $(this.el).find("div");
    var datagrid = this.dataGrid;
    var self = this;

    column.resizable({
      handles: "e",
      minWidth: self.minWidth,
      resize: function(event, ui) {
        var newWidth = ui.size.width + "px";
        ui.helper.parent().css("width", newWidth);
        $(brother.el).width(newWidth);
      },
      stop: function(event, ui) {
        var difference = ui.size.width - ui.originalSize.width;
        var minWidhtText = $(".phx-grid-scroll table.ui-grid-table", datagrid.el).css("min-width");
        var minWidth = parseInt(minWidhtText.substring(0, minWidhtText.length - 2)) + difference;
        datagrid._setTableMinWidth(minWidth);
        if (self.minWidthMode) {
          var parentModified = $(ui.element).closest('th').toArray();
          var headerNodesSelection = $(".phx-grid-header-scroll table", datagrid.el).find("tr th").toArray();

          var index = _.findIndex(headerNodesSelection, function(item) {
            return (parentModified[0].isEqualNode(item));
          })
          if (index !== -1) {
            $(".phx-grid-scroll table.ui-grid-table", datagrid.el).find("thead tr th").get(index).style.minWidth = ui.size.width + 'px';
          }

        }
        //				$("table",datagrid.el).css("min-width",minWidth+"px");
        LOG.info(difference + " " + minWidhtText + " " + minWidth + "px");
      }
    });
  },

  /**
   * Renders the header view.
   */
  render: function() {
    var content = $("<div></div>");

    $(this.el).addClass("phx-th" + this.hIndex + this.cellIndex + " ui-grid-th");
    if (this.multiselection) {
      content.append("<div class='phx-tristate-box phx-tristate-unchecked'></div>");
    } else {
      var alignment = (this.dataGrid.currentVue._columnsAlign && this.dataGrid.currentVue._columnsAlign[this.columnCode]) ? this.dataGrid.currentVue._columnsAlign[this.columnCode] : "left";

      switch (alignment) {
        case "right":
          content.css("text-align", "right");
          break;
        case "center":
          content.css("text-align", "center");
          break;
        default:
          content.css("text-align", "left");
          break;
      }
      content.append($("<span class='title' style=\"display:inline-block;\">" + _.result(this, "text") + "</span>"));
      content.css("width", "100%");
      this.sortButton = $("<span></span>");
      this.sortButton.css("border", "none");
      this.sortButton.addClass("phx-sort-button");
      this.sortButtonIcon = $("<span></span>");
      this.sortButtonIcon.addClass("ui-icon");
      this.sortButton.append(this.sortButtonIcon);
      content.append(this.sortButton);
      this.sortButton.hide();
      this.sortButton.addClass("phx-sort-state-asc");
      this.sortButtonIcon.addClass("ui-icon-triangle-1-n");
    }
    $(this.el).append(content);
    if (this.last !== true) {
      $(this.el).addClass("ui-grid-th-RightBorder");
    } else {
      content.css("position", "relative");
    }
    // Manage the selection checkbox
    this._manageChecking();
    //accessibility
    this.$el.attr({ "role": "columnheader" });
    //Set all inputs of the header to -1 to avoid tab selection
    this.$el.find("a").is("a") ? this.$el.find("a").attr("tabindex", "-1") : null;
    this.$el.find("select").is("select") ? this.$el.find("select").attr("tabindex", "-1") : null;
    this.$el.find(":input").is(":input") ? this.$el.find("input").attr("tabindex", "-1") : null;
    this.$el.find("button").is("button") ? this.$el.find("button").attr("tabindex", "-1") : null;

    return this;
  },

  /**
   * Manages when click on the header checkbox on multiselection grid.
   */
  _manageChecking: function() {
    if (this.multiselection) {
      if (this.dataGrid.model.multiselectColl.length === 0) {
        this._setTristateUnchecked();
      } else if (this.dataGrid.model.multiselectColl.length !== this.dataGrid.model.coll.totalRecords) {
        this._setTristateUndefined();
      } else {
        this._setTristateChecked();
      }
    }
  },

  /**
   * Sets the Tristate checkbox to Checked state.
   */
  _setTristateChecked: function() {
    var jQtarget = this.$el.find(".phx-tristate-box");

    jQtarget.html('<span class="ui-icon ui-icon-closethick"></span>');
    jQtarget.addClass("phx-tristate-checked");
    jQtarget.removeClass("phx-tristate-unchecked");
    jQtarget.removeClass("phx-tristate-undefined");
  },

  /**
   * Sets the Tristate checkbox to Unchecked state.
   */
  _setTristateUnchecked: function() {
    var jQtarget = this.$el.find(".phx-tristate-box");

    jQtarget.html('');
    jQtarget.removeClass("phx-tristate-checked");
    jQtarget.addClass("phx-tristate-unchecked");
    jQtarget.removeClass("phx-tristate-undefined");
  },

  /**
   * Sets the Tristate checkbox to Undefined state.
   */
  _setTristateUndefined: function() {
    var jQtarget = this.$el.find(".phx-tristate-box");

    jQtarget.html('<span class="ui-icon ui-icon-minusthick"></span>');
    jQtarget.removeClass("phx-tristate-checked");
    jQtarget.removeClass("phx-tristate-unchecked");
    jQtarget.addClass("phx-tristate-undefined");
  },

  /**
   * Perform a Selection/Unselection on all rows in a multiselectable grid.
   */
  _selectUnselectAll: function(event) {
    var state = $(event.currentTarget).hasClass("phx-tristate-unchecked");

    if (state) {
      this.dataGrid.selectAllRows();
    } else {
      this.dataGrid.clearSelectedRows();
    }
    this.dataGrid.model.trigger("tristate:checked", state);
  },

  /**
   * Set the text of the header
   *
   */
  setHeaderTitle: function(title) {
    var $span = $("span.title", this.$el);

    if ($span && $span.length > 0) {
      $span.html(title);
    }
  }
});