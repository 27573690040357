module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<fieldset class="gererprets-detail">\n  <div class="phx-formTable">\n    <div class="phx-formErrors ui-state-error"></div>\n    <div>\n\n      <div class="gererpret-content">\n        <div class="gererpret-form-inputs">\n          <span class="journeeSimulated ui-phx-plan-simule-en-cours" style="display:none"></span><br>\n          <div class="beginning-form">\n            <div class="imbrique-header">\n              <span class="pret-header"></span>\n              <br>\n              <span class="periode-header"></span>\n              <span class="statutPretLibelle"></span>\n              <br>\n              <span class="description-header"></span>\n            </div>\n            <div class="popup-header">\n              <br>\n              <span class="gererprets-utilisateur ctxGestionCollab"></span>\n              <br>\n              <span class="statutPretLibelle"></span>\n              <br>\n              <span class="description-header"></span>\n            </div>\n            <div>\n              <label for="type.code" class="required">'+
((__t=(i18n.t('common:gererprets.type')))==null?'':__t)+
'</label>\n              <span class="cmbType"></span>\n            </div>\n            <div>\n              <label for="perimetre.code" class="required">'+
((__t=(i18n.t('common:gererprets.perimetre')))==null?'':__t)+
'</label>\n              <span class="cmbPerimetre"></span>\n            </div>\n            <div class="liste-structures"></div>\n          </div>\n          <br>\n          <span class="typeSaisie"></span>\n          <br>\n          <span class="modeSaisie"></span>\n          <br>\n          <div class="end-form">\n            <label for="motif.code" class="required">'+
((__t=(i18n.t('common:gererprets.motif')))==null?'':__t)+
'</label>\n            <span class="cmbMotif" title="'+
((__t=(i18n.t('common:gererprets.motif_title')))==null?'':__t)+
'"></span>\n            <br>\n            <div class="originActif">\n              <label for="origine.code">'+
((__t=(i18n.t('common:gererprets.origine')))==null?'':__t)+
'</label>\n              <span class="cmbOrigine" title=""></span>\n            </div>\n            <div class="gererprets-data">\n              <div class="data">\n                <label for="libelle">'+
((__t=(i18n.t('common:gererprets.libelle')))==null?'':__t)+
'</label>\n                <input type="text" class="libelle" value="" maxlength="30" size="30" />\n                <span class="valeur-error-container"></span>\n                <br>\n                <label for="collabremplace">'+
((__t=(i18n.t('common:gererprets.collabRef')))==null?'':__t)+
'</label>\n                <input type="text" class="collabremplace ui-phx-ihm-texte-donnees-alternatif" value="" maxlength="30" size="30" />\n                <br>\n                <div class="comment_pret_div">\n                  <label for="commentaire_pret">'+
((__t=(i18n.t('common:gererprets.info_comp')))==null?'':__t)+
'</label>\n                  <textarea class="commentaire_pret" maxlength="200" cols="30" rows="4" style="resize:none"></textarea>\n                </div>\n                <div class="commentaire_div">\n                  <label for="commentaire">'+
((__t=(i18n.t('common:gererprets.comment')))==null?'':__t)+
'</label>\n                  <textarea class="commentaire" title="'+
((__t=(i18n.t('common:gererprets.comment_title')))==null?'':__t)+
'" maxlength="200" cols="30" rows="4" style="resize:none"></textarea>\n                </div>\n              </div>\n            </div>\n            <br/>\n            <span class="gererprets-piecesjointes"></span>\n            <br>\n            <span class="gererprets-infocomp"></span>\n\n          </div>\n          <span class="gererprets-wkfhisto"></span>\n        </div>\n      </div>\n    </div>\n  </div>\n  <ul class="validerListMenu menuContent">\n  </ul>\n</fieldset>';
}
return __p;
};
