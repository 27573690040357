module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="phx-referentiel-wrap">\n  <input class="phx-referentiel-input" type="text"></input>\n  <span class="phx-referentiel-button ui-icon ui-icon-search"></span>\n  ';
if(showIconPlus && showIconPlus === true){
__p+='\n  <span class="funcColSpan phx-icon phx-icon-planresp-ajouter"></span>\n  ';
 } 
__p+='\n</div>\n<div class="phx-referentiel-selection"></div>\n';
}
return __p;
};
