module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="phx-menu-badgeages"></div>\n<div class="phx-menu-zones-buttons">\n  <button type="button" class="agenda uix-button" value="agenda">\n    <span class="text-button">'+
((__t=(i18n.t('common:bvMenu_agenda')))==null?'':__t)+
'</span>\n  </button>\n  <button type="button" class="consulter uix-button" value="consulter">\n    <span class="text-button">'+
((__t=(i18n.t('common:bvMenu_consulter')))==null?'':__t)+
'</span>\n  </button>\n  <button type="button" class="absences uix-button" value="absences">\n    <span class="text-button">'+
((__t=(i18n.t('common:bvMenu_absences')))==null?'':__t)+
'</span>\n  </button>\n  ';
 if(!hideCET){
__p+='\n  <button type="button" class="cet uix-button" value="cet">\n    <span class="text-button">'+
((__t=(i18n.t('common:bvMenu_cet')))==null?'':__t)+
'</span>\n  </button>\n  ';
}
__p+='\n\n</div>\n';
}
return __p;
};
