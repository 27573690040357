module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<fieldset class="gererbadgeages-popup">\n  <div class="phx-formTable">\n    <div class="phx-formErrors ui-state-error"></div>\n    <div>\n      <span class="spanComment"></span>\n      <br/><br/>\n      <div class="blockBadgeCommentaire">\n        <label for="commentaire">'+
((__t=(i18n.t('common:gererbadgeages.comment')))==null?'':__t)+
'</label>\n        <textarea class="commentaire" maxlength="60" cols="30" rows="3"></textarea>\n      </div>\n    </div>\n    <div class="gererbadgeages-buttons">\n      <button type="button" class="btnOK" value="btnOK">'+
((__t=(i18n.t('common:gererbadgeages.btnOK')))==null?'':__t)+
'</button>\n      <button type="button" class="btnAnnuler" value="btnAnnuler">'+
((__t=(i18n.t('common:gererbadgeages.btnAnnuler')))==null?'':__t)+
'</button>\n    </div>\n  </div>\n</fieldset>\n';
}
return __p;
};
