import { GLOBAL_DATA } from '../../../../../../globalData.js';
import { ReadOnlyModel } from '../../../../../../core/models/readOnly.model.js';

export var GererpiecesjointesWorkflow = ReadOnlyModel.extend({
  gererPiecesJointes: null,

  initialize: function() {

  },

  /**
   * Set up the workflow. Link the models between them
   *
   */
  setUp: function(callback) {
    this.pDiversIdCollabModel = GLOBAL_DATA.paramDivers.get("idCollab");
    this.gererPiecesJointes.diver = this.pDiversIdCollabModel.get("valeur");

    if (callback) {
      callback();
    }
  }
});
