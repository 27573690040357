import { DIVERS } from '../../../../utils/divers.js';
import { GLOBAL_DATA } from '../../../../globalData.js';
import { i18n } from '../../../../i18n.js';
import { objs } from '../../../../objectsRepository.js';
import { ProfilesColl } from '../models/profiles.collection.js';
import { STR } from '../../../../utils/str.js';
import { UTILS } from '../../../../utils/utils.js';
import { ProfilutilisateurWorkflow } from '../models/profilutilisateur.workflow.js';
import TPL_profilutilisateur_profileMenuItem from '../profileMenuItem.tpl.html';
import TPL_profilutilisateur_profileRecu from '../profileRecu.tpl.html';
import TPL_profilutilisateur_profilesMenu from '../profilesMenu.tpl.html';

export var ProfilUtilisateurView = Backbone.View.extend({

  initialize: function() {
    this.workflow = new ProfilutilisateurWorkflow();

    this.template = TPL_profilutilisateur_profilesMenu;
    this.templateMenuItem = TPL_profilutilisateur_profileMenuItem;
    this.templateMenuRecu = TPL_profilutilisateur_profileRecu;

    this.isVisible = false;
    this.currentSelected = { code: null };
  },

  events: {
    "click .phx-profiles-menu-icon": "_showList",
    "click .phx-profiles-menu-header": "_showList",
    "click input.tous": "_clickTousCheckbox",
    "click .menuProfileItem": "_changeProfilEvent",
    "click .phx-collapsable-icon": "_collapse",
    "click .phx-expandable-icon": "_expand",
    "click .defaut:not(.ui-state-disabled)": "_setAsDefault"
  },

  render: function() {
    var json = { "i18n": i18n, tousCheck: DIVERS.get("prfTous") };
    this.$el.append(this.template(json));

    this.$el.addClass("ui-phx-color-gris_7");

    var defaultProfil;
    if (!_.isUndefined(objs.appRt.workflow.configuration) &&
      !_.isUndefined(objs.appRt.workflow.configuration.get("profildefaut"))) {
      defaultProfil = objs.appRt.workflow.configuration.get("profildefaut");
      GLOBAL_DATA.rights.profile = defaultProfil;
    } else {
      defaultProfil = DIVERS.get("prfUti");
    }

    if (defaultProfil !== "TOUS_LES_PROFILS") {
      var element = GLOBAL_DATA.profiles.get("mesprofils").getProfile(defaultProfil);
      this.$el.find(".phx-profiles-menu-header").text(element.get("libelle"));
      this.$el.find(".phx-profiles-tous-check .tous").val([false]);
      //initialize profile
      GLOBAL_DATA.rights.profile = defaultProfil;
    } else {
      /*  If the attribut termeProfilGenre is 1 -> 	this.$el.find(".phx-profiles-menu-header").text(i18n.t('messages:GT_1898', { "1" :  i18n.t('profilutilisateur.restotous')}));
      else -> this.$el.find(".phx-profiles-menu-header").text(i18n.t('messages:GT_1897', { "1" :  i18n.t('profilutilisateur.restotous')}));
      ATTENTION! the argument will be  termeProfilPluriel  (We haven't it)
       */
      if (this.$el.find(".phx-profiles-menu-header")) {
        this.$el.find(".phx-profiles-menu-header").text(i18n.t('messages:GT_1897', { "1": i18n.t('profilutilisateur.restotous') }));
      }
      //initialize profile
      GLOBAL_DATA.rights.profile = null;
    }

    this._addElements();

    var self = this;
    $(this.el).find(".phx-profiles-menu-header").keydown(function(e) {
      self._headerKeyboradNavigation(e);
    });
    $(this.el).find(".phx-profiles-menu-list").keydown(function(e) {
      self._menuProfilesHeaderNavigation(e);
    });
    $(this.el).find(".phx-profiles-menu-list").hide();

    //prepare keyboard navigation for each link at menu
    var menuLinks = $(this.el).find(".phx-profiless-menu-list li.phx-menu-link a");
    _.each(menuLinks, function(link) {
      $(link).keydown(function(e) {
        self._keyboardNav(e, false);
      });
    });

    $(window).resize(function() {
      self._checkHeight();
    });

    return this;
  },

  _headerKeyboradNavigation: function(e) {
    if (e.keyCode == 13 || e.keyCode == 32 || e.keyCode == 40) {
      //Enter spacebar down
      if (this.isVisible === false) {
        this._showList(e);
      }
    }
  },

  _menuProfilesHeaderNavigation: function(e) {
    var elementFocused = $(".phx-profiles-menu-list").find(':focus');
    var elementToFocus = null;
    if (elementFocused) {
      var index = $(".phx-profiles-menu-list").find("a").index(elementFocused);
      if (index !== -1) {
        switch (e.keyCode) {
          case 38:
            { //Up
              if (index > 0) {
                do {
                  index--;
                  elementToFocus = $(".phx-profiles-menu-list").find("a")[(index)];
                  if (elementToFocus && $(elementToFocus).is(":visible")) {
                    elementToFocus.focus();
                  }
                } while (elementToFocus && !$(elementToFocus).is(":visible"));
              }
              break;
            }
          case 40:
            { //Down
              if (index < $(".phx-profiles-menu-list").find("a").length) {
                do {
                  index++;
                  elementToFocus = $(".phx-profiles-menu-list").find("a")[(index)];
                  if (elementToFocus && $(elementToFocus).is(":visible")) {
                    elementToFocus.focus();
                  }
                } while (elementToFocus && !$(elementToFocus).is(":visible"));
              }
              break;
            }
          default:
            break;
        }
      }
    } else {
      if (e.keyCode == 38 || e.keyCode == 40) {
        $(".phx-profiles-menu-list").find("a").first().focus();
      }
    }
  },

  _clickTousCheckbox: function(event) {
    if ($(event.target).is(":checked") === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      this.currentSelected = {};
      this._changeProfil(null);
    }
  },

  _eventMouseUp: function() {
    var self = this;
    //		If you click out of the menu, close the menu.
    $(document).one("mouseup", function(event) {
      var menuElements = $(".phx-profiles-menu-list", self.el).find(event.target).length;
      if (menuElements > 0) {
        //Don't close menu if mouseup is on scroll bar customer 160164
        if (((event.target.tagName === "a" || event.target.tagName === "A") && $(event.target).hasClass("ui-phx-ihm-texte-donnees-attenue") === false) ||
          ($(event.target).is(".tous") && $(event.target).is(":checked") === false) || // Just in case.
          ($(event.target).is(".defaut:not(.ui-state-disabled)") || $(event.target).parents(".defaut:not(.ui-state-disabled)").length > 0)
        ) {
          $(self.el).find(".phx-profiles-menu-list").hide();
          self.isVisible = false;
        } else {
          self._eventMouseUp(event);
        }
      } else {
        $(self.el).find(".phx-profiles-menu-list").hide();
        self.isVisible = false;
      }
    });
  },

  _showList: function(event) {
    var l_ucase_loading = _.find(_.keys(UTILS.usecase_loading_counter), function(item) { return UTILS.usecase_loading_counter[item] > 0; })

    if (STR.isBlank(l_ucase_loading)) { //s'il y a de demandes pendants, on ne doit pas permiter de changer de profil
      if (this.isVisible === false) {
        $(this.el).find(".phx-profiles-menu-list").show();
        this.isVisible = true;
        this._eventMouseUp(event);
        this._checkHeight();
        $(".phx-profiles-menu-list").find("a").first().focus();
        event.preventDefault();
      } else {
        $(this.el).find(".phx-profiles-menu-list").hide();
        this.isVisible = false;
      }
    }
  },

  _addElements: function() {
    GLOBAL_DATA.profiles.profilesNumber();
    if (GLOBAL_DATA.profiles.get("mesprofils").length > 0) {
      this.$el.find(".phx-profiles-menu-mesProfils").show();
      this._generateOptions(this.$el.find(".phx-profiles-menu-mesProfils"), GLOBAL_DATA.profiles.get("mesprofils"), 0, "M");
    }
    if (GLOBAL_DATA.profiles.get("profilsrecus").length > 0) {
      this.$el.find(".phx-profiles-menu-profilsRecus").show();
      this._generateDelegations(this.$el.find(".phx-profiles-recus"), GLOBAL_DATA.profiles.get("profilsrecus"), 0, "R");
    }
  },

  _generateOptions: function(parent, list, level, type, parentId, userId) {
    var size = list.length;
    for (var i = 0; i < size; i++) {
      var profil = list.at(i);
      var enfants = profil.get("enfants");
      var json = {
        code: profil.get("code"),
        libelle: profil.get("libelle"),
        level: level,
        enfants: enfants.length > 0,
        principal: profil.get("principal"),
        selection: profil.get("selectionnable"),
        type: type,
        parentId: parentId,
        userId: userId
      };
      var $element = $(this.templateMenuItem(json));
      if (level === 0) {
        $element.find("a").addClass("ui-phx-ihm-texte-donnees");
        parent.append($element);
      } else {
        if (profil.get("selectionnable")) {
          $element.find("a").addClass("ui-phx-ihm-texte-donnees-alternatif");
        } else {
          $element.find("a").addClass("ui-phx-ihm-texte-donnees-attenue");
        }
        parent.find(".phx-profle-enfants").first().append($element);
      }

      if (enfants.length > 0) {
        var l_parentCode;
        if (level === 0) {
          l_parentCode = profil.get("code");
        } else {
          l_parentCode = parentId;
        }
        this._generateOptions($element, enfants, level + 1, type, l_parentCode, userId);
      }
    }
  },

  _generateDelegations: function(parent, list, level, type) {
    var size = list.length;
    var lastUserId = "";
    for (var i = 0; i < size; i++) {
      var recu = list.at(i);
      if (lastUserId != recu.get("codeutil")) {
        var listFilteredByUserId = list.filter(function(a) { return a.get("codeutil") === recu.get("codeutil") });
        var collFilteredByUserId = new ProfilesColl(listFilteredByUserId, { parse: true });
        //Treat all reçus by the same delegueur
        var message;
        var profilMsg;
        if (recu.get("enfants").length >= 1 || collFilteredByUserId.length > 1) { // Plural
          if (false /*genere*/ ) {
            profilMsg = i18n.t('profilutilisateur.profils') + " ";
            message = i18n.t('messages:GT_1904', { "1": "<span class='ui-phx-ihm-texte-donnees-important'>" + profilMsg + "</span>", interpolation: { escapeValue: false } });
          } else {
            profilMsg = i18n.t('profilutilisateur.profils') + " ";
            message = i18n.t('messages:GT_1903', { "1": "<span class='ui-phx-ihm-texte-donnees-important'>" + profilMsg + "</span>", interpolation: { escapeValue: false } });
          }
        } else { // Singular
          if (false /*genere*/ ) {
            profilMsg = i18n.t('profilutilisateur.profil') + " ";
            message = i18n.t('messages:GT_1902', { "1": "<span class='ui-phx-ihm-texte-donnees-important'>" + profilMsg + "</span>", interpolation: { escapeValue: false } });
          } else {
            profilMsg = i18n.t('profilutilisateur.profil') + " ";
            message = i18n.t('messages:GT_1901', { "1": "<span class='ui-phx-ihm-texte-donnees-important'>" + profilMsg + "</span>", interpolation: { escapeValue: false } });
          }
        }
        var username = recu.get("nomutil");
        if (!STR.isBlank(recu.get("collaborateur")) && !STR.isBlank(recu.get("collaborateur").nom) && !STR.isBlank(recu.get("collaborateur").prenom)) {
          username = recu.get("collaborateur").prenom + " " + recu.get("collaborateur").nom;
        }
        var $spanTitle = $("<span>").addClass("ui-phx-ihm-texte-donnees").html(message);
        var $spanUser = $("<span>").addClass("ui-phx-ihm-texte-donnees-important").html(" " + username);
        var $element = $(this.templateMenuRecu({}));
        $element.append($spanTitle);
        $element.append($spanUser);
        parent.append($element);

        this._generateOptions($element.filter(".phx-profiles-recu-first-level"), collFilteredByUserId, 0, type, null, recu.get("codeutil"));

        lastUserId = recu.get("codeutil");
      }
    }
  },

  _changeProfil: function(code, type, parentId, userId, revert) {
    var self = this;
    var profileList = null;
    var parentProfil = null;
    var showTooltip = false;
    if (type === "M") {
      this._showDefaultButton();
      profileList = GLOBAL_DATA.profiles.get("mesprofils");
      if (STR.isBlank(parentId) === false) {
        parentProfil = profileList.get(parentId);
        showTooltip = true;
      }
    } else if (type === "R") {
      this._hideDefaultButton();
      profileList = GLOBAL_DATA.profiles.get("profilsrecus");
      if (STR.isBlank(userId) === false) {
        showTooltip = true;
        var listFilteredByUserId = profileList.filter(function(a) { return a.get("codeutil") == userId });
        var collFilteredByUserId = new ProfilesColl(listFilteredByUserId, { parse: true });
        profileList = collFilteredByUserId;
        if (STR.isBlank(parentId) === false) {
          parentProfil = collFilteredByUserId.get(parentId);
        }
      }
    }
    if (profileList !== null || code === null) {
      var element = null;
      if (code !== null) {
        if (parentProfil === null) {
          element = profileList.get(code);
        } else {
          element = parentProfil.getProfile(code);
        }
      }

      GLOBAL_DATA.rights.profile = code;
      if (parentProfil) {
        GLOBAL_DATA.rights.parentProfil = parentProfil.get("code");
      } else {
        GLOBAL_DATA.rights.parentProfil = null;
      }
      GLOBAL_DATA.rights.reset({ silent: true }); //to clean first
      GLOBAL_DATA.rights.fetch({
        success: function() {
          var $icon = self.$el.find(".phx-profiles-first-icon span");
          // Clear classes and set again the identifier class
          $icon.removeClass();
          if (STR.isBlank(element) === false) {
            if (element.get("principal") === true) {
              $icon.addClass("ui-phx-icon-metier ui-phx-profil-principal");
            } else if (STR.isBlank(userId) === false) {
              $icon.addClass("ui-phx-icon-metier ui-phx-profil-recu-en-delegation");
            } else if (STR.isBlank(parentId) === false) {
              $icon.addClass("ui-phx-icon-metier ui-phx-appartient-a-une-hierarchie");
            }
            self.$el.find(".phx-profiles-menu-header").text(element.get("libelle"));
            self.$el.find(".phx-profiles-tous-check .tous").val([false]);
            if (showTooltip === true) {
              var $title = $("<div>");
              $title.append(i18n.t('profilutilisateur.profilEnCours') + " : ");
              var $text = $("<span>");
              // Set text
              if (STR.isBlank(parentId) === false) {
                $text.text(parentProfil.get("libelle"));
              } else {
                $text.text(element.get("libelle"));
              }
              $title.append($text);
              self.$el.find(".phx-profiles-menu-header").attr("title", $title.html());
            } else {
              self.$el.find(".phx-profiles-menu-header").attr("title", "");
            }
          } else {
            self.$el.find(".phx-profiles-menu-header").attr("title", "");
            self.$el.find(".phx-profiles-menu-header").text(i18n.t('messages:GT_1897', { "1": i18n.t('profilutilisateur.restotous') }));
          }

          if (!STR.isBlank(objs.populationMenu)) {
            objs.populationMenu.repaintPopulation(function() {
              GLOBAL_DATA.rights.trigger("updateTabs", revert === true);
            });
          }
        },
        avoidNavigation: revert === true
      });
    }
  },

  _hideDefaultButton: function() {
    this.$el.find(".defaut").addClass("ui-state-disabled");
  },

  _showDefaultButton: function() {
    this.$el.find(".defaut").removeClass("ui-state-disabled");
  },

  _changeProfilEvent: function(event) {
    if ($(event.target).hasClass("ui-phx-ihm-texte-donnees-attenue")) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    var code = $(event.target).attr("data-value");
    var type = $(event.target).attr("data-type");
    var parentId = $(event.target).attr("data-parentId");
    var userId = $(event.target).attr("data-userId");

    var selected = {
      code: code,
      type: type,
      parentId: parentId,
      userId: userId
    };
    if (_.isEqual(this.currentSelected, selected) === false) {
      this.oldSelected = UTILS.cloneObj(this.currentSelected);
      this.currentSelected.code = code;
      this.currentSelected.type = type;
      this.currentSelected.parentId = parentId;
      this.currentSelected.userId = userId;
      this._changeProfil(code, type, parentId, userId);
    }
  },

  _revertSelection: function() {
    if (STR.isBlank(this.oldSelected.code)) {
      this.currentSelected = {};
      this.$el.find(".tous.phx-bistate-box").prop("checked", true);
      this._changeProfil(null, null, null, null, true);
    } else {
      var code = this.oldSelected.code;
      var type = this.oldSelected.type;
      var parentId = this.oldSelected.parentId;
      var userId = this.oldSelected.userId;

      this.currentSelected = UTILS.cloneObj(this.oldSelected);
      this._changeProfil(code, type, parentId, userId, true);
    }
  },

  _collapse: function(event) {
    var parent = $(event.target).parents(".ui-menu-item").first();
    parent.find(".phx-profle-enfants").hide();
    var icons = parent.find(".phx-collapsable-icon");
    icons.removeClass("phx-collapsable-icon");
    icons.addClass("phx-expandable-icon");
    this._checkHeight();
  },

  _expand: function(event) {
    var parent = $(event.target).parents(".ui-menu-item").first();
    parent.find(".phx-profle-enfants").first().show();
    $(event.target).removeClass("phx-expandable-icon");
    $(event.target).addClass("phx-collapsable-icon");
    this._checkHeight();
  },

  _setAsDefault: function() {
    // TOUS_LES_PROFILS
    var currentDefault = DIVERS.get("prfUti");
    var newDefault = this.currentSelected.code;

    // if it has parent the default it its parent
    if (STR.isBlank(this.currentSelected.parentId) === false) {
      newDefault = this.currentSelected.parentId;
    }

    if (STR.isBlank(newDefault)) {
      newDefault = "TOUS_LES_PROFILS";
    }
    if (currentDefault !== newDefault) {
      GLOBAL_DATA.paramDivers.modifyParamForUser("prfUti", newDefault, "profilutilisateur");
    }
  },

  _checkHeight: function() {
    if (!$(this.el).find('.phx-profiles-menu-list').is(':visible')) {
      return;
    }

    var window_height = $(window).height();
    var list_height = $(this.el).find('.phx-profiles-menu-list').innerHeight();
    var list_offset = $(this.el).find('.phx-profiles-menu-list').position();
    var list_extra = 25;

    if (window_height < list_height + list_offset.top + list_extra) {
      var new_height = window_height - list_offset.top - list_extra;
      $(this.el).find('.phx-profiles-menu-list').css({
        maxHeight: new_height
      });
    } else {
      $(this.el).find('.phx-profiles-menu-list').css({
        maxHeight: 'none'
      });
    }

  }
});
