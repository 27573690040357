import { PaginatedCollection } from '../../../../../../core/models/paginated.collection.js';
import { RemoteFileModel } from '../../../../../../core/models/remoteFile.model.js';

/**
 * Liste pieces jointes collection
 */
export var PiecesJointesColl = PaginatedCollection.extend({

  //	habilitationV : "",
  //	habilitationG : "",
  //	usecase : "gererpiecesjointes",

  model: RemoteFileModel,

  initialize: function() {
    PaginatedCollection.prototype.initialize.call(this);
    this.paginated = false;
  },

  url: function() {
    return Configuration.restRoot + "/rest/fichier/liste/" + encodeURIComponent(this.id);
  }
});