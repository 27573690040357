import { CalendaccModel } from '../models/calendacc.model.js';
import { FormView as VueannuelleFormView } from '../../vueannuelle/views/form.view.js';
import { FormView as VuemensuelFormView } from '../../vuemensuel/views/form.view.js';
import { i18n } from '../../../../i18n.js';
import { TYPE } from '../../../../tda/tda.js';

export var FormView = Backbone.View.extend({

  initialize: function(options) {

    if (options && options.workflow) {
      this.workflow = options.workflow;
    }

    this.vueannuelle = new VueannuelleFormView({ droppable: true, workflow: this.workflow });
    this.vuemensuel = new VuemensuelFormView({ droppable: true, workflow: this.workflow });

    //change the render functions
    this.vueannuelle.cellRender = this._renderCellsVueannuelle;
    this.vueannuelle.isJourExceptionnel = function(stringDate) {
      var model = this.workflow.formModel;
      var elements = model.get("elements");
      var day = elements.get(stringDate);

      var jex = false;
      if (day) {
        jex = day.get("nature") == "E";
      }
      return jex;
    };
    this.vueannuelle.isJourFerie = function(stringDate) {
      var model = this.workflow.formModel;
      var elements = model.get("elements");
      var day = elements.get(stringDate);
      var jfe = false;
      if (day) {
        jfe = day.get("nature") == "F";
      }
      return jfe;
    };
    this.vueannuelle.isJourHorRepos = function(stringDate) {
      var hasRepos = false;
      return hasRepos;
    };
    this.model = new CalendaccModel;
  },

  render: function() {
    $(this.el).html(this.vueannuelle.render().el);

    return this;
  },

  _renderCellsVueannuelle: function(parent, stringDate) {
    var model = this.workflow.formModel;
    var elements = model.get("elements");
    var zones = model.get("zones");
    var vasco = model.get("vasco");
    var colorZones = [];
    var libelleZones = [];

    var objZones = [];

    var day = elements.get(stringDate);
    if (day) {
      var dayWeek = TYPE.DATE.strToDate(stringDate).getDay();

      if (day.get("nature") == "F") { //Férié
        var jfe = true;
      } else if (day.get("nature") == "E") { //exceptionnel
        var jex = true;
      }

      //view if is day vacences
      var vacances = [];
      var backgroundZones = [];
      var titleZones = [];
      _.each(day.get("vacances"), function(vac) {
        vacances[vac.zone] = vac;
        backgroundZones[vac.zone] = "background-color:" + zones.get(vac.zone).get("colorRGB");
        var objVac = vasco.get(vac.id);
        titleZones[vac.zone] = " title='" + i18n.t('calendacc.titleVacances', {
          "0": zones.get(vac.zone).get("libelle"),
          "1": objVac.get("type").libelle,
          "2": TYPE.DATE.format(objVac.get("datedeb")),
          "3": TYPE.DATE.format(objVac.get("datefin"))
        }) + "' ";
        // Dynamic creation of zone lines
        var cols = Math.max(7, model.attributes.zones.models.length * 2 + 1);
        var style = "position:absolute;width:calc(100% / " + cols + ");height:20px;top:0px;z-index:2;";
        parent.css("height", "100%");

        var cellDom = $("<div style='position:relative;top:0px;left:0px'></div>");
        cellDom.html($("<div style='" + style + "left:0px'></div>"));
        var count = 0;

        _.each(model.attributes.zones.models, function(model) {

          cellDom.append($("<div style='" + style + backgroundZones[model.attributes.code] + ";left:calc((100% / " + cols + ")* " + (count++) + " ' " + titleZones[model.attributes.code] + "></div>"));
          cellDom.append($("<div style='" + style + "left:calc((100% / " + cols + ")* " + (count++) + ")'></div>"));
          parent.html(cellDom);
        });
      });
      /*
      var style = "position:absolute;width:calc(100% / 7);height:20px;top:0px;z-index:2;";
      parent.css("height", "100%");

      var cellDom = $("<div style='position:relative;top:0px;left:0px'></div>");
      cellDom.html($("<div style='" + style + "left:0px'></div>"));
      cellDom.append($("<div style='" + style + backgroundZones["A"] + ";left:calc((100% / 7)*1' " + titleZones["A"] + "></div>"));
      cellDom.append($("<div style='" + style + "left:calc((100% / 7)*2)'></div>"));
      cellDom.append($("<div style='" + style + backgroundZones["B"] + ";left:calc((100% / 7)*3' " + titleZones["B"] + "></div>"));
      cellDom.append($("<div style='" + style + "left:calc((100% / 7)*4)'></div>"));
      cellDom.append($("<div style='" + style + backgroundZones["C"] + ";left:calc((100% / 7)*5' " + titleZones["C"] + "></div>"));
      cellDom.append($("<div style='" + style + "left:calc((100% / 7)*6)'></div>"));

      parent.html(cellDom);
      */
    }
  }
});
