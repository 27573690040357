import { ReadOnlyModel } from '../../models/readOnly.model.js';
import { STR } from '../../../utils/str.js';
import TPL_common_table_col_menu from './table_col_menu.tpl.html';

export var ColumnsChooserView = Backbone.View.extend({

  dummy: "",
  className: "phx-grid-nemu ui-grid-th",

  /**
   * View of the menu that toggles the columns.
   */
  initialize: function(options) {
    this.template = TPL_common_table_col_menu;
    this.model = new ReadOnlyModel({
      defaults: {
        mode: ""
      }
    });
    this.dataGrid = options.dataGrid;
    this.visible = [];
    this.invisible = [];
    this.listenTo(this.dataGrid.model, "toggle:column", this._manageIcons);
    this.listenTo(this.dataGrid.model, "lock:column", this._hideMenuOption);
    this.listenTo(this.dataGrid.model, "unlock:column", this._showMenuOption);
    this.listenTo(this.dataGrid.model, "block:column", this._hideMenuOption);
    this.listenTo(this.dataGrid.model, "unblock:column", this._showMenuOption);

    // common class prefix
    this.classPrefix = "phx-grid-menu-";
  },

  /**
   * Event when click on a column header.
   */
  /**
   * Event when click on a checkbox on the header of the multisel column.
   */
  events: {
    "click button": "_toggleMenu",
    "click li a": "_selectOption"
  },

  /**
   * Render the menu view
   */
  render: function() {
    var dynamic = { text: "" };
    $(this.el).html(this.template(dynamic));
    $("button", this.el).button({
      icons: {
        primary: "ui-icon-triangle-1-s"
      },
      text: false
    });

    this.menu = this._configureMenu();
    this.menu.hide();

    return this;
  },

  /**
   * Manage the icon status of the menu to see what columns are displayed or not.
   */
  _manageIcons: function(columnName) {
    this.menu.find("." + this.classPrefix + columnName.replace(".", "\\.") + " span").toggleClass("phx-icon-planresp-valider");
    this.menu.find("." + this.classPrefix + columnName.replace(".", "\\.") + " span").toggleClass("none");
    this._manageVisibleState(columnName);
  },

  /**
   * Show the column in the menu.
   */
  _showMenuOption: function(columnName) {
    this.menu.find("li ." + this.classPrefix + columnName.replace(".", "\\.")).parent().show();
  },

  /**
   * Hide a column in the menú.
   */
  _hideMenuOption: function(columnName) {
    this.menu.find("li ." + this.classPrefix + columnName.replace(".", "\\.")).parent().hide();
  },

  /**
   * Add the columns to the menu list.
   */
  _configureMenu: function() {
    var $menu = $("ul", this.el);
    _.each(this.dataGrid.currentVue.columns, function(column, index, columns) {
      if (index < columns.length && !this.dataGrid.currentVue._columns[column.property].omitMenu &&
        ((column.title && column.title.length > 0 && index === 0) || index > 0)) {
        var $li = $("<li></li>");
        var $a = $("<a class=\"" + this.classPrefix + column.property + "\"></a>");
        var $span = $("<span></span>").addClass("phx-icon phx-icon-planresp-valider");
        $a.append($span);
        var title = (!STR.isBlank(column.menuTitle) && !STR.isBlank(column.menuTitle)) ? column.menuTitle : column.title;
        $a.append(title);
        $li.append($a);
        if (STR.isBlank(title)) { //Only add option to menu if it has some title text
          $li.hide();
        }
        $menu.append($li);

        this.visible.push(column.property);
      }
    }, this);
    $menu.menu();
    $menu.css("cursor", "pointer");
    return $menu;
  },

  /**
   * Manages the column menú click hidding or showing the cilcked column.
   */
  _selectOption: function(event) {
    var columnId = event.currentTarget.className.split(" ")[0].replace(this.classPrefix, "");
    // If there are only one visible column and try to click it the event is not triggered.
    if (this.visible.length > 1 || _.indexOf(this.visible, columnId) === -1) {
      this.dataGrid.model.trigger("toggle:column", columnId);
    }
    this._toggleMenu();
  },

  _manageVisibleState: function(columnId) {
    var indexOf = _.indexOf(this.invisible, columnId);
    if (indexOf === -1) {
      this.invisible.push(columnId);
      this.visible.splice(_.indexOf(this.visible, columnId), 1);
    } else {
      this.visible.push(columnId);
      this.invisible.splice(_.indexOf(this.invisible, columnId), 1);
    }
  },

  /**
   * Opens or Closes the menú.
   */
  _toggleMenu: function() {
    if (this.menu.is(":visible")) {
      this.menu.hide();
    } else {
      var $targetEl = $(this.el);
      this.menu.show().position({
        my: "right top",
        at: "bottom",
        of: $targetEl
      });
      var self = this;
      $(document).one("mousedown", function(event) {
        var element = $targetEl.find(event.target);
        if (element.length === 0) {
          // mousedown out of the button
          self._toggleMenu();
        }
      });
    }
    //		}
  },

  /**
   * Called when backbone removes the view.
   */
  remove: function() {
    this.dataGrid = null;

    var visibleLength = this.visible.length;
    this.visible.splice(0, visibleLength);
    delete this.visible;

    var invisibleLength = this.invisible.length;
    this.invisible.splice(0, invisibleLength);
    delete this.invisible;

    Backbone.View.prototype.remove.call(this);
    this.$el.empty();
  },

  /**
   * Set the text of an item of the menu
   *
   */
  setMenuItemTitle: function(property, title) {
    var $entry = $("." + this.classPrefix + property, this.$el);
    if ($entry && $entry.length > 0) {
      var $span = $("span", $entry);
      $entry.html($span);
      $entry.append(title);
      if ($entry.parent("li").length > 0 && !$entry.parent("li").is(":visible")) {
        $entry.parent("li").removeClass("ui-menu-divider");
        $entry.parent("li").addClass("ui-menu-item");
        $entry.parent("li").show();
      }
    }
  }
});