import { BaseModel } from '../../../../../../core/models/base.model.js';


/**
 * Model for Combos Loading
 */
export var GererOneBadgeageProfilsModel = BaseModel.extend({

  initialize: function(options) {
    this.profilId = "";
    if (options && options.profilId) {
      this.profilId = options.profilId;
    }
  },

  /**
   * Builds the url for the REST call
   */
  url: function() {
    var result = Configuration.restRoot + "/rest/badgeage/composant/profils/" + encodeURIComponent(this.profilId);
    return result;
  }
});