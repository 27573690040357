import { DataGridModel } from 'core/grids/data_grid/dataGrid.model.js';
import { DataGridView } from 'core/grids/data_grid/dataGrid.view.js';
import { i18n } from '../../../../i18n.js';
import { STR } from '../../../../utils/str.js';
import { SYNC } from '../../../../utils/sync.js';
import { TYPE } from '../../../../tda/tda.js';
import TPL_badgeage_badgeage_compte_dialog from '../badgeage_compte_dialog.tpl.html';

/**
 * Badgeage Virtuel Dialog
 */
export var BadgeageVirtuelDialogView = Backbone.View.extend({

  initialize: function(options) {
    if (options && options.parent) {
      this.parent = options.parent;
    }
    if (options && options.heure) {
      this.heure = options.heure;
    }
    this.template = TPL_badgeage_badgeage_compte_dialog;

    if (options && options.model) {
      this.badgVirtModel = options.model;
    }
    if (options && options.collection) {
      this.badgVirtComptColl = options.collection;
    }

    if (options && options.title) {
      this.title = options.title;
    }

    if (options && options.subtitle) {
      this.subtitle = options.subtitle;
    }
  },

  _initCompteursTable: function() {
    var tableModel = new DataGridModel({
      coll: this.badgVirtComptColl
    });
    var tblColumns = [{
      title: i18n.t('common:bvMenu_comptLib'),
      property: "libelle",
      width: 200
    }, {
      title: i18n.t('common:bvMenu_comptVal'),
      property: "valeur"
    }];
    var table = new DataGridView({
      columns: tblColumns,
      model: tableModel,
      gridHeightType: "auto",
      parentContainer: this.parent
    });

    table.cellRenderers["libelle"] = function(model) {
      var span = $("<span>").attr("title", i18n.t('common:bvMenu_infoLibelle')).text(model.get("libelle"));
      return span;
    };

    table.cellRenderers["valeur"] = function(model) {
      var span = $("<span>").attr("title", i18n.t('common:bvMenu_infoValeur')).text(model.get("valeur"));
      if (model.get("valeur").indexOf("-") != -1) {
        span.addClass("ui-phx-valorisation-negative");
      }
      return span;
    };

    table.setSortableColumns("libelle");

    $(this.parent.el).on("dialogresize", function() {
      table._resizeGrid();
    });

    return table;
  },

  render: function() {
    var json = { 'i18n': i18n };
    this.$el.append(this.template(json));

    var bvcompte = this.$el.find(".bvcompte");
    var bvcompteurs = this.$el.find(".bvcompteurs");
    if (this.badgVirtModel) {
      bvcompte.show();
      bvcompteurs.hide();
      bvcompte.html("");
      if (!STR.isBlank(this.badgVirtModel.get("cptrendu"))) {
        bvcompte.append("<span class=\"bvline1\">" + this._buildMessage(this.badgVirtModel.get("cptrendu"), this.badgVirtModel.get("parametres")) + "</span>");
        bvcompte.append("<br/>");
      }
      if (!STR.isBlank(this.badgVirtModel.get("message1"))) {
        bvcompte.append("<span class=\"bvline2\">" + this.badgVirtModel.get("message1") + "</span>");
        bvcompte.append("<br/>");
      }
      if (!STR.isBlank(this.badgVirtModel.get("message2"))) {
        bvcompte.append("<span class=\"bvline3\">" + this.badgVirtModel.get("message2") + "</span>");
        bvcompte.append("<br/>");
      }
    } else if (this.badgVirtComptColl && this.badgVirtComptColl.length > 0) {
      if (this.subtitle) {
        this.$el.find(".bvcompteurs-valorisation").hide();
        this.$el.find(".bvcompteurs-compteurs-instantanes").html(this.subtitle).show();
      } else {
        var valorisation = i18n.t('common:bvMenu_valorisation') + " " + TYPE.DATE.dateToStr(SYNC.getServerDate(), "DD/MM/YYYY");
        this.$el.find(".bvcompteurs-compteurs-instantanes").hide();
        this.$el.find(".bvcompteurs-valorisation").html(valorisation).show();
      }

      bvcompte.hide();
      bvcompteurs.show();
      if (this.parent._setTitle && this.title) {
        this.parent._setTitle(this.title);
      } else if (this.parent._setTitle) {
        this.parent._setTitle(i18n.t('common:bvMenu_comptTitle', { "0": this.badgVirtComptColl.length }));
      }
      var tableCompteurs = this._initCompteursTable();
      bvcompteurs.html(tableCompteurs.el);
      tableCompteurs.render();
    } else {
      this.parent._setTitle(i18n.t('common:bvMenu_comptTitle', { "0": 0 }));
      bvcompteurs.html(i18n.t('common:bvMenu_infoNoDisponible'));
    }

    $(this.parent.el).css("height", "auto");

    return this;
  },

  _buildMessage: function(message, parametres) {
    var result = null;
    if (!STR.isBlank(message)) {
      result = message;
      if (!STR.isBlank(parametres) && parametres.length > 0) {
        for (var i = 0; i < parametres.length; i++) {
          var next = i + 1;
          result = result.replace("@" + next, parametres[i]["@" + next]);
        }
      }
    }
    return result;
  }
});