import { BaseModel } from '../../../../../core/models/base.model.js';

/**
 * Model for Brique 'Resultats'
 */
export var ResultatsModel = BaseModel.extend({

  initialize: function(options) {
    if (options) {
      if (options.userId)
        this.userId = options.userId;

      if (options.codeBrique)
        this.codeBrique = options.codeBrique;
    }
  },

  //In cpt, an empty item is a separator
  defaults: {
    "libelle": "",
    "dateref": "",
    "cpt": [{
      "code": null,
      "libelle": "",
      "valeur": "",
      "typevalo": "",
      "datevalo": ""
    }]
  },

  url: function() {
    return Configuration.restRoot + "/rest/personnalisation/definition/brique/rescpt/" + encodeURIComponent(this.userId + "," + this.codeBrique) + "/resultat";
  }
});