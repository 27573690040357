import { BaseModel } from '../../../../../core/models/base.model.js';

import { STR } from '../../../../../utils/str.js';
export var PersoBriquesModel = BaseModel.extend({

  defaults: {
    "briques": ""
  },

  /**
   * This method is executed when a webservice is called from this model
   */
  url: function() {
    var url = Configuration.restRoot + "/rest/personnalisation/confignav/accueil/"; //Creation
    if (!STR.isBlank(this.espaceid)) {
      url += "?espaceid=" + encodeURIComponent(this.espaceid);
    }
    return url;
  }
});