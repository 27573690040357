import { BaseModel } from '../../../../../core/models/base.model';
import { STR } from '../../../../../utils/str.js';
import { i18n } from '../../../../../i18n.js';
import { TYPE } from '../../../../../tda/tda.js'
import { SYNC } from '../../../../../utils/sync.js'
import { MSGS } from '../../../../../utils/msgs';

/**
 * Briques configuration.
 */
/**
 * Model for Brique 'affectations en cours ou à venir'
 */
/**
 *  */
export var MonRespModel = BaseModel.extend({

  defaults: {
    "itemlib": null,
    "itemid": null,
    "itemcode": null,
    "debsitu": null,
    "finsitu": TYPE.DATE.INFINITY,
  },

  validate: function(attrs) {
    var errors = {};

    if (STR.isBlank(attrs.debsitu)) {
      errors.debsitu = i18n.t('common:required', { "0": i18n.t('common:bmonresp.colonneDu') });
    }

    if (STR.isBlank(attrs.itemid)) {
      errors.itemid = i18n.t('common:required', { "0": i18n.t('common:bmonresp.resp') });
    }

    var datedeb = {
      "name": "debsitu",
      "value": attrs.debsitu,
      "label": i18n.t('common:bmonresp.colonneDu')
    };

    var datefin = {
      "name": "finsitu",
      "value": attrs.finsitu,
      "label": i18n.t('common:bmonresp.colonneDu'),
    };

    var errorMsg = TYPE.DATE.validatePeriod(datedeb, datefin);
    if (!STR.isBlank(errorMsg)) {
      errors.debsitu = errorMsg.datedeb;
      errors.finsitu = errorMsg.datefin;
    }

    if (!_.isEmpty(errors)) {
      var errorMap = {
        errorValidation: STR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
    return null;
  },
});
