/**
 * Node Model of the Tree Component
 */
export var TreeNodeModel = Backbone.Model.extend({

  dummy: "",

  /**
   * Tree Root
   */
  /**
   * Model with the Node internal Data
   */
  /**
   * Collection to fetch the child nodes of the current node
   */

  defaults: {
    expanded: false,
    hasChild: undefined,
    label: "",
    level: 0
  }
});