import { BaseModel } from '../../../../../../core/models/base.model.js';

/**
 * Model progressbar view
 */
export var ProgressBarModel = BaseModel.extend({

  defaults: {
    "value": null,
    "treatedSuccess": null,
    "treatedSuccessA": null,
    "treatedSuccessR": null,
    "max": null,
    "stop": false
  }
});