
import { TDAModel } from './tda.model.js';

/**
 * Application Data Types Collection. Get the configuration of the data types.
 */
export var TDAColl = Backbone.Collection.extend({

  dummy: "",

  /**
   * url
   */
  url: Configuration.restRoot + "/rest/typeapplicatif",
  model: TDAModel

});