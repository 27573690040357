module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<div class="phx-lanceetat-filter" style="min-width:310px;">\n  <div>\n    <label for="theme">'+
((__t=(i18n.t('lanceetat.theme')))==null?'':__t)+
'</label>\n    <span class="cboTheme"></span> <br/>\n\n    <label for="libelle">'+
((__t=(i18n.t('lanceetat.libelle')))==null?'':__t)+
'</label>\n    <input type="text" class="libelle" value="" size="28" maxlength="50" /> <br/>\n  </div>\n</div>';
}
return __p;
};
