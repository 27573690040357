module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!-- <div class="phx-multiLabel-bloc" data-bloc-name="PLH" data-bloc-label="'+
((__t=(i18n.heures))==null?'':__t)+
'" > -->\n<!-- 	<label for="heuredeb" class="phx-inline-label">&nbsp;</label> -->\n<!-- 	<input type="text" class="heuredeb typeHourMinuteNightly" size="5"> -->\n<!-- 	<label for="heurefin" class="phx-inline-label">'+
((__t=(i18n.heures_a))==null?'':__t)+
'</label> -->\n<!-- 	<input type="text" class="heurefin typeHourMinuteNightly" size="5"> -->\n<!-- 	<label for="soit" class="phx-inline-label">'+
((__t=(i18n.heures_soit))==null?'':__t)+
'</label> -->\n<!-- 	<input type="text" class="soit typeDurationHM" size="5" readonly="readonly"> -->\n<!-- 	<span class="heuredeb-error-container"></span> -->\n<!-- 	<span class="heurefin-error-container"></span> -->\n<!-- </div> -->\n<!-- <div class="phx-multiLabel-bloc" data-bloc-name="DUREE" data-bloc-label="'+
((__t=(i18n.duree))==null?'':__t)+
'" > -->\n<!-- 	<label for="duree" class="phx-inline-label">&nbsp;</label> -->\n<!-- 	<input type="text" class="duree typeDuration24HM" size="6"> -->\n<!-- </div> -->\n<div class="phx-multiLabel-bloc" data-bloc-name="UNITE" data-bloc-label="'+
((__t=(i18n.t('common:gererprets.unite')))==null?'':__t)+
'">\n  <label for="unitedeb.code" class="phx-inline-label">&nbsp;</label>\n  <span class="comboUnite"></span>\n</div>\n<!-- <div class="phx-multiLabel-bloc" data-bloc-name="PRES" data-bloc-label="'+
((__t=(i18n.presence))==null?'':__t)+
'" > -->\n<!-- 	<label for="presence" class="phx-inline-label">&nbsp;</label> -->\n<!-- 	<input type="checkbox" class="presence" readonly="readonly" value="true"> -->\n<!-- 	<span>'+
((__t=(i18n.duJour))==null?'':__t)+
'</span> -->\n<!-- </div> -->\n<!-- <div class="phx-multiLabel-bloc" data-bloc-name="POURCENT" data-bloc-label="'+
((__t=(i18n.tempeff))==null?'':__t)+
'" > -->\n<!-- 	<label for="pourcentage" class="phx-inline-label">&nbsp;</label> -->\n<!-- 	<input type="text" class="pourcentage typePercentage_0" size="10"> -->\n<!-- </div> -->\n';
}
return __p;
};
