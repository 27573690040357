import { BaseModel } from 'core/models/base.model.js';
import { CriteresCalModel } from '../models/criteresCal.model.js';
import { DialogView } from 'core/views/dialog.view.js';
import { FormView as CommonFormView } from 'core/views/form.view.js';
import { GererBadgeagesView } from '../../evenements/gerer/gererbadgeages/views/gererBadgeages.view.js';
import { GererOneBadgeageView } from '../../evenements/gerer/gereronebadgeage/views/gererOneBadgeage.view.js';
import { GLOBAL_DATA } from '../../../../globalData.js';
import { HABILITATION } from '../../../../utils/habilitation.js';
import { i18n } from '../../../../i18n.js';
import { LICENCE } from '../../../../utils/licence.js';
import { LOG } from '../../../../utils/log.js';
import { ScheduleModel } from 'core/controls/schedule/schedule.model.js';
import { ScheduleView } from 'core/controls/schedule/schedule.view.js';
import { STR } from '../../../../utils/str.js';
import { SYNC } from '../../../../utils/sync.js';
import { TYPE } from '../../../../tda/tda.js';
import { UTILS } from '../../../../utils/utils.js';
import TPL_vuemensuel_detail from '../detail.tpl.html';
import TPL_vuemensuel_horairePlageTooltip from '../horairePlageTooltip.tpl.html';

/**
 * Render a Calendrier vue mensuel in a form
 */
export var FormView = CommonFormView.extend({

  initialize: function(options) {
    this.template = TPL_vuemensuel_detail;

    this.model = new BaseModel({
      value: new CriteresCalModel()
    });
    this.selectionModel = new Backbone.Model({
      dateStart: "",
      dateEnd: "",
      isRange: false
    });

    this.workflow = options.workflow;
    this.calendar = null;

    this.listenTo(this.workflow, "drawCalendar", this._drawCalendar);

    if (STR.isBlank(this.workflow.get("selectedDate"))) {
      this.selectedDate = "19700101";

      this.currentDay = 1;
      this.currentMonth = 0;
      this.currentYear = 1970;
    } else {
      this.selectedDate = this.workflow.get("selectedDate");
      var date = TYPE.DATE.strToDate(this.selectedDate);

      this.currentDay = date.getDate();
      this.currentMonth = date.getMonth();
      this.currentYear = date.getFullYear();
    }
  },

  events: {
    "dblclick .js-event-pret": "_manageClickPret",
    "dblclick .js-event-absence": "_manageClickAbsence",
    "dblclick .js-event-activite": "_manageClickActivite",
    "dblclick .js-horaire": "_manageClickHoraire",
    "dblclick .presence-info": "_initPopupView",
    "dblclick .vuemensuel-nweek": "_manageClickWeek",
    "click .js-event-selectionable": "_manageEventSelection",
    "click .vuemensuel-cell-header": "_goToWeeklyView",
    "click .scroll-down": "_manageScrollDown",
    "click .scroll-up": "_manageScrollUp",
    "click .display-totalisations-groups": "_toggleTotalisationsMenu",
    "click .phx-totalisations-groups .ui-menu-item ": "_selectMenuElement",
    "mousedown .vuemensuel-day": "_mousedownHandler",
    "mousemove .vuemensuel-day": "_mousemoveHandler",
    "mouseup .vuemensuel-day": "_mouseupHandler"
  },
  _manageClickWeek: function(event) {
    var type = "creerAb";
    var hourAux = SYNC.getServerDate().getHours();
    var minutesAux = SYNC.getServerDate().getMinutes() + "";
    if (minutesAux < 10) {
      minutesAux = "0" + minutesAux;
    }
    var currentHour = "";
    if (hourAux < 10) {
      currentHour = "0" + hourAux + "" + minutesAux;
    } else {
      currentHour = hourAux + "" + minutesAux;
    }
    var startHour = currentHour;
    var endHour = currentHour;

    var startDate = this.$el.find(".vuemensuel-day[data-week=" + $(event.target).text() + "]").first().data('date').toString();
    startDate = TYPE.DATE.strToDate(startDate);

    var endDate = this.$el.find(".vuemensuel-day[data-week=" + $(event.target).text() + "]").last().data('date').toString();
    endDate = TYPE.DATE.strToDate(endDate);

    startDate = TYPE.DATE.dateToStr(startDate, TYPE.DATE.DEFAULT_MASK_BBDD);
    endDate = TYPE.DATE.dateToStr(endDate, TYPE.DATE.DEFAULT_MASK_BBDD);

    this.workflow.trigger("popup:open", type, this.workflow.get("collabId"), null, startDate, endDate, startHour, endHour);

  },
  _manageScrollDown: function(event) {
    var target = $(event.currentTarget);
    var cell = target.parent();
    var body = cell.find(".vuemensuel-day-body");

    var scrollHeight = body[0].scrollHeight;
    var actualScroll = body.scrollTop();
    var bodyHeight = body.height();
    var lineHeight = body.children(":first-child").height();

    if ((actualScroll + bodyHeight) < scrollHeight) {
      if ((lineHeight + (actualScroll + bodyHeight)) <= scrollHeight) {
        body.scrollTop(actualScroll + lineHeight);
      } else {
        body.scrollTop(scrollHeight);
      }

      if ((body.scrollTop() + bodyHeight) >= scrollHeight) {
        target.hide();
      }

      cell.find(".scroll-up").show();
    }
  },

  _manageScrollUp: function(event) {
    var target = $(event.currentTarget);
    var cell = target.parent();
    var body = cell.find(".vuemensuel-day-body");

    var scrollHeight = body[0].scrollHeight;
    var actualScroll = body.scrollTop();
    var bodyHeight = body.height();
    var lineHeight = body.children(":first-child").height();

    if (actualScroll > 0) {
      if (lineHeight < actualScroll) {
        body.scrollTop(actualScroll - lineHeight);
      } else {
        body.scrollTop(0);
      }

      if (body.scrollTop() <= 0) {
        target.hide();
      }

      cell.find(".scroll-down").show();
    }
  },
  _initPopupView: function() {
    var self = this;
    var contextAjouter = this._buildContextGerer("initialiser");
    if (this.typeGerer == "badgeages") {

      var dialog = new DialogView({
        view: GererBadgeagesView,
        viewData: {
          context: contextAjouter
        }
      });

      dialog.setHeight(300);
      dialog.setWidth(1200);

      dialog.model.trigger("dlg:open", function() {
        // Close Callback
        LOG.debug("callback from dialog view");
      });
    } else if (this.typeGerer == "onebadgeage") {
      var dialog = new DialogView({
        view: GererOneBadgeageView,
        viewData: {
          context: contextAjouter
        }
      });

      dialog.setHeight(300);
      dialog.setWidth(600);

      dialog.model.trigger("dlg:open", function() {
        // Close Callback
        LOG.debug("callback from dialog view");
      });
    }

  },
  _buildContextGerer: function(type) {

    var context = this.context;
    var contextGerer = {};
    var habBadgeG = "RES_PT.G";
    var habBadgeA = "RES_PT.V";
    var habBadgeV = "RES_VAL_BDG.G";

    //FIXME Fixes arror
    if (this.workflow.get("utilisateur") === this.workflow.COLLABORATEUR_TYPE) {
      habBadgeA = "COL_BADG.V";
      habBadgeG = "COL_BADG.G";
    }

    if (type == "initialiser") {
      contextGerer.ctxUtilisateur = this.context.ctxUtilisateur;
      contextGerer.ctxHabilitation = { HabilitationAcces: habBadgeA, HabilitationGestion: habBadgeG, HabilitationValidation: habBadgeV };
      contextGerer.ctxEcran = this.context.ctxEcran;
      contextGerer.ctxGestionPerioaff = null;
      contextGerer.ctxGestionPeriode = "";
      contextGerer.ctxGestionCollective = false;
      contextGerer.ctxGestionCollab = {
        matric_aux: this.workflow.mainModel.models[0].get("aux"),
        matricule: this.workflow.mainModel.models[0].get("mat"),
        nom: this.workflow.mainModel.models[0].get("nom"),
        prenom: this.workflow.mainModel.models[0].get("pre")
      };
      contextGerer.ctxActionsPossibles = [];
      contextGerer.ctxActionsPossibles = ["Creer", "Modifier", "Supprimer", "Valider"];
      contextGerer.ctxModeSuppression = "Button"; // SensX,Button
      contextGerer.ctxModeInitialisation = "InitForAction";
      contextGerer.ctxIdentifiantBadgeage = "";

      contextGerer.ctxFiltreApplique = ["A", "D", "I", "T", "H", "R"];
      contextGerer.ctxGestionDate = this.workflow.get("selectedDate");
    }

    return contextGerer;
  },
  // Events handlers
  _mousedownHandler: function(event) {
    event.preventDefault();
    // If the element clicked is not a selectable element.
    if ($(event.target).parents(".vuemensuel-day").length > 0) {
      // Delete old selection
      this.datesSelectable = true;
      this.isMultipleSelection = false;
      this._unselectAllDates();
      this._dayClickListener(event);
    }
  },
  _mousemoveHandler: function(event) {
    event.preventDefault();
    if (!STR.isBlank(this.selectionModel.get("dateStart")) && this.datesSelectable === true) {
      this.isMultipleSelection = true;

      this._unselectAllDates();
      this.oldDatefinClicked = this.selectionModel.get("dateEnd");
      this.selectionModel.set("dateEnd", $(event.currentTarget).attr("data-date"));

      this._treatSelectedDates();
    }
  },

  _mouseupHandler: function(event) {
    var self = this;
    this.datesSelectable = false;
    var isShiftPressed = event.shiftKey;

    this.workflow.trigger("end:selectDay");
    if (isShiftPressed === false && this.isMultipleSelection == false) {
      this._goToDay(event);
    } else {
      var selectionStartDate = TYPE.DATE.strToDate(this.selectionModel.get("dateStart"), TYPE.DATE.DEFAULT_MASK_BBDD);
      var selectionEndDate = TYPE.DATE.strToDate(this.selectionModel.get("dateEnd"), TYPE.DATE.DEFAULT_MASK_BBDD);

      if (selectionEndDate < selectionStartDate) {
        this.selectionModel.set({
          "dateStart": this.selectionModel.get("dateEnd"),
          "dateEnd": this.selectionModel.get("dateStart")
        });
      }

      this._goToStartDay(this.selectionModel.get("dateStart"));

      this._treatSelectedDates();
    }
    this.isMultipleSelection = false;

  },
  _manageClickMensualSelection: function(event) {
    //this.once("cell:selection:end",function(){
    LOG.debug(this.workflow.selection);
    LOG.debug(this.selection);
    var self = this,
      eventSelection,
      eventSelectionParent;

    eventSelectionParent = $(event.target).closest(".vuemensuel-day.js-heure-selectionnee");
    eventSelection = eventSelectionParent.find(".agenda_selected_item");

    if (eventSelection.length > 0) {
      this.workflow.selection.select(eventSelection.get(0).getAttribute('evtid'), eventSelection.get(0).dataset.typeevent, eventSelectionParent.get(0).dataset.date, eventSelectionParent.get(0).dataset.date);
      this.workflow.once("end:selectDay", this.workflow._emptySelection, this.workflow);
    };

  },
  _unselectAllDates: function() {
    LOG.debug("_unselectAllDates");
    var element = $(this.el).find("div.ui-phx-date-heure-selectionnee-border-imp");
    element.removeClass("ui-phx-date-heure-selectionnee-border-imp");

    this.selectionModel.set("dateEnd", "");
  },

  _dayClickListener: function(event) {
    var isShiftPressed = event.shiftKey;
    var dateSelected = $(event.currentTarget).attr("data-date");

    if (isShiftPressed === true && !STR.isBlank(this.selectionModel.get("dateStart"))) {
      this.selectionModel.set("dateEnd", dateSelected);

      //Put range of dates in correct order
      var selectionStartDate = TYPE.DATE.strToDate(this.selectionModel.get("dateStart"), TYPE.DATE.DEFAULT_MASK_BBDD);
      var selectionEndDate = TYPE.DATE.strToDate(this.selectionModel.get("dateEnd"), TYPE.DATE.DEFAULT_MASK_BBDD);

      if (selectionEndDate < selectionStartDate) {
        this.selectionModel.set({
          "dateStart": this.selectionModel.get("dateEnd"),
          "dateEnd": this.selectionModel.get("dateStart")
        });
      }

      this._treatSelectedDates();
    } else {
      if (!STR.isBlank(this.selectionModel.get("dateStart"))) {
        this._unmarkCell(".vuemensuel-day[data-date='" + this.selectionModel.get("dateStart") + "']");
      }

      if (this.selectionModel.get("dateStart") != dateSelected) {
        this.selectionModel.set("dateStart", dateSelected);
        this._markCell(".vuemensuel-day[data-date='" + this.selectionModel.get("dateStart") + "']");
      } else {
        this.selectionModel.set("dateStart", null);
      }
    }
  },

  _markCell: function(target) {
    var element = $(this.el).find(target);
    element.addClass("ui-phx-date-heure-selectionnee-border-imp");
  },

  _unmarkCell: function(target, position) {
    var element = $(this.el).find(target);
    element.removeClass("ui-phx-date-heure-selectionnee-border-imp");
  },

  _treatSelectedDates: function() {
    LOG.debug("treat:" + this.selectionModel.get("dateStart") + " " + this.selectionModel.get("dateEnd"));
    var datedeb = this.selectionModel.get("dateEnd");
    var datefin = this.selectionModel.get("dateStart");
    this._markSelectedDates(datedeb, datefin);
  },

  _markSelectedDates: function(datedeb, datefin) {
    var size = datefin - datedeb;
    var date = "";
    if (size > 0) {
      for (var i = 0; i <= size; i++) {
        date = parseInt(datedeb) + parseInt(i);
        this._markCell(".vuemensuel-day[data-date='" + date + "']");
      }
    } else {
      for (var i = size; i <= 0; i++) {
        date = parseInt(datedeb) + parseInt(i);
        this._markCell(".vuemensuel-day[data-date='" + date + "']");
      }
    }
  },

  _goToDay: function(event) {
    var target = $(event.currentTarget);

    if (target.attr("data-type") !== "out" && !target.hasClass("js-heure-selectionnee")) {
      var dateSelected = TYPE.DATE.strToDate(this.context.ctxDateEnEvidence);

      var year = dateSelected.getFullYear().toString();
      var month = (dateSelected.getMonth() + 1) < 10 ? "0" + (dateSelected.getMonth() + 1).toString() : (dateSelected.getMonth() + 1).toString();
      var day = target.children(".vuemensuel-day-header").children(".vuemensuel-day-header-number").text() < 10 ? "0" + target.children(".vuemensuel-day-header").children(".vuemensuel-day-header-number").text() : target.children(".vuemensuel-day-header").children(".vuemensuel-day-header-number").text()

      this.context.ctxDateEnEvidence = year + month + day;;

      var date = TYPE.DATE.strToDate(this.context.ctxDateEnEvidence);

      this.currentDay = date.getDate();
      this.currentMonth = date.getMonth();
      this.currentYear = date.getFullYear();

      this.workflow.set("selectedDate", this.context.ctxDateEnEvidence);

      this.selectedDate = this.context.ctxDateEnEvidence;

      var date = TYPE.DATE.strToDate(this.context.ctxDateEnEvidence);

      this.currentDay = date.getDate();
      this.currentMonth = date.getMonth();
      this.currentYear = date.getFullYear();
    }
  },

  _goToStartDay: function(date) {
    if (STR.isBlank(date))
      return;

    var target = $(".vuemensuel-day[data-date=" + date + "]");

    if (target.attr("data-type") !== "out" && !target.hasClass("js-heure-selectionnee")) {
      var dateSelected = TYPE.DATE.strToDate(this.context.ctxDateEnEvidence);

      var year = dateSelected.getFullYear().toString();
      var month = (dateSelected.getMonth() + 1) < 10 ? "0" + (dateSelected.getMonth() + 1).toString() : (dateSelected.getMonth() + 1).toString();
      var day = target.children(".vuemensuel-day-header").children(".vuemensuel-day-header-number").text() < 10 ? "0" + target.children(".vuemensuel-day-header").children(".vuemensuel-day-header-number").text() : target.children(".vuemensuel-day-header").children(".vuemensuel-day-header-number").text()

      this.context.ctxDateEnEvidence = year + month + day;;

      var date = TYPE.DATE.strToDate(this.context.ctxDateEnEvidence);

      this.currentDay = date.getDate();
      this.currentMonth = date.getMonth();
      this.currentYear = date.getFullYear();

      this.workflow.set("selectedDate", this.context.ctxDateEnEvidence);

      this.selectedDate = this.context.ctxDateEnEvidence;

      var date = TYPE.DATE.strToDate(this.context.ctxDateEnEvidence);

      this.currentDay = date.getDate();
      this.currentMonth = date.getMonth();
      this.currentYear = date.getFullYear();
    }
  },

  _goToWeeklyView: function() {
    this.workflow.set("viewType", this.workflow.WEEKLY_VIEW);
  },

  _manageEventSelection: function(event) {
    var target = $(event.currentTarget);
    //event.stopPropagation();

    if (!target.hasClass("agenda_selected_item")) {
      this.$el.find(".agenda_selected_item").removeClass("agenda_selected_item");
      target.addClass("agenda_selected_item");
      target.parents(".vuemensuel-day").addClass("ui-phx-date-heure-selectionnee-border-imp");
      this._manageClickMensualSelection(event);
    } else {
      target.removeClass("agenda_selected_item");
      this.workflow.selection.clear();
      target.parents(".vuemensuel-day").addClass("ui-phx-date-heure-selectionnee-border-imp");
    }
  },

  _manageClickHoraire: function(event) {
    var target = $(event.currentTarget);

    if (this.workflow.get("utilisateur") == this.workflow.RESPONSABLE_TYPE) {
      if (event.type == "dblclick") {
        this.dblclick = 2;

        var date = target.parent().parent().attr("data-date");
        var user = this.workflow.mainModel.collabId;
        var pk = user + "," + date;

        if (this.workflow.evtModel.hasEvent("HORAIRES", pk)) {
          var evt = this.workflow.evtModel.getEvent("HORAIRES", pk).get("evt").models[0].get("val");

          if (!STR.isBlank(evt)) {
            this.workflow.trigger("popup:open", "dropHor", this.workflow.get("collabId"), evt, date, date, null, null);
          }
        }

        event.stopPropagation();
      }
    }
  },

  _manageClickAbsence: function(event) {
    var target = $(event.currentTarget);

    if (event.type == "dblclick") {
      target.addClass("agenda_selected_item");

      this.dblclick = 2;

      var date = target.parent().parent().attr("data-date");
      var user = this.workflow.mainModel.collabId;
      var pk = user + "," + date;

      if (this.workflow.evtModel.hasEvent("ABSENCES", pk)) {
        var evt = this.workflow.evtModel.getEvent("ABSENCES", pk).get("evt").get(target.attr("evtid"));

        if (!STR.isBlank(evt)) {
          var ev = "consulterAb";
          if (evt.get("dts").sou == true) {
            ev = "consulterSo";
          }
          this.workflow.trigger("popup:open", ev, this.workflow.get("collabId"), evt);
        }
      }

      event.stopPropagation();
    }
  },

  _manageClickActivite: function(event) {
    var target = $(event.currentTarget);

    if (event.type == "dblclick") {
      target.addClass("agenda_selected_item");

      this.dblclick = 2;

      var date = target.parent().parent().attr("data-date");
      var user = this.workflow.mainModel.collabId;
      var pk = user + "," + date;

      if (this.workflow.evtModel.hasEvent("ACTIVITES", pk)) {
        var evt = this.workflow.evtModel.getEvent("ACTIVITES", pk).get("evt").get(target.attr("evtid"));

        if (!STR.isBlank(evt)) {
          var ev = "consulterAct";
          if (evt.get("dts").sou == true) {
            ev = "consulterSo";
          }
          this.workflow.trigger("popup:open", ev, this.workflow.get("collabId"), evt);
        }
      }

      event.stopPropagation();
    }
  },

  _manageClickPret: function(event) {
    var target = $(event.currentTarget);

    if (event.type == "dblclick") {
      target.addClass("agenda_selected_item");

      this.dblclick = 2;

      var date = target.parent().parent().attr("data-date");
      var user = this.workflow.mainModel.collabId;
      var pk = user + "," + date;

      if (this.workflow.evtModel.hasEvent("PRETS", pk)) {
        var evt = this.workflow.evtModel.getEvent("PRETS", pk).get("evt").get(target.attr("evtid"));

        if (!STR.isBlank(evt)) {
          var ev = "consulterPret";
          if (evt.get("dts").sou == true) {
            ev = "consulterSo";
          }

          var date = target.parent().parent().attr("data-date");
          this.workflow.trigger("popup:open", ev, this.workflow.get("collabId"), evt.get("dts"), date, date, evt.get("val"), evt.get("val"));
        }
      } else if (this.workflow.evtModel.hasEvent("AFFECTATIONS", pk)) {
        var evt = this.workflow.evtModel.getEvent("AFFECTATIONS", pk).get("evt").get(target.attr("evtid"));

        if (!STR.isBlank(evt)) {
          var ev = "consulterPret";
          if (evt.get("dts").sou == true) {
            ev = "consulterSo";
          }

          var date = target.parent().parent().attr("data-date");
          this.workflow.trigger("popup:open", ev, this.workflow.get("collabId"), evt.get("dts"), date, date, evt.get("val"), evt.get("val"));
        }
      }

      event.stopPropagation();
    }
  },

  _toggleTotalisationsMenu: function(event) {
    var menuContent = $(".phx-totalisations-groups ul", this.el);

    if (menuContent.is(":visible")) {
      menuContent.hide();
    } else {
      menuContent.show();

      var self = this;
      var closeMenu = function(event) {
        var element = $(self.el).find(".phx-totalisations-groups").find(event.target);

        if (STR.isBlank($(event.target).parent(".phx-totalisations-groups .ui-menu-item")[0]) && //not a menu element
          (STR.isBlank($(event.target).attr("class")) || (element.length === 0))) {
          self._toggleTotalisationsMenu(event);
        } else {
          $(document).one("mousedown", closeMenu);
        }
      };

      $(document).one("mousedown", closeMenu);
    }
  },

  _selectMenuElement: function(event) {
    var li = $(event.currentTarget).closest("li");
    var list = li.parent();

    var opt = li.children("a").attr("menu-opt");
    this.workflow.get("totals").set("val", parseInt(opt.replace("total-groups-", "")));

    var valoHab = "COL_SITUVAL.V";
    if (this.workflow.get("utilisateur") === this.workflow.RESPONSABLE_TYPE) {
      valoHab = "RES_RESCPT.V";
    }

    if (HABILITATION.canView(valoHab) === true) {
      list.find(".phx-icon-planresp-valider").hide();
      li.find(".phx-icon-planresp-valider").show();

      $(".phx-agenda-view:visible .phx-agenda-viewmenu-totals .phx-icon-planresp-valider").hide();
      $(".phx-agenda-view:visible .phx-agenda-viewmenu-totals a[menu-opt=total-" + parseInt(opt.replace("total-groups-", "")) + "]").siblings(".phx-icon-planresp-valider").show();

      this.workflow.trigger("rerender:totalisations", this.workflow.get("viewType"));
    }
  },

  _drawTotalisations: function() {
    if (this.workflow.get("panelsState").get("bottom") == "S") {
      this.workflow.get("panelsState").set("bottom", "H");
    }

    if ((this.workflow.get("synt").get("val") === false) || STR.isBlank(this.workflow.get("synt").get("val"))) {
      var totalisations = this.$el.find(".vuemensuel-totalisations");

      $(".vuemensuel-days:visible").css("width", "96%");
      $(".vuemensuel-totalisations:visible").css("width", "0px");
      $(".phx-totalisations-groups:visible").css("display", "none");

      totalisations.empty();
    } else {
      var divisionCells = $(".vuemensuel-calendrier:visible").hasClass("c5") ? 6 : 8
      var cellWidth = ($(".agenda_zone_center:visible").width() - 35) / divisionCells;
      var totalisations = this.$el.find(".vuemensuel-totalisations");

      totalisations.css("display", "inline-block");
      totalisations.empty();
      totalisations.css("width", cellWidth);

      totalisations.append(this._renderTotalisationsGroups());
      var totalisationsContent = $("<div class=\"totalisations-content\">");
      totalisationsContent.append(this._renderTotalisationsHeader());
      totalisationsContent.append(this._renderTotalisationsBody());
      totalisations.append(totalisationsContent);

      if (totalisations.length > 0) {
        $(".vuemensuel-days").css("width", "calc(96% - " + cellWidth + "px)");
      }

    }
  },

  _renderTotalisationsGroups: function() {
    var output = $("<div class=\"phx-totalisations-groups ui-menu-icons\">");
    var current = this.workflow.get("totals").get("val");
    var list = $("<ul class=\"ui-menu\" tabindex=\"0\" role=\"menu\">");

    //if ( this.workflow.totalsMensuelGroups.attributes.length > 0 ) {
    if (_.size(this.workflow.totalsMensuelGroups.attributes) > 0) {

      $.each(this.workflow.totalsMensuelGroups.attributes, function(ix, group) {
        var liElement = $("<li class=\"ui-menu-item phx-hover\"  tabindex=\"-1\"  role=\"menuitem\">");
        var spanElement = $("<span>").addClass("phx-icon phx-icon-planresp-valider");
        spanElement.attr("menu-group", "total-groups");
        if (parseInt(ix) !== current) {
          spanElement.attr("style", "display:none");
        }
        var aElement = $("<a>").attr("menu-opt", "total-groups-" + ix).text(group.libelle);
        aElement.prepend(spanElement);
        liElement.append(aElement);
        list.append(liElement);
      });

      var arrow = $("<button type=\"button\" class=\"display-totalisations-groups ui-button ui-corner-all ui-button-icon-only\" role=\"button\"><span class=\"ui-button-icon-primary ui-icon ui-icon-triangle-1-s\">");

      output.append(arrow);
      output.append(list);
    }

    return output;
  },

  _renderTotalisationsHeader: function() {
    var selectedDay = TYPE.DATE.strToDate(this.selectedDate).getDay();

    var output = $("<div class=\"vuemensuel-totalisations-header vuemensuel-header\">");

    var titles = this.workflow.totalsMensuelModel.get("lib");

    if (!STR.isBlank(titles)) {
      _.each(titles, function(title) {
        var cell = $("<div class=\"vuemensuel-totalisation-cell-header\">");
        cell.text(title.nom);
        cell.attr("data-idx", title.idx);

        var tooltip = "";
        tooltip = title.lib + " (" + title.nom + ")";
        cell.attr("title", tooltip);

        output.append(cell);
      });
    }

    return output;
  },

  _renderTotalisationsBody: function() {
    var drawData = {};
    drawData.cMonth = this.currentMonth;
    drawData.cYear = this.currentYear;
    var nbRows = UTILS.getNumWeeksForMonth(drawData.cMonth + 1, drawData.cYear);
    var self = this;

    var formats = new Array();
    _.each(this.workflow.totalsMensuelModel.get("lib"), function(column) {
      formats[column.idx] = column.fmt;
    });

    var output = $("<div class=\"vuemensuel-totalisations-body\">");

    for (var i = 0; i < nbRows; ++i) {
      var weekDay = new Date(drawData.cYear, drawData.cMonth, (i * 7 + 1));
      var weekNumber = $.datepicker.iso8601Week(weekDay);

      var weekRow = $("<div class=\"vuemensuel-row\">");

      var totals = this.workflow.totalsMensuelModel.get("totalisations");
      var data = [];

      if (!STR.isBlank(totals)) {
        _.each(totals, function(total) {
          if (total.semaine == weekNumber) {
            data = total.data;
          }
        });
      }

      _.each(data, function(item) {
        var nbWeek = $("<div class=\"vuemensuel-totalisation-value\">");
        var nbWeekWrapper = $("<div>");

        if (!STR.isBlank(item.val)) {
          var format = formats[item.idx];

          nbWeekWrapper.text(TYPE.HOUR_MINUTE.format(item.val, format));

          if (item.val < 0) {
            nbWeekWrapper.addClass("ui-phx-valorisation-negative");
          }
        } else {
          nbWeekWrapper.text("NC");
          nbWeekWrapper.attr("title", i18n.t('agenda.valoNonCalc'));
          nbWeekWrapper.addClass("ui-phx-resultat-invalide");
        }

        nbWeek.attr("data-idx", item.idx);

        nbWeek.append(nbWeekWrapper);
        weekRow.append(nbWeek);
      });

      output.append(weekRow);
    }

    return output;
  },

  setContext: function(options) {
    this.context = options.context;

    this.selectedDate = this.context.ctxDateEnEvidence;

    var date = TYPE.DATE.strToDate(this.context.ctxDateEnEvidence);

    this.currentDay = date.getDate();
    this.currentMonth = date.getMonth();
    this.currentYear = date.getFullYear();

    this.render();
  },

  _setDroppable: function(selection) {
    var self = this;
    selection.droppable({
      scope: "agenda-items",
      addClasses: false,
      activeClass: "",
      tolerance: "pointer",
      drop: function(event, ui) {
        var div = $(event.target);
        var code = ui.helper[0].attributes.code.value;
        var libelle = null;
        if (ui.helper[0].attributes.libelle) {
          libelle = ui.helper[0].attributes.libelle.value;
        }

        var buttonCode;
        var type = "";
        if (ui.draggable.attr("class").split(" ")[0].indexOf("abs") === 0) {
          type = "dropAbs";
        } else if (ui.draggable.attr("class").split(" ")[0].indexOf("reg") === 0) {
          type = "dropReg";
        } else if (ui.draggable.attr("class").split(" ")[0].indexOf("hor") === 0) {
          type = "dropHor";
        } else if (ui.draggable.attr("class").split(" ")[0].indexOf("act_") === 0) {
          var typo = ui.draggable.attr("class").split(" ")[0].split("act_")[1];
          type = "dropAct_" + typo;
          buttonCode = ui.draggable.data("data");
        }

        if (!STR.isBlank(self.workflow.selectionMonthModel.get("dateStart")) && !STR.isBlank(self.workflow.selectionMonthModel.get("dateEnd"))) {
          var startDay = self.workflow.selectionMonthModel.get("dateStart");
          var endDay = self.workflow.selectionMonthModel.get("dateEnd");

          self.workflow.trigger("popup:open", type, self.workflow.get("collabId"), code, startDay, endDay, undefined, undefined, libelle, buttonCode);
        } else {
          var startDay = div.attr("data-date");
          var endDay = div.attr("data-date");

          self.workflow.trigger("popup:open", type, self.workflow.get("collabId"), code, startDay, endDay, undefined, undefined, libelle, buttonCode);
        }

      }
    });
  },

  render: function(callback) {
    var json = { 'i18n': i18n };
    this.$el.html(this.template(json));

    this._drawWeeks();
    this._drawCalendar();
    this._drawTotalisations();

    this._setDroppable(this.$el.find(".vuemensuel-day"));

    return this;
  },

  _drawWeeks: function() {
    var drawData = {};
    drawData.cMonth = this.currentMonth;
    drawData.cYear = this.currentYear;
    drawData.nbRows = UTILS.getNumWeeksForMonth(drawData.cMonth + 1, drawData.cYear);
    drawData.today = new Date();

    var todayWeek = $.datepicker.iso8601Week(drawData.today);

    var selectedWeek;
    if (!STR.isBlank(this.selectedDate)) {
      selectedWeek = $.datepicker.iso8601Week(TYPE.DATE.strToDate(this.selectedDate));
    }

    var content = this.$el.find(".vuemensuel-weeks");
    content.append($("<div class=\"vuemensuel-header\">"));

    for (var i = 0; i < drawData.nbRows; ++i) {
      var weekDay = new Date(drawData.cYear, drawData.cMonth, (i * 7 + 1));
      var weekNumber = $.datepicker.iso8601Week(weekDay);

      var weekRow = $("<div class=\"vuemensuel-row\">");

      var nbWeek = $("<div class=\"vuemensuel-nweek\">");
      var nbWeekWrapper = $("<div>");
      nbWeekWrapper.text(weekNumber);

      if (weekNumber == todayWeek) {
        nbWeek.addClass("ui-phx-date-heure-actuelle");
      }

      if (!STR.isBlank(selectedWeek) && weekNumber == selectedWeek) {
        nbWeek.addClass("ui-phx-date-heure-selectionnee");
      }

      nbWeek.append(nbWeekWrapper);
      weekRow.append(nbWeek);
      content.append(weekRow);
    }
  },

  _drawCalendar: function() {

    if (this.workflow.get("viewType") !== "M")
      return;

    var drawData = {};
    drawData.cMonth = this.currentMonth;
    drawData.cYear = this.currentYear;
    // min number of rows 5
    drawData.nbRows = (UTILS.getNumWeeksForMonth(drawData.cMonth + 1, drawData.cYear) > 5) ? UTILS.getNumWeeksForMonth(drawData.cMonth + 1, drawData.cYear) : 5;
    drawData.nbDays = UTILS.daysInMonth(drawData.cMonth, drawData.cYear);
    drawData.prevMonthDays = UTILS.daysInMonth(drawData.cMonth - 1, drawData.cYear);
    drawData.today = new Date();

    var mFirstDay = new Date(drawData.cYear, drawData.cMonth, "01");

    drawData.initDay = mFirstDay.getDay();

    var calendar = this.$el.find(".vuemensuel-days");
    calendar.empty();

    calendar.append(this._drawHeader(drawData));
    calendar.append(this._drawDays(drawData));

    this.$el.find(".vuemensuel-calendrier").removeClass("r6").removeClass("r5");
    this.$el.find(".vuemensuel-calendrier").addClass("r" + drawData.nbRows);

    if (!STR.isBlank(this.workflow.get("options").get("affSemTrevail")) && this.workflow.get("options").get("affSemTrevail")) {
      this.$el.find(".vuemensuel-calendrier").addClass("c5");
    } else {
      this.$el.find(".vuemensuel-calendrier").addClass("c7");
    }

    this.calendar = calendar;
  },

  _drawHeader: function(drawData) {
    var selectedDay = TYPE.DATE.strToDate(this.selectedDate).getDay();

    var output = $("<div class=\"vuemensuel-days-header vuemensuel-header\">");

    for (var i = 1; i < 7; ++i) {
      var cell = $("<div class=\"vuemensuel-cell-header\">");
      cell.text(i18n.t('common:dayNames', { returnObjects: true })[i]);

      if (drawData.today.getMonth() === drawData.cMonth && drawData.today.getFullYear() === drawData.cYear && drawData.today.getDay() == i) {
        cell.addClass("ui-phx-date-heure-actuelle");
      }
      if (selectedDay === i) {
        cell.addClass("ui-phx-date-heure-selectionnee");
      }

      output.append(cell);
    }

    var cell = $("<div class=\"vuemensuel-cell-header\">");
    cell.text(i18n.t('common:dayNames', { returnObjects: true })[0]);

    if (drawData.today.getDay() === 0) {
      cell.addClass("ui-phx-date-heure-actuelle");
    }
    if (selectedDay === 0) {
      cell.addClass("ui-phx-date-heure-selectionnee");
    }

    output.append(cell);

    return output;
  },

  _drawDays: function(drawData) {
    var day = new Date(drawData.cYear, drawData.cMonth, 0);
    var selectedDay = TYPE.DATE.strToDate(this.selectedDate);
    var displayHorTop = true;
    var output = $("<div class=\"vuemensuel-days-body\">");

    if (GLOBAL_DATA.paramDivers.get("AgdMHorEvt") && parseInt(GLOBAL_DATA.paramDivers.get("AgdMHorEvt").get("valeur")) === 2) {
      displayHorTop = false;
    }

    if (drawData.initDay != 1) {
      day.setDate(day.getDate() - (drawData.initDay === 0 ? 7 : drawData.initDay) + 1);
    }

    for (var i = 0; i < drawData.nbRows; ++i) {
      var wrow = $("<div class=\"vuemensuel-row\">");

      for (var j = 0; j < 7; ++j) {
        day.setDate(day.getDate() + 1);
        var dateformat = "" + day.getFullYear() + ((day.getMonth() + 1) < 10 ? "0" + (day.getMonth() + 1) : (day.getMonth() + 1)) + (day.getDate() < 10 ? "0" + day.getDate() : day.getDate());

        var cell = $("<div class=\"vuemensuel-day vuemensuel-cell\" data-type=\"\" data-date=\"\">");

        var cellHeader = this._drawCellHeader($("<div class=\"vuemensuel-day-header\">"), day.getDate(), dateformat, (day.getMonth() == drawData.cMonth), displayHorTop);
        var cellBody = this._drawCellBody($("<div class=\"vuemensuel-day-body\">"), dateformat, displayHorTop);
        var cellFooter = this._drawCellFooter($("<div class=\"vuemensuel-day-footer\">"), dateformat, cellBody);

        if (day.getFullYear() === drawData.today.getFullYear() &&
          day.getMonth() === drawData.today.getMonth() &&
          day.getDate() === drawData.today.getDate()) {
          cell.addClass("ui-phx-date-heure-actuelle-border");
        }

        if (!STR.isBlank(selectedDay) &&
          i === UTILS.getWeekNumOfDate(selectedDay) - 1 &&
          j === (selectedDay.getDay() - 1)) {
          cell.addClass("ui-phx-date-heure-selectionnee-border-imp");
          cell.addClass("js-heure-selectionnee");
        }
        if (!STR.isBlank(selectedDay) &&
          i === UTILS.getWeekNumOfDate(selectedDay) - 1 &&
          selectedDay.getDay() === 0 &&
          j === 6) {
          cell.addClass("ui-phx-date-heure-selectionnee-border-imp");
          cell.addClass("js-heure-selectionnee");
        }

        if (day.getMonth() != drawData.cMonth) {
          cell.attr("data-type", "out");
        } else {
          if (this.isJourExceptionnel(dateformat)) {
            cell.attr("data-type", "exceptionnel");
            cellHeader.addClass("ui-phx-jours-exceptionnel");
          } else if (this.isJourFerie(dateformat)) {
            cell.attr("data-type", "ferie");
            cellHeader.addClass("ui-phx-jours-ferie");
          } else if (this.isJourHorRepos(dateformat)) {
            cell.attr("data-type", "repos");
            cell.addClass("ui-phx-horaire-repos");
          } else {
            cell.attr("data-type", "normal");
          }
        }
        cell.attr("data-date", dateformat);
        cell.attr("data-week", $.datepicker.iso8601Week(day));

        cell.append(cellHeader).append(cellBody).append(cellFooter);

        wrow.append(cell);
      }
      output.append(wrow)
    }

    return output;
  },

  _drawCellHeader: function(wrapper, nbDay, format, inMonth, displayHorTop) {
    var headerLeft = $("<span class=\"vuemensuel-day-header-number\">");
    var headerRight = $("<span class=\"vuemensuel-day-header-info\">");
    var displayColor = true;

    var pk = this.workflow.get("collabId") + "," + format;
    var valParamDivAstRegul = GLOBAL_DATA.paramDivers.get("AstRegul").get("valeur");
    var valParamDivAstRegulCh = GLOBAL_DATA.paramDivers.get("AstRegulCh").get("valeur");
    var valParamDivAstRegulType = GLOBAL_DATA.paramDivers.get("AstRegulType").get("valeur");
    var licenceGas = LICENCE.hasModule("GAS");

    //Restitution Régularisation d'astreintes
    var habRegAst = "RES_REG.V";
    if (this.workflow.get("utilisateur") === this.workflow.COLLABORATEUR_TYPE) {
      habRegAst = "COL_REG.V";
    }

    if (this.workflow.evtModel.hasEvent("ASTREINTES", pk) && HABILITATION.canView(habRegAst) == true && this.workflow.getLayersModel().get("ast") == true) {
      //TODO Redmine 13729
      //			if (licenceGas && valParamDivAstRegul =="1" && !STR.isBlank(valParamDivAstRegulCh) && typeof(valParamDivAstRegulType) !== "undefined"){
      wrapper.css("border-bottom", "4px solid black");
      //			}
    }

    if (this.context && this.context.ctxEcran != "agenda") {
      if (this.context && parseInt(this.context.ctxHorAffNa) === 0) {
        displayColor = false;
      }
    } else {
      if (GLOBAL_DATA.paramDivers.get("AgdMHorCnh") && parseInt(GLOBAL_DATA.paramDivers.get("AgdMHorCnh").get("valeur")) === 0) {
        displayColor = false;
      }
    }

    if (inMonth === true) {
      if (displayHorTop) {
        headerRight.text(this.headerRender(format));

        //tooltip
        var tooltip = this._buildHoraireTooltip(format);
        headerRight.attr("title", tooltip);

        if (displayColor) {
          if (!this.isJourExceptionnel(format) && !this.isJourFerie(format)) {
            headerLeft.addClass(this.headerColor(format));
          }

          headerRight.addClass(this.headerColor(format));
        }

        headerRight.addClass("js-horaire");
      }
    }

    headerLeft.text(nbDay);

    this._headerLeftTooltip(headerLeft, format);

    wrapper.append(headerLeft).append(headerRight);

    var showZones = 0;
    if (GLOBAL_DATA.paramDivers.get("GTvissco")) {
      showZones = parseInt(GLOBAL_DATA.paramDivers.get("GTvissco").get("valeur"));
    }

    if (showZones == 0 || showZones == 1) {
      var day = this.workflow.mainModel.models[0].get("cel").get(format);
      if (!STR.isBlank(day)) {
        var zone = day.get("zsc");
        if (!STR.isBlank(zone)) {
          var lisereZone = $("<span class=\"lisere lisere-zone\">");
          lisereZone.css("background-color", "rgb(" + zone.coul.coder + ", " + zone.coul.codeg + ", " + zone.coul.codeb + ")");
          lisereZone.attr("title", zone.lib);

          wrapper.append(lisereZone);
        }
      }
    }

    return wrapper;
  },

  _headerLeftTooltip: function(headerLeft, date) {
    var selectedDate = TYPE.DATE.strToDate(this.workflow.get("selectedDate"));
    var startDate = TYPE.DATE.strToDate(this.workflow.get("startDate"));
    var endDate = TYPE.DATE.strToDate(this.workflow.get("endDate"));

    // Days
    var table = this.$el.find("table.phx-agenda-center-header-dais");

    table.empty();

    var tr = $("<tr>");

    var dateTemp = new Date(TYPE.DATE.strToDate(date).getTime());
    // First row with day names

    var td = $("<td>");
    var actualStrDate = TYPE.DATE.dateToStr(dateTemp, TYPE.DATE.DEFAULT_MASK_BBDD);
    // Set day property
    td.prop("day", actualStrDate);

    var jex = false;
    var jfe = false;

    //Apply style colors to the headers
    var element = this.workflow.mainModel.models[0].get("cel").get(actualStrDate);
    var styleClass = "";
    if (element && element.get("jex") == true) {
      styleClass = "ui-phx-jours-exceptionnel";
      jex = true;
    } else if (element && element.get("jfe") == true) {
      styleClass = "ui-phx-jours-ferie";
      jfe = true;
    } else if (dateTemp.getDay() == 0 || dateTemp.getDay() == 6) {
      var pk = this.workflow.get("collabId") + "," + actualStrDate;
      var hasRepos = false;
      if (this.workflow.evtModel.hasEvent("HORAIRES", pk)) {
        var evts = this.workflow.evtModel.getEvent("HORAIRES", pk).get("evt");
        for (var i = 0; evts && i < evts.length; i++) {
          var cod = evts.at(i).get("cod");
          var dts = this.workflow.dtsModel.HORAIRES.get(cod);
          if (!STR.isBlank(dts) && dts.get("re") == true) {
            hasRepos = true;
            break;
          }
        }
      }
      if (hasRepos == false) {
        styleClass = "ui-phx-entete-jour-week-end";
      }
    }

    var headerTitle = "<b>" + TYPE.DATE.format(date, TYPE._getFormatByCode("DATE_L")) + i18n.t('agenda.headerTitleSem', { "0": $.datepicker.iso8601Week(TYPE.DATE.strToDate(date)) }) + "</b>";
    if (jex == true) {
      headerTitle += "<br/>" + i18n.t('agenda.headerTitleJex');
    } else if (jfe == true) {
      headerTitle += "<br/>" + i18n.t('agenda.headerTitleJfl');
    }
    var dc = this.workflow.mainModel.models[0].get("dc");

    if (!STR.isBlank(dc)) {
      if (actualStrDate <= dc) {
        var titlePc = "<label>" + i18n.t('agenda.headerTitlePcPerio') + " </label>" + TYPE.DATE.format(dc);
        headerTitle += "<br/>" + titlePc;
      } else {
        var titlePnc = "<label>" + i18n.t('agenda.headerTitlePncPerio') + " </label>" + TYPE.DATE.format(dc);
        headerTitle += "<br/>" + titlePnc;
      }
    }
    var dep = this.workflow.mainModel.models[0].get("dep");
    if (!STR.isBlank(dep)) {
      if (actualStrDate <= dep) {
        var titlePe = "<label>" + i18n.t('agenda.headerTitlePePerio') + " </label>" + TYPE.DATE.format(dep);
        headerTitle += "<br/>" + titlePe;
      } else {
        var titlePne = "<label>" + i18n.t('agenda.headerTitlePnePerio') + " </label>" + TYPE.DATE.format(dep);
        headerTitle += "<br/>" + titlePne;
      }
    }

    // ::::::: add Structure P to HeaderTooltip ::::::::::::::
    var hab = "COL_AFFPRINC.V";
    if (this.workflow.get("utilisateur") == this.workflow.RESPONSABLE_TYPE) {
      hab = "RES_AFFPRINC.V";
    }
    if (HABILITATION.canView(hab) == true) {

      headerTitle = headerTitle += "<br/>";

      // headerTitle = headerTitle + this._buildStructTooltip(this.workflow.get("selectedDate"));
      headerTitle = headerTitle + this._buildStructTooltip(actualStrDate);
    }

    var div = "<div title='" + headerTitle + "' style='position:relative;text-align:center'";
    if (this._isDateSelected(dateTemp) === true) {
      div += " class='ui-phx-date-heure-selectionnee " + styleClass + "'";
    } else if (this._isDateToday(dateTemp) === true && this._isDateSelected(dateTemp) === false && ((element &&
        element.get("jfe") == false && element.get("jex") == false) || !element)) {
      div += " class='ui-phx-date-heure-actuelle " + styleClass + "'";
    } else if (!STR.isBlank(styleClass)) {
      div += " class='" + styleClass + "'";
    }

    div += "><label style='left:5px;position:absolute;'>" + dateTemp.getDate() + "</label><label style='margin-left:10px'>" + i18n.t('common:dayNames', { returnObjects: true })[dateTemp.getDay()] + "</label></div>";

    td.html(div);
    tr.append(td);

    headerLeft.attr("title", headerTitle);
  },
  _isDateToday: function(date) {
    var result = false;
    if (date && TYPE.DATE.dateToStr(date) == TYPE.DATE.dateToStr(SYNC.getServerDate())) {
      result = true;
    }
    return result;
  },

  _isDateSelected: function(date) {
    var result = false;
    var selectedDate = TYPE.DATE.strToDate(this.workflow.get("selectedDate"));
    if (date && TYPE.DATE.dateToStr(date) == TYPE.DATE.dateToStr(selectedDate)) {
      result = true;
    }
    return result;
  },
  _buildStructTooltip: function(date) {
    var tooltip = "";

    var affDate = date;
    /*if (this.previousDay == true) {
      affDate = UTILS.addDaysString(affDate, -1);
    }*/
    var affCod = null;

    affCod = this._getCodePrinc(affDate);

    if (STR.isBlank(affCod)) {
      return tooltip;
    }

    var dtsModel = this.workflow.dtsModel.AFFECTATIONS.get(affCod);

    if (!STR.isBlank(dtsModel)) {
      var txtTitle = "";
      txtTitle = "<span>" + i18n.t('agenda.tt_aff_princ') + "</span><br/>";
      tooltip += txtTitle;
      tooltip += this._getTooltipText(dtsModel);
    }

    return tooltip;
  },

  _getCodePrinc: function(date) {
    var prevCodePrinc = null;

    var pk = this.workflow.get("collabId") + "," + date;
    if (this.workflow.evtModel.hasEvent(this.workflow.AFFECTATIONS_TYPE, pk)) {
      var evts = this.workflow.evtModel.getEvent(this.workflow.AFFECTATIONS_TYPE, pk).get("evt");
      for (var i = 0; evts && i < evts.length; i++) {
        var evt = evts.at(i);
        prevCodePrinc = evt.get("dts").ppl;
        break;
      }
    }
    return prevCodePrinc;
  },
  _getTooltipText: function(dtsModel) {
    var txt = "";

    if (!STR.isBlank(dtsModel) && !STR.isBlank(dtsModel.get("dts"))) {
      var levels = dtsModel.get("dts");
      txt = "<table style=\"width:500px\">";
      var marginLeft = 0;
      var tabAux = "<span class=\"ui-icon\" style=\"display:inline-block; background:none; padding:0; margin-left:10px\"></span>";
      if (levels[0].niv > 0) {
        // paint chemin when node is not a root node
        for (var j = 1; j < levels.length; j++) {
          var tab = "<span class=\"ui-icon ui-icon-arrowreturn-1-e\" style=\"display:inline-block; padding:0; margin-left:" + marginLeft + "px\"></span>";
          if (j == 1) {
            txt += "<tr><td style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:300px\">" + levels[j].lib + "(" + levels[j].cod + ")</td><td class=\"phx-chemin-tooltip-text\" style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:200px\">" + tabAux + i18n.t('common:chemin.structure') + "</td></tr>";
          } else {
            var niveau = levels[j].lbnv;
            txt += "<tr><td style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:300px\">" + tab + levels[j].lib + "(" + levels[j].cod + ")</td><td class=\"phx-chemin-tooltip-text\" style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:200px\">" + tabAux + niveau + "</td></tr>";
            marginLeft += 10;
          }
        }
        var tab = "<span class=\"ui-icon ui-icon-arrowreturn-1-e\" style=\"display:inline-block; padding:0; margin-left:" + marginLeft + "px\"></span>";
        var niveau = levels[0].lbnv;
        txt += "<tr><td style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:300px\">" + tab + levels[0].lib + "(" + levels[0].cod + ")</td><td class=\"phx-chemin-tooltip-text\" style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:200px\">" + tabAux + niveau + "</td></tr>";
        txt += "</table>";
      } else {
        // paint chemin when node is a root node
        var tabAux = "<span class=\"ui-icon\" style=\"display:inline-block; background:none; padding:0; margin-left:10px\"></span>";
        txt += "<tr><td style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:300px\">" + levels[0].lib + "(" + levels[0].cod + ")</td><td class=\"phx-chemin-tooltip-text\" style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:200px\">" + tabAux + i18n.t('common:chemin.structure') + "</td></tr>";
      }
    }
    return txt;
  },
  /**
   * Creating a structure that has divs by events in every cell
   */
  _drawCellBody: function(container, dateformat, displayHorTop) {
    var pk = this.workflow.get("collabId") + "," + dateformat;
    var arrayEvents = this._eventsInDay(dateformat, displayHorTop);

    for (var i = 0; i < arrayEvents.length; i++) {
      var events = this._renderCellBody(arrayEvents[i], dateformat);
      if (!STR.isBlank(events) && events.length > 0) {
        _.each(events, function(event) {
          container.append(event);
        });
      }
    }

    return container;
  },

  /**
   * We declare an array that will have:
   * type - is the name witch we like to call the divs (class) that will be created.
   * events - is the array with all the events from every kind of evenements
   * number - is to know the number of event (of every kind of evenements) that we will create
   */
  _eventsInDay: function(dateformat, displayHorTop) {
    var pk = this.workflow.get("collabId") + "," + dateformat;
    var self = this;

    var events;
    var typeEvent;
    var eventsTypArray = [];

    //Horaires
    if (!displayHorTop) {
      if (this.workflow.evtModel.hasEvent("HORAIRES", pk)) {
        events = [];
        typeEvent = this.workflow.evtModel.get("HORAIRES");

        for (var i = 0; i < typeEvent.length; i++) {
          if (dateformat === typeEvent.at(i).get("dat")) {
            events.push(typeEvent.at(i));
          }
        }

        if (events.length > 0) {
          eventsTypArray.push({
            "type": "HORAIRES",
            "events": events,
            "numberEvents": events.length
          });
        }
      }
    }

    //Presences
    if (this.workflow.evtModel.hasEvent("PRESENCES", pk)) {
      events = [];
      typeEvent = this.workflow.evtModel.get("PRESENCES");

      for (var i = 0; i < typeEvent.length; i++) {
        if (dateformat === typeEvent.at(i).get("dat")) {
          events.push(typeEvent.at(i));
        }
      }

      if (events.length > 0) {
        this.typeGerer = "badgeages";
        eventsTypArray.push({
          "type": "PRESENCES",
          "events": events,
          "numberEvents": events.length
        });
      } else {
        this.typeGerer = "onebadgeage";
      }
    }

    //Affectations secondaires
    if (this.workflow.evtModel.hasEvent("AFFECTATIONS", pk)) {
      events = [];
      typeEvent = this.workflow.evtModel.get("AFFECTATIONS");

      for (var i = 0; i < typeEvent.length; i++) {
        if (dateformat === typeEvent.at(i).get("dat")) {
          var affec = typeEvent.at(i);
          if (affec.get("evt").models[0].get("dts").sec.length > 0) {
            _.each(affec.get("evt").models[0].get("dts").sec, function(affsec) {
              events.push(affsec);
            });
          }
        }
      }

      if (events.length > 0) {
        eventsTypArray.push({
          "type": "AFFECTATIONS",
          "events": events,
          "numberEvents": events.length
        });
      }
    }

    //Prets
    if (this.workflow.evtModel.hasEvent("PRETS", pk)) {
      events = [];
      typeEvent = this.workflow.evtModel.get("PRETS");

      for (var i = 0; i < typeEvent.length; i++) {
        if (dateformat === typeEvent.at(i).get("dat")) {
          events.push(typeEvent.at(i));
        }
      }

      if (events.length > 0) {
        eventsTypArray.push({
          "type": "PRETS",
          "events": events,
          "numberEvents": events.length
        });
      }
    }

    //Absences
    if (this.workflow.evtModel.hasEvent("ABSENCES", pk)) {
      events = [];
      typeEvent = this.workflow.evtModel.get("ABSENCES");

      for (var i = 0; i < typeEvent.length; i++) {
        if (dateformat === typeEvent.at(i).get("dat")) {
          events.push(typeEvent.at(i));
        }
      }

      if (events.length > 0) {
        eventsTypArray.push({
          "type": "ABSENCES",
          "events": events,
          "numberEvents": events.length
        });
      }
    }

    //Activites
    if (this.workflow.evtModel.hasEvent("ACTIVITES", pk)) {
      events = [];
      typeEvent = this.workflow.evtModel.get("ACTIVITES");

      for (var i = 0; i < typeEvent.length; i++) {
        if (dateformat === typeEvent.at(i).get("dat")) {
          events.push(typeEvent.at(i));
        }
      }

      if (events.length > 0) {
        eventsTypArray.push({
          "type": "ACTIVITES",
          "events": events,
          "numberEvents": events.length
        });
      }
    }
    // SPECIFIQUE VDP debut

    //Regularisations
    if (this.workflow.evtModel.hasEvent("REGULARISATIONS", pk)) {
      events = [];
      typeEvent = this.workflow.evtModel.get("REGULARISATIONS");

      for (var i = 0; i < typeEvent.length; i++) {
        if (dateformat === typeEvent.at(i).get("dat")) {
          events.push(typeEvent.at(i));
        }
      }

      if (events.length > 0) {
        eventsTypArray.push({
          "type": "REGULARISATIONS",
          "events": events,
          "numberEvents": events.length
        });
      }
    }
    // SPECIFIQUE VDP fin

    return eventsTypArray;
  },

  _renderCellBody: function(eventContainer, dateformat) {
    var events = new Array();
    var typeEvent = eventContainer.type;
    var self = this;
    var date = dateformat;
    var plusOpt = this.workflow.get("plusopt");

    switch (typeEvent) {
      case "HORAIRES":
        if (plusOpt.get("plusHorJ") === true) {
          _.each(eventContainer.events[0].get("evt").models, function(event) {
            events.push(self._renderHoraire($("<div data-typeEvent='HORAIRES' >"), event, date));
          });
        }
        break;
      case "PRESENCES":
        if (plusOpt.get("plusPreMis") === true) {
          events.push(self._renderPresences($("<div data-typeEvent='PRESENCES'>"), eventContainer.events[0].get("evt").models, date));
        }
        break;
      case "PRETS":
        if (plusOpt.get("plusAff") === true) {
          _.each(eventContainer.events[0].get("evt").models, function(event) {
            events.push(self._renderPrets($("<div data-typeEvent='PRETS'>"), event, date));
          });
        }
        break;
      case "AFFECTATIONS":
        if (plusOpt.get("plusAff") === true) {
          _.each(eventContainer.events, function(event) {
            events.push(self._renderAffectations($("<div data-typeEvent='AFFECTATIONS'>"), event, date));
          });
        }
        break;
      case "ABSENCES":
        _.each(eventContainer.events[0].get("evt").models, function(event) {
          if (event.get("dts").sou == true && plusOpt.get("plusSou") === true) {
            events.push(self._renderAbsences($("<div data-typeEvent='ABSENCES'>"), event, date));
          } else if (event.get("dts").sou == false && plusOpt.get("plusAbs") === true) {
            events.push(self._renderAbsences($("<div data-typeEvent='ABSENCES'>"), event, date));
          }
        });
        break;
      case "ACTIVITES":
        _.each(eventContainer.events[0].get("evt").models, function(event) {
          if (plusOpt.get("plus" + event.get("dts").tpo) === true) {
            events.push(self._renderActivites($("<div data-typeEvent='ACTIVITES'>"), event, date));
          }
        });
        break;
        //SPECIFIQUE VDP debut
      case "REGULARISATIONS":

        let teletravailElement = eventContainer.events[0].attributes.evt.models[0];
        if (eventContainer.events[0].attributes.evt.models.length > 1) {
          //check if one of the events is not validated (more that one teletravail reg on the same day)
          _.each(eventContainer.events[0].attributes.evt.models, function(iterator) {
            if ((iterator.attributes.cod === "160" || iterator.attributes.cod === "162" || iterator.attributes.cod === "164") &&
              (iterator.attributes.dts.eta === "X" || iterator.attributes.dts.eta === "D"))
              teletravailElement = iterator;
          });
        }

        events.push(self._renderRegularisations($("<div data-typeEvent='REGULARISATIONS'>"), teletravailElement, date));
        break;
        //SPECIFIQUE VDP fin
      default:
        break;
    }

    return events;
  },

  _renderHoraire: function(wrapper, event, dateformat) {
    var dts = this.workflow.dtsModel.HORAIRES.get(event.get("val"));

    if (!STR.isBlank(dts)) {
      //Text
      var typeHor = 1;

      if (GLOBAL_DATA.paramDivers.get("AgdMHorInf")) {
        typeHor = parseInt(GLOBAL_DATA.paramDivers.get("AgdMHorInf").get("valeur"));
      }

      var horaireTdu = $("<span class=\"horaire-tdu\">");

      if (dts.get("re") === true) {
        var horaireLibelle = $("<span class=\"horaire-libelle-only\">");
        horaireLibelle.text(dts.get("ab8"));
      } else {
        var suffix = "";

        if (this.isHoraireExceptionnel(dateformat)) {
          var suffix = "*";
        }

        if (typeHor === 1) {
          var horaireLibelle = $("<span class=\"horaire-libelle-ext\">");
          horaireLibelle.text(suffix + dts.get("ab4") + " ");
          horaireTdu.text(TYPE.HOUR_MINUTE_NIGHTLY.format(dts.get("ho")) + "..." + TYPE.HOUR_MINUTE_NIGHTLY.format(dts.get("hf")));
        } else if (typeHor === 2) {
          var horaireLibelle = $("<span class=\"horaire-libelle\">");
          horaireLibelle.text(suffix + dts.get("lib") + " ");
          horaireTdu.text(TYPE.HOUR_MINUTE.format(dts.get("tdu"), TYPE._getFormatByCode("DUREEHM")));
        } else if (typeHor === 3) {
          var horaireLibelle = $("<span class=\"horaire-libelle\">");
          horaireLibelle.text(suffix + dts.get("ab8") + " ");
          horaireTdu.text(TYPE.HOUR_MINUTE.format(dts.get("tdu"), TYPE._getFormatByCode("DUREEHM")));
        }
      }

      wrapper.append(horaireLibelle).append(horaireTdu);

      //Color
      var displayColor = true;

      if (this.context && this.context.ctxEcran != "agenda") {
        if (this.context && parseInt(this.context.ctxHorAffNa) === 0) {
          displayColor = false;
        }
      } else {
        if (GLOBAL_DATA.paramDivers.get("AgdMHorCnh") && parseInt(GLOBAL_DATA.paramDivers.get("AgdMHorCnh").get("valeur")) === 0) {
          displayColor = false;
        }
      }

      if (displayColor && parseInt(GLOBAL_DATA.paramDivers.get("AgdMHorCnh").get("valeur")) === 1) {
        var nature = this.workflow.mainModel.dtsModel.NATHOR.get(dts.get("nat"));

        if (!STR.isBlank(nature)) {
          wrapper.addClass(nature.get("aff"));
        }
      }

      if (displayColor && parseInt(GLOBAL_DATA.paramDivers.get("AgdMHorCnh").get("valeur")) === 2) {
        var matin = dts.get("hm");
        var soir = dts.get("hs");
        var nuit = dts.get("hn");
        var repos = dts.get("re");

        // EVO 545 presence jour added
        var jour = dts.get("hj");
        var parametreDiversIndJourneeValeur = GLOBAL_DATA.paramDivers.get("indJournee").get("valeur");

        if (!STR.isBlank(matin) && !STR.isBlank(soir) && !STR.isBlank(nuit) && !STR.isBlank(repos) && !STR.isBlank(jour)) {
          if (repos) {
            wrapper.removeAttr('class');
            wrapper.addClass("ui-phx-horaire-repos");
          } else if (nuit && matin && soir) {
            wrapper.removeAttr('class');
            wrapper.addClass("ui-phx-horaire-jour-nuit");
          } else if (nuit && matin) {
            wrapper.removeAttr('class');
            wrapper.addClass("ui-phx-horaire-matin-nuit");
          } else if (nuit && soir) {
            wrapper.removeAttr('class');
            wrapper.addClass("ui-phx-horaire-soir-nuit");
          } else if (nuit && !jour) {
            wrapper.removeAttr('class');
            wrapper.addClass("ui-phx-horaire-nuit");
          } else if (matin && soir) {
            wrapper.removeAttr('class');
            wrapper.addClass("ui-phx-horaire-jour");
          } else if (matin && !soir) {
            wrapper.removeAttr('class');
            wrapper.addClass("ui-phx-horaire-matin");
          } else if (!matin && soir) {
            wrapper.removeAttr('class');
            wrapper.addClass("ui-phx-horaire-soir");
          } else if (parametreDiversIndJourneeValeur == 1 && jour && !nuit) {
            wrapper.removeAttr('class');
            wrapper.addClass("ui-phx-horaire-jour1");
          } else if (parametreDiversIndJourneeValeur == 1 && jour && nuit) {
            wrapper.removeAttr('class');
            wrapper.addClass("ui-phx-horaire-jour1-nuit");
          } else {
            wrapper.removeAttr('class');
            wrapper.addClass("ui-phx-presence-dans-lequipe");
          }
        }
      }
    }

    //Tooltip
    var tooltip = this._buildHoraireTooltip(dateformat);

    wrapper.attr("title", tooltip);

    wrapper.addClass("ui-phx-horaire");
    wrapper.addClass("js-horaire");

    return wrapper;
  },

  // SPECIFIQUE CASVP - Affichage adapté Diapo 6 - DEB
  _renderPresences: function(wrapper, events, dateformat) {
    var hStart = "";
    var hEnd = "";
    var hSegStart = "";
    var hSegEnd = "";
    var firstSC, firstEC, secSC, secEC, unionC = "";
    var validate = false;
    var tooltip = "";
    var formattedDate = TYPE.DATE.format(dateformat, TYPE._getFormatByCode("DATE_A"));
    var cod, codf = "";
    var evtnum = 0;

    var status = null;
    var presIndValidate = "";

    if (_.isEmpty(events[0].get("dts"))) {
      validate = true;
    }

    _.each(events, function(event) {

      evtnum++;
      cod = (event.get("cod") === null) ? '' : event.get("cod");
      codf = (event.get("codf") === null) ? '' : event.get("codf");
      status = event.get("dts").sta;
      presIndValidate = (status !== "A" && status !== "H" && !validate) ? "?" : "";

      if (validate) {

        if (events.length === 2) {

          if (!STR.isBlank(hStart) && STR.isBlank(hSegStart)) {
            hSegStart = presIndValidate + TYPE.HOUR_MINUTE_NIGHTLY.format(event.get("val")).replace(" ", "");
            secSC = (cod === STR.toFirstUpperCase(cod)) ? ' class="ui-presence-soir"' : "";
          }

          if (!STR.isBlank(hEnd) && STR.isBlank(hSegEnd)) {
            hSegEnd = presIndValidate + TYPE.HOUR_MINUTE_NIGHTLY.format(event.get("valf")).replace(" ", "");
            secEC = (codf === STR.toFirstUpperCase(codf)) ? ' class="ui-presence-soir"' : "";
          }

          if (STR.isBlank(hStart)) {
            hStart = presIndValidate + TYPE.HOUR_MINUTE_NIGHTLY.format(event.get("val")).replace(" ", "");
            firstSC = (cod === STR.toFirstUpperCase(cod)) ? ' class="ui-presence-soir"' : "";
          }

          if (STR.isBlank(hEnd)) {
            hEnd = presIndValidate + TYPE.HOUR_MINUTE_NIGHTLY.format(event.get("valf")).replace(" ", "");
            firstEC = (codf === STR.toFirstUpperCase(codf)) ? ' class="ui-presence-soir"' : "";
          }

        } else {

          if (events.length > 2) {
            var _rest = events.slice(1, -1);

            _.each(_rest, function() {
              unionC = (cod === STR.toFirstUpperCase(cod) || codf === STR.toFirstUpperCase(codf)) ? ' class="ui-presence-soir"' : "";
            });
          }

          if (STR.isBlank(hStart)) {
            hStart = presIndValidate + TYPE.HOUR_MINUTE_NIGHTLY.format(event.get("val")).replace(" ", "");
            firstSC = (cod === STR.toFirstUpperCase(cod)) ? ' class="ui-presence-soir"' : "";
          }

          if (STR.isBlank(hEnd)) {
            hEnd = presIndValidate + TYPE.HOUR_MINUTE_NIGHTLY.format(event.get("valf")).replace(" ", "");
            firstEC = (codf === STR.toFirstUpperCase(codf)) ? ' class="ui-presence-soir"' : "";
          }

        }

      } else {
        if (events.length === 3 || events.length === 4) {

          if (!STR.isBlank(hEnd) && (STR.isBlank(hSegStart) && event.get("cod").toLowerCase() === "e")) {
            hSegStart = presIndValidate + TYPE.HOUR_MINUTE_NIGHTLY.format(event.get("val")).replace(" ", "");
            secSC = (cod === STR.toFirstUpperCase(cod)) ? ' class="ui-presence-soir"' : "";
          } else if (!STR.isBlank(hEnd) && (STR.isBlank(hSegEnd))) {
            hSegEnd = presIndValidate + TYPE.HOUR_MINUTE_NIGHTLY.format(event.get("val")).replace(" ", "");
            secEC = (cod === STR.toFirstUpperCase(cod)) ? ' class="ui-presence-soir"' : "";
          }

          if (STR.isBlank(hStart)) {
            hStart = presIndValidate + TYPE.HOUR_MINUTE_NIGHTLY.format(event.get("val")).replace(" ", "");
            firstSC = (cod === STR.toFirstUpperCase(cod)) ? ' class="ui-presence-soir"' : "";
          } else if (STR.isBlank(hEnd)) {
            hEnd = presIndValidate + TYPE.HOUR_MINUTE_NIGHTLY.format(event.get("val")).replace(" ", "");
            firstEC = (cod === STR.toFirstUpperCase(cod)) ? ' class="ui-presence-soir"' : "";
          }

        } else {

          if (events.length > 4) {
            var _rest = events.slice(1, -1);

            _.each(_rest, function() {
              if (STR.isBlank(unionC)) {
                unionC = (cod === STR.toFirstUpperCase(cod) || codf === STR.toFirstUpperCase(codf)) ? ' class="ui-presence-soir"' : "";
              }
            });
          }

          if (events.length <= 4) {
            if (!STR.isBlank(hStart) && !STR.isBlank(hEnd) && STR.isBlank(hSegStart)) {
              hSegStart = presIndValidate + TYPE.HOUR_MINUTE_NIGHTLY.format(event.get("val")).replace(" ", "");
              secSC = (cod === STR.toFirstUpperCase(cod)) ? ' class="ui-presence-soir"' : "";

              if (STR.isBlank(hSegEnd) && event.get("cod").toLowerCase() === "s") {
                hSegEnd = presIndValidate + TYPE.HOUR_MINUTE_NIGHTLY.format(event.get("val")).replace(" ", "");
                secEC = (cod === STR.toFirstUpperCase(cod)) ? ' class="ui-presence-soir"' : "";
              } else if (STR.isBlank(hSegEnd) && event.get("codf")) {
                hSegEnd = presIndValidate + TYPE.HOUR_MINUTE_NIGHTLY.format(event.get("valf")).replace(" ", "");
                secEC = (codf === STR.toFirstUpperCase(codf)) ? ' class="ui-presence-soir"' : "";
              }
            }

          } else {
            if (!STR.isBlank(hEnd)) {
              if (event.get("cod").toLowerCase() === "s" || evtnum === events.length) {
                hEnd = presIndValidate + TYPE.HOUR_MINUTE_NIGHTLY.format(event.get("val")).replace(" ", "");
                firstEC = (cod === STR.toFirstUpperCase(cod)) ? ' class="ui-presence-soir"' : "";
              } else if (event.get("codf")) {
                hEnd = presIndValidate + TYPE.HOUR_MINUTE_NIGHTLY.format(event.get("valf")).replace(" ", "");
                firstEC = (codf === STR.toFirstUpperCase(codf)) ? ' class="ui-presence-soir"' : "";
              }
            }
          }

          if (STR.isBlank(hStart) && event.get("cod").toLowerCase() === "e") {
            hStart = presIndValidate + TYPE.HOUR_MINUTE_NIGHTLY.format(event.get("val")).replace(" ", "");
            firstSC = (cod === STR.toFirstUpperCase(cod)) ? ' class="ui-presence-soir"' : "";
          }

          if (STR.isBlank(hEnd) && event.get("cod").toLowerCase() === "s" || (evtnum === events.length && evtnum !== 1)) {
            hEnd = presIndValidate + TYPE.HOUR_MINUTE_NIGHTLY.format(event.get("val")).replace(" ", "");
            firstEC = (cod === STR.toFirstUpperCase(cod)) ? ' class="ui-presence-soir"' : "";
          } else if (STR.isBlank(hEnd) && event.get("codf")) {
            hEnd = presIndValidate + TYPE.HOUR_MINUTE_NIGHTLY.format(event.get("valf")).replace(" ", "");
            firstEC = (codf === STR.toFirstUpperCase(codf)) ? ' class="ui-presence-soir"' : "";
          }

        }
      }

      var endtext = "";
      if (event.get("cod").toLowerCase() === "e" || event.get("cod").toLowerCase() === "r") {
        endtext = i18n.t('agenda.presencesInfoEntree');
        if (event.get("cod").toLowerCase() === "r") {
          endtext = i18n.t('agenda.presencesInfoRetour');
        }
      } else {
        endtext = i18n.t('agenda.presencesInfoSortie');
        if (event.get("cod").toLowerCase() === "d") {
          endtext = i18n.t('agenda.presencesInfoDepart');
        }
      }

      tooltip += "<span><b>" + formattedDate + "</b></span><label> " + i18n.t('agenda.presencesInfoA') + " </label><span><b> " + TYPE.HOUR_MINUTE_NIGHTLY.format(event.get("val")) + "</b></span><span> " + endtext + "</span><br/>";

      // When "codf" and "valf" have a value, - add new lines in the tooltip  (customer 181758)
      if (event.get("valf") && event.get("codf")) {

        var endtextF = "";
        if (event.get("codf").toLowerCase() === "e" || event.get("codf").toLowerCase() === "r") {
          endtextF = i18n.t('agenda.presencesInfoEntree');
          if (event.get("codf").toLowerCase() === "r") {
            endtextF = i18n.t('agenda.presencesInfoRetour');
          }
        } else {
          endtextF = i18n.t('agenda.presencesInfoSortie');
          if (event.get("codf").toLowerCase() === "d") {
            endtextF = i18n.t('agenda.presencesInfoDepart');
          }
        }

        tooltip += "<span><b>" + formattedDate + "</b></span><label> " + i18n.t('agenda.presencesInfoA') + " </label><span><b> " + TYPE.HOUR_MINUTE_NIGHTLY.format(event.get("valf")) + "</b></span><span> " + endtextF + "</span><br/>";

      }
    });

    var union = "";

    if (!STR.isBlank(hStart) && !STR.isBlank(hEnd)) {
      union = "-";

      if (validate && events.length > 2) {
        union = "...";
      } else if (!validate && events.length > 2) {
        union = ((events.length === 3 || events.length === 4)) ? "-" : "...";
      }
    }

    var presencePrefix = $("<span class=\"presence-prefix\">");
    var presenceInfo = $("");

    if (validate) {
      presencePrefix.text(i18n.t('agenda.presenceValidate'));
    }

    if ((!validate && !STR.isBlank(hSegStart)) || (validate && events.length === 2)) {
      presenceInfo = $('<span class="presence-info"><span' + firstSC + '>' + hStart + '</span><span' + unionC + '>' + union + '</span><span' + firstEC + '>' + hEnd + '</span><span' + unionC + '>' + union + '</span><span' + secSC + '>' + hSegStart + '</span><span' + unionC + '>' + union + '</span><span' + secEC + '>' + hSegEnd + '</span></span>');
    } else {
      presenceInfo = $('<span class="presence-info"><span' + firstSC + '>' + hStart + '</span><span' + unionC + '>' + union + '</span><span' + firstEC + '>' + hEnd + '</span></span>');
    }

    wrapper.append(presencePrefix).append(presenceInfo);

    //Tooltip

    wrapper.attr("title", tooltip);

    // Add js events

    return wrapper;
  },
  // SPECIFIQUE CASVP - Affichage adapté Diapo 6 - FIN

  _renderAbsences: function(wrapper, event, dateformat) {
    var dts = event.get("dts");
    var wfdts = this.workflow.dtsModel.ABSENCES.get(event.get("cod"));
    var group = this.workflow.dtsModel.GROUPS_ABSENCE.get(wfdts.get("grp"));

    if ((this.workflow.get("options").get("evt_val") === false && dts.sta !== "A" && dts.sta !== "H") || dts.sta === "R") {
      return this;
    }

    var params = _.clone(dts.par);
    params["@absDateDebut"] = "<b>" + TYPE.DATE.format(params["@absDateDebut"], TYPE._getFormatByCode("DATE_A")) + "</b>";
    params["@absDateFin"] = "<b>" + TYPE.DATE.format(params["@absDateFin"], TYPE._getFormatByCode("DATE_A")) + "</b>";
    params["@absHeureDuree"] = TYPE.HOUR_MINUTE.format(params["@absHeureDuree"], TYPE._getFormatByCode("DUREEHM"));

    //check hours
    params["@absHeureDebut"] = TYPE.HOUR_MINUTE_NIGHTLY.format(params["@absHeureDebut"]);
    params["@absHeureFin"] = TYPE.HOUR_MINUTE_NIGHTLY.format(params["@absHeureFin"]);

    wrapper.attr("evtid", event.get("uid"));

    //time
    var abscensetime = $("<span class=\"body-cell-time\">");
    var time = "";
    if ((dts.par["@absUniteDebut"] !== "H" || dts.par["@absUniteFin"] !== "H") && (dts.par["@absDateDebut"] || dts.par["@absDateFin"])) {
      if (dts.par["@absDateDebut"] == dateformat) {
        if (!STR.isBlank(dts.par["@absUniteDebut"])) {
          time = "(" + dts.par["@absUniteDebut"] + ")";
        } else if (!STR.isBlank(dts.par["@absHeureDebut"])) {
          time = TYPE.HOUR_MINUTE_NIGHTLY.format(dts.par["@absHeureDebut"]) + "...";
        }
      } else if (dts.par["@absDateFin"] == dateformat) {
        if (!STR.isBlank(dts.par["@absUniteFin"])) {
          time = "(" + dts.par["@absUniteFin"] + ")";
        } else if (!STR.isBlank(dts.par["@absHeureFin"])) {
          time = "..." + TYPE.HOUR_MINUTE_NIGHTLY.format(dts.par["@absHeureFin"]);
        }
      } else {
        if (!STR.isBlank(dts.par["@absUniteDebut"]) && !STR.isBlank(dts.par["@absUniteFin"]) && (dts.par["@absUniteDebut"] == dts.par["@absUniteFin"])) {
          time = "(" + dts.par["@absUniteDebut"] + ")";
        } else {
          time = "(J)";
        }
      }
    } else if (dts.par["@absHeureDebut"]) {
      time = TYPE.HOUR_MINUTE_NIGHTLY.format(dts.par["@absHeureDebut"]) + "...";
    } else if (dts.par["@absHeureFin"]) {
      time = "..." + TYPE.HOUR_MINUTE_NIGHTLY.format(dts.par["@absHeureFin"]);
    } else if (dts.par["@absHeureDuree"]) {
      time = TYPE.HOUR_MINUTE.format(dts.par["@absHeureDuree"], TYPE._getFormatByCode("DUREEHM"));
    }

    abscensetime.text(time);
    wrapper.append(abscensetime);

    //body
    var abscenseText = $("<span class=\"body-cell-text\">");
    var abscenseInd = $("<span class=\"body-cell-indicateur\">");
    var abscenceIndContent = "";

    if (this.workflow.get("indicateurs")) {
      if (dts.sou === false) {
        abscenceIndContent += i18n.t('agenda.absenceab');
      } else {
        abscenceIndContent += i18n.t('agenda.absenceso');
      }
    }

    if (dts.sta !== "A" && dts.sta !== "H") {
      abscenceIndContent += "?";
    }

    if (!STR.isBlank(abscenceIndContent)) {
      abscenseInd.text(abscenceIndContent);
      wrapper.append(abscenseInd);
    }

    var displayMotif = true;

    if (this.context && !STR.isBlank(this.context.ctxLibelleAbs)) {
      if (parseInt(this.context.ctxLibelleAbs) == 1) {
        var displayMotif = false;
      }
    } else if (GLOBAL_DATA.paramDivers.get("PLAbsLibGr") && parseInt(GLOBAL_DATA.paramDivers.get("PLAbsLibGr").get("valeur")) === 1) {
      var displayMotif = false;
    }

    var libelle = "**";

    if (displayMotif == true) {
      if (!STR.isBlank(params["@absLibelleMotif"])) {
        var libelle = params["@absLibelleMotif"];
      }
    } else if (!STR.isBlank(group)) {
      var libelle = group.get("lib");
    }

    abscenseText.text(libelle);
    wrapper.append(abscenseText);

    //Color
    if (displayMotif == true) {
      wrapper.css({ "background-color": wfdts.get("group").get("bgcolor"), "color": wfdts.get("group").get("color") });
    } else {
      wrapper.css({ "background-color": group.get("bgcolor"), "color": group.get("color") });
    }

    //Periode
    if (dts.per == true) {
      if (dts.par["@absDateDebut"] !== dateformat) {
        wrapper.append($("<span class='icon-periode-start phx-icon-agenda-periode-w phx-icon'>"))
      }

      if (dts.par["@absDateFin"] !== dateformat) {
        wrapper.append($("<span class='icon-periode-end phx-icon-agenda-periode-e phx-icon'>"))
      }
    }

    //Tooltip
    var tooltip = "";

    var line1 = this._buildAbsenceMessage(dts.lib, params);
    var line3 = !STR.isBlank(dts.com) ? dts.com : "";
    var line4 = "";

    if (!STR.isBlank(dts.acp) && !STR.isBlank(dts.acn) && !STR.isBlank(dts.sai) && !STR.isBlank(dts.acd)) {
      var nom = dts.acp.substring(0, 1) + "." + dts.acn;
      var sai = TYPE.DATE.format(dts.sai, TYPE._getFormatByCode("DATE_A"));
      var acd = TYPE.DATE.format(dts.acd, TYPE._getFormatByCode("DATE_A"));

      line4 = "<label for ='agenda_saidate'>" + i18n.t('agenda.absencetooltipSaisi') + " </label><span class='agenda_saidate'><b>" + sai + "</b><label for='agenda_acp'>" +
        i18n.t('agenda.absencetooltipDerniere') + " </label><span class='agenda_acp'><b>" + nom + "</b></span><label for='agenda_acd'> " + i18n.t('agenda.absencetooltipLe') +
        " </label><span class='agenda_acd'><b>" + acd + "</b></span>";

    }

    var h1 = "<label for ='agenda_abstable'><b>" + i18n.t('agenda.absencetooltipAbs') + "</b></label>";
    if (dts.sou === true) {
      h1 = "<label for ='agenda_abstable'><b>" + i18n.t('agenda.absencetooltipSou') + "</b></label>";
    }

    var h2 = "";
    if ((this.workflow.wkfActiv.souhait && (event.get("dts").sou === true)) || (this.workflow.wkfActiv.absence && (event.get("dts").sou === false))) {
      switch (dts.sta) {
        case "D":
        case "T":
          h2 += "<label class='ui-phx-statut-demande'><b>";
          break;
        case "I":
          h2 += "<label class='ui-phx-statut-en-cours'><b>";
          break;
        case "A":
          h2 += "<label class='ui-phx-statut-accepte'><b>";
          break;
        case "R":
          h2 += "<label class='ui-phx-statut-refuse'><b>";
          break;
        case "H":
          h2 += "<label class='ui-phx-statut-hors-validation'><b>";
          break;
        default:
          break;
      }

      var staLib = "";
      staLib = dts.sta.libelle;
      if (STR.isBlank(staLib)) {
        var workflowStatus = this.workflow.dtsModel.WORKFLOWS.get(dts.sta);

        if (!STR.isBlank(workflowStatus)) {
          staLib = workflowStatus.get("lib");
        }
      }
      h2 += staLib + "</b></label>";
    }
    var tooltip = "<table style='width: 100%'><tr><td align='left' width='45%'><label><b>" + h1 + "</b></label></td><td align='left'>" + h2 + "</td></tr></table>";

    if (!STR.isBlank(line1) && line1.indexOf("null") == -1) {
      tooltip += "<br/>" + line1;
    }
    if (!STR.isBlank(line3)) {
      tooltip += "<br/>" + line3;
    }
    if (!STR.isBlank(line4)) {
      tooltip += "<br/>" + line4;
    }

    wrapper.attr("title", tooltip);

    // Add js events
    wrapper.addClass("js-event-selectionable");
    wrapper.addClass("js-event-absence");

    return wrapper;
  },

  _renderPrets: function(wrapper, event, dateformat) {
    var dts = event.get("dts");
    var status = dts.sta || null;

    if ((this.workflow.get("options").get("evt_val") === false && status !== "A" && status !== "H") || status === "R") {
      return this;
    }

    wrapper.attr("evtid", event.get("uid"));

    //time
    var pretTime = $("<span class=\"body-cell-time\">");
    var time = "";
    if ((dts.mod == "UNITE" || dts.mod == "PERIOC") && (dts.deb || dts.fin)) {
      if (dts.deb == dateformat) {
        if (!STR.isBlank(dts.ude)) {
          time = "(" + dts.ude + ")";
        } else if (!STR.isBlank(dts.hed)) {
          time = TYPE.HOUR_MINUTE_NIGHTLY.format(dts.hed) + "...";
        }
      } else if (dts.fin == dateformat) {
        if (!STR.isBlank(dts.ufi)) {
          time = "(" + dts.ufi + ")";
        } else if (!STR.isBlank(dts.hef)) {
          time = "..." + TYPE.HOUR_MINUTE_NIGHTLY.format(dts.hef);
        }
      } else if (dts.ude && dts.ufi && dts.ude == dts.ufi) {
        time = "(" + dts.ude + ") ";
      } else {
        time = "(J) ";
      }
    } else if (dts.hed) {
      time = TYPE.HOUR_MINUTE_NIGHTLY.format(dts.hed) + "...";
    } else if (dts.hef) {
      time = "..." + TYPE.HOUR_MINUTE_NIGHTLY.format(dts.hef);
    } else if (dts.het) {
      time = TYPE.HOUR_MINUTE.format(dts.het, TYPE._getFormatByCode("DUREEHM"));
    }

    pretTime.text(time);
    wrapper.append(pretTime);

    //body
    var pretText = $("<span class=\"body-cell-text\">");
    var pretInd = $("<span class=\"body-cell-indicateur\">");
    var pretIndContent = "";

    if (this.workflow.get("indicateurs")) {
      pretIndContent += i18n.t('agenda.affpret');
    }

    if (status !== "A" && status !== "H") {
      pretIndContent += "?";
    }

    if (!STR.isBlank(pretIndContent)) {
      pretInd.text(pretIndContent);
      wrapper.append(pretInd);
    }

    var libelle = dts.libstruct + " (" + dts.codstruct + ")";

    pretText.text(libelle);
    wrapper.append(pretText);

    wrapper.addClass("ui-phx-pret");

    //Periode
    if (dts.deb !== dateformat) {
      wrapper.append($("<span class='icon-periode-start phx-icon-agenda-periode-w phx-icon'>"))
    }

    if (dts.fin !== dateformat) {
      wrapper.append($("<span class='icon-periode-end phx-icon-agenda-periode-e phx-icon'>"))
    }

    // Tooltip
    var dtsModel = this.workflow.dtsModel.AFFECTATIONS.get(dts.cod);
    var tooltip = "";

    if (dtsModel) {
      var txtTitle = "";
      var txtDates = "";
      var txtHours = "";
      var txtMotif = "";

      if (dts.pret == true) {
        txtTitle = "<span>" + i18n.t('agenda.tt_pret') + "</span><br/>";
      } else {
        txtTitle = "<span>" + i18n.t('agenda.tt_emprunt') + "</span><br/>";
      }

      tooltip += txtTitle;

      var dates = "";
      if (dts.deb > dtsModel.get("deb") && dts.fin >= dtsModel.get("fin")) {
        dates = i18n.t('common:chemin.sitpartdu', { "0": TYPE.DATE.format(dts.deb) });
      } else if (dts.deb <= dtsModel.get("deb") && dts.fin < dtsModel.get("fin")) {
        dates = i18n.t('common:chemin.sitjusq', { "0": TYPE.DATE.format(dts.fin) });
      } else if (dts.deb > dtsModel.get("deb") && dts.fin < dtsModel.get("fin") && dts.deb != dts.fin) {
        dates = i18n.t('common:chemin.sitduau', { "0": TYPE.DATE.format(dts.deb), "1": TYPE.DATE.format(dts.fin) });
      } else if (dts.deb > dtsModel.get("deb") && dts.fin < dtsModel.get("fin") && dts.deb == dts.fin) {
        dates = i18n.t('common:chemin.sitdatedu', { "0": TYPE.DATE.format(dts.fin) });
      }

      if (dates) {
        txtDates = "<span>" + dates + "</span><br/>";
      }
      tooltip += txtDates;

      if (!STR.isBlank(dts.hed) && !STR.isBlank(dts.hef) && !STR.isBlank(dts.het)) {
        txtHours = "<span>" + i18n.t('agenda.tt_aff_dea', { "0": TYPE.HOUR_MINUTE.format(dts.hed), "1": TYPE.HOUR_MINUTE.format(dts.hef), "2": TYPE.DURATION.HOUR_MINUTE.format(dts.het).replace(/\ /g, "") }) + "</span><br/>";
      } else {
        var auxTxt = "";
        if (dts.ude == "J") {
          auxTxt = this._getUnite(dts.ude);
        } else {
          auxTxt = i18n.t('agenda.tt_aff_duau', { "0": this._getUnite(dts.ude), "1": this._getUnite(dts.ufi) });
        }
        txtHours = "<span>" + auxTxt + "</span><br/>";
      }
      tooltip += txtHours;

      if (dts.pret == true) {
        tooltip += this._getPretTooltipText(dtsModel);
      }

      var pretDts = this.workflow.dtsModel.PRETS.get(dts.mot);
      if (!STR.isBlank(pretDts)) {
        txtMotif = "<span>" + pretDts.get("lib") + " (" + pretDts.get("cod") + ")</span><br/>";
      }
      tooltip += txtMotif;
    }

    wrapper.attr("title", tooltip);

    // Add js events
    wrapper.addClass("js-event-selectionable");
    wrapper.addClass("js-event-pret");

    return wrapper;
  },

  _renderAffectations: function(wrapper, event, dateformat) {
    var dts = this.workflow.dtsModel.AFFECTATIONS.get(event.cod);
    var affTime = $("<span class=\"body-cell-time\">");

    var time = "(" + event.uni + ")";

    affTime.text(time);
    wrapper.append(affTime);

    var affText = $("<span class=\"body-cell-text\">");

    if (this.workflow.get("indicateurs")) {
      var affInd = $("<span class=\"body-cell-indicateur\">");
      affInd.text(i18n.t('agenda.affseco'));
      wrapper.append(affInd);
    }

    var libelle = dts.get("dts")[0].lib + " (" + dts.get("dts")[0].cod + ")";

    affText.text(libelle);
    wrapper.append(affText);

    wrapper.addClass("ui-phx-affsec");

    //tooltip
    var tooltip = "";

    if (dts) {
      var txtTitle = "";

      txtTitle = "<span>" + i18n.t('agenda.tt_aff_sec') + "</span><br/>";
      tooltip += txtTitle;

      tooltip += this._getPretTooltipText(dts);
    }

    wrapper.attr("title", tooltip);

    // Add js events
    wrapper.addClass("js-event-selectionable");
    wrapper.addClass("js-event-affectation");

    return wrapper;
  },

  _renderActivites: function(wrapper, event, dateformat) {
    var dts = event.get("dts");
    var dtsModel = this.workflow.dtsModel.ACTIVITES.get(event.get("cod"));
    var fam = this.workflow.dtsModel.FAM_ACTIVITE.get(dts.fam);
    var status = dts.sta ? dts.sta.code : null;

    if ((this.workflow.get("options").get("evt_val") === false && status !== "A" && status !== "H") || status === "R") {
      return this;
    }

    wrapper.attr("evtid", event.get("uid"));

    //time
    var actTime = $("<span class=\"body-cell-time\">");
    var time = "";
    if ((dts.mod == "UNITE" || dts.mod == "PERIOC") && (dts.deb || dts.fin)) {
      if (dts.deb == dateformat) {
        if (!STR.isBlank(dts.ude)) {
          time = "(" + dts.ude + ") ";
        } else if (!STR.isBlank(dts.hed)) {
          time = TYPE.HOUR_MINUTE_NIGHTLY.format(dts.hed) + "...";
        }
      } else if (dts.fin == dateformat) {
        if (!STR.isBlank(dts.ufi)) {
          time = "(" + dts.ufi + ") ";
        } else if (!STR.isBlank(dts.hef)) {
          time = "..." + TYPE.HOUR_MINUTE_NIGHTLY.format(dts.hef);
        }
      } else if (dts.ude && dts.ufi && dts.ude == dts.ufi) {
        time = "(" + dts.ude + ") ";
      } else {
        time = "(J) ";
      }
    } else if (dts.hed) {
      time = TYPE.HOUR_MINUTE_NIGHTLY.format(dts.hed) + "...";
    } else if (dts.hef) {
      time = "..." + TYPE.HOUR_MINUTE_NIGHTLY.format(dts.hef);
    } else if (dts.het) {
      time = TYPE.HOUR_MINUTE.format(dts.het, TYPE._getFormatByCode("DUREEHM"));
    }

    actTime.text(time);
    wrapper.append(actTime);

    //body
    var actText = $("<span class=\"body-cell-text\">");
    var actInd = $("<span class=\"body-cell-indicateur\">");
    var actIndContent = "";

    if (this.workflow.get("indicateurs")) {
      if (dts.typ === "P") {
        actIndContent += i18n.t('agenda.activiteprevue');
      } else if (dts.typ === "R") {
        actIndContent += i18n.t('agenda.activiterealisee');
      }
    }

    if (status !== "A" && status !== "H") {
      actIndContent += "?";
    }

    if (!STR.isBlank(actIndContent)) {
      actInd.text(actIndContent);
      wrapper.append(actInd);
    }

    var libelle = dtsModel.get("lib") + " (" + dtsModel.get("cod") + ")";

    actText.text(libelle);
    wrapper.append(actText);

    //Color
    if (STR.isBlank(dts.fam) || dts.fam == "EMPTY") {
      if (this.event.get("dts") && this.event.get("dts").typ == "P") {
        wrapper.addClass("ui-phx-activite-prevue");
      } else if (this.event.get("dts") && this.event.get("dts").typ == "R") {
        wrapper.addClass("ui-phx-activite-realisee");
      }
    } else {
      wrapper.css({ "background-color": fam.get("bgcolor"), "color": fam.get("color") });
      //wrapper.addClass("phx-dynamicStyle-cell-act_" + UTILS.escapeJQueryString(dts.fam));
    }

    //Periode
    if (dts.deb !== dateformat) {
      wrapper.append($("<span class='icon-periode-start phx-icon-agenda-periode-w phx-icon'>"))
    }

    if (dts.fin !== dateformat) {
      wrapper.append($("<span class='icon-periode-end phx-icon-agenda-periode-e phx-icon'>"))
    }

    //Tooltip
    var tooltip = "";

    var line1 = this._buildActiviteMessage(dts, libelle);

    var typAux = GLOBAL_DATA.typologies.get(dts.tpo).get("evenement");
    var type = typAux.substr(0, 1).toUpperCase() + typAux.substr(1);
    var h1 = "<label for ='agenda_acttable'><b>" + type + "</b></label>";

    var h2 = "";
    switch (dts.sta.code) {
      case "D":
      case "T":
        h2 += "<label class='ui-phx-statut-demande'><b>";
        break;
      case "I":
        h2 += "<label class='ui-phx-statut-en-cours'><b>";
        break;
      case "A":
        h2 += "<label class='ui-phx-statut-accepte'><b>";
        break;
      case "R":
        h2 += "<label class='ui-phx-statut-refuse'><b>";
        break;
      case "H":
        h2 += "<label class='ui-phx-statut-hors-validation'><b>";
        break;
      default:
        h2 += "<label><b>";
        break;
    }

    var line4 = "";
    if (!STR.isBlank(dts.acp) && !STR.isBlank(dts.acn) &&
      !STR.isBlank(dts.sai) && !STR.isBlank(dts.acd)) {
      var nom = dts.acp.substring(0, 1) + "." + dts.acn;
      var sai = TYPE.DATE.format(dts.sai, TYPE._getFormatByCode("DATE_A"));
      var acd = TYPE.DATE.format(dts.acd, TYPE._getFormatByCode("DATE_A"));
      line4 = "<label for ='agenda_saidate'>" + i18n.t('agenda.absencetooltipSaisi') + " </label><span class='agenda_saidate'><b>" + sai +
        "</b></span><label for='agenda_acp'>" + i18n.t('agenda.absencetooltipDerniere') + " </label><span class='agenda_acp'><b>" + nom +
        "</b></span><label for='agenda_acd'> " + i18n.t('agenda.absencetooltipLe') + " </label><span class='agenda_acd'><b>" + acd + "</b></span>";
    }

    var staLib = "";
    if (!STR.isBlank(dts.sta.libelle)) {
      staLib = dts.sta.libelle;
    }
    h2 += staLib + "</b></label>";

    tooltip = "<table style='width: 100%'><tr><td align='left' width='45%'><label><b>" + h1 + "</b></label></td><td align='left'>" + h2 + "</td></tr></table>";

    if (!STR.isBlank(line1) && line1.indexOf("null") == -1) {
      tooltip += "<br/>" + line1;
    }

    if (!STR.isBlank(line4)) {
      tooltip += "<br/>" + line4;
    }

    wrapper.attr("title", tooltip);

    // Add js events
    wrapper.addClass("js-event-selectionable");
    wrapper.addClass("js-event-activite");

    return wrapper;
  },

  //SPECIFIQUE VDP debut

  _renderRegularisations(wrapper, event) {
    const wrapperClass = "spe-teletravail-agenda-mensuel";
    wrapper.append("<span class=" + wrapperClass + "> T </span>");
    if (event.attributes.dts.eta !== undefined && (event.attributes.dts.eta === "X" || event.attributes.dts.eta === "D"))
      wrapper.addClass("spe-teletravail-styles");

    return wrapper;
  },

  //SPECIFIQUE VDP fin

  _drawCellFooter: function(wrapper, dateformat, cellBody) {
    var optList = $("<ul>");

    optList.append($("<li><span title = '" + i18n.t('agenda.disponibiliteind') + "' class=\"hideDispo\">" + i18n.t('agenda.disponibiliteDi') + "</span></li>"));
    optList.append($("<li><span title = '" + i18n.t('agenda.regularisationsrgTooltip') + "'class=\"hideRegul\">" + i18n.t('agenda.regularisationsrg') + "</span></li>"));
    optList.append($("<li><span title = '" + i18n.t('agenda.anomaliesanTooltip') + "'class=\"hideAnom\">" + i18n.t('agenda.anomaliesan') + "</span></li>"));
    optList.append($("<li><span title = '" + i18n.t('agenda.messagesmsTooltip') + "'class=\"hideMessage\">" + i18n.t('agenda.messagesms') + "</span></li>"));

    //TODO add in future evolution
    //optList.append($("<li>Mm</li>"));
    //$(optList.children('li')[4]).addClass("hideMm");

    optList = this._renderIndicateurs(optList, dateformat, cellBody);
    wrapper.append(optList);

    return wrapper;
  },

  _renderIndicateurs: function(optList, dateformat, cellBody) {
    var noEvents = true;
    var pk = this.workflow.get("collabId") + "," + dateformat;

    //Disponibilites
    var habDisp = "RES_DISP.V";
    if (this.workflow.get("utilisateur") == this.workflow.COLLABORATEUR_TYPE) {
      habDisp = "COL_DISP.V";
    }

    if (this.workflow.evtModel.hasEvent("DISPONIBILITES", pk) && HABILITATION.canView(habDisp) == true) {
      optList.find("span").removeClass("hideDispo");
      noEvents = false;
    }

    //Regularisations
    var habReg = "RES_REG.V";
    if (this.workflow.get("utilisateur") == this.workflow.COLLABORATEUR_TYPE) {
      habReg = "COL_REG.V";
    }

    if (this.workflow.evtModel.hasEvent("REGULARISATIONS", pk) && HABILITATION.canView(habReg) == true) {
      optList.find("span").removeClass("hideRegul");
      noEvents = false;

    }

    //Anomalies
    var habAno = "RES_LEVANO.V";
    if (this.workflow.get("utilisateur") == this.workflow.COLLABORATEUR_TYPE) {
      habAno = "COL_ANO.V";
    }

    if (this.workflow.evtModel.hasEvent("ANOMALIES", pk) && HABILITATION.canView(habAno) == true) {
      optList.find("span").removeClass("hideAnom");
      noEvents = false;
    }

    //Messages
    var habMess = "RES_MESS.V";
    if (this.workflow.get("utilisateur") == this.workflow.COLLABORATEUR_TYPE) {
      habMess = "COL_MESS.V";
    }

    if (this.workflow.evtModel.hasEvent("MESSAGES", pk) && HABILITATION.canView(habMess) == true) {
      optList.find("span").removeClass("hideMessage");
      noEvents = false;
    }

    if (noEvents === true) {
      cellBody.addClass("noFooter");
      return "";
    } else {
      return optList;
    }
  },

  _buildAbsenceMessage: function(message, parametres) {
    var result = null;
    if (!STR.isBlank(message)) {
      result = message;
      if (!STR.isBlank(parametres)) {
        var array = [];
        for (var i in parametres) {
          array.push(i);
        }
        array.sort(
          function(a, b) {
            return b.length - a.length;
          }
        );
        for (var j = 0; j < array.length; j++) {
          result = result.replace(array[j], "</label><span class='agenda_absl1'>" + parametres[array[j]] + "</span><label for='agenda_absl1'>");
        }
      }
    }
    return "<label for='agenda_absl1'>" + result + "</label>";
  },

  _buildHoraireTooltip: function(date) {
    var user = this.workflow.mainModel.collabId;
    var evt = this.workflow.mainModel.evtModel.get("HORAIRES").get(user + "," + date);
    var dts = null;
    var result = null;

    if (!STR.isBlank(evt)) {
      var code = evt.get("evt").models[0].get("cod");
      var l_val = evt.get("evt").models[0].get("val");

      if (_.isNumber(l_val) && l_val != code) {
        code = l_val;
      }
      dts = this.workflow.mainModel.dtsModel.HORAIRES.get(code);
    }
    if (!STR.isBlank(dts)) {
      var json = { 'i18n': i18n };

      var tooltipPlages = new ScheduleModel({ showPlageBlank: false });
      tooltipPlages.set({
        tolouv: dts.get("too"),
        tolclo: dts.get("toc"),
        houv: dts.get("ho"),
        hclo: dts.get("hf"),
        dpf1: dts.get("f1d"),
        fpf1: dts.get("f1f"),
        dpf2: dts.get("f2d"),
        fpf2: dts.get("f2f"),
        pause: dts.get("pau")
      });

      if (tooltipPlages) {
        var tooltipTemplate = TPL_vuemensuel_horairePlageTooltip;
        var plageView = new ScheduleView(null, tooltipPlages);

        $(tooltipTemplate(json)).find(".agenda_plageGraph").html(plageView.render().el);

        // EVO 545 prints presence in the infobulle
        var repos = dts.get("re");
        var nuit = dts.get("hn");
        var matin = dts.get("hm");
        var soir = dts.get("hs");
        var jour = dts.get("hj");
        var parametreDiversIndJourneeValeur = GLOBAL_DATA.paramDivers.get("indJournee").get("valeur");

        var presence = "";

        if (repos) {
          presence = i18n.t('common:vuemensuel.hor_repos');
        } else if (nuit) {
          presence = i18n.t('common:vuemensuel.hor_nuit');
        } else if (parametreDiversIndJourneeValeur == 0 && matin && soir) {
          presence = i18n.t('common:vuemensuel.hor_journee');
        } else if (parametreDiversIndJourneeValeur == 1 && matin && soir) {
          presence = i18n.t('common:vuemensuel.hor_journee_M_A');
        } else if (parametreDiversIndJourneeValeur == 1 && jour) {
          presence = i18n.t('common:vuemensuel.hor_journee');
        } else if (matin && !soir) {
          presence = i18n.t('common:vuemensuel.hor_matin');
        } else if (!matin && soir) {
          presence = i18n.t('common:vuemensuel.hor_apresMidi');
        }

        result = $("<div/>").html(tooltipTemplate(json));
        result.find(".agenda_plageGraph").html(plageView.render().el);
        result.find(".agenda_horaire").text(dts.get("lib") + " (" + dts.get("cod") + ")");
        result.find(".agenda_presence").text(presence);
        result.find(".agenda_temps").text(TYPE.HOUR_MINUTE.format(dts.get("tdu"), TYPE._getFormatByCode("DUREEHM")));
        result = result.html();
      }
    }

    return result;
  },

  _getPretTooltipText: function(dtsModel) {
    var txt = "";

    if (!STR.isBlank(dtsModel) && !STR.isBlank(dtsModel.get("dts"))) {
      var levels = dtsModel.get("dts");
      txt = "<table style=\"width:500px\">";
      var marginLeft = 0;
      var tabAux = "<span class=\"ui-icon\" style=\"display:inline-block; background:none; padding:0; margin-left:10px\"></span>";
      if (levels[0].niv > 0) {
        // paint chemin when node is not a root node
        for (var j = 1; j < levels.length; j++) {
          var tab = "<span class=\"ui-icon ui-icon-arrowreturn-1-e\" style=\"display:inline-block; padding:0; margin-left:" + marginLeft + "px\"></span>";
          if (j == 1) {
            txt += "<tr><td style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:300px\">" + levels[j].lib + "(" + levels[j].cod + ")</td><td class=\"phx-chemin-tooltip-text\" style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:200px\">" + tabAux + i18n.t('common:chemin.structure') + "</td></tr>";
          } else {
            var niveau = levels[j].lbnv;
            txt += "<tr><td style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:300px\">" + tab + levels[j].lib + "(" + levels[j].cod + ")</td><td class=\"phx-chemin-tooltip-text\" style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:200px\">" + tabAux + niveau + "</td></tr>";
            marginLeft += 10;
          }
        }
        var tab = "<span class=\"ui-icon ui-icon-arrowreturn-1-e\" style=\"display:inline-block; padding:0; margin-left:" + marginLeft + "px\"></span>";
        var niveau = levels[0].lbnv;
        txt += "<tr><td style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:300px\">" + tab + levels[0].lib + "(" + levels[0].cod + ")</td><td class=\"phx-chemin-tooltip-text\" style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:200px\">" + tabAux + niveau + "</td></tr>";
        txt += "</table>";
      } else {
        // paint chemin when node is a root node
        var tabAux = "<span class=\"ui-icon\" style=\"display:inline-block; background:none; padding:0; margin-left:10px\"></span>";
        txt += "<tr><td style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:300px\">" + levels[0].lib + "(" + levels[0].cod + ")</td><td class=\"phx-chemin-tooltip-text\" style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:200px\">" + tabAux + i18n.t('common:chemin.structure') + "</td></tr>";
      }
    }
    return txt;
  },

  _getUnite: function(unite) {
    var result = "";
    if (!STR.isBlank(unite)) {
      switch (unite) {
        case "M":
          result = i18n.t('agenda.tt_aff_matin');
          break;
        case "S":
          result = i18n.t('agenda.tt_aff_soir');
          break;
        case "J":
          result = i18n.t('agenda.tt_aff_jour');
          break;
        case "N":
          result = i18n.t('agenda.tt_aff_nuit');
          break;
      }
    }
    return result;
  },

  _buildActiviteMessage: function(dts, motif) {
    var result = "";

    //Check dates
    var dateDeb = "</label><span class='agenda_actl1'><b>" + TYPE.DATE.format(dts.deb, TYPE._getFormatByCode("DATE_A")) + "</b></span><label for='agenda_actl1'>";
    var dateFin = "</label><span class='agenda_actl1'><b>" + TYPE.DATE.format(dts.fin, TYPE._getFormatByCode("DATE_A")) + "</b></span><label for='agenda_actl1'>";

    //check hours
    var hDeb = "";
    var hFin = "";
    if (!STR.isBlank(dts.hed) && !STR.isBlank(dts.hef) && dts.hed != dts.hef) {
      var hDebAux = dts.hed >= 2400 ? dts.hed - 2400 : dts.hed;
      hDeb = "</label><span class='agenda_actl1'>" + TYPE.HOUR_MINUTE.format(hDebAux) + "</span><label for='agenda_actl1'>";
      var hFinAux = dts.hef > 2400 ? dts.hef - 2400 : dts.hef;
      hFin = "</label><span class='agenda_actl1'>" + TYPE.HOUR_MINUTE.format(hFinAux) + "</span><label for='agenda_actl1'>";
    }

    if (dts.deb == dts.fin) {
      if (!STR.isBlank(dts.hed) && !STR.isBlank(dts.hef) && dts.hed != dts.hef) {
        result = i18n.t('agenda.act_tt_leh', { "0": dateDeb, "1": hDeb, "2": hFin, "3": motif, interpolation: { escapeValue: false } });
      } else {
        result = i18n.t('agenda.act_tt_le', { "0": dateDeb, "1": motif, interpolation: { escapeValue: false } });
      }
    } else {
      if (!STR.isBlank(dts.hed) && !STR.isBlank(dts.hef) && dts.hed != dts.hef) {
        result = i18n.t('agenda.act_tt_duh', { "0": dateDeb, "1": dateFin, "2": hDeb, "3": hFin, "4": motif, interpolation: { escapeValue: false } });
      } else {
        result = i18n.t('agenda.act_tt_du', { "0": dateDeb, "1": dateFin, "2": motif, interpolation: { escapeValue: false } });
      }
    }

    return "<label for='agenda_actl1'>" + result + "</label>";
  },

  _optimizeCellBody: function(calendar) {
    var lineHeight = 14;
    var self = this;

    $.each(calendar.find(".vuemensuel-cell:not([data-type=out])"), function(id, cell) {
      var body = $(cell).find(".vuemensuel-day-body");

      var events = body.children();
      var aEvents = new Array();
      var nTotalLines = 0;
      var hbody = body.outerHeight();
      var hdisp = hbody - (events.length * 4); // 6 == sum padding bot and top of ecah line;

      if (hbody > 0) {
        var nLines = Math.floor(hdisp / lineHeight);

        $.each(events, function(i, event) {
          var eventLines = $(event).height() / lineHeight;
          aEvents.push({
            "lines": eventLines,
          });

          nTotalLines += eventLines;
        });

        if (nTotalLines > nLines) {
          //Min lines needed for display events
          var nLinesMin = nLines - events.length;

          if (nLinesMin < 0) {
            // can't expand all lines;
            $.each(events, function(k, event) {
              $(event).addClass("noexpand");
            });

            var scrollContainerUp = $("<div class=\"scroll-up\">");
            scrollContainerUp.append($("<span class=\"ui-icon ui-icon-triangle-1-n\">"));

            var scrollContainerDown = $("<div class=\"scroll-down\">");
            scrollContainerDown.append($("<span class=\"ui-icon ui-icon-triangle-1-s\">"));

            $(cell).append(scrollContainerUp.hide());
            $(cell).append(scrollContainerDown);
          } else {
            var ospace = self._getOptimSpace(aEvents, nLinesMin);

            $.each(events, function(k, event) {
              if (ospace[k].expand == false) {
                $(event).addClass("noexpand");
              }
            });
          }
        }
      }
    });
  },

  _getOptimSpace: function(aEvents, nLinesMin) {
    var totals = 0;
    var optim = new Array();
    var shortedEvents = new Array();

    $.each(aEvents, function(id, event) {
      optim.push({ "expand": false });
      shortedEvents.push({ "lines": event.lines - 1, "id": id });
    });

    for (var i = 0; i < shortedEvents.length - 1; ++i) {
      var min = i;

      for (var j = i + 1; j < shortedEvents.length; ++j) {
        if (shortedEvents[min].lines <= shortedEvents[j].lines) {
          min = j;
        }
      }

      var aux = shortedEvents[min];
      shortedEvents[min] = shortedEvents[i];
      shortedEvents[i] = aux;
    }

    var k = shortedEvents.length - 1;
    while (totals < nLinesMin) {
      event = shortedEvents[k];

      if ((totals + event.lines) <= nLinesMin) {
        optim[event.id].expand = true;
        totals += event.lines;
      } else {
        totals = nLinesMin;
      }

      --k;
    }

    return optim;
  }

});
