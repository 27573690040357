import { BaseModel } from 'core/models/base.model.js';

/**
 * Model of lancer etats in order to build the url to print excel or pdf
 */
export var LancerEtatModel = BaseModel.extend({

  url: function() {
    var thcollab = this.thcollab || false;

    return Configuration.restRoot + "/rest/edition/" + this.type + "/" + encodeURIComponent(this.code) + "?thcollab=" + thcollab;
  },

  parse: function(response) {
    if (response.id === undefined && response.code !== undefined) {
      response.id = response.code;
    }
    return response;
  }
});