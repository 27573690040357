
import { PaginatedCollection } from '../../../../../../core/models/paginated.collection.js';
import { PretModel } from './pret.model.js';
import { STR } from '../../../../../../utils/str.js';

/**
 * Collection of prets
 */
export var PretColl = PaginatedCollection.extend({
  /**
   * Add this to have Outline
   *
   */

  model: PretModel,
  url: function() {
    var optionalParams = this._buildURL();
    return Configuration.restRoot + "/rest/composant/pret/liste" + optionalParams;
  },

  initialize: function() {
    PaginatedCollection.prototype.initialize.call(this);
    this.paginated = false;

  },

  setSortings: function(ctxTriDate) {
    var asc = (ctxTriDate == "Ascendant") ? true : false;
    this.sortings = { periode: asc };
  },
  /**
   * Set parametres to the collection from context
   */
  setParametres: function(context, pDiverIdCollab, maxPieces, paramDivPieceJointe) {
    if (context.ctxFiltreApplique && context.ctxFiltreApplique.length > 1) {
      this.filtreStatus = context.ctxFiltreApplique.join(",");
    } else {
      this.filtreStatus = "";
    }
    if (context.ctxPeriode && context.ctxPeriode.datedeb) {
      this.filtre_datedeb = context.ctxPeriode.datedeb;
    }
    if (context.ctxPeriode && context.ctxPeriode.datefin) {
      this.filtre_datefin = context.ctxPeriode.datefin;
    }
    if (context.ctxEncoursOuAVenir) {
      this.filtre_avenir = context.ctxEncoursOuAVenir;
    }
    if (context.ctxPretRecus && context.ctxPretRecus !== false) {
      this.filtre_pretrecus = context.ctxPretRecus;
      // when filtre_pretrecus not false
      if (context.ctxTypePret) {
        this.filtre_typepret = context.ctxTypePret;
      }
    }
    if (context.ctxCollab && (context.ctxCollab.matricule)) {
      this.matricule = context.ctxCollab.matricule;
    }
    if (context.ctxPretsID && context.ctxPretsID != null) {
      this.filtre_prets = context.ctxPretsID.join(",");
    }
    if (STR.isBlank(maxPieces) || maxPieces.get("maxi") <= 0 ||
      paramDivPieceJointe.get("valeur") != "1") {
      this.piecejointe = false;
    } else {
      this.piecejointe = true;
    }
  },
  /**
   * Build the URL by the params
   */
  _buildURL: function() {
    var params = "";

    // Filtre en fonction de leur statut.
    params += "?filtre_statuts=" + encodeURIComponent(this.filtreStatus);
    // Periode-Date debut
    if (!STR.isBlank(this.filtre_datedeb)) {
      params += "&filtre_datedeb=" + encodeURIComponent(this.filtre_datedeb);
    }
    // Periode-Date fin
    if (!STR.isBlank(this.filtre_datefin)) {
      params += "&filtre_datefin=" + encodeURIComponent(this.filtre_datefin);
    }
    // Emprunts en cours ou à venir
    if (!STR.isBlank(this.filtre_avenir)) {
      params += "&filtre_avenir=" + encodeURIComponent(this.filtre_avenir);
    }
    if (!STR.isBlank(this.piecejointe)) {
      params += "&piecejointe=" + encodeURIComponent(this.piecejointe);
    }
    if (!STR.isBlank(this.filtre_prets)) {
      params += "&filtre_prets=" + encodeURIComponent(this.filtre_prets);
    }
    // Si afficher tous les prêts qui sont affectés
    if (!STR.isBlank(this.filtre_pretrecus)) {
      params += "&filtre_pretrecus=" + encodeURIComponent(this.filtre_pretrecus);
    }
    // Filtrer les prêts selon PretEstUnEmprunt
    if (this.filtre_typepret) {
      params += "&filtre_typepret=" + encodeURIComponent(this.filtre_typepret);
    }

    return params;
  }

});