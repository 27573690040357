import { objs } from '../objectsRepository.js';
import { UTILS } from '../utils/utils.js';

export var onComplete = function(headers) {
  if (UTILS) {
    setTimeout(function() {
      if (headers["gfi-context"]) {
        if (objs && objs.appRt &&
          (objs.appRt.blocked === true || objs.appRt.hidderDisabled === true)) {
          //don't show/hide usecase hidder when app is blocked
        } else {
          UTILS.hideUsecaseHidder(JSON.parse(headers["gfi-context"]).onglet);

        }
      }
    }, 400);
  }
}