import { BaseModel } from '../../../../../core/models/base.model.js';
import { BriquesListeView } from './briquesListe.view.js';
import { i18n } from '../../../../../i18n.js';
import { PersoBriquesModel } from '../models/persoBriques.model.js';
import TPL_persobriques_general from '../general.tpl.html';

export var GeneralView = Backbone.View.extend({

  initialize: function(options) {
    this.template = TPL_persobriques_general;
    if (options && options.context) {
      this.context = options.context;
    }
    this.model = new BaseModel({
      value: new PersoBriquesModel()
    });
    this.listeView = new BriquesListeView({ context: this.context, model: this.model });
  },
  setContext: function(context) {
    this.listeView.setContext(context);
  },
  render: function() {
    var json = { "i18n": i18n };
    this.$el.append(this.template(json));
    this.$el.find(".persobriques-general-View").html(this.listeView.render().el);
    if (this.context) {
      this.setContext(this.context);
    }

    return this;
  }
});