module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<div class="calendacc-footer" style="min-width:826px">\n  <span class="calendacc-footer-title-jours">'+
((__t=(i18n.t('calendacc.jours')))==null?'':__t)+
'</span>\n  <div class="ui-jours-ferie typeFe"></div>\n  <span>'+
((__t=(i18n.t('calendacc.jourFe')))==null?'':__t)+
'</span>\n  <div class="ui-phx-jours-exceptionnel typeEx"></div>\n  <span>'+
((__t=(i18n.t('calendacc.jourEx')))==null?'':__t)+
'</span>\n\n  <span class="calendacc-footer-title-zones">'+
((__t=(i18n.t('calendacc.zones')))==null?'':__t)+
'</span>\n</div>\n';
}
return __p;
};
