module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div style="display:inline-block; float:left;	width: 90%;text-align: center;">\n  '+
((__t=(text))==null?'':__t)+
'\n</div>\n<div class="phx-hd-main-btn" style="float:right;width: 18px;margin:1;padding:0">\n  <button type="button" style="width: 18px;margin:1;padding:0"></button>\n  <ul style="position: fixed;z-index: 10">\n    <li class="ui-menu-item" role="presentation">\n      <a class="phx-grid-menu-selectionner-vue ui-corner-all" style="padding-left:0.4em" tabindex="-1" role="menuitem">\n			<span class="ui-menu-icon ui-icon ui-icon-triangle-1-e"></span>\n			'+
((__t=(i18nCom.t("common:grid.vue_menu")))==null?'':__t)+
'\n\n		</a>\n      <ul class="phx-grid-vues-chooser">\n      </ul>\n    </li>\n    <li class="ui-menu-item" role="presentation" style="padding-right:1.4em">\n      <a class="phx-grid-menu-selectionner-colonnes ui-corner-all" style="padding-left:0.4em" tabindex="-1" role="menuitem">\n			<span class="ui-menu-icon ui-icon ui-icon-triangle-1-e"></span>\n			'+
((__t=(i18nCom.t("common:grid.colonnes_menu")))==null?'':__t)+
'\n		</a>\n      <ul class="phx-grid-column-chooser">\n      </ul>\n    </li>\n  </ul>\n</div>\n';
}
return __p;
};
