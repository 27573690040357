import { BadgeageVirtuelDialogView } from './badgeageVirtuelDialog.view.js';
import { CompteursColl } from '../models/compteurs.collection.js';
import { DialogModel } from '../models/dialog.model.js';
import { DialogView } from 'core/views/dialog.view.js';
import { FonctionsColl } from '../models/fonctions.collection.js';
import { GLOBAL_DATA } from '../../../../globalData.js';
import { HABILITATION } from '../../../../utils/habilitation.js';
import { HabilitationContext } from 'core/models/habilitationContext.js';
import { i18n } from '../../../../i18n.js';
import { LastBadgeModel } from '../models/lastBadge.model.js';
import { ModeBadgModel } from '../models/modeBadg.model.js';
import { MSGS } from '../../../../utils/msgs.js';
import { objs } from '../../../../objectsRepository.js';
import { STR } from '../../../../utils/str.js';
import { SYNC } from '../../../../utils/sync.js';
import { TYPE } from '../../../../tda/tda.js';
import TPL_badgeage_badgeage_menu_layout from '../badgeage_menu_layout.tpl.html';

/**
 * Badgeage Virtuel component View
 */
export var BadgeageVirtuelMenuView = Backbone.View.extend({

  tagName: "span",

  initialize: function() {
    this.template = TPL_badgeage_badgeage_menu_layout;

    var self = this;
    this.blockedByOtherLink = false;

    //RGAA development

    this.theme = _.clone(GLOBAL_DATA.theme);

    // retrieve the matricule from the loged user
    this.matricule = objs.appRt.workflow.authModel.get("matricule");

    // retrieve the modebadge attribute
    if (HABILITATION.canView("COL_BADGVIRT.G")) {
      objs.appRt.workflow.off("reload:badgeage");
      objs.appRt.workflow.on("reload:badgeage", this._refreshToolips, this);

      var modebadgModel = new ModeBadgModel({ matricule: this.matricule });
      this.habContextBadg = new HabilitationContext({
        onglet: "badgeage",
        fonc_cour: "COL_BADGVIRT.G",
        nat_gest: ""
      });
      modebadgModel.setHabContext(this.habContextBadg);

      modebadgModel.fetch({
        success: function() {
          self.modeBadge = modebadgModel.get("modebadg");
          if (self.modeBadge != 0) {
            // retrieve the last badgeage
            self._getLastBadge(self);

            // retrieve the fonctions
            var fonctionsColl = new FonctionsColl();
            self.habContextFonctions = new HabilitationContext({
              onglet: "badgeage",
              fonc_cour: "COL_BADGVIRT.G",
              nat_gest: ""
            });
            fonctionsColl.setHabContext(self.habContextFonctions);

            // retrieve the parametre divers "badgeES"
            var badgeESModel = GLOBAL_DATA.paramDivers.get("badgeES");
            self.badgeES = badgeESModel.get("valeur");
            if (self.badgeES == "0") {
              self.$el.find(".bvMenuHeader1").attr("tabindex", "0");
              self.$el.find(".bvMenuHeader1").show();
              self.$el.find(".bvMenuHeader2").attr("tabindex", "-1");
              self.$el.find(".bvMenuHeader2").hide();
            } else if (self.badgeES == "1") {
              self.$el.find(".bvMenuHeader1").attr("tabindex", "-1");
              self.$el.find(".bvMenuHeader1").hide();
              self.$el.find(".bvMenuHeader2").attr("tabindex", "0");
              self.$el.find(".bvMenuHeader2").show();
            }

            fonctionsColl.fetch({
              success: function(fresh) {
                self.fonctions = fresh.models;
                self._renderFonctions();

                self.render();
              }
            });
          }
        }
      });
    }
  },

  events: {
    "click .bvMenuButton": "_toggleMenu",
    "click .bvMenuHButtonBadge": "_showCompteDialog",
    "click .bvMenuHButtonRentre": "_showCompteDialog",
    "click .bvMenuHButtonSors": "_showCompteDialog",
    "click .bvMenuContent span": "_showCompteDialog",
    "click .bvMenuCompteurs": "_showCompteursDialog"
  },

  /**
   * Gets the last posted badgeage virtuel
   */
  _getLastBadge: function(view) {
    var lastBadgeModel = new LastBadgeModel({ matricule: view.matricule });
    this.habContextLastBadge = new HabilitationContext({
      onglet: "badgeage",
      fonc_cour: "COL_BADGFONC.G",
      nat_gest: ""
    });
    lastBadgeModel.setHabContext(this.habContextLastBadge);

    lastBadgeModel.fetch({
      success: function(fresh) {
        if (!STR.isBlank(fresh.get("dervalide")) && !STR.isBlank(fresh.get("derheure")) &&
          !STR.isBlank(fresh.get("derlibelle")) && !STR.isBlank(fresh.get("derdate"))) {
          view.lastBadge = i18n.t('common:bvMenu_title', {
            "0": fresh.get("derlibelle"),
            "1": TYPE.DATE.format(fresh.get("derdate")),
            "2": fresh.get("derheure"),
            interpolation: { escapeValue: false }
          });
          view.bdgEtatVal = fresh.get("dervalide");
        } else {
          view.lastBadge = "";
          view.bdgEtatVal = null;
        }
        //render the button tooltip

        // (show or disable badge options - Badger par touche de fonction) - MON COMPTE/BADGEAGE
        if (HABILITATION.canView("COL_BADGVIRT.G") == true) {
          view._renderTooltip(view);
        }

      }
    });
  },

  _updateTime: function(view) {
    this.heure = TYPE.DATE.dateToStr(SYNC.getServerDate(), "HH:mm", "", ":");
    if (view.badgeES && view.badgeES == "0") {
      view.$el.find(".bvMenuHButtonBadge").text(i18n.t('common:bvMenu_badge', { "0": this.heure }));
    } else if (view.badgeES && view.badgeES == "1") {
      view.$el.find(".bvMenu_heure").text(i18n.t('common:bvMenu_heure', { "0": this.heure }));
    }
  },

  _renderFonctions: function() {
    var fonctionMenu = this.$el.find(".bvMenuContent");
    var hasOptions = false;
    if (fonctionMenu) {
      // fonctions
      if (HABILITATION.canView("COL_BADGFONC.G")) {

        if (!STR.isBlank(this.fonctions)) {
          for (var i = 0; i < this.fonctions.length; i++) {
            var code = this.fonctions[i].get("code");
            var libelle = this.fonctions[i].get("libelle");
            fonctionMenu.append("<li><span id=\"" + code + "\" class=\"" + code + " phx-menu-badgeage phx-hover ui-corner-all\">" + libelle + "</span></li>");
            hasOptions = true;
          }
        }
      }
      // temps réel
      if (HABILITATION.canView("COL_TPSREEL.V")) {
        fonctionMenu.append("<li><a id=\"bvMenuCompteurs\" class=\"bvMenuCompteurs\">" + i18n.t('common:bvMenu_compteurs') + "</a></li>");
        hasOptions = true;
      }
    }
    if (hasOptions === false) {
      this.$el.find(".bvMenuButton").remove();
    }
  },

  render: function() {
    if (this.modeBadge != 0) {
      var json = { "i18n": i18n };
      this.$el.append(this.template(json));

      //Show or hide the header buttons
      if (this.badgeES) {
        if (this.badgeES == "0") {
          this.$el.find(".bvMenuHeader1").attr("tabindex", "0");
          this.$el.find(".bvMenuHeader1").show();
          this.$el.find(".bvMenuHeader2").attr("tabindex", "-1");
          this.$el.find(".bvMenuHeader2").hide();
        } else if (this.badgeES == "1") {
          this.$el.find(".bvMenuHeader1").attr("tabindex", "-1");
          this.$el.find(".bvMenuHeader1").hide();
          this.$el.find(".bvMenuHeader2").attr("tabindex", "0");
          this.$el.find(".bvMenuHeader2").show();
        }
      }

      //Show the fonctions menus
      this._renderFonctions();

      var menuContent = $(".bvMenuContent", this.el);
      menuContent.menu();
      menuContent.hide();

      //Show local time every 2 seconds
      if (!STR.isBlank(this.timer)) {
        window.clearInterval(this.timer);
        delete this.timer;
      }
      this._updateTime(this);
      var self = this;
      this.timer = window.setInterval(function() { self._updateTime(self); }, 2000);

      //render the button tooltip

      if (HABILITATION.canView("COL_BADGVIRT.G") == true) {
        //enables the tooltips
        this.$el.find(".bvMenuHButtonBadge").tooltip({ tooltipClass: "" });
        this.$el.find(".bvMenuHButtonRentre").tooltip({ tooltipClass: "" });
        this.$el.find(".bvMenuHButtonSors").tooltip({ tooltipClass: "" });
        //render the button tooltip
        this._renderTooltip(this);
      } else {
        this.$el.find(".bvMenu").hide();
      }
    }
    return this;
  },

  _showCompteursDialog: function() {
    var self = this;

    //Dialog compteurs
    if (this.matricule) {
      var compteursColl = new CompteursColl({ matricule: this.matricule });
      this.habContextCompteurs = new HabilitationContext({
        onglet: "badgeage",
        fonc_cour: "COL_TPSREEL.V",
        nat_gest: ""
      });
      compteursColl.setHabContext(this.habContextCompteurs);

      compteursColl.reset();
      compteursColl.fetch({
        success: function(fresh) {
          var dialogCompteurs = new DialogView({
            view: BadgeageVirtuelDialogView,
            viewData: {
              collection: fresh,
              heure: self.heure
            }
          });
          dialogCompteurs.setHeight(450);
          dialogCompteurs.setWidth(400);
          dialogCompteurs.open();
        }
      });
    }
  },

  _showCompteDialog: function(event) {

    if (this.blockedByOtherLink == false) {

      //Désactivation des liens de badgeages
      this.blockedByOtherLink = true;

      var cursorBadge = this.$el.find(".bvMenuHButtonBadge").css("cursor");
      var cursorEntree = this.$el.find(".bvMenuHButtonRentre").css("cursor");
      var cursorSortie = this.$el.find(".bvMenuHButtonSors").css("cursor");
      var cursorMenuSpan = this.$el.find(".bvMenuContent span").css("cursor");

      this.$el.find(".bvMenuHButtonBadge").css("cursor", "wait");
      this.$el.find(".bvMenuHButtonRentre").css("cursor", "wait");
      this.$el.find(".bvMenuHButtonSors").css("cursor", "wait");
      this.$el.find(".bvMenuContent span").css("cursor", "wait");

      //Dialog compte
      var buttonId = event.target.classList[0];
      var sensVar = null;
      var fonctionVar = null;

      switch (buttonId) {
        case "bvMenuHButtonBadge":
          sensVar = null;
          fonctionVar = null;
          break;
        case "bvMenuHButtonRentre":
          sensVar = "e";
          fonctionVar = null;
          break;
        case "bvMenuHButtonSors":
          sensVar = "s";
          fonctionVar = null;
          break;
        default: //Fonctions
          sensVar = null;
          fonctionVar = buttonId;
          break;
      }
      var dtm = new Date();
      var decalage = -dtm.getTimezoneOffset();
      var self = this;
      var dialogModel = new DialogModel({
        matricule: this.matricule,
        sens: sensVar,
        fonction: fonctionVar,
        decalage: decalage
      });

      this.habContextDialog = new HabilitationContext({
        onglet: "badgeage",
        fonc_cour: "COL_BADGVIRT.G",
        nat_gest: ""
      });
      dialogModel.setHabContext(this.habContextDialog);

      if ((HABILITATION.canView("COL_BADGFONC.G") && fonctionVar != null) || (HABILITATION.canView("COL_BADGVIRT.G") && fonctionVar == null)) {
        dialogModel.save(null, {
          success: function(fresh) {
            if (!STR.isBlank(fresh.get("cptrendu"))) {
              if (!STR.isBlank(fresh.get("message1")) || !STR.isBlank(fresh.get("message2"))) {
                var dialogCompte = new DialogView({
                  view: BadgeageVirtuelDialogView,
                  viewData: {
                    model: fresh,
                    heure: self.heure
                  }
                });

                dialogCompte.setHeight(150);
                dialogCompte.setWidth(450);
                dialogCompte.open();
              } else {
                var text = "<span class=\"bvline1\">" + self._buildMessage(fresh.get("cptrendu"), fresh.get("parametres")) + "</span>";
                MSGS.showInfo(text);
              }
              self._getLastBadge(self);
            }
            //Ré-activation des liens de badgeages
            self.blockedByOtherLink = false;
            self.$el.find(".bvMenuHButtonBadge").css("cursor", cursorBadge);
            self.$el.find(".bvMenuHButtonRentre").css("cursor", cursorEntree);
            self.$el.find(".bvMenuHButtonSors").css("cursor", cursorSortie);
            self.$el.find(".bvMenuContent span").css("cursor", cursorMenuSpan);
          },
          error: function() {
            //Ré-activation des liens de badgeages
            self.blockedByOtherLink = false;
            self.$el.find(".bvMenuHButtonBadge").css("cursor", cursorBadge);
            self.$el.find(".bvMenuHButtonRentre").css("cursor", cursorEntree);
            self.$el.find(".bvMenuHButtonSors").css("cursor", cursorSortie);
            self.$el.find(".bvMenuContent span").css("cursor", cursorMenuSpan);
          }
        });
      } else {
        //Ré-activation des liens de badgeages
        self.blockedByOtherLink = false;
        self.$el.find(".bvMenuHButtonBadge").css("cursor", cursorBadge);
        self.$el.find(".bvMenuHButtonRentre").css("cursor", cursorEntree);
        self.$el.find(".bvMenuHButtonSors").css("cursor", cursorSortie);
        self.$el.find(".bvMenuContent span").css("cursor", cursorMenuSpan);
      }
    }
  },

  //Method to close and open the menu.
  _toggleMenu: function() {
    var menuContent = $(".bvMenuContent", this.el);
    var menuFonctionContent = $(".bvMenuContent", this.el);
    if (menuContent.is(":visible")) {
      menuFonctionContent.hide();
      menuContent.hide();
    } else {
      var menuBtn = $(".bvMenuButton", this.el);
      menuContent.show().position({
        my: "right top",
        at: "right bottom",
        of: menuBtn
      });

      //If you click out of the menu, close the menu.
      var self = this;
      $(document).one("mousedown", function(event) {
        var element = $(self.el).find(event.target);
        if (element.length == 0) {
          self._toggleMenu();
        }
      });
      //If you click in one option, the menu is closed
      $(document).one("mouseup", function(event) {
        var element = $(self.el).find(event.target);
        if (element.length > 0 && event.target.className.split(" ")[0] != "bvMenuButton") {
          self._toggleMenu();
        }
      });
    }
  },

  _refreshToolips: function(event) {
    this.$el.empty();
    this.initialize();
    this.delegateEvents();
  },

  _renderTooltip: function(view) {
    var rgaa = false;
    if (!STR.isBlank(view.lastBadge)) {

      if(this.theme.get("preferences") && !STR.isBlank(this.theme.get("preferences")[0]) && this.theme.get("preferences")[0].varchar1 === 'contrast.theme'){
        rgaa = true;
      }

      var buttonBadge = view.$el.find(".bvMenuHButtonBadge");
      var buttonRentre = view.$el.find(".bvMenuHButtonRentre");
      var buttonSors = view.$el.find(".bvMenuHButtonSors");

      if(rgaa === true) {
        buttonBadge = $(".bvMenuHButtonBadge");
        buttonRentre = $(".bvMenuHButtonRentre");
        buttonSors = $(".bvMenuHButtonSors");
      }


      if (!STR.isBlank(view.bdgEtatVal)) {
        if (buttonBadge.length > 0) {
          buttonBadge.tooltip("close");
          buttonBadge.prop("title", view.lastBadge);
          if (view.bdgEtatVal == true) {
            buttonBadge.tooltip("option", "tooltipClass", "");
          } else {
            buttonBadge.tooltip("option", "tooltipClass", "phx-tooltip-italic");
          }
        }
        if (buttonRentre.length > 0) {
          buttonRentre.tooltip("close");
          buttonRentre.prop("title", view.lastBadge);
          if (view.bdgEtatVal == true) {
            buttonRentre.tooltip("option", "tooltipClass", "");
          } else {
            buttonRentre.tooltip("option", "tooltipClass", "phx-tooltip-italic");
          }
        }
        if (buttonSors.length > 0) {
          buttonSors.tooltip("close");
          buttonSors.prop("title", view.lastBadge);
          if (view.bdgEtatVal == true) {
            buttonSors.tooltip("option", "tooltipClass", "");
          } else {
            buttonSors.tooltip("option", "tooltipClass", "phx-tooltip-italic");
          }
        }
      }
    }
  },

  _buildMessage: function(message, parametres) {
    var result = null;
    if (!STR.isBlank(message)) {
      result = message;
      if (!STR.isBlank(parametres) && parametres.length > 0) {
        for (var i = 0; i < parametres.length; i++) {
          var next = i + 1;
          result = result.replace("@" + next, parametres[i]["@" + next]);
        }
      }
    }
    return result;
  }
});
