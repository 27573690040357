import { EditedRowView as CommonEditedRowView } from '../../../../../core/views/editedRow.view';
import { i18n } from '../../../../../i18n.js';
import { TYPE } from '../../../../../tda/tda.js';
import { FORMS } from '../../../../../utils/forms.js'
import TPL_bmonresp_detail from '../detail.tpl.html';
import { SelecteurCheminItemModel } from '../../../../../core/components/selecteur_chemin/selecteurCheminItem.model.js';
import { SelecteurCheminView } from '../../../../../core/components/selecteur_chemin/selecteurChemin.view.js';
import { objs } from '../../../../../objectsRepository.js';
import { STR } from '../../../../../utils/str';
import { HabilitationContext } from '../../../../../core/models/habilitationContext.js';
import { SYNC } from '../../../../../utils/sync.js';
import { avantAjoutModel } from "../models/avantAjout.model.js";

export var EditedRowView = CommonEditedRowView.extend({
  /**
   * Add this to have Outline
   *
   */

  initialize: function() {
    this.template = TPL_bmonresp_detail;
  },

  events: _.defaults({
    "change :input:not([readonly])": "_changeView"
  }, TYPE.DURATION.HOUR_MINUTE.events(), TYPE.DATE.events()),

  _changeView: function(event, data) {
    var className = event.currentTarget.className.split(" ")[0];
    var debsitu = TYPE.DATE.parse(event.target.value).val;

    if (className === "debsitu") {
      if (STR.isBlank(debsitu)) {
        var today = TYPE.DATE.parse(TYPE.DATE.dateToStr(SYNC.getServerDate())).val;

        this.model.set("debsitu", today, { silent: true });
        this.structureSelected.coll.datedebut = this.model.get("debsitu");
        this.structureSelected.params.datedebut = this.model.get("debsitu");
      } else{
        this._change(event, data);
        this.structureSelected.coll.datedebut = this.model.get("debsitu");
        this.structureSelected.params.datedebut = this.model.get("debsitu");
      }
    }

    if (className === "finsitu") {
      this._change(event, data);
      this.structureSelected.coll.datefin = this.model.get("finsitu");
    }
  },

  render: function() {

    var json = { 'i18n': i18n };

    this.$el.html(this.template(json));
    // Enable the PKs defined in the table
    //this._enablePk(this.model.isNew());

    //disable so it doesn't show the calender
    // FORMS.setDatepicker(this, ".finsitu");

    var self = this;

    var structCode = STR.isBlank(self.structureSelected) ? self.model.collection.structid : self.structureSelected.structid;
    var structVal = !STR.isBlank(self.model.collection) ? self.model.collection.structval : self.structureSelected.structval
    this.habContext = new HabilitationContext({
      onglet: "bmonresp",
      fonc_cour: "COL_SPE_MRESP.G",
      nat_gest: ""
    });

    this.structureSelected = new SelecteurCheminView({
      classname: "itemid",
      name: "itemid",
      host: self,
      structid: structCode,
      listRenderStyle: 1,
      idComponent: "itemid",
      height: 500,
      width: 850,
      addItemCallback: function(data) {
        self._responseCallback(data, self.model);
      },
      datedebut: self.model.get("debsitu"),
      datefin: self.model.get("finsitu"),
      partial: false,
      modeComplet: true,
      racine: false,
      nonrattachable: false,
      exclure_collab: objs.appRt.workflow.authModel.get("matricule"),
      habContext: this.habContext,
      ecran: objs.appRt.workflow.get("zone"),
      structval: structVal
    });
    var item = new SelecteurCheminItemModel({
      "date": self.model.get("debsitu") ? self.model.get("debsitu") : TYPE.DATE.INITIAL,
      "elementCode": self.model.get("itemid") ? self.model.get("itemid") : "",
      "structid": structCode,
    });

    if (!STR.isBlank(item.get("elementCode"))) {
      this.structureSelected.addValue(item);
    }

    this.$el.find(".selVal").html(this.structureSelected.render().el);

    if (!STR.isBlank(this.model.get("itemid"))) {
      this.$el.find(".debsitu").attr("readonly", true)
      this.$el.find(".debsitu").val(TYPE.DATE.format(this.model.get("debsitu"), TYPE._getFormatByCode("DATE")))
    } else {
      FORMS.setDatepicker(this, ".debsitu");
    }


    this.$el.find(".debsitu.typeDate:not([readonly])").blur(function(event) {
      var debsitu = TYPE.DATE.parse(event.target.value).val;

      if (STR.isBlank(debsitu)) {
        var today = TYPE.DATE.parse(TYPE.DATE.dateToStr(SYNC.getServerDate())).val;

        self.model.set("debsitu", today, { silent: true });
        $(event.target).val(TYPE.DATE.format(self.model.get("debsitu"), TYPE._getFormatByCode("DATE")));
      }
    });

    this._mapModelToForm($(this.el), this.model);
    return this;
  },

  _responseCallback: function(data, model) {
    model.set("itemid", data.code);
    model.set("itemcode", data.codef);
    model.set("itemlib", data.libelle);
  },

});
