import { BaseModel } from '../../../../../../core/models/base.model.js';
import { BlockView } from '../../../../wkfhisto/views/block.view.js';

import { GLOBAL_DATA } from '../../../../../../globalData.js';
import { EditableGridModel } from '../../../../../../core/grids/editablegrid/editableGrid.model.js';
import { EditableGridView } from '../../../../../../core/grids/editablegrid/editableGrid.view.js';
import { EditedRowView } from './editedRow.view.js';
import { FormView } from '../../../../../../core/views/form.view.js';
import { GererBadgeagesGestion } from '../models/gererBadgeagesGestion.js';
import { GererBadgeagesProfilsModel } from '../models/gererBadgeagesProfils.model.js';
import { HABILITATION } from '../../../../../../utils/habilitation.js';
import { HabilitationContext } from '../../../../../../core/models/habilitationContext.js';
import { i18n } from '../../../../../../i18n.js';
import { ListeBadgeageColl } from '../models/listeBadgeage.collection.js';
import { ListeBadgeageModel } from '../models/listeBadgeage.model.js';
import { STR } from '../../../../../../utils/str.js';
import { TYPE } from '../../../../../../tda/tda.js';
import { GererbadgeagesWorkflow } from '../models/gererbadgeages.workflow.js';
import TPL_gererbadgeages_detail from '../detail.tpl.html';

export var GererBadgeagesView = FormView.extend({
  /**
   * Constructor
   * View to render gerer badgeages, and
   * API to the component for execute the actions (new, fetch, save, modifiable, delete, acepter, refuser)
   */

  initialize: function(p_options) {
    var self = this;
    var options = p_options || {};

    this.listeBadgeage = new ListeBadgeageColl();
    //this.listenTo(this.listeBadgeage,"row:select",this._changeValiderOptions);

    this.model = new BaseModel({
      value: new ListeBadgeageModel(),
      mode: "C"
    });

    this.workflow = new GererbadgeagesWorkflow();
    this.workflow.gererBadgeages = this;

    this.EMPTY_BADGEAGE = new ListeBadgeageModel();
    this.EMPTY_BADGEAGE.setHabContext(this.workflow.getHabContext());

    this.template = TPL_gererbadgeages_detail;

    if (options.context) {
      this.context = options.context;
    }

    if (options && options.parent) {
      this.parent = options.parent;
    }

    if (options.orientation) { // 0: Horizontal ; 1: Vertical
      this.orientation = options.orientation;
    }

    this.theme = _.clone(GLOBAL_DATA.theme);

    this.parent._setTitle(i18n.t('common:gererbadgeages.table_title'));

    this.workflow.setUp(function() {
      self.setUp(function() {
        self.workflow.tableModel.btnBarModel.off("btn:click", self.workflow.tableModel.buttonAction);
        self.workflow.tableModel.btnBarModel.on("btn:click", self.workflow._customBtnAction, self);

        self.listenTo(self.workflow.tableModel.coll, "row:select", self._manageActionsForLine);
      });
    });
  },

  /**
   * Show/hide buttons depending the actions available for the selected line
   */
  _manageActionsForLine: function(model) {
    var btnBar = this.workflow.tableModel.btnBarModel;
    this._manageButtonsTable();
    if (!STR.isBlank(model.get("statut").code)) {
      //view si line can delete
      if (!this._actionsForStatut("suppression", model.get("statut").code)) {
        btnBar.trigger("hide:delete");
      }
      if (model.get("valorise").sens.code == "X") {
        btnBar.trigger("hide:delete");
      }

      if (this.context.ctxModeSuppression == "SensX") {
        btnBar.trigger("hide:delete");
      }

      //view si line can valider
      if (!this._actionsForStatut("validation", model.get("statut").code) || !model.get("profils") || model.get("profils").length == 0) {
        btnBar.trigger("custom.hide:valider");
      }
    }
    this._changeValiderOptions(model);
  },

  /**
   * Show/hide buttons depending the actions available for the table
   */
  _manageButtonsTable: function() {
    var context = this.context;
    var btnBar = this.workflow.tableModel.btnBarModel;
    this._habilityButtons();
    //view if can create
    if (context.ctxActionsPossibles.indexOf("Creer") == -1 ||
      !this._actionsForStatut("creation")) {
      btnBar.trigger("hide:new");
    }
    //view if can delete
    if (context.ctxActionsPossibles.indexOf("Supprimer") == -1 ||
      context.ctxModeSuppression != "Button") {
      btnBar.trigger("hide:delete");
    }
    //view if can edition
    if (context.ctxActionsPossibles.indexOf("Modifier") == -1) {
      this.table.enableEdition(false);
    }

    //view if validate
    if (context.ctxActionsPossibles.indexOf("Valider") == -1 ||
      !HABILITATION.canView(context.ctxHabilitation.HabilitationValidation)) {
      btnBar.trigger("custom.hide:valider");
    }
    //hide every copy
    btnBar.trigger("hide:copy");
  },

  /**
   * Show all buttons
   */
  _habilityButtons: function() {
    var btnBar = this.workflow.tableModel.btnBarModel;
    btnBar.trigger("show:save");
    btnBar.trigger("show:revert");
    btnBar.trigger("show:new");
    btnBar.trigger("show:delete");

    btnBar.trigger("custom.show:valider");
  },

  /**
   * Load the internal events of the view, and the events of TDA Date
   */
  events: {
    "mouseenter span.txtStatutGerer": "_openTooltipListener",
    "mouseleave span.txtStatutGerer": "_closeTooltipListener"
  },

  /**
   * Set up the view. Link the models between them and render the form
   */
  setUp: function(callback) {
    var context = this.context;
    var self = this;
    //params for url
    this.listeBadgeage.setHabContext(new HabilitationContext({
      onglet: this.context.ctxEcran,
      fonc_cour: this.context.ctxHabilitation.HabilitationAcces
    }));
    this.listeBadgeage.matricule = context.ctxGestionCollab.matricule;
    //dates
    if (context.ctxGestionDate) {
      this.listeBadgeage.datedeb = context.ctxGestionDate;
      this.listeBadgeage.datefin = context.ctxGestionDate;
    } else {
      this.listeBadgeage.datedeb = context.ctxGestionPeriode.datedebut;
      this.listeBadgeage.datefin = context.ctxGestionPeriode.datefin;
    }
    this.listeBadgeage.statuts = context.ctxFiltreApplique.join(",");

    //Update the workflow's habilitations
    this._manageWorkflowContect();

    self.listeBadgeage.fetch({
      success: function() {
        //delete the file where valorise sens = X
        self.table = self._initTable();
        self.workflow.table = self.table;
        self.table.model.setHabContext(new HabilitationContext({
          onglet: self.context.ctxEcran,
          fonc_cour: self.context.ctxHabilitation.HabilitationAcces
        }));
        self.workflow.tableModel = self.table.model;
        //add the event to dblClick
        //self.listenTo(self.workflow.table.model.coll,"row:dblclick",self._lineStateRender,self);

        if (callback) {
          callback();
        }

        self._manageButtonsTable();
        self.renderForm();

        if (context.ctxModeInitialisation == "Ajout" && context.ctxActionsPossibles.indexOf("Creer") != -1 &&
          HABILITATION.canCreate(context.ctxHabilitation.HabilitationGestion) &&
          self._actionsForStatut("creation")) {
          self.workflow.tableModel.btnBarModel.trigger("btn:click", "new");
        } else {
          var tableMd = self.workflow.tableModel;
          if (tableMd.coll.length > 0) {
            var firstRow = tableMd.coll.at(0);
            tableMd.selectRow(firstRow);
          }
        }

        if (self.context) {
          //self._manageContext();
        }
        self.model.trigger("ready");
      }
    });
  },

  _lineStateIsReadonly: function() {
    var model = this.table.model.get("value");
    var isReadOnly = false;

    if (model && this.context && this.context.ctxHabilitation && this.context.ctxHabilitation.HabilitationGestion) {
      var canUpdate = HABILITATION.canUpdate(this.context.ctxHabilitation.HabilitationGestion);
      isReadOnly = !canUpdate && !model.isNew();
    }
    return isReadOnly;
  },

  /**
   * Renders the view to the form and formated the Collaborateur and Periode
   */
  renderForm: function() {
    var context = this.context;
    var objMatricule = null;
    var objPeriode = null;
    //Collaborateur concerne

    if (context.ctxUtilisateur == "Responsable" &&
      context.ctxGestionCollective === false &&
      !STR.isBlank(context.ctxGestionCollab)) {
      if (this.paramIdCollab == "matric_aux") {
        objMatricule = context.ctxGestionCollab.matric_aux;
      } else {
        objMatricule = context.ctxGestionCollab.matricule;
      }

      var nom = context.ctxGestionCollab.nom;
      var prenom = context.ctxGestionCollab.prenom;
      var htmlCollab = nom.toUpperCase() + " " + prenom + " ";
      htmlCollab += " (" + objMatricule + ")";

      $(this.el).find(".gererbadgeages-detail .collaborateur").html(htmlCollab);
    }
    //Date ou période
    if (!STR.isBlank(context.ctxGestionDate) && STR.isBlank(context.ctxGestionPeriode)) {
      var formatDate = TYPE.DATE.format(context.ctxGestionDate, TYPE._getFormatByCode("DATE_L"));
      objPeriode = i18n.t('messages:GT_1143', { "1": formatDate });
    } else if (!STR.isBlank(context.ctxGestionPeriode) && STR.isBlank(context.ctxGestionDate)) {
      var formatDateDeb = TYPE.DATE.format(context.ctxGestionPeriode.datedebut, TYPE._getFormatByCode("DATE_L"));
      var formatDateFin = TYPE.DATE.format(context.ctxGestionPeriode.datefin, TYPE._getFormatByCode("DATE_L"));
      var formatHeureDeb = TYPE.HOUR_MINUTE.format(context.ctxGestionPeriode.heuredebut);
      var formatHeureFin = TYPE.HOUR_MINUTE.format(context.ctxGestionPeriode.heurefin);

      if (context.ctxGestionPeriode.datedebut == context.ctxGestionPeriode.datefin) {
        objPeriode = i18n.t('messages:GT_1144', { "1": formatDateDeb, "2": formatHeureDeb, "3": formatHeureFin });
      } else {
        objPeriode = i18n.t('messages:GT_1196', { "1": formatDateDeb, "2": formatHeureDeb, "3": formatDateFin, "4": formatHeureFin });
      }
    }
    $(this.el).find(".gererbadgeages-detail .periode").html(objPeriode);

    //table of badgeages
    $(this.el).find(".gererbadgeages-detail .table_badgeages").html(this.table.el);
    this.table.render();

    return this;
  },

  _manageWorkflowContect: function() {
    var auxNatGest;
    if (this.context.ctxModeInitialisation == "Ajout") {
      auxNatGest = "A";
    } else if (this.context.ctxModeInitialisation == "Consultation") {
      auxNatGest = "M";
    } else {
      auxNatGest = "";
    }
    this.workflow.setHabContext(new HabilitationContext({
      onglet: this.context.ctxEcran,
      fonc_cour: this.context.ctxHabilitation.HabilitationGestion,
      nat_gest: auxNatGest
    }));
  },

  /**
   * Creating the table that contains the data from the collection
   */
  _initTable: function() {

    var self = this;
    var tableModel = new EditableGridModel({ coll: this.listeBadgeage }, { context: this.context });
    var utilisateur = this.context.ctxUtilisateur;
    this.showCombo = utilisateur === "Collaborateur";

    var rgaa = false;

    if(this.theme.get("preferences") && !STR.isBlank(this.theme.get("preferences")[0]) && this.theme.get("preferences")[0].varchar1 === 'contrast.theme'){
      rgaa = true;
    }

    var tblColumns = [
      { title: i18n.t('common:gererbadgeages.date'), property: "saisie.date", width: (rgaa === false) ? 140 : 200 },
      { title: i18n.t('common:gererbadgeages.heure'), property: "saisie.heure", width: (rgaa === false) ? 80 : 103 },
      { title: i18n.t('common:gererbadgeages.sens'), property: "saisie.sens", width: (rgaa === false) ? 150 : 150 },
      { title: i18n.t('common:gererbadgeages.motif'), property: "motif.code", width: 220 },
      { title: i18n.t('common:gererbadgeages.fonction'), property: "fonction", width: 80 },
      { title: i18n.t('common:gererbadgeages.origine'), property: "origine", width: 300 },
      { title: i18n.t('common:gererbadgeages.statut'), property: "statut.code", width: 100 }
    ];

    var viewRow = new EditedRowView({ parent: this, context: this.context, workflow: this.workflow });
    var table = new EditableGridView({
      id: "gererbadgeages_table",
      columns: tblColumns,
      model: tableModel,
      viewRow: viewRow,
      showFilteredRowsInTitle: true,
      habilitations: this.context.ctxHabilitation.HabilitationGestion,
      title: i18n.t('common:gererbadgeages.table_title')
    });

    // Render the Cells for formated the text presentation
    table.cellRenderers["saisie.date"] = function(model) {
      var resp = "";
      var formatedSaisieDate = TYPE.DATE.format(model.get("saisie").date, TYPE._getFormatByCode("DATE_A"));
      var formatedValoriseDate = TYPE.DATE.format(model.get("valorise").date, TYPE._getFormatByCode("DATE_A"));

      resp = formatedSaisieDate;

      if (self.context.ctxModeInitialisation == "Consultation") {
        if (self._isDiferentBadgeages(model.get("valorise"), model.get("saisie"))) {
          resp = formatedSaisieDate;
          resp += "<br/>";
          resp += "<span class='ui-phx-badgeage-valorise'>" + formatedValoriseDate + "</span>";
        } else {
          resp = "<span>" + formatedValoriseDate + "</span>";
        }
      }
      return resp;
    };

    table.cellRenderers["saisie.heure"] = function(model) {
      var resp = "";
      var formatedSaisieHeure = TYPE.HOUR_MINUTE.format(model.get("saisie").heure);
      var formatedValoriseHeure = TYPE.HOUR_MINUTE.format(model.get("valorise").heure);

      resp = formatedSaisieHeure;

      if (self.context.ctxModeInitialisation == "Consultation") {
        if (self._isDiferentBadgeages(model.get("valorise"), model.get("saisie"))) {
          resp = formatedSaisieHeure;
          resp += "<br/>";
          resp += "<span class='ui-phx-badgeage-valorise'>" + formatedValoriseHeure + "</span>";
        } else {
          resp = "<span>" + formatedValoriseHeure + "</span>";
        }
      }
      return resp;
    };

    table.cellRenderers["saisie.sens"] = function(model) {
      var resp = model.get("saisie").sens.code;

      if (self.context.ctxModeInitialisation == "Consultation") {
        if (self._isDiferentBadgeages(model.get("valorise"), model.get("saisie"))) {
          resp = model.get("saisie").sens.code;
          resp += "<br/>";
          resp += "<span class='ui-phx-badgeage-valorise'>" + model.get("valorise").sens.code + "</span>";
        } else {
          resp = "<span>" + model.get("valorise").sens.code + "</span>";
        }
      }
      if (model.get("etat") === "X") {
        resp += "<br/>";
        resp += "<span class='ui-phx-demande-suppression'>" + i18n.t('common:gererbadgeages.demandeSuppressionBadgeage') + "</span>";
      }
      return resp;
    };

    table.cellRenderers["motif.code"] = function(model) {
      var resp = "";
      if (!STR.isBlank(model.get("motif").code)) {
        resp = model.get("motif").libelle + " (" + model.get("motif").code + ")";
      }

      return resp;
    };

    table.cellRenderers["origine"] = function(model) {
      var resp = "";
      var msgType = "";
      var msgOrigine = "";
      var msgPointage = "";

      var genere = model.get("genere");
      var origine = model.get("origine");
      var terminal = model.get("terminal");
      var provenance = model.get("provenance");
      var initial = model.get("initial");
      var valorise = model.get("valorise");

      if (genere == true) {
        msgType = i18n.t('common:gererbadgeages.origine_genere');
      } else if (origine == "T") {
        msgType = i18n.t('common:gererbadgeages.origine_telephone');
      } else if (origine == "E") {
        msgType = i18n.t('common:gererbadgeages.origine_cs');
      } else {
        if (terminal && !STR.isBlank(terminal.code)) {
          msgType = i18n.t('common:gererbadgeages.origine_pmf');
          msgOrigine = terminal.libelle + " (" + terminal.code + ")";
        } else {
          if (origine == "P") {
            msgType = i18n.t('common:gererbadgeages.origine_pmf');
          } else if (origine == "D") {
            msgType = i18n.t('common:gererbadgeages.origine_differe');
          }
          if ((origine == "P" || origine == "I" || origine == "D") &&
            !STR.isBlank(provenance) &&
            !STR.isBlank(self.paramProvenanceBadgeage) &&
            (self.paramProvenanceBadgeage == "1" || self.paramProvenanceBadgeage == "2")) {
            msgOrigine = provenance;
          }
        }
        if (!STR.isBlank(initial.heure) && self._isDiferentBadgeages(initial, valorise)) {
          var sensLibelle = initial.sens.libelle;
          var sensCode = initial.sens.code;
          var formattedDate = TYPE.DATE.format(initial.date, TYPE._getFormatByCode("DATE_A"));
          var formattedHeure = TYPE.HOUR_MINUTE.format(initial.heure);
          if (initial.date != valorise.date) {
            msgPointage = formattedDate + " " + formattedHeure + " " + sensLibelle + " (" + sensCode + ")";
          } else {
            msgPointage = formattedHeure + " " + sensLibelle + " (" + sensCode + ")";
          }
        }
      }

      var pointSeparator = !STR.isBlank(msgType) && !STR.isBlank(msgOrigine);
      var minusSeparator = (!STR.isBlank(msgType) || !STR.isBlank(msgOrigine)) && !STR.isBlank(msgPointage);

      resp += msgType;
      if (pointSeparator) {
        resp += " : ";
      }
      resp += msgOrigine;
      if (minusSeparator) {
        resp += " - ";
      }
      resp += msgPointage;

      return resp;
    };

    table.cellRenderers["statut.code"] = function(model) {
      var resp = "";
      if (!STR.isBlank(model.get("statut").code)) {
        resp = self._apliqueStatut(model);
      }
      return resp;
    };

    table.setColumnsAlignment({ "saisie.date": "center", "saisie.heure": "center", "saisie.sens": "center" });

    //		var tableHabilitations = this.context.ctxHabilitation.HabilitationGestion;
    this.workflow.habContext = new HabilitationContext({
      onglet: this.context.ctxEcran,
      fonc_cour: this.context.ctxHabilitation.HabilitationGestion
    });

    // set habilitations context that the table collection will use
    tableModel.coll.setHabContext(new HabilitationContext({
      onglet: this.context.ctxEcran,
      fonc_cour: this.context.ctxHabilitation.HabilitationGestion
    }));

    // create a copy of the habilitation context to be used by the actions (add/update/remove) originating from the grid
    table.model.setHabContext(new HabilitationContext({
      onglet: this.context.ctxEcran,
      fonc_cour: this.context.ctxHabilitation.HabilitationGestion
    }));

    var menuOptions = [{ action: "accepter", text: i18n.t('common:gererbadgeages.accepter') }, { action: "refuser", text: i18n.t('common:gererbadgeages.refuser') }];
    table.buttonBar.addButton(i18n.t('common:gererbadgeages.valider'), "valider", true, menuOptions);

    return table;
  },

  /**
   * Creating the menu valider to the table, depends to the profils
   */
  _changeValiderOptions: function(model) {
    var self = this;

    if (self.context.ctxActionsPossibles.indexOf("Valider") != -1 && self._actionsForStatut("validation", model.get("statut").code)) {
      var profilId = model.get("matricule") + "," + model.get("code");
      var profilsModel = new GererBadgeagesProfilsModel();
      profilsModel.profilId = profilId;
      profilsModel.setHabContext(new HabilitationContext({
        onglet: this.context.ctxEcran,
        fonc_cour: "RES_VAL_BDG.G"
      }));

      profilsModel.fetch({
        success: function(fresh) {
          var profils = fresh.get("profils");
          var menuOptions = [];

          if (profils && profils.length === 0 || profils && profils.length > 0 && !profils[0]) { //0 profils
            self.table.buttonBar.model.trigger("custom.hide:valider");
          } else if (profils && profils.length === 1 && profils[0]) { //only 1 profils

            // #customer 146291
            menuOptions = [
              { action: "accepter_" + profils[0].code, text: i18n.t('common:gererbadgeages.accepter') },
              { action: "refuser_" + profils[0].code, text: i18n.t('common:gererbadgeages.refuser') }
            ];
            // #customer 146291

          } else if (profils && profils.length > 1) { // >1 profils
            menuOptions.push({ action: "", text: i18n.t('common:gererbadgeages.accepter') });
            _.each(profils, function(profil) {
              if (!STR.isBlank(profil)) {
                menuOptions.push({ action: "accepter_" + profil.code, text: profil.libelle });
              }
            });

            menuOptions.push({ action: "", text: i18n.t('common:gererbadgeages.refuser') });
            _.each(profils, function(profil) {
              if (!STR.isBlank(profil)) {
                menuOptions.push({ action: "refuser_" + profil.code, text: profil.libelle });
              }
            });
          }

          self.table.buttonBar.replaceButtonOptions("valider", menuOptions);

          self.table._showSelectedRowIndex();
        }
      });
    }
  },

  /**
   * Find libelle to the liste, with your code
   */
  _findLibelle: function(code, liste) {
    var i;
    for (i = 0; i < liste.length; i++) {
      var c1 = String(liste[i].code).toUpperCase();
      var c2 = String(code).toUpperCase();
      if (c1 == c2) {
        return liste[i].libelle;
      }
    }
    return null;
  },

  /**
   * Apply the class to the statut type
   */
  _apliqueStatut: function(badgeage) {
    var ensemble, demande;
    if (badgeage.get("iddemande") == 0) {
      ensemble = badgeage.get("evenement");
      demande = null;
    } else {
      demande = badgeage.get("iddemande");
      ensemble = null;
    }
    var style;
    switch (badgeage.get("statut").code) {
      case "D":
      case "T":
        style = "ui-phx-statut-demande";
        break;
      case "I":
        style = "ui-phx-statut-en-cours";
        break;
      case "A":
        style = "ui-phx-statut-accepte";
        break;
      case "R":
        style = "ui-phx-statut-refuse";
        break;
      case "H":
        style = "ui-phx-statut-hors-validation";
        break;
        // no default
    }

    var resp;
    if (badgeage.get("historique")) {
      resp = "<span class='txtStatutGerer " + style + "' demande='" + demande +
        "' ensemble='" + ensemble + "'>" + badgeage.get("statut").libelle +
        "</span>";
    } else {
      resp = "<span class='" + style + "'>" + badgeage.get("statut").libelle + "</span>";
    }

    return resp;
  },
  /**
   * Compare 2 badgeages and says if different
   */
  _isDiferentBadgeages: function(badgeage1, badgeage2) {
    if (
      badgeage1.date != badgeage2.date ||
      badgeage1.heure != badgeage2.heure ||
      (!STR.isBlank(badgeage1.sens) && !STR.isBlank(badgeage2.sens) && (badgeage1.sens.code != badgeage2.sens.code))
    ) {
      return true;
    } else {
      return false;
    }
  },

  /**
   * Renders the view to the form
   */
  render: function() {
    var json = { "i18n": i18n };
    this.$el.html(this.template(json));

    return this;
  },

  /**
   * Create and open tooltip when a hover your mouse over the class 'txtStatutGerer'
   */
  _openTooltipListener: function(event) {
    var demande = null;
    var reference = null;
    if (event.currentTarget.attributes.demande.value == 0) {
      reference = event.currentTarget.attributes.ensemble.value;
    } else {
      demande = event.currentTarget.attributes.demande.value;
    }

    var jquery = event.currentTarget.attributes["aria-describedby"];
    if (jquery) {
      return;
    }

    var self = this;
    this.tooltipDemande = demande || reference;

    var infobulleWorkflow = new BlockView();

    var contextInfobulleWkf = {
      ctxEcran: this.context.ctxEcran,
      ctxHabilitation: this.context.ctxHabilitation.HabilitationAcces,
      ctxTypeEvenement: "BADGEAGE",
      ctxRefEvenement: reference,
      ctxDemId: demande,
      ctxModeRestitution: "infobulle",
      ctxActivModeSynth: true,
      ctxModeDefaut: "Replie",
      ctxModeRech: true
    };

    infobulleWorkflow.setContext(contextInfobulleWkf);

    $(event.currentTarget).tooltip({ content: "" });
    $(event.currentTarget).tooltip("option", "content", function() {
      return infobulleWorkflow.$el.html();
    });
    $(event.currentTarget).prop("title", "");

    infobulleWorkflow.model.get("histoDem").once("sync", function() {
      try {
        var demande = event.currentTarget.attributes.demande.value;
        if (self.tooltipDemande == demande) {
          $(event.currentTarget).tooltip("open");
        }
      } catch (error) {
        // empty
      }
    });
  },

  /**
   * Close tooltip when a quit your mouse over the class 'txtStatutGerer'
   */
  _closeTooltipListener: function() {
    try {
      this.tooltipDemande = null;
      $(event.currentTarget).tooltip("destroy");

    } catch (error) {
      // empty
    }
  },

  /**
   * Return if is permitted an action depending on the state
   */
  _actionsForStatut: function(action, statut) {
    //view if can ejecute action where a one statut and a one collaborateur
    var utilisateur = this.context.ctxUtilisateur;
    var resp = false;
    //for ever statut
    if (action == "creation") {
      if (utilisateur == "Responsable") {
        resp = true;
      } else if (utilisateur == "Collaborateur") {
        resp = true;
      }
    } else if (action == "validation") {
      if (utilisateur == "Collaborateur") {
        resp = false;
      }
    }

    //for a one statut
    switch (statut) {
      case "D":
      case "T":
        if (action == "modification") {
          if (utilisateur == "Responsable") {
            resp = false;
          } else if (utilisateur == "Collaborateur") {
            resp = true;
          }
        } else if (action == "suppression") {
          if (utilisateur == "Responsable") {
            resp = false;
          } else if (utilisateur == "Collaborateur") {
            resp = true;
          }
        } else if (action == "validation") {
          if (utilisateur == "Responsable") {
            resp = true;
          }
        }
        break;
      case "I":
        if (action == "modification") {
          if (utilisateur == "Responsable") {
            resp = false;
          } else if (utilisateur == "Collaborateur") {
            resp = true;
          }
        } else if (action == "suppression") {
          if (utilisateur == "Responsable") {
            resp = false;
          } else if (utilisateur == "Collaborateur") {
            resp = true;
          }
        } else if (action == "validation") {
          if (utilisateur == "Responsable") {
            resp = true;
          }
        }
        break;
      case "A":
        if (action == "modification") {
          if (utilisateur == "Responsable") {
            resp = true;
          } else if (utilisateur == "Collaborateur") {
            resp = false;
          }
        } else if (action == "suppression") {
          if (utilisateur == "Responsable") {
            resp = true;
          } else if (utilisateur == "Collaborateur") {
            resp = true;
          }
        } else if (action == "validation") {
          if (utilisateur == "Responsable") {
            resp = false;
          }
        }
        break;
      case "R":
        if (action == "modification") {
          if (utilisateur == "Responsable") {
            resp = false;
          } else if (utilisateur == "Collaborateur") {
            resp = false;
          }
        } else if (action == "suppression") {
          if (utilisateur == "Responsable") {
            resp = false;
          } else if (utilisateur == "Collaborateur") {
            resp = false;
          }
        } else if (action == "validation") {
          if (utilisateur == "Responsable") {
            resp = false;
          }
        }
        break;
      case "H":
        if (action == "modification") {
          if (utilisateur == "Responsable") {
            resp = true;
          } else if (utilisateur == "Collaborateur") {
            resp = false;
          }
        } else if (action == "suppression") {
          if (utilisateur == "Responsable") {
            resp = true;
          } else if (utilisateur == "Collaborateur") {
            resp = true;
          }
        } else if (action == "validation") {
          if (utilisateur == "Responsable") {
            resp = false;
          }
        }
        break;
        // no default
    }
    return resp;
  },

  /**
   * Create the new badgeage
   */
  newBadgeage: function() {

    var target = new ListeBadgeageModel();
    var context = this.context;
    target.usecase = context.ctxEcran;
    target.habilitationV = context.ctxHabilitation.HabilitationAcces;
    this.workflow.updateHabContext({ nat_gest: "A" });

    //default values
    target.set("matricule", context.ctxGestionCollab.matricule);
    if (!STR.isBlank(context.ctxValeursCreation)) {
      target.get("saisie").date = context.ctxValeursCreation;
    } else {
      target.get("saisie").date = context.ctxGestionDate;
    }
    //select combo sens option
    target.get("saisie").sens.code = "E";

    this.table.model.coll.add(target);
    //this.model.set("value", target);
  },

  /**
   * Fetch data to the server of the badgeage
   */
  revertBadgeage: function(callback) {
    var editedModel;
    if (this.table.model.get("value").isNew()) {
      editedModel = this.table.model.get("value");
      editedModel.setHabContext(new HabilitationContext({
        onglet: this.context.ctxEcran,
        fonc_cour: this.context.ctxHabilitation.HabilitationGestion,
        nat_gest: "S"
      }));
      editedModel.destroy();
      //not selection model in the coll into
      this.table.model.set("value", null);
      this.table.model.set("mode", "C");

      this.workflow.updateHabContext({ nat_gest: "M" });

      this.table.model.selectFirstRow();
    } else {
      editedModel = this.table.model.get("value");
      editedModel.revert();

      this.workflow.updateHabContext({ nat_gest: "M" });

      // Select the record and paginate if required
      editedModel.trigger("row:unedit");
      this.table.model.set("mode", "R");

      if (callback) {
        callback();
      }
    }
  },

  /**
   * Save data of the badgeage
   */
  saveBadgeage: function(callback) {
    var self = this;
    var target = this.table.model.get("value");
    target.set("matricule", this.context.ctxGestionCollab.matricule);
    target.action = "demande";

    target.setHabContext(new HabilitationContext({
      onglet: this.context.ctxEcran,
      fonc_cour: this.context.ctxHabilitation.HabilitationGestion,
      nat_gest: "M"
    }));

    var operation = "";
    if (target.isNew()) {
      target.version = "2.2.0";
      target.id = null;
      operation = "create";
      target.updateHabContext({ nat_gest: "A" });
    } else {
      target.version = "2.2.0";
      target.action = target.action + "/" + encodeURIComponent(target.id);
      operation = "update";
    }

    if (this.showCombo) {
      self.table.model.attributes.value.set("commentaire", $(this.el).find("input.origine").val());
      self.table.model.attributes.value.set("origine", null);
      target.checkComments = true;
    }

    target.save(null, {
      success: function(fresh) {
        target.checkComments = false;
        self.table.model.coll.fetch({
          success: function() {
            //self.table.model.get("value").trigger("row:unedit");
            self.table.model.set("mode", "R");
            self.workflow.updateHabContext({ nat_gest: "M" });
            self.table.model.selectRow(fresh);

            self.model.trigger("badgeageChanged", fresh, operation);
            if (callback) {
              callback(fresh);
            }
          }
        });
      }
    });
  },

  /**
   * Check up is modificable the badgeage
   */
  modifiableBadgeage: function(callback) {
    var modifiableBadgeage = new GererBadgeagesGestion();

    modifiableBadgeage.action = "modifiable";
    modifiableBadgeage.id = encodeURIComponent(this.table.model.get("value").id);
    modifiableBadgeage.setHabContext(new HabilitationContext({
      onglet: this.context.ctxEcran,
      fonc_cour: this.context.ctxHabilitation.HabilitationAcces
    }));

    modifiableBadgeage.fetch({
      success: function(fresh) {
        if (callback) {
          callback(fresh);
        }
      }
    });
  },

  /**
   * Delete the badgeage
   */
  deleteBadgeage: function(comment) {
    var self = this;
    var target = new GererBadgeagesGestion();
    var droit = this.table.model.get("value").get("droit");
    var originalBadgeage = this.table.model.get("value");
    target.action = "demande";
    target.id = encodeURIComponent(this.table.model.get("value").id);
    if (!droit) {
      droit = "N";
    }
    target.setHabContext(new HabilitationContext({
      onglet: this.context.ctxEcran,
      fonc_cour: this.context.ctxHabilitation.HabilitationGestion,
      nat_gest: "S"
    }));

    if (!STR.isBlank(comment)) {
      target.set("commentaire", comment);
    } else {
      target.set("commentaire", " ");
      target.set("droit", "N");
    }

    target.destroy({
      contentType: 'application/json',
      url: Configuration.restRoot + "/rest/badgeage/composant/" + target.action + "/" + target.id + "/" + encodeURIComponent(target.get("commentaire")) + "/" + encodeURIComponent(droit),
      success: function() {
        self.table.model.coll.fetch({
          success: function() {
            self.model.trigger("badgeageChanged", originalBadgeage, "delete");
          }
        });
      }
    });
  },

  /**
   * Valider the badgeage to acepter
   */
  accepterBadgeage: function(commentaire, profil) {
    var self = this;
    var target = new GererBadgeagesGestion();
    var droit = this.table.model.get("value").get("droit");
    target.action = "accepter";
    target.id = encodeURIComponent(this.table.model.get("value").id);
    target.set("commentaire", commentaire);
    target.set("droit", droit);
    target.set("profil", {});
    target.get("profil").code = profil;
    target.canViewTreatment = true;

    target.setHabContext(new HabilitationContext({
      onglet: this.context.ctxEcran,
      fonc_cour: this.context.ctxHabilitation.HabilitationValidation,
      nat_gest: "M"
    }));

    target.save(null, {
      success: function(fresh) {
        self.table.model.coll.fetch({
          success: function() {
            self.table.model.selectRow(self.table.model.get("value"));
            var attrs = _.extend(self.table.model.get("value").attributes, fresh.attributes);
            fresh.set(attrs);
            self.model.trigger("badgeageChanged", fresh, "update");
          }
        });
      }
    });
  },

  /**
   * Valider the badgeage to refuser
   */
  refuserBadgeage: function(commentaire, profil) {
    var self = this;
    var target = new GererBadgeagesGestion();
    var droit = this.table.model.get("value").get("droit");
    target.action = "refuser";
    target.id = encodeURIComponent(this.table.model.get("value").id);
    target.set("commentaire", commentaire);
    target.set("droit", droit);
    target.set("profil", {});
    target.get("profil").code = profil;
    target.canViewTreatment = true;

    target.setHabContext(new HabilitationContext({
      onglet: this.context.ctxEcran,
      fonc_cour: this.context.ctxHabilitation.HabilitationValidation,
      nat_gest: "M"
    }));

    target.save(null, {
      success: function(fresh) {
        self.table.model.coll.fetch({
          success: function() {
            self.table.model.selectRow(self.table.model.get("value"));
            var attrs = _.extend(self.table.model.get("value").attributes, fresh.attributes);
            fresh.set(attrs);
            self.model.trigger("badgeageChanged", fresh, "update");
          }
        });
      }
    });
  }
});
