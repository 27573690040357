import { BaseModel } from 'core/models/base.model.js';
import { CriteresCalModel } from '../models/criteresCal.model.js';
import { FormView as CommonFormView } from 'core/views/form.view.js';
import { i18n } from '../../../../i18n.js';
import { LOG } from '../../../../utils/log.js';
import { STR } from '../../../../utils/str.js';
import { TYPE } from '../../../../tda/tda.js';
import TPL_vueannuelle_detail from '../detail.tpl.html';

/**
 * Render a Calendrier vue anuelle in a form
 */
export var FormView = CommonFormView.extend({

  initialize: function(options) {
    this.model = new BaseModel({
      value: new CriteresCalModel()
    });
    this.template = TPL_vueannuelle_detail;

    var self = this;
    this.selectionModel = new Backbone.Model({
      dateStart: "",
      dateEnd: "",
      isRange: false
    });
    //Update range model data when selectionModel changes
    this.listenTo(this.selectionModel, "change", function(model) {
      if ((model.hasChanged("dateStart") || model.hasChanged("dateEnd"))) {
        if ((!STR.isBlank(model.get("dateStart")) && !STR.isBlank(model.get("dateEnd"))) &&
          model.get("dateStart") != model.get("dateEnd")) {
          model.set("isRange", true);
        } else {
          model.set("isRange", false);
        }
      }

      if (STR.isBlank(model.get("dateStart")) && STR.isBlank(model.get("dateEnd")) && self.context) {
        model.set("dateStart", self.context.ctxDateEnEvidence);
      }
    });

    this.initialMonth = 0;
    this.months = 11;

    this.droppable = false;
    if (options && !STR.isBlank(options.droppable)) {
      this.droppable = options.droppable;
    }

    if (options && !STR.isBlank(options.workflow)) {
      this.workflow = options.workflow;
    }

    this.headerRender = null;
    this.cellRender = null; // Cell render function to paint specific content

    this.rowHeight = undefined;
    if (options && !STR.isBlank(options.rowHeight)) {
      this.rowHeight = options.rowHeight;
    } else {
      this.rowHeight = "20px";
    }
  },

  events: {
    "click .phx-cell ": "_cellClickListener",
    "dblclick .phx-cell ": "_cellClickListener",
    "click .phx-vueannuelle-header": "_headerListener",
    "dblclick .phx-vueannuelle-header": "_headerListener",
    "click .year": "_yearClickListener",
    "mousedown .phx-cell": "_mousedownHandler",
    "mousemove .phx-cell": "_mousemoveHandler",
    "mouseup .phx-cell": "_mouseupHandler",
    "click .phx-vueannuelle-header[data-month]": "_goToMonthlyView"
  },

  _cellClickListener: function(event) {
    // Trigger the custom even select to be interpreted by the parent view
    if (event.type == "click") {
      var editActionClicked = false;
      if (!editActionClicked) {
        var self = this;
        setTimeout(function() {
          var double = self.dblclick;
          if (double > 0) {
            self.dblclick = double - 1;
            return false;
          }
        }, 300);
      }
    } else if (event.type == "dblclick") {
      this.dblclick = 2;
      var dateSelected = $(event.currentTarget).attr("data-date");

      if ((this.selectionModel.get("dateStart") < this.selectionModel.get("dateEnd") && !(this.selectionModel.get("dateStart") <= dateSelected && dateSelected <= this.selectionModel.get("dateEnd"))) ||
        (this.selectionModel.get("dateStart") > this.selectionModel.get("dateEnd") && !(this.selectionModel.get("dateEnd") <= dateSelected && dateSelected <= this.selectionModel.get("dateStart")))) {
        this._dayClickListener(event);
      }

      this._dayDblclickListener(event);
      event.stopPropagation();
    }
  },

  _goToMonthlyView: function(event) {
    var target = $(event.currentTarget);
    var selectedDate = target.attr("data-year") + target.attr("data-month") + "01";
    if (this.workflow.get("viewType", this.workflow.MONTHLY_VIEW)) {
      this.workflow.selectionMonthModel.clear();
    }
    this.workflow.set("selectedDate", selectedDate);
    this.workflow.set("viewType", this.workflow.MONTHLY_VIEW);

    //MSGS.showWarning(i18n.t('messages:GL_1009'));
  },

  _dayClickListener: function(event) {
    var isShiftPressed = event.shiftKey;
    var dateSelected = $(event.currentTarget).attr("data-date");
    this._unselectAllDates();

    if (isShiftPressed === true && STR.isBlank(this.selectionModel.get("dateStart")) == true) {
      this.selectionModel.set({
        "dateStart": this.selectionModel.get("dateEnd"),
        "dateEnd": ""
      });
      isShiftPressed = false;
    }

    if (isShiftPressed === true) {
      this.selectionModel.set("dateEnd", dateSelected);

      //Put range of dates in correct order
      var selectionStartDate = TYPE.DATE.strToDate(this.selectionModel.get("dateStart"), TYPE.DATE.DEFAULT_MASK_BBDD);
      var selectionEndDate = TYPE.DATE.strToDate(this.selectionModel.get("dateEnd"), TYPE.DATE.DEFAULT_MASK_BBDD);

      if (selectionEndDate < selectionStartDate) {
        this.selectionModel.set({
          "dateStart": this.selectionModel.get("dateEnd"),
          "dateEnd": this.selectionModel.get("dateStart")
        });
      }

      this._treatSelectedDates();
    } else {
      if (!STR.isBlank(this.selectionModel.get("dateStart"))) {
        this._unmarkCell("td.phx-cell[data-date='" + this.selectionModel.get("dateStart") + "']");
      }

      if (this.selectionModel.get("dateStart") != dateSelected) {
        this.selectionModel.set("dateStart", dateSelected);
        this._markCell("td.phx-cell[data-date='" + this.selectionModel.get("dateStart") + "']");
      } else {
        this.selectionModel.set("dateStart", null);
      }
    }
  },

  _dayDblclickListener: function(event) {
    var dateSelected = $(event.currentTarget).attr("data-date");
    if (!STR.isBlank(this.selectionModel.get("dateEnd"))) {
      if (this.selectionModel.get("dateStart") < this.selectionModel.get("dateEnd")) {
        this.model.trigger("day:dblClicked", this.selectionModel.get("dateStart"), this.selectionModel.get("dateEnd"));
      } else {
        this.model.trigger("day:dblClicked", this.selectionModel.get("dateEnd"), this.selectionModel.get("dateStart"));
      }
    } else {
      this.model.trigger("day:dblClicked", dateSelected);
    }
  },

  _headerListener: function(event) {
    if (event.type == "click") {
      var editActionClicked = false;
      if (!editActionClicked) {
        var self = this;
        setTimeout(function() {
          var double = self.dblclick;
          if (double > 0) {
            self.dblclick = double - 1;
            return false;
          } else {
            self._monthClickListener(event);
          }
        }, 300);

      }
    } else if (event.type == "dblclick") {
      this.dblclick = 2;
      this._monthDblclickListener(event);
      event.stopPropagation();
    }
  },

  _monthClickListener: function(event) {
    var monthSelected = $(event.currentTarget).attr("data-month");
    var yearSelected = $(event.currentTarget).attr("data-year");

    this.model.trigger("month:clicked", monthSelected, yearSelected);
  },

  _monthDblclickListener: function(event) {
    var monthSelected = $(event.currentTarget).attr("data-month");
    var yearSelected = $(event.currentTarget).attr("data-year");
    var datedeb = yearSelected + monthSelected + "01";
    var datefin = yearSelected + monthSelected + this._daysInMonth(monthSelected, yearSelected);

    this.model.trigger("month:dblClicked", datedeb, datefin);
  },

  _yearClickListener: function(event) {
    var yearSelected = $(event.currentTarget).attr("data-year");

    this.model.trigger("year:clicked", yearSelected);
  },

  _treatSelectedDates: function() {
    LOG.debug("treat:" + this.selectionModel.get("dateStart") + " " + this.selectionModel.get("dateEnd"));
    var nbDiffMonth = this._diffMonths(this.selectionModel.get("dateStart"), this.selectionModel.get("dateEnd"));

    // Treat the special case of selecting from a later date until a previous date.
    if (nbDiffMonth > 0) {
      var datedeb = this.selectionModel.get("dateStart");
      var datefin = this.selectionModel.get("dateEnd");
    } else {
      nbDiffMonth = Math.abs(nbDiffMonth); // We need the absolute amount of months from this point on.
      var datedeb = this.selectionModel.get("dateEnd");
      var datefin = this.selectionModel.get("dateStart");
    }

    switch (nbDiffMonth) {
      case 0:
        this._markSelectedDates(datedeb, datefin);
        break;
      case 1:
        // Mark starting month.
        var auxDatefin = this._computeExtremumDate(datedeb, true);
        this._markSelectedDates(datedeb, auxDatefin);

        // Mark ending month.
        var auxDatedeb = this._computeExtremumDate(datefin, false);
        this._markSelectedDates(auxDatedeb, datefin);
        break;
      default: // case > 1
        // Mark starting month.
        var auxDatefin = this._computeExtremumDate(datedeb, true);
        this._markSelectedDates(datedeb, auxDatefin);

        // Mark full months between beginning and ending month.
        var auxDate = this._nextMonthDate(datedeb, true); // auxDate "YYYYMMDD" formatted
        for (var i = 0; i < nbDiffMonth - 1; i++) {
          var auxDatedeb = this._computeExtremumDate(auxDate, false);
          var auxDatefin = this._computeExtremumDate(auxDate, true);
          this._markSelectedDates(auxDatedeb, auxDatefin);

          auxDate = this._nextMonthDate(auxDate, true); // auxDate "YYYYMMDD" formatted
        }

        // Mark ending month.
        var auxDatedeb = this._computeExtremumDate(datefin, false);
        this._markSelectedDates(auxDatedeb, datefin);
        break;
    }
  },

  _computeExtremumDate: function(date, lastDayInMonth) {
    var month = TYPE.DATE.strToDate(date, "YYYYMMDD").getMonth();
    var year = TYPE.DATE.strToDate(date, "YYYYMMDD").getFullYear();

    // If is the ending month we have to compute the last day in month, for the starting month, 1.
    var extremumDay = lastDayInMonth === true ? this._daysInMonth(month, year) : 1;

    var objDate = new Date(year, month, extremumDay);
    var auxDate = TYPE.DATE.parse(TYPE.DATE.dateToStr(objDate)).val;

    return auxDate;
  },

  _markSelectedDates: function(datedeb, datefin) {
    var size = datefin - datedeb;
    var date = "";
    if (size > 0) {
      for (var i = 0; i <= size; i++) {
        date = parseInt(datedeb) + parseInt(i);
        this._markCell("td.phx-cell[data-date='" + date + "']");
      }
    } else {
      for (var i = size; i <= 0; i++) {
        date = parseInt(datedeb) + parseInt(i);
        this._markCell("td.phx-cell[data-date='" + date + "']");
      }
    }
  },

  _markCell: function(target) {
    var element = $(this.el).find(target);
    element.children().eq(0).removeClass("ui-phx-date-heure-actuelle-border");
    element.children().eq(0).addClass("ui-phx-date-heure-selectionnee-border");
    this._setBorderedHeightProperties(element.children().eq(0));
  },

  _unmarkCell: function(target) {
    var element = $(this.el).find(target);

    element.children().eq(0).removeClass("ui-phx-date-heure-selectionnee-border");
    if (element.attr("data-date") == this.context.ctxDateEnEvidence) {
      element.children().eq(0).addClass("ui-phx-date-heure-actuelle-border");
    } else {
      this._unsetBorderedHeightProperties(element);
    }
  },

  _unselectAllDates: function() {
    LOG.debug("_unselectAllDates");
    var element = $(this.el).find("div.ui-phx-date-heure-selectionnee-border");
    element.removeClass("ui-phx-date-heure-selectionnee-border");
    this._unsetBorderedHeightProperties(element);

    this.selectionModel.set("dateEnd", "");

    if (!STR.isBlank(this.context.ctxDateEnEvidence)) {
      var currentDay = $(this.el).find("td.phx-cell[data-date=" + this.context.ctxDateEnEvidence + "] > div");
      currentDay.addClass("ui-phx-date-heure-actuelle-border");
      this._setBorderedHeightProperties(currentDay);
    }

  },

  _setBorderedHeightProperties: function(element) {
    element.css("height", parseInt(this.rowHeight.replace("px", "")) - 4);
    element.find("div").css("line-height", (parseInt(this.rowHeight.replace("px", "")) - 4) + "px");
  },

  _unsetBorderedHeightProperties: function(element) {
    element.css("height", parseInt(this.rowHeight.replace("px", "")));
    element.find("div").css("line-height", this.rowHeight);
  },

  // Events handlers
  _mousedownHandler: function(event) {
    // If the element clicked is not a selectable element.
    if ($(event.target).parents("td.phx-cell").length > 0) {
      // Delete old selection
      this.datesSelectable = true;
      this._unselectAllDates(event);
      this._dayClickListener(event);
    }
  },

  _mousemoveHandler: function(event) {
    if (!STR.isBlank(this.selectionModel.get("dateStart")) && this.datesSelectable === true) {
      this._unselectAllDates();
      this.oldDatefinClicked = this.selectionModel.get("dateEnd");
      this.selectionModel.set("dateEnd", $(event.currentTarget).attr("data-date"));
      this._treatSelectedDates();
    }
  },

  _mouseupHandler: function() {
    this.datesSelectable = false;
  },

  setContext: function(options) {
    this.context = options.context;
    STR.setElValue(this.model.get("value"), "datedeb", this.context.ctxDateSynoptique.ctxDateDeb);
    STR.setElValue(this.model.get("value"), "datefin", this.context.ctxDateSynoptique.ctxDateFin);

    //Update selection date
    this.selectionModel.set("dateStart", this.context.ctxDateEnEvidence);

    this.mainModel = options.mainModel;
    this.render();
  },

  _calculateDates: function() {
    // 0 January - 11 December
    var dateDeb = TYPE.DATE.strToDate(this.context.ctxDateSynoptique, "YYYYMMDD");
    var dateFin = new Date(); //function used to obtain the Date object. Date is fixed behind this.
    dateDeb.setDate(1);
    dateFin.setDate(1);

    if (this.initialMonth < 0 && this.initialMonth > 11) {
      dateDeb = dateDeb.setMonth(0);
      dateFin.setMonth(11);
      dateFin.setYear(dateDeb.getFullYear());

      STR.setElValue(this.model.get("value"), "datedeb", TYPE.DATE.parse(TYPE.DATE.dateToStr(dateDeb)).val);
      STR.setElValue(this.model.get("value"), "datefin", TYPE.DATE.parse(TYPE.DATE.dateToStr(dateFin)).val);

    } else { // this.initialMonth between 1-11 ----- 2 posibilities

      if (this.initialMonth <= dateDeb.getMonth()) {
        dateDeb.setMonth(this.initialMonth);
        STR.setElValue(this.model.get("value"), "datedeb", TYPE.DATE
          .parse(TYPE.DATE.dateToStr(dateDeb)).val);

        if (dateDeb.getMonth() - 1 >= 0) { // we only show 12 months, then the last month is the first - 1
          dateFin.setMonth(dateDeb.getMonth() - 1);
          dateFin.setYear(dateDeb.getFullYear() + 1);
        } else { // if the first month is 0 (January) we have to set month 11(December)
          dateFin.setMonth(11);
          dateFin.setYear(dateDeb.getFullYear());
        }
        STR.setElValue(this.model.get("value"), "datefin", TYPE.DATE.parse(TYPE.DATE.dateToStr(dateFin)).val);
      } else { // !!!!
        dateFin = dateDeb;
        dateFin.setMonth(this.initialMonth);
        STR.setElValue(this.model.get("value"), "datefin", TYPE.DATE.parse(TYPE.DATE.dateToStr(dateFin)).val); // !!!!

        if (dateDeb.getMonth() + 1 <= 11) {
          dateDeb.setMonth(dateDeb.getMonth() + 1);
          dateDeb.setYear(dateDeb.getFullYear() - 1);
        } else {
          dateDeb.setMonth(0);
          dateDeb.setYear(dateDeb.getFullYear());
        }
        STR.setElValue(this.model.get("value"), "datedeb", TYPE.DATE.parse(TYPE.DATE.dateToStr(dateDeb)).val); // !!!!
      }
    }
  },

  render: function() {
    var json = { 'i18n': i18n };
    this.$el.html(this.template(json));

    this.drawCalendar();

    if (this.context.ctxAfficheAnnee === false) {
      this.$el.find(".phx-vueannuelle-header-years").hide();
    }

    return this;
  },

  /**
   * draw empty calendar
   */
  drawCalendar: function() {
    this.scalendar = this.scalendar || {};
    this.scalendar.datedeb = this.model.get("value").get("datedeb");
    this.scalendar.datefin = this.model.get("value").get("datefin");
    this.scalendar.nbMonth = this._diffMonths(this.scalendar.datedeb, this.scalendar.datefin);

    var id = "tableCalendrierJourEx";
    this.scalendar.headers = this._calculateHeader(this.scalendar.nbMonth);
    this.scalendar.data = this._calculateDays();

    var table = $("<table class='" + this.cid + " " + id + " disable-select'>");
    table.append(this._drawHeader(this.scalendar.headers));
    table.append(this._drawDays(this.scalendar.headers, this.scalendar.data));

    $(this.el).find(".tableCalendrier").empty();
    $(this.el).find(".tableCalendrier").html(table);
  },

  _diffMonths: function(date1, date2) {

    var date1 = TYPE.DATE.strToDate(date1, "YYYYMMDD");
    var date2 = TYPE.DATE.strToDate(date2, "YYYYMMDD");
    // Remember, months are 0 based in JS
    var year1 = date1.getFullYear();
    var year2 = date2.getFullYear();
    var month1 = date1.getMonth();
    var month2 = date2.getMonth();
    if (month1 === 0) { // Have to take into account
      month1++;
      month2++;
    }
    // If is the same month the diff is 0, for that we need to subtract 1 to the result.
    return ((year2 - year1) * 12 + (month2 - (month1 - 1))) - 1;
  },

  /**
   * Group of functions to draw the calendar
   */
  _calculateHeader: function(nbMonth) {

    var tblColumns = [];
    var months = i18n.t('common:monthNames', { returnObjects: true });
    var date = TYPE.DATE.strToDate(this.scalendar.datedeb, "YYYYMMDD");
    var month, year = "";

    for (var i = 0; i <= nbMonth; i++) {

      month = date.getMonth();
      year = date.getFullYear();

      var property = months[month] + year;
      // tblColumns.push({title : months[month] + " " + year, property : property, width : 100});
      tblColumns.push({
        title: months[month],
        property: property,
        width: 100,
        month: month,
        year: year
      });

      date = this._nextMonthDate(date, false);
    }
    return tblColumns;
  },

  _drawHeader: function(headers, id) {
    var thead = $("<thead>");
    var tr1 = $("<tr class='phx-vueannuelle-header-years'>");
    var self = this;

    if (headers.length > 0) {
      var year = headers[0].year;
      var span = 0;
      _.each(headers, function(column, index) {
        if (column.year == year && index != headers.length - 1) {
          span++;
        } else {
          if (index == headers.length - 1) {
            span++;
          }
          var th = $("<th class='phx-vueannuelle-header' colspan = '" + span + "'>");
          var div = $("<div>").text(year);
          if (self.headerRender) {
            self.headerRender(div, null, year, null, self);
          }
          th.append(div);
          tr1.append(th);
          span = 1;
          year = column.year;
        }
      }, year, span, headers.length);
    }

    var tr2 = $("<tr>");
    _.each(headers, function(column) {
      // column.month++ cause in JS is 0-index, and we want it 1-index
      var month = column.month++;
      month = column.month < 10 ? "0" + column.month : column.month;
      var th = $("<th data-month ='" + month + "' data-year ='" + column.year +
        "' class='phx-vueannuelle-header' colspan = '1'>");
      var div = $("<div><span>" + column.title + "</span></div>");
      if (self.headerRender) {
        var daysInMonth = self._daysInMonth(month - 1, column.year);
        self.headerRender(div, month, column.year, daysInMonth, self);
      }
      th.append(div);
      tr2.append(th);
    });

    thead.append(tr1);
    thead.append(tr2);

    return thead;
  },

  _drawDays: function(tblColumns, data) {
    var tbody = $("<tbody>");
    var strDate = null;
    for (var i = 0; i < 31; i++) {
      var tr = $("<tr>");
      for (var j = 0; j < tblColumns.length; j++) {

        var date = data[j][i];
        strDate = TYPE.DATE.dateToStr(date, "YYYYMMDD");
        var td = $("<td>");
        // columns of the number the day
        if (date) {

          var formattedDate = TYPE.DATE.parse(TYPE.DATE.dateToStr(date)).val;
          var dayNameInitialCap = i18n.t('common:dayNamesInitialCap', { returnObjects: true })[date.getDay()];
          var dayWeek = date.getDay();

          // create divs into the td
          td.addClass("phx-cell");
          td.attr("data-date", formattedDate);

          var div = $("<div>").css({ "position": "relative", "height": this.rowHeight });

          if (this.isJourExceptionnel(formattedDate)) {
            div.addClass("ui-phx-jours-exceptionnel").attr("title", TYPE.DATE.format(strDate, TYPE._getFormatByCode("DATE_L")));
          } else if (this.isJourFerie(formattedDate)) {
            div.addClass("ui-phx-jours-ferie").attr("title", TYPE.DATE.format(strDate, TYPE._getFormatByCode("DATE_L")));;
          }

          var divDay = $("<div>").addClass("day phx-vueannuelle-day").css({ "position": "absolute", "top": 0, "left": 0, "height": "100%", "width": "50%" });

          var numDayDiv = $("<div>").addClass("phx-vueannuelle-cell");
          numDayDiv.text(i + 1);

          if (this.isJourHorRepos(formattedDate) === true) {
            numDayDiv.addClass("ui-phx-horaire-repos");
          } else if (dayWeek == 6 || dayWeek == 0) {
            numDayDiv.addClass("ui-phx-entete-jour-week-end");
          }

          divDay.append(numDayDiv);

          var clases = "phx-vueannuelle-cell";
          if (dayWeek == 6 || dayWeek == 0) {
            if (this.isJourHorRepos(formattedDate) === true && this.isJourFerie(formattedDate) === false) {
              clases += " ui-phx-horaire-repos";
            } else {
              if (this.isJourFerie(formattedDate) === false)
                clases += " ui-phx-entete-jour-week-end";
            }
          }

          var nameDayDiv = $("<div class='" + clases + "'>").text(dayNameInitialCap);

          //Vacances
          if (this.vacancesCellRender) {
            this.vacancesCellRender(strDate, nameDayDiv);
          }

          divDay.append(nameDayDiv);

          div.append(divDay);

          var tdClass = "jourex";
          if (dayWeek == 6 || dayWeek == 0) {
            if (this.isJourHorRepos(formattedDate) === true) {
              tdClass += " ui-phx-horaire-repos";
            } else {
              tdClass += " ui-phx-entete-jour-week-end";
            }
          }
          var divDate = $("<div class='" + tdClass + "' data-date='" + formattedDate +
            "' style='position:absolute;top:0;left:50%;height:100%;width:50%;'>");

          if (dayWeek == 6 || dayWeek == 0) {
            divDate.html("&nbsp");
          }
          if (this.cellRender) {
            this.cellRender(divDate, formattedDate, div, this, i, j);
          }

          var divCell = divDate.find(".phx-cell-render");
          if (this.astreinteCellRender && divCell.length > 0) {
            this.astreinteCellRender(strDate, divCell);
          }

          div.append(divDate);

          if (this.context && this.context.ctxDateEnEvidence == formattedDate) {
            div.addClass("ui-phx-date-heure-actuelle-border");
            this._setBorderedHeightProperties(div);
          }

          td.append(div);

        } else {

          var div = $("<div class='day phx-vueannuelle-day'>");

          div.append($("<div class='phx-vueannuelle-cell ui-phx-horaire-repos'>").html("&nbsp"));
          div.append($("<div class='phx-vueannuelle-cell ui-phx-horaire-repos'>").html("&nbsp"));

          td.append(div);
          td.append($("<div class='jourex ui-phx-horaire-repos'>").html("&nbsp"));

        }
        tr.append(td);

      }
      tbody.append(tr);
    }

    return tbody;
  },

  _calculateDays: function() {
    var data = [];
    var nbMonths = this.scalendar.nbMonth;

    var date = TYPE.DATE.strToDate(this.scalendar.datedeb, "YYYYMMDD");
    var month, year = "";

    for (var i = 0; i <= nbMonths; i++) { // For each month
      var dataColumn = [];

      month = date.getMonth();
      year = date.getFullYear();
      var daysInMonth = this._daysInMonth(month, year);

      for (var day = 1; day <= daysInMonth; day++) { // For each day in month
        var auxDate = new Date(year, month, day);
        dataColumn.push(auxDate);
      }

      data.push(dataColumn);
      date = this._nextMonthDate(date, false);
    }
    return data;
  },

  _daysInMonth: function(month, year) { // Here Month is 1 based
    return new Date(year, month + 1, 0).getDate();
  },

  // Return a date of next month ("YYYYMM01") of a date gived.
  _nextMonthDate: function(date, formatted) {
    var month = null;
    var year = null;
    var nextMonth = null;

    if (formatted === true) {
      date = TYPE.DATE.strToDate(date, "YYYYMMDD");
    }

    month = date.getMonth();
    year = date.getFullYear();
    // December = 11; Months are 0-indexed in the i18nCom.monthNames and in DATE.getMonth()
    month++;
    if (month === 12) {
      month = 0;
      year++;
    }

    nextMonth = new Date(year, month, 1);

    if (formatted === true) {
      nextMonth = TYPE.DATE.parse(TYPE.DATE.dateToStr(nextMonth)).val;
    }

    return nextMonth;
  }

});