
import { STR } from '../../utils/str.js';

/**
 * Authentication Model. To be used with these ids: "session" --> Authentication
 * and user in session data "date" --> Server date "langues" --> langues
 * available
 */
export var AuthModel = Backbone.Model.extend({

  dummy: "",

  /**
   * UrlRoot
   */
  urlRoot: Configuration.restRoot + "/rest/authentification",

  defaults: {},

  validate: function(attrs) {
    var errors = {};

    if (STR.isBlank(attrs.login)) {
      errors.login = "Login cannot be empty";
    }

    if (!_.isEmpty(errors)) {
      var errorMap = {
        errorValidation: STR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
    return false;
  },

  parse: function(response) {
    if (response && response.token) {
      Configuration.x_token_key = response.token;
    }
    return response;
  }

});