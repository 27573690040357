import { ReadOnlyModel } from '../../../../../core/models/readOnly.model.js';

/**
 * Model for Brique 'Situations particulières'
 */
/**
 * Model used to show the data of situations particulieres
 */
export var SituationModel = ReadOnlyModel.extend({

  url: Configuration.restRoot + "/rest/brique/situation?tri=date;asc,libelle;asc,commentaire;asc",

  /**
   * Default data model
   *	"nbrang": 10,
   *	"total": null,
   *	"index": 1,
   *	"situation": {
   *		"date":"",
   *		"heure":"",
   *		"libelle":"",
   *		"commentaire":"",
   *		"type":"",
   *		"calcul":""
   *	}
   *}
   */
  defaults: {
    "nbrang": 10,
    "total": null,
    "index": 1,
    "situation": {
      "date": "",
      "heure": "",
      "libelle": "",
      "commentaire": "",
      "type": "",
      "calcul": ""
    }
  }

});