import { i18n } from '../i18n.js';
import { STR } from './str.js';
import { TYPE } from '../tda/tda.js';

/**
 * Utilities to manage the workflow
 */
export var WKF = {

  dummy: "",

  /**
   * Construct the first line of a workflow message
   *
   */
  buildLine: function(model) {
    var result = "";
    var date = TYPE.DATE.format(model.get("moments")[0]["@date"], TYPE._getFormatByCode("DATE_A"));
    var codefonctionnel = model.get("codefonctionnel");
    var resp = WKF._retrieveMessageCode(codefonctionnel);
    var messageCode = resp[0];
    var style = resp[1];

    var messageDate = " " + i18n.t('messages:GL_1026', { "1": "<b>" + date + "</b>", interpolation: { escapeValue: false } });
    var messageAuthor = " <b>" + model.get("auteur").message + "</b>";
    messageAuthor = this._buildMessageParametres(messageAuthor, model.get("auteur"));

    result += "<tr><td > <span class='" + style + "'>" + messageCode + "</span>" + messageDate + messageAuthor + "</td></tr>";

    if (!STR.isBlank(model.get("commentaire"))) {
      result += "<tr><td style='padding-left:30px;'>" + model.get("commentaire") + "</td></tr>";
    }
    return result;
  },

  /**
   * Construct the historical information line of a workflow message (as a table row)
   *
   */
  buildHistoLine: function(model) {
    var result = "";
    var date = TYPE.DATE.format(model.get("moments")[0]["@date"], TYPE._getFormatByCode("DATE_A"));
    var codefonctionnel = model.get("codefonctionnel");
    var resp = WKF._retrieveMessageCode(codefonctionnel);
    var messageCode = resp[0];
    var style = resp[1];

    var messageDate = " " + i18n.t('messages:GL_1026', { "1": "<b>" + date + "</b>", interpolation: { escapeValue: false } });
    var messageAuthor = " <b>" + model.get("auteur").message + "</b>";
    messageAuthor = this._buildMessageParametres(messageAuthor, model.get("auteur"));

    result += "<tr><td > <span class='" + style + "'>" + messageCode + "</span>" + messageDate + messageAuthor + "</td></tr>";

    return result;
  },

  /**
   * Construct the historical information line of a workflow message (as a cell)
   *
   */
  buildHistoLine2: function(model) {
    var date = TYPE.DATE.format(model.get("moments")[0]["@date"], TYPE._getFormatByCode("DATE_A"));
    var codefonctionnel = model.get("codefonctionnel");
    var resp = WKF._retrieveMessageCode(codefonctionnel);
    var messageCode = resp[0];
    var style = resp[1];

    var messageDate = " " + i18n.t('messages:GL_1026', { "1": "<b>" + date + "</b>", interpolation: { escapeValue: false } });
    var messageAuthor = " <b>" + model.get("auteur").message + "</b>";
    messageAuthor = this._buildMessageParametres(messageAuthor, model.get("auteur"));

    var td = $("<span>");
    var span = $("<span>");
    span.addClass(style);
    span.html(messageCode);
    td.html(span);
    td.append(messageDate);
    td.append(messageAuthor);

    return td;
  },

  /**
   * merge parameters values inside preformated message
   *
   */
  _buildMessageParametres: function(message, parametresBeforeFilter) {
    var parametres = {};
    var keysParametres = _.keys(parametresBeforeFilter);
    var indexLibelle = 0;
    //Delete message from parametres because it is the message not a parametre
    _.each(parametresBeforeFilter, function(item) {
      if (keysParametres[indexLibelle] != "message") {
        parametres[keysParametres[indexLibelle]] = item;
      }
      indexLibelle++;
    });

    var result = null;
    if (!STR.isBlank(message)) {
      result = message;
      if (!STR.isBlank(parametres)) {
        var array = [];
        for (var i in parametres) {
          if (i) {
            array.push(i);
          }
        }
        for (var j = 0; j < array.length; j++) {
          result = result.replace(array[j], parametres[array[j]]);
        }
      }
    }
    return result;
  },

  /**
   * retrieve the workflow message and style to use for a given 'codefonctionnel'
   *
   */
  _retrieveMessageCode: function(codefonctionnel) {
    var messageCode = "";
    var style = "";
    switch (codefonctionnel) {
      case "CO_AJ_D":
        messageCode = i18n.t('messages:GL_1010');
        style = "ui-phx-statut-demande";
        break;
      case "CO_AT_D":
        messageCode = i18n.t('messages:GL_1010');
        style = "ui-phx-statut-demande";
        break;
      case "CO_AT_V":
        messageCode = i18n.t('messages:GL_1011');
        //style = "ui-phx-statut-hors-validation";
        break;
      case "CO_AJ_V":
        messageCode = i18n.t('messages:GL_1011');
        //style = "ui-phx-statut-hors-validation";
        break;
      case "CO_SU_D":
        messageCode = i18n.t('messages:GL_1012');
        style = "ui-phx-statut-demande";
        break;
      case "CO_SU_V":
        messageCode = i18n.t('messages:GL_1013');
        //style = "ui-phx-statut-hors-validation";
        break;
      case "CO_MD_D":
        messageCode = i18n.t('messages:GL_1039');
        //style = "ui-phx-statut-demande";
        break;
      case "CO_MD_V":
        messageCode = i18n.t('messages:GL_1014');
        //style = "ui-phx-statut-hors-validation";
        break;
      case "CO_AD":
      case "CO_AD_D":
        messageCode = i18n.t('messages:GL_1015');
        style = "ui-phx-statut-demande";
        break;
      case "CO_AD_V":
        messageCode = i18n.t('messages:GL_1015');
        //style = "ui-phx-statut-hors-validation";
        break;
      case "CO_AS":
        messageCode = i18n.t('messages:GL_1016');
        break;
      case "RE_RED_D":
        messageCode = i18n.t('messages:GL_1017');
        style = "ui-phx-statut-en-cours";
        break;
      case "RE_ACD_D":
        messageCode = i18n.t('messages:GL_1018');
        style = "ui-phx-statut-en-cours";
        break;
      case "RE_ACD_V":
        messageCode = i18n.t('messages:GL_1020');
        style = "ui-phx-statut-accepte";
        break;
      case "RE_RED_V":
        messageCode = i18n.t('messages:GL_1019');
        style = "ui-phx-statut-refuse";
        break;
      case "RE_RES_D":
        messageCode = i18n.t('messages:GL_1021');
        style = "ui-phx-statut-en-cours";
        break;
      case "RE_RES_V":
        messageCode = i18n.t('messages:GL_1023');
        style = "ui-phx-statut-refuse";
        break;
      case "RE_ACS_D":
        messageCode = i18n.t('messages:GL_1022');
        style = "ui-phx-statut-en-cours";
        break;
      case "RE_ACS_V":
        messageCode = i18n.t('messages:GL_1024');
        style = "ui-phx-statut-accepte";
        break;
      case "RE_AJ_D":
        messageCode = i18n.t('messages:GL_1010');
        style = "ui-phx-statut-demande";
        break;
      case "RE_AJ_V":
        messageCode = i18n.t('messages:GL_1011');
        //style = "ui-phx-statut-hors-validation";
        break;
      case "RE_SU_D":
        messageCode = i18n.t('messages:GL_1012');
        style = "ui-phx-statut-demande";
        break;
      case "RE_SU_V":
        messageCode = i18n.t('messages:GL_1013');
        //style = "ui-phx-statut-hors-validation";
        break;
      case "RE_MO_V":
        messageCode = i18n.t('messages:GL_1014');
        //style = "ui-phx-statut-hors-validation";
        break;
      case "REL_D":
        messageCode = i18n.t('messages:GL_1025');
        break;
      case "REL_S":
        messageCode = i18n.t('messages:GL_1025');
        break;
      default:
        messageCode = "";
        break;
    }
    return [messageCode, style];
  }

};