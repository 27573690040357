module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<fieldset class="groupecompteurs-consultation">\n  <div class="phx-formTable">\n    <div class="phx-formErrors ui-state-error"></div>\n    <fieldset class="groupecompteurs-consultation-detail">\n      <legend>\n        '+
((__t=(i18n.t("common:resultatgrpcompteurs.consultation_detail_label")))==null?'':__t)+
'\n      </legend>\n      <div>\n        <div class="groupecompteurs-consultation-groupe">\n          <label for="groupe">'+
((__t=(i18n.t("common:resultatgrpcompteurs.groupe")))==null?'':__t)+
'</label>\n          <span class="cmbGroupe"></span>\n        </div>\n\n        <label for="date" class="required">'+
((__t=(i18n.t("common:resultatgrpcompteurs.date")))==null?'':__t)+
'</label>\n        <input type="text" class="date typeDate" value="" size="10" /><br>\n\n        <label for="valeur">'+
((__t=(i18n.t("common:resultatgrpcompteurs.valeur")))==null?'':__t)+
'</label>\n        <span class="groupecompteurs-consultation-cmbValeur">\n					<span class="checkbox-zone-demande">\n						<label for="demande">'+
((__t=(i18n.t("common:resultatgrpcompteurs.absence")))==null?'':__t)+
'</label>\n						<input type="checkbox" style="margin-right:3px;"  class="demande" value="true" />\n					</span>\n        <br>\n        <span class="checkbox-zone-souhait">\n						<label for="souhait">'+
((__t=(i18n.t("common:resultatgrpcompteurs.souhait")))==null?'':__t)+
'</label>\n						<input type="checkbox" style="margin-right:3px;"  class="souhait"  value="true" />\n					</span>\n        </span>\n      </div>\n    </fieldset>\n  </div>\n</fieldset>\n';
}
return __p;
};
