import { TabSheetView } from './tabSheet.view.js';
import TPL_app_zone from '../zone.tpl.html';

/**
 * Zone view. A zone it's a view with a menu on the left and a tabsheet on the
 * right that is able to load Use cases in the tab sheet
 */
export var ZoneView = Backbone.View.extend({

  zJSON: undefined, //JSON needed to paint a zone.

  className: "phx-zone",

  initialize: function(options) {
    this.template = TPL_app_zone;
    this.zJSON = options.zJSON;
  },

  render: function() {
    this.$el.html(this.template(this.zJSON));
    // TabSheet
    this.tabsView = new TabSheetView({
      el: $(".phx-center", this.el),
      model: this.model,
      zJSON: this.zJSON
    });
    this.tabsView.render();
    // By default it's hidden
    this.$el.hide();
    return this;
  }
});