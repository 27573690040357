import { GroupeMenuModel } from './groupeMenu.model.js';
/**
 * Parametres Divers Collection
 */
export var GroupeMenuColl = Backbone.Collection.extend({
  dummy: "",
  /**
   * habilitationV
   */
  habilitationV: "N",
  /**
   * habilitationG
   */
  habilitationG: "N",
  /**
   * usecase
   */
  usecase: "app",
  /**
   * url
   */
  url: Configuration.restRoot + "/rest/configuration/groupemenu",
  /**
   * model
   */
  model: GroupeMenuModel,

  /**
   * Get a Typologye by code
   */
  get: function(id) {
    var element = Backbone.Collection.prototype.get.call(this, id);

    var result = element;
    if (element instanceof Backbone.Model) {
      result = element.clone();
    }
    return result;
  }
});
