import { BaseModel } from '../../../../../core/models/base.model.js';

import { STR } from '../../../../../utils/str.js';

/**
 * referentiel Utilisateurs Model
 */
export var DelegationsUtilisateurModel = BaseModel.extend({

  urlRoot: Configuration.restRoot + "/rest/utildelegation/utilisateurs",

  defaults: {
    "id": null,
    "matricule": null,
    "matriculeaux": "",
    "nom": "",
    "prenom": "",
    "utilcode": "",
    "utilnom": ""

  },

  validate: function() {
    var errors = {};

    if (!_.isEmpty(errors)) {
      var errorMap = {
        errorValidation: STR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
  },

  parse: function(response) {
    if (response) {
      if (!STR.isBlank(response.matricule)) {
        response.id = response.matricule;
      } else {
        response.id = parseInt(response.utilnom);
      }
    }
    return response;
  }
});