import { AutocompleteColl } from '../combo/autocomplete.collection.js';

import { DialogView } from '../../views/dialog.view.js';
import { FORMS } from '../../../utils/forms.js';
import { i18n } from '../../../i18n.js';
import { LOG } from '../../../utils/log.js';
import { ReadOnlyModel } from '../../models/readOnly.model.js';
import { SelecteurCheminDialogView } from './selecteurCheminDialog.view.js';
import { SelecteurCheminModeSimpleColl } from './selecteurCheminModeSimple.collection.js';
import { SelecteurCheminNivColl } from './selecteurCheminNiv.collection.js';
import { SelecteurCheminResultItemView } from './selecteurCheminResultItem.view.js';
import { STR } from '../../../utils/str.js';
import { SYNC } from '../../../utils/sync.js';
import { TYPE } from '../../../tda/tda.js';
import { UTILS } from '../../../utils/utils.js';
import TPL_common_selecteur_chemin from './selecteur_chemin.tpl.html';
import { GLOBAL_DATA } from '../../../globalData.js';

export var SelecteurCheminView = Backbone.View.extend({
  cache: undefined,
  cacheEventsModel: undefined,

  dummy: "",

  /**
   * Name of the html tag which involves the view
   */
  tagName: "div",

  /**
   * Name of the container class of the view
   */
  className: "phx-selecteur-chemin",

  /**
   * Alias for the messages to show in this view
   */
  //REVIEW: 	i18n : phx.i18n.common,

  /**
   * Width of the combo list
   */
  listWidth: "400px",

  /**
   * Minimum string length to do the autocomplete search
   */
  minSearchLength: Configuration.selecteurChemin.minCharactersToSearch,

  /**
   * Indicator to know if the multiselection is allowed or not
   */
  multiselection: false,

  /**
   * Maximum number of items in the list of results to show
   */
  maxResultItems: Configuration.selecteurChemin.maxResultItems,

  /**
   * Indicator to know if the component has dialog for complete view or not
   */
  modeComplet: false,

  /**
   * Indicator to know if the component is editable or not
   */
  readonly: false,

  /**
   * Indicator to know if the component is show as plain text or not
   */
  plainText: false,

  /**
   * Event launched when the detail button is clicked
   *
   */
  /**
   * Event launched when the autocompletion result list is showed
   *
   */
  /**
   * Event launched when an element from the autocompletion result list is selected
   *
   */
  /**
   * Event launched when the mouse is over an element from the autocompletion result list to close any existing tooltip
   *
   */
  /**
   * Event launched when the mouse is over an element from the autocompletion result list to open a new tooltip
   *
   */
  /**
   * Event launched when a new element is selected
   *
   */
  /**
   * Event launched when the autocomplete list is returned to build the tooltips
   *
   */
  /**
   * Event launched when the autocomplete list is focused
   *
   */

  events: {
    "click .phx-chemin-button": "_openDetailPopup",
    "autocompleteopen": "_openlist",
    "autocompleteselect": "_selectItem",
    "tooltipclose .phx-chemin-input": "_focused",
    "tooltipopen .phx-chemin-input": "_focused",
    "change .phx-chemin-input": "_changeItem",
    "autocompleteresponse .phx-chemin-input": "_setTooltips",
    "autocompletefocus": "_keyboardMenuItemFocus"
  },

  /**
   * Constructor
   * Selecteur de chemin mode simple view
   */
  initialize: function(params) {

    this.cache = {};
    this.cacheEventsModel = new Backbone.Model();

    //Params that come from param.context
    if (!STR.isBlank(params.context)) {

      var context = params.context;

      if (!STR.isBlank(context.ctxActivSelMultiple)) {
        params.multiple = context.ctxActivSelMultiple
      }
      if (!STR.isBlank(context.ctxStructure)) {
        params.structid = context.ctxStructure;
      }
      if (!STR.isBlank(context.ctxActivModeComplet)) {
        params.modeComplet = context.ctxActivModeComplet;
      }
      if (!STR.isBlank(context.ctxActivModeComplet)) {
        params.modeComplet = context.ctxActivModeComplet;
      }
      if (context.ctxPeriodeRecherche && !STR.isBlank(context.ctxPeriodeRecherche.datedebut)) {
        params.datedebut = context.ctxPeriodeRecherche.datedebut;
      }
      if (context.ctxPeriodeRecherche && !STR.isBlank(context.ctxPeriodeRecherche.datefin)) {
        params.datefin = context.ctxPeriodeRecherche.datefin;
      }
      if (!STR.isBlank(context.ctxAfficheRacine)) {
        params.racine = context.ctxAfficheRacine;
      }
      if (!STR.isBlank(context.ctxStructureCollab)) {
        params.nonrattachable = !context.ctxStructureCollab;
      }
      if (!STR.isBlank(context.ctxStructureActivites)) {
        params.nonrattactiv = !context.ctxStructureActivites;
      }
      if (!STR.isBlank(context.ctxEcran)) {
        params.ecran = context.ctxEcran;
      }
      if (!STR.isBlank(context.ctxConsultation)) {
        params.readonly = context.ctxConsultation;
      }
      if (!STR.isBlank(context.ctxHabilitation)) {
        params.habContext = context.ctxHabilitation;
      }
      //context.ctxSelRacineAuto > root is selectable (default true)
      if (!STR.isBlank(context.ctxSelRacineAuto)) {
        params.selRacineAuto = context.ctxSelRacineAuto;
      }
      //context.ctxSelNoeudChemin > intermediate elements, not leaf or root, are selectable (default true).
      if (!STR.isBlank(context.ctxSelNoeudChemin)) {
        params.selNoeudChemin = context.ctxSelNoeudChemin;
      }
      //context.ctxInitChemins > List consists of one or more structural paths preset in the initial display of the component.
      if (!STR.isBlank(context.ctxInitChemins)) {
        params.initChemins = context.ctxInitChemins;
      }

      if (!STR.isBlank(context.ctxCollabExclure)) {
        params.exclure_collab = context.ctxCollabExclure;
      }
    }

    this.coll = params.ws;
    /**
     * HTML template of the view
     */
    this.template = TPL_common_selecteur_chemin;

    /**
     * Parameters of the initialization
     */
    this.params = params;

    if (params && !STR.isBlank(params.multiselection)) {

      /**
       * Multiselection indicator
       */
      this.multiselection = params.multiselection;
    }
    var today = TYPE.DATE.parse(TYPE.DATE.dateToStr(SYNC.getServerDate())).val;
    var parameters = {
      structid: null,
      parentcode: null,
      parentdebsitu: null,
      parentfinsitu: null,
      datedebut: today,
      datefin: today,
      multiple: false,
      intermediaire: true,
      partial: true,
      complet: false,
      selectionnes: "",
      niveau: null,
      racine: null,
      nonrattachable: null,
      nonrattactiv: null,
      elementcode: null,
      exclure_collab: null,
      structval: null
    };

    /**
     * Id of the structure of the component
     */
    this.structid = null;
    if (params && !STR.isBlank(params.structid)) {
      this.structid = params.structid;
      parameters.structid = params.structid;
    } else {
      this.structid = "";
    }

    parameters.multiple = this.multiselection;
    this.params.multiple = this.multiselection;

    this.params.complet = false;

    if (params && !STR.isBlank(params.parentcode)) {
      parameters.parentcode = params.parentcode;
    }

    if (params && !STR.isBlank(params.parentdebsitu)) {
      this.parentdebsitu = params.parentdebsitu;
    }
    if (params && !STR.isBlank(params.parentfinsitu)) {
      this.parentfinsitu = params.parentfinsitu;
    }
    /**
     * Start date of the component
     */
    this.datedebut = undefined;
    if (params && !STR.isBlank(params.datedebut)) {
      this.datedebut = params.datedebut;
      parameters.datedebut = params.datedebut;
    } else {
      this.datedebut = today;
      this.params.datedebut = today;
    }

    /**
     * End date of the component
     */
    this.datefin = undefined;
    if (params && !STR.isBlank(params.datefin)) {
      this.datefin = params.datefin;
      parameters.datefin = params.datefin;
    } else {
      this.params.datefin = today;
      this.datefin = today;
    }

    if (params && !STR.isBlank(params.intermediaire)) {
      parameters.intermediaire = params.intermediaire;
    }

    if (params && !STR.isBlank(params.partial)) {
      parameters.partial = params.partial;
    }

    if (params && !STR.isBlank(params.selectionnes)) {
      parameters.selectionnes = params.selectionnes;
    }

    if (params && !STR.isBlank(params.niveau)) {
      parameters.niveau = params.niveau;
    }

    if (params) {
      parameters.racine = params.racine;
      parameters.nonrattachable = params.nonrattachable;
      parameters.nonrattactiv = params.nonrattactiv;
    }

    if (params && !STR.isBlank(params.ecran)) {
      parameters.ecran = params.ecran;
    }

    if (params && !STR.isBlank(params.selRacineAuto)) {
      parameters.selRacineAuto = params.selRacineAuto;
      this.selRacineAuto = params.selRacineAuto;
    }
    if (params && !STR.isBlank(params.selNoeudChemin)) {
      parameters.selNoeudChemin = params.selNoeudChemin;
      this.selNoeudChemin = params.selNoeudChemin;
    }

    if (params && !STR.isBlank(params.exclure_collab)) {
      parameters.exclure_collab = params.exclure_collab;
    }

    if (params && !STR.isBlank(params.structval)) {
      this.params.structval = params.structval;
      parameters.structval = params.structval;
    } else {
      this.params.structval = null;
    }

    /**
     * Collection of elements of the component
     */
    this.coll = new SelecteurCheminModeSimpleColl(parameters);

    if (params && !STR.isBlank(params.name)) {
      /**
       * Name of the component
       */
      this.name = params.name;
    }

    if (params && params.readonly) {
      /**
       * Readonly indicator
       */
      this.readonly = params.readonly;
    }

    if (params && !this.readonly && !STR.isBlank(params.modeComplet)) {
      /**
       * Mode complet indicator
       */
      this.modeComplet = params.modeComplet;
      parameters.complet = this.modeComplet;
      parameters.habContext = params.habContext;
      if (this.modeComplet) {
        /**
         * View for the mode complet dialog
         */
        this.view = SelecteurCheminDialogView;
      }
    }

    if (params && !STR.isBlank(params.plainText)) {
      /**
       * PlainText indicator
       */
      this.plainText = params.plainText;
    }

    /**
     * Style of rendering of the component elements
     */
    this.listRenderStyle = 1;
    if (params && params.listRenderStyle && !STR.isBlank(GLOBAL_DATA.paramDivers.get("SPEValideu")) && GLOBAL_DATA.paramDivers.get("SPEValideu").get("valeur") != parameters.structval) {
      this.listRenderStyle = params.listRenderStyle;
    }

    if (params && params.habContext) {
      this.habilitationContext(params.habContext);
    }

    if (params && params.idCache) {
      this.idCache = params.idCache;
      //Initialize Cache
      if (STR.isBlank(this.cache["niveau"]) || STR.isBlank(this.cache["item"])) {
        this.cache["niveau"] = {};
        this.cache["item"] = {};
      }
      //Initialize Niveau and item cache
      if (STR.isBlank(this.cache["niveau"][this.idCache])) {
        this.cache["niveau"][this.idCache] = {};
      }
      if (STR.isBlank(this.cache["item"][this.idCache])) {
        this.cache["item"][this.idCache] = {};
      }
    }

    /**
     * Title of the component
     */
    if (params && !_.isUndefined(params.titlePopup)) {
      this.title = i18n.t('messages:GT_2128', { "1": params.titlePopup });
    } else {
      this.title = undefined;
      if (this.datedebut && this.datefin) {
        if (!STR.isBlank(GLOBAL_DATA.paramDivers.get("SPEValideu")) && GLOBAL_DATA.paramDivers.get("SPEValideu").get("valeur") === "1" && this.structid == parameters.structval) {
          this.title = i18n.t('common:chemin.dlgtitleValideur');
        } else {
          if (this.datedebut === this.datefin) {
            this.title = i18n.t('common:chemin.dlgtitle');
          } else {
            this.title = i18n.t('common:chemin.dlgtitle');
          }
        }
      } else {
        this.title = "";
      }
    }

    /**
     * Height of the component
     */
    this.height = undefined;
    if (params && params.height) {
      this.height = params.height;
    } else {
      this.height = 500;
    }

    /**
     * Width of the component
     */
    this.width = undefined;
    if (params && params.width) {
      this.width = params.width;
    } else {
      this.width = 800;
    }

    /**
     * Width of the input field of the component
     */
    this.fieldWidth = undefined;
    if (params && params.fieldWidth) {
      var l_regxp = /^([0-9\.]+)[%]?$/g;
      if (l_regxp.test(params.fieldWidth) || params.fieldWidth === "auto") {
        this.fieldWidth = params.fieldWidth;
      } else {
        throw new Error("the fieldWidth must be numeric (" + this.name + ")");
      }
    }

    if (params && params.racine) {

      /**
       * Indicator of the root element
       */
      this.racine = params.racine;
    }
    if (params && params.nonrattachable) {

      /**
       * Indicator of an element "non rattachable"
       */
      this.nonrattachable = params.nonrattachable;
    }
    if (params && params.nonrattactiv) {

      /**
       * Indicator of an element "non rattachable activite"
       */
      this.nonrattactiv = params.nonrattactiv;
    }
    if (params && !STR.isBlank(params.elementcode)) {
      parameters.elementcode = params.elementcode;
    }

    if (params && params.keepOldId) {
      this.keepOldId = params.keepOldId;
    } else {
      this.keepOldId = null;
    }
    // called with the selected items
    /**
     * Callback function to execute when an element is added
     */
    this.addItemCallback = params.addItemCallback;

    /**
     * Callback function to execute when an element is deleted
     */
    this.removeItemCallback = params.removeItemCallback;

    // response list filled with user selection
    /**
     * Selection list of elements of the autocompletion results
     */
    this.selection = new AutocompleteColl();
    this.selection.itemRenderer = this._getAttrsRenderer();
    this.selection.on("click:item", this._removeItem, this);

    // initial display of the component.
    this.clearSelection();
    if (!STR.isBlank(params.initChemins)) {
      if (_.isArray(params.initChemins) && params.initChemins.length > 0) {
        // init with one or more elements in arrays
        for (var i = 0; i < params.initChemins.length; i++) {
          if (!STR.isBlank(params.initChemins[i].get("elementCode"))) {
            this.addValue(params.initChemins[i]);
          }
        }
      } else if (!STR.isBlank(params.initChemins.get("elementCode"))) {
        // init with an element in object
        this.addValue(params.initChemins);
      }
    }

  },

  setHabContext: function(habContext) {
    this.habContext = habContext;
  },

  updateHabContext: function(attributes) {
    if (this.habContext) {
      this.habContext.update(attributes);
    }
  },

  getHabContext: function() {
    return this.habContext;
  },
  /**
   * Called the first time to initialize the context of the combo
   */
  habilitationContext: function(context) {
    if (this.coll) {
      if (STR.isBlank(this.coll.habContext) || !_.isEqual(this.coll.habContext.toJSON(), context.toJSON())) {
        this.clearCache();
      }

      this.stopListening();
      this.coll.setHabContext(context);
      this.listenTo(context, "change", this.clearCache);
    }

  },

  clearCache: function() {
    for (var i in this.cache) {
      if (this.cache.hasOwnProperty(i)) {
        this.cache[i].length = 0;
        delete this.cache[i];
      }
    }
    this.cache["pendingInUse"] = this.cache["pendingInUse"] || [];
  },

  /**
   * Cleans the selection of the component
   */
  cleanChemin: function() {
    this.$el.find(".phx-chemin-selection").empty();
    this.selection.reset();
  },

  /**
   * Sets the dates of the component
   */
  setDates: function(datedeb, datefin) {
    var today = TYPE.DATE.parse(TYPE.DATE.dateToStr(SYNC.getServerDate())).val;
    if (!STR.isBlank(datedeb)) {
      this.datedebut = datedeb;
      this.params.datedebut = datedeb;
    } else {
      this.datedebut = today;
      this.params.datedebut = today;
    }

    if (!STR.isBlank(datefin)) {
      this.datefin = datefin;
      this.params.datefin = datefin;
    } else {
      this.params.datefin = today;
      this.datefin = today;
    }
    this.coll.setDates(this.params.datedebut, this.params.datefin);
  },

  /**
   * Gets the path of an element of the component's list
   */
  _getChemin: function(model, initParent) {
    var result = "";

    //When there are too many results show according message.
    if (_.isNull(model.code)) {
      return model.libelle;
    }
    if ((model instanceof Backbone.Model) && (!STR.isBlank(model.get("attrs")) && _.isNull(model.get("attrs").code))) {
      return model.libelle;
    }

    if (initParent > 0) {
      result += ".../";
    } else {
      initParent = 1;
    }

    if (!STR.isBlank(model)) {
      var parents = [];
      var item = {};
      if (model.parents) {
        parents = model.parents;
        item = new Backbone.Model(model);
      } else {
        if (model.code) {
          //Structure complete
          parents = [];
          item = new Backbone.Model(model);
        } else if (model.get("attrs") && model.get("attrs").parents) {
          parents = model.get("attrs").parents;
          item = new Backbone.Model(model.get("attrs"));
        }
      }

      if (parents.length > 1) {
        var first = true;
        for (var i = initParent; i < parents.length; i++) {
          if (first === true) {
            first = false;
          } else {
            result += "/";
          }
          var parentModel = new Backbone.Model(parents[i]);
          result += this._getRenderer().call(this, parentModel);
        }
      }
      if (!STR.isBlank(result) && result !== ".../") {
        result += "/";
      }
      result += this._getRenderer().call(this, item);
    }
    return result;
  },

  /**
   * Shows the tooltip of an element
   */
  _showTooltip: function(model) {
    var result = "";

    if (this.datedebut && this.datefin && !STR.isBlank(model)) {
      if (model.datedebut > this.datedebut || model.datefin < this.datefin) {
        //(&#9679)= (●)
        result = "<span class=\"phx-chemin-tooltip\" title=\"\">(&#9679)</span>";
      }
    }

    return result;
  },

  /**
   * Method which is executed during the navigation in the menu using the keyboard
   */
  _keyboardMenuItemFocus: function() {
    return false;
  },

  /**
   * Method which is executed during the navigation in the menu using the keyboard
   */
  _getLevels: function(callback) {
    var self = this;
    if (this._useCache() && !STR.isBlank(this.cache["niveau"][this.idCache][this.structid])) {
      //Using cache and 2nd time
      if (this.cache["niveau"][this.idCache][this.structid].fetching) {
        this.listenToOnce(this.cacheEventsModel, "niveau" + this.idCache + this.structid, function() {
          self.niveauColl = self.cache["niveau"][self.idCache][self.structid].data;
          callback(self.niveauColl.models);
        });
      } else {
        this.niveauColl = this.cache["niveau"][this.idCache][this.structid].data;
        callback(this.niveauColl.models);
      }
    } else {
      if (this.niveauColl && this.niveauColl.models) {
        callback(this.niveauColl.models);
      } else {
        if (this._useCache()) {
          //Using cache and 1st time
          this.cache["niveau"][this.idCache][this.structid] = { fetching: true };
        }
        var coll = new SelecteurCheminNivColl({ code: this.structid });
        if (this.params && this.params.context && !STR.isBlank(this.params.context.ctxEcran)) {
          coll.ecran = this.params.context.ctxEcran;
        }
        if (!STR.isBlank(this.params.context) && this.params.context.ctxStructureSelpop === true) {
          coll.selectpopu = true;
        }
        coll.fetch({
          success: function() {
            if (!self.niveauColl) {
              self.niveauColl = coll;
              if (self._useCache()) {
                self.cache["niveau"][self.idCache][self.structid].data = self.niveauColl;
                self.cache["niveau"][self.idCache][self.structid].fetching = false;
                self.cacheEventsModel.trigger("niveau" + self.idCache + self.structid);
              }
            }
            callback(self.niveauColl.models);
          },
          error: function() {
            callback([]);
          }
        });
      }
    }
  },
  _useCache: function() {
    if (!STR.isBlank(this.idCache)) {
      return true;
    }
    return false;
  },
  /**
   * Sets the readonly flag of the component
   */
  setReadOnly: function(readOnly) {
    this.readonly = readOnly;
    this.render();
    if (this.modeComplet === true) {
      if (readOnly === true) {
        this.$el.find(".phx-chemin-button").hide();
      } else {
        this.$el.find(".phx-chemin-button").show();
      }
    }
  },

  /**
   * Sets the tooltips of the elements of the component
   */
  _setTooltips: function(event, ui) {
    var self = this;
    this._getLevels(function(levels) {
      for (var i = 0; i < ui.content.length; i++) {
        var tooltip = $(ui.content[i].label).tooltip({ content: "" });
        if (tooltip.length > 1) {
          var txt = self._tooltipText(ui.content[i], levels, true);

          tooltip.tooltip("option", "content", txt);
          ui.content[i].label = tooltip;
        }
      }
    });
  },

  /**
   * Returns the tooltip text of an element of the component
   */
  _tooltipText: function(model, levels, dateonly) {
    var txt = "";
    var header = "";

    if (model.attrs.datedebut > this.datedebut && model.attrs.datefin >= this.datefin) {
      header = i18n.t('common:chemin.sitpartdu', { "0": TYPE.DATE.format(model.attrs.datedebut) });
    }
    if (model.attrs.datedebut <= this.datedebut && model.attrs.datefin < this.datefin) {
      header = i18n.t('common:chemin.sitjusq', { "0": TYPE.DATE.format(model.attrs.datefin) });
    }
    if (model.attrs.datedebut > this.datedebut && model.attrs.datefin < this.datefin && model.attrs.datedebut !== model.attrs.datefin) {
      header = i18n.t('common:chemin.sitduau', { "0": TYPE.DATE.format(model.attrs.datedebut), "1": TYPE.DATE.format(model.attrs.datefin) });
    }
    if (model.attrs.datedebut > this.datedebut && model.attrs.datefin < this.datefin && model.attrs.datedebut === model.attrs.datefin) {
      header = i18n.t('common:chemin.sitdatedu', { "0": TYPE.DATE.format(model.attrs.datefin) });
    }

    if (header) {
      txt = "<span>" + header + "</span><br/>";
    }
    if (!dateonly) {
      txt += "<table style=\"width:500px\">";
      var marginLeft = 0;
      var tabAux = "<span class=\"ui-icon\" style=\"display:inline-block; background:none; padding:0; margin-left:10px\"></span>";
      if (model.attrs.parents && model.attrs.parents.length > 0) {
        // paint chemin when node is not a root node
        for (var j = 0; j < model.attrs.parents.length; j++) {
          var tab = "<span class=\"ui-icon ui-icon-arrowreturn-1-e\" style=\"display:inline-block; padding:0; margin-left:" + marginLeft + "px\"></span>";
          if (j === 0) {
            txt += "<tr><td style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:300px\">" + model.attrs.parents[j].libelle + "(" + model.attrs.parents[j].codef + ")</td><td class=\"phx-chemin-tooltip-text\" style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:200px\">" + tabAux + i18n.t('common:chemin.structure') + "</td></tr>";
          } else {
            var niveau = "";
            if (j - 1 < levels.length) {
              niveau = levels[j - 1].get("libelle");
            }
            txt += "<tr><td style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:300px\">" + tab + model.attrs.parents[j].libelle + "(" + model.attrs.parents[j].codef + ")</td><td class=\"phx-chemin-tooltip-text\" style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:200px\">" + tabAux + niveau + "</td></tr>";
            marginLeft += 10;
          }
        }
        var tab = "<span class=\"ui-icon ui-icon-arrowreturn-1-e\" style=\"display:inline-block; padding:0; margin-left:" + marginLeft + "px\"></span>";
        var niveau = "";
        if (j - 1 < levels.length) {
          niveau = levels[j - 1].get("libelle");
        }
        txt += "<tr><td style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:300px\">" + tab + model.attrs.libelle + "(" + model.attrs.codef + ")</td><td class=\"phx-chemin-tooltip-text\" style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:200px\">" + tabAux + niveau + "</td></tr>";
      } else {
        // paint chemin when node is a root node
        var tabAux = "<span class=\"ui-icon\" style=\"display:inline-block; background:none; padding:0; margin-left:10px\"></span>";
        txt += "<tr><td style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:300px\">" + model.attrs.libelle + "(" + model.attrs.codef + ")</td><td class=\"phx-chemin-tooltip-text\" style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:200px\">" + tabAux + i18n.t('common:chemin.structure') + "</td></tr>";
      }
      txt += "</table>";
    }

    return txt;
  },

  /**
   * Gets the render function for every element of the list.
   */
  _getListRenderer: function(initParent) {
    var self = this;
    var result = function(model) {
      return self._showTooltip(model) + "<span class=\"phx-chemin-option\">" + self._getChemin(model, initParent) + "</span>";
    };
    return result;
  },

  /**
   * Gets the render function depending on the attribute listRenderStyle value
   */
  _getRenderer: function() {
    var result = null,
      self = this;
    switch (this.listRenderStyle) {
      case 1:
        result = function(item) {
          if (!STR.isBlank(self.params) && self.params.ecran === "coll" && !STR.isBlank(GLOBAL_DATA.paramDivers.get("SPEValideu")) && GLOBAL_DATA.paramDivers.get("SPEValideu").get("valeur") === "1") {
            return item.get("libelle") + " (" + item.get("codef") + ")" + (!STR.isBlank(item.get("libelle_structprincipale")) ? "<span>&nbsp;&nbsp;</span>" + item.get("libelle_structprincipale") : '');
          } else {
            return item.get("libelle") + " (" + item.get("codef") + ")";
          }
        };
        break;
      case 2:
        result = function(item) {
          return item.get("codef") + " (" + item.get("libelle") + ")";
        };
        break;
      case 3:
        result = function(item) {
          return item.get("libelle");
        };
        break;
      case 4:
        result = function(item) {
          return item.get("codef");
        };
        break;
      default:
        break;
    }
    return result;
  },

  /**
   * Gets the render function depending on the attribute listRenderStyle value and the element level
   */
  _getAttrsRenderer: function() {
    var result = null;
    var l_resultaux = "";

    switch (this.listRenderStyle) {
      case 1:
        result = function(item) {
          l_resultaux = "";
          if (item.niveau === 0) {
            l_resultaux = i18n.t('common:chemin.structurecomp');
          } else {
            l_resultaux = item.libelle + " (" + item.codef + ")";
          }
          return l_resultaux;
        };
        break;
      case 2:
        result = function(item) {
          l_resultaux = "";
          if (item.niveau === 0) {
            l_resultaux = i18n.t('common:chemin.structurecomp');
          } else {
            l_resultaux = item.codef + " (" + item.libelle + ")";
          }
          return l_resultaux;
        };
        break;
      case 3:
        result = function(item) {
          l_resultaux = "";
          if (item.niveau === 0) {
            l_resultaux = i18n.t('common:chemin.structurecomp');
          } else {
            l_resultaux = item.libelle;
          }
          return l_resultaux;
        };
        break;
      case 4:
        result = function(item) {
          l_resultaux = "";
          if (item.niveau === 0) {
            l_resultaux = i18n.t('common:chemin.structurecomp');
          } else {
            l_resultaux = item.codef;
          }
          return l_resultaux;
        };
        break;
      default:
        break;
    }
    return result;
  },

  /**
   * Gets the render function for an element of the list with the path of the element
   */
  getCheminRenderer: function(item) {
    var result = null;
    switch (this.listRenderStyle) {
      case 1:
        result = function() {
          return item.get("libelle") + " (" + item.get("codef") + ")";
        };
        break;
      case 2:
        result = function() {
          return item.get("codef") + " (" + item.get("libelle") + ")";
        };
        break;
      case 3:
        result = function() {
          return item.get("libelle");
        };
        break;
      case 4:
        result = function() {
          return item.get("codef");
        };
        break;
      default:
        break;
    }
    return result;
  },

  /**
   * Gets the initial level from the list
   */
  _getInitialNiveau: function(collection) {
    var result = 0;
    var minParents = 0;
    var first = true;

    if (!STR.isBlank(collection) && collection.models && collection.models.length > 1) {
      for (var i = 0; i < collection.models.length; i++) {
        if (collection.models[i].get("parents").length < minParents && !first) {
          minParents = collection.models[i].get("parents").length;
        } else if (first === true) {
          first = false;
          minParents = collection.models[i].get("parents").length;
        }
      }

      var end = false;
      for (var j = 1; j < minParents && minParents > 1 && !end; j++) {
        var lastParent = null;
        for (var k = 0; k < collection.models.length && !end; k++) {
          if (STR.isBlank(lastParent)) {
            lastParent = collection.models[k].get("parents")[j];
          } else if (collection.models[k].get("parents")[j].code !== lastParent.code) {
            result = j;
            end = true;
          }
        }

        if (!end) {
          result = j + 1;
        }
      }
    }

    return result;
  },

  /**
   * Sets the structure id of the component
   */
  setStructId: function(structId) {
    this.params.structid = structId;
    this.$el.empty();
    this.render();
  },

  /**
   * Paints the view of the selecteur de chemin component
   */
  render: function() {
    if (!this.plainText) {
      var self = this;

      $(this.el).html(this.template());
      $(this.el).find(".phx-chemin-input").prop("viewRef", this);
      // instantiate/configure component
      if (STR.isBlank(this.view)) {
        $(this.el).find(".phx-chemin-button").remove();
      }
      $(this.el).find(".phx-chemin-input").removeClass("phx-chemin-input").addClass(this.name + " phx-chemin-input");
      $(this.el).find(".chemin-error-container").css("display", "table");
      $(this.el).find(".chemin-error-container").removeClass("chemin-error-container").addClass(this.name + "-error-container");

      if (!this.readonly) {
        this.appended = false;
        this.input = $(this.el).find(".phx-chemin-input").autocomplete({
          minLength: this.minSearchLength,
          source: function(request, response) {
            //Delete current tooltip
            $(self.el).find(".phx-chemin-input").tooltip({ content: "" });
            $(self.el).find(".phx-chemin-input").trigger("mouseout");
            var term = request.term;
            if (self.coll) {
              self.coll.params = "";
              self.coll.pagination.size = self.maxResultItems;
              self.coll.search = "&search=" + term;
              if (!STR.isBlank(self.params.context) && self.params.context.ctxStructureSelpop === true) {
                self.coll.params = {};
                self.coll.params.selectpopu = true;
              }
              self.coll.fetch({
                success: function() {
                  var collection = new AutocompleteColl();

                  var initParent = self._getInitialNiveau(self.coll);
                  self.listRenderer = self._getListRenderer(initParent);
                  collection.itemRenderer = self.listRenderer;

                  // add message to list if more results exists
                  if (self.coll.totalRecords > self.maxResultItems) {

                    var message = "";
                    if (self.modeComplet === true) {
                      message = i18n.t('messages:GL_1007');
                    } else {
                      message = i18n.t('messages:GL_1006');
                    }
                    var info = new ReadOnlyModel({ code: null, libelle: message });
                    collection.add([info.toJSON()], { parse: true });
                  } else {
                    collection.add(self.coll.toJSON(), { parse: true });
                  }
                  //order the collection
                  collection.comparator = function(model) {
                    if (model.get("attrs").libelle === i18n.t('common:chemin.structurecomp')) {
                      return "!";
                    } else {
                      var initParent = self._getInitialNiveau(self.coll);
                      return self._getChemin(model, initParent);
                    }

                  };
                  collection.sort();

                  response(collection.toJSON());
                }
              });
            }
          },
          position: {
            my: "left top",
            at: "left bottom",
            //of : $(this.el) //Without this option the menu is aligned correctly
            collision: "flipfit"
          },
          open: function() {
            var menu = $(this).data("ui-autocomplete").menu;
            menu.activeMenu.addClass("phx-chemin-autocomplete-menu");
            $('.ui-autocomplete').css('width', 'auto');
            //var menu = $(this).data("ui-autocomplete").menu;
            menu.activeMenu.addClass("phx-selecteur-chemin-autocomplete-menu");
            self._registerOneWheelEvent();
          },
          close: function() {
            $(document).off("wheel." + self.cid);
            $(document).off("mousedown." + self.cid);
            this.searching = false;

            $(this).data('ui-autocomplete').term = null;
            if (self.cache["current"] && self.autocompleteMode === true) {
              self._setItem(self.cache["current"].attrs);
            }

            if (self.multiselection === true && self._setInputValue !== undefined) {
              self._setInputValue("");
            }
            return false;
          },
          create: function() {
            var menu = $(this).data("ui-autocomplete").menu;
            $(menu.activeMenu).css("position", "fixed");
            $(this).data('ui-autocomplete')._resizeMenu = function() {
              if (self.appended === false) {
                //AppendTo option in order to let jquery recalculate z-index for each element
                $(self.input).autocomplete("option", "appendTo", null);
                self.appended = true;
              }

              //
              var tab_contaner_height = 0;

              $(".ui-tabs-nav").each(function() {
                if (tab_contaner_height < $(this)[0].offsetHeight) {
                  tab_contaner_height = $(this)[0].offsetHeight;
                }
              });

              //Get the limit container
              var limit_container = $("#phx-container");

              if (this.element[0] && $(this.element[0]) && limit_container && $(this.element[0]).offset()) {
                var scrollTop = $(window).scrollTop(),
                  scrollBot = scrollTop + $(window).height(),
                  elTop = limit_container.offset().top,
                  elBottom = elTop + limit_container.outerHeight(),
                  visibleTop = elTop < scrollTop ? scrollTop : elTop,
                  visibleBottom = elBottom > scrollBot ? scrollBot : elBottom;

                this.menu.element[0].style.maxHeight = ""; //set default heiht

                var distance_from_input_to_top_container = $(this.element[0]).offset().top - (limit_container.offset().top + tab_contaner_height);
                var distance_from_input_to_buttom = (visibleBottom - visibleTop - tab_contaner_height) - distance_from_input_to_top_container;
                var height_of_select_despl = this.menu.element[0].offsetHeight;
                var height_of_the_input = this.element[0].offsetHeight;

                distance_from_input_to_buttom -= height_of_the_input;

                if (distance_from_input_to_buttom < height_of_select_despl) {
                  if (distance_from_input_to_buttom > distance_from_input_to_top_container) {
                    var maxHeight = distance_from_input_to_buttom - 15;
                    if (maxHeight < 20) {
                      maxHeight = 20;
                    }
                    this.menu.element[0].style.maxHeight = maxHeight + "px";
                  } else {
                    this.menu.element[0].style.maxHeight = (distance_from_input_to_top_container - 15) + "px";
                  }
                }
              }
            };
          }
        });
        // color search term in result list
        $(this.input).data("ui-autocomplete")._renderItem = function(ul, item) {
          var keywords = $.trim(this.term);
          var output = item.label;
          var output2 = "";
          try {
            if (item.label[1] && item.label[1].textContent) {
              output = item.label[1].textContent.replace(new RegExp("(" + keywords + ")", "gi"),
                '<span class="phx-searched-char-sequence">$1</span>');
              output2 = item.label[0];
            } else {
              output = item.label.replace(new RegExp("(" + keywords + ")", "gi"),
                '<span class="phx-searched-char-sequence">$1</span>');
            }
          } catch (err) {
            //nothing
          }

          if (STR.isBlank(item.attrs.id)) {
            return $("<li>").addClass("ui-state-disabled").html('<span class="phx-label-style phx-chemin-moreitems-info">' + item.label + '</span>').appendTo(ul);
          }
          return $("<li>").append($("<a>").html(output2).append(output)).appendTo(ul);
        };
        if (!STR.isBlank(this.keepOldId)) {
          this.input.attr("id", this.keepOldId);
        }

      } else {
        this.$el.find(".phx-chemin-input").attr("readonly", true);
        var fieldset = this.$el.find(".phx-chemin-wrap");
        FORMS.setInputFieldReadonly(fieldset, this.name, true);
      }

      //apply width to the component wrapper
      this.$el.find(".phx-chemin-wrap").css("width", this.fieldWidth);

      //apply width class to the input and remove button if not complete mode
      if (this.modeComplet) {
        if ((this.fieldWidth + "").indexOf("%") === -1) {
          this.$el.find(".phx-chemin-input").css("width", this.fieldWidth - 22 + "px");
        } else {
          this.$el.find(".phx-chemin-input").css("width", "calc(" + this.fieldWidth + " - 22px)");
        }
      } else {
        $(this.el).find(".phx-chemin-button").remove();
        this.$el.find(".phx-chemin-input").css("width", "calc(100% - 4px)");
      }

      $(this.el).find(".phx-chemin-input").tooltip({ content: "" });

      if (!this.multiselection) {
        this.$el.find(".phx-chemin-selection");
      } else {
        this.$el.find(".phx-chemin-selection").css("display", "inherit");
      }
    }

    return this;
  },

  /**
   * Close autocomplete dropdown when scrolling outside.
   */
  _registerOneWheelEvent: function() {
    var self = this;

    $(document).one("wheel." + this.cid, function(event) {
      self.shouldCloseMenu = false;

      // wheel on the list
      try {
        if ($(event.target).attr("id") === $(self.input).autocomplete("widget").attr("id") || $(self.input).autocomplete("widget").find(event.target).length > 0) {
          self._registerOneWheelEvent();
          return;
        } else if ($(self.input).autocomplete("widget").find(event.target).length === 0) {
          if ($(self.input).autocomplete("widget").is(":visible")) {
            $(self.input).autocomplete("close");
            if (STR.isBlank(self.currentCode)) {
              self._paintItems();
            }
          }
          self.shouldCloseMenu = false;
        }
      } catch (e) {
        LOG.error("Autocomplete__registerOneWheelEvent: " + e);
      }
    });
  },

  /**
   * Clears the current selection of the component
   */
  clearSelection: function() {
    this.selection.reset();
    var $input = this.$el.find(".phx-chemin-input");
    if ($input.data('ui-tooltip')) {
      $input.tooltip('destroy');
    }

    this._paintItems();
  },

  // item is a backbone model representing a chemin structure
  /**
   * Adds a value to the component
   */
  addValue: function(item, callback) {
    var self = this;
    var itemid = "";
    var response = "";

    if (!STR.isBlank(item.get("elementCode"))) {
      itemid = item.get("structid") + "," + item.get("date") + "," + item.get("elementCode");
    } else if (!STR.isBlank(item.get("chemin"))) {
      itemid = item.get("structid") + "," + item.get("date") + "," + item.get("chemin");
    }
    if (this._useCache() && !STR.isBlank(this.cache["item"][this.idCache]) && !STR.isBlank(this.cache["item"][this.idCache][itemid])) {
      //cache is using and 2nd time
      if (this.cache["item"][this.idCache][itemid].fetching) {
        this.listenToOnce(this.cacheEventsModel, "item" + this.idCache + itemid, function() {
          //Paint items when event is triggered
          response = self.cache["item"][self.idCache][itemid].data;
          self.selection.add(response.toJSON(), { parse: true });
          self._paintItems();
          if (callback) {
            callback(response);
          }
        });
      } else {
        //Paint items when data is already loaded
        response = this.cache["item"][this.idCache][itemid].data;
        self.selection.add(response.toJSON(), { parse: true });

        self._paintItems();
        if (callback) {
          callback(response);
        }
      }
    } else {
      if (this._useCache()) {
        //First time using cache initialize object
        this.cache["item"][self.idCache][itemid] = { fetching: true };
      }
      item.fetch({
        success: function(resp) {
          if (self._useCache()) {
            self.cache["item"][self.idCache][itemid].data = resp;
            self.cache["item"][self.idCache][itemid].fetching = false;
            self.cacheEventsModel.trigger("item" + self.idCache + itemid);
          }

          self.selection.add(resp.toJSON(), { parse: true });

          self._paintItems();

          if (callback) {
            callback(resp);
          }
        }
      }, this);
    }
  },

  /**
   * Gets the selected values
   */
  getValues: function() {
    return this.selection;
  },

  /**
   * Paints every element of the selection
   */
  _paintItems: function() {
    var self = this;

    if (this.multiselection) {
      $(this.el).find(".phx-chemin-selection").empty();
      var tmpItemViews = [],
        selectionModels = this.selection.models;

      _.each(this.selection.models, function(value) {
        var itemView = new SelecteurCheminResultItemView({ "model": value, "readonly": self.readonly });
        self.once("loadedMultipleTooltip", function(data) {
          tmpItemViews.push({
            "itemView": data.item,
            "tooltip": data.tooltip
          });

          if (tmpItemViews.length == selectionModels) {
            self.trigger("generatedTooltip:selectourChemin", {
              multiple: true,
              "tooltipsArray": tmpItemViews
            });
          }
        });

        $(self.el).find(".phx-chemin-selection").append(itemView.render().el);

        if (value.tooltip) {
          $(itemView.el).tooltip({ content: value.tooltip });

          self.trigger("loadedMultipleTooltip", {
            "item": itemView,
            "tooltip": value.tooltip
          });

        } else {
          // add tooltip
          self._getLevels(function(levels) {
            value.tooltip = self._tooltipText(value.attributes, levels);
            $(itemView.el).tooltip({ content: value.tooltip });

            self.trigger("loadedMultipleTooltip", {
              "item": itemView,
              "tooltip": value.tooltip
            });

          });
        }
      });

      if (this.selection.models.length === 0) {
        this.$el.find(".phx-chemin-selection").css("display", "inherit");
      } else {
        this.$el.find(".phx-chemin-selection").css("display", "table");
      }
      if (this.$el.find(".phx-chemin-selection").length > 0) {

        if (_.isUndefined(self.params.paintItemsPosition)) {
          this.$el.find(".phx-chemin-selection").position({
            my: "left top",
            at: "left bottom",
            collision: "flip flip",
            of: self.$el.find(".phx-chemin-wrap")
          });

        } else {

          var filterLines = $('.phx-filtres-detail .phx-definitionfiltres-simple-line').toArray();
          var selectionLine;
          filterLines.forEach(function(element) {
            selectionLine = "";
            selectionLine = $(element).find('.phx-chemin-selection');
            if (selectionLine.length > 0) {
              $(element).find('.phx-definitionfiltres-simple-btnRemove').position({
                of: $(element).find('.phx-chemin-wrap'),
                my: "left top",
                at: "right top",
                collision: "flip flip"
              });
              var customPosition;
              if (self.params.paintItemsPosition.customCheminPosition) {
                customPosition = self.params.paintItemsPosition.customCheminPosition;
              } else {
                customPosition = self.params.paintItemsPosition;
              }
              $(selectionLine).find(customPosition.targetParent).position({
                of: $(element).find(customPosition.targetElement),
                my: customPosition.my,
                at: customPosition.to,
                collision: customPosition.collision
              });
            }
          });

        }

      }
    } else {

      if (this.selection.length > 0) {
        // render item in input field (no multiselection)
        if (self.plainText) {
          this._getLevels(function(levels) {
            var txt = self._tooltipText(self.selection.at(0).attributes, levels);
            self.$el.attr("title", "");
            self.$el.tooltip({ content: txt });
            self.$el.text(self.selection.at(0).get("label"));
            self.trigger("generatedTooltip:selectourChemin", {
              multiple: false,
              "tooltip": txt
            });

          });
        } else {
          this.$el.find(".phx-chemin-input").val(this.selection.at(0).get("label"));
          FORMS.autosizeInput(this.$el.find(".phx-chemin-input"));
          this.$el.find(".phx-chemin-input").width(this.$el.find(".phx-chemin-input").width() + 4);
          if (this.readonly) {
            // add tooltip
            this._getLevels(function(levels) {
              var txt = self._tooltipText(self.selection.at(0).attributes, levels);
              self.$el.find("input." + UTILS.escapeJQueryString(self.name)).tooltip({ content: txt });
              self.trigger("generatedTooltip:selectourChemin", {
                multiple: false,
                "tooltip": txt
              });
            });
          } else {
            // add tooltip
            this._getLevels(function(levels) {
              var txt = self._tooltipText(self.selection.at(0).attributes, levels);
              //Initialize tooltip to prevent errors when tooltip is not initialized
              self.$el.find(".phx-chemin-input").tooltip({ content: txt });
              self.$el.find(".phx-chemin-input").tooltip("option", "content", txt);
              self.trigger("generatedTooltip:selectourChemin", {
                multiple: false,
                "tooltip": txt
              });
            });

          }
        }
      }
    }
  },

  /**
   * Selects a new element in the component
   */
  _selectItem: function(event, ui) {
    this._addItem(ui.item.attrs);

    return false;
  },

  /**
   * Changes a item in the current selection
   */
  _changeItem: function(event) {
    var value = $(event.target).val();
    if (this.multiselection === false && STR.isBlank(value) && this.addItemCallback) {
      var model = new this.coll.model;
      this.addItemCallback(model.attributes);
      this.selection.reset();
    }
    var $input = $(this.el).find(".phx-chemin-input");
    if ($input.data("ui-tooltip") && this.selection.length === 0) {
      $input.tooltip("option", "content", "");
    }
  },

  /**
   * Adds a new element to the selection
   */
  _addItem: function(attrs) {
    //Case Traversal component population
    if (this.params && this.params.context && this.params.context.ctxStructureSelpop) {
      if (attrs.selectionnable == false) {
        return false;
      }
    }
    if (this.multiselection === true) {
      if (attrs.niveau === 0 || (this.selection.length === 1 && this.selection.models[0].get("attrs").niveau === 0)) {
        this.selection.reset();
      }

      // clear input field and set focus to it.
      $(this.el).find(".phx-chemin-input").val("");
      $(this.el).find(".phx-chemin-input").focus();
    } else {
      this.selection.reset();
    }

    // add item to selection list
    if (!this.selection.get(attrs.id)) {
      if (attrs.niveau === 0) {
        var rootAttrs = _.clone(attrs);
        rootAttrs.libelle = i18n.t('common:chemin.structurecomp');
        this.selection.add([rootAttrs], { parse: true });
        if (this.addItemCallback) {
          this.addItemCallback(rootAttrs);
        }
      } else {
        this.selection.add([attrs], { parse: true });
        if (this.addItemCallback) {
          this.addItemCallback(attrs);
        }
      }

      // paint items
      this._paintItems();
      // Notify edition
      $(this.el).find(".phx-chemin-input").trigger("change");
    }

    // retur false to prevent bubbling of event
    return false;
  },

  /**
   * Deletes an element from the list of selections of the component
   */
  _removeItem: function(model) {
    // remove item from collection
    this.selection.remove(model);

    if (this.removeItemCallback) {
      this.removeItemCallback(model.attributes.attrs);
    }

    // repaint
    this._paintItems();
    // Notify edition
    $(this.el).find(".phx-chemin-input").trigger("change");
  },

  /**
   * Opens the detail popup view
   */
  _openDetailPopup: function() {
    var self = this;
    if (this.popup !== undefined) {
      this.popup.remove();
      this.popup = null;
    }
    this.popup = new DialogView({
      view: this.view,
      viewData: { parameters: this.params, listRenderStyle: this.listRenderStyle, currentSelection: this.selection }
    });
    this.popup.setHeight(this.height);
    this.popup.setWidth(this.width);
    this.popup._eventsBB = function(event, value) {
      if (event === "response") {
        self.popupResponse = value;
      }
    };

    // set buttons
    var btn = [{
      text: i18n.t('common:apply'),
      click: function() {
        var populationSelector = false,
          checkedDescendents;
        if (self._isSelectorPopulationContext()) {
          populationSelector = true;
          checkedDescendents = $(".phx-selecteur-chemin-dialog input.populationDescendence").is(":checked");
        }

        $(this).dialog("close");
        if (populationSelector == true) {
          self._popupResponseCallback("y", checkedDescendents);
        } else {
          self._popupResponseCallback("y");
        }
      }
    }, {
      text: i18n.t('common:cancel'),
      click: function() {
        $(this).dialog("close");
        self._popupResponseCallback("n");
      }
    }];
    try {
      this.popup.open();
    } catch (err) {
      LOG.debug("chemin: " + err);
    }
    this.popup.dialog.dialog("option", "buttons", btn);
    this.popup._setTitle(this.title);
  },

  /**
   * Callback function to be executed after close the detail popup view
   */
  _isSelectorPopulationContext: function() {
    var populationSelector = false;
    if (this.params && this.params.context && this.params.context.ctxStructureSelpop == true) {
      populationSelector = true;
    }
    return populationSelector;
  },
  _popupResponseCallback: function(action, populationDescendence) {
    var self = this;
    if (action === "y" && !STR.isBlank(this.popupResponse)) {
      var list = this.popupResponse;
      if (this.popupResponse instanceof Backbone.Collection) {
        list = this.popupResponse.models;
      } else {
        if (!_.isArray(list)) {
          list = list ? [list] : [];
        }
      }
      this.clearSelection();
      _.each(list, function(item) {
        this._addItem(item.get("attrs"));
      }, this);

      if (self._isSelectorPopulationContext()) {
        self._setPopulationDescendence(populationDescendence);
      }
    }
    $(this.el).find(".phx-chemin-input").focus();
  },
  _setPopulationDescendence: function(selected) {
    $(".phx-populations-detail-structure-simple input.populationDescendence").prop("checked", selected);
  },
  // resize result list on open
  /**
   * Opens a new list of autocompletion results
   */
  _openlist: function() {
    var parentWidth = $(this.el).css("width");
    $(".phx-chemin-input", this.el).autocomplete("widget").css("width", parentWidth);
  },

  /**
   * Cleans the current selection
   */
  clean: function() {
    this.$el.find(".phx-chemin-input").val("");
    this.selection.reset();
  },

  /**
   * Method to be executed when an element of the list is focused
   */
  _focused: function(event, ui) {
    // reset tooltip on focus. (maybe a bug in jquery is removing the tooltip when field receive focus)
    // adding again the attribute "title" is enougth to have the tooltip work again.
    if (ui && ui.tooltip && STR.isBlank($(".phx-chemin-input", this.el).attr("title"))) {
      $(".phx-chemin-input", this.el).attr("title", "");
    }

    if (event.type === "tooltipopen" && event.originalEvent && event.originalEvent.type === "focusin") {
      $(".phx-chemin-input", this.el).tooltip("close");
    }
  }

});
