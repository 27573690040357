import { CombosModel } from './combos.model.js';

import { PaginatedCollection } from '../../models/paginated.collection.js';
import { STR } from '../../../utils/str.js';

/**
 * Collection for combos
 */
export var CombosColl = PaginatedCollection.extend({
  model: CombosModel,

  initialize: function(models, options) {
    PaginatedCollection.prototype.initialize.call(this);
    this.paginated = false;
    if (_.isObject(models)) {
      options = models;
    }
    if (options.model) {
      this.model = options.model;
    }
    if (options.params) {
      this.params = options.params;
    }

    if (options.urlMine) {
      this.urlMine = options.urlMine;
    } else {
      if (options.comboId) {
        this.comboId = options.comboId;
      }
      if (options.name) {
        this.name = options.name;
      } else {
        this.name = "ls";
      }
    }
  },

  url: function() {
    var url;
    if (!STR.isBlank(this.urlMine)) {
      url = this.urlMine;
    } else {
      if (STR.isBlank(this.name)) {
        url = Configuration.restRoot + "/rest/" + this.comboId;
      } else {
        if (STR.isBlank(this.comboId)) {
          url = Configuration.restRoot + "/rest/" + this.name;
        } else {
          url = Configuration.restRoot + "/rest/" + this.name + "/" + this.comboId;
        }
      }
    }
    return url;
  }
});