module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<li class="ui-menu-item">\n  ';
 for(var i = 0; i < level; i++){ 
__p+='\n    <span class="phx-profilutilisateur-treeLeft">&nbsp;</span>\n    ';
 } 
__p+='\n      ';
 if(enfants === true){ 
__p+='\n        <span style="position:relative;width:15px">\n		<span class="phx-expandable-icon"></span>\n        </span>\n        ';
 } 
__p+='\n\n          ';
 if(enfants !== true && level > 0){ 
__p+='\n            <span style="position:relative;width:15px">\n		<span class="phx-treenode-leaf-icon ui-icon ui-icon-document-b"></span>\n            </span>\n            ';
 } 
__p+='\n\n\n              <a href="javascript:void(0)" tabindex="-1" class="menuProfileItem" data-value="'+
((__t=(code))==null?'':__t)+
'" data-type="'+
((__t=(type))==null?'':__t)+
'" data-parentId="'+
((__t=(parentId))==null?'':__t)+
'" data-userId="'+
((__t=(userId))==null?'':__t)+
'">\n                '+
((__t=(libelle))==null?'':__t)+
'\n              </a>\n              ';
 if(principal === true){ 
__p+='\n                <span style="position:relative;width:15px;margin-left: -5px;top:1px">\n		';
 if(selection === true){ 
__p+='\n			<span class="ui-phx-icon-metier ui-phx-profil-principal-selectable"></span>\n                ';
 }else{ 
__p+='\n                  <span class="ui-phx-icon-metier ui-phx-profil-principal-non-selectable"></span>\n                  ';
 } 
__p+='\n                    </span>\n                    ';
 } 
__p+='\n                      ';
 if(enfants === true){ 
__p+='\n                        <span class="phx-profle-enfants" style="display:none"></span>\n                        ';
 } 
__p+='\n</li>';
}
return __p;
};
