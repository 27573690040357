import { STR } from '../../../utils/str.js';

/**
 * Intern Object that represents a table vue.
 */
// export var InternVueModel = function(vueName, columns, nongroupee, menuName, buttonText, beforeFetchCallback) {
export var InternVueModel = function(vueName, options) {

  var self = this;
  if (!STR.isBlank(options.columns)) {
    this.columns = options.columns;
  }
  if (!STR.isBlank(options.nongroupee)) {
    this.nongroupee = options.nongroupee; // For treeviews, nongroupee indicates that table shouldn't be grouped
  }
  if (!STR.isBlank(options.menuName)) {
    this.menuName = options.menuName;
  }
  if (!STR.isBlank(options.buttonText)) {
    this.buttonText = options.buttonText;
  }
  if (!STR.isBlank(options.beforeFetchCallback)) {
    this.beforeFetchCallback = options.beforeFetchCallback;
  }
  if (!STR.isBlank(vueName)) {
    this.vueName = vueName;
  }
  if (!STR.isBlank(options.code)) {
    this.code = options.code;
  }
  if (!_.isUndefined(options.columns) && options.columns.minWidthMode === true) {
    processMinWidth();
  }

  this._columns = {}; // Used to store columns
  this.cellRenderers = {}; //Allow the definition of custom cell renderer per column
  this._visibleColumns = []; // Used to store default visible columns
  this._lockedColumns = []; // Used to store default locked columns
  this._columnsAlign = []; // Used to store the columns alignment
  this._sortableCustomNames = {};

  function processMinWidth() {
    _.each(self.columns, function(column, index) {
      if (!_.isUndefined(column.minWidth, index)) {
        self.column[index].width = '100%';
      }
    });
  }
};