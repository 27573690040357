import { i18n } from '../../../../i18n.js';
import { STR } from '../../../../utils/str.js';

export var DelegueRenderView = Backbone.View.extend({

  initialize: function(options) {
    this.model = null;
    if (options && options.model) {
      this.model = options.model;
    }
    this.workflow = null;
    if (options && options.workflow) {
      this.workflow = options.workflow;
    }
  },

  render: function() {
    var text = "";
    if (this.model.get("delegue")) {
      if (!STR.isBlank(this.model.get("delegue").matricule)) { // if have a collaborateur
        if (this.workflow.paramIdCollab === "matric" || STR.isBlank(this.workflow.paramIdCollab)) {
          text = this.model.get("delegue").nom + " " + this.model.get("delegue").prenom + " (" + this.model.get("delegue").matricule + ")";
          this.$el.html(text).attr("title", text);
        } else if (this.workflow.paramIdCollab === "matric_aux") {
          text = this.model.get("delegue").nom + " " + this.model.get("delegue").prenom + " (" + this.model.get("delegue").matriculeaux + ")";
          this.$el.html(text).attr("title", text);
        }
      } else { // if have a utilisateur
        text = i18n.t('droitsdel.utilisateur') + " <span style='font-weight: bold'>" + this.model.get("delegue").utilnom + "</span>";
        this.$el.html(text).attr("title", text);
      }
    }

    return this;
  }

});