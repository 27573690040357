import { CustomBarView } from './customBar.view.js';
import { FooterView } from './footer.view.js';
import { FormView } from './form.view.js';
import { FormView as CommonFormView } from 'core/views/form.view.js';
import { i18n } from '../../../../i18n.js';
import { SimplePanelView } from 'core/layouts/simplePanel.view.js';
import { CalendaccWorkflow } from '../models/calendacc.workflow.js';

export var GeneralView = CommonFormView.extend({

  className: 'calendacc',

  initialize: function(options) {
    if (options && options.parent) {
      this.parent = options.parent;
    }
    // Initializes the main parts of the UI and its workflow
    this.workflow = new CalendaccWorkflow();

    this.layout = new SimplePanelView();
    this.form = this._initForm(); //table view
    this.workflow.formModel = this.form.model;
    this.workflow.form = this.form;
    this.customBar = new CustomBarView({ workflow: this.workflow });
    this.workflow.customBarModel = this.customBar.model;
    this.footer = new FooterView({ workflow: this.workflow });
  },

  /**
   * Init screen
   */
  list: function() {
    var firstTime = (this.workflow.get("ready") == false);
    var self = this;
    this._initLayoutUC(function() {});
  },

  _initLayoutUC: function(callback) {
    if (this.workflow.get("ready") == true) {
      if (callback) {
        callback();
      }
    } else {
      var self = this;
      this.workflow.setUp(function() {
        //When the workflow is ready, we can render the view without concerns
        self.$el.html(self.customBar.render().el);
        self.$el.append(self.form.render().el);
        self.$el.append(self.footer.render().el);

        if (callback) {
          callback();
        }
        // Function call to recalculate the size of the panels once the
        // burden of everything you need to show the use case
        self.trigger("uc:ready");
      });
    }
  },

  _initForm: function() {
    var View = new FormView({ workflow: this.workflow });
    return View;
  },

  render: function() {
    this.$el.html(this.layout.render().el);
    // allow auto height of dialog
    this.parent._setTitle(i18n.t('calendacc.usecase'));
    this.parent.$el.dialog("option", "height", 750);
    this.parent.$el.dialog("option", "width", 850);
    this._initLayoutUC();
    return this;
  }
});
