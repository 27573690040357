module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="phx_chemin_dlgentete">\n    <label class="phx_chemin_strlbl" for="phx-chemin-strinp"></label>\n    <input class="phx-chemin-strinp" type="text"></input>\n    <button type="button" class="phx_chemin_rechbtn" value="save">'+
((__t=(i18n.t("common:chemin.dlgrechercher")))==null?'':__t)+
'</button>\n</div>\n\n<fieldset class="phx_chemin_dlgresultheader">\n    <label class="phx_chemin_restitle"></label>\n    <span class="phx_chemin_menuicon phx-menu-icon ui-corner-all ui-icon ui-icon-circle-plus"></span>\n</fieldset>\n\n<fieldset class="phx_chemin_dlgresult">\n    <div class="phx_chemin_dlgresult_div">\n        <ul class="phx_chemin_menucontent">\n            <li class="phx_chemin_opt1 phx-menu-link phx-hover ui-corner-all"><span class="phx_chemin_opt1">'+
((__t=(i18n.t("common:chemin.optlibcod")))==null?'':__t)+
'</span></li>\n            <li class="phx_chemin_opt2 phx-menu-link phx-hover ui-corner-all"><span class="phx_chemin_opt2">'+
((__t=(i18n.t("common:chemin.optcodlib")))==null?'':__t)+
'</span></li>\n            <li class="phx_chemin_opt3 phx-menu-link phx-hover ui-corner-all"><span class="phx_chemin_opt3">'+
((__t=(i18n.t("common:chemin.optlib")))==null?'':__t)+
'</span></li>\n            <li class="phx_chemin_opt4 phx-menu-link phx-hover ui-corner-all"><span class="phx_chemin_opt4">'+
((__t=(i18n.t("common:chemin.optcod")))==null?'':__t)+
'</span></li>\n        </ul>\n        <div class="phx_chemin_treediv">\n            <span class="phx_chemin_tree"></span>\n        </div>\n    </div>\n</fieldset>\n\n<div class="phx_chemin_dlgentete">\n    <label class="phx_chemin_sellbl">'+
((__t=(i18n.t("common:chemin.dlgselection")))==null?'':__t)+
'</label>\n    <span class="phx-chemin-selval"></span>\n    <div class="phx-chemindlg-selection"></div>\n</div>\n';
 if(context && context.ctxStructureSelpop){
__p+='\n    <div class="phx-chemin-population-descendence" style="'+
((__t=((context.ctxSelRemDesc == 0 || context.ctxSelRemDesc == 1 )?" display:none; ":" "))==null?'':__t)+
'">\n        <label for="populationDescendence" style="width: 100%;margin-bottom:15px;">\n      <input class="populationDescendence" type="checkbox" '+
((__t=((context.ctxSelRemDesc == 0 || context.ctxSelRemDesc == 2 )?"checked='checked'":""))==null?'':__t)+
'  readonly="'+
((__t=((context.ctxSelRemDesc == 2 || context.ctxSelRemDesc == 3 )?"":"readonly"))==null?'':__t)+
'" >\n       <span>  '+
((__t=( i18n.t("populationsmenu.returnerEgalement")))==null?'':__t)+
'  </span>\n  </label>\n    </div>\n    ';
}
__p+='\n';
}
return __p;
};
