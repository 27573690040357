import { BaseModel } from '../../../../../../core/models/base.model.js';

/**
 * Model for the complement combos
 */
export var SaisieOptionsModel = BaseModel.extend({

  defaults: {
    "id": null,
    "code": "",
    "libelle": ""
  }
});