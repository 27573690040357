import { BaseModel } from '../../../../../../core/models/base.model.js';

/**
 * Pieces autorisees
 */
export var PiecesAutorisees = BaseModel.extend({

  //	habilitationV : "",
  //	habilitationG : "",
  //	usecase : "gererpiecesjointes",

  urlRoot: Configuration.restRoot + "/rest/fichier/maxi",

  defaults: {
    "maxi": null
  }
});