import { DialogView } from '../../../../../../core/views/dialog.view.js';
import { EvtBadgeageSoumisWkf } from './evtBadgeageSoumisWkf.js';
import { GererOneBadgeagePopUpView } from '../views/gererOneBadgeagePopUp.view.js';
import { GLOBAL_DATA } from '../../../../../../globalData.js';
import { HabilitationContext } from '../../../../../../core/models/habilitationContext.js';
import { i18n } from '../../../../../../i18n.js';
import { LOG } from '../../../../../../utils/log.js';
import { MSGS } from '../../../../../../utils/msgs.js';
import { ReadOnlyModel } from '../../../../../../core/models/readOnly.model.js';
import { STR } from '../../../../../../utils/str.js';

export var GereronebadgeageWorkflow = ReadOnlyModel.extend({

  gereronebadgeage: null,

  /**
   * Constructor
   * Workflow model for component Gerer One Badgeage
   */
  initialize: function() {
    this.btnBarModel = null;

    /**
     * Model of the habilitation context
     */
    this.habContext = new HabilitationContext();
  },

  /**
   * Set up the workflow. Link the models between them
   */
  setUp: function(callback) {
    var self = this;
    this.pDiversIdCollabModel = GLOBAL_DATA.paramDivers.get("idCollab");
    this.gereronebadgeage.divers = this.pDiversIdCollabModel.get("valeur");

    var evtBadgeageSoumisWkf = new EvtBadgeageSoumisWkf();
    this.wkf_activ = false;
    evtBadgeageSoumisWkf.setHabContext(new HabilitationContext({
      onglet: "gererbadgeages",
      fonc_cour: "PAR_WKFASSC.V",
      nat_gest: ""
    }));
    if (this.gereronebadgeage && !STR.isBlank(this.gereronebadgeage.context)) {
      var l_context = this.gereronebadgeage.context;

      if (!STR.isBlank(l_context.ctxEcran) && !STR.isBlank(l_context.ctxHabilitation.HabilitationAcces)) {
        var l_habContext = new HabilitationContext({
          onglet: l_context.ctxEcran,
          fonc_cour: l_context.ctxHabilitation.HabilitationAcces
        });
        evtBadgeageSoumisWkf.setHabContext(l_habContext);
      }
    }
    if (callback) {
      evtBadgeageSoumisWkf.fetch({
        success: function(fresh) {
          self.wkf_activ = fresh.get("0").active;
          if (callback) {
            self._manageCommentaire();
            callback();
          }
        }
      });

    }

    this.btnBarModel.on("btn:click", this._buttonAction, this);
    this.gereronebadgeage.model.on("form:edited", this._formEdited, this);
  },

  _manageCommentaire: function() {
    if (this.wkf_activ) {
      $(".blockBadgeCommentaire").show();
    } else {
      $(".blockBadgeCommentaire").hide();
    }
  },

  _confirmDelete: function() {
    var self = this;
    var objModel = this.gereronebadgeage.model.get("value");
    var statut = objModel.get("statut").code;
    if (statut === "A" || statut === "H" ||
      (statut === "I" && this.gereronebadgeage.context.ctxUtilisateur.toUpperCase() == "COLLABORATEUR")) {
      var dialog = this._initPopUp("suppression");
      var dialogModel = dialog.model;
      dialog.open(function() {
        if (dialogModel.get("action") == "OK") {
          self.gereronebadgeage.deleteOneBadgeages(dialogModel.get("commentaire"));
        }
      });
      dialog._setTitle(i18n.t('common:gereronebadgeage.title_popUp'));
    } else {
      MSGS.showConfirm(i18n.t('common:gereronebadgeage.delconfirm'), function(result) {
        if (result == "Y") {
          self.gereronebadgeage.deleteOneBadgeages("");
        }
      });
    }
  },

  /**
   * Manages actions (save,revert,new,delete,accepter,refuser) when buttons are pressed
   */
  _buttonAction: function(buttonId, profil) {
    LOG.debug("Button clicked in gerer badgeages : " + buttonId);
    var self = this;
    switch (buttonId) {
      case "save":
        var objModel = this.gereronebadgeage.model.get("value");
        var context = this.gereronebadgeage.context;
        var error = false;

        if (!STR.isBlank(context.ctxGestionPeriode)) {
          if (context.ctxGestionPeriode.datedebut < objModel.get("saisie").date &&
            context.ctxGestionPeriode.datefin > objModel.get("saisie").date) {

            if (context.ctxGestionPeriode.heuredebut < objModel.get("saisie").heure &&
              context.ctxGestionPeriode.heurefin > objModel.get("saisie").heure) {
              error = false;
            } else {
              error = true;
            }
          } else {
            error = true;
          }
        }

        if (error) {
          var txtError = i18n.t('messages:GT_1142', { "1": context.ctxGestionPeriode.datedebut, "2": context.ctxGestionPeriode.heuredebut, "3": context.ctxGestionPeriode.datefin, "4": context.ctxGestionPeriode.heurefin });
          MSGS.showError(txtError);
        } else {
          //New badgeage
          if (objModel.isNew()) {
            var action = "demande";
            self.gereronebadgeage.saveOneBadgeages(action, function(fresh) {
              self.gereronebadgeage.model.trigger("badgeageChanged", fresh, "create");
            });
          } else { //Modified regularisation. First we check if is modifiable
            if (objModel.get("saisie") && (objModel.get("saisie").sens == "X" || objModel.get("saisie").sens == "x")) {
              this._confirmDelete();
            } else {
              this.gereronebadgeage.modifiableOneBadgeages(function(fresh) {
                if (fresh.get("result") == "ok") {
                  var action = "demande/" + encodeURIComponent(self.gereronebadgeage.model.get("value").id);
                  self.gereronebadgeage.saveOneBadgeages(action, function(fresh) {
                    self.gereronebadgeage.model.trigger("badgeageChanged", fresh, "update");
                  });
                }
              });
            }
          }
        }
        break;
      case "revert":
        this.btnBarModel.set("mode", "R");
        this.gereronebadgeage.revertView();
        this.btnBarModel.trigger("enabled:accepter");
        this.btnBarModel.trigger("enabled:refuser");
        break;
      case "new":
        if (this.btnBarModel.get("mode") !== "E") {
          this.gereronebadgeage.newOneBadgeages();

          this.btnBarModel.set("mode", "E");
          this.btnBarModel.trigger("show:save");
          this.btnBarModel.trigger("disable:accepter");
          this.btnBarModel.trigger("disable:refuser");
        }
        break;
      case "delete":
        this._confirmDelete();
        break;
      case "accepter":
        var dialog = this._initPopUp("accepter");
        var dialogModel = dialog.model;
        var validationProfil = profil;
        dialog.open(function() {
          if (dialogModel.get("action") == "OK") {
            self.gereronebadgeage.accepterOneBadgeages(dialogModel.get("commentaire"), validationProfil);
          }
        });

        dialog._setTitle(i18n.t('common:gereronebadgeage.title_popUp'));
        break;
      case "refuser":
        var dialog = this._initPopUp("refuser");
        var dialogModel = dialog.model;
        var validationProfil = profil;
        dialog.open(function() {
          if (dialogModel.get("action") == "OK") {
            self.gereronebadgeage.refuserOneBadgeages(dialogModel.get("commentaire"), validationProfil);
          }
        });

        dialog._setTitle(i18n.t('common:gereronebadgeage.title_popUp'));
        break;
    }
  },

  /**
   * Manages buttonBar when form is edited. It sets mode to E and disables accepter and refuser buttons.
   */
  _formEdited: function() {
    this.btnBarModel.set("mode", "E");
    this.btnBarModel.trigger("disable:accepter");
    this.btnBarModel.trigger("disable:refuser");
  },

  /**
   * It initializes pop up view used to send a message and confirm (GererOneBadgeagePopUpView)
   */
  _initPopUp: function(act) {
    var stat = this.gereronebadgeage.model.get("value").get("statut").code;
    var ctxUtilisateur = this.gereronebadgeage.context.ctxUtilisateur;
    var dialog = new DialogView({
      view: GererOneBadgeagePopUpView,
      viewData: {
        action: act,
        statut: stat,
        utilisateur: ctxUtilisateur
      }
    });

    dialog.setHeight(220);
    dialog.setWidth(445);

    return dialog;
  }
});