import { AffectationEnCoursView } from 'common/briques/baffec/views/affectationEnCours.view.js';
import { DemandesCollaborateursView } from 'common/briques/bdemcoll/views/demandesCollaborateurs.view.js';
import { DialogView } from 'core/views/dialog.view.js';
import { EffectifsView } from 'common/briques/beff/views/effectifs.view.js';
import { GeneralView } from 'common/briques/persobriques/views/general.view.js';
import { HABILITATION } from '../../utils/habilitation.js';
import { i18n } from '../../i18n.js';
import { InfosMemosView } from 'common/briques/binfmem/views/infosMemos.view.js';
import { MesDemandesView } from 'common/briques/bdem/views/mesDemandes.view.js';
import { MonResponsableView } from 'common/briques/bmonresp/views/monResponsable.view.js';
import { MesEmpruntsView } from 'common/briques/bemprunt/views/mesEmprunts.view.js';
import { PortletView } from './portlet.view.js';
import { ResultatsView } from 'common/briques/bresultats/views/resultats.view.js';
import { SituationsARegulariserView } from 'common/briques/bsitreg/views/situationsARegulariser.view.js';
import { SituationView } from 'common/briques/bsitpart/views/situation.view.js';
import { STR } from '../../utils/str.js';
import { SYNC } from '../../utils/sync.js';
import { TYPE } from '../../tda/tda.js';
import TPL_app_home from '../home.tpl.html';
import { GLOBAL_DATA } from '../../globalData.js';
import { MSGS } from '../../utils/msgs.js';
import { RgaaBadgeageView } from './rgaaBadgeage.view.js';

/**
 * A view to paint the  home page of each zone
 */
export var HomeView = Backbone.View.extend({

  className: "ui-widget-content",
  events: {
    "click .phx-brique-personnaliser-link-div a.phx-brique-personnaliser-link": "_personnaliser"
  },
  initialize: function(options) {
    this.model.on("reload:brique",
      function() {
        this._reloadBrique(true);
      }, this);

    this.template = TPL_app_home;
    this.zJSON = options.zJSON;
    this.columns = 3;
    this.BRIQUES_VIEWS = {};

    // DEVELOPMENT RGAA
    this.CREATE_RGAA = 'empty';

    this.listenTo(this.model, "change:uri", this._changeZone);
    this.listenTo(this.model, "refresh:Briques", this._managePopulationChange);

  },

  render: function() {
    this.$el.html(this.template());

    $(".phx-portlet-container", this.el).sortable({ items: ".phx-portlet", handle: ".phx-portlet-header", cursor: "move" });
    $(".phx-portlet-container", this.el).disableSelection();

    return this;
  },

  _managePopulationChange: function() {
    this._reloadBrique(true);
  },

  //RefreshAll=true if all briques have to be refreshed
  //briquesToRefresh, Array with the id of briques that should be refreshed, if only some specific briques have to be refreshed
  _reloadBrique: function(refreshAll, briquesToRefresh) {
    var self = this;
    var zone = this.model.get("zone");
    var lastBriqueReloaded = "";
    var callback = function() {
      var briques = self.model.BRIQUES[zone];
      var views = self.BRIQUES_VIEWS;

      _.each(briques, function(brique, index) {
        var brique_view_id = self.model.getBriqueUniqueId(brique);
        if (self.briqueAlreadyCreated(brique_view_id)) { //Created
          //IF bresultats has no data it shouldn't be shown
          if (STR.isBlank(views[brique_view_id].view) || STR.isBlank(views[brique_view_id].view.isEmpty) || views[brique_view_id].view.isEmpty() !== true) {
            views[brique_view_id].$el.show();
          }
          if (refreshAll === true || (_.isArray(briquesToRefresh) && _.indexOf(briquesToRefresh, brique_view_id) !== -1)) {
            self.refreshBrique(brique_view_id);
          }
        } else { //Not created
          self.createBrique(brique);
        }
        //KEEP ORDER sent in configuration Web Service in the order of painting
        if (!STR.isBlank(self.BRIQUES_VIEWS[brique_view_id])) {
          if (!STR.isBlank(lastBriqueReloaded)) {
            self.BRIQUES_VIEWS[brique_view_id].$el.insertAfter(lastBriqueReloaded.$el);
          }
          lastBriqueReloaded = self.BRIQUES_VIEWS[brique_view_id];
        }

      }, this);
      //Look for briques that are not activated anymore
      _.each(_.keys(self.BRIQUES_VIEWS), function(brique_view_id) {
        if (!self.briqueisActivated(brique_view_id)) { //It is not activated (hide this brique)
          self.BRIQUES_VIEWS[brique_view_id].$el.hide();
        }
      });
    };
    if (zone == this.zJSON["id"]) {
      this.model._reloadBriquesInfo(callback, "home" + this.zJSON["id"]);
    }
  },
  briqueAlreadyCreated: function(brique_view_id) {
    var created = false;
    var views = this.BRIQUES_VIEWS;
    if (!STR.isBlank(views[brique_view_id])) {
      created = true;
    }
    return created;

  },
  briqueisActivated: function(brique_view_id) {
    var self = this;
    var activated = false;
    var zone = this.model.get("zone");
    var briques = this.model.BRIQUES[zone];
    var briqueFound = _.find(briques, function(brique) {
      return brique_view_id === self.model.getBriqueUniqueId(brique);
    });
    if (!STR.isBlank(briqueFound)) {
      activated = true;
    }

    return activated;

  },
  refreshBrique: function(brique_view_id) {
    var views = this.BRIQUES_VIEWS;
    if (views[brique_view_id].view && views[brique_view_id].view.refreshBrique) {
      views[brique_view_id].view.refreshBrique();
    } else {
      views[brique_view_id].render();
    }

  },

  _changeZone: function() {
    if (this.model.get("zone") === this.zJSON.id && this.model.isGoingToZoneHome()) {
      this.loadComponents();
    }
  },

  loadComponents: function() {
    var self = this;
    if ((this.model.get("zone") === "coll" && HABILITATION.canView("PER_BRACC_C.V")) || (this.model.get("zone") === "resp" && HABILITATION.canView("PER_BRACC_R.V"))) {
      //Paint personnaliser link when zone collaborateur or resp
      var $link = $("<a>");
      $link.addClass("phx-brique-personnaliser-link").css("cursor", "pointer");
      $link.html(i18n.t('app.personnaliser'));
      this.$el.find(".phx-brique-personnaliser-link-div").html($link);
    }
    if (this.model.get("zone") === "coll") {
      if (GLOBAL_DATA.paramDivers.get("SPEValideu").get("valeur") == 1 && HABILITATION.canView("COL_SPE_MRESP.V")) {
        if (!GLOBAL_DATA.respDesig.get("existValideur")) {
          var span = $("<span>")
          span.html(i18n.t('common:bmonresp.msgBloquant'));
          var msgBloq = MSGS.showError($(span).text());
          msgBloq.find(".phx-msgs-body").css({ "color": "red", "text-transform": "uppercase", "font-weight": "bolder" });
        }
      }

    }
    if (_.keys(this.BRIQUES_VIEWS).length <= 0) {
      this._briques = this.model.BRIQUES[this.zJSON.id];
      _.each(this._briques, function(brique, index) {
        self.createBrique(brique);
      });
    }

    // DEVELOPMENT RGAA
    if(this.model.get("zone") === "coll"){
      const theme = _.clone(GLOBAL_DATA.theme);
      if(theme && theme.get("preferences")[0] === null){
        const _this = this;
        theme.fetch({
          success: function(model) {
            var _theme = model.get("preferences")[0].varchar1;

            if (!Configuration.themes || Configuration.themes.indexOf(_theme) < 0) {
              _theme = "gfi.theme";
            }
            var element = document.createElement("link");
            element.href = "css/" + _theme + ".css?_=" + _.random(1, 1000000000000000);
            element.id = "ui-theme";
            element.rel = "stylesheet";
            document.head.appendChild(element);
            if(_theme === 'contrast.theme'){
              _this.createRgaa();
            } else {
              _this.$el.find(".phx-brique-badgeage-rgaa-link-div").empty();
              _this.CREATE_RGAA = 'empty';
            }
          }
        });
      } else {
        if(theme.get("preferences") && !STR.isBlank(theme.get("preferences")[0]) && theme.get("preferences")[0].varchar1 === 'contrast.theme'){
          this.createRgaa();
        } else {
          this.$el.find(".phx-brique-badgeage-rgaa-link-div").empty();
          this.CREATE_RGAA = 'empty';
        }
      }

    }
  },

  createBrique: function(brique) {
    var briqueToday = TYPE.DATE.dateToStr(SYNC.getServerDate());
    var usePopulation = this.model.get("zone") === "resp";
    var brique_view_id = this.model.getBriqueUniqueId(brique);
    switch (brique.id) {
      case "bemprunt":
        var portlet = new PortletView({
          id: "bemprunt",
          title: i18n.t('common:bemprunt.mesEmprunts_title', { "0": briqueToday }),
          view: null
        });
        portlet.view = new MesEmpruntsView({ parent: portlet, usePopulation: usePopulation });
        $(".phx-portlet-container", this.el).append(portlet.render().el);
        if (portlet.view.refreshBrique) {
          portlet.view.refreshBrique();
        }
        portlet.$el.addClass("phx-portlet-2tier");
        this.BRIQUES_VIEWS[brique_view_id] = portlet;
        break;
      case "binfmem":
        var portlet = new PortletView({
          id: "binfmem",
          title: i18n.t('common:binfmem.infosMemos'),
          tooltip: i18n.t('common:binfmem.infosMemos_title'),
          view: null
        });
        portlet.view = new InfosMemosView({ parent: portlet, usePopulation: usePopulation });
        $(".phx-portlet-container", this.el).append(portlet.render().el);
        if (portlet.view.refreshBrique) {
          portlet.view.refreshBrique();
        }
        portlet.$el.addClass("phx-portlet-1tier");
        this.BRIQUES_VIEWS[brique_view_id] = portlet;
        break;
      case "bdem":
        var portlet = new PortletView({
          id: "bdem",
          title: i18n.t('common:bdem.mesDemandes'),
          tooltip: i18n.t('common:bdem.mesDemandes_title'),
          view: null
        });
        portlet.view = new MesDemandesView({ parent: portlet, usePopulation: usePopulation });
        $(".phx-portlet-container", this.el).append(portlet.render().el);
        if (portlet.view.refreshBrique) {
          portlet.view.refreshBrique();
        }
        portlet.$el.addClass("phx-portlet-2tier");
        this.BRIQUES_VIEWS[brique_view_id] = portlet;
        break;
      case "bsitpart":
        var portlet = new PortletView({
          id: "bsitpart",
          title: i18n.t('common:bsitpart.situation'),
          view: null
        });
        portlet.view = new SituationView({ parent: portlet, usePopulation: usePopulation });
        $(".phx-portlet-container", this.el).append(portlet.render().el);
        if (portlet.view.refreshBrique) {
          portlet.view.refreshBrique();
        }
        portlet.$el.addClass("phx-portlet-2tier");
        this.BRIQUES_VIEWS[brique_view_id] = portlet;
        break;
      case "bdemcoll":
        var portlet = new PortletView({
          id: "bdemcoll",
          title: i18n.t('common:bdemcoll.DemandesCollaborateurs'),
          view: null
        });
        portlet.view = new DemandesCollaborateursView({ parent: portlet, usePopulation: usePopulation });
        $(".phx-portlet-container", this.el).append(portlet.render().el);
        if (portlet.view.refreshBrique) {
          portlet.view.refreshBrique();
        }
        portlet.$el.addClass("phx-portlet-1tier");
        this.BRIQUES_VIEWS[brique_view_id] = portlet;
        break;
      case "baffec":
        var portlet = new PortletView({
          id: "baffec",
          title: i18n.t('common:baffec.affectationEnCours'),
          view: null
        });
        portlet.view = new AffectationEnCoursView({ parent: portlet, usePopulation: usePopulation });
        $(".phx-portlet-container", this.el).append(portlet.render().el);
        if (portlet.view.refreshBrique) {
          portlet.view.refreshBrique();
        }
        portlet.$el.addClass("phx-portlet-2tier");
        this.BRIQUES_VIEWS[brique_view_id] = portlet;
        break;
      case "bsitreg":
        var portlet = new PortletView({
          id: "bsitreg",
          title: i18n.t('common:bsitreg.SituationsARegulariser'),
          view: null
        });
        portlet.view = new SituationsARegulariserView({ parent: portlet, usePopulation: usePopulation });
        $(".phx-portlet-container", this.el).append(portlet.render().el);
        if (portlet.view.refreshBrique) {
          portlet.view.refreshBrique();
        }
        portlet.$el.addClass("phx-portlet-2tier");
        this.BRIQUES_VIEWS[brique_view_id] = portlet;
        break;
      case "beff":
        var heure = TYPE.DATE.dateToStr(SYNC.getServerDate(), "HH:mm", "", ":");
        var portlet = new PortletView({
          id: "beff",
          title: i18n.t('common:beff.brickTitle', { "0": heure }),
          view: null
        });
        portlet.view = new EffectifsView({ parent: portlet, usePopulation: usePopulation });
        $(".phx-portlet-container", this.el).append(portlet.render().el);
        if (portlet.view.refreshBrique) {
          portlet.view.refreshBrique();
        }
        portlet.$el.addClass("phx-portlet-1tier");
        this.BRIQUES_VIEWS[brique_view_id] = portlet;
        break;
      case "bresultats":
        var view = new ResultatsView({ brique: brique, usePopulation: usePopulation });
        var portlet = new PortletView({
          id: "bresultats" + view.cid.substring(4),
          view: view,
          usePopulation: usePopulation
        });
        view.parent = portlet;
        $(".phx-portlet-container", this.el).append(portlet.render().el);
        if (portlet.view.refreshBrique) {
          portlet.view.refreshBrique();
        }
        portlet.$el.addClass("phx-portlet-1tier");
        this.BRIQUES_VIEWS[brique_view_id] = portlet;
        break;
      case "bmonresp":
        if (GLOBAL_DATA.paramDivers.get("SPEValideu").get("valeur") == 1) {
          if (HABILITATION.canView("COL_SPE_MRESP.V") && HABILITATION.canView("PER_BRACC_C.V")) {
            var portlet = new PortletView({
              id: "bmonresp",
              title: "Mon Responsable",
              view: null
            });
            portlet.view = new MonResponsableView({ parent: portlet, usePopulation: usePopulation });
            $(".phx-portlet-container", this.el).append(portlet.render().el);
            if (portlet.view.refreshBrique) {
              portlet.view.refreshBrique();
            }
            portlet.$el.addClass("phx-portlet-2tier");
            this.BRIQUES_VIEWS[brique_view_id] = portlet;
          }
        }
        break;
      default:
        break;
    }
  },
  hasCreatedComponents: function() {
    return false;
  },
  _personnaliser: function() {
    var self = this;
    var zone = this.model.get("zone");

    var context = {
      ctxEcran: "home" + zone,
      espaceid: this.zJSON.emid,
      zone: zone,
      zoneName: this.zJSON.nom
    };

    if (this.dialogPersonnaliser === undefined) {
      this.dialogPersonnaliser = new DialogView({
        view: GeneralView,
        viewData: {
          context: context
        }
      });

    } else {
      this.dialogPersonnaliser.viewData = {
        context: context
      };
    }
    this.dialogPersonnaliser.setHeight(400);
    this.dialogPersonnaliser.setWidth(500);
    _.each(this.model.configuration.get("confignav").get("zones"), function(zone) {
      if (zone.id == this.model.get("zone")) {
        self.dialogPersonnaliser._setTitle(i18n.t('app.personnaliser') + " <<" + zone.nom + ">>");
      }
    }, this);

    this.dialogPersonnaliser.open(function() {
      //What to do on close
    });
    this.dialogPersonnaliser.internalView.model.off("reload");
    this.dialogPersonnaliser.internalView.model.on("reload",
      function(collection, refreshData, bToRefresh) {
        this._reloadBrique(null, bToRefresh);
      }, this);
  },

  createRgaa: function() {
    if (this.CREATE_RGAA === 'empty') {
      this.rgaaBadgeageView = new RgaaBadgeageView({
        model: this.model
      });
      this.$el.find(".phx-brique-badgeage-rgaa-link-div").html(this.rgaaBadgeageView.render().el);
      this.CREATE_RGAA = 'created';
    }
  }

});
