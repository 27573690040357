import { DialogView } from '../../../../../../core/views/dialog.view.js';
import { GererPretPopUpView } from '../views/gererPretPopUp.view.js';
import { GLOBAL_DATA } from '../../../../../../globalData.js';
import { i18n } from '../../../../../../i18n.js';
import { LOG } from '../../../../../../utils/log.js';
import { MSGS } from '../../../../../../utils/msgs.js';
import { PiecesAutorisees } from '../../gererpiecesjointes/models/piecesAutorisees.js';
import { ReadOnlyModel } from '../../../../../../core/models/readOnly.model.js';
import { STR } from '../../../../../../utils/str.js';

export var GererpretsWorkflow = ReadOnlyModel.extend({

  gererprets: null,

  initialize: function() {
    this.btnBarModel = null;
  },

  /**
   * Set up the workflow. Link the models between them
   *
   */
  setUp: function(callback) {
    this.pDiversIdCollabModel = GLOBAL_DATA.paramDivers.get("idCollab");

    this.maxPieces = new PiecesAutorisees({ id: "PJPRT" });
    if (this.formModel !== undefined && this.formModel.getHabContext() !== undefined) {
      this.maxPieces.setHabContext(this.formModel.getHabContext());
    }

    if (callback) {
      callback();
    }

    this.btnBarModel.on("btn:click", this._buttonAction, this);
    this.gererPrets.model.on("form:edited", this._formEdited, this);
  },

  _buttonAction: function(buttonId, profil) {
    LOG.debug("Button clicked in gerer pret : " + buttonId);
    var self = this; // Update habilitation to exec complementary action
    var habilitationGestion = this.gererPrets._getHabilitationGestion(this.formModel.get("value"));
    this.formModel.updateHabContext({fonc_cour: habilitationGestion });
    switch (buttonId) {
      case "save":
        this.gererPrets.savePretCollab();
        break;
      case "revert":
        this.gererPrets.revertView();
        break;
      case "new":
        this.gererPrets.newPretCollab();
        break;
      case "delete":
        var statut = STR.getElValue(this.gererPrets.model.get("value"), "statut.code");

        if (statut === "A" || statut === "H" ||
          (statut === "I" && self.gererPrets.context.ctxUtilisateur.toUpperCase() == "COLLABORATEUR")) {
          var dialog = self._initPopUp("suppression");
          var dialogModel = dialog.model;
          dialog.open(function() {
            if (dialogModel.get("action") == "OK") {
              self.gererPrets.deletePretCollab(dialogModel.get("commentaire"));
            }
          });
          dialog._setTitle(i18n.t('common:gererprets.title_popUp'));
        } else {
          MSGS.showConfirm(i18n.t('common:gererprets.delconfirm'), function(result) {
            if (result == "Y") {
              self.gererPrets.deletePretCollab("");
            }
          });
        }

        break;
      case "accepter":
        var dialog = this._initPopUp("accepter");
        var dialogModel = dialog.model;
        var validationProfil = profil;
        dialog.open(function() {
          if (dialogModel.get("action") == "OK") {
            self.gererPrets.accepterPretCollab(dialogModel.get("commentaire"), validationProfil);
          }
        });

        dialog._setTitle(i18n.t('common:gererprets.title_popUp'));
        break;
      case "refuser":
        var dialog = this._initPopUp("refuser");
        var dialogModel = dialog.model;
        var validationProfil = profil;
        dialog.open(function() {
          if (dialogModel.get("action") == "OK") {
            self.gererPrets.refuserPretCollab(dialogModel.get("commentaire"), validationProfil);
          }
        });

        dialog._setTitle(i18n.t('common:gererprets.title_popUp'));
        break;
    }
  },

  _formEdited: function() {
    this.btnBarModel.set("mode", "E");
    this.btnBarModel.trigger("disable:valider");

    if (this.formModel.getHabContext().get("nat_gest") !== "A") {
      var habilitationGestion = this.gererPrets._getHabilitationGestion(this.formModel.get("value"));
      this.formModel.updateHabContext({ fonc_cour: habilitationGestion, nat_gest: "M" });
    }
  },

  _initPopUp: function(act) {
    var stat = this.gererPrets.model.get("value").get("statut").code;
    var ctxUtilisateur = this.gererPrets.context.ctxUtilisateur;
    var dialog = new DialogView({
      view: GererPretPopUpView,
      viewData: {
        action: act,
        statut: stat,
        utilisateur: ctxUtilisateur
      }
    });
    dialog.setHeight(210);
    dialog.setWidth(470);

    return dialog;
  }
});