import { ElementModel } from './element.model.js';
import { PaginatedCollection } from 'core/models/paginated.collection.js';

export var ElementColl = PaginatedCollection.extend({

  usecase: "droitsdel",

  model: ElementModel,

  /**
   * * Constructor - renove the paginated (this.paginated = false)
   */
  initialize: function() {
    PaginatedCollection.prototype.initialize.call(this);
    this.paginated = false;
  },

  clone: function() {
    var temp = PaginatedCollection.prototype.clone.call(this);
    return temp;
  }
});