import { i18n } from '../../../i18n.js';
import { ReadOnlyModel } from '../../models/readOnly.model.js';
import { STR } from '../../../utils/str.js';
import TPL_common_navigation_layout from './navigation_layout.tpl.html';

/**
 * Render a Page menu in a form
 */
export var NavigationView = Backbone.View.extend({

  tagName: "div",

  showMenu: false,

  initialize: function(options) {

    this.template = TPL_common_navigation_layout;

    // template of the menu
    if (options.menutpl) {
      this.showMenu = true;
      this.menuTemplate = options.menutpl;
    }

    /*
    // template of the menu
    if (options.menutpl){
    	this.showMenu = true;
    	this.menuTemplate = _.template(TPL.get(options.menutpl));
    }
    */

    if (options.text) {
      this.text = options.text;
    }

    // number of panel to initialize menuText div
    if (options.menuIni) {
      this.iniPanel = options.menuIni;
    }

    if (options.habilitationOption) {
      this.habOpt = options.habilitationOption;
    } else {
      this.habOpt = null;
    }

    this.menuHide = null;
    if (options.menuHide) {
      this.menuHide = options.menuHide;
    }

    this.model = new ReadOnlyModel({
      page: "0",
      navigationState: 3,
      title: "",

      defaults: {
        page: "0",
        navigationState: 3, //0:all enabled, 1:first,prev disabled, 2:last,next disabled, 3:all disabled
        title: ""
      }
    });

    this.model.on("change:navigationState", this._navigationState, this);
    this.model.on("change:title", this._changeTitle, this);
    this.model.on("revert", this.revert, this);
    this.model.on("reset", this.reset, this);
    this.model.on("setPage", this.setPage, this);
    this.model.on("closeMenu", this._closeMenu, this);
    this.panelNames = [];

  },

  events: {
    "click .menuButton": "_toggleMenu",
    "click .menuItem:not([screen-navigation])": "_selectMenuItem",
    "click .menuItem[screen-navigation]": "_selectScreenNavigationItem",
    "click .linkItem": "_selectLinkItem",
    "click .navigationButton-first": "_navigateFirst",
    "click .navigationButton-prev": "_navigatePrev",
    "click .navigationButton-next": "_navigateNext",
    "click .navigationButton-last": "_navigateLast"
  },

  // navigation values ---> 1:first, 2:prev 3:next 4:last
  _navigateFirst: function() {
    this.model.trigger("change:navigation", 1);
  },

  _navigatePrev: function() {
    this.model.trigger("change:navigation", 2);
  },

  _navigateNext: function() {
    this.model.trigger("change:navigation", 3);
  },

  _navigateLast: function() {
    this.model.trigger("change:navigation", 4);
  },

  _changeTitle: function(model) {
    //Set the initial TEXT
    var menuText = $(".menuText", this.el);
    if (this.panelNames.length > 0) {
      menuText.text(model.get("title") + this.panelNames[this.iniPanel]);
    } else {
      menuText.text(model.get("title"));
    }
  },

  _navigationState: function(state) {
    switch (state.get("navigationState")) {
      case 0:
        $(".navigationButton-first", this.el).button({ disabled: false });
        $(".navigationButton-last", this.el).button({ disabled: false });
        $(".navigationButton-prev", this.el).button({ disabled: false });
        $(".navigationButton-next", this.el).button({ disabled: false });
        break;
      case 1:
        $(".navigationButton-first", this.el).button({ disabled: true });
        $(".navigationButton-prev", this.el).button({ disabled: true });
        $(".navigationButton-last", this.el).button({ disabled: false });
        $(".navigationButton-next", this.el).button({ disabled: false });
        break;
      case 2:
        $(".navigationButton-first", this.el).button({ disabled: false });
        $(".navigationButton-prev", this.el).button({ disabled: false });
        $(".navigationButton-last", this.el).button({ disabled: true });
        $(".navigationButton-next", this.el).button({ disabled: true });
        break;
      case 3:
        $(".navigationButton-first", this.el).button({ disabled: true });
        $(".navigationButton-last", this.el).button({ disabled: true });
        $(".navigationButton-prev", this.el).button({ disabled: true });
        $(".navigationButton-next", this.el).button({ disabled: true });
        break;
      default:
        //nothing
    }
    // to remove focus on button after disabling it
    $(".navigationButton-first", this.el).removeClass('ui-state-focus ui-state-hover ui-state-active');
    $(".navigationButton-last", this.el).removeClass('ui-state-focus ui-state-hover ui-state-active');
    $(".navigationButton-next", this.el).removeClass('ui-state-focus ui-state-hover ui-state-active');
    $(".navigationButton-prev", this.el).removeClass('ui-state-focus ui-state-hover ui-state-active');
  },

  render: function() {

    this.$el.append(this.template());

    if (this.menuTemplate) {
      var json = { i18n: i18n, habilitationOption: this.habOpt, menuHide: this.menuHide };

      this.$el.append(this.menuTemplate(json));
    }

    var menuBtn = $(".menuButton", this.el);

    if (this.showMenu) {
      menuBtn.show();
      menuBtn.button({ text: false });
      menuBtn.button("option", "icons", { primary: "ui-icon-triangle-1-s" });

      var menuContent = $(".menuContent", this.el);
      menuContent.menu();
      menuContent.hide();
    } else {
      menuBtn.hide();
    }

    // navigation bar buttons
    $(".navigationButton-first", this.el).button({ icons: { primary: "ui-icon-seek-first" }, text: false });
    $(".navigationButton-last", this.el).button({ icons: { primary: "ui-icon-seek-end" }, text: false });
    $(".navigationButton-prev", this.el).button({ icons: { primary: "ui-icon-triangle-1-w" }, text: false });
    $(".navigationButton-next", this.el).button({ icons: { primary: "ui-icon-triangle-1-e" }, text: false });

    $(".navigationButton-first span.ui-button-text", this.el).html(i18n.t('common:premiere'));
    $(".navigationButton-last span.ui-button-text", this.el).html(i18n.t('common:dernier'));
    $(".navigationButton-prev span.ui-button-text", this.el).html(i18n.t('common:precedent'));
    $(".navigationButton-next span.ui-button-text", this.el).html(i18n.t('common:suivant'));
    //Load panelNames
    var items = (this.$el).find(".ui-menu-item a");
    for (var i = 0; i < items.length; i++) {
      this.panelNames[items[i].getAttribute("data-value")] = this._currentMenuText(items[i]);
    }

    //Set the initial TEXT
    var menuText = $(".menuText", this.el);
    if (this.panelNames.length > 0) {
      menuText.html(this.text + this.panelNames[this.iniPanel]);
    } else {
      menuText.html(this.text);
    }
    menuText.attr("id", this.getTextId());

    return this;
  },

  setPanelName: function(item) {
    this.panelNames[$(item).attr("data-value")] = this._currentMenuText(item);
  },

  // Method to update model with newly selected menu
  // Iterate thru menus to extract correct libelles: menu1 > menu2 > etc...
  _selectMenuItem: function(event) {
    var option = event.currentTarget.attributes["data-value"].nodeValue;
    // Check keep-menu attribute if exist the menu does not change
    var keepMenu = !STR.isBlank(event.currentTarget.attributes["keep-menu"]);
    var menuText = $(".menuText", this.el);

    if (!keepMenu) {
      // add menu name for dynamic menus (see fichecollaborateur/orga)
      if (STR.isBlank(this.panelNames[option])) {
        this.panelNames[option] = this._currentMenuText(event.currentTarget);
      }
      // set menu text
      menuText.html(this.text + this.panelNames[option]);
    }
    this._toggleMenu();
    this.model.set("page", option);
    this.model.panelPage = option;
  },

  _selectScreenNavigationItem: function(event) {
    var option = event.currentTarget.attributes["data-value"].nodeValue;
    this._toggleMenu();
    if (option && (parseInt(option, 10) === 40 || parseInt(option, 10) === 41 || parseInt(option, 10) === 43)) {
      //this.model.set("page", option);
      this.model.trigger("call:agenda", option);
    }
  },

  _selectLinkItem: function(event) {
    var option = event.currentTarget.attributes["data-value"].nodeValue;
    var menuText = $(".menuText", this.el);
    // add menu name for dynamic menus (see fichecollaborateur/orga)
    if (STR.isBlank(this.panelNames[option])) {
      this.panelNames[option] = this._currentMenuText(event.currentTarget);
    }
    // set menu text
    menuText.html(this.text + this.panelNames[option]);
    this.model.set("page", option);
    this.model.panelPage = option;
  },

  //Method to close and open the menu.
  _toggleMenu: function() {
    var menuContent = $(".menuContent", this.el);
    if (menuContent.is(":visible")) {
      menuContent.hide();
    } else {
      var menuBtn = $(".menuButton", this.el);
      menuContent.show().position({
        my: "left top",
        at: "left bottom",
        of: menuBtn
      });
      //If you click out of the menu, close the menu.
      var self = this;
      //$(document).one("mousedown", function (event) {
      $(document).one("mouseup", function(event) {
        var element = $(self.el).find(".menuContent").find(event.target);
        var elementInnNavigation = $(self.el).find(event.target);
        if (element.length === 0 && !(elementInnNavigation.length === 1 && $(event.target).hasClass("a.linkIteml")) &&
          !(elementInnNavigation.length === 1 && $(event.target).hasClass("ui-icon-triangle-1-s"))) {
          self._toggleMenu();
        } else {
          $(document).one("mouseup", function(event) {
            if (!($(event.target).hasClass("menuItem"))) {
              self._closeMenu();
            }
          });
        }
      });
    }
  },

  _closeMenu: function() {
    var menuContent = $(".menuContent", this.el);
    if (menuContent.is(":visible")) {
      menuContent.hide();
    }
  },

  revert: function() {
    //Revert the model
    this.model.attributes = this.model.previousAttributes();
    //Repaint the menuText with the actual page
    var menuText = $(".menuText", this.el);
    menuText.html(this.text + this.panelNames[this.model.get("page")]);
  },

  reset: function() {
    //Repaint the menuText with the actual page
    this.model.set("page", "0", { pageReset: true });
    var menuText = $(".menuText", this.el);
    menuText.html(this.text + this.panelNames[this.model.get("page")]);
  },

  setPage: function(page) {
    //Repaint the menuText with the specified page
    this.model.set("page", page, { pageReset: true });
    var menuText = $(".menuText", this.el);
    menuText.html(this.text +
      (!STR.isBlank(this.panelNames[this.model.get("page")]) ? this.panelNames[this.model.get("page")] : ""));
  },

  _currentMenuText: function(item) {
    var text = "";
    var parent = $(item).parents(".subMenuItem");

    // CUSTOMER 138595
    var separator = "";

    _.each(parent.siblings("a"), function(ref) {
      separator = ref.getAttribute("separator");
      if (!STR.isBlank(ref)) {
        if ($(ref).hasClass("linkItem")) {
          var link = $("<span>").addClass("linkItem").attr("data-value", ref.getAttribute("data-value"));
          link.html($(ref).text());
          text = text + "<span role='presentation' class='presentation' aria-hidden='true'> > </span>" + link.prop("outerHTML");
        } else {
          text = text + "<span role='presentation' class='presentation' aria-hidden='true'> - </span>" + $(ref).text();
        }
      }
    });

    return text + "<span role='presentation' class='presentation' aria-hidden='true'>" + ((separator && separator.length > 0) ? separator : " > ") + "</span>" + $(item).text();
  },

  getTextId: function() {
    // Set the id to be used by WAI-ARIA
    return this.cid + "-menuText";
  }
});
