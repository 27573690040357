import { BaseModel } from '../../../../../../core/models/base.model.js';


export var AccepterPret = BaseModel.extend({

  urlRoot: Configuration.restRoot + "/rest/composant/pret/accepter/",
  defaults: {
    "commentaire": "",
    "droit": "",
    "profil": ""
  }
});