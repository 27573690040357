import { FORMS } from '../../utils/forms.js';
import { LOG } from '../../utils/log.js';
import { STR } from '../../utils/str.js';
import { TREE } from '../../utils/tree.js';
import { TreeModel } from './tree.model.js';
import { TreeNodeView } from './treeNode.view.js';

export var TreeView = Backbone.View.extend({

  dummy: "",

  /**
   * View of the Tree Component
   */
  initialize: function(params) {
    params || (params = {});
    /**
     * Model of the Tree
     */
    this.model = new TreeModel(params);
    /**
     * Sort Function of the tree
     */
    this.sortTree = undefined;

    //EVO 186: Multiselection mode, to show the checkboxes
    this.multiselect = false;
    if (!STR.isBlank(params.multiselect)) {
      this.multiselect = params.multiselect;
    }
    if (this.multiselect) {
      if (params.checkedColl) {
        if (params.checkedColl instanceof Backbone.Collection) {
          this.model.checkedColl = params.checkedColl;
        } else {
          throw Error("checkedColl parm must be a Backbone.Collection instance");
        }
      } else {
        this.model.checkedColl = new Backbone.Collection();
      }
    }
    this.readOnly = false;
    if (params && params.readOnly) {
      this.readOnly = params.readOnly;
    }

    if (params && params.isCheckedCallback) {
      this.isCheckedCallback = params.isCheckedCallback;
    }

    /**
     * Root Node
     */
    this.root = undefined;
  },

  expandAllNodes: function() {
    this.root._expandAllNodes();
  },

  collapseAllNodes: function() {
    this.root._collapseAllNodes();
  },

  setReadOnly: function(readOnly) {
    this.readOnly = readOnly;
    FORMS.setFormReadonly(this.$el, readOnly, true);
  },

  /**
   * Clear all selected rows.
   */
  clearCheckedRows: function() {
    this.model.checkedColl.reset(null);
    this.model.coll.forEach(function(model) {
      model.trigger("row:unchecked");
    });

  },

  /**
   * Gets all rows marked for selection if in multiselection, otherwise return the active row.
   */
  getCheckedRows: function() {
    if (this.multiselect === true) {
      return this.model.checkedColl;
    } else {
      return this.getCurrentRow();
    }
  },

  /**
   * Gets all rows marked for selection if in multiselection, otherwise return the active row.
   */
  setCheckedRows: function(selectedModelsColls) {
    this.model.checkedColl = selectedModelsColls;
  },

  //EVO 186: ------------>

  /**
   * Get active row (row selected but not marked for selection.
   */
  getCurrentRow: function() {
    return this.model.get("value");
  },

  ////EVO 186: <------------------

  /**
   * Set the collection for the tree data
   */
  setColl: function(coll) {
    this.model.coll = coll;
  },

  /**
   * Set the sortTree function for tree sorting
   */
  setSortFunction: function(func) {
    this.sortTree = func;
  },

  /**
   * Set the renderer function to render the tree nodes
   */
  setRenderer: function(renderer) {
    if (!STR.isBlank(renderer)) {
      this.model.renderer = renderer;
    }
  },

  /**
   * Render the View
   */
  render: function() {
    this.$el.empty();
    var showSelected = this.model.get("showSelected") || false;

    if (!STR.isBlank(this.model.manyRoots) && this.model.manyRoots === true) { //There are many roots for this Node
      this.root = new TreeNodeView({
        root: this.model,
        level: 0,
        showSelected: showSelected,
        firstLevelNode: true,
        multiselect: this.multiselect,
        parent: this,
        isCheckedCallback: this.isCheckedCallback,
      });
      this.root.setSortFunction(this.sortTree);
      this.$el.html(this.root.render().el);
      $(this.root.el).find(".phx-treenode-icon").hide(); //Hide fictitious root
      $(this.root.el).find(".phx-treenode-leaf-icon").hide();
      $(this.root.el).find(".phx-treenode-wrap").hide();
    } else { //There is only one root for the node
      this.root = new TreeNodeView({
        root: this.model,
        level: 0,
        showSelected: showSelected,
        multiselect: this.multiselect,
        parent: this,
      });
      this.root.setSortFunction(this.sortTree);
      this.$el.html(this.root.render().el);
    }
    return this;
  },

  /**
   * Remove the View
   */
  remove: function() {
    this.root.remove();

    Backbone.View.prototype.remove.call(this); // Remove view from DOM
    delete this.$el; // Delete the jQuery wrapped object variable
    delete this.el; // Delete the variable reference to this node
  },

  /**
   * Select the first node available. It opens all structures to reach to it.
   * callback - Claabck function to be executed to know if an element has been selected or not
   */
  _selectFirstNode: function(callback) {
    //this._sortCollection(this.root.model.coll);
    this._selectFirstNodeRecurs(this.root.sonsColl, callback);
  },

  /**
   * If the node is a structure, then open it and start again with its childrens.
   */
  _selectFirstNodeRecurs: function(nodeViews, callback) {
    var self = this;
    var expanded = false;
    _.each(nodeViews, function(view) {
      var model = view.model.node;
      if (model.get("typelt") === "A" && expanded === false) {
        //				model.trigger("expandNode", null, function(coll){
        model.trigger("selectNode");
        //				});
        expanded = true;
      } else if ((model.get("typelt") === "S" || model.get("code") === " " || model.get("typelt") === "F") && expanded === false) {
        expanded = true;
        model.trigger("expandNode", null, function(coll, sonsColl) {
          //self._sortCollection(coll);
          self._selectFirstNodeRecurs(sonsColl);
        });
      }
    });

    if (expanded === false && nodeViews.length > 0) {
      nodeViews.model.node.trigger("selectNode");
      //collection.at(0).trigger("selectNode");
      if (callback) {
        callback(true);
      }
    } else {
      if (callback) {
        callback(expanded); //Informs if element has been selected or not.
      }
    }
  },

  findElement: function(parentNode, elementCode, datedeb, recursive, callback, treatedArray) {
    var l_treatedArray = !STR.isBlank(treatedArray) ? treatedArray : [];
    _.each(parentNode.sonsColl, function(childNode) {
      l_treatedArray.push(childNode);
      // recursive navigation thru all the nodes
      if (childNode.model.get("expanded") && recursive) {
        this.findElement(childNode, elementCode, datedeb, recursive, callback, l_treatedArray);
      }
      // if node == elementCode then we call the callback function for further processing
      if (childNode.model.node.get("code") === elementCode) {
        if (datedeb) {
          if (childNode.model.node.get("datedeb") === datedeb) {
            callback(childNode, l_treatedArray);
          }
        } else {
          callback(childNode, l_treatedArray);
        }
      }

    }, this);
  },

  /**
   * The method seach for an element and returns if this element is found or not.
   */
  findElementWithResponse: function(parentNode, elementCode, datedeb, recursive, callback) {
    var found = false;
    var foundNode = undefined;
    var seachingInsideChildren = false;

    if (parentNode.model.node.get("code") === elementCode) {
      if (datedeb) {
        if (parentNode.model.node.get("datedeb") === datedeb) {
          found = true;
          foundNode = parentNode;
        }
      } else {
        found = true;
        foundNode = parentNode;
      }
    }

    var size = parentNode.sonsColl.length;

    for (var i = 0; i < size && found === false; i++) {
      var childNode = parentNode.sonsColl[i];
      // recursive navigation thru all the nodes
      if (childNode.model.get("expanded") && recursive) {
        seachingInsideChildren = true;
        this.findElementWithResponse(childNode, elementCode, datedeb, recursive, callback);
      }
      // if node == elementCode then we call the callback function for further processing
      if (childNode.model.node.get("code") === elementCode) {
        if (datedeb) {
          if (childNode.model.node.get("datedeb") === datedeb) {
            found = true;
            foundNode = childNode;
          }
        } else {
          found = true;
          foundNode = childNode;
        }
      }
    }

    if (seachingInsideChildren === false) {
      var nodeIde = "";
      if (foundNode) {
        nodeIde = foundNode.id;
      }
      LOG.debug("finalized seach: " + found + " " + nodeIde);
      callback(found, foundNode);
    }
  },

  expandRoot: function(callback) {
    this.root._expand(null, callback);
  },

  /**
   * Construct the complete path and trigger the function to open the path and find the correct activity.
   * rattachement dates of the element to be selected are filterede by periodes d'utilization to obtain valid periods.
   * don't click on it, because it is being selected from first open of the tree, so element is already the value selected in selecteuractiviteinput
   */
  expandPath: function(pathModel, objectNodeToOpen, addHors, shouldReload, perutil, arrayPerUtil, callbackFound, onlyMarkSelected) {
    var hierarchy = !STR.isBlank(STR.getElValue(pathModel, "hierar")) ? STR.getElValue(pathModel, "hierar") : [];
    var structure = !STR.isBlank(STR.getElValue(pathModel, "structa")) ? STR.getElValue(pathModel, "structa") : [];
    var elemDateDeb = null;
    var elemDateFin = null;
    var perUtilInfo = {};
    if (perutil === true) {
      //If perutil is=true, dates are limited by utilization dates. So hierid doesn'tpoint to a unique element.
      //And we have to filter path by dates of the element  to be selected.
      elemDateDeb = !STR.isBlank(STR.getElValue(pathModel, "datedeb")) ? STR.getElValue(pathModel, "datedeb") : null;
      elemDateFin = !STR.isBlank(STR.getElValue(pathModel, "datefin")) ? STR.getElValue(pathModel, "datefin") : null;
      perUtilInfo = { elemDateDeb: elemDateDeb, elemDateFin: elemDateFin, arrayPerUtil: arrayPerUtil, index: 0 };
    }
    if (STR.isBlank(structure) && addHors === true) {
      //if do not have structure then it is "hors structure".
      structure = [];
      var horsObject = { "code": " ", "libelle": "", "niveau": null, "codef": null, nodeType: "S" };
      structure.push(horsObject);
    }
    var arrayCompletePath = _.union(structure, hierarchy);

    if (arrayCompletePath.length > 0) {
      if (perutil === true) { //Find element in tree for different periodes d'utilisation
        this.recursExpandPerUtil(arrayCompletePath, 0, this.root.model.coll, objectNodeToOpen, shouldReload, perUtilInfo, callbackFound, onlyMarkSelected);
      } else { //Find element in tree (without perutil)
        this.recursExpand(arrayCompletePath, 0, this.root.model.coll, objectNodeToOpen, shouldReload, false, callbackFound, onlyMarkSelected);
      }

    } else {
      var nodeToSelect = this._findElementPath(this.root.model.coll, objectNodeToOpen);
      if (!STR.isBlank(nodeToSelect)) {
        if (STR.isBlank(onlyMarkSelected) || onlyMarkSelected !== true) {
          nodeToSelect.trigger("selectNode");
        } else { //Only paint style of selection but don't select element because it is already selected
          nodeToSelect.trigger("markCLassSelectedNode");
        }
        if (callbackFound) {
          callbackFound(true);
        }
      } else if (callbackFound) {
        callbackFound(false);
      }
    }
  },

  /**
   * Call recursexpan once per each period d'utilization until a chemin is found to the element we want to select.
   */
  recursExpandPerUtil: function(arrayCompletePath, index, coll, elementToSelect, shouldReload, perUtilInfo, callbackFound, onlyMarkSelected) {
    var self = this;
    var callbackRecurs = function(found) {
      if (found !== true) {
        perUtilInfo.index++;
        if (perUtilInfo.index <= (perUtilInfo.arrayPerUtil.length - 1)) {
          self.recursExpand(arrayCompletePath, 0, self.root.model.coll, elementToSelect, shouldReload, perUtilInfo, callbackRecurs, onlyMarkSelected);
        } else {
          if (callbackFound) {
            callbackFound(false); //Element not found
          }
        }

      } else {
        if (callbackFound) {
          callbackFound(true); //Element found
        }
      }
    };
    this.recursExpand(arrayCompletePath, 0, this.root.model.coll, elementToSelect, shouldReload, perUtilInfo, callbackRecurs, onlyMarkSelected);
  },
  /**
   * Find and open all elements of the path until the last one is reached.
   */
  recursExpand: function(arrayCompletePath, index, coll, elementToSelect, shouldReload, perUtilInfo, callback, onlyMarkSelected) {
    var self = this;
    var objectPathToFind = arrayCompletePath[index];
    //search the element of path and if it's found, select it.
    var findPathModel = this._findElementPath(coll, objectPathToFind, perUtilInfo);
    if (!STR.isBlank(findPathModel)) {
      findPathModel.trigger("expandNode", null, function(coll) {
        if (index + 1 < arrayCompletePath.length) {
          self.recursExpand(arrayCompletePath, index + 1, coll, elementToSelect, shouldReload, perUtilInfo, callback, onlyMarkSelected);
        } else {
          var nodeToSelect = self._findElementPath(coll, elementToSelect);
          if (!STR.isBlank(nodeToSelect)) {
            if (STR.isBlank(onlyMarkSelected) || onlyMarkSelected !== true) {
              nodeToSelect.trigger("selectNode");
            } else { //Only paint style of selection but don't select element because it is already selected
              nodeToSelect.trigger("markCLassSelectedNode");
            }
            if (callback) {
              callback(true);
            }
          } else {
            if (callback) {
              callback(false);
            }
          }
        }
      }, shouldReload);
    } else {
      if (callback) {
        callback(false);
      }
    }
  },

  /**
   * Find the element into the collection
   */
  _findElementPath: function(collection, elementToSelect, perUtilInfo) {
    var findObject = undefined;
    var type = elementToSelect.nodeType;
    _.each(collection.models, function(model) {
      var id = type === "H" ? model.get("hierid") : model.get("code");
      var idToFind = null;
      if (type === "H") {
        if (elementToSelect instanceof Backbone.Model) {
          idToFind = elementToSelect.get("hierid");
        } else {
          idToFind = elementToSelect.hierid;
        }
      } else {
        if (elementToSelect instanceof Backbone.Model) {
          idToFind = elementToSelect.get("code");
        } else {
          idToFind = elementToSelect.code;
        }
      }

      if (id === idToFind) {
        if (STR.isBlank(perUtilInfo) || STR.isBlank(perUtilInfo.elemDateDeb)) {
          if (!STR.isBlank(elementToSelect) && !STR.isBlank(elementToSelect.periodeToSelect)) {
            var periodeModelColl = { datedeb: STR.getElValue(model, "datedeb"), datefin: STR.getElValue(model, "datefin") };
            if (TREE.periodeContainesPart(periodeModelColl, elementToSelect.periodeToSelect)) {
              findObject = model;
            }
          } else {
            findObject = model;
          }
        } else { //dates filtered by periode d'utilization
          //there could be many elements in the tree with the same hierid, so we have to filter by date.
          //The period of the parent must contain totally or partially the period of the element to select.
          var periodUtil = perUtilInfo.arrayPerUtil[perUtilInfo.index];
          var intersectionRattAndUtil = TREE.getPeriodeIntersection({ datedeb: periodUtil.datedeb, datefin: periodUtil.datefin }, { datedeb: perUtilInfo.elemDateDeb, datefin: perUtilInfo.elemDateFin });
          if (!STR.isBlank(intersectionRattAndUtil) && TREE.periodeContainesPart({ datedeb: model.get("datedeb"), datefin: model.get("datefin") }, intersectionRattAndUtil)) {
            findObject = model;
          }
        }
      }
    });
    return findObject;
  },

  _sortCollection: function(coll) {
    coll.comparator = function(model) {
      //return (!_.isEmpty(model.node)?model.get("libelle")+model.node.get("datedeb"):model.get("libelle"));
      return model.get("libelle");
    };
    coll.sort();
  }
});