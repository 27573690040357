import { AccepterPret } from '../models/accepterPret.js';
import { BaseModel } from '../../../../../../core/models/base.model.js';
import { BlockView } from '../../../../wkfhisto/views/block.view.js';
import { FormView } from '../../../../../../core/views/form.view.js';
import { i18n } from '../../../../../../i18n.js';
import { RefuserPret } from '../models/refuserPret.js';
import { STR } from '../../../../../../utils/str.js';
import TPL_listerprets_rowPopup from '../rowPopup.tpl.html';

export var RowPopupView = FormView.extend({

  initialize: function(options) {
    this.template = TPL_listerprets_rowPopup;

    this.model = new BaseModel({
      value: null
    });

    if (options.refEvenement) {
      this.refEvenement = options.refEvenement;
    }

    if (options.demandeId) {
      this.demandeId = options.demandeId;
    }

    if (options.action) {
      this.action = options.action;

      if (this.action === "A") {
        this.model.set("value", new AccepterPret());
      } else if (this.action === "R") {
        this.model.set("value", new RefuserPret());
      }
    } else {
      this.model.set("value", new AccepterPret());
    }

    this.workflow = options.workflow;

    this.model.get("value").on("change:commentaire", this._mapToForm, this);

    this.commentaire = STR.isBlank(options.commentaire) ? "" : options.commentaire;

    if (options.parent) {
      this.parent = options.parent;
      this.parent.model.set("isCommentChanged", false);
    }
  },

  events: {
    "change :input:not([readonly])": "_change",
    "keyup :input:not([readonly])": "_enableButtons",
    "click button": "_clickListener"
  },

  render: function() {
    var json = { "i18n": i18n };
    this.$el.append(this.template(json));

    // Render buttons
    $(this.el).find(".btnValider").button();
    $(this.el).find(".btnRevert").button({
      icons: {
        primary: "ui-icon-arrowrefresh-1-w"
      },
      text: false
    });

    //Initialize the comment textarea with the previous comment.
    STR.setElValue(this.model.get("value"), "commentaire", this.commentaire);

    //Make the comment mandatory if is for refuse an absence
    if (this.action === "R") {
      $(this.el).find("fieldset.listerprets-dialogPopupRow label[for=commentaire], fieldset.listerprets-dialogPopupRow label[for^=commentaire_uid_]").addClass("required");
    }

    //Disable the buttons
    $(this.el).find("fieldset.listerprets-dialogPopupRow button").button({ disabled: true });

    this._manageWKFHisto();

    if (this.parent) {
      $(this.parent.el).css("height", "auto");
    }

    return this;
  },

  _manageWKFHisto: function() {
    var typeLabel = "PRET";
    var contextComponentWkf = {
      ctxEcran: this.workflow.context.ctxEcran,
      ctxHabilitation: this.workflow.context.ctxHabilitation.HabilitationAcces,
      ctxTypeEvenement: typeLabel,
      ctxRefEvenement: this.refEvenement,
      ctxDemId: this.demandeId,
      ctxModeRestitution: "Imbrique",
      ctxActivModeSynth: true,
      ctxModeDefaut: "Deplier",
      ctxModeRech: "false"
    };

    var composantWorkflow = new BlockView();
    $(this.el).find(".listerprets-wkfhisto").html(composantWorkflow.render().el);
    composantWorkflow.setContext(contextComponentWkf);
  },

  _change: function(event) {
    FormView.prototype._change.call(this, event);

    // Clear current validation erros
    this._cleanValidationErrors();
  },

  _enableButtons: function() {
    $(this.el).find("fieldset.listerprets-dialogPopupRow button").button({ disabled: false });
  },

  _mapToForm: function() {
    var model = this.model.get("value");
    var fieldset = this.$el.find("fieldset");
    this._mapModelToForm(fieldset, model, null);
  },

  _clickListener: function(event) {
    var btnClicked = event.currentTarget.value;
    var model = this.model.get("value");
    var commentaire = STR.getElValue(model, "commentaire");

    switch (btnClicked) {
      case "btnValider":
        // Error management
        model.off("invalid");
        model.on("invalid", this._showValidationErrors, this);

        if (model.isValid()) {
          this.parent.model.set("commentaire", commentaire);
          this.parent.model.set("isCommentChanged", true);
          this.parent.close();
        }
        break;
      case "btnRevert":
        STR.setElValue(model, "commentaire", this.commentaire);
        break;
      default:
    }
  }
});