import { i18n } from '../../../../i18n.js';
import { ReadOnlyModel } from 'core/models/readOnly.model.js';
import { STR } from '../../../../utils/str.js';

/**
 * Population model
 */
export var PopulationSelectColaborateursModel = ReadOnlyModel.extend({

  defaults: {
    "matric": null
  },

  validate: function(attrs) {
    var errors = {};

    if (STR.isBlank(attrs.matric)) {
      errors.nom = i18n.t('common:required', { "0": i18n.t('populations.collaborateurs_collaborateur') });
    }

    if (!_.isEmpty(errors)) {
      var errorMap = {
        errorValidation: STR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
    return null;
  },

  parse: function(response) {
    if (response.id === undefined && response.ident !== undefined) {
      this.id = response.ident;
    }
    return response;
  }
});