import { i18n } from '../../i18n.js';
import { MenuBarView } from './menuBar.view.js';
import { NAV } from '../../utils/nav.js';
import { ProfileSelector } from './profileSelector.js';
import { UserMenuView } from './userMenu.view.js';
import { ZoneSelectorView } from './zoneSelector.view.js';
import TPL_app_header from '../header.tpl.html';

/**
 * View of the header of the home
 */
export var HeaderView = Backbone.View.extend({

  initialize: function() {
    this.template = TPL_app_header;
  },

  events: {
    "click #phx-account .phx-acount-span a": function() {
      //logout
      this.model.trigger("user:prelogout");
    },
    "click #phx-logo": function() {
      NAV.navigate();
    }
  },

  render: function() {
    var json = { i18n: i18n, auth: this.model.authModel.toJSON() };
    this.$el.html(this.template(json));
    var zonesView = new ZoneSelectorView({
      el: $(".phx-zone-selector", this.el),
      model: this.model
    });
    zonesView.render();

    var menuBarView = new MenuBarView({
      el: $("#phx-menu-bar", this.el),
      model: this.model
    });
    menuBarView.render();

    this.userMenuView = new UserMenuView({
      el: $("#phx-user-menu", this.el),
      model: this.model
    });
    this.userMenuView.render();

    var profilesView = new ProfileSelector({
      el: $("#phx-profile-selector", this.el),
      model: this.model
    });
    profilesView.render();
    this.$el.find(".phx-zone-selector").css("position", "relative").css("display", "inline-block");
    return this;
  }

});