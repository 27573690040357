import { BaseModel } from 'core/models/base.model.js';

/**
 * Model of SearchResultatsListeCpt
 */
export var SearchResultatsListeCptModel = BaseModel.extend({

  defaults: {
    "libelle": "",
    "valeur": "",
    "type": ""
  },

  parse: function(response) {
    if (response.id === undefined && response.code !== undefined) {
      response.id = response.code;
    }
    return response;
  }
});