import { EditableGridModel } from '../editablegrid/editableGrid.model.js';
import { STR } from '../../../utils/str.js';

export var EditableGroupeGridModel = EditableGridModel.extend({

  dummy: "",

  /**
   * Constructor
   * Model underlying a Groupe type DataGrid
   */
  initialize: function(options) {
    EditableGridModel.prototype.initialize.call(this, options);
    this.checkGroup = false; //pour indiquer que c'est un groupe spécial de regroupement (avec sous-groupes) ou non.
    if (options && options.checkGroup) {
      this.checkGroup = options.checkGroup;
    }
    if (options && _.isFunction(options.isEmptyRowSelection)) {
      this.isEmptyRowSelection = options.isEmptyRowSelection;
    }
    this.duplicateRows = false;
    if (options && options.duplicateRows) {
      this.duplicateRows = options.duplicateRows;
    }

    this.coll.off("row:dblclick");
    this.coll.on("row:dblclick", this._customManageRowEdition, this);

    this.coll.off("row:click");
    this.coll.on("row:click", this._customManageRowSelection, this);
  },

  _customManageRowEdition: function(model, options) {
    //if check Group
    if (this.checkGroup === true) {
      if ((options && options.force === true) || !this.isEmptyRowSelection(model)) { //from button "ajouter"  but is new
        EditableGridModel.prototype._manageRowEdition.call(this, model);
      }
      //disabled dblclick in other cases
    } else {
      EditableGridModel.prototype._manageRowEdition.call(this, model);
    }
  },

  _customManageRowSelection: function(model) {
    EditableGridModel.prototype._manageRowSelection.call(this, model);
    //if check Group
    if (this.checkGroup === true) {
      if (this.isEmptyRowSelection(model)) {
        this.btnBarModel.trigger("hide:delete");
        this.btnBarModel.trigger("hide:copy");
      } else {
        this.btnBarModel.trigger("show:delete");
        var checkedJours = _.filter(this.multiselectColl.models, function(model) { return !model.hasOwnProperty('isChecked'); });
        if (this.duplicateRows && checkedJours.length === 0) {
          this.btnBarModel.trigger("hide:copy");
        } else {
          this.btnBarModel.trigger("show:copy");
          this.btnBarModel.trigger("hide:delete");
        }
      }
      //disabled click in other cases
    }
  },

  isEmptyRowSelection: function(model) {
    // To be used it must be override.
    return false;
  },

  _manageMultiSelection: function() {
    EditableGridModel.prototype._manageMultiSelection.call(this);
    if (this.duplicateRows) {
      var checkedHoraires = _.filter(this.multiselectColl.models, function(model) { return model.hasOwnProperty('isChecked'); });
      if (checkedHoraires.length > 0) {
        this.btnBarModel.trigger("hide:copy");
        this.btnBarModel.trigger("show:delete");
      }
    }
  },
  /**
   * Overrides Management row ordering of editablegrid
   * if manageRowOrderByGroup===true, then we enable/disable ordering buttons depending position
   * inside current groupe (instead of checking position inside whole table).
   */
  _manageRowOrdering: function() {
    var self = this;
    if (_.isBoolean(this.manageRowOrderByGroup) && this.manageRowOrderByGroup === true) {
      if (this.btnBarModel && this.btnBarModel.get("rowOrdering") === true) {
        var value = this.get("value");

        var groupeValue = STR.getElValue(value, this.groupeByColumn);
        var groupeModels = [];
        _.each(this.coll.models, function(mod) {
          if (STR.getElValue(mod, self.groupeByColumn) === groupeValue) {
            groupeModels.push(mod);
          }
        });
        var index = _.indexOf(groupeModels, value);
        var length = groupeModels.length - 1;
        if (groupeModels.length > 1) {
          if (index === 0) {
            this.btnBarModel.trigger("moved:top");
          } else if (index === length) {
            this.btnBarModel.trigger("moved:bottom");
          } else {
            this.btnBarModel.trigger("moved:other");
          }
        } else {
          this.btnBarModel.trigger("moved:hide");
        }
      }
    } else {
      EditableGridModel.prototype._manageRowOrdering.call(this);
    }
  }
});