import { CompteursTableModel } from './compteursTable.model.js';
import { PaginatedCollection } from 'core/models/paginated.collection.js';

/**
 * Collection of Table Compteurs
 */
export var CompteursTableColl = PaginatedCollection.extend({

  dummy: "",
  /**
   * Model to use in the collection
   */
  model: CompteursTableModel,
  /**
   * The REST URL for this collection
   */
  url: function() {
    var url = Configuration.restRoot + "/rest/personnalisation/definition/brique/rescpt/" + encodeURIComponent(this.userid + "," + this.codebrique) + "/detail/";

    return url;
  },

  /**
   * Id Names keys for the Collection
   */
  idnames: ["rowid"]

});