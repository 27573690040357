import { BaseModel } from 'core/models/base.model.js';
import { STR } from '../../../../utils/str.js';
/**
 * Activate Model
 */
export var ActivateModel = BaseModel.extend({

  url: function() {
    var url = Configuration.restRoot + "/rest/personnalisation/confignav/accueil/brique/" + encodeURIComponent(this.userid + "," + this.codebrique) + "/" + this.action;
    if (!STR.isBlank(this.espaceid)) {
      url += "?espaceid=" + encodeURIComponent(this.espaceid);
    }
    return url;
  }

});