import { BaseModel } from 'core/models/base.model.js';
import { STR } from '../../../../utils/str.js';

/**
 * Model for load the elements with the general table (table left)
 */
export var ElementModel = BaseModel.extend({

  usecase: "droitsdel",

  /**
   * Default data model
   *		id : null,
   *		code : "",
   *		libelle : ""
   * }
   */
  defaults: {
    "id": null,
    "code": "",
    "libelle": ""
  },

  validate: function(attrs) {
    var errors = {};

    if (!_.isEmpty(errors)) {
      var errorMap = {
        errorValidation: STR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
    return null;
  }
});