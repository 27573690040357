import { DataGridModel } from 'core/grids/data_grid/dataGrid.model.js';
import { DataGridView } from 'core/grids/data_grid/dataGrid.view.js';
import { i18n } from '../../../../i18n.js';
import { STR } from '../../../../utils/str.js';
import { objs } from '../../../../objectsRepository.js';
import TPL_lanceetat_detailSelecteurRefDialog from '../detailSelecteurRefDialog.tpl.html';

/**
 * Selecteur Referentiel Dialog View
 */
export var SelecteurRefDialogView = Backbone.View.extend({

  tagName: "div",
  className: "0",
  searchTerm: "",

  initialize: function(options) {
    this.template = TPL_lanceetat_detailSelecteurRefDialog;

    if (!_.isUndefined(options) && !_.isUndefined(options.coll)) {
      this.coll = options.coll;
    }

    this.parent = options.parent;

    // Create table
    this.table = this._initTable(options.currentSelection);
    this.table.model.on("change:value", this.selection, this);
    this.table.model.multiselectColl.on("add", this.selection, this);
    this.table.model.multiselectColl.on("remove", this.selection, this);
    this.table.model.multiselectColl.on("reset", this.selection, this);
  },

  _manageTable: function() {
    this.table.model.resetRowHeight();

    var self = this;
    this.table.model.coll.applyFilter({ "search": this.searchTerm });
    try {
      this.table.model.coll.habContext.set("onglet", "home" + objs.appRt.workflow.get("zone"));
    } catch (e) {
      this.table.model.coll.habContext.set("onglet", "lanceetat");
    }
    this.table.model.coll.fetch({
      success: function() {
        if (!STR.isBlank(self.$el)) {
          self.$el.find(".referentielhabr-selecteurref-table legend span").text(self.table.model.coll.totalRecords);
        }
      }
    });
  },

  render: function() {
    var json = { 'i18n': i18n };
    this.$el.html(this.template(json));

    // Render table
    $(this.el).find(".table_selecteurref_referentielhabr").html(this.table.render().el);

    this._manageTable();

    var self = this;
    $(this.el).find(".criteres").autocomplete({
      minLength: 0,
      source: function(request) {
        self.searchTerm = request.term;
        self._manageTable();
        return false;
      }
    });

    if (this.parent.viewData.displayTitle !== true) {
      this.$el.find(".habr-selecteur-view-size legend").hide();
      this.$el.find(".phx-grid-header-scroll").hide();
      this.$el.find(".phx-grid-main-chooser").hide();
      this.$el.find(".phx-grid-scroll").css("margin-top", "38px");
    }

    return this;
  },

  selection: function() {
    var selection = this.table.getSelectedRows();
    this.trigger("response", selection);
    return selection;
  },

  _selectionAndClose: function() {
    this.trigger("close", "Y");
  },

  _initTable: function(currentSelection) {

    var tableModel = new DataGridModel({
      coll: this.coll,
      enableSelectionOnDblclick: true
    });

    var tableColumns = [{
      title: i18n.t('habr.table_selectref_code'),
      property: "code",
      width: 120
    }, {
      title: i18n.t('habr.table_selectref_libelle'),
      property: "libelle",
      width: 300
    }];

    var table = new DataGridView({
      id: this.parent.viewData.name + "_selecteurref_table",
      columns: tableColumns,
      model: tableModel,
      title: i18n.t('habr.legend_selectref_table'),
      showFilteredRowsInTitle: true,
      multiselection: this.parent.viewData.multiselection,
      gridHeightType: "auto",
      parentContainer: this.parent
    });

    table.width = 750;
    table.height = 250;

    if (this.parent.viewData.displayAllColumns === true) {
      table.setVisibleColumns(["code", "libelle"]);
    } else {
      var visibleColumns = new Array();

      if (!STR.isBlank(this.parent.viewData.displayColumns)) {
        _.each(this.parent.viewData.displayColumns, function(item) {
          visibleColumns.push(item);
        });
      }

      table.setVisibleColumns(visibleColumns);
    }

    if (currentSelection && currentSelection instanceof Backbone.Collection) {
      currentSelection.forEach(function(element) {
        table.model.multiselectColl.add(element.get("attrs"), { parse: true });
      });
    }
    return table;
  }
});
