import { CriteresModel } from './criteres.model.js';
import { PaginatedCollection } from 'core/models/paginated.collection.js';

/**
 * Collection of etats for the rigth side.
 */
export var CriteresColl = PaginatedCollection.extend({

  usePopulation: true,

  model: CriteresModel,

  version: "2.2.2",

  url: function() {
    var criteresPath = Configuration.restRoot + "/rest/edition/definition/" + encodeURIComponent(this.id) + "/critere?thcollab=false";
    if (this.thcollab && this.thcollab === true) {
      criteresPath = Configuration.restRoot + "/rest/edition/definition/" + encodeURIComponent(this.id) + "/critere?thcollab=true"
    }
    return criteresPath;
  },

  initialize: function() {
    PaginatedCollection.prototype.initialize.call(this);
    this.sortings = { libelle: true };
    this.paginated = false;
  },

  parse: function(response) {
    if (response.id === undefined && response.code !== undefined) {
      response.id = response.code;
    }
    return response;
  }
});