import { GLOBAL_DATA } from '../../../../../globalData.js';
import { HABILITATION } from '../../../../../utils/habilitation.js';
import { HabilitationContext } from '../../../../../core/models/habilitationContext.js';
import { i18n } from '../../../../../i18n.js';
import { NAV } from '../../../../../utils/nav.js';
import { objs } from '../../../../../objectsRepository.js';
import { SituationModel } from '../models/situation.model.js';
import { STR } from '../../../../../utils/str.js';
import { STYLE } from '../../../../../utils/style.js';
import { TYPE } from '../../../../../tda/tda.js';
import TPL_bsitpart_briqueSituation from '../briqueSituation.tpl.html';

/**
 * View of Brique 'Situations particulières'
 */
export var SituationView = Backbone.View.extend({

  /**
   * Event launched when link date at table is clicked
   *
   */
  //REVIEW: 	i18n : phx.i18n.common.bsitpart,

  _maxLines: 10,

  /**
   * Constructor of this class
   */
  initialize: function(options) {
    this.usePopulation = false;
    if (options && options.usePopulation) {
      this.usePopulation = options.usePopulation;
    }
    this.template = TPL_bsitpart_briqueSituation;
    //this.tablebriqueSituation = null;

    this.styleBold = "phx-bold-input-style";
    this.styleNormal = "phx-input-style";
    this.parent = null;

    this.model = new SituationModel();
    this.model.usePopulation = this.usePopulation;

    this.habContext = new HabilitationContext({
      onglet: "bsitpart",
      fonc_cour: "COL_ANO.V",
      nat_gest: ""
    });
    this.model.setHabContext(this.habContext);

    if (options && options.parent) {
      this.parent = options.parent;
    }

    // param divers to control max lines in the "brique."
    // Nombre maximum d'éléments à charger dans une brique d'accueil
    this.paramMaxElements = GLOBAL_DATA.paramDivers.get("BRCnbElt").get("valeur");

  },

  events: {
    "click .table_BriqueSituationsParticulieres_link": "_linkToValidation"
  },

  /**
   * Renders this brique
   */
  render: function() {
    var json = { 'i18n': i18n };
    $(this.el).html(this.template(json));
    this.$el.attr("aria-busy", "true");
    $(this.el).attr("aria-live", "polite");
    $(this.el).find(".table_BriqueSituation").html(i18n.t('common:bsitpart.noSituationAffiche'));

    return this;
  },

  /**
   * Refreshes all information inside the brique fetching again information
   */
  refreshBrique: function() {
    $(this.el).find(".table_BriqueSituation").html(i18n.t('common:bsitpart.noSituationAffiche'));

    var self = this;

    self.model.fetch({
      success: function(fresh) {
        self.parent.setTitle(i18n.t('common:bsitpart.situation') + " (" + fresh.get("situation").length + "/" + fresh.get("total") + ")");
        self.renderTable();
      }
    });

    this.delegateEvents();

    return this;
  },

  /**
   * Render the table inside the brique
   */
  renderTable: function() {
    //render the collection is in 'situation'
    var coll = this._renderCollection(this.model.get("situation"));

    if (this.model.get("situation").length > 0) {
      //create the columns for the table
      var tblColumns = [{
        title: "",
        property: "type",
        width: 10
      }, {
        title: "",
        property: "date",
        width: 140
      }, {
        title: "",
        property: "heure",
        width: 50
      }, {
        title: "",
        property: "libelle"
      }];
      //create the table witdh the collection data and collection titles
      var table = this._createTableOfColl(coll, tblColumns, "tableBriqueSituation");

      $(this.el).find(".table_BriqueSituation").html(table);

      var maxElemSize = coll.length;

      if (parseInt(this.paramMaxElements) < maxElemSize) {
        this.parent.setTitle(i18n.t('common:bsitpart.situation') + " (" + this.paramMaxElements + "/" + this.paramMaxElements + ")");
      }

      var rowHeight = $(this.el).find(".table_BriqueSituation tr").outerHeight();

      //Value for Default
      if (rowHeight < 5) {
        rowHeight = 19;
      }

      if (rowHeight !== null && this.parent !== null) {
        this.parent.setContentHeight(rowHeight * this._maxLines);
      }
    }
  },

  /**
   * Adds style to text
   */
  _textToStyle: function(txt, style) {
    var newTxt = "";
    newTxt = "<span class='" + style + "'>" + txt + "</span>";

    return newTxt;
  },

  /**
   * Prepares each model in the collection, adapting its attributes to html that will be used in table
   */
  _renderCollection: function(collection) {
    var self = this;
    _.each(collection, function(obj) {
      //render the date
      var dateFormated = TYPE.DATE.format(obj.date, "Dy " + TYPE._getFormatByCode(TYPE.DATE.DEFAULT_MASK_CODE));
      //obj.date = dateFormated;
      var matric = objs.appRt.workflow.authModel.get("matricule");
      var classLink = "";
      var style = "";

      // The link depends of the agenda's habilitations
      if (HABILITATION.canView("COL_AGHEBDO") === true) {
        classLink = "agenda/" + obj.date + "/" + matric + "/H";
      } else if (HABILITATION.canView("COL_AGANNU") === true) {
        classLink = "agenda/" + obj.date + "/" + matric + "/A";
      } else if (HABILITATION.canView("COL_AGMENS") === true) {
        classLink = "agenda/" + obj.date + "/" + matric + "/M";
      } else {
        style = "cursor:default;";
      }
      // The text depends of the type of calcul
      var textLink;
      if (obj.calcul == "P") {
        textLink = dateFormated + " " + i18n.t('common:bsitpart.prevue');
      } else {
        textLink = dateFormated;
      }
      obj.date = "<span class='" + classLink + "' style='" + style + "'>" + textLink + "</span>";
      //render the heure
      var heureFormated = TYPE.HOUR_MINUTE.format(obj.heure);
      obj.heure = heureFormated;
      //render the libelle
      var libelle = "";
      var strFormated = "";

      libelle = self._textToStyle(obj.libelle, self.styleBold);

      if (!STR.isBlank(obj.commentaire)) {
        strFormated = libelle + ", " + obj.commentaire;
      } else {
        strFormated = libelle;
      }
      obj.libelle = strFormated;

      var className = "";
      var tooltip = "";
      if (!STR.isBlank(obj.type))
        className = STYLE.getClassColorFromAnomalie(obj.type);
      tooltip = self._getTitleFromAnomalie(obj.type);
      if (obj.type === "B" || obj.type === "P" || obj.type === "N") {
        //TODO: Pass to the good way of create the html
        //obj.type = $("<span>").addClass(className).attr("title", tooltip).css("font-weight","bold").html("!");
        obj.type = "<span class='" + className + "' style='font-weight:bold;' title='" + tooltip + "'>!</span>";
      }
    });
    return collection;
  },

  /**
   * Gets type of anomalie depending on Type of anomalie
   */
  _getTitleFromAnomalie: function(anocode) {
    var title = "";
    switch (anocode) {
      case "B":
        title = i18n.t('common:bsitpart.bloqueante');
        break;
      case "P":
        title = i18n.t('common:bsitpart.persistante');
        break;
      case "N":
        title = i18n.t('common:bsitpart.non_bloqueante');
        break;
    }
    return title;
  },

  /**
   * Creates the HTML of the table from the data
   */
  _createTableOfColl: function(coll, tblColumns, id) {
    var showTitle = false;
    var table = "";
    var maxElemSize = coll.length;

    table = "<table id='" + id + "' class='ui-grid-table phx-grid ui-grid'>";

    //paint the titles
    if (showTitle) {
      table += "<thead class='ui-grid-header'>";
      table += "<tr>";
      _.each(tblColumns, function(column, index) {
        table += "<th class='phx-th" + index + " ui-grid-th " +
          "ui-grid-th-RightBorder'style='width:" + column.width + "px;'>";
        table += "<div style='text-align:left'><span>" + column.title + "</span></div>";
        table += "</th>";

      });
      table += "</tr>";
      table += "</thead>";
    }
    //paint the data
    table += "<tbody class='ui-grid-body'>";

    if (this.paramMaxElements < maxElemSize) {
      maxElemSize = this.paramMaxElements;
    }

    for (var i = 0; i < maxElemSize; i++) {
      table += "<tr class='ui-grid-row'>";
      for (var j = 0; j < tblColumns.length; j++) {
        var row_class = "phx-td";
        if (tblColumns[j].property == "date" && coll[i].calcul == "C") {
          //When anotypeCal=C date is a link to agenda hebdomadaire
          row_class = "table_BriqueSituationsParticulieres_link " + row_class;
        }
        if (tblColumns[j].width === undefined) {
          table += "<td class='" + row_class + j + "'>";
        } else {
          table += "<td class='" + row_class + j + "' style='width:" + tblColumns[j].width + "px;'>";
        }

        table += coll[i][tblColumns[j].property];
        table += "</td>";
      }
      table += "</tr>";
    }
    table += "</tbody>";
    return table;
  },

  /**
   * Opens vue journee panel at anomalie screen
   */
  _linkToValidation: function(event) {
    var target = event.target;
    var ecran = target.className.split(" ")[0];

    NAV.navigate("coll", ecran, "uc", true, true);
  }
});