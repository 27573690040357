import { BaseModel } from 'core/models/base.model.js';


export var DialogModel = BaseModel.extend({

  canViewTreatment: true,

  url: Configuration.restRoot + "/rest/badgvirtuel",

  initialize: function(options) {
    if (options && options.matricule) {
      this.matricule = options.matricule;
    }
    if (options && options.sens) {
      this.sens = options.sens;
    }
    if (options && options.fonction) {
      this.fonction = options.fonction;
    }
  }
});