
import { PaginatedCollection } from 'core/models/paginated.collection.js';
import { SuvietatModel } from './suvietat.model.js';

/**
 * Collection for the main table
 */
export var SuvietatColl = PaginatedCollection.extend({

  model: SuvietatModel,

  url: Configuration.restRoot + "/rest/edition",

  initialize: function() {
    PaginatedCollection.prototype.initialize.call(this);
    this.sortings = { datedebut: false, heuredebut: false };
  }
});