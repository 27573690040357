module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<div class="bvMenu" aria-labeledby="bvMenuHButtonBadge">\n  <span class="bvMenuHeader1 phx-menu" style="color:green;" tabindex="0">\n		<span class="bvMenuHButtonBadge phx-menu-badgeage phx-hover ui-corner-all"></span>\n  </span>\n  <span class="bvMenuHeader2" tabindex="0">\n		<span class="bvMenuHButtonRentre phx-menu-badgeage phx-hover ui-corner-all">'+
((__t=(i18n.t('common:bvMenu_rentre')))==null?'':__t)+
'</span> &nbsp;\n  <span class="bvMenuHButtonSors phx-menu-badgeage phx-hover ui-corner-all">'+
((__t=(i18n.t('common:bvMenu_sors')))==null?'':__t)+
'</span> &nbsp;\n  <span class="bvMenu_heure"></span>\n  </span>\n  <span class="bvMenuButton phx-menu-icon ui-corner-all ui-icon ui-icon-triangle-1-s phx-hover" tabindex="0" role="menu"></span>\n  <ul class="bvMenuContent"></ul>\n</div>\n';
}
return __p;
};
