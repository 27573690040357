module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="phx-zone-selector">\n</div>\n<div class="phx-right-menu">\n  <div id="phx-account">\n    <span class="phx-acount-span">\n			<span class="phx-account-username">'+
((__t=(auth.login))==null?'':__t)+
'</span>\n    </span>\n    <div id="phx-user-menu"></div>\n    <div id="phx-profile-selector"></div>\n    <span class="phx-acount-span">\n			|&nbsp;<a href="javascript:void(0)">\n        '+
((__t=(i18n.t('app.logout')))==null?'':__t)+
'\n        </a>\n		</span>\n\n  </div>\n  <div id="phx-menu-bar"></div>\n</div>\n\n<div id="phx-logo" role="banner"></div>\n';
}
return __p;
};
