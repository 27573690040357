module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<div class="calendacc-customBar" style="min-width:826px">\n  <div>\n    <button type="button" class="phx-icon-prev">'+
((__t=(i18n.t('calendacc.annoLast')))==null?'':__t)+
'</button>\n    <label for="year_id" style="display:none" aria-hidden="false">'+
((__t=(i18n.t('calendacc.year')))==null?'':__t)+
'</label>\n    <input type="text" class="year_id year typeShort" value="" />\n    <button type="button" class="phx-icon-next">'+
((__t=(i18n.t('calendacc.annoNext')))==null?'':__t)+
'</button>\n  </div>\n</div>';
}
return __p;
};
