import { i18n } from '../../../../i18n.js';
import { STR } from '../../../../utils/str.js';
import TPL_droitsdel_tooltipDeleg from '../tooltipDeleg.tpl.html';

export var TooltipDelegView = Backbone.View.extend({

  /**
   * Constructor
   * Toooltip for Fonc View
   */
  initialize: function(options) {
    this.template = TPL_droitsdel_tooltipDeleg;
    if (options && options.model) {
      this.model = options.model;
    }
  },

  /**
   * Renders the view to the form
   */
  render: function() {
    var model = this.model;
    var json = { "i18n": i18n };
    this.$el.html(this.template(json));
    var resp = "";

    if (model.get("vue") === true && model.get("filtres")) {
      for (var i = 0; i < model.get("filtres").length; i++) {
        resp += model.get("filtres")[i].libelle;
        if (i < model.get("filtres").length - 1) {
          resp += "<br/>";
        }
      }
      $(this.el).find("span.filtres").html(resp);
      $(this.el).find("span.filtres-content").show();
    } else {
      $(this.el).find("span.filtres-content").hide();
    }

    if (STR.isBlank(model.get("profilwkf"))) {
      $(this.el).find("span.profil-content").hide();
    } else {
      $(this.el).find("span.profil").html(model.get("profilwkf"));
      $(this.el).find("span.profil-content").show();
    }

    if (model.get("ajout") || model.get("modification") || model.get("suppression")) {
      resp = "";
      if (model.get("ajout")) {
        resp += i18n.t('droitsdel.creer') + ",";
      }
      if (model.get("modification")) {
        resp += i18n.t('droitsdel.modifier') + ",";
      }
      if (model.get("suppression")) {
        resp += i18n.t('droitsdel.supprimer') + ",";
      }

      resp = resp.substring(0, resp.length - 1);

      $(this.el).find("span.actions").html(resp);
      $(this.el).find("span.actions-content").show();
    } else {
      $(this.el).find("span.actions-content").hide();
    }

    return this;
  }

});