module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="btnBar-btn-container" style="display:inline-block;">\n  <button type="button" class="save" value="save">'+
((__t=(i18n.t("common:save")))==null?'':__t)+
'</button>\n</div>\n<div class="btnBar-btn-container" style="display:inline-block;">\n  <button type="button" class="revert ui-button-special" value="revert">'+
((__t=(i18n.t("common:revert")))==null?'':__t)+
'</button>\n</div>\n&nbsp;&nbsp;&nbsp;\n<div class="btnBar-btn-container" style="display:inline-block;">\n  <button type="button" class="new ui-button-special" value="new">'+
((__t=(i18n.t("common:nw")))==null?'':__t)+
'</button>\n</div>\n<div class="btnBar-btn-container" style="display:inline-block;">\n  <button type="button" class="delete ui-button-special" value="delete">'+
((__t=(i18n.t("common:del")))==null?'':__t)+
'</button>\n</div>\n<div class="btnBar-btn-container" style="display:inline-block;">\n  <button type="button" class="copy ui-button-special" value="copy">'+
((__t=(i18n.t("common:copy")))==null?'':__t)+
'</button>\n</div>\n';
}
return __p;
};
