import { HABILITATION } from '../../utils/habilitation.js';
import { HEADERS } from '../../utils/headers.js';
import { objs } from '../../objectsRepository.js';
import { STR } from '../../utils/str.js';

/**
 * BaseCollection : base collection to use instead of the backbone collection
 *
 */
export var BaseCollection = Backbone.Collection.extend({
  /**
   * Initializes the special attributes of this collection
   *
   */
  dummy: "",

  /**
	 * Checks habilitations and if they are correct, fetches the collection

     *  responds fetch without errors
	 */
  fetch: function(options) {
    var l_options = options ? _.clone(options) : {};

    if (!STR.isBlank(this.version)) {
      var currentVersion = _.isObject(this.version) ? this.version.GET : this.version;
      this.setHeaders(l_options, HEADERS.versionContext(currentVersion));
    }

    if (this.activeSimulation === true) {
      this.prepareSimulation(l_options);
    }

    if (this.habContext) {
      this._prepareEcranHeader(l_options);
      this.setHeaders(l_options, this.habContext.header());
      if (this.usePopulation === true) {
        this.preparePopulation(l_options);
      }

      return Backbone.Collection.prototype.fetch.call(this, l_options);

    } else {
      this._checkHabilitations();
      this._prepareEcranHeader(l_options);

      if (this.habilitationV === "N" || HABILITATION.canView(this.habilitationV)) {
        if (this.usePopulation === true) {
          this.preparePopulationAndHabiliation(l_options);
        } else {
          this.setHeaders(l_options, HEADERS.habilitationContext(this.usecase, this.habilitationV));
        }

        return Backbone.Collection.prototype.fetch.call(this, l_options);

      } else {
        if (Configuration.development === true && STR.isBlank(this.habilitationV)) {
          throw new Error("The habilitation is not properly configured");
        }

        if (l_options.success) {
          l_options.success(this, null, l_options);
        }
      }
    }
    return $.Deferred().resolve();
  },

  /**
   * Checks that habilitationV, habilitationG and usecase are informed. If they are not an error is shown
   */
  _checkHabilitations: function() {
    if (STR.isBlank(this.habilitationV)) {
      throw new Error("View Habilitation must be defined or set to empty string if not used.");
    }
    if (STR.isBlank(this.habilitationG)) {
      throw new Error("Management Habilitation must be defined or set to empty string if not used.");
    }
    if (STR.isBlank(this.usecase)) {
      throw new Error("Usecase name must be defined.");
    }
  },

  /**
   * Includes population Ident and Type in header
   */
  preparePopulationAndHabiliation: function(options) {
    var popId = "0",
      popType = null,
      popCode = null,
      popNat = null,
      popDesc = null;
    if (!_.isUndefined(this.popId) && !STR.isBlank(this.popType)) {
      popId = this.popId;
      popType = this.popType;
      popCode = this.popCode;
      popNat = this.popNat;
      popDesc = this.popDesc;
    } else if (!STR.isBlank(objs.populationMenu) && !STR.isBlank(objs.populationMenu.model)) {
      popId = objs.populationMenu.model.get("ident");
      popType = objs.populationMenu.model.get("type");
      popCode = objs.populationMenu.model.get("code");
      popNat = objs.populationMenu.model.get("nature");
      popDesc = objs.populationMenu.model.get("desc");
    }

    this.setHeaders(options, HEADERS.habilitationContext(this.usecase, this.habilitationV));

    if (STR.isBlank(popId)) {
      popId = "0"; // by default we force gfi-population at "0"
    }
    if (STR.isBlank(popNat)) {
      popNat = "";
    } else if (popNat === "M") {
      popNat = "C";
    }
    if (STR.isBlank(popCode)) {
      popCode = "";
    }
    if (STR.isBlank(popDesc)) {
      popDesc = "";
    }
    if (STR.isBlank(popType)) {
      popType = "D";
    }
    this.setHeaders(options, HEADERS.populationContext(popId, popType, popCode, popNat, popDesc));
  },

  /**
   * Includes population Ident and Type in header
   */
  preparePopulation: function(options) {
    var popId = null,
      popType = null,
      popCode = null,
      popNat = null,
      popDesc = null;

    var populationModel = objs.populationMenu ? objs.populationMenu.model : null;

    if (!_.isUndefined(this.popId)) {
      popId = this.popId;
    } else if (populationModel && populationModel.get("ident")) {
      popId = populationModel.get("ident");
    }
    if (!STR.isBlank(this.popType)) {
      popType = this.popType;
    } else if (populationModel && populationModel.get("type")) {
      popType = populationModel.get("type");
    }
    if (!STR.isBlank(this.popCode)) {
      popCode = this.popCode;
    } else if (populationModel && populationModel.get("code") && !STR.isBlank(populationModel.get("nature"))) {
      popCode = populationModel.get("code");
    }
    if (!STR.isBlank(this.popNat)) {
      popNat = this.popNat;
    } else if (populationModel) {
      popNat = populationModel.get("nature");
    }
    if (!STR.isBlank(this.popDesc)) {
      popDesc = this.popDesc;
    } else if (populationModel) {
      popDesc = populationModel.get("desc");
    }

    if (STR.isBlank(popId)) {
      popId = "0"; // by default we force gfi-population at "0"
    }
    if (STR.isBlank(popNat)) {
      popNat = "";
    } else if (popNat === "M") {
      popNat = "C";
    }
    if (STR.isBlank(popCode)) {
      popCode = "";
    }
    if (STR.isBlank(popType)) {
      popType = "D";
    } else if (popType === "M") {
      popType = "D";
    }
    this.setHeaders(options, HEADERS.populationContext(popId, popType, popCode, popNat, popDesc));
  },

  prepareSimulation: function(options) {
    var sim = !STR.isBlank(this.simulationData) ? this.simulationData : objs.simulationModel;
    if (!STR.isBlank(sim)) {
      var datedeb = sim.get("datedeb");

      var passreel = false
      if (objs.ctxAffReelPasse === true) {
        passreel = true
      }

      this.setHeaders(options, HEADERS.simulationContext(sim.get("code"), datedeb, sim.get("datefin"), passreel));
    }
  },

  /**
   * Prepares model header for server calls
   */
  _prepareEcranHeader: function(options) {
    if (!STR.isBlank(this.ecran)) {
      this.setHeaders(options, HEADERS.ecranContext(this.ecran));
    }
  },

  /**
   * Adds options.header to current header
   *  responds without errors
   *  responds with an error
   */
  setHeaders: function(options, header) {
    if (options.headers) {
      options.headers = _.extend(options.headers, header);
    } else {
      options.headers = header;
    }
  },
  /**
   * Sets collection's habilitations and usecase to values passed
   */
  setHabilitation: function(V, G, usecase) {
    if (!STR.isBlank(V)) {
      this.habilitationV = V;
    }
    if (!STR.isBlank(G)) {
      this.habilitationG = G;
    }
    if (!STR.isBlank(usecase)) {
      this.usecase = usecase;
    }
  },

  setHabContext: function(habContext) {
    this.habContext = habContext;
  },

  setVersion: function(version) {
    this.version = version;
  },

  updateHabContext: function(attributes) {
    if (this.habContext) {
      this.habContext.update(attributes);
    }
  },

  getHabContext: function() {
    return this.habContext;
  },

  /**
   * Copies current attributes to the property oldAttributes. This property can be accessed in the future to revert changes
   */
  store: function() {
    this.oldModels = _.clone(JSON.stringify(this.toJSON()));
  },

  /**
   * Sets current model attributes to values that were stored (oldAttributes).
   */
  revert: function() {
    if (this.oldModels) {
      this.reset(this.parse(JSON.parse(this.oldModels)));
    }
  }
});
