import { BaseModel } from 'core/models/base.model.js';
import { STR } from '../../../../utils/str.js';
import { TYPE } from '../../../../tda/tda.js';

/**
 * Model of Data of SearchListeGroupes
 */
export var SearchListeGroupesData = BaseModel.extend({

  defaults: {
    "groupe": "",
    "date": "",
    "demande": false,
    "souhait": false
  },

  validate: function(attrs) {
    var errors = {};

    if (!STR.isBlank(attrs.date)) {
      var errorMsg = TYPE.DATE.validate(attrs.date);
      if (!STR.isBlank(errorMsg)) {
        errors.date = errorMsg;
      }
    }

    if (!_.isEmpty(errors)) {
      var errorMap = {
        errorValidation: STR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
    return null;
  }
});
