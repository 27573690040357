import { BaseModel } from 'core/models/base.model.js';
import { BlockView as CommonBlockView } from 'core/components/collapsible_block/block.view.js';
import { DialogView } from 'core/views/dialog.view.js';
import { FileTransferView } from 'core/components/file_transfer/fileTransfer.view.js';
import { GererPiecesJointesView } from '../../evenements/gerer/gererpiecesjointes/views/gererPiecesJointes.view.js';
import { HABILITATION } from '../../../../utils/habilitation.js';
import { HabilitationContext } from 'core/models/habilitationContext.js';
import { i18n } from '../../../../i18n.js';
import { LOG } from '../../../../utils/log.js';
import { PiecesJointesColl } from '../../evenements/gerer/gererpiecesjointes/models/piecesJointes.collection.js';
import { RemoteFileModel } from 'core/models/remoteFile.model.js';
import { STR } from '../../../../utils/str.js';
import TPL_piecesjointes_composant from '../composant.tpl.html';

/**
 * View for the pieces jointes component
 */
export var BlockView = CommonBlockView.extend({

  initialize: function(options) {
    var opt = {
      hSeparator: false,
      expanded: false,
      component: "piecesjointes",
      detailTemplate: TPL_piecesjointes_composant,
      buttons: []
    };

    if (options) {
      if (options.context) {
        this.context = options.context;
      }
      if (options.type) {
        this.type = options.type;
      }
      if (options.hSeparator) {
        opt.hSeparator = options.hSeparator;
      }
      if (!_.isUndefined(options.maxPieces)) {
        this.maxPieces = options.maxPieces;
      }
      if (options.popupGerer) {
        opt.hSeparator = options.popupGerer;
      }
    }

    if (STR.isBlank(this.type)) {
      throw new Error("A 'type' should be defined for this component");

    }
    this.model = new BaseModel({
      value: new PiecesJointesColl()
    });

    this.piecesJointesList = [];
    this.fileTransferList = [];

    CommonBlockView.prototype.initialize.call(this, opt);
  },

  events: _.extend({
    "click div.phx-piece-jointe span.piece-jointe-file": "_manageFileClick"
  }, CommonBlockView.prototype.events),

  // override default function
  canAccess: function() {
    var manage = this.context && this.context.ctxHabilitation &&
      this.context.ctxHabilitation.HabilitationAcces &&
      HABILITATION.canView(this.context.ctxHabilitation.HabilitationAcces);

    return manage;
  },

  // override default implementation to use this.context.ctxActionsPossibles contains "Modifier"
  canManage: function() {
    var manage = this.context && this.context.ctxHabilitation &&
      this.context.ctxHabilitation.HabilitationGestion &&
      HABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion);

    if (manage && this.context.ctxActionsPossibles && this.context.ctxActionsPossibles.indexOf("Creer") != -1) {
      return true;
    } else {
      return false;
    }

  },

  setContext: function(context) {
    this.context = context;

    this.buttons.pop();

    this._manageBlocEstDeplie();
    this.showBlock();
  },

  setDescription: function(description) {
    this.description = description;
  },

  setPiecesJointes: function(list) {
    var self = this;

    this.piecesJointesList.length = 0;

    _.each(list, function(item) {
      var obj = {};
      obj.code = item.code;
      obj.libelle = item.libelle;
      obj.description = item.description;
      obj.mime = item.mime;
      obj.id = item.code;
      obj.reelNom = item.reelNom;
      this.piecesJointesList.push(obj);
    }, this);

    if (this.canManage() && this.maxPieces > 0) {
      this.buttons.push({ id: "add", text: i18n.t('common:piecesjointes.add_button') });
    }

    if (this.piecesJointesList) {
      this._generateContent(this.piecesJointesList);
    } else {
      this.setTitle(this._generateTitle(0));
    }

  },

  applyPendingActions: function(evenement, callback, keepFileTransferState) {
    try {
      //Customer 145490
      var isModification = this.piecesJointesList.length >= 2 ? this.piecesJointesList[0].code : -1;
      var pieceDelete = _.filter(this.piecesJointesList, function(ele) {
        return ele.deleted === true;
      });

      var self = this;

      if (pieceDelete) {
        // first step is to remove deleted pieces jointes
        _.each(pieceDelete, function(item) {
          if (item.deleted === true) {
            var model = new RemoteFileModel();
            //model.id = item.code;
            // EVO piece jointe - Evolution pour les pièces jointes (14149)
            model.id = item.code + "," + self.context.ctxRefEvenement + "," + self.type;
            model.setHabContext(new HabilitationContext({
              onglet: this.context.ctxEcran,
              fonc_cour: this.context.ctxHabilitation.HabilitationGestion,
              nat_gest: "S"
            }));
            model.destroy();
          }
        }, this);
      }

      // second step is to add new pieces jointes
      if (this.fileTransferList.length > 0) {
        var copyFileTransfer = _.clone(this.fileTransferList);
        if (keepFileTransferState) {
          _.each(this.fileTransferList, function(fileTransfer) {
            fileTransfer.setMultipleTransfer();
          });
        }
        this._recursiveSumbits(evenement, this.fileTransferList.length - 1, callback, isModification, keepFileTransferState, copyFileTransfer);

      } else {
        if (callback) {
          callback();
        }
      }
    } catch (e) {
      for (var i = this.fileTransferList.length - 1; i >= 0; i--) {
        this.fileTransferList[i].remove();
        this.fileTransferList.splice(i, 1);
      }

      if (callback) {
        callback();
      }
    }
  },

  _recursiveSumbits: function(evenement, cont, callback, isModification, keepFileTransferState, copyFileTransfer) {
    var self = this;
    var i = cont;
    //		for(var i = cont; i>=0 ; i--){
    if (i >= 0) {

      this._submitPieceJointe(evenement, this.fileTransferList[i], function(cid, hasError, keepFileTransferState) {
        // destroy view when transfer is finished
        for (var i = cont; i >= 0; i--) {
          if (self.fileTransferList[i].cid === cid) {
            self.fileTransferList[i].remove();
            self.fileTransferList.splice(i, 1);
            break;
          }
        }
        //if does not have error the recursivity should continue
        if (!hasError) {
          self._recursiveSumbits(evenement, i - 1, callback, isModification, keepFileTransferState, copyFileTransfer);
        }
        if (self.fileTransferList.length == 0 || hasError) {
          if (keepFileTransferState == true) { //Restore filetransfer if keepfiletransfer is true
            self.fileTransferList = copyFileTransfer;
          }
          if (callback) {
            callback();
          }
        }
      }, isModification, keepFileTransferState);
      //		}
    }
  },

  _submitPieceJointe: function(evenement, fileTransfer, callback, isModification, keepFileTransferState) {
    if (fileTransfer && fileTransfer.shouldSubmit()) {
      fileTransfer.model.off("successUpload");
      fileTransfer.model.off("errorUpload");
      fileTransfer.model.off("progressUpload");

      fileTransfer.model.on("successUpload", function(event) {
        LOG.debug("success");
        if (callback) {
          callback(fileTransfer.cid, false, keepFileTransferState);
        }
      }, this);

      fileTransfer.model.on("errorUpload", function(event) {
        LOG.debug("error");
        if (callback) {
          callback(fileTransfer.cid, true, keepFileTransferState);
        }
      }, this);

      fileTransfer.model.on("progressUpload", function(progress) {
        if ($('html').is('.ie6, .ie7, .ie8, .ie9')) {
          this.setTitle(i18n.t('common:piecesjointes.progressText') + '<img src="img/loading.gif" />');
        } else {
          this.setTitle(i18n.t('common:piecesjointes.progressText') + progress);
        }
      }, this);

      if ($('html').is('.ie6, .ie7, .ie8, .ie9')) {
        this.setTitle(i18n.t('common:piecesjointes.progressText') + '<img src="img/loading.gif" />');
        this.$el.append(fileTransfer.el);
        fileTransfer.$el.hide();
      }

      fileTransfer.submit(evenement, isModification);
    } else {
      if (callback) {
        callback(fileTransfer.cid, keepFileTransferState);
      }
    }
  },

  _contextToFilter: function() {
    var params = {};
    return params;
  },

  _getVisibleItems: function(list) {
    var nbItems = 0;
    _.each(list, function(item) {
      if (item.deleted !== true) {
        nbItems++;
      }
    }, this);
    return nbItems;
  },

  _generateTitle: function(nbItems) {
    // #customer 146922  - changed to "<=" maxPieces && nbItems > 0
    if (nbItems <= this.maxPieces && this.maxPieces > 0 && nbItems > 0) {
      if (this.buttons && this.buttons.length == 1) {
        this.buttons[0].text = i18n.t('common:piecesjointes.gerer_button');
      }

      this.showIcon();
      if (nbItems === 1) {
        return i18n.t('common:piecesjointes.piecejointe');
      } else {
        return i18n.t('common:piecesjointes.piecesjointes', { "0": nbItems });
      }
    } else if (nbItems === 0) {

      if (this.buttons && this.buttons.length == 1) {
        this.buttons[0].text = i18n.t('common:piecesjointes.add_button');
      }
      this.hideIcon();
      return i18n.t('common:piecesjointes.aucune');
    }
  },

  _generateContent: function(collection) {
    var self = this;
    this.setTitle(self._generateTitle(this._getVisibleItems(collection)));
    if (collection.length > 0) {
      var div = $("<div>");
      this._addPieceJointeLine(collection, 0, div, function(html) {
        self.setContent(html);
      });
    }
  },

  _addPieceJointeLine: function(collection, i, htmlContent, contentCallback) {
    if (collection.length > i) {
      var model = collection[i];

      if (model.deleted !== true) {
        var div = $("<div>");
        div.addClass("phx-piece-jointe");
        div.addClass(i);

        this._formatPieceJointe(model, div);

        htmlContent.append(div);
      }

      this._addPieceJointeLine(collection, ++i, htmlContent, contentCallback);

    } else {
      contentCallback(htmlContent);
    }
  },

  _formatPieceJointe: function(model, div) {

    var file = $("<span>");

    try {
      var libelle = model.reelNom ? model.reelNom : model.libelle.substring(model.libelle.lastIndexOf("_") + 1);
      file.attr("data-code", model.code);
      file.attr("title", model.description);
      file.addClass("piece-jointe-file");
      file.html(libelle);
    } catch (e) {
      file.html(model.reelNom ? model.reelNom : model.libelle);
    }

    var icon = $("<span>");

    if (model.code != null) {
      icon.addClass("piece-jointe-icon");
      icon.addClass(this.mimeClass(model.mime));
    } else {
      icon.addClass("piece-jointe-icon ui-icon");
      file.html(model.libelle + i18n.t('common:piecesjointes.pendingUpload'));
    }

    div.html(icon);
    div.append(file);
  },

  mimeClass: function(mime) {
    switch (mime) {
      case "doc":
      case "docx":
        return "phx-icon-doc";
      case "pdf":
        return "phx-icon-pdf";
      case "png":
      case "bmp":
      case "jpg":
      case "jpeg":
        return "phx-icon-img";
      case "xls":
        return "phx-icon-xls";
      default:
        return "ui-phx-piece-jointe-icon";

    }
  },

  // open file in another navigator tab
  _manageFileClick: function(event) {
    var code = $(event.target).attr("data-code");

    if (code) {
      window.open(Configuration.restRoot + "/rest/fichier/download?id=" + encodeURIComponent(code) + "&x_token_key=" + encodeURIComponent(Configuration.x_token_key), "_blank");
    }

  },

  _manageButtons: function(event) {
    var buttonId = event.currentTarget.className.split(" ")[2];
    var self = this;
    LOG.debug("Button clicked in component pieces jointes : " + buttonId);
    switch (buttonId) {
      case "add":
        var context = {};
        context.ctxRefEvenement = this.context.ctxRefEvenement;
        context.ctxHabilitation = this.context.ctxHabilitation;
        context.ctxEcran = this.context.ctxEcran;
        context.ctxGestionCollab = this.context.ctxGestionCollab;
        context.ctxActionsPossibles = this.context.ctxActionsPossibles;
        context.ctxModeInitialisation = "Ajout";

        this.popupView = this._initPopupView(context);
        this.popupView.open();
        this.popupView.dialog.dialog("option", "position", { my: "center", at: "center", of: window });

        this.popupView.internalView.model.on("piecejointeAdded", this._piecejointeAdded, this);
        this.popupView.internalView.model.on("piecejointeRemoved", this._piecejointeRemoved, this);

        break;
    }
  },

  _piecejointeAdded: function(fresh) {
    // new piece jointe added
    if (fresh instanceof FileTransferView) {
      if (fresh.shouldSubmit()) {
        this.fileTransferList.push(fresh);
        this.piecesJointesList.push(fresh.fileToUpload());
      }
    }

    // repaint component
    this._generateContent(this.piecesJointesList);

    // inform parent that the list has changed
    this.model.trigger("piecejointeChanged");
  },

  _piecejointeRemoved: function(id) {
    // piece jointe removed
    if (id) {
      for (var i = this.piecesJointesList.length - 1; i >= 0; i--) {
        if (this.piecesJointesList[i].cid === id) {
          // clean file transfer list
          for (var j = this.fileTransferList.length - 1; j >= 0; j--) {
            if (this.fileTransferList[j].cid === id) {
              this.fileTransferList[j].remove();
              this.fileTransferList.splice(j, 1);
              this.piecesJointesList.splice(i, 1);
              break;
            }
          }
          break;
        }
        if (this.piecesJointesList[i].code === id) {
          this.piecesJointesList[i].deleted = true;
          break;
        }
      }
    }

    // repaint component
    this._generateContent(this.piecesJointesList);

    // inform parent that the list has changed
    this.model.trigger("piecejointeChanged");
  },

  _initPopupView: function(context) {
    var fileTransfer = this._initFileTransfer();

    var dialog = new DialogView({
      view: GererPiecesJointesView,
      viewData: {
        context: context,
        coll: this.piecesJointesList,
        fileTransferView: fileTransfer,
        description: this.description,
        maxPieces: this.maxPieces
      }
    });
    dialog.setHeight("auto");
    dialog.setWidth(600);
    return dialog;
  },

  _initFileTransfer: function() {
    var habContextFileTransfer = new HabilitationContext({
      onglet: this.context.ctxEcran,
      fonc_cour: this.context.ctxHabilitation.HabilitationAcces
    });

    var fileTransfer = new FileTransferView({
      entite: this.context.ctxRefEvenement,
      type: this.type,
      multiples: false,
      delayedUpload: true,
      habContext: habContextFileTransfer
    });

    fileTransfer.setHabContext(habContextFileTransfer);
    return fileTransfer;
  }
});
