import { objs } from '../../objectsRepository.js';
import { PROFILE } from '../../utils/profile.js';
import { ProfilUtilisateurView } from 'common/profilutilisateur/views/profilUtilisateur.view.js';

/**
 * View to show the profile component.
 */
export var ProfileSelector = Backbone.View.extend({

  render: function() {
    //Create the DOM for the menu
    var visibleMenu = false;
    var transverses = this.model.configuration.get("confignav").get("transverses");
    for (var i = 0; i < transverses.length; i++) {
      var uc = transverses[i];
      if (uc.id === "profilutilisateur" && PROFILE.checkHabilitations(transverses[i].fonctions) && PROFILE.checkProfile()) {
        visibleMenu = true;
        objs.profilutilisateur = new ProfilUtilisateurView();
        this.$el.append(objs.profilutilisateur.render().el);
      }
    }

    if (visibleMenu === false) {
      this.$el.hide();
    } else {
      this.$el.show();
    }

    return this;
  }
});