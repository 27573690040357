import { BaseModel } from '../../../../../../core/models/base.model.js';

import { i18n } from '../../../../../../i18n.js';
import { STR } from '../../../../../../utils/str.js';
import { SYNC } from '../../../../../../utils/sync.js';
import { TYPE } from '../../../../../../tda/tda.js';

/**
 * Lister Gerer Prets Model
 */
export var GererPretsModel = BaseModel.extend({

  initialize: function() {
    this.MODEGROUPE = false;
    //		this.set("infocomp",{});
    this.groupedErrors = {
      datedeb: ["datedeb", "datefin"],
      datefin: ["datedeb", "datefin"]
    };
    this.structures = [];
  },

  url: function() {
    var id = this.isNew() === true ? "" : encodeURIComponent(this.id);
    if (this.action === "saisiegroupee") {
      return Configuration.restRoot + "/rest/composant/pret/" + this.action;
    }
    return Configuration.restRoot + "/rest/composant/pret/" + this.action + "/" + id;
  },

  defaults: {
    "id": null,
    "code": "",
    "collaborateur": {
      "matricule": null,
      "matriculeaux": "",
      "nom": "",
      "prenom": ""
    },
    "type": {
      "code": "",
      "libelle": ""
    },
    "emprunt": null,
    "createur": null,
    "perimetre": {
      "code": "",
      "libelle": ""
    },
    "collabremplace": {
      "nom": "",
      "prenom": "",
      "matric": null,
      "matricaux": "",
      "datedeb": "",
      "datefin": ""
    },
    "structures": null,
    "typesaisie": "D",
    "modesaisie": "UNITE",
    "datedeb": "",
    "datefin": "",
    "unitedeb": {
      "code": "",
      "libelle": ""
    },
    "modesai_periodeb": {
      "code": "",
      "libelle": ""
    },
    "modesai_periofin": {
      "code": "",
      "libelle": ""
    },
    "heuredeb": "",
    "heurefin": "",
    "duree": "",
    "pourcentage": "",
    "dureecalculee": "",
    "motif": {
      "code": "",
      "libelle": ""
    },
    "origine": {
      "code": "",
      "libelle": ""
    },
    "libelle": "",
    "commentaire_pret": "",
    "demandesuppression": null,
    "statut": {
      "code": "",
      "libelle": ""
    },
    "commentaire": "",
    "etat": "",
    "indicateurpj": null,
    "est_createur": null,
    "infocomp": null

    /*Example of data
			 * "PRTSVARDAT2" :{ // JSON correspondant à une caractéristique de type date
          "code":"PRTSVARDAT2"
          "valeur":"20141118"
          "type" : "D",
          "oblig" : true,
          "libelle" : "Lib date",
          "datemin" : "",
          "datemax" : ""
        },
       "PRTSVARCHAR3" : { // JSON correspondant à une caractéristique de type chaine
          "code":"PRTSVARCHAR3"
          "valeur":"Caract1"
          "type" : "C",
          "oblig" : true,
          "libelle" : "Lib chaine",
          "codif" : ""
        },
        "PRTSVARINT1" : { // JSON correspondant à une caractéristique de type numérique
          "code":"PRTSVARINT1"
          "valeur":"12"
          "type" : "N",
          "oblig" : true,
          "libelle" : "Lib num",
          "masque" : "",
          "nummin" : 5,
          "nummax" : 15,
          "codif" : ""
        }
			 */
  },

  validate: function(attrs, options) {
    var self = this;
    var errors = {};
    var errorMsg;
    var dateNow = TYPE.DATE.dateToStr(SYNC.getServerDate(), "YYYYMMDD");

    if (STR.isBlank(attrs.type.code)) {
      errors.type = {};
      errors.type.code = i18n.t('common:required', { "0": i18n.t('common:gererprets.type') });
    }

    if (STR.isBlank(attrs.perimetre.code)) {
      errors.perimetre = {};
      errors.perimetre.code = i18n.t('common:required', { "0": i18n.t('common:gererprets.perimetre') });
    }

    if (STR.isBlank(attrs.motif.code)) {
      errors.motif = {};
      errors.motif.code = i18n.t('common:required', { "0": i18n.t('common:gererprets.motif') });
    }

    // Dates
    if (attrs.typesaisie == "D") {
      if (STR.isBlank(attrs.datedeb)) {
        errors.datedeb = i18n.t('common:required', { "0": i18n.t('common:gererprets.datedebut') });
      } else {
        errorMsg = TYPE.DATE.validate(attrs.datedeb);
        if (!STR.isBlank(errorMsg)) {
          errors.datedeb = errorMsg;
        }
      }
    } else {
      //Period validation
      var datedeb = {
        "name": "datedeb",
        "value": attrs.datedeb,
        "label": i18n.t('common:gererprets.datedebut')
      };
      var datefin = {
        "name": "datefin",
        "value": attrs.datefin,
        "label": i18n.t('common:gererprets.datefin'),
        "isInfinity": false
      };
      var errorMsg = TYPE.DATE.validatePeriod(datedeb, datefin, options);
      if (!STR.isBlank(errorMsg)) {
        errors.datedeb = errorMsg.datedeb;
        errors.datefin = errorMsg.datefin;
      }
    }

    if (attrs.typesaisie == "P") {
      if (!STR.isBlank(attrs.modesai_periofin) && STR.isBlank(attrs.modesai_periofin.code)) {
        errors.modesai_periofin = {};
        errors.modesai_periofin.code = i18n.t('common:required', { "0": i18n.t('common:gererprets.unite_deb') });
      }
      if (!STR.isBlank(attrs.modesai_periodeb) && STR.isBlank(attrs.modesai_periodeb.code)) {
        errors.modesai_periodeb = {};
        errors.modesai_periodeb.code = i18n.t('common:required', { "0": i18n.t('common:gererprets.unite_fin') });
      }
    }

    if (attrs.typesaisie != "P") {
      if (STR.isBlank(attrs.unitedeb.code)) {
        errors.unitedeb = {};
        errors.unitedeb.code = i18n.t('common:required', { "0": i18n.t('common:gererprets.unite') });
      }
    }

    //		if (STR.isBlank(attrs.origine.code)) {
    //			errors.origine = {};
    //			errors.origine.code = i18n.t('common:required', { "0" :  i18n.t('common:gererprets.origine')});
    //		}

    if (STR.isBlank(attrs.datefin)) {
      errors.datefin = i18n.t('common:required', { "0": i18n.t('common:gererprets.datefin') });
    } else {
      errorMsg = TYPE.DATE.validate(attrs.datefin);
      if (!STR.isBlank(errorMsg)) {
        errors.datefin = errorMsg;
      }
    }

    if (STR.isBlank(errors.datefin) && STR.isBlank(errors.datedeb) && attrs.datedeb > attrs.datefin) {
      errors.datefin = i18n.t('messages:GT_224');
    }
    if (attrs.typesaisie == "P") {
      if (STR.isBlank(errors.datefin) && STR.isBlank(errors.datedeb) && attrs.datedeb == attrs.datefin) {
        errors.datefin = i18n.t('messages:GT_1345');
      }
    }

    _.each(this.structures, function(struct) {
      var modelStruct = _.find(attrs.structures, { type: struct.type });
      if ((!modelStruct || STR.isBlank(modelStruct.element)) && struct.rattcollab === "O") {
        errors["structures_itemid_" + struct.type] = {};
        errors["structures_itemid_" + struct.type] = i18n.t('common:required', { "0": i18n.t('common:gererprets.structure') });
        //				if(_.isUndefined(errors.structures)) errors.structures = {};
        //					errors.structures["itemid_"+struct.type] = {};
        //					errors.structures["itemid_"+struct.type] = i18n.t('common:required', { "0" :  i18n.t('common:gererprets.structure')});
      }
    });

    //Infocomp
    _.each(attrs.infocomp, function(item, key) {
      if (STR.isBlank(item.valeur)) {
        if (item.oblig) {
          if (_.isUndefined(errors.infocomp)) errors.infocomp = {};
          errors.infocomp[item.code] = {};
          errors.infocomp[item.code].valeur = i18n.t('common:required', { "0": item.libelle });
        }
      } else {
        switch (item.type) {
          case "D":
            errorMsg = TYPE.DATE.validate(item.valeur);
            if (!STR.isBlank(errorMsg)) {
              if (_.isUndefined(errors.infocomp)) errors.infocomp = {};
              errors.infocomp[item.code] = {};
              errors.infocomp[item.code].valeur = errorMsg;
            } else if ((!STR.isBlank(item.datemin) && item.valeur < item.datemin) || (!STR.isBlank(item.datemax) && item.valeur > item.datemax)) {
              if (_.isUndefined(errors.infocomp)) errors.infocomp = {};
              errors.infocomp[item.code] = {};
              errors.infocomp[item.code].valeur = i18n.t('messages:GT_1040', { "1": item.libelle, "2": TYPE.DATE.format(item.datemin), "3": TYPE.DATE.format(item.datemax) });
            }
            break;
          case "N":
            if (!STR.isBlank(item.masque)) {
              if (!_.isUndefined(TYPE.CUSTOM.validate(item.valeur, item.nummin, item.nummax))) {
                if (_.isUndefined(errors.infocomp)) errors.infocomp = {};
                errors.infocomp[item.code] = {};
                errors.infocomp[item.code].valeur = i18n.t('messages:GT_1041', { "1": item.libelle, "2": item.nummin, "3": item.nummax });
              }
            } else {
              var errorNum = TYPE.LONG.validate(item.valeur, item.nummin, item.nummax);
              if (!STR.isBlank(errorNum)) {
                if (_.isUndefined(errors.infocomp)) errors.infocomp = {};
                errors.infocomp[item.code] = {};
                errors.infocomp[item.code].valeur = errorNum;
              }
            }
            break;
        }
      }
    });

    if (!_.isEmpty(errors)) {
      var errorMap = {
        errorValidation: STR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
  },

  parse: function(response) {
    if (this.MODEGROUPE === false) {
      if (response.id == undefined && response.code != undefined) {
        response.id = response.code;
      }
    }
    if (response.result) {
      return null;
    }
    return response;
  }
});