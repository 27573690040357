import { BaseGridView } from '../basegrid/baseGrid.view.js';
import { STR } from '../../../utils/str.js';

export var DataGridView = BaseGridView.extend({

  /**
   * Add this to have Outline
   *
   */
  dummy: "",
  /**
   * DEFAULT_NAME
   */
  DEFAULT_NANE: "Data",

  /**
   * Data Grid View
   */
  initialize: function(options) {
    BaseGridView.prototype.initialize.call(this, options);
  },

  setOverflow: function(x, y) {
    this.overflowx = x;
    this.overflowy = y;
  },

  /**
   * Renders this view
   */
  render: function() {
    var self = this;

    if ($(this.el).children().length === 0) { // Render the header only one time
      $(this.el).html(this.template());
      // Header
      this._renderHead();
    }

    //Configure height and width of the component
    var tableScroll = $(this.el).find(".phx-grid-scroll");
    tableScroll.height(this.height);
    if (!STR.isBlank(this.overflowx) && !STR.isBlank(this.overflowy)) {
      tableScroll.css("overflow-y", this.overflowy);
      tableScroll.css("overflow-x", this.overflowx);
    } else {
      tableScroll.css("overflow-y", "auto");
      tableScroll.css("overflow-x", "auto");
    }

    //Width:
    $(this.el).width(this.width);

    var tbody = $(this.el).find("tbody");
    if (tbody.children().length === 0) {
      // Patch because the first time the collection is filled the
      // DataGrid is not yet visible
      this.model.coll.trigger("reset", this.model.coll);

      $(".phx-grid-scroll", this.el).scroll(function(event) {
        self._scrollControl(event);
        $(".phx-grid-header-scroll", self.el).scrollLeft($(this).scrollLeft());
      });
    }

    this.positionHeader();
    return this;
  }
});
