import { AffectationEnCoursModel } from './affectationEnCours.model.js';

import { PaginatedCollection } from '../../../../../core/models/paginated.collection.js';

export var AffectationComplemColl = PaginatedCollection.extend({

  model: AffectationEnCoursModel,
  url: Configuration.restRoot + "/rest/brique/affectations/complementaires",

  initialize: function() {
    PaginatedCollection.prototype.initialize.call(this);
    this.paginated = false;
  },

  parse: function(response) {
    response.list = response.affectation;

    delete response.affectation;

    return PaginatedCollection.prototype.parse.call(this, response);
  }
});