module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<fieldset class="phx-populations-detail-structure-simple">\n    <div class="">\n        <div id="selectorEstructura"></div>\n        <br><br>\n\n        ';
 if(context && context.ctxStructureSelpop){
__p+='\n            <div class="phx-chemin-population-descendence" style="'+
((__t=((context.ctxSelRemDesc == 0 || context.ctxSelRemDesc == 1 )?" display:none; ":" "))==null?'':__t)+
'">\n                <label for="populationDescendence" style="width: 100%;margin-bottom:15px;">\n      <input class="populationDescendence" type="checkbox" '+
((__t=((context.ctxSelRemDesc == 0 || context.ctxSelRemDesc == 2 )?"checked='checked'":""))==null?'':__t)+
'  readonly="'+
((__t=((context.ctxSelRemDesc == 2 || context.ctxSelRemDesc == 3 )?"":"readonly"))==null?'':__t)+
'" >\n       <span>  '+
((__t=( i18n.t("populationsmenu.returnerEgalement")))==null?'':__t)+
'  </span>\n  </label>\n            </div>\n            ';
}
__p+='\n                <div class="">\n                    <button type="button" class="buttonAppliquer" type="button" style="float:right;">'+
((__t=(i18n.t("populationsmenu.appliquer")))==null?'':__t)+
'</button>\n                </div>\n\n    </div>\n</fieldset>\n';
}
return __p;
};
