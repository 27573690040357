import { GLOBAL_DATA } from '../globalData.js';
import { STR } from './str.js';
/**
 * Utility to get the divers parameters.
 */
export var DIVERS = {

  get: function(id) {
    var l_rtn = null;

    if (!STR.isBlank(id) && GLOBAL_DATA.paramDivers && GLOBAL_DATA.paramDivers.get(id)) {
      l_rtn = GLOBAL_DATA.paramDivers.get(id).get("valeur");
    }
    return l_rtn;
  }
};