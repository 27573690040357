/**
 * Groupe Menu Model.
 */
export var GroupeMenuModel = Backbone.Model.extend({
  dummy: "",
  defaults: {},
  /**
   * Process to manage the response
   */
  parse: function(response) {
    if (response && response.fonction) {
      this.id = response.groupe;
    }
    return response;
  }
});
