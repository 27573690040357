module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<fieldset class="gereronebadgeage-detail">\n    <div class="phx-formTable">\n        <div class="phx-formErrors ui-state-error"></div>\n        <div>\n            <div class="gereronebadgeage-content">\n                <span class="gereronebadgeage-utilisateur ctxGestionCollab"></span>\n                <br/><br/>\n                <span class="gereronebadgeage-date"></span>\n                <span class="actionbadgeage">'+
((__t=(i18n.t('common:gereronebadgeage.actionbadgeage')))==null?'':__t)+
'</span>\n                <span class="gereronebadgeage-action"></span>\n                <br/>\n                <span class="optionsDate">\n					<label for="saisie.date" class="required">'+
((__t=(i18n.t('common:gereronebadgeage.le')))==null?'':__t)+
'</label>\n					<input type="text" class="saisie.date typeDate required" value="" size="18"/>\n			   </span>\n                <label for="saisie.heure" class="required heure_label">'+
((__t=(i18n.t('common:gereronebadgeage.a')))==null?'':__t)+
'</label>\n                <input type="text" class="saisie.heure typeHourMinute required" value="" size="5" autofocus /></br>\n                <span class="saisie.date-error-container"></span>\n                <span class="saisie.heure-error-container"></span>\n                <br/>\n                <br/>\n                <label for="saisie.sens" class="required">'+
((__t=(i18n.t('common:gereronebadgeage.sens')))==null?'':__t)+
'</label>\n                <span class="cmbSens"></span>\n                <br/>\n                <label for="motif.code">'+
((__t=(i18n.t('common:gereronebadgeage.motif')))==null?'':__t)+
'</label>\n                <span class="cmbMotif"></span>\n                <span class="motif.code-error-container"></span>\n                <br/>\n                <label for="fonction">'+
((__t=(i18n.t('common:gereronebadgeage.fonction')))==null?'':__t)+
'</label>\n                <input type="text" class="fonction" value="" size="10" maxlength="10" />\n                <br/>\n                <div class="cmbBlockBadgeCommentaire">\n                    <label for="commentaireLibelle" class="commentaireLibelleReq">'+
((__t=(i18n.t('common:gereronebadgeage.comment')))==null?'':__t)+
'</label>\n                    <span class="cmbCommentaire"></span>\n                </div>\n                <br/>\n                <div class="blockBadgeCommentaire">\n                    <label for="commentaire">'+
((__t=(i18n.t('common:gereronebadgeage.comment')))==null?'':__t)+
'</label>\n                    <textarea class="commentaire" maxlength="60" cols="36" rows="5" style="resize:none"></textarea>\n                </div>\n                <br/>\n                <span class="gereronebadgeage-wkfhisto"></span>\n                <br/>\n            </div>\n        </div>\n    </div>\n    <ul class="validerListMenu menuContent">\n    </ul>\n</fieldset>';
}
return __p;
};
