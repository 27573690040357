import { ButtonBarView as CommonButtonBarView } from 'core/controls/buttonBar.view.js';
import { i18n } from '../../../../i18n.js';

/**
 * View for the button bar
 */
export var ButtonBarView = CommonButtonBarView.extend({

  initialize: function() {
    CommonButtonBarView.prototype.initialize.call(this);

    this.model.on("exportEtat", this._manageExportEtat, this);

    this.addButton("print", i18n.t('lanceetat.imprimer'));
    this.addButton("export", i18n.t('lanceetat.exporter'));
    this.addButton("revert", "", "ui-icon-arrowrefresh-1-w");

    this.exportEtat = false;
  },

  _manageExportEtat: function(exportEtat) {
    this.exportEtat = exportEtat;
    if (exportEtat === true) {
      this.$el.find(".export").show();
    } else {
      this.$el.find(".export").hide();
    }
  },

  render: function() {
    if (this.exportEtat === true) {
      this.$el.find(".export").show();
    } else {
      this.$el.find(".export").hide();
    }
    return this;
  }
});