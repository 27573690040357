import { BaseModel } from 'core/models/base.model.js';


export var Preferences = BaseModel.extend({

  urlRoot: Configuration.restRoot + "/rest/situationvalo/preferences",

  defaults: {
    "preferences": [{
      "varchar5": "",
      "varchar3": "",
      "varchar4": "",
      "varchar1": "",
      "code": "",
      "varchar2": "",
      "varint3": null,
      "varint2": null,
      "varint1": null,
      "varint5": null,
      "varint4": null,
      "vardat5": "",
      "vardat4": "",
      "vardat3": "",
      "vardat2": "",
      "vardat1": ""
         }],
  },

  parse: function(response) {
    if (response.id == undefined && response.code != undefined) {
      response.id = response.code;
    }

    return response;
  }
});