import { BaseModel } from 'core/models/base.model.js';
import { PaginatedCollection } from 'core/models/paginated.collection.js';
import { STR } from '../../../../utils/str.js';

/**
 * Model d'etats des attributs dépendants
 */
export var CriteresReinitModel = BaseModel.extend({

  defaults: {
    "vue": "",
    "totfin": "",
    "periode": "",
    "totcoll": "",
    "totindiv": "",
    "population": null,
    "infcompl": ""
  },

  //Not version "2.2.0",
  url: function() {
    var optionalParams = (!STR.isBlank(this.newValeur) ? "?valeur=" + this.newValeur : "");
    return Configuration.restRoot + "/rest/edition/definition/" + encodeURIComponent(this.id) + "/critere/" + encodeURIComponent(this.code) + "/reinit" + optionalParams;
  },

  initialize: function() {
    PaginatedCollection.prototype.initialize.call(this);
    this.paginated = false;
  }
});