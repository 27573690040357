
import { PaginatedCollection } from 'core/models/paginated.collection.js';
import { SearchResultatsListeCptModel } from './searchResultatsListeCpt.model.js';

/**
 * Collection of SearchResultatsListeCpt
 */
export var SearchResultatsListeCptColl = PaginatedCollection.extend({

  model: SearchResultatsListeCptModel,

  _formatDate: function(date) {

    var datAttr = date.split('/');

    var day = datAttr[0];
    var month = datAttr[1];
    var year = datAttr[2];

    var dateFormat = year + "" + month + "" + day;

    return dateFormat;
  },

  _createUrl: function() {
    var urlS = "/rest/resultatsgroupecpt?matricule=" + encodeURIComponent(this.matricule) +
      "&groupe=" + encodeURIComponent(this.groupe);

    if (this.date !== undefined && this.date !== null) {
      urlS = urlS + "&date=" + this.date;
    }
    if (this.demande !== undefined && this.demande !== null) {
      urlS = urlS + "&demande=" + encodeURIComponent(this.demande);
    }
    if (this.souhait !== undefined && this.souhait !== null) {
      urlS = urlS + "&souhait=" + encodeURIComponent(this.souhait);
    }

    return urlS;
  },

  url: function() {
    return Configuration.restRoot + this._createUrl();
  },

  initialize: function() {
    PaginatedCollection.prototype.initialize.call(this);
    this.paginated = false;
  },

  clone: function() {
    var temp = PaginatedCollection.prototype.clone.call(this);
    return temp;
  }
});