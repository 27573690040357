import { ButtonBarView } from './buttonBar.view.js';
import { EtatsColl } from '../models/etats.collection.js';
import { FilterContentView } from './filterContent.view.js';
import { FilterView } from 'core/components/filter/filter.view.js';
import { FormView } from './form.view.js';
import { FormView as CommonFormView } from 'core/views/form.view.js';
import { HeaderView } from './header.view.js';
import { i18n } from '../../../../i18n.js';
import { ListeDetailPanelView } from 'core/layouts/listeDetailPanel.view.js';
import { NavigationView } from 'core/components/navigation/navigation.view.js';
import { STR } from '../../../../utils/str.js';
import { TreeTypeGridModel } from 'core/grids/treetypegrid/treeTypeGrid.model.js';
import { TreeTypeGridView } from 'core/grids/treetypegrid/treeTypeGrid.view.js';
import { LanceetatWorkflow } from '../models/lanceetat.workflow.js';

/**
 * It will paint all the tab for the UC.
 */
export var GeneralView = CommonFormView.extend({

  className: 'lanceetat',
  initialize: function(options) {
    if (options && options.parent) {
      this.parent = options.parent;
    }

    // Initializes the main parts of the UI and its workflow
    this.workflow = new LanceetatWorkflow({ loc: options.loc });
    //		this.workflow.on("change:uri", function (wfl) {
    //			NAV.navigateToModule(wfl.get("uri"),false);
    //		}, this);

    this.layout = new ListeDetailPanelView({ position: 24 });

    this.filter = this._initFilter();
    this.workflow.filterModel = this.filter.model;

    this.form = this._initForm();
    this.workflow.formColl = this.form.coll;
    this.workflow.formModel = this.form.model;

    this.detailsHeader = this._initDetailsHeader();
    this.workflow.headerModel = this.detailsHeader.model;

    this.navigationView = this._initNavigationView();
    this.workflow.navigationModel = this.navigationView.model;
    this.table = this._initTable();
    this.workflow.tableModel = this.table.model;

    this.btnBar = new ButtonBarView();
    this.workflow.btnBarModel = this.btnBar.model;

    this.listenTo(this.workflow, "closePopup", this._closePopup);
    this.listenTo(this.workflow, "checkFournisseur", this._checkFournisseur);
    this.listenTo(this.workflow.headerModel, "resize", this._resizeHeader);
  },
  _checkFournisseur: function() {
    var boolHideFournisseur = true;
    _.each(this.table.model.coll.models, function(mod) {
      if (mod.hasChild === false) {
        if (mod.get("fournisseur") !== 2) {
          boolHideFournisseur = false;
        }
      }
    });
    if (boolHideFournisseur === true) {
      this.table.setVisibleColumns(["libelle"]);
    } else {
      this.table.setVisibleColumns(["libelle", "fournisseur"]);
    }

  },
  _initLayoutUC: function(callback) {
    if (this.workflow.get("ready") === true) {
      if (callback) {
        callback();
      }
    } else {
      var self = this;
      this.workflow.setUp(function(setUpCallback) {
        //When the workflow is ready, we can render the view without concerns
        self.layout.$el.find(".phx-panelA1").html(self.filter.render().el);
        self.layout.$el.find(".phx-panelA2").html(self.table.el);
        self.table.render();

        self.layout.$el.find(".phx-panelB1").html(self.btnBar.render().el);
        self.layout.$el.find(".phx-panelB2").html(self.navigationView.render().el);
        self.layout.$el.find(".phx-panelB3").html(self.detailsHeader.render().el);
        self.layout.$el.find(".phx-panelB4").html(self.form.render().el);

        if (self.workflow.paramExportEtat === false) {
          self.btnBar.hideButton("export");
        }
        if (callback) {
          callback(setUpCallback);
        }
        // Function call to recalculate the size of the panels once the
        // burden of everything you need to show the use case
        self.trigger("uc:ready");
      });
    }
  },

  _initTable: function() {
    var self = this;

    var tableModel = new TreeTypeGridModel({
      coll: new EtatsColl()
    });
    var tblColumns = [
      { title: i18n.t('lanceetat.nom'), property: "libelle", width: 260, treecol: true },
      { title: i18n.t('lanceetat.ordre'), property: "ordre", width: 180 },
      { title: i18n.t('lanceetat.commentaire'), property: "commentaire", width: 260 },
      { title: i18n.t('lanceetat.fournisseur'), property: "fournisseur", width: 180 }
    ];

    var table = new TreeTypeGridView({
      id: "lanceetat_table",
      title: i18n.t('lanceetat.title_table'),
      columns: tblColumns,
      model: tableModel,
      showFilteredRowsInTitle: true,
      gridHeightType: "auto",
      parentContainer: this.parent
    });

    table._manageSortingOld = table._manageSorting;
    table._manageSorting = function(columnCode) {
      table._manageSortingOld(columnCode, function(fresh) {
        //reset and build flat collection for tree grid
        self.workflow.flatColl.reset();
        self.workflow._arrayToCollection(fresh);
        self.table.model.coll.totalRecords = self.workflow.flatColl.length;
        self.table.model.coll.reset(self.workflow.flatColl.models);

        //search first level 2 element ( level 1 can't be selected)
        var finded = false;
        for (var i = 0; i < self.table.model.coll.length && finded === false; i++) {
          if (self.table.model.coll.at(i).level === 2) {
            finded = true;
            var row = self.table.model.coll.at(i);
            row.trigger("row:click", row);
          }
        }
      }, true);
    };

    table.cellRenderers["libelle"] = function(model) {
      var libelle = $('<span>');
      if (model.level === 1) {
        libelle.css("font-weight", "bold");
      }
      libelle.text(model.get("libelle"));
      return libelle;
    };

    table.cellRenderers["fournisseur"] = function(model) {
      //var longColecc = model.collection.length;
      var fournisseur = $('<span>');
      if (model.get("fournisseur") == 2) {
        fournisseur = i18n.t('lanceetat.chrono');
      } else if (model.get("fournisseur") == 3) {
        fournisseur = i18n.t('lanceetat.timeRep');
      }

      if (!STR.isBlank(model.get("fournisseur")))
        return "<span title='" + i18n.t('lanceetat.fournisseurDesc') + " " + "'>" + fournisseur + "</span>";
    };

    table.setVisibleColumns(["libelle", "fournisseur"]);
    table.setSortableColumns(["ordre"]);

    return table;
  },

  _initFilter: function() {
    var part1View = new FilterContentView({ workflow: this.workflow });
    var filter = new FilterView({ viewPart1: part1View });
    return filter;
  },

  _initForm: function() {
    var form = new FormView({ workflow: this.workflow });
    return form;
  },

  _initDetailsHeader: function() {
    var header = new HeaderView();
    return header;
  },

  _initNavigationView: function() {
    var page = new NavigationView({ text: i18n.t('lanceetat.usecase') });
    return page;
  },

  render: function() {
    var self = this;

    this.$el.html(this.layout.render().el);
    this.parent._setTitle(i18n.t('lanceetat.title_popup'));
    this._initLayoutUC(function(callback) {
      if (self.parent) {
        self.parent.resize();
      }
      if (callback) {
        callback(); // ici on appelle le callback du setUp
      }
    });
    return this;
  },

  _closePopup: function() {
    this.parent.close();
  },

  _resizeHeader: function() {
    this.parent.resize();
  }
});