import { ReadOnlyModel } from '../../../../../core/models/readOnly.model.js';

/**
 * Briques configuration.
 */
/**
 * Model for Brique 'affectations en cours ou à venir'
 */
/**
 *  */
export var AffectationEnCoursModel = ReadOnlyModel.extend({

  urlRoot: Configuration.restRoot + "/rest/brique/affectations/principales/"
});