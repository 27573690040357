module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<div class="parametragebresultats-detail-main">\n  <fieldset class="parametragebresultats-detail">\n    <div class="phx-formTable">\n      <div class="phx-formErrors ui-state-error" style="margin-left: 230px"></div>\n      <div>\n        <div class="parametragebresultats-content">\n          <div class="parametragebresultats-form-inputs">\n            <span class="ctxGestionCollab">'+
((__t=(i18n.t('common:parametragebresultats.title')))==null?'':__t)+
'</span><br>\n            <br>\n            <div class="phx-details-header-text">\n              <span class="titreBrique"></span><br/>\n              <span class="infoCompBrique"></span><br/>\n            </div>\n            <br/>\n            <label for="codebrique" class="required codebrique">'+
((__t=(i18n.t('common:parametragebresultats.code_label')))==null?'':__t)+
'</label>\n            <input type="text" class="codebrique" value="" size="10" maxlength="15" />\n            <br>\n\n            <label for="libelle" class="required libelle">'+
((__t=(i18n.t('common:parametragebresultats.titre_label')))==null?'':__t)+
'</label>\n            <input type="text" class="libelle" value="" size="45" maxlength="60" />\n            <br>\n\n            <label for="visibilite">'+
((__t=(i18n.t('common:parametragebresultats.visibilite_label')))==null?'':__t)+
'</label>\n            <input type="checkbox" class="visibilite" value="true" title="'+
((__t=(i18n.t('common:parametragebresultats.visibilite_tooltip')))==null?'':__t)+
'" />\n            <br>\n\n            <label for="datereference" class="required" title="'+
((__t=(i18n.t('common:parametragebresultats.date_tooltip')))==null?'':__t)+
'">'+
((__t=(i18n.t('common:parametragebresultats.date_label')))==null?'':__t)+
'</label>\n            <span class="radioDateReference"></span>\n            <br>\n          </div>\n\n        </div>\n      </div>\n    </div>\n\n  </fieldset>\n  <div style="min-width : 780px;">\n    <span class="tableCompteurs"></span>\n  </div>\n  <div class="btnBar-container"></div>\n</div>\n';
}
return __p;
};
