import { HEADERS } from '../../../utils/headers.js';
import { SelecteurCheminNivModel } from './selecteurCheminNiv.model.js';

export var SelecteurCheminNivColl = Backbone.Collection.extend({
  /**
   * Add this to have Outline
   *
   */
  dummy: "",

  model: SelecteurCheminNivModel,

  /**
   * Constructor
   * Selecteur Chemin Niveau Collection
   */
  initialize: function(params) {
    this.code = "";
    if (params && params.code) {
      this.code = params.code;
    }
  },

  /**
   * Url for this collection
   */
  url: function() {
    var urlFin = Configuration.restRoot + "/rest/cheminstructure/" + this.code + "/niveau";
    return urlFin;
  },

  fetch: function(options) {
    var options = options || {};

    if (this.ecran && !this.habContext) {
      options.headers = HEADERS.habilitationContext(this.ecran, "N", "");
    }
    return Backbone.Collection.prototype.fetch.call(this, options);
  }
});