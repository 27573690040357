import { BaseGridModel } from '../basegrid/baseGrid.model.js';

export var DataGridModel = BaseGridModel.extend({

  /**
   * Add this to have Outline
   *
   */
  dummy: "",
  /**
   * Model underlying a basic DataGrid.
   * Events out: change:value
   * Events in: row:click, row:select, row:unselect
   */
  initialize: function(options) {
    BaseGridModel.prototype.initialize.call(this, options);
  },

  /**
   * The current habilitation context that the grid should use perform the actions (add/modify/remove)
   *
   */
  setHabContext: function(habContext) {
    this.habContext = habContext;
  },

  updateHabContext: function(attributes) {
    if (this.habContext) {
      this.habContext.update(attributes);
    }
  },

  getHabContext: function() {
    return this.habContext;
  }
});