
import { DelegationsTableModel } from './delegationsTable.model.js';
import { PaginatedCollection } from 'core/models/paginated.collection.js';

export var DelegationsTableColl = PaginatedCollection.extend({
  /**
   * Code of the Utilisateur
   */

  usecase: "droitsdel",

  model: DelegationsTableModel,

  /**
   * Builds the url for the REST call
   */
  url: function() {
    return Configuration.restRoot + "/rest/delegation/" + encodeURIComponent(this.utilCode);
  },

  /**
   * Constructor - Collection for load the models for the load data in table rigth (DelegationsTableModel)
   * renove the paginated (this.paginated = false)
   */
  initialize: function() {
    PaginatedCollection.prototype.initialize.call(this);
    //this.sortings = {datedeb : false};
    this.paginated = false;
    this.utilCode = null;
  }
});