import { SelecteurCheminItemModel } from '../../../../core/components/selecteur_chemin/selecteurCheminItem.model.js';
import { SelecteurCheminView } from '../../../../core/components/selecteur_chemin/selecteurChemin.view.js';
import { TYPE } from '../../../../tda/tda.js';

/**
 * Plain Text Structures Chemin View
 */
export var PlainStructuresView = Backbone.View.extend({

  tagName: "span",

  initialize: function(options) {
    // if we are going to show this in a table or not.
    this.table = true;

    if (options && options.table == false) {
      this.table = options.table;
    }
    if (options && options.context) {
      this.context = options.context;
    }
    if (options && options.plainText) {
      this.plainText = options.plainText;
    }
    if (options && options.populationColl) {
      this.populationColl = options.populationColl;
    }
  },

  render: function() {
    var self = this;
    var chemin;
    // The selector is shown if the collaborateur is rattached to it
    if (this.model.model.get("itemid")) {
      chemin = new SelecteurCheminView({
        name: "struct",
        listRenderStyle: this.model.structure ? this.model.structure.format : null, //format
        fieldWidth: 220,
        structid: this.model.structCode, //code
        datedebut: this.model.debsitu ? this.model.debsitu : TYPE.DATE.INITIAL,
        datefin: this.model.finsitu ? this.model.finsitu : TYPE.DATE.INFINITY,
        intermediaire: true,
        partial: true,
        modeComplet: false,
        multiselection: false,
        readonly: true,
        racine: true,
        nonrattachable: true,
        context: this.context,
        plainText: this.plainText
      });

      var item = new SelecteurCheminItemModel({
        "structid": this.model.structCode,
        "date": this.model.debsitu ? this.model.debsitu : TYPE.DATE.INITIAL,
        "elementCode": this.model.model.get("itemid"),
        "nonrattachable": true,
        "racine": true
      });

      chemin.clearSelection();
      chemin.addValue(item);

      chemin.setElement(this.$el);

      chemin.on("generatedTooltip:selectourChemin", function() {
        var structureModel = chemin.selection.at(0);
        var populationModel = self.populationColl.findWhere({ code: String(structureModel.get("id")) });
        if (populationModel) {
          populationModel.set("libelle", structureModel.get("label"));
        }
      }, this);
    }
    return this;
  }
});