import { SplitPanelView } from './splitPanel.view.js';
import TPL_common_liste_detail_panel from './liste_detail_panel.tpl.html';

/**
 * ListeDetail Panel View
 */
export var ListeDetailPanelView = SplitPanelView.extend({

  dummy: "",
  /**
   * className
   */
  className: "phx-splitPanel phx-listeDetailPanel",
  /**
   * firstTemplate
   */
  firstTemplate: TPL_common_liste_detail_panel,
  /**
   * Renders this view
   */
  render: function() {
    SplitPanelView.prototype.render.call(this);
    var panelB1 = this.$el.find(".phx-panelB1");
    var panelB2 = this.$el.find(".phx-panelB2");
    var panelB3 = this.$el.find(".phx-panelB3");
    var panelB4 = this.$el.find(".phx-panelB4");

    // Synchronize scrolls
    this.$el.find(".phx-panelB4").scroll(function() {
      panelB1.scrollLeft(panelB4.scrollLeft());
      panelB2.scrollLeft(panelB4.scrollLeft());
      panelB3.scrollLeft(panelB4.scrollLeft());
    });
    return this;
  }
});