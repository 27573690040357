let Zone_0 = require('mon_compte/navigation.json');
let Zone_1 = require('mon_equipe/navigation.json');
let Zone_2 = require('referentiel/navigation.json');
let Zone_3 = require('administration/navigation.json');


class RouterFactoryClass {
  constructor(routers_0, routers_1, routers_2, routers_3) {
    this.routersZone_0 = new Map();
    this.routersZone_1 = new Map();
    this.routersZone_2 = new Map();
    this.routersZone_3 = new Map();
    routers_0.forEach((element) => {
      this.routersZone_0.set(element.name, element);
    });
    routers_1.forEach((element) => {
      this.routersZone_1.set(element.name, element);
    });
    routers_2.forEach((element) => {
      this.routersZone_2.set(element.name, element);
    });
    routers_3.forEach((element) => {
      this.routersZone_3.set(element.name, element);
    });
  }

  get(name) {
    if (this.routersZone_0.has(name)) {
      let routerName = this.routersZone_0.get(name).routerName;
      $("#phx-loading_initial").show();
      return import(
        /* webpackChunkName: "MonCompte" */
        /* webpackMode: "lazy" */
        "./mon_compte/monCompteRouters").then((routers) => {
          $("#phx-loading_initial").hide();
          return routers[routerName];
        });
    } else if (this.routersZone_1.has(name)) {
      let routerName = this.routersZone_1.get(name).routerName;
      $("#phx-loading_initial").show();
      return import(
        /* webpackChunkName: "MonEquipe" */
        /* webpackMode: "lazy" */
        "./mon_equipe/monEquipeRouters").then((routers) => {
          $("#phx-loading_initial").hide();
          return routers[routerName];
        });
    } else if (this.routersZone_2.has(name)) {
      let routerName = this.routersZone_2.get(name).routerName;
      $("#phx-loading_initial").show();
      return import(
        /* webpackChunkName: "Referentiel" */
        /* webpackMode: "lazy" */
        "./referentiel/referentielRouters").then((routers) => {
          $("#phx-loading_initial").hide();
          return routers[routerName];
        });
    } else if (this.routersZone_3.has(name)) {
      let routerName = this.routersZone_3.get(name).routerName;
      $("#phx-loading_initial").show();
      return import(
        /* webpackChunkName: "Admistration" */
        /* webpackMode: "lazy" */
        "./administration/administrationRouters").then((routers) => {
          $("#phx-loading_initial").hide();
          return routers[routerName];
        });
    }

    return Promise.reject("Router not found.");
  }
};

let RouterFactory = new RouterFactoryClass(Zone_0, Zone_1, Zone_2, Zone_3);

export { RouterFactory };
