import { DroitModel } from './droit.model.js';
import { objs } from '../../objectsRepository.js';
import { STR } from '../../utils/str.js';
/**
 * Droits Collection. Get all right of a user.
 */
export var DroitColl = Backbone.Collection.extend({

  dummy: "",

  menuVisibility: null,

  model: DroitModel,

  /**
   * This function retrieve a boolean which means if the current profile has at least one
   * "responsable" habilitation (which start by RES)
   */
  isRespo: function() {
    return this.some(function(m) {
      return m.get("fonction").search("RES") === 0
    });
  },

  initialize: function() {
    this.profile = null;
    this.menuVisibility = new Backbone.Model;
  },
  /**
   * url
   */
  url: function() {
    var Prof = "";

    if (!STR.isBlank(this.profile) && !STR.isBlank(this.parentProfil)) {
      Prof = "/" + encodeURIComponent(this.parentProfil) + "?hierarchique=" + this.profile;
    } else if (!STR.isBlank(this.profile)) {
      Prof = "/" + encodeURIComponent(this.profile);
    }
    return Configuration.restRoot + "/rest/configuration/droit" + Prof;
  },

  parse: function(droits) {
    this.calculateMenuAccess(droits);
    return droits;
  },

  calculateMenuAccess : function(droitsResponse){
		droitsResponse = droitsResponse.map(function(m){
			return m.fonction;
		});
		var ecranHidden = {};
		var menusEmid = [];
		var zoneHidden = [], predicate = function(b){
			return b;
		}, predicateSearchDroit = function(m){
			return droitsResponse.indexOf(m) > -1;

		}, handleMenu = function(menu){
			if(menu.id){
				var _tmp = menu.fonctions.every(predicateSearchDroit);
				var screenId = menu.id;
				if(menu.aspect === "hidden"){
					screenId = screenId + "_R";
				}
				zoneHidden.push(_tmp);
				if(menusEmid.length > 0){ //into submenu
					ecranHidden[menusEmid[menusEmid.length-1]].push(_tmp);
				}
				else{
					ecranHidden[menu.emid] = [_tmp];
				}

				this.menuVisibility.set(menu.emid, _tmp);
				this.menuVisibility.set(screenId, _tmp);
			}else{
				menusEmid.push(menu.emid);
				ecranHidden[menu.emid] = [];
				menu.ecrans.forEach(handleMenu, this);
				if(ecranHidden[menu.emid].some(predicate)){
					zoneHidden.push(true);
					if(menusEmid.length > 1){//submenu son
						ecranHidden[menusEmid[0]].push(true);
					}
					this.menuVisibility.set(menu.emid, true);
				}else{
					zoneHidden.push(false);
					if(menusEmid.length > 1){//submenu son
						ecranHidden[menusEmid[0]].push(false);
					}
					this.menuVisibility.set(menu.emid, false);
				}
				menusEmid.pop();
			}
		};
		objs.appRt.workflow.configuration.get("confignav").get("zones").forEach(function(zone){

			zoneHidden = [];
			zone.menus.forEach(handleMenu, this);
			zone.briques.forEach(function(b){
				var _tmp = b.fonctions.every(predicateSearchDroit);
				this.menuVisibility.set(b.emid, _tmp);
			}, this);
			if(!zoneHidden.some(predicate)){
				this.menuVisibility.set(zone.emid, false);
				this.menuVisibility.set(zone.id, false);
			}else{
				this.menuVisibility.set(zone.emid, true);
				this.menuVisibility.set(zone.id, true);
			}

		}, this);
		objs.appRt.workflow.configuration.get("confignav").get("transverses").forEach(handleMenu, this);
	}
});
