
import { FonctionsModel } from './fonctions.model.js';
import { PaginatedCollection } from 'core/models/paginated.collection.js';

export var FonctionsColl = PaginatedCollection.extend({

  model: FonctionsModel,

  url: Configuration.restRoot + "/rest/utilbadgvirtuel/fonctions",

  initialize: function() {
    PaginatedCollection.prototype.initialize.call(this);
    this.sortings = { code: true };
    this.paginated = false;
  }
});