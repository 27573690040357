import { CombosColl } from 'core/components/combo/combos.collection.js';
import { DynamicComboModel } from './dynamicCombo.model.js';
import { HEADERS } from '../../../../utils/headers.js';
import { PaginatedCollection } from 'core/models/paginated.collection.js';
import { STR } from '../../../../utils/str.js';

export var DynamicComboModelColl = CombosColl.extend({

  model: DynamicComboModel,

  initialize: function(options) {
    if (!STR.isBlank(options)) {
      if (options.modifyHeader === true) {
        options = _.extend(options, { headers: { "gfi-ecran": '"RES_PLANNING"' } });
      }

      this.urlService = options.url;
      this.defaut = options.defaut;
      this.filtre = options.filtre;
      this.refForm = options.refForm;

    }

    PaginatedCollection.prototype.initialize.call(this, options);
  },

  url: function() {
    var dates = "";
    if (!STR.isBlank(this.defaut) && !STR.isBlank(this.datedeb)) {
      dates = "&datedeb=" + this.datedeb + "&datefin=" + this.datefin;
    } else if (STR.isBlank(this.defaut) && !STR.isBlank(this.datedeb)) {
      dates = "?datedeb=" + this.datedeb + "&datefin=" + this.datefin;
    }
    var filtre = "";

    if (this.filtre && !STR.isBlank(dates)) {
      filtre = '&filtre=true'
    }
    return Configuration.restRoot + "/" + this.urlService + (!STR.isBlank(this.defaut) ? '?valeurinit=' + this.defaut : '') +
      (!STR.isBlank(dates) ? dates : '') + filtre;
  },
  sync: function(model, xhr, options) {
    var options = options || {};
    if (this.filtre == true) {
      options.headers = _.extend(options.headers, HEADERS.populationContext(this.refForm.popId, this.refForm.popType));
    }
    return CombosColl.prototype.sync.apply(this, arguments);
  }

});