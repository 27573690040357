module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="phx-multiLabel-bloc" data-bloc-name="D" data-bloc-label="'+
((__t=(i18n.t('common:gererprets.date')))==null?'':__t)+
'">\n  <label for="datedeb" class="phx-inline-label">&nbsp;</label>\n  <input type="text" class="datedeb typeDate" size="10">\n  <span class="datedeb-error-container"></span>\n</div>\n<div class="phx-multiLabel-bloc" data-bloc-name="P" data-bloc-label="'+
((__t=(i18n.t('common:gererprets.periode')))==null?'':__t)+
'">\n  <div class="multiLabelTypeSaisieGererPrets">\n    <fieldset class="multiLabelTypeSaisieGererPrets_form">\n      <div class="phx-line">\n        <label for="datedeb">'+
((__t=(i18n.t('common:gererprets.periodeDeb')))==null?'':__t)+
'</label>\n        <input type="text" class="datedeb typeDate periodStart" periodId="1" size="10" /></input>&nbsp;&nbsp;\n        <label for="modesai_periodeb.code" style="display:none" class="phx-inline-label"></label>\n        <span class="comboUniteDeb"></span>\n\n        <span class="heuredeb-container">\n					<div style="float: right; margin-left: 20px;">\n						<input type="text" class="heuredeb typeHourMinuteNightly" size="5">\n					</div>\n				</span>\n      </div>\n      <div class="phx-line">\n        <label for="datefin">'+
((__t=(i18n.t('common:gererprets.periodeFin')))==null?'':__t)+
'</label>\n        <input type="text" class="datefin typeDate periodEnd" periodId="1" size="10" /></input>&nbsp;&nbsp;\n        <label for="modesai_periofin.code" style="display:none" class="phx-inline-label"></label>\n        <span class="comboUniteFin"></span>\n        <span class="heurefin-container">\n					<div style="float: right; margin-left: 20px;">\n						<input type="text" class="heurefin typeHourMinuteNightly" size="5">\n					</div>\n				</span>\n      </div>\n    </fieldset>\n  </div>\n  <span class="datedeb-error-container"></span>\n  <span class="datefin-error-container"></span>\n  <br>\n</div>\n<div class="phx-multiLabel-bloc" data-bloc-name="R" data-bloc-label="'+
((__t=(i18n.t('common:gererprets.repetition')))==null?'':__t)+
'">\n  <label for="datedeb" class="phx-inline-label">&nbsp;</label>\n  <input type="text" class="datedeb typeDate" size="10">\n  <label for="datefin" class="phx-inline-label">'+
((__t=(i18n.t('common:gererprets.au')))==null?'':__t)+
'</label>\n  <input type="text" class="datefin typeDate" size="10">\n  <span class="datedeb-error-container"></span>\n  <span class="datefin-error-container"></span>\n</div>\n';
}
return __p;
};
