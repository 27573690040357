import { DialogView } from 'core/views/dialog.view.js';
import { FormView } from 'core/views/form.view.js';
import { GLOBAL_DATA } from '../../../../globalData.js';
import { HABILITATION } from '../../../../utils/habilitation.js';
import { HabilitationContext } from 'core/models/habilitationContext.js';
import { i18n } from '../../../../i18n.js';
import { LOG } from '../../../../utils/log.js';
import { objs } from '../../../../objectsRepository.js';
import { PopulationColl } from '../models/population.collection.js';
import { PopulationModel } from '../models/population.model.js';
import { PopulationGenerationModel } from '../models/populationStructure.model.js';
import { PopupSelectCollabView } from './popupSelectCollab.view.js';
import { PopupSelectStructureView } from './popupSelectStructure.view.js';
import { PopulationSelectColaborateursModel as CollaborateurModel } from '../models/populationSelectColaborateurs.model.js';
import { STR } from '../../../../utils/str.js';
import TPL_populationsmenu_populationMenu from '../populationMenu.tpl.html';
import TPL_populationsmenu_structuresMenu from '../structuresMenu.tpl.html';
import { TypeStructureColl } from '../models/typeStructure.collection.js';
import { TYPE } from '../../../../tda/tda.js';
import { PlainStructuresView } from './plainStructures.view.js';
import { SYNC } from '../../../../utils/sync.js';

export var PopulationMenuView = FormView.extend({

  className: "phx-populations-menu-zone",

  initialize: function(options) {

    var self = this;
    this.template = TPL_populationsmenu_populationMenu;
    this.templateStructure = TPL_populationsmenu_structuresMenu;
    this.dialog = {};
    this.isVisible = false;
    this.nbMaxPopEntete = 2;
    this.showPopulationPlus = false;
    this.workflow = false;
    this.populationColl = new PopulationColl();
    this.diversIdCollab = GLOBAL_DATA.paramDivers.get("idCollab");

    if (options && !STR.isBlank(options.workflow)) {
      this.workflow = options.workflow;
    }

    if (options && !STR.isBlank(options.selectedDefaultPopulationData)) {
      this.selectedDefaultPopulationData = options.selectedDefaultPopulationData;
    }

    this.habContext = new HabilitationContext({
      onglet: "populationsmenu",
      fonc_cour: "RES_POPCOLLAB.V"
    });

    this.model = new PopulationModel();
    this.model.set("libelle", i18n.t('populationsmenu.tous'));
    this.model.set("type", "D");
    this.model.set("ident", null);

    if (options && !STR.isBlank(options.selectedPopulation)) {
      this.model.set("ident", options.selectedPopulation);
    }

    this.model.setHabContext(this.getHabContext());

    if (STR.isBlank(options) || options.disableInvalidation != true) {
      // listen to UC which invalidates population active
      this.listenTo(objs.appRt.workflow, "change:usecase", function(workflow) {
        if (workflow.get("zone") === "resp") {
          var usecase = objs.appRt.workflow.get("usecase");
          if (!STR.isBlank(usecase) && objs[usecase + "Rt"]) {
            self.invalidatePopulation(objs[usecase + "Rt"].invalidatePopulation);
          } else {
            self.invalidatePopulation(false);
          }
        }
      });
    }

    this.canViewStructures = HABILITATION.canView("RES_POPCOLLAB.G");

    this.listenTo(self, "structures:loaded", function(data) {
      self.renderStructuresList(data);
    });

    this._getStructures();
  },

  events: {
    "click .structureSelectorMenu": "_selectStructureSelector",
    "click .phx-population-menu-icon": "_showList",
    "click .phx-population-menu-header": "_showList",
    "click .menuPopulationPlus": "_showPopulationPlus",
    "click .menuPopulationIdent": "_changeActivePopulation",
    "click .phx-populations-menu-select-collab": "_openSelectorCollab"
  },

  _getStructures: function(callback) {
    var self = this;
    //Comprobar que se tiene la habilitacion para las structuras
    this.structuresArray = [];
    if (this.canViewStructures) {
      this.structure = new TypeStructureColl({ indselpop: true });
      this.structure.setHabContext(new HabilitationContext({
        onglet: "populationsmenu",
        fonc_cour: "RES_POPCOLLAB.G"
      }));
      this.structure.fetch({
        success: function(dataStructuresModel, dataRaw) {
          //var dataFiltered = self.filterStructures(dataStructures);
          var dataFiltered = dataRaw;
          self.structuresArray = dataFiltered;
          self.trigger("structures:loaded", dataFiltered);

          if (callback) {
            callback();
          }
        }
      });
    } else {
      if (callback) {
        callback();
      }
    }
  },

  _setCollaborateurOnTraversalComponent: function(model, callback) {
    var habContext = new HabilitationContext({
        onglet: "populationsmenu",
        fonc_cour: "RES_POPCOLLAB.G"
      }),
      PopulationGenerationModelTemp = new PopulationGenerationModel({
        code: model.get("matric"),
        nature: "C",
        desc: null
      });

    PopulationGenerationModelTemp.setHabContext(habContext);
    PopulationGenerationModelTemp.save(null, {
      success: callback
    })
  },

  _clearTooltipStructure: function() {
    $(this.el).find(".phx-population-menu-header").get(0).title = "";
    $(this.el).find(".phx-population-menu-header").tooltip({ content: "" });
  },

  _filterStructures: function(dataStructures) {
    var dataStructuresFiltereds = [];
    dataStructuresFiltereds = _.filter(dataStructures, function(item) {
      return dataStructures[item].structureSelPopActiv == true;
    });
    return dataStructuresFiltereds;
  },

  renderStructuresList: function(list) {
    var fragment = document.createDocumentFragment(),
      self = this;
    _.each(list, function(item, index) {
      if (item.rattcolpar === "O" || item.rattcolpar === "F") {
        var data = {
            "element": item,
            "i18n": i18n
          },
          tmpElement = $(self.templateStructure(data))[0];
        fragment.appendChild(tmpElement);
      }
    });
    this.$el.find(".structure-elements-container").append(fragment);
  },

  _getContextSelectorChemin: function(structure) {
    return {
      ctxEcran: 'populationsmenu',
      ctxStructure: structure.code,
      ctxStructureCollab: false,
      ctxStructureActivites: false,
      ctxStructureSelpop: true,
      ctxInitChemins: null,
      ctxPeriodeRecherche: {
        datedebut: structure.debsitu,
        datefin: structure.finsitu
      },
      ctxHabilitation: this.habContext,
      ctxActivModeComplet: true,
      ctxAfficheRacine: true,
      ctxConsultation: false,
      ctxSelRacineAuto: false,
      ctxSelNoeudChemin: true,
      ctxActivSelMultiple: false,
      format: structure.format,
      ctxSelRemDesc: structure.typeremdesc,
      recine: structure.racine,
      name: structure.codef
    };
  },

  _setStructureOnTraversalComponent: function(selection, descendence) {
    var self = this,
      habContext = new HabilitationContext({
        onglet: "populationsmenu",
        fonc_cour: "RES_POPCOLLAB.G"
      }),
      PopulationGenerationModelTemp = new PopulationGenerationModel({
        code: selection.at(0).get("id"),
        nature: "S",
        desc: descendence
      });

    PopulationGenerationModelTemp.setHabContext(habContext);
    PopulationGenerationModelTemp.save(null, {
      success: function(model, rawData) {
        self.model = new PopulationModel();
        self.setHabContext(self.getHabContext());
        self.model.set("ident", rawData.ident);
        self.model.set("type", "D");
        self.model.set("nature", "S");
        self.model.set("desc", descendence);
        self.model.set("libelle", selection.at(0).get("label"));
        self.model.set("code", selection.at(0).id);
        self.model.set("struct", self.structure.codestructureRequest);
        self.id = rawData.ident;
        objs.appRt.workflow.syncModel.trigger("change:population", model);
      }
    })
  },
  _selectStructureSelector: function(event) {
    event.preventDefault();
    event.stopPropagation();
    var structureIdentifier = $(event.currentTarget).data("codestructure"),
      self = this;
    this.structure.indselpop = false;
    this.structure.codestructureRequest = structureIdentifier;
    this.structure.fetch({
      success: function(model, dataRaw) {
        var dataContext = self._getContextSelectorChemin(dataRaw);

        self.dialog[dataContext.name] = new DialogView({
          view: PopupSelectStructureView,
          viewData: {
            workflow: self.workflow,
            parent: self,
            context: dataContext,
            habContext: self.getHabContext()
          }
        });
        var dialog = self.dialog[dataContext.name],
          dialogModel = dialog.model;

        dialog.setHeight(180);
        dialog.setWidth(330);

        dialog._setTitle(i18n.t("messages:GT_2128", { "1": dataRaw.libselpop }));
        dialogModel.trigger("dlg:open");
      }
    })

  },
  render: function(callback) {
    var self = this;
    var json = { 'i18n': i18n, habilitation_gerer: HABILITATION.canView("RES_POPCOLLAB.G") };

    this.$el.append(this.template(json));
    this.populationColl.setHabContext(this.getHabContext());

    var onSucessFunction = function(list) {
      if (self.model.get("ident")) {
        self.setPopulationActive(self.model.get("ident"));
      }
      if (callback) {
        callback(list);
      }
    };

    if (HABILITATION.canView("RES_POPCOLLAB.V")) {
      this.populationColl.fetch({
        success: function(list) {
          onSucessFunction(list);
        }
      });
    } else {
      if (self.model.get("ident") && !STR.isBlank(this.selectedDefaultPopulationData)) {
        var model = new PopulationModel();
        model.id = this.selectedDefaultPopulationData.id;
        model.set("ident", this.selectedDefaultPopulationData.ident);
        model.set("code", this.selectedDefaultPopulationData.id);
        model.set("libelle", this.selectedDefaultPopulationData.libelle);
        model.set("type", this.selectedDefaultPopulationData.type);
        this.populationColl.add(model);
      }
      onSucessFunction(this.populationColl);
    }

    $(this.el).find(".phx-population-menu-header").keydown(function(e) {
      self._headerKeyboradNavigation(e);
    });
    $(this.el).find(".phx-populations-menu-list").hide();

    //prepare keyboard navigation for each link at menu
    var menuLinks = $(this.el).find(".phx-populations-menu-list li.phx-menu-link a");
    _.each(menuLinks, function(link) {
      $(link).keydown(function(e) {
        self._keyboardNav(e, false);
      });
    });

    //Check if the user can create populations
    var ptgVisiPop;
    if (GLOBAL_DATA.paramDivers.get("ptgVisiPop") && GLOBAL_DATA.paramDivers.get("ptgVisiPop").get("valeur")) {
      ptgVisiPop = parseInt(GLOBAL_DATA.paramDivers.get("ptgVisiPop").get("valeur"), 10);
    }
    if (!((HABILITATION.canCreate("RES_POPCOLLAB.G") && ptgVisiPop !== 2) ||
        (HABILITATION.canCreate("RES_POPTOUS.G") && ptgVisiPop === 2))) {
      $(this.el).find(".create-population-opt").hide();
    }

    return this;
  },

  repaintPopulation: function(callback) {
    var self = this;

    this.setPopulationActive();

    // Rerender menu with current habilitation
    var json = { 'i18n': i18n, habilitation_gerer: HABILITATION.canView("RES_POPCOLLAB.G") };
    this.$el.html(this.template(json));

    // Hide menu
    $(this.el).find(".phx-populations-menu-list").hide();
    this.isVisible = false;

    // Get populations
    this.fetchingData = false;
    this._addElements(null, function() {
      //Hide the list
      self._showList();

      self._getStructures(function() {
        if (self.model.get("ident")) {
          self.setPopulationActive(self.model.get("ident"));
        }

        if (callback) {
          callback();
        }
      });
    });
  },

  _eventMouseUp: function() {
    var self = this;
    //If you click out of the menu, close the menu.
    $(document).one("mouseup", function(event) {
      var menuElements = $(".phx-populations-menu-list", self.el).find(event.target).length;
      var headerElements = $(self.el).find(event.target).length - menuElements;
      if (headerElements == 0) {
        //Don't close menu if mouseup is on scroll bar customer 160164
        if (event.target.className === "generated-list-elements-container") {
          self._eventMouseUp(event);
        } else {
          $(self.el).find(".phx-populations-menu-list").hide();
          self.isVisible = false;
        }
      }
    });
  },

  _showList: function(event, callback) {
    if (this.isVisible === false) {
      this._addElements(event, callback);
      this._eventMouseUp(event);
    } else {
      $(this.el).find(".phx-populations-menu-list").hide();
      this.isVisible = false;
    }
  },

  _addElements: function(event, callback) {
    var self = this;
    if (this.fetchingData !== true) {
      this.fetchingData = true;
      this.populationColl.setHabContext(this.getHabContext());

      var onSucessFunction = function(list) {
        var html = self.generatePopulationListElements(list);
        $(self.el).find("ul.phx-populations-menu-list .generated-list-elements-container").html(html);
        self.fetchingData = false;

        //Show the list
        $(self.el).find(".phx-populations-menu-list").show();
        self.isVisible = true;
        var $menuList = $(self.el).find("ul.phx-populations-menu-list");
        var topObject = $menuList.offset().top;

        //Get height size of the windows (normally px unit)
        var windowsHeight = $(window).height();

        //Get height size of the pop up object (normally px unit)
        var contextMenuheight = $menuList.height();
        var scrollsize = 20;

        //maxScrollHeight is all height size without all under first separator (under scroll bar)
        var maxScrollHeight = windowsHeight;
        if ($(self.el).find("ul.phx-populations-menu-list .ui-menu-divider").length > 0) {
          maxScrollHeight = $(self.el).find("ul.phx-populations-menu-list .ui-menu-divider")[0].offsetTop;
        }
        //Population part
        var $elementList = $(self.el).find("ul.phx-populations-menu-list .generated-list-elements-container");

        //customer 160164 : If all pop up cannot be displayed with current position get pop up (only population elements) with scroll
        if (topObject + contextMenuheight > windowsHeight) {
          var maxHeight = windowsHeight - topObject - scrollsize;
          $elementList.css("overflow-x", "hidden");
          $elementList.css("overflow-y", "scroll");
          $elementList.css("display", "inline-block");

          //10px is security to display all block
          var separatorSize = 10;

          //Lower menu is all height size under first separator (under scroll bar) + separator size
          var lowerMenuSize = contextMenuheight - maxScrollHeight + separatorSize;
          $elementList.css("max-height", maxHeight - lowerMenuSize);
          $menuList.css("max-height", maxHeight);
        } else {
          $elementList.css("overflow-x", "hidden");
          $elementList.css("overflow-y", "hidden");
          $elementList.css("display", "");
          $elementList.css("max-height", "");
          $menuList.css("max-height", "");
        }
        self._prepareKeyboardNav();
        if (callback) {
          callback(event);
        }
      };

      if (HABILITATION.canView("RES_POPCOLLAB.V")) {

        this.populationColl.fetch({
          success: function(list) {
            onSucessFunction(list);
          },
          error: function() {
            $(self.el).find("ul.phx-populations-menu-list .generated-list-elements-container").html("");
            self.fetchingData = false;

            //Show the list
            $(self.el).find(".phx-populations-menu-list").show();
            self.isVisible = true;
          }
        });
      } else {
        onSucessFunction(this.populationColl);
      }
    }
  },

  generatePopulationListElements: function(list) {
    var elements = "";

    //Tots el collabs
    elements += "<li class='ui-menu-item phx-hover ui-corner-all'>";
    if (STR.isBlank(this.model.get("ident"))) {
      elements += "<span class='view-menu-icon phx-icon phx-icon-planresp-valider'/>";
    }
    elements += "<a href='javascript:void(0)' tabindex='-1'  class='menuPopulationIdent'>" + i18n.t('populationsmenu.tous') + "</a></li>";

    //Elements retrieved by the fetch
    var itemsToShow = this.showPopulationPlus ? list.length : Math.min(this.nbMaxPopEntete, list.length);
    for (var i = 0; i < itemsToShow; i++) {
      if (HABILITATION.canView("RES_POPCOLLAB.V")) {
        var model = list.models[i];
        elements += "<li class='ui-menu-item phx-hover ui-corner-all'>";
        if (model.get("ident") == this.model.get("ident")) {
          elements += "<span class='view-menu-icon phx-icon phx-icon-planresp-valider'/>";
        }
        elements += "<a href='javascript:void(0)' tabindex='-1' class='menuPopulationIdent' data-value='" + model.get("ident") + "'>" +
          model.get("libelle") + "</a></li>";
      }
    }

    if (list.length > this.nbMaxPopEntete && this.showPopulationPlus === false) {
      elements += "<li class='ui-menu-item phx-menu-link phx-hover ui-corner-all'>" +
        "<a href='javascript:void(0)' tabindex='-1' class='menuPopulationPlus'>" +
        "... " + i18n.t('populationsmenu.plusPopulations') + "</a></li>";
    }
    this.showPopulationPlus = false;
    return elements;
  },

  _showPopulationPlus: function(event) {
    this.showPopulationPlus = true;
    this._showList(event);
  },

  _openSelectorCollab: function() {
    var self = this;
    var dialog = new DialogView({
      view: PopupSelectCollabView,
      viewData: {
        parentView: self
      }
    });
    dialog.setHeight(150);
    dialog.setWidth(300);

    var dialogModel = dialog.model;
    dialogModel.trigger("dlg:open", function() {
      if (!STR.isBlank(dialogModel.get("collab"))) {
        self._setCollaborateurActive(dialogModel.get("ident"), dialogModel.get("collab"));
      }
    });

  },

  _setCollaborateurActive: function(ident, model) {
    this._clearTooltipStructure();

    $(this.el).find(".phx-population-menu-header").html(model.get("libelle"));

    var selectedModel = new PopulationModel();
    selectedModel.id = ident;
    selectedModel.set("ident", ident);
    selectedModel.set("code", model.get("matric"));
    selectedModel.set("type", "D");
    selectedModel.set("nature", "C");
    selectedModel.set("libelle", model.get("libelle"));
    selectedModel.set("desc", null);
    selectedModel.set("struct", null);

    this.populationColl.add(selectedModel);
    this.model = selectedModel.clone();

    objs.appRt.workflow.syncModel.trigger("change:population", selectedModel);
  },

  _changeActivePopulation: function(event) {
    // get selected population
    var ident = $(event.target).attr("data-value");

    this.setPopulationActive(ident);

    objs.appRt.workflow.syncModel.trigger("change:population", this.model);

    return false;
  },

  _headerKeyboradNavigation: function(e) {
    if (e.keyCode == 13 || e.keyCode == 32 || e.keyCode == 40) {
      //Enter spacebar down
      this._showList(e, function(e) {
        $(e.target).closest("div.phx-populations-menu").find("a").first().focus();
        e.preventDefault();
      });
    }
  },

  _prepareKeyboardNav: function() {
    var self = this;
    var populationsListLinks = $(this.el).find(".phx-populations-menu-list .generated-list-elements-container li a");

    //Prepare keyboard navigation for each population link
    _.each(populationsListLinks, function(link) {
      $(link).keydown(function(e) {
        self._keyboardNav(e, true);
      });
    });
  },

  _keyboardNav: function(e, isPopulation) {
    if (e.keyCode == 38) { // up
      LOG.debug("up");
      var menuLink = $(e.target).closest("li");
      if (menuLink.prevAll().first().length == 0) {
        //Focus last menuitem
        var parentMenu = menuLink.closest('div.phx-populations-menu');
        var lastLiElement = parentMenu.find(".phx-populations-menu-list").find("li:not(.ui-menu-divider)").last();
        lastLiElement.find("a").focus();
      } else {
        //Focus previous item
        var prevMenu = menuLink.prevAll().first();
        prevMenu = this._discardSeparators(prevMenu, "UP");
        this._selectFocusable(prevMenu, "UP");
      }
    } else if (e.keyCode == 40) {
      // down
      LOG.debug("down");
      var menuLink = $(e.target).closest("li");
      if (menuLink.nextAll().first().length == 0) {
        //Focus first item
        if (menuLink.closest(".generated-list-elements-container").length != 0) {
          //The current link is a population link
          menuLink.closest(".phx-populations-menu-list").find("> li:not(.ui-menu-divider)").first().find("a").focus();
        } else {
          //The current link is not a population
          //Focus first menuitem
          var parentMenu = menuLink.closest('div.phx-populations-menu');
          var lastLiElement = parentMenu.find(".phx-populations-menu-list").find("li:not(.ui-menu-divider)").first();
          lastLiElement.find("a").focus();
        }
      } else {
        //Focus next item
        var nextMenu = menuLink.nextAll().first();
        nextMenu = this._discardSeparators(nextMenu, "DOWN");
        this._selectFocusable(nextMenu, "DOWN");
      }
    } else if (e.keyCode == 32) {
      // spacebar
      LOG.debug("espace");
      e.target.click();
    } else if (e.keyCode == 27) {
      // escape
      LOG.debug("escape");
      var parentMenu = $(e.target).closest('div.phx-populations-menu');
      parentMenu.find(".phx-population-menu-header").focus();
      //Simulate click in the menuBar in order to close drop/down menu
      parentMenu.find(".phx-population-menu-header").click();
    } else if (e.keyCode == 9) {
      // tab
      LOG.debug("espace");
      //If menu is visible close menu
      var ulMenu = $(e.target).closest(".phx-populations-menu-list");
      if (ulMenu.is(":visible")) {
        var parentMenu = $(e.target).closest('div.phx-populations-menu');
        parentMenu.find(".phx-population-menu-header").click();
      }
    }
  },

  _discardSeparators: function(prevMenu, action) {
    if (action == "UP") {
      if (prevMenu.hasClass("ui-menu-divider")) {
        prevMenu = this._discardSeparators(prevMenu.prevAll().first(), action);
      }
    } else if (action == "DOWN") {
      if (prevMenu.hasClass("ui-menu-divider")) {
        prevMenu = this._discardSeparators(prevMenu.nextAll().first(), action);
      }
    }
    return prevMenu;
  },

  /*
   * Select the link in selectedMenu
   * selectedMenu can be a li containing a menu selector or a span containing all populations
   */
  _selectFocusable: function(selectedMenu, action) {
    if (selectedMenu.hasClass("generated-list-elements-container")) {
      //Populations menu
      if (action) {
        if (action == "UP") {
          //Select Last element from populations menu
          selectedMenu.find("li").last().find("a").focus();
        } else if (action == "DOWN") {
          //Select first element from populations menu
          selectedMenu.find("li").first().find("a").focus();
        }
      }
    } else if (selectedMenu.hasClass("phx-menu-link") || selectedMenu.hasClass("ui-menu-item")) {
      //Links menu
      selectedMenu.find("a").first().focus();
    }
  },

  /**
   * updatePopulationStructureLibelle - Load and render structure libelle and
   * tooltip in population component
   *
   * @param  {type} populationModel description
   * @return {type}                 description
   */
  updatePopulationStructureLibelle: function(populationModel) {
    var today = TYPE.DATE.parse(TYPE.DATE.dateToStr(SYNC.getServerDate())).val;
    var structureData = {
      debsitu: today,
      finsitu: today,
      format: 1,
      itemcode: populationModel.get("code"),
      itemid: populationModel.get("code"),
      itemlib: "",
      structid: populationModel.get("struct"),
      structlib: "",
      racine: 1,
      codef: populationModel.get("struct"),
      code: populationModel.get("struct"),
      typeremdesc: 2
    };

    var objModel = new Backbone.Model({
      debsitu: structureData.debsitu,
      finsitu: structureData.finsitu,
      format: structureData.format,
      itemcode: structureData.itemcode,
      itemid: structureData.itemid,
      itemlib: structureData.itemlib,
      structid: structureData.structid,
      structlib: structureData.structlib
    });

    var structToPaint = {
      model: objModel,
      structCode: structureData.structid,
      structure: structureData.structlib,
      debsitu: structureData.debsitu,
      finsitu: structureData.finsitu
    };

    // we create the View with the option table = false to adapt the html.(because is not a table)
    var view = new PlainStructuresView({
      model: structToPaint,
      table: false,
      context: this._getContextSelectorChemin(structureData),
      plainText: true,
      populationColl: this.populationColl
    });

    view.render();
    $(this.el).find(".phx-population-menu-header").html(view.el);
  },

  setPopulationActiveFromStructure: function(populationModel, callback) {

    this.updatePopulationStructureLibelle(populationModel);
    this.populationColl.add(populationModel);
    this.model = populationModel.clone();

    if (callback) {
      callback();
    }
  },

  setPopulationActiveFromCollab: function(populationModel, callback) {
    var self = this;
    var collabModel = new CollaborateurModel();
    collabModel.id = populationModel.get("code");
    collabModel.setHabContext(self.getHabContext());
    collabModel.params = {};
    collabModel.params.matric = populationModel.get("code");
    collabModel.fetch({
      url: Configuration.restRoot + "/rest/population/collaborateurs",
      success: function(fresh) {
        if (fresh && fresh.get(0)) {
          var libelle = fresh.get(0).nom + " " + fresh.get(0).prenom + " (";
          // Default value is matric
          if (self.diversIdCollab.get("valeur") === "matric_aux") {
            libelle += fresh.get(0).matricaux;
          } else {
            libelle += fresh.get(0).matric;
          }
          libelle += ")";

          populationModel.set("libelle", libelle);
          $(self.el).find(".phx-population-menu-header").html(libelle);

          // self.populationColl.add(populationModel);
          // self.model = populationModel;

          if (callback) {
            callback();
          }
        }
      }
    });
  },

  /**
   *
   * @param ident
   * @param defaultModel -> selectedDefaultPopulationData
   */
  setPopulationActive: function(ident, defaultModel, callback) {
    var self = this;
    // set header
    if (ident) {
      this._clearTooltipStructure();

      var activePopulation = this.populationColl.get(ident);
      if (activePopulation) {
        if (activePopulation.get("nature") !== "S") {
          $(this.el).find(".phx-population-menu-header").html(activePopulation.get("libelle"));
        } else {
          this.updatePopulationStructureLibelle(activePopulation);
        }
        this.model.id = activePopulation.get("ident");
        this.model.set("ident", activePopulation.get("ident"));
        this.model.set("code", activePopulation.get("code"));
        this.model.set("type", activePopulation.get("type"));
        this.model.set("libelle", activePopulation.get("libelle"));
        this.model.set("nature", activePopulation.get("nature"));
        this.model.set("desc", activePopulation.get("desc"));
        this.model.set("struct", activePopulation.get("struct"));

        if (callback) {
          callback();
        }
      } else if (defaultModel) {
        if (!HABILITATION.canView("RES_POPCOLLAB.V")) {
          this.populationColl.remove(this.populationColl.models[0]); //Remove previous element
          this.defaultModel = defaultModel;
          var model = new PopulationModel();
          model.id = this.defaultModel.id;
          model.set("ident", this.defaultModel.ident);
          model.set("code", this.defaultModel.code);
          model.set("libelle", this.defaultModel.libelle);
          model.set("type", this.defaultModel.type);
          model.set("struct", this.defaultModel.struct);
          model.set("nature", this.defaultModel.nature);
          model.set("desc", this.defaultModel.desc);
          this.populationColl.add(model);
          this.setPopulationActive(ident, null, callback);
        } else {

          this.defaultModel = defaultModel;

          if (defaultModel.nature === "C" || defaultModel.nature === "S") {
            var populationModel = new PopulationModel();
            populationModel.id = defaultModel.ident;

            populationModel.set("ident", defaultModel.ident);
            populationModel.set("code", defaultModel.code);
            populationModel.set("libelle", defaultModel.libelle);
            populationModel.set("type", defaultModel.type);
            populationModel.set("struct", defaultModel.struct);
            populationModel.set("nature", defaultModel.nature);
            populationModel.set("desc", defaultModel.desc);

            self.populationColl.add(populationModel);
            self.model = populationModel.clone();

            if (defaultModel.nature === "S") {
              self.setPopulationActiveFromStructure(populationModel, callback);
            } else if (defaultModel.nature === "C") {
              self.setPopulationActiveFromCollab(populationModel, callback);
            }
          }
        }
      }
    } else {
      $(this.el).find(".phx-population-menu-header").html(i18n.t('populationsmenu.tous'));
      this.model.set("ident", null);
      this.model.set("type", "D");
      this.model.set("libelle", i18n.t('populationsmenu.tous'));
      this.model.set("nature", null);
      this.model.set("desc", null);
      this.model.set("code", null);
      this.model.set("struct", null);
      this.model.set("structure", false);
      this.model.id = null;
      this.id = null;
    }
  },

  invalidatePopulation: function(invalidate) {
    if (invalidate === true) {
      $(this.el).find(".phx-menu").addClass("ui-phx-population-invalidee");
    } else {
      $(this.el).find(".phx-menu").removeClass("ui-phx-population-invalidee");
    }
  }
});
