import { BaseGridModel } from '../basegrid/baseGrid.model.js';

export var TreeTypeGridModel = BaseGridModel.extend({

  dummy: "",

  /**
   * Constructor
   * Model underlying a Tree type DataGrid
   */
  initialize: function(options) {
    BaseGridModel.prototype.initialize.call(this, options);
  },

  /**
   * Called when do a click on a row, performs the selection of that row.
   * Overrides _manageRowSelection in basegrid
   */
  _manageRowSelection: function(model, options) {
    if (model && options && options.deploy === true) {
      this._expandPath(model);
    }
    BaseGridModel.prototype._manageRowSelection.call(this, model, options);
  },

  /**
   * Method used to expand a path to an specific row
   */
  _expandPath: function(model) {
    var path = model.path;
    var level = model.level;
    if (model.visible === false) {
      _.each(this.coll.models, function(mod) {
        if (level > mod.level && ("" + path).indexOf("" + mod.path) === 0) {
          //the model represents a parent row of the row we want to click
          if (mod.isExpanded === false) {
            mod.trigger("treetbl_exp", mod);
          }
        }
      });
    }

  }
});