import { HEADERS } from '../../utils/headers.js';

/**
 * Model to manage the context of an habilitation
 *
 */
export var HabilitationContext = Backbone.Model.extend({

  dummy: "",

  defaults: {
    onglet: "",
    fonc_cour: "",
    nat_gest: ""
  },

  /**
   * overrideNatureGestion is used in some components (mainly grids) to indicate if the component can modify this value
   * or should use the original value
   */
  initialize: function(attributes, options) {
    this.copyAttributes = _.clone(this.attributes);
    this.overrideNatureGestion = true;
    if (options && _.isBoolean(options.overrideNatureGestion)) {
      this.overrideNatureGestion = options.overrideNatureGestion;
    }
  },

  /**
   * List of the attributs that will be updated
   *
   */
  update: function(attrs) {
    this.copyAttributes = _.clone(this.attributes);

    for (var i in attrs) {
      if (i === "nat_gest") {
        if (this.overrideNatureGestion === true) {
          this.set(i, attrs[i]);
        }
      } else {
        this.set(i, attrs[i]);
      }
    }
  },

  /**
   * Build the gfi-context header used by the WS
   *
   */
  header: function() {
    return HEADERS.habilitationContext(this.attributes.onglet, this.attributes.fonc_cour, this.attributes.nat_gest);
  },

  /**
   * return a copy of the current habilitation context
   *
   */
  copy: function() {
    var ctx = new HabilitationContext(_.clone(this.attributes));
    ctx.overrideNatureGestion = this.overrideNatureGestion;
    return ctx;
  },

  /**
   * reverts the attributs to a previous version (previous update call)
   */
  revert: function() {
    this.attributes = this.copyAttributes;
  }
});