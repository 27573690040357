import { BaseModel } from 'core/models/base.model.js';
import { i18n } from '../../../../i18n.js';
import TPL_calendacc_footer from '../footer.tpl.html';
/**
 * Footer View
 */
export var FooterView = Backbone.View.extend({

  initialize: function(options) {
    this.model = new BaseModel();

    if (options && options.workflow) {
      this.workflow = options.workflow;
    }

    this.template = TPL_calendacc_footer;
    this.workflow.formModel.on("change", this.manageZones, this);
  },

  render: function() {
    var json = { 'i18n': i18n };
    this.$el.html(this.template(json));
    this.manageZones();
    return this;
  },

  manageZones: function() {
    var self = this;
    var zones = this.workflow.formModel.get("zones").models;
    //paint the zones in legend
    /*
    if(zones.get("A")){
      this.$el.find(".colorA").css("background-color",zones.get("A").get("colorRGB"));
      this.$el.find(".colorA-container").show().css("display","inline-block");
    }else{
      this.$el.find(".colorA-container").hide();
    }
    if(zones.get("B")){
      this.$el.find(".colorB").css("background-color",zones.get("B").get("colorRGB"));
      this.$el.find(".colorB-container").show().css("display","inline-block");
    }else{
      this.$el.find(".colorB-container").hide();
    }
    if(zones.get("C")){
      this.$el.find(".colorC").css("background-color",zones.get("C").get("colorRGB"));
      this.$el.find(".colorC-container").show().css("display","inline-block");
    }else{
      this.$el.find(".colorC-container").hide();
    }
    */

    //Dynamic creation of zones
    _.each(zones, function(zone) {
      var footerDiv = $('<div class="color-container" style="display: inline-block;"></div>');
      var divColor = $('<div class="color"></div>');
      var labelCode = $('<span style="margin-left:2px;"></span>');
      var rgb = zone.get('colorRGB');
      divColor.css({ 'background-color': rgb, 'width': '5px', 'height': '10px', 'display': 'inline-block', 'margin-left': '5px' });
      labelCode.html(zone.get('code'));
      footerDiv.append(divColor, labelCode);
      self.$el.find(".calendacc-footer").append(footerDiv);
    });
  }
});
