import { UTILS } from '../../utils/utils.js';
import TPL_app_portlet from '../portlet.tpl.html';

/**
 * Portlet item container
 */
export var PortletView = Backbone.View.extend({

  className: "phx-portlet ui-helper-clearfix ui-corner-all",

  events: {
    "click .phx-portlet-header .ui-icon": "_toggleVisibility",
    "keydown": "_keydownHandler"
  },

  initialize: function(options) {
    this.template = TPL_app_portlet;
    this.title = options.title;
    this.tooltip = options.tooltip;
    this.view = options.view;
  },

  render: function() {
    this.$el.html(this.template());
    $(".phx-porlet-title", this.el).html(this.title);
    $(".phx-portlet-header", this.el).attr("title", this.tooltip);
    $(".phx-portlet-content", this.el).html(this.view.render().el);
    $(this.el).css("position", "relative");

    // Set WAI-ARIA attributes
    $(".phx-porlet-title", this.el).attr("id", this.cid + "-porlet-title"); // it gives id to the title to be used as label.
    this.$el.attr("role", "region");
    this.$el.attr("tabindex", "0");
    this.$el.attr("labelledby", this.cid + "-porlet-title");
    this.$el.attr("aria-expanded", "true");

    return this;
  },

  _keydownHandler: function(e) {
    var key = e.which || e.keyCode;
    if (key === 13 /* enter */ || key === 32 /* space */ ) {
      e.preventDefault();
      this._toggleVisibility();
    }
  },

  _toggleVisibility: function() {
    $(".phx-portlet-header .ui-icon", this.el).toggleClass("ui-icon-minusthick").toggleClass("ui-icon-plusthick");
    // Set WAI-ARIA attributes
    var expanded = UTILS.stringToBoolean(this.$el.attr("aria-expanded"));
    this.$el.attr("aria-expanded", !expanded);
  },

  setTitle: function(title) {
    this.title = title;
    $(".phx-porlet-title", this.el).html(this.title);
  },

  setContentHeight: function(height) {
    $(".phx-portlet-content", this.el).css("max-height", height + "px");
  }
});