import { BaseModel } from 'core/models/base.model.js';

import { DialogView } from 'core/views/dialog.view.js';
import { EtatsColl } from './etats.collection.js';
import { EtatsModel } from './etats.model.js';
import { GeneralView } from '../../suvietat/views/general.view.js';
import { GLOBAL_DATA } from '../../../../globalData.js';
import { HabilitationContext } from 'core/models/habilitationContext.js';
import { LOG } from '../../../../utils/log.js';
import { objs } from '../../../../objectsRepository.js';
import { ReadOnlyModel } from 'core/models/readOnly.model.js';
import { STR } from '../../../../utils/str.js';
import { i18n } from '../../../../i18n.js';

/**
 * Workflow of etats
 */
export var LanceetatWorkflow = ReadOnlyModel.extend({

  defaults: {
    "mode": "C"
  },

  initialize: function(options) {
    // Initialize the constant
    this.defaultCriteresModel = new EtatsModel();

    //Collection with the correct format to use the treegrid table
    this.flatColl = new EtatsColl();

    this.tableModel = null;
    this.btnBarModel = null;
    this.filterModel = null;
    this.formColl = null;
    this.formModel = null;
    this.headerModel = null;
    this.navigationModel = null;

    //parametre divers
    this.parametreDiversIdCollabModel = GLOBAL_DATA.paramDivers.get("idCollab");
    this.parametreDiversExportEtatModel = GLOBAL_DATA.paramDivers.get("IEXCEL");
    this.parametreDiversCalPrev = GLOBAL_DATA.paramDivers.get("CalPrev");
    this.paramIdCollab = this.parametreDiversIdCollabModel.get("valeur");
    this.paramExportEtat = this.parametreDiversExportEtatModel.get("valeur") === "1" ? true : false;

    /**
     * Model of the habilitation context for the UC
     */
    this.habContext = new HabilitationContext({ onglet: 'lanceetat', fonc_cour: { 'resp': 'ETATS.V', 'collab': 'ETATSCOL.V' } });
    this.defaultCriteresModel.setHabContext(this.getHabContext());
    this.flatColl.setHabContext(this.getHabContext());

    if (options && options.loc) {
      this.loc = options.loc;
    }

    // ready = true when the setUp is finished. Just one setUp per life
    // cycle of the module
    this.set("ready", false);
  },

  /**
   * Set up the workflow. Link the models between them
   *
   */
  setUp: function(callback) {
    // Declare events consumers
    this.filterModel.on("search", this._filterTable, this);
    this.tableModel.on("change:value", this._tableValueChange, this);
    this.navigationModel.on("change:navigation", this._navigateRow, this);
    this.btnBarModel.on("btn:click", this._buttonAction, this);
    this.listenTo(this, "change:mode", this._manageMode);
    this.formModel.on("form:edited", this._formEdited, this);
    this.btnBarModel.trigger("exportEtat", this.paramExportEtat);

    //If we are in usecase planning, make a previous call to store current planning criteres
    this._checkOpenedFromPlanning();
    if (this.openedFromPlanning) {
      var self = this;
      var preferencesPlanning = objs.planrespRt.workflow.preferences.clone(); //méthode clone dans common/preferences.js

      //Si les deux populations sont "tous les collaborateurs, on doit envoyer 0
      preferencesPlanning.temporaire = true;
      //update valeur de population, calques et periode du planning
      if (!STR.isBlank(objs.populationMenu) && !STR.isBlank(objs.populationMenu.model.get("ident"))) {
        //n'est pas "tous les collaborateurs"
        preferencesPlanning.set("popid", objs.populationMenu.model.get("ident"));
        preferencesPlanning.set("poptype", objs.populationMenu.model.get("type"));
        preferencesPlanning.set("poplib", objs.populationMenu.model.get("libelle"));
      } else {
        //Si les deux populations sont "tous les collaborateurs, on doit envoyer null
        preferencesPlanning.set("popid", 0);
        preferencesPlanning.set("poptype", "D");
        preferencesPlanning.set("poplib", i18n.t('populationsmenu.tous'));
      }

      //EVO 518
      if (parseInt(preferencesPlanning.getPreferenceValue("PL_PERIODE", "varint1") /*pertype*/ , 10) !== 1) {
        var attrs = {
          varint1: 1, //pertype
          varchar2: "" //perunit
        };
        preferencesPlanning.setPreferenceValue("PL_PERIODE", attrs, { silent: true });

      }

      preferencesPlanning.setHabContext(new HabilitationContext({
        onglet: "lanceetat",
        fonc_cour: "RES_GCOLLAB.G",
        nat_gest: ""
      }));
      preferencesPlanning.url = Configuration.restRoot + "/rest/planning/critere/temporaire";

      preferencesPlanning.save(null, {
        success: function() {
          self.preferencesPlanningPopulation = {
            popid: preferencesPlanning.get("popid"),
            poptype: preferencesPlanning.get("poptype"),
            poplib: preferencesPlanning.get("poplib")
          }
          self._loadData(callback);
        }
      });
    } else {
      this._loadData(callback);
    }

    this.set("panel", 0);
    this.set("ready", true);
  },

  _checkOpenedFromPlanning: function() {
    this.openedFromPlanning = false;

    var idUc = this.loc.zone + "_" + this.loc.uc;
    if (idUc == "resp_planresp") {
      this.openedFromPlanning = true;
    }
  },

  _loadData: function(callback) {
    //collection fetch
    var coll = new EtatsColl();
    coll.set("definitions", []);

    coll.setHabContext(new HabilitationContext({
      onglet: "lanceetat",
      fonc_cour: { "resp": "ETATS.V", "collab": "ETATSCOL.V" },
      nat_gest: ""
    }));

    var self = this;
    coll.fetch({
      success: function(fresh) {
        self._arrayToCollection(fresh); //this.flatColl
        self.tableModel.coll.totalRecords = self.flatColl.length;
        self.tableModel.coll.reset(self.flatColl.models);
        self.trigger("checkFournisseur");
        if (self.loc) {
          if (callback) {
            callback(function(rowSelected) {
              var numEtat = self._getNumEtatByLoc();
              if (!STR.isBlank(numEtat)) {
                var rowSelected = self.tableModel.coll.get(numEtat);
                self.tableModel.selectRow(rowSelected);
              }
            });
          }
        } else if (callback) {
          callback();
        }
      }
    });
  },

  _getNumEtatByLoc: function() {
    var zone = this.loc.zone;
    var uc = this.loc.uc;
    var idUc = zone + "_" + uc;
    var array = [];
    array["resp_suivicollab"] = 410; //Détail journée"
    array["resp_planresp"] = 1300;
    array["resp_presabs"] = 260;
    array["resp_anomalie"] = 1170;
    array["resp_anovalor"] = 440;
    array["ref_categories"] = 330;
    array["ref_metiers"] = 20;
    array["ref_qualification"] = 20;
    array["ref_statut"] = 530;
    array["ref_grptravail"] = 420;
    array["ref_arrete"] = 310;
    array["ref_vacances"] = 270;
    array["ref_calendiv"] = 280;
    array["ref_motifsabs"] = 140;
    array["ref_horairej"] = 90;
    array["ref_proghoraire"] = 100;
    array["ref_cyclehor"] = 110;
    array["ref_motifsregul"] = 70;
    array["ref_motifanomalie"] = 160;
    array["ref_compteur"] = 300;
    array["ref_reglecalcul"] = 250;
    array["ref_calcprev"] = 340;
    array["ref_calcprevcompteur"] = 340;
    array["ref_calcprevanomalie"] = 340;
    array["ref_calcprevarrete"] = 340;
    array["ref_paramdivers"] = 50;
    array["ref_tablebase"] = 820;
    array["ref_codiftable"] = 810;

    array["adm_utilisateur"] = 450;
    array["adm_sitebadgeage"] = 40;
    array["adm_terminal"] = 380;
    array["adm_typeterm"] = 320;

    return array[idUc];
  },

  /**
   * Do an action when a row is selected in the grid.
   */
  _tableValueChange: function(model) {
    var self = this;
    var value = model.get("value");
    var id = (value == null) ? null : value.get("code");

    // Update Btn Bar and Form
    if (value == null) {
      this.headerModel.set("value", this.defaultCriteresModel);
      this.formColl.trigger("sync");
    } else {
      // Fetch the complete data of the model before notifying the form
      if (value.level !== 1) {
        this.set("mode", "R");

        var newHab = this.getHabContext().clone();
        newHab.update({ fonc_cour: value.get("fonction").code });
        this.formColl.setHabContext(newHab);
        this.formColl.thcollab = value.get("thcollab")
        this.formColl.id = id;
        this.formColl.set("souscriteres", []);

        var params = null;
        var numEtatSelected = this.tableModel.get("value").get("code");
        // If is opened since the planning and the edition selected is "planning par criteres"
        if (this.openedFromPlanning && numEtatSelected == 1300) {
          params = {
            headers: { "gfi-ecran": '"RES_PLANNING"' },
            success: function(fresh) {
              self.formView.reinitModel(null, fresh.get("critid").get("defaut"), null, true);
            }
          };
        }
        this.formColl.fetch(params);

        this.headerModel.set("value", value);
      } else {
        this.headerModel.set("value", this.defaultCriteresModel);
      }
    }
    this.btnBarModel.set("mode", "R");
    this.formModel.set("mode", "R");
  },

  _buttonAction: function(buttonId) {
    LOG.debug("Button clicked in etats : " + buttonId);
    var self = this;

    switch (buttonId) {
      case "print":
        if (!STR.isBlank(self.tableModel.get("value"))) {
          this.formColl.trigger("validateColl", function(model) {
            model.type = "pdf";
            model.thcollab = self.tableModel.get("value").get("thcollab");
            model.code = self.tableModel.get("value").get("code");

            model.setHabContext(self.getHabContext());
            model.updateHabContext({ fonc_cour: "N" });

            model.save(null, {
              success: function() {
                self.trigger("closePopup");
                var dialogSuvieEtat = new DialogView({
                  view: GeneralView
                });
                dialogSuvieEtat.setHeight(600);
                dialogSuvieEtat.setWidth(1200);
                dialogSuvieEtat.open();
                self.btnBarModel.set("mode", "R");
                self.formModel.set("mode", "R");
              },
              error: function(fresh, response) {
                //Désactiver le comportement normal pour le montrer dans un popup (demandé dans SFD)
                response.status = response.oldStatus;
                response.responseText = response.oldResponseText;
                LOG.debug("print failed");
              }
            });
          });
        }
        break;
      case "export":
        if (this.paramExportEtat === true && !STR.isBlank(self.tableModel.get("value"))) {
          this.formColl.trigger("validateColl", function(model) {
            model.type = "excel";
            model.code = self.tableModel.get("value").get("code");
            model.thcollab = self.tableModel.get("value").get("thcollab");
            model.setHabContext(self.getHabContext());
            model.updateHabContext({ fonc_cour: "N" });

            model.save(null, {
              success: function() {
                self.trigger("closePopup");
                var dialogSuvieEtat = new DialogView({
                  view: GeneralView
                });
                dialogSuvieEtat.setHeight(600);
                dialogSuvieEtat.setWidth(1200);
                dialogSuvieEtat.open();
                self.btnBarModel.set("mode", "R");
                self.formModel.set("mode", "R");
              },
              error: function(fresh, response) {
                //Désactiver le comportement normal pour le montrer dans un popup (demandé dans SFD)
                response.status = response.oldStatus;
                response.responseText = response.oldResponseText;
                LOG.debug("Export failed");
              }
            });
          });
        }
        break;
      case "revert":
        this.formColl.id = this.tableModel.get("value").get("code");
        this.formColl.set("souscriteres", []);
        this.formColl.fetch();
        break;
      default:
        //none
    }
  },

  /**
   * Filters the grid by some fields.
   */
  _filterTable: function(filter) {
    LOG.debug("Filter table " + filter);

    var coll = new EtatsColl(); //collection only for retrieve filtered data
    coll.set("definitions", []);
    coll.applyFilter(filter);
    coll.setHabContext(this.getHabContext());

    var self = this;
    coll.fetch({
      success: function(fresh) {
        self.flatColl.reset();
        self._arrayToCollection(fresh);
        self.tableModel.coll.totalRecords = self.flatColl.length;
        self.tableModel.coll.reset(self.flatColl.models);
        if (self.flatColl.length > 0) {
          var finded = false;
          for (var i = 0; i < self.flatColl.length && finded === false; i++) {
            if (self.flatColl.at(i).level === 2) {
              finded = true;
              var row = self.tableModel.coll.at(i);
              row.trigger("row:click", row);
            }
          }
        } else {
          self.tableModel.set("value", null);
        }
      }
    });
  },

  _navigateRow: function(action) {
    switch (action) {
      case 1: // navigate to first row (see NavigationView)
        this.tableModel.selectFirstRow();
        break;
      case 2: // navigate to prev row (see NavigationView)
        this.tableModel.selectPreviousRow();
        break;
      case 3: // navigate to next row (see NavigationView)
        this.tableModel.selectNextRow();
        break;
      case 4: // navigate to last row (see NavigationView)
        this.tableModel.selectLastRow();
        break;
      default:
        //none
    }
  },

  _arrayToCollection: function(collection) {
    var self = this;
    //level 1
    for (var i = 0; i < collection.length; i++) {
      var auxModel1 = collection.at(i);
      //atributes for tree table view
      auxModel1.isExpanded = true;
      auxModel1.level = 1;
      auxModel1.collapsible = true;
      auxModel1.branch = i;
      if (auxModel1.get("definitions") != undefined && auxModel1.get("definitions").length > 0) {
        auxModel1.hasChild = true;
      } else {
        auxModel1.hasChild = false;
      }

      //level 2
      var arrayDefinitions = auxModel1.get("definitions");
      if (arrayDefinitions != undefined) {
        self.flatColl.add(auxModel1);
        for (var j = 0; j < arrayDefinitions.length; j++) {
          var auxModel2 = arrayDefinitions[j];
          var model2 = new BaseModel(auxModel2);

          //atributes for tree table view
          model2.isExpanded = true;
          model2.level = 2;
          model2.collapsible = false;
          model2.branch = i;
          model2.hasChild = false;
          model2.id = model2.get("code") + "," + model2.get("theme");

          //Remove definitions 340,430 if calprev not activated
          if (!(this.parametreDiversCalPrev.get("valeur") === "0" && (model2.id === "340" || model2.id === "430"))) {
            self.flatColl.add(model2);
          }
        }
      }
    }
  },

  _formEdited: function() {
    this.btnBarModel.set("mode", "E");
    this.formModel.set("mode", "E");
  }
});
