import { ReadOnlyModel } from '../../../../../core/models/readOnly.model.js';

/**
 * Model for Brique 'Demandes Collaborateurs'
 */
export var DemandesCollaborateursModel = ReadOnlyModel.extend({
  urlRoot: Configuration.restRoot + "/rest/brique/respo/demande",
  typeevenement: "",

  url: function() {
    return this.urlRoot + "/" + this.typeevenement;
  },

  defaults: {
    "valeur": 0
  },

  parse: function(response) {
    return response;
  }
});