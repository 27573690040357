module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<div class="droitsdel-tooltip">\n  <span class="filtres-content">\n		<label for="filtres">'+
((__t=(i18n.t("droitsdel.filtres") + ":"))==null?'':__t)+
'</label>\n		<span class="filtres"></span>\n  <br/>\n  </span>\n  <span class="profil-content">\n		<label for="profil">'+
((__t=(i18n.t("droitsdel.profil") + ":"))==null?'':__t)+
'</label>\n		<span class="profil"></span>\n  <br/>\n  </span>\n  <span class="actions-content">\n		<label for="actions">'+
((__t=(i18n.t("droitsdel.actions") + ":"))==null?'':__t)+
'</label>\n		<span class="actions"></span>\n  </span>\n</div>\n';
}
return __p;
};
