import { STR } from './str.js';

/**
 * ajax headers util
 */
export var HEADERS = {

  dummy: "",

  /**
   * Create 'habilitation' in the context header to the REST
   */
  habilitationContext: function(onglet, fonc_cour, nat_gest) {
    onglet || (onglet = "");
    fonc_cour || (fonc_cour = "");
    nat_gest || (nat_gest = "");

    // Support Objects as fonc_cour
    if (_.isObject(fonc_cour)) {
      fonc_cour = JSON.stringify(fonc_cour).replace(/"/g, '\\\"');
    }

    return { "gfi-context": '{"onglet": "' + onglet + '", "fonc_cour": "' + fonc_cour + '", "nat_gest": "' + nat_gest + '"}' };
  },

  /**
   * Create 'population' in the context header to the REST
   */
  populationContext: function(popId, popType, popCode, popNat, popDesc) {
    var populationContext = { "gfi-population": '{"id": "' + popId + '", "type": "' + popType + '"' };
    if (!STR.isBlank(popCode)) {
      populationContext["gfi-population"] += ', "code": "' + popCode + '"';
    }
    if (!STR.isBlank(popNat)) {
      populationContext["gfi-population"] += ', "nature": "' + popNat + '"';
    } else {
      populationContext["gfi-population"] += ', "nature": ""';
    }
    if (!STR.isBlank(popDesc)) {
      populationContext["gfi-population"] += ', "desc": "' + popDesc + '"';
    }
    populationContext["gfi-population"] += '}';
    return populationContext;

    //    return { "gfi-population": '{"id": "' + popId + '", "type": "' + popType + '", "nature": "' + popNat + '", "desc": "' + popDesc + '"}' };
  },

  /**
   * Create 'ecran' in the context header to the REST
   */
  ecranContext: function(ecran) {
    return { "gfi-ecran": '"' + ecran + '"' };
  },

  /**
   * Create 'version' in the context header to versioning the REST services.
   */
  versionContext: function(version) {
    return { "Accept": 'application/vnd.gfi-chronotime.v' + version + '+json' };
  },

  simulationContext: function(code, datedeb, datefin, preel, vuejourneedate) {
    //var l_rtn = { "gfi-simulation" : '{"id": "' + code + '", "dated": "' + datedeb + '", "datef": "' + datefin + '"}'};
    var dateconsult = !STR.isBlank(vuejourneedate) ? (', "dateconsult": "' + vuejourneedate + '"') : "";

    var l_rtn = { "gfi-simulation": '{"id": "' + code + '", "dated": "' + datedeb + '", "datef": "' + datefin + '", "passreel": "' + (preel ? preel : false) + '"' + dateconsult + '}' };

    return l_rtn;
  }

};
