import { BaseModel } from '../../../../../core/models/base.model.js';
import { STR } from '../../../../../utils/str.js';

export var ListeBriquesModel = BaseModel.extend({

  defaults: {
    "id": "",
    "emid": "",
    "nom": "",
    "ordre": null,
    "active": false,
    "personnalisation": null
  },

  parse: function(response) {
    if (response.code !== undefined) {
      response.id = response.code;
    }
    return response;
  },
  getUniqueId: function() {
    var uniqueId = "";
    if (!STR.isBlank(this.get("personnalisation"))) {
      uniqueId = this.get("id") + "," + this.get("emid") + "," + this.get("personnalisation").userid + "," + this.get("personnalisation").codebrique;
    } else {
      uniqueId = this.get("id") + "," + this.get("emid");
    }
    return uniqueId;
  }

});