import { BaseModel } from '../../../../../../core/models/base.model.js';

import { i18n } from '../../../../../../i18n.js';
import { STR } from '../../../../../../utils/str.js';

/**
 * Model for accept, reject or delete
 */
export var GererPretsGestion = BaseModel.extend({

  canViewTreatment: true,

  url: function() {
    var id = this.isNew() === true ? "" : encodeURIComponent(this.id);
    return Configuration.restRoot + "/rest/composant/pret/" + this.action + "/" + id;
    //return Configuration.restRoot + "/rest/composant/pret/" + this.action + "/" + this.get("profil").code;
  },

  validate: function(attrs) {
    var errors = {};
    if (!STR.isBlank(this.action) && this.action == "refuser") {
      if (STR.isBlank(attrs.commentaire)) {
        errors.commentaire = i18n.t('common:required', { "0": i18n.t('common:gererprets.comment') });
      }
    }

    if (!_.isEmpty(errors)) {
      var errorMap = {
        errorValidation: STR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
  },

  defaults: {
    "commentaire": "",
    "droit": ""
  },

  parse: function(response) {
    if (response.id == undefined && response.code != undefined) {
      response.id = response.code;
    }
    return response;
  }
});