import { DesactivateMessageModel } from 'core/models/desactivateMessage.model.js';
import { HabilitationContext } from 'core/models/habilitationContext.js';
import { GLOBAL_DATA } from '../globalData.js';
import { i18n } from '../i18n.js';
import { SESSION } from './session.js';
import { STR } from './str.js';

/**
 * User messages manager
 */
export var MSGS = {

  /**
   * Confirm the family, number and usecase.
   *
   */
  confirmMessage: function(opt, options) {
    var casesArray = [
      { famille: "GT", numero: 1496, usecase: "utilisateur" },
      { famille: "GT", numero: 1496, usecase: "habr" },
      { famille: "GT", numero: 1496, usecase: "habg" },
      { famille: "GT", numero: 1496, usecase: "habp" },
      { famille: "GT", numero: 1932, usecase: "suivicollab" }
    ];

    if (!STR.isBlank(options) && !STR.isBlank(options.headers) && !STR.isBlank(options.headers["gfi-context"]) && !STR.isBlank(JSON.parse(options.headers["gfi-context"]).onglet)) {
      var onglet = JSON.parse(options.headers["gfi-context"]).onglet;
    } else {
      return false;
    }

    for (var i = 0; i < casesArray.length; i++) {
      if ((opt.famille === casesArray[i].famille) && (opt.numero === casesArray[i].numero) && (onglet === casesArray[i].usecase)) {
        return true;
      }
    }

    return false;
  },

  /**
   * Show a info message to the user
   *
   */
  showInfo: function(str) {
    var rgaa = false;
    var theme = _.clone(GLOBAL_DATA.theme);
    if (theme.get("preferences") && !STR.isBlank(theme.get("preferences")[0]) && theme.get("preferences")[0].varchar1 === 'contrast.theme') {
      rgaa = true;
    }
    var text = str;
    var timeout = 6000; // millis
    var id = _.uniqueId("dialogShowInfo");
    var $div = $("<div>").attr("id", id);
    var $p = $("<p>").addClass("phx-msgs-body").html(text);
    if (rgaa === true) {
      $p = $("<p>").addClass("phx-msgs-body-rgaa").html(text);
    }
    $div.append($p);
    $("body").prepend($div);
    return $("#" + id).dialog({
      dialogClass: "ui-dialog-showInfo ui-state-highlight",
      height: "auto",
      width: "auto",
      maxHeight: 200,
      maxWidth: 800,
      minHeight: 100,
      minWidth: 400,
      draggable: false,
      resizable: false,
      closeText: i18n.t('common:close'),
      position: {
        my: "right bottom",
        at: "right bottom",
        of: "#phx-container"
      },
      create: function() {
        var $parent = $(this).parent();
        $parent.removeClass("ui-corner-all");
        $parent.find(".ui-dialog-titlebar").removeClass("ui-corner-all");
        // apply unique styling
        $(this).parents(".ui-dialog:first").find(".ui-dialog-titlebar-close").addClass("ui-state-highlight");
      },
      open: function() {
        window.setTimeout(function() {
          $("#" + id).dialog('close');
        }, timeout);
      },
      close: function() {
        $(this).dialog("destroy");
        $(this).remove();
      }
    });
  },

  /**
   * Show a warning message to the user
   *
   */
  showWarning: function(str, callback, noShowAgainButton, noShowAgainId) {
    if (noShowAgainButton == true && SESSION.get(noShowAgainId) == true) {
      if (!STR.isBlank(callback)) {
        callback();
      }
      return null;
    } else {
      var rgaa = false;
      var theme = _.clone(GLOBAL_DATA.theme);
      if (theme.get("preferences") && !STR.isBlank(theme.get("preferences")[0]) && theme.get("preferences")[0].varchar1 === 'contrast.theme') {
        rgaa = true;
      }
      var text = str;
      var icon = "<span class='ui-icon ui-icon-alert' style='float: left; margin-right: .3em;'></span>";
      var id = _.uniqueId("dialogShowWarning");
      var $div = $("<div>").attr("id", id);
      var $p = $("<p>").addClass("phx-msgs-body").html(icon + text);
      if (rgaa === true) {
        $p = $("<p>").addClass("phx-msgs-body-rgaa").html(icon + text);
      }
      $div.append($p);
      if (noShowAgainButton == true) {
        var noShowAgain = $("<div><input type='checkbox' name='neplusaff' >" + i18n.t('common:neplusaff') + "</input></div");
        noShowAgain.css({
          "margin-top": "20px",
          "margin-bottom": "-20px",
          "margin-left": "calc(50% - 90px)"
        });
        $div.append(noShowAgain);
      }
      $("body").prepend($div);
      return $("#" + id).dialog({
        dialogClass: "ui-dialog-showWarning",
        width: 500,
        modal: true,
        title: i18n.t('common:msgs_warning_title'),
        closeText: i18n.t('common:close'),
        buttons: [{
          autofocus: true,
          text: i18n.t('common:close'),
          click: function() {
            $(this).dialog("close");
          }
        }],
        create: function() {
          var $parent = $(this).parent();
          $parent.removeClass("ui-corner-all");
          $parent.find(".ui-dialog-titlebar").removeClass("ui-corner-all");
          // apply unique styling
          $(this).parents(".ui-dialog:first").find(".ui-dialog-content span.ui-icon").wrap(
            '<div class="ui-state-highlight" style="border:none; background:none;" />');
          $(this).parents(".ui-dialog:first").find(".ui-dialog-titlebar").addClass("ui-state-highlight");
          $(this).parents(".ui-dialog:first").find(".ui-dialog-titlebar-close").addClass(
            "ui-state-highlight");
        },
        close: function(event) {
          if (noShowAgainButton == true) {
            SESSION.set(noShowAgainId, $(event.target).find("[name=neplusaff]").is(":checked"));
          }
          if (!STR.isBlank(callback)) {
            callback();
          }
          $(this).dialog("destroy");
          $(this).remove();
        }
      });
    }
  },

  /**
   * Show a info message to the user in a center popup
   *
   */
  showInfoOpt: function(str, callback, noShowAgainButton, noShowAgainId) {
    if (noShowAgainButton == true && SESSION.get(noShowAgainId) == true) {
      if (!STR.isBlank(callback)) {
        callback();
      }
      return null;
    } else {
      var rgaa = false;
      var theme = _.clone(GLOBAL_DATA.theme);
      if (theme.get("preferences") && !STR.isBlank(theme.get("preferences")[0]) && theme.get("preferences")[0].varchar1 === 'contrast.theme') {
        rgaa = true;
      }
      var text = str;
      var icon = "<span class='ui-icon ui-icon-info' style='float: left; margin-right: .3em;'></span>";
      var id = _.uniqueId("dialogShowWarning");
      var $div = $("<div>").attr("id", id);
      var $p = $("<p>").addClass("phx-msgs-body").html(icon + text);
      if (rgaa === true) {
        $p = $("<p>").addClass("phx-msgs-body-rgaa").html(icon + text);
      }

      $div.append($p);
      if (noShowAgainButton == true) {
        var noShowAgain = $("<div><input type='checkbox' name='neplusaff' >" + i18n.t('common:neplusaff') + "</input></div");
        noShowAgain.css({
          "margin-top": "20px",
          "margin-bottom": "-20px",
          "margin-left": "calc(50% - 90px)"
        });
        $div.append(noShowAgain);
      }
      $("body").prepend($div);
      return $("#" + id).dialog({
        dialogClass: "ui-dialog-showWarning",
        width: 500,
        modal: true,
        title: i18n.t('common:information'),
        closeText: i18n.t('common:close'),
        buttons: [{
          autofocus: true,
          text: i18n.t('common:close'),
          click: function() {
            $(this).dialog("close");
          }
        }],
        create: function() {
          var $parent = $(this).parent();
          $parent.removeClass("ui-corner-all");
          $parent.find(".ui-dialog-titlebar").removeClass("ui-corner-all");
          // apply unique styling
          $(this).parents(".ui-dialog:first").find(".ui-dialog-content span.ui-icon").wrap(
            '<div class="ui-state-highlight" style="border:none; background:none;" />');
          $(this).parents(".ui-dialog:first").find(".ui-dialog-titlebar").addClass("ui-state-highlight");
          $(this).parents(".ui-dialog:first").find(".ui-dialog-titlebar-close").addClass(
            "ui-state-highlight");
        },
        close: function(event) {
          if (noShowAgainButton == true) {
            SESSION.set(noShowAgainId, $(event.target).find("[name=neplusaff]").is(":checked"));
          }
          if (!STR.isBlank(callback)) {
            callback();
          }
          $(this).dialog("destroy");
          $(this).remove();
        }
      });
    }
  },

  /**
   * Show an error message to the user
   *
   */
  showError: function(str, callback) {
    var rgaa = false;
    var theme = _.clone(GLOBAL_DATA.theme);

    if (!STR.isBlank(theme)) {
      if (theme.get("preferences") && !STR.isBlank(theme.get("preferences")[0]) && theme.get("preferences")[0].varchar1 === 'contrast.theme') {
        rgaa = true;
      }
    }
    var text = str;
    var icon = "<span class='ui-icon ui-icon-circle-close' style='float: left; margin-right: .3em;'></span>";
    var id = _.uniqueId("dialogShowError");
    var $div = $("<div>").attr("id", id);
    var $p = $("<p>").addClass("phx-msgs-body").html(icon + text);
    if (rgaa === true) {
      $p = $("<p>").addClass("phx-msgs-body-rgaa").html(icon + text);
    }
    $div.append($p);
    $("body").prepend($div);

    return $("#" + id).dialog({
      dialogClass: "ui-dialog-showError",
      width: 500,
      modal: true,
      title: i18n.t('common:msgs_error_title'),
      closeText: i18n.t('common:close'),
      buttons: [{
        autofocus: true,
        text: i18n.t('common:close'),
        click: function() {
          $(this).dialog("close");
        }
      }],
      create: function() {
        var $parent = $(this).parent();
        $parent.removeClass("ui-corner-all");
        $parent.find(".ui-dialog-titlebar").removeClass("ui-corner-all");
        // apply unique styling
        $(this).parents(".ui-dialog:first").find(".ui-dialog-content span.ui-icon").wrap(
          '<div class="ui-state-error" style="border:none; background:none;" />');
      },
      close: function() {
        if (!STR.isBlank(callback)) {
          callback();
        }
        $(this).dialog("destroy");
        $(this).remove();
      }
    });
  },

  /**
   * Show a message to the user requiring confirmation
   *
   * 								 true = 3 buttons yes/no/cancel
   * 								true = 1 button
   */
  showConfirm: function(str, callback, tributton, optionsText, oneButton) {
    var text = str;
    var id = _.uniqueId("dialogShowConfirm");
    var $div = $("<div>").attr("id", id);
    var $p = $("<p>").addClass("phx-msgs-body").html(text);
    $div.append($p);
    $("body").prepend($div);

    var btn = [{
      text: (optionsText ? (optionsText.yes ? optionsText.yes : i18n.t('common:yes')) : i18n.t('common:yes')),
      click: function() {
        $(this).dialog("close");
        callback("Y");
      }
    }];
    if (tributton) {
      btn.push({
        text: (optionsText ? (optionsText.no ? optionsText.no : i18n.t('common:no')) : i18n.t('common:no')),
        click: function() {
          $(this).dialog("close");
          callback("N");
        }
      });
    }
    if (!_.isBoolean(oneButton) || !oneButton) {
      btn.push({
        autofocus: true,
        text: (optionsText ? (tributton ? (optionsText.cancel ? optionsText.cancel : i18n.t('common:cancel')) :
          (optionsText.no ? optionsText.no : i18n.t('common:cancel'))) : i18n.t('common:cancel')),
        click: function() {
          $(this).dialog("close");
          callback("C");
        }
      });
    }

    return $("#" + id).dialog({
      title: i18n.t('common:msgs_confirm_title'),
      dialogClass: "ui-dialog-showConfirm",
      width: 500,
      modal: true,
      buttons: btn,
      closeText: i18n.t('common:close'),
      create: function() {
        var $parent = $(this).parent();
        $parent.removeClass("ui-corner-all");
        $parent.find(".ui-dialog-titlebar").removeClass("ui-corner-all");
      },
      close: function() {
        $(this).dialog("destroy");
        $(this).remove();
      }
    });
  },

  /**
   * Show a message of notification
   *
   */
  showNotification: function(notification, callback, options) {
    var id = _.uniqueId("dialogShowNotification");

    // set notification icon
    var image = "";
    switch (notification.icone) {
      case "!":
        image = "<span class='ui-icon ui-icon-alert' style='float: left; margin: 0 7px 20px 0;'></span>";
        break;
      case "?":
        image = "<span class='ui-icon ui-icon-info' style='float: left; margin: 0 7px 20px 0;'></span>";
        break;
      case "X":
        image = "<span class='ui-icon ui-icon-circle-close' style='float: left; margin: 0 7px 20px 0;'></span>";
        break;
      default:
    }

    // set notification title
    var title = "";
    var dialogClass = "ui-state-highlight";

    var rgaa = false;
    var theme = _.clone(GLOBAL_DATA.theme);
    if (theme.get("preferences") && !STR.isBlank(theme.get("preferences")[0]) && theme.get("preferences")[0].varchar1 === 'contrast.theme') {
      rgaa = true;
    }

    switch (notification.titre) {
      case "i18n_error":
        title = i18n.t('common:error');
        dialogClass = rgaa === false ? "ui-state-error" : "ui-state-error-rgaa";
        break;
      case "i18n_alert":
        title = i18n.t('common:alert');
        dialogClass = rgaa === false ? "ui-state-error" : "ui-state-error-rgaa";
        break;
      case "i18n_information":
        title = i18n.t('common:information');
        dialogClass = rgaa === false ? "ui-state-highlight" : "ui-state-highlight-rgaa";
        break;
      case "i18n_confirmation":
        title = i18n.t('common:confirmation');
        dialogClass = rgaa === false ? "ui-state-showConfirm" : "ui-state-showConfirm-rgaa";
        break;
      case "i18n_question":
        title = i18n.t('common:question');
        dialogClass = rgaa === false ? "ui-state-highlight" : "ui-state-highlight-rgaa";
        break;
      default:
        title = notification.titre;
        break;
    }

    var $div = $("<div>").attr("id", id).attr("title", title);
    $div.append(image);

    var $p = $("<p>").addClass("phx-msgs-body").html(notification.message);
    if (rgaa === true) {
      $p = $("<p>").addClass("phx-msgs-body-rgaa").html(notification.message);
    }
    $div.append($p);
    var check = this.confirmMessage(notification, options);

    if (check) {
      var noShowAgain = $("<div><input type='checkbox' name='neplusaff' >" + i18n.t('common:neplusaff') + "</input></div");
      noShowAgain.css({
        "margin-top": "10px",
        "margin-bottom": "-5px"
      });
      $div.append(noShowAgain);
    }

    $("body").prepend($div);

    // manage notification buttons
    var callbackAction = function(action) {
      switch (action) {
        case "BREAK": // ABORT
          break;
        case "ACTION": // CONTINUE
          callback(action);
          break;
        default:
          if (check && noShowAgain.find("input").prop("checked")) {
            var model = new DesactivateMessageModel();
            model.famille = notification.famille;
            model.numero = notification.numero;

            model.setHabContext(new HabilitationContext({
              onglet: JSON.parse(options.headers["gfi-context"]).onglet,
              fonc_cour: JSON.parse(options.headers["gfi-context"]).fonc_cour,
              nat_gest: ""
            }));
            model.save(null, {
              success: function() {
                callback(action);
              },
              error: function() {
                // Do nothing
              }
            });

          } else {
            callback(action);
          }
      }
    };

    var btn = [];
    _.each(notification.boutons, function(button) {
      var text = "";
      switch (button.libelle) {
        case "i18n_close":
          text = i18n.t('common:close');
          break;
        case "i18n_ok":
          text = i18n.t('common:ok');
          break;
        case "i18n_yes":
          text = i18n.t('common:yes');
          break;
        case "i18n_no":
          text = i18n.t('common:no');
          break;
        case "i18n_continue":
          text = i18n.t('common:continu');
          break;
        case "i18n_abort":
          text = i18n.t('common:abort');
          break;
        case "i18n_fermer":
          text = "Fermer";
          break;
        case "i18n_annuler":
          text = "Annuler";
          break;
        case "i18n_appliquer":
          text = "Appliquer";
          break;
        default:
          text = button.libelle;
          break;
      }

      btn.push({
        text: text,
        click: function() {
          $(this).dialog("close");
          callbackAction(button.action);
        }
      });
    }, this);

    // Set autofoucs to the last button, it usually is the cancel button.
    _.last(btn).autofocus = true;

    return $("#" + id).dialog({
      modal: true,
      dialogClass: dialogClass,
      width: 500,
      buttons: btn,
      closeText: i18n.t('common:close'),
      create: function() {
        var $parent = $(this).parent();
        $parent.removeClass("ui-corner-all");
        $parent.find(".ui-dialog-titlebar").removeClass("ui-corner-all");
      },
      close: function() {
        $(this).dialog("destroy");
        $(this).remove();
      }
    });
  }
};
