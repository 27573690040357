import { ComplementComboModel } from './complementCombo.model.js';

import { PaginatedCollection } from '../../../../../../core/models/paginated.collection.js';

/**
 * Collection for ComplementComboColl
 */
export var ComplementComboColl = PaginatedCollection.extend({

  model: ComplementComboModel,
  codif: null,

  initialize: function(options) {
    PaginatedCollection.prototype.initialize.call(this);
    this.paginated = false;
    if (options && options.codif)
      this.codif = options.codif;
  },
  url: function() {
    return Configuration.restRoot + "/rest/tablebase/" + encodeURIComponent(this.codif) + "/liste";
  },

  clone: function() {
    var temp = PaginatedCollection.prototype.clone.call(this);
    temp.codif = this.codif;
    return temp;
  }

});