import { BaseModel } from 'core/models/base.model.js';
import { CriteresColl } from './criteres.collection.js';

/**
 * Model of criteria for the right side
 */
export var CriteresModel = BaseModel.extend({

  defaults: {
    "id": null,
    "libelle": "",
    "selectattribut": "",
    "obligatoire": false,
    "parent": "",
    "tda": null,
    "valeurparent": "",
    "code": "",
    "type": "",
    "groupe": null
  },

  parse: function(response) {
    if (response.id === undefined && response.code !== undefined) {
      response.id = response.code;

      var arraySous = response.souscriteres;
      response.souscriteres = new CriteresColl(arraySous, { parse: true });
    }
    return response;
  }
});