import { BlockView } from '../../../../wkfhisto/views/block.view.js';
import { ComboBoxView2 } from '../../../../../../core/components/combo/comboBoxView2.js';
import { ComboBoxRgaaView2 } from '../../../../../../core/components/combo/comboBoxRgaaView2.js';
import { CombosColl } from '../../../../../../core/components/combo/combos.collection.js';
import { EditedRowView as CommonEditedRowView } from '../../../../../../core/views/editedRow.view.js';
import { FORMS } from '../../../../../../utils/forms.js';
import { i18n } from '../../../../../../i18n.js';
import { STR } from '../../../../../../utils/str.js';
import { TYPE } from '../../../../../../tda/tda.js';
import TPL_gererbadgeages_detailRow from '../detailRow.tpl.html';
import { GLOBAL_DATA } from '../../../../../../globalData.js';

export var EditedRowView = CommonEditedRowView.extend({
  /**
   * Event launch for Backbone when a inputs of fieldset change the property value
   *
   */
  /**
   * Event launch for Backbone when a hover your mouse over the class 'txtStatutGererEdit'
   *
   */
  /**
   * Event launch for Backbone when a quit your mouse over the class 'txtStatutGererEdit'
   *
   */

  //REVIEW: 	i18n : phx.i18n.common.gererbadgeages,

  //REVIEW: 	i18nCom : phx.i18n.common,

  /**
   * Constructor
   * View of EditedRow to the table of badgeages
   */
  initialize: function(options) {
    this.template = TPL_gererbadgeages_detailRow;
    if (options && options.parent) {
      this.parent = options.parent;
    }
    if (options && options.context) {
      this.context = options.context;
    }
    if (options && options.workflow) {
      this.workflow = options.workflow;
    }
    // RGAA development
    this.theme = _.clone(GLOBAL_DATA.theme);
  },

  /**
   * Load the internal events of the view, and the events of TDA Date
   */
  events: _.extend({
    "change :input:not([readonly])": "_change", // See jQuery api, it means
    "mouseenter span.txtStatutGererEdit": "_openTooltipListenerEdit",
    "mouseleave span.txtStatutGererEdit": "_closeTooltipListenerEdit"
  }, TYPE.DATE.events(), TYPE.HOUR_MINUTE.events()),

  /**
   * Renders the view to the form
   */
  render: function() {
    var self = this;
    var rgaa = false;
    var json = { "i18n": i18n };
    this.$el.html(this.template(json));

    //is New
    var objNew = this.model.isNew();
    var habContext = this.workflow.getHabContext();
    habContext.set('fonc_cour', this.context.ctxHabilitation.HabilitationGestion);

    if (objNew) {
      habContext.set("nat_gest", "A");
    } else {
      habContext.set("nat_gest", "M");
    }

    // RGAA development
    if(this.theme.get("preferences") && !STR.isBlank(this.theme.get("preferences")[0]) && this.theme.get("preferences")[0].varchar1 === 'contrast.theme'){
      rgaa = true;
    }

    const comboSensObject = {
      ws: new CombosColl({
        name: "utilbadgeage",
        comboId: "sens"
      }),
      name: "saisie.sens.code",
      optionsRender: function(value) {
        if (!value || STR.isBlank(value.libelle) || STR.isBlank(value.code)) {
          return "";
        }
        return value.code + " (" + value.libelle + ")";
      },
      required: true,
      habContext: habContext
    };

    //Combo sens
    this.comboSens = rgaa === true ? new ComboBoxRgaaView2(comboSensObject) : new ComboBoxView2(comboSensObject);
    this.comboSens.setFilter(this._filterComboSens());
    this.$el.find(".cmbSens").html(this.comboSens.render().el);

    // Placeholder for the comment input for creating/updating
    this.$el.find(".commentaire").attr("placeholder", i18n.t('common:gererbadgeages.commentaire'));

    let commentHab = this.workflow.getHabContext();
    // commentHab.set('fonc_cour', 'COL_BADG.G');
    commentHab.set('fonc_cour', this.context.ctxHabilitation.HabilitationGestion);

    const comboCommentaireObject = {
      ws: new CombosColl({
        name: "utilbadgeage",
        comboId: "commentaires"
      }),
      name: "origine",
      width: "240px",
      habContext: commentHab
    };

    this.comboCommentaire = rgaa === true ? new ComboBoxRgaaView2(comboCommentaireObject) : new ComboBoxView2(comboCommentaireObject);
    $(this.el).find(".cmbCommentaire").html(this.comboCommentaire.render().el);

    var promesa = this.comboCommentaire.fetchComboPromise();

    promesa.then((res) => {
      this._creacionCommentaire();
    }).catch(() => {
      this._showOrHideCommentaire(0);
    });

    //Combo motifs
    /*customer 163267/205104*/
    var combId = "motifs?removenothourly=true";

    const comboMotifObject = {
      ws: new CombosColl({
        name: "absence/composant",
        //comboId: "motifs"
        comboId: combId
      }),
      name: "motif.code",
      width: "140px",
      autocomplete: true,
      required: false,
      habContext: habContext
    };

    this.comboMotif = rgaa === true ? new ComboBoxRgaaView2(comboMotifObject) : new ComboBoxView2(comboMotifObject);

    this.$el.find(".cmbMotif").html(this.comboMotif.render().el);

    //Render origine
    // this.$el.find(".commentaire").width((this.$el.find(".commentaire").parent().width() - this.$el.find(".objOrigine").width() - 18) + "px");

    if (objNew) { //if a new option
      FORMS.setDatepicker(this, ".saisie\\.date");
    } else { //if a modification option
      //render date
      var obj;
      FORMS.setDatepicker(this, ".saisie\\.date");
      if (this.parent._isDiferentBadgeages(this.model.get("valorise"), this.model.get("saisie"))) {
        var formatedDate = TYPE.DATE.format(this.model.get("valorise").date, TYPE._getFormatByCode("DATE_A"));
        obj = "<br/>" + formatedDate;
        $(this.el).find(".txtValoriseDate").html(obj);
        //render heure
        var formatedHeure = TYPE.HOUR_MINUTE.format(this.model.get("valorise").heure);
        obj = "<br/>" + formatedHeure;
        $(this.el).find(".txtValoriseHeure").html(obj);
        //render sensa
        obj = "<br/>" + this.model.get("valorise").sens.code;
        $(this.el).find(".txtValoriseSens").html(obj);
      }

      var objStatut = this._apliqueStatut(this.model);
      this.$el.find(".objStatut").html(objStatut);
    }

    var renderers = {};
    renderers["motif.code"] = function() {
      if (!STR.isBlank(self.model.get("motif").code)) {
        return self.model.get("motif").libelle + " (" + self.model.get("motif").code + ")";
      } else {
        return null;
      }
    };

    this._convertSensBadgeageVirtual(this.model);

    this._mapModelToForm($(this.el), this.model, renderers);
    var param = !STR.isBlank(GLOBAL_DATA.paramDivers.get("sai_lisf")) ? GLOBAL_DATA.paramDivers.get("sai_lisf").get("valeur") : null;
    if (!STR.isBlank(param) && _.isEqual(param, "0")) {
      FORMS.setFieldReadonly(this.$el.find(".fonction"), true);
    } else {
      FORMS.setFieldReadonly(this.$el.find(".fonction"), false);
    }

    return this;
  },

  _showOrHideCommentaire: function(valeur) { //0 standar behavior, 1 replace textarea for combo

    if (valeur == 0) {
      $(this.el).find(".cmbCommentaire").hide();
      $(this.el).find(".commentaire").show();
    } else if (valeur == 1) {
      $(this.el).find(".commentaire").hide();
      $(this.el).find(".cmbCommentaire").show();
      $(this.el).find(".objOrigine").addClass("required");
    }

  },

  _creacionCommentaire: function() {
    var self = this;
    var utilisateur = this.context.ctxUtilisateur;

    if (utilisateur === "Collaborateur") {

      self._showOrHideCommentaire(1);

      var default1;

      if (!STR.isBlank(this.context.ctxIdentifiantBadgeage)) {
        default1 = _.find(this.comboCommentaire.coll.models, (modelo) => {
          return modelo.get("libelle") === this.context.ctxIdentifiantBadgeage;
        });
      }

      if (STR.isBlank(default1)) {
        default1 = _.find(this.comboCommentaire.coll.models, (modelo) => {
          return modelo.get("libelle") === this.model.attributes.commentaire;
        });
      }

      if (!STR.isBlank(default1)) {

        let parseDefault1 = {
          code: default1.get("code"),
          libelle: default1.get("libelle")
        };

        self.comboCommentaire.setItem(parseDefault1);

        this.model.set("commentaire", default1.get("libelle"));
      }

    } else {
      self._showOrHideCommentaire(0);
    }

  },

  /**
   * Filter the option to load the combo sens
   */
  _filterComboSens: function() {
    var self = this;
    var filter = function(collection) {
      if (self.context.ctxModeSuppression == "Button") {
        var filterCollection = [];

        _.each(collection, function(model) {
          if (model.attrs.code != "X") {
            filterCollection.push(model);
          }
        });

        return filterCollection;
      } else {
        return collection;
      }
    };
    return filter;
  },

  /**
   * Apply the class to the statut type
   */
  _apliqueStatut: function(badgeage) {
    var ensemble, demande;
    if (badgeage.get("iddemande") == 0) {
      ensemble = badgeage.get("evenement");
      demande = null;
    } else {
      demande = badgeage.get("iddemande");
      ensemble = null;
    }
    var style;
    switch (badgeage.get("statut").code) {
      case "D":
      case "T":
        style = "ui-phx-statut-demande";
        break;
      case "I":
        style = "ui-phx-statut-en-cours";
        break;
      case "A":
        style = "ui-phx-statut-accepte";
        break;
      case "R":
        style = "ui-phx-statut-refuse";
        break;
      case "H":
        style = "ui-phx-statut-hors-validation";
        break;
        // no default
    }

    var resp;
    if (badgeage.get("historique")) {
      resp = "<span class='txtStatutGererEdit " + style + "' demande='" + demande +
        "' ensemble='" + ensemble + "'>" + badgeage.get("statut").libelle +
        "</span>";
    } else {
      resp = "<span class='" + style + "'>" + badgeage.get("statut").libelle + "</span>";
    }
    return resp;
  },

  /**
   * Create and open tooltip when a hover your mouse over the class 'txtStatutGererEdit'
   */
  _openTooltipListenerEdit: function(event) {
    var demande = null;
    var reference = null;
    if (event.currentTarget.attributes.demande.value == 0) {
      reference = event.currentTarget.attributes.ensemble.value;
    } else {
      demande = event.currentTarget.attributes.demande.value;
    }

    var jquery = event.currentTarget.attributes["aria-describedby"];
    if (jquery) {
      return;
    }

    var self = this;
    this.tooltipDemande = demande || reference;

    var infobulleWorkflow = new BlockView();

    var contextInfobulleWkf = {
      ctxEcran: this.context.ctxEcran,
      ctxHabilitation: this.context.ctxHabilitation.HabilitationAcces,
      ctxTypeEvenement: "BADGEAGE",
      ctxRefEvenement: reference,
      ctxDemId: demande,
      ctxModeRestitution: "infobulle",
      ctxActivModeSynth: true,
      ctxModeDefaut: "Replie",
      ctxModeRech: true
    };
    infobulleWorkflow.setContext(contextInfobulleWkf);

    $(event.currentTarget).tooltip({ content: "" });
    $(event.currentTarget).tooltip("option", "content", function() {
      return infobulleWorkflow.$el.html();
    });
    $(event.currentTarget).prop("title", "");

    infobulleWorkflow.model.get("histoDem").once("sync", function() {
      try {
        var demande = event.currentTarget.attributes.demande.value;
        if (self.tooltipDemande == demande) {
          $(event.currentTarget).tooltip("open");
        }
      } catch (error) {
        // empty
      }
    });

    infobulleWorkflow.model.get("histoEvent").once("sync", function() {
      try {
        var reference = event.currentTarget.attributes.ensemble.value;
        if (self.tooltipDemande == reference) {
          $(event.currentTarget).tooltip("open");
        }
      } catch (error) {
        // empty
      }
    });
  },

  /**
   * Close tooltip when a quit your mouse over the class 'txtStatutGererEdit'
   */
  _closeTooltipListenerEdit: function() {
    try {
      this.tooltipDemande = null;
      $(event.currentTarget).tooltip("destroy");

    } catch (error) {
      // empty
    }
  },

  /**
   * Function to know if you can edit or cannot edit the line
   * */
  canEdit: function(model) {
    //view if can edit
    if (model.get("etat") == "X") {
      return false;
    }
    if (!this.parent._actionsForStatut("modification", model.get("statut").code)) {
      return false;
    }
    if (this.parent._lineStateIsReadonly()) {
      return false;
    }

    return true;

  },

  /**
   * Convert the sens Badgeages (e and s) to virtual (E and S)
   * */
  _convertSensBadgeageVirtual: function(gererBadgeage) {
    if (gererBadgeage.get("saisie").sens.code == "e") {
      gererBadgeage.get("saisie").sens.code = "E";
    }
    if (gererBadgeage.get("saisie").sens.code == "s") {
      gererBadgeage.get("saisie").sens.code = "S";
    }
  }
});
