import { EtatsModel } from './etats.model.js';
import { PaginatedCollection } from 'core/models/paginated.collection.js';

/**
 * Collection of etats for the left table.
 */
export var EtatsColl = PaginatedCollection.extend({

  model: EtatsModel,

  version: "3.1.0",

  url: Configuration.restRoot + "/rest/edition/groupe",

  initialize: function() {
    PaginatedCollection.prototype.initialize.call(this);
    this.sortings = { ordre: true };
    this.paginated = false;
  },

  parse: function(response) {
    if (response) {
      _.each(response, function(respTheme) {
        _.each(respTheme.definitions, function(definition) {
          definition.thcollab = respTheme.thcollab;
        });
      });
    }
    return response;
  }
});