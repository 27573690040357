import { GLOBAL_DATA } from '../../../../globalData.js';
import { HabilitationContext } from 'core/models/habilitationContext.js';
import { LOG } from '../../../../utils/log.js';
import { ReadOnlyModel } from 'core/models/readOnly.model.js';
import { STR } from '../../../../utils/str.js';
import { SuvietatModel } from './suvietat.model.js';

/**
 * Workflow for UC Etat du Reseau
 */
export var SuvietatWorkflow = ReadOnlyModel.extend({

  initialize: function(options) {
    // Initialize the constant
    this.EMPTY_suvietat = new SuvietatModel();

    this.tableModel = null;
    this.customBarModel = null;

    //parametres
    this.parametreDiversIdCollabModel = GLOBAL_DATA.paramDivers.get("idCollab");
    this.parametreDiversRafEtatModel = GLOBAL_DATA.paramDivers.get("Raf_etats");
    this.paramIdCollab = this.parametreDiversIdCollabModel.get("valeur");

    var rafEtat = this.parametreDiversRafEtatModel;

    /**
     * Model of the habilitation context for the component
     */
    this.habContext = new HabilitationContext({
      onglet: "suvietat",
      fonc_cour: "ETATS.V"
    });
    this.EMPTY_suvietat.setHabContext(this.getHabContext());

    var rafEtat = this.parametreDiversRafEtatModel;
    if (!STR.isBlank(rafEtat.get("valeur"))) {
      this.paramRafEtat = rafEtat.get("valeur");
    } else {
      this.paramRafEtat = 0;
    }

    this.set("ready", false);
  },

  /**
   * Set up the workflow. Link the models between them
   */
  setUp: function(callback) {
    //collection fetch
    var self = this;
    this.tableModel.coll.fetch({
      success: function(fresh) {
        //Repace the tableModel.coll for rafModel to get the refresh fq.
        //Start the stream Cycle
        self.stream({ interval: self.paramRafEtat });
        if (callback) {
          callback();
        }
      }
    });

    this.set("ready", true);
  },

  _manageErreurSeul: function(model) {
    var erreurSeul = model.get("erreurSeul");
    var coll = this.tableModel.coll;
    var streaming = this._isStreaming();

    //1 >> stop the stream
    if (streaming) {
      this._unstream();
    }
    //2 >> add/remove the filter
    if (erreurSeul) {
      //only show Errors (add indicErreur to filter)
      LOG.debug("Only Errors: ON");
      coll.params = _.extend(coll.params, { "erreur": "true" });
    } else {
      //display All (remove indicErreur from filter)
      LOG.debug("Only Errors: OFF");
      coll.params = _.omit(coll.params, "erreur");
    }
    //3 >> restart the stream
    if (streaming) {
      this.stream({ interval: self.paramRafEtat });
    }
  },

  stream: function(options) {
    // Cancel any potential previous stream
    if (this._isStreaming()) {
      this._unstream();
    }
    var self = this;
    LOG.debug("> Start Live Update <");
    var _update = _.bind(function() {
      if ($(".suvietat").length <= 1) {
        this._unstream();
      } else {
        LOG.debug("Updating...");
        var currentSelectedModel = this.tableModel.get("value");
        this.tableModel.coll.fetch({
          success: function(fresh) {
            LOG.debug("fetching...");
            if (currentSelectedModel) {
              self.tableModel.selectRow(currentSelectedModel);
            }
          }
        });
        this._intervalFetch = window.setTimeout(_update, options.interval * 1000 || 10000);
      }
    }, this);
    if (options && options.interval > 0) {
      _update();
    } else {
      this.tableModel.coll.fetch({
        success: function(fresh) {
          LOG.debug("fetching...");
        }
      });
    }
  },

  _unstream: function() {
    window.clearTimeout(this._intervalFetch);
    delete this._intervalFetch;
    LOG.debug("> Stop Live Update <");
  },

  _isStreaming: function() {
    return !_.isUndefined(this._intervalFetch);
  }
});
