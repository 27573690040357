import { FormView } from '../../../../../../core/views/form.view.js';
import { i18n } from '../../../../../../i18n.js';
import { STR } from '../../../../../../utils/str.js';
import TPL_listerprets_resumePopUp from '../resumePopUp.tpl.html';

export var ResumePopUpView = FormView.extend({

  initialize: function(options) {
    this.template = TPL_listerprets_resumePopUp;
    if (options) {
      this.data = options.data;
    }
  },

  render: function() {
    this.$el.append(this.template());

    $(this.el).find(".NDemandes").html(i18n.t('messages:GT_1384', { "1": this.data.nbDemandes }));
    //    	$(this.el).find(".NDemandes").html("Num. dem.: "+this.data.nbDemandes);

    $(this.el).find(".NDemandesAbandone").html("");
    $(this.el).find(".NDemandesError").html("");
    if (!STR.isBlank(this.data.nbDemandesAbandonee) && this.data.nbDemandesAbandonee > 0) {
      //    		$(this.el).find(".NDemandesAbandone").html("Dem. aband.: "+this.data.nbDemandesAbandonee);
      $(this.el).find(".NDemandesAbandone").html(i18n.t('messages:GT_1385', { "1": this.data.nbDemandesAbandonee }));
    }
    if (!STR.isBlank(this.data.nbErrors) && this.data.nbErrors > 0) {
      $(this.el).find(".NDemandesError").html(i18n.t('messages:GT_1369', { "1": this.data.nbErrors }));
    }

    return this;
  }
});