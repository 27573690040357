import { BodyView } from './views/body.view.js';
import { FocusToFormSaveView } from './views/focusToFormSave.view.js';
import { FORMS } from '../utils/forms.js';
import { GLOBAL_DATA } from '../globalData.js';
import { HeaderView } from './views/header.view.js';
import { i18n } from '../i18n.js';
import { LOG } from '../utils/log.js';
import { MSGS } from '../utils/msgs.js';
import { NAV } from '../utils/nav.js';
import { objs } from '../objectsRepository.js';
import { RouterFactory } from '../uc/routerFactory.js';
import { SplitPanelView } from 'core/layouts/splitPanel.view.js';
import { STR } from '../utils/str.js';
import { UTILS } from '../utils/utils.js';
import { WorkFlowModel } from './models/workFlow.model.js';

/**
 * Application main configuration
 */

/**
 * Application Main Router
 */
export var PhoenixAppRouter = Backbone.Router.extend({

  initialize: function() {
    // Initializes the main parts of the UI and its workflow
    this.workflow = new WorkFlowModel();

    // change the uri in the home implies a real navigation
    this.workflow.on("change:uri", function(wfl, value, options) {
      LOG.debug("Navigate to: " + wfl.get("uri") + " value: " + value + " trigger: " + options.trigger);
      var trigger = true;
      if (options && _.isBoolean(options.trigger)) {
        trigger = options.trigger;
      }
      if (trigger === false) {
        var zone = wfl.get("uri").split("/").splice(1, 1).join("/");
        var usecase = wfl.get("uri").split("/").splice(3, 1).join("/");
        // Todo check if I'm the same usecase.
        var newOperation = wfl.get("uri").split("/").splice(4).join("/");
        if (this.workflow.get("zone") === zone &&
          this.workflow.get("usecase") === usecase &&
          this.workflow.get("operation") !== newOperation) {
          this.workflow.set("operation", newOperation);
        }
      }
      this.navigate(wfl.get("uri"), { "trigger": trigger });
    }, this);

    // Reset the subrouter of the usecase
    this.workflow.on("predestroy:usecase", this._predestroyUsecase, this);
    this.workflow.on("destroy:usecase", this._resetUsecase, this);

    // Manage full screen changes
    this.workflow.on("change:fullscreen", function(wfl) {
      var fullsc = wfl.get("fullscreen");
      this._manageFullscreen(fullsc);
    }, this);

    // Initializes the views
    this.headerView = new HeaderView({ el: $(".phx-header"), model: this.workflow });
    this.bodyView = new BodyView({ el: $("#phx-container"), model: this.workflow });
    this.goToLogout = new FocusToFormSaveView({ el: $(".focusToFormSave") });

    var self = this;
    Backbone.history.on("route", function(router, name, args) {
      LOG.debug("route: " + router.prefix + router.module + " usecase: " + router.module + " operation: " + args.join("/"));
      // If the router listener is a subroute and the subroute is already created
      if (router !== self && objs[router.module + "Rt"]) {
        var zone = router.prefix.split("/")[1];
        var type = router.prefix.split("/")[2];
        self.workflow.set("zone", zone);
        self.workflow.set("type", type);
        self.workflow.set("usecase", router.module);
        self.workflow.set("operation", args.join("/"));
        self.workflow.set("uri", Backbone.history.getFragment());
        LOG.debug("zone: " + zone + " usecase: " + router.module + " operation: " + args.join("/"));
      }
    });

    this.listenTo(Backbone, "resize:center", function() {
      self._resizeProtoResizables();
    });
    this.workflow.on("changed:usecase", function() {
      self._resizeProtoResizables();
      // event used to fix a bug in IE with the scrolls
      self.workflow.trigger("fixScrollPosition");
    });
    this.workflow.on("resize", function() {
      var usecase = self.workflow.get("usecase");
      if (!STR.isBlank(usecase)) {
        self._resizeUsecaseResizables("#" + usecase);
      }
      if (self.blocked) {
        self.blockApplication();
      }
    });
    this.workflow.on("block:app", function() {
      self.blockApplication();
    });
    this.workflow.on("unblock:app", function() {
      self.unblockApplication();
    });
    this.workflow.set("activeTabs", new Backbone.Model());
    this.workflow.set("newNavigation", false);

    this.blocked = false; //Flag to know if application is blocked (in etape par etape component application is blocked and no hidder should be shown);

    // disable the hidder temporary
    this.hidderDisabled = false;
    this.workflow.on("hidder:enable", function() {
      self.hidderDisabled = false;
    });
    this.workflow.on("hidder:disable", function() {
      self.hidderDisabled = true;
    });
  },

  routes: {
    "": "home",
    "z/:zoneid": "dispatchToUseCase",
    "z/:zoneid/:type/*subroute": "dispatchToUseCase"
  },

  /**
   * Home of the application
   */
  home: function() {
    var self = this;
    this._initHomeLayout(function() {
      var preferedUcLoaded = false;
      if (!self.workflow.get("newNavigation") && self.workflow.paramEcranDef !== undefined && !STR.isBlank(self.workflow.paramEcranDef.get("valeur")) &&
        self.workflow.paramEcranDef.get("valeur") !== "aucun" && GLOBAL_DATA.rights.menuVisibility.get(self.workflow.paramEcranDef.get("valeur"))) {
        preferedUcLoaded = self.workflow._loadPreferedUc();
      }
      if (preferedUcLoaded === false) {
        var briques = self.workflow.BRIQUES;
        var zone = "";
        var availableZones = self.workflow.ZONES;

        // TODO change order coll -> last, resp -> first
        if (briques["coll"] && briques["coll"].length > 0 && self._checkBriquePermissions(briques["coll"])) {
          zone = "coll";
        } else if (briques["resp"] && briques["resp"].length > 0 && self._checkBriquePermissions(briques["resp"])) {
          zone = "resp";
        } else if (briques["adm"] && briques["adm"].length > 0 && self._checkBriquePermissions(briques["adm"])) {
          zone = "adm";
        } else if (briques["ref"] && briques["ref"].length > 0 && self._checkBriquePermissions(briques["ref"])) {
          zone = "ref";
        } else {
          // There is no briques, it's default zones
          // TODO change order coll -> last, resp -> first
          if (!_.isUndefined(availableZones.coll) && GLOBAL_DATA.rights.menuVisibility.get("coll")) {
            zone = "coll"; //Default zone if no briques
          } else if (!_.isUndefined(availableZones.resp) && GLOBAL_DATA.rights.menuVisibility.get("resp")) {
            zone = "resp";
          } else if (!_.isUndefined(availableZones.adm) && GLOBAL_DATA.rights.menuVisibility.get("adm")) {
            zone = "adm";
          } else if (!_.isUndefined(availableZones.ref) && GLOBAL_DATA.rights.menuVisibility.get("ref")) {
            zone = "ref";
          }
        }

        var obj = {
          "zone": zone,
          "usecase": "",
          "type": "uc",
          "operation": ""

        }
        var oldUsecase = self.workflow.get("usecase");
        self.workflow.set(obj);
        self.workflow.set("newNavigation", false);
        if (oldUsecase === "") {
          self.workflow.trigger("change:usecase", self.workflow, "");
        }
        if (zone !== "") {
          self.workflow.set("uri", "z/" + zone);
          self.workflow.set("selectedTab-" + zone, { module: "", type: "" });
        }
      }
    });
  },

  _checkBriquePermissions: function(briquesArr) {
    return briquesArr.every(function(b) {
      return GLOBAL_DATA.rights.menuVisibility.get(b.emid);
    });
  },

  _handleUseCase: function(tmpTabs, z, usecaseid) {
    var zone = _.findWhere(this.workflow.configuration.get("confignav").get("zones"), { id: z });
    var perms;
    _.each(zone.menus, function(menu) {
      if (menu.id) {
        perms = menu.fonctions;
      } else {
        _.each(menu.ecrans, function(ecran) {
          ecran.fonctions;
        }, this);
      }
    }, this);
    var tab = { id: usecaseid, permissions: perms };
    if (!tmpTabs.get(z)) {
      tmpTabs.set(z, new Backbone.Collection);
    }
    tmpTabs.get(z).add(tab);
  },

  /**
   * Manage the requirement of load an Usecase
   */
  dispatchToUseCase: function(zone, type, subroute) {

    // Check if Home is not yet initialized
    var uri = "";
    var self = this;

    if (!(_.isUndefined(subroute) || _.isNull(subroute)) && NAV.checkMaximumTabs() === true) {
      // Limit for the tabs size
      return;
    }

    this._dispatchToZone(zone, function() {
      self.workflow.set("zone", zone);
      // create zone uri
      uri += "z/" + zone;
      var ucid = "";
      try {
        if (!STR.isBlank(subroute)) {
          var parts = subroute.split("/");
          ucid = parts[0];
          var operation = null;
          if (parts.length > 1) {
            operation = subroute.replace(ucid + "/", "");
          }
          try {
            self.workflow.set("type", type);
            self.workflow.set("usecase", ucid);
            self._resizeProtoResizables();
          } catch (e) {
            LOG.error("Error resizing a module : " + e);
          }

          //add usecase uri
          uri += "/" + type + "/" + _.map(parts, function(part) { return encodeURIComponent(part); }).join("/");

          self._loadModule(ucid, type + "/");

          if (operation != null) {
            self.workflow.set("operation", operation);
          }

        } else {
          self.workflow.set("type", "");
          self.workflow.set("usecase", "");
        }
      } catch (e) {
        throw e;
      } finally {
        // Set uri
        self.workflow.set("uri", uri);
        if (!STR.isBlank(ucid)) {
          var l_zone = self.workflow._searchZoneFromUc(ucid);
          if (l_zone !== zone) {
            zone = l_zone; //Éviter des problèmes
          }
        }
        self.workflow.set("selectedTab-" + zone, { module: ucid, type: type });
        if (!STR.isBlank(ucid)) {
          self._handleUseCase(self.workflow.get("activeTabs"), zone, ucid);
        }
      }
    });
  },

  /**
   * Manage the zone dispatching
   */
  _dispatchToZone: function(zone, callback) {
    var self = this;
    this._initHomeLayout(function() {
      var zones = _.keys(self.workflow.ZONES);
      if (!_.contains(zones, zone)) {
        LOG.debug("The required zone does not exist : [" + zone + "]");
        self.workflow.set("uri", "");
        self.navigate(self.workflow.get("uri"));
      } else if (callback) {
        callback();
      }
    });
  },

  /**
   * Manage the change to full screen mode
   */
  _manageFullscreen: function(fullsc) {
    if (fullsc === true) {
      $(".phx-header").hide();
      $(".phx-center").height($("#phx-wrap").height());
      $(".phx-center").width($("#phx-wrap").width());
    } else {
      $(".phx-header").show();
    }
    this._updateMaxWidthSplitPanels();
    this._resizeProtoResizables();
  },

  /**
   * Initializes all the objects needed to paint the home. Layouts, workflow ...
   */
  _initHomeLayout: function(callback) {
    if (this.workflow.get("ready") === true) {
      if (callback) {
        callback();
      } // fix the error in IE11.
      this._updateMaxWidthSplitPanels();
    } else {
      var self = this;
      this.workflow.setUp(function() {
        $("#phx-loading_initial").hide();
        $("#phx-wrap").show();
        self.headerView.render();
        self.bodyView.render();
        self.listenTo(GLOBAL_DATA.rights, "updateTabs", self._handleTabs);
        $(".phx-center").width($("#phx-wrap").width());

        if (callback) {
          callback();
        }

        self._updateMaxWidthSplitPanels();

        // resize header
        Backbone.trigger("resize:header");
      });
    }
  },

  _handleTabs: function(avoidNavigation) {

    if (avoidNavigation !== true) {
      var tmpTabs = this.workflow.get("activeTabs");
      var checkClosed = false;
      var zones = tmpTabs.values(tmpTabs.attributes);
      var zonesSize = zones.length;
      for (var i = 0; i < zonesSize && checkClosed === false; i++) {
        var menus = zones[i];
        var menusSize = menus.length;
        for (var j = 0; j < menusSize && checkClosed === false; j++) {
          var usecase = menus.at(j);
          checkClosed = !GLOBAL_DATA.rights.menuVisibility.get(usecase.get("id"));
        }
      }

      var self = this;
      var applyAction = function() {
        var tabsToClose = [];
        var tabsToReload = {
          coll: [],
          adm: [],
          resp: [],
          ref: []
        };

        //Pour les briques est necessaire ajouter avant l'information ded reload
        _.each(objs.appRt.bodyView.zViews, function(zoneView, id) {
          _.each(zoneView.tabsView.home.BRIQUES_VIEWS, function(v, i) {
            var brique_emid = i.split(",")[1];
            if (GLOBAL_DATA.rights.menuVisibility.get(brique_emid)) {
              v.$el.show();
            } else {
              v.$el.hide();
            }
          });
          tabsToReload[id].forEach(function(uc) {
            if (self.workflow.get("usecase") !== uc.id) {
              zoneView.tabsView.$el.find("li[aria-controls=" + uc.id + "]").prop("reload", true);
            }
          });
          zoneView.tabsView.$el.find("li[aria-controls=home" + id + "]").prop("reload", true);
        });

        if (GLOBAL_DATA.rights.menuVisibility.get(self.workflow.get("usecase"))) {
          MSGS.showInfo(i18n.t('messages:GL_1072'));
          self._predestroyUsecase(self.workflow.get("usecase"), true);
        } else {
          if (GLOBAL_DATA.rights.menuVisibility.get(self.workflow.get("zone"))) {
            NAV.navigate(self.workflow.get("zone"));
            self.workflow.trigger("change:usecase", self.workflow, "");
          } else {
            // if i'm already in the home I force to go there again.
            if (Backbone.history.fragment === "") {
              Backbone.history.loadUrl(Backbone.history.fragment);
            } else {
              NAV.navigate("");
            }
            self.workflow.set("newNavigation", true);
          }
        }

        _.each(tmpTabs.attributes, function(menus, id) {
          var menusSize = menus.length;
          for (var j = 0; j < menusSize; j++) {
            var usecase = menus.at(j);
            if (GLOBAL_DATA.rights.menuVisibility.get(usecase.id)) {
              tabsToReload[id].push(usecase);
            } else {
              tabsToClose.push(usecase);
            }
          }
        });

        tabsToClose.forEach(function(uc) {
          self._predestroyUsecase(uc.get("id"), false, true);
        });
      };

      if (checkClosed) {
        MSGS.showConfirm(i18n.t('messages:GL_1071'), function(result) {
          if (result === "Y") {
            applyAction();
          } else {
            objs.profilutilisateur._revertSelection();
          }
        });
      } else {
        applyAction();
      }
    }
  },

  /**
   * Load an application module on the fly
   */
  _loadModule: function(module, modulePrefix) {
    this._createNewRouter(module, modulePrefix);
  },

  _createNewRouter: function(module, modulePrefix) {
    try {
      var self = this;
      var prefix = "";

      // We assume all files are already loaded.
      if (!STR.isBlank(module)) {
        var l_zone = this.workflow._searchZoneFromUc(module);

        if (module === "agenda_R") {
          //l_zone="resp";
          if (!_.isEqual(l_zone, "resp")) {
            l_zone = this.workflow._searchZoneFromUc("planresp"); //la zone doit être 'resp' mais la  meilleure forme de garantir qu'elle est la correcte, c'est utiliser celle-là du planning
          }
        }
        //la zone du module est obtenu de l'information qui déjà on a. On évite des problèmes comme "coll/pl/planresp" ou "ref/suivi..."
        prefix = "z/" + l_zone + "/" + modulePrefix;
        if (this.workflow.get("zone") !== l_zone) {
          LOG.debug("(_createNewRouter) zone distinct: " + this.workflow.get("zone") + " !=" + l_zone);
          this.workflow.set("zone", l_zone, { silent: true });
        }
      } else {
        prefix = "z/" + this.workflow.get("zone") + "/" + modulePrefix;
      }
      LOG.debug("(_createNewRouter) prefix Router:" + prefix + module);

      RouterFactory.get(module).then(function(router) {
        objs[module + "Rt"] = new router(prefix, { "prefix": prefix, createTrailingSlashRoutes: true, "module": module });
        objs[module + "Rt"].on("uc:ready", function() {
          self._resizeProtoResizables();
          self._updateMaxWidthSplitPanels();
        });
        self.workflow.set("usecase", objs[module + "Rt"].module);
      })

    } catch (ex) {
      throw new Error("Error creating a router. Error code:(LoadingUCException) - reason -> " + ex);
    }
  },

  /**
   * Reset recursively the backbone objects by calling off() or remove() for
   * the views
   */
  _resetBackboneRouter: function(obj) {
    if (obj instanceof Backbone.Router) {
      obj.off();
      obj.stopListening();
      if (obj.remove) {
        obj.remove();
      } else {
        UTILS.backboneObjectRecollector(obj, true);
      }
    }
  },

  /**
   * Reset the router of the Usecase if it exists
   */
  _resetUsecase: function(ucid, reloadUC, omittedCheck, callback) {
    var router = objs[ucid + "Rt"];

    // Delete routes from history
    Backbone.history.handlers = _.filter(Backbone.history.handlers, function(historyRouter) { return historyRouter.route.toString().indexOf(ucid) === -1; });

    if (router) {
      this._resetBackboneRouter(router);
      var tmpTabs = this.workflow.get("activeTabs");
      tmpTabs.values(tmpTabs.attributes).forEach(function(coll) {
        coll.reset(coll.without(coll.get(ucid)));
      });
      delete objs[ucid + "Rt"];
    }

    if (reloadUC) {
      var ulDom = $("#phx-tabs-" + this.workflow.get("zone") + " ul").eq(0);
      if ($("#" + ucid, ulDom.parent()).length === 0) {
        ulDom.after($("<div id=\"" + ucid + "\" class='phx-proto-resizable '></div>"));
      }
      var subroute = ucid

      if (this.workflow.get("usecase") === ucid && !STR.isBlank(this.workflow.get("operation"))) {
        subroute += "/" + this.workflow.get("operation");
      }
      this.dispatchToUseCase(this.workflow.get("zone"), ulDom.find("[aria-controls=" + ucid + "]").attr("uc-type"), subroute);
    }

    if (callback) {
      callback();
    }
  },

  /**
   * Reset the router of the Usecase if it exists
   */
  _predestroyUsecase: function(ucid, reloadUC, omittedCheck, callback) {
    LOG.debug("destroy:" + ucid + " before treatClose");
    var router = objs[ucid + "Rt"];
    //When router has a _treatClose function
    if (router && !STR.isBlank(router._treatClose)) {
      var self = this;
      router._treatClose(reloadUC, function() {
        LOG.debug("destroy:" + ucid + " after treatClose");
        self.workflow.trigger("destroy:usecase", ucid, reloadUC, omittedCheck, callback);
      });
    } else {
      LOG.debug("destroy:" + ucid + " whitout treatClose");
      this.workflow.trigger("destroy:usecase", ucid, reloadUC, omittedCheck, callback);
    }
  },

  /**
   * Configure the screen and layout management
   */
  configureHomeLayout: function() {

    var self = this;
    var prevHeight = $("#phx-wrap").height();
    //On Resize the center part
    $(window).resize(function(event, ui) {
      // If the resize don't implies an ui or is on height it applies resizeProtos.
      var height = $("#phx-wrap").height();
      if ((!ui && prevHeight !== height) || (ui && ui.originalSize.height !== ui.size.height)) {
        // reset previous height
        prevHeight = height;
        self._resizeProtoResizables();
      }
      self._updateMaxWidthSplitPanels(event, ui);

      // Expand resize event to the whole application
      Backbone.trigger("resize:views", event, ui);
    });

    // Configure tooltips
    $(document).tooltip(FORMS.tooltip).off("focusin");
    // changed the general config to avoid problems.
    $(document).tooltip(FORMS.tooltip).on('focusout mouseout mouseleave', function() {
        var l_tool = $(document).find("[role='tooltip']");

        if (l_tool.length > 1) {
          var l_len = l_tool.length - 1;
          for (var i = 0; i < l_len; i++) {
            $(document).find("[role='tooltip']").first().remove();
          }
        }
        $(document).tooltip('close');
      })
      .tooltip();
  },

  /**
   * Resize the height of layout divs
   */
  _resizeProtoResizables: function() {
    var headerHeight = "0";

    if ($(".phx-header").is(":visible")) {
      headerHeight = $(".phx-header").outerHeight();
    }

    // Sets the height of the phx-container.
    $("#phx-container").height("calc(100vh - " + headerHeight + "px)");

    this._resizeUsecaseResizables("#phx-container");

    this.workflow.trigger("resize");
  },

  /**
   * Resize the height of layout divs
   */
  _resizeUsecaseResizables: function(usecase) {

    var components = $(usecase + " .phx-proto-resizable").filter(":visible");
    _.each(components, function(component) {
      var offsetTop = $(component).offset().top;
      var margins = parseFloat($(component).css("margin-top").replace("px", "")) + parseFloat($(component).css("margin-bottom").replace("px", ""));
      var paddings = parseFloat($(component).css("padding-top").replace("px", "")) + parseFloat($(component).css("padding-bottom").replace("px", ""));

      if (component && offsetTop > 0) {
        $(component).height($("#phx-wrap").height() - (offsetTop + margins + paddings));
      } else {
        $(component).height($("#phx-wrap").height());
      }
    }, this);
  },

  /**
   * Resize the maxwidth of split panels and the width of partB to adjust
   * scroll
   */
  _updateMaxWidthSplitPanels: function() {

    //This border width is needed to adjust the min and maximun width of the menu
    var borderWidth = SplitPanelView.prototype.SPLIT_BORDER_WIDTH;
    var zone = this.workflow.get("zone");

    // set width of main container
    $("#phx-zone-" + zone + " .phx-center").width($("#phx-wrap").width());
    var lastUsecase = "lastuc-" + zone;
    var activeModule = this.workflow.get(lastUsecase);
    if (!activeModule || activeModule === "") {
      return;
    }

    var maxWidthAllowed = $("#phx-wrap").width();
    //set size of active panel
    var $module = $("#" + activeModule);
    $module.width(maxWidthAllowed);

    // Configure split panels
    // set size of left panel
    var $elSplitA = $module.find(".phx-splitPanel .phx-splitA");
    // If is a liste detail
    if ($elSplitA.length > 0) {
      var size = $elSplitA.length;
      for (var i = 0; i < size; i++) {
        var cid = $elSplitA.eq(i).parents(".phx-splitPanel").attr("cid");
        LOG.debug("Backbone=>rezize:" + cid);
        Backbone.trigger("resize:" + cid);
      }
    } else {
      $elSplitA = $module.find(".phx-splitA .phx-panelA3");
      $elSplitA.width(maxWidthAllowed - borderWidth);
    }
  },

  /**
   * Calculate the required height of #phx-container
   */
  calculateCenterHeight: function() {
    return $("#phx-wrap").height() - $(".phx-header:visible").height();
  },

  blockApplication: function() {
    var $divBlocker = $("<div>").addClass("phx-parent-hidder");
    var $divBlockerTabs = $("<div>").addClass("phx-tabs-hidder");
    var $divBlockerSplitA = $("<div>").addClass("phx-parent-hidder");
    var $divBlockerPanelB1 = $("<div>").addClass("phx-panelB1-hidder");
    var $divBlockerPanelB2 = $("<div>").addClass("phx-panelB2-hidder");
    var l_tabZone = !STR.isBlank(this.workflow.get("usecase")) ? "#phx-tabs-" + objs.appRt.workflow._searchZoneFromUc(objs.appRt.workflow.get("usecase")) : "#phx-tabs-" + objs.appRt.workflow.get("zone");
    var l_usecase = "#" + objs.appRt.workflow.get("usecase");

    //Premier
    if (this.blocked) {
      this.unblockApplication();
    }
    //Après
    $(".phx-header").prepend($divBlocker);
    if ($(l_tabZone).length > 0) {
      var l_height = $(l_tabZone + " > ul").outerHeight(true);

      $(l_tabZone).prepend($divBlockerTabs);
      $(l_tabZone + " .phx-tabs-hidder").height(l_height + 5);
    }
    if (!STR.isBlank(l_usecase)) {
      var $l_divB1 = $(l_usecase + " .phx-splitBcell .phx-splitB .phx-contentPanel.phx-panelB1");
      var $l_divB2 = $(l_usecase + " .phx-splitBcell .phx-splitB .phx-contentPanel.phx-panelB2");
      var l_marginLeftSplitA = $(l_usecase + " .phx-splitA").outerWidth(true);

      $(l_usecase + " .phx-splitA").prepend($divBlockerSplitA);
      if ($l_divB1.length > 0) {
        var l_heightB1 = $l_divB1.outerHeight(true);
        var l_widthB1 = $l_divB1.outerWidth() + 9;

        $l_divB1.prepend($divBlockerPanelB1);
        $l_divB1.find(".phx-panelB1-hidder").css({ "height": l_heightB1, "width": l_widthB1, "margin-left": l_marginLeftSplitA });
      }
      if ($l_divB2.length > 0) {
        var l_heightB2 = $l_divB2.outerHeight(true) + 5;
        var l_widthB2 = $l_divB2.outerWidth() + 9;

        $l_divB2.prepend($divBlockerPanelB2);
        $l_divB2.find(".phx-panelB2-hidder").css({ "height": l_heightB2, "width": l_widthB2, "margin-left": l_marginLeftSplitA });
      }
    }
    this.blocked = true;
    document.onkeydown = function(e) {
      return false;
    }
  },

  unblockApplication: function() {
    $(".phx-parent-hidder").remove();
    $(".phx-tabs-hidder").remove();
    $(".phx-panelB1-hidder").remove();
    $(".phx-panelB2-hidder").remove();
    this.blocked = false;
    document.onkeydown = function(e) {
      return true;
    }
  }
});
