import { BaseModel } from '../../../../../../core/models/base.model.js';

import { i18n } from '../../../../../../i18n.js';
import { STR } from '../../../../../../utils/str.js';

export var RefuserPret = BaseModel.extend({

  urlRoot: Configuration.restRoot + "/rest/composant/pret/refuser/",

  defaults: {
    "commentaire": "",
    "droit": "",
    "profil": ""
  },
  validate: function(attrs) {
    var errors = {};

    if (STR.isBlank(attrs.commentaire)) {
      errors.commentaire = i18n.t('messages:GT_1103');
    }

    if (!_.isEmpty(errors)) {
      var errorMap = {
        errorValidation: STR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
    return null;
  }
});