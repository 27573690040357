import { BaseModel } from '../../../../../../core/models/base.model.js';


/**
 * Model for infocomp and defaults of activity
 */
export var TypeAffDefaults = BaseModel.extend({

  defaults: {
    "modadef": { //Type de saisie por defecto
      "code": null,
      "libelle": ""
    },
    "unitedef": {
      "code": null,
      "libelle": ""
    },
    "perdef ": // indica de donde coger unitedeb y unitefin si type de saisie=P
    {
      "code": null,
      "libellecourt": ""

    },
    "repetdef": //Mode de saisie
    {
      "code": null,
      "libelle": ""
    },
    "unitedebdef": {
      "code": null,
      "libellecourt": ""

    },
    "unitefindef": {
      "code": null,
      "libellecourt": ""
    }

  },

  url: function() {
    return Configuration.restRoot + "/rest/composant/pret/definition/" + this.id;
  }
});