
import { PaginatedCollection } from 'core/models/paginated.collection.js';
import { WkfHistoModel } from './wkfHisto.model.js';

export var HistoDemModelColl = PaginatedCollection.extend({

  model: WkfHistoModel,

  url: function() {
    var newUrl = Configuration.restRoot + "/rest/wkf/histo/demande/" + encodeURIComponent(this.ctxModeRech + "," + this.ctxDemId);
    return newUrl;
  },

  initialize: function() {
    PaginatedCollection.prototype.initialize.call(this);
    this.paginated = false;
  }
});