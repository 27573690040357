import { CompteursModel } from './compteurs.model.js';

import { PaginatedCollection } from 'core/models/paginated.collection.js';

export var CompteursColl = PaginatedCollection.extend({

  model: CompteursModel,

  url: function() {
    return Configuration.restRoot + "/rest/cpttpsreel/" + this.matricule;
  },

  initialize: function(options) {
    PaginatedCollection.prototype.initialize.call(this);
    if (options && options.matricule) {
      this.matricule = options.matricule;
    }
  }
});