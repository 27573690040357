module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<fieldset class="droitsdel-detail-panel">\n  <div class="phx-formTable">\n    <div class="phx-formErrors ui-state-error"></div>\n    <div>\n      <label for="datedeb">'+
((__t=(i18n.t("droitsdel.datedeb")))==null?'':__t)+
'</label>\n      <input type="text" class="datedeb typeDate periodStart" periodId="1" value="" size="15" />\n      <span class="datedeb-error-container"></span>\n      <br/>\n      <label for="datefin">'+
((__t=(i18n.t("droitsdel.datefin")))==null?'':__t)+
'</label>\n      <input type="text" class="datefin typeDate periodEnd" periodId="1" value="" size="15" />\n      <span class="datefin-error-container"></span>\n      <br/>\n      <label for="delegue.matricule">'+
((__t=(i18n.t("droitsdel.droitsDelegues")))==null?'':__t)+
'</label>\n      <span class="droisDeleguesA"></span>\n      <label for="retro">&nbsp</label>\n      <span class="radioRetro"></span>\n    </div>\n  </div>\n</fieldset>\n';
}
return __p;
};
