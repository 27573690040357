import { BaseModel } from 'core/models/base.model.js';

/**
 * StoreParamDivers
 */
export var StoreParamDiversModel = BaseModel.extend({

  habilitationV: "N",
  habilitationG: "N",
  usecase: "app",

  defaults: {
    "valeur": null
  },
  url: function() {
    var url = Configuration.restRoot + "/rest/utilisateur/parametre/" + encodeURIComponent(this.paramCode);
    return url;
  }
});