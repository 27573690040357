module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<button type="button" class="filter-view-btn" title="'+
((__t=(i18n.t("common:filter")))==null?'':__t)+
'"><span>&nbsp;</span></button>\n<div class="filter-content">\n  <fieldset>\n\n    <div class="filter-simple-fields"></div>\n    <div class="filter-more">\n      <div class="filter-more-fields"></div>\n    </div>\n    <span class="filter-link-btn filter-more-btn">'+
((__t=(i18n.t("common:morefilters")))==null?'':__t)+
'</span>\n    <br/>\n    <div class="filter-view-btn-buttons">\n      <button type="button" class="search" title="'+
((__t=(i18n.t("common:search")))==null?'':__t)+
'" value="search"></button>\n      <button type="button" class="clear" title="'+
((__t=(i18n.t("common:clear")))==null?'':__t)+
'" value="clear"></button>\n    </div>\n  </fieldset>\n</div>\n';
}
return __p;
};
