import { FORMS } from '../../../utils/forms.js';
import { HabilitationContext } from '../../models/habilitationContext.js';
import { i18n } from '../../../i18n.js';
import { LOG } from '../../../utils/log.js';
import { LoginPasswordModel } from './loginPassword.model.js';
import { STR } from '../../../utils/str.js';
import TPL_common_detailMotPasse from './detailMotPasse.tpl.html';

export var LoginChangePwdDialogView = Backbone.View.extend({

  //REVIEW: 	i18n : phx.i18n.ppmpd,

  initialize: function(options) {
    if (options && !STR.isBlank(options.premiere)) {
      this.premiere = options.premiere;
    }
    if (options && !STR.isBlank(options.message)) {
      this.message = options.message;
    }

    this.template = TPL_common_detailMotPasse;

    if (options && options.parent) {
      this.parent = options.parent;
      this.parent._setTitle(i18n.t('login:pwdtitle'));
    }
    if (options && options.parentView) {
      this.parentView = options.parentView;
    }

    // CUSTOMER 157455
    if (options && options.loginView) {
      this.loginView = options.loginView;
    }
    if (options && options.operation) {
      this.operation = options.operation;
    }

    this.saved = false;

    this.loginPasswordModel = new LoginPasswordModel();
    this.loginPasswordModel.setHabContext(new HabilitationContext({
      onglet: "password",
      fonc_cour: "N",
      nat_gest: ""
    }));
    this.loginPasswordModel.premiere = this.premiere;
  },

  events: {
    "click .motpassecontinue": function() {
      this._continue(this);
    },
    "click .motpassecancel": function() {
      this._close(this);
    }
  },

  _showValidationErrors: function(model, errors) {
    LOG.debug("ERROR saving..." + errors);
    if (errors && _.has(errors, "errorValidation")) {
      if (_.has(errors, "errors")) {
        var fieldErrors = errors.errors;
        FORMS.showErrors($(this.el), fieldErrors);
      }
    }
  },

  _cleanValidationErrors: function() {
    FORMS.cleanErrors($(this.el));
  },

  _continue: function(view) {
    var self = this;

    view._cleanValidationErrors();
    view.loginPasswordModel.id = "password";
    view.loginPasswordModel.set("password",
      sjcl.codec.base64.fromBits(sjcl.codec.utf8String.toBits($("#password").val())), {
        silent: true
      });
    view.loginPasswordModel.set("pwd_new",
      sjcl.codec.base64.fromBits(sjcl.codec.utf8String.toBits($("#pwd_new").val())), {
        silent: true
      });
    view.loginPasswordModel.set("pwd_confirm",
      sjcl.codec.base64.fromBits(sjcl.codec.utf8String.toBits($("#pwd_confirm").val())), {
        silent: true
      });
    var errors = view.loginPasswordModel.validate(view.loginPasswordModel.attributes);
    if (!_.isNull(errors)) {
      view._showValidationErrors(view.loginPasswordModel, errors);
      return;
    }
    view.loginPasswordModel.save(null, {
      success: function() {
        self.saved = true;
        var loginData = { user: null, pass: null };

        // CUSTOMER 157455
        if (self.loginView && self.operation == "badgeage") {
          //$("#user_pass").val($("#pwd_new").val());
          loginData.user = self.loginView.$el.find("#user_login").val();
          loginData.pass = $("#pwd_new").val();
        }

        view.parent.close();
        if (self.parentView) {
          self.parentView._logon(null, view.loginPasswordModel.get("pwd_new"));
        }

        // CUSTOMER 157455
        if (self.loginView && self.operation == "badgeage") {
          self.loginView._badger(loginData);
        }

      }
    });
  },

  _close: function(view) {
    view.parent.close();
  },

  render: function() {
    var json = { "i18n": i18n };
    this.$el.html(this.template(json));

    this.$el.find(".ctxGestionCollab").hide();
    if (this.premiere == true) {
      this.$el.find(".old_password").hide();
    } else {
      this.$el.find(".old_password").show();
    }
    if (!STR.isBlank(this.message)) {
      this.$el.find(".messages").text(this.message);
    }

    $(this.el).find(".motpassecontinue").button({ text: true });
    $(this.el).find(".motpassecancel").button({ text: true });
    this._cleanValidationErrors();

    return this;
  }
});