export var DataGridActionCellView = Backbone.View.extend({

  dummy: "",

  tagName: "td",

  /**
   * View of the Cells with Action Buttons for its row.
   */
  initialize: function(options) {
    if (!options.dataGridRow) {
      throw Error("You must use a DataGridCellView just inside a DataGridRowView");
    }
    if (!options.action) {
      throw Error("You must define a template");
    }
    this.template = options.action;
    this.dataGridRow = options.dataGridRow;
  },

  render: function() {
    $(this.el).html(this.template());
    return this;
  },

  /**
   * Show/Hide this cell when row is editable or not
   */
  disableEdition: function(disable) {
    if (disable) {
      $(this.el).find(".phx-cell-edit-action").hide();
    } else {
      $(this.el).find(".phx-cell-edit-action").show();
    }
  }

});