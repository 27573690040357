import { DialogView } from 'core/views/dialog.view.js';
import { GeneralView } from 'common/lanceetat/views/general.view.js';
import { GeneralView as CalendaccGeneralView } from 'common/calendacc/views/general.view.js';
import { GeneralView as SuvietatGeneralView } from 'common/suvietat/views/general.view.js';
import { GLOBAL_DATA } from '../../globalData.js';
import { i18n } from '../../i18n.js';
import { MSGS } from '../../utils/msgs.js';
import { objs } from '../../objectsRepository.js';
import { STR } from '../../utils/str.js';
import { UTILS } from '../../utils/utils.js';
import { ZoneMenuView } from './zoneMenu.view.js';
import TPL_app_menu from '../menu.tpl.html';
import TPL_app_menu_item_niv1 from '../menu_item_niv1.tpl.html';
import TPL_app_menu_item_niv2 from '../menu_item_niv2.tpl.html';
import TPL_app_menu_item_section from '../menu_item_section.tpl.html';
import TPL_app_menubar from '../menubar.tpl.html';

/**
 * Main MenuBar View
 *
 *  contains all the different menus
 *
 */
export var MenuBarView = Backbone.View.extend({

  zJSON: undefined, //JSON needed to paint a zone.

  initialize: function(options) {
    this.template = TPL_app_menubar;
    this.templateMenu = TPL_app_menu;
    this.templateItemNiv1 = TPL_app_menu_item_niv1;
    this.templateItemNiv2 = TPL_app_menu_item_niv2;
    this.templateItemSection = TPL_app_menu_item_section;
    this.listenTo(GLOBAL_DATA.rights, "sync", this._reloadIcons);
    this.zViews = {};
    this.zJSON = options.zJSON;
    this.model.on("change:zone", this._zonesSwapping, this);
    this.components = null;
  },

  events: {
    "click .phx-menus-transversal-print-menu a": "_clickOption",
    "click #phx-menus-transversal-right .phx-menus-transversal-print": "_clickMenu",
    "click #phx-menus-transversal-right .phx-menus-transversal-refresh": "_clickRefresh",
    "click #phx-menus-transversal-right .phx-menus-transversal-calend": "_clickCalend",
    "click #phx-menus-transversal-right .phx-menus-transversal-help": "_clickHelp"
  },

  _zonesSwapping: function() {
    var oldZone = this.model.previous("zone");
    var newZone = this.model.get("zone");
    if (oldZone !== "" && !_.isUndefined(oldZone)) {
      this.zViews[oldZone].$el.hide();
      this.zViews[oldZone].$el.attr("aria-hidden", "true");
    }
    if (newZone !== "" && !_.isUndefined(newZone)) {
      this.zViews[newZone].$el.show();
      this.zViews[newZone].$el.attr("aria-hidden", "false");
    }
    // resize header
    Backbone.trigger("resize:header");
  },

  _renderMenuRefresh: function() {
    var refreshButton = this.$el.find("#phx-menus-transversal-right .phx-menus-transversal-refresh");
    refreshButton.addClass("ui-icon").addClass("ui-icon-refresh");
    refreshButton.css("display", "inline-block");

    this.$el.find(".phx-menus-transversal-refresh").show();
  },

  _renderMenuCalend: function() {
    var calendarButton = this.$el.find("#phx-menus-transversal-right .phx-menus-transversal-calend");
    var uc = _.find(this.model.configuration.get("confignav").get("transverses"), { id: "calendacc" });
    if (uc) {
      calendarButton.addClass("ui-icon").addClass("ui-icon-calendar").addClass(uc.emid);
      calendarButton.css("display", "inline-block");

      var divMenu = $("<div>").addClass("phx-menus-transversal-calend-menu ui-menu ui-corner-all");
      divMenu.css({ "position": "fixed", "z-index": "100", "padding": "5px", "right": "10px" });

      this.$el.find("#phx-menus-transversal-right").append(divMenu);

      $(divMenu).hide();
    } else {
      calendarButton.hide();
    }
  },

  _renderMenuPrint: function() {
    var printButton = this.$el.find("#phx-menus-transversal-right .phx-menus-transversal-print");

    var uc = _.find(this.model.configuration.get("confignav").get("transverses"), { id: "etats" });
    if (uc) {
      printButton.addClass("ui-icon").addClass("ui-icon-print").addClass(uc.emid);
      printButton.css("display", "inline-block");

      var divMenu = $("<div>").addClass("phx-menus-transversal-print-menu ui-menu ui-corner-all");
      divMenu.css({ "position": "fixed", "z-index": "100", "padding": "5px", "right": "70px" });
      this.$el.find("#phx-menus-transversal-right").append(divMenu);

      _.each(uc.ecrans, function(ecran) {
        ecran.href = "#z/coll/uc/" + ecran.id;
        ecran.target = "";
        var itemNiv1 = this.templateItemNiv1(ecran);
        $(divMenu).append(itemNiv1);
      }, this);

      $(divMenu).hide();
    } else {
      printButton.hide();
    }

  },

  _reloadIcons: function() {
    this.model.configuration.get("confignav").get("transverses").forEach(function(trans) {
      if (GLOBAL_DATA.rights.menuVisibility.get(trans.emid)) {
        this.$el.find("." + trans.emid).removeClass("ui-state-disabled");
      } else {
        this.$el.find("." + trans.emid).addClass("ui-state-disabled");
      }
    }, this);
  },

  _renderMenuHelp: function() {
    var helputton = this.$el.find("#phx-menus-transversal-right .phx-menus-transversal-help");
    var uc = _.find(this.model.configuration.get("confignav").get("transverses"), { id: "aide" }).emid
    if (uc) {
      helputton.addClass("ui-icon").addClass("ui-icon-help").addClass(uc.emid);
      helputton.css("display", "inline-block");
      this.$el.find(".phx-menus-transversal-help").show();
    } else {
      helputton.hide();
    }
  },

  _clickMenu: function(event) {
    if ($(event.target).hasClass("ui-state-disabled")) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    var menu = this.$el.find(".phx-menus-transversal-print-menu");

    if ($(menu).is(":visible")) {
      $(menu).hide();
    } else {
      $(menu).show();
      //If you click out of the menu, close the menu.
      var self = this;
      $(document).one("mousedown", function(event) {
        var element = $(self.el).find(event.target);
        if (element.length === 0) {
          $(menu).hide();
        }
      });
    }
  },

  _clickRefresh: function() {
    var self = this;
    MSGS.showConfirm(i18n.t('messages:GT_1301'), function(result) {
      if (result === "Y") {
        var usecase = self.model.get("usecase");
        if (STR.isBlank(usecase)) {
          self.model.trigger("reload:brique");
        } else {
          self.model.trigger("predestroy:usecase", self.model.get("usecase"), true);
        }
        self.model.trigger("reload:badgeage");
      }
    });
  },

  _clickCalend: function(event) {
    var self = this;

    if ($(event.target).hasClass("ui-state-disabled")) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    // load dependencies to component
    if (self.calendaccDialog === undefined) {
      self.calendaccDialog = new DialogView({
        view: CalendaccGeneralView
      });
    }
    self.calendaccDialog.setHeight(600);
    self.calendaccDialog.setWidth(1200);
    self.calendaccDialog.open();
    return false;
  },

  _clickHelp: function(event) {
    if ($(event.target).hasClass("ui-state-disabled")) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    var lang = UTILS.getLanguage();
    var uc = objs.appRt.workflow.get("usecase");
    var zone = !STR.isBlank(uc) ? objs.appRt.workflow._searchZoneFromUc(uc) : objs.appRt.workflow.get("zone");
    var ucPath = !STR.isBlank(uc) ? zone + "_" + uc : zone;
    var navBar = $((!STR.isBlank(uc) ? "#" + uc + " .phx-navigation-bar" : ".phx-navigation-bar")).find(".menuText").first().text().split(/[->]+/);

    for (var i = 0; i < navBar.length; i++) {
      navBar[i] = navBar[i].trim();
    }
    if (!STR.isBlank(navBar[1]) && Configuration.userlocale === "fr") {
      // add subsections for screen Suivicollab ( we get value from pos in navBar )
      ucPath = this._checkNavBarOption(navBar, ucPath, uc, zone);
    }

    var globalPath = null;
    // Until they are implemented, idioms different to english and french will be french
    if (lang !== "fr" && lang !== "en") {
      lang = "fr";
    }
    globalPath = !STR.isBlank(ucPath) ? "help/aide.html?lang=" + lang + "#" + ucPath : "help/aide.html?lang=" + lang;

    var tab = window.open(
      globalPath,
      "Aide", 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=800,height=600'
    );
    tab.focus();
  },

  _checkNavBarOption: function(navBar, a_ucPath, uc) {
    var l_ucPath = a_ucPath;

    if (uc === "fichecollab") {
      l_ucPath = this._helpFicheCollab(navBar.pop(), a_ucPath);
    }
    if (uc === "suivicollab") {
      l_ucPath = this._helpSuiviCollab(navBar.pop());
    }
    return l_ucPath;
  },

  _helpSuiviCollab: function(a_ucPath) {
    var l_text = a_ucPath;

    if (i18n.t('suivicollab.menuSituation') === a_ucPath ||
      i18n.t('suivicollab.menuEtatCivil') === a_ucPath ||
      i18n.t('suivicollab.menuContact') === a_ucPath ||
      i18n.t('suivicollab.menuHoraireRef') === a_ucPath ||
      i18n.t('suivicollab.menuContrat') === a_ucPath ||
      i18n.t('suivicollab.menuChangements') === a_ucPath ||
      i18n.t('suivicollab.menuCaracteristiques') === a_ucPath ||
      i18n.t('suivicollab.menuPeriodePresence') === a_ucPath ||
      i18n.t('suivicollab.menuRattachements') === a_ucPath ||
      i18n.t('suivicollab.menuBadgeage') === a_ucPath ||
      i18n.t('suivicollab.menuActivites') === a_ucPath ||
      i18n.t('suivicollab.menuClassification') === a_ucPath ||
      i18n.t('suivicollab.menuInfComp') === a_ucPath) {
      l_text = "_" + a_ucPath.replace(/\s/g, "_") + "_1";
    } else if (i18n.t('suivicollab.menuProfilsMetier') === a_ucPath ||
      i18n.t('suivicollab.menuCompetences') === a_ucPath ||
      i18n.t('suivicollab.menuInaptitudes') === a_ucPath ||
      i18n.t('suivicollab.menuRecapitulatifs') === a_ucPath ||
      i18n.t('suivicollab.menuVueJournee') === a_ucPath ||
      i18n.t('suivicollab.menuResindiv') === a_ucPath ||
      i18n.t('suivicollab.menuSoldes') === a_ucPath ||
      i18n.t('suivicollab.menuDroits') === a_ucPath ||
      i18n.t('suivicollab.menuPretsDisponibilites') === a_ucPath ||
      i18n.t('suivicollab.menuEmpTemps') === a_ucPath) {
      l_text = "_" + a_ucPath.replace(/\s/g, "_");
    } else if (i18n.t('suivicollab.menuRecapActivite') === a_ucPath ||
      i18n.t('suivicollab.menuRegularisations') === a_ucPath) {
      l_text = "_" + a_ucPath.replace(/\s/g, "_") + "_2";
    } else if (i18n.t('suivicollab.menuAnomalies') === a_ucPath) {
      l_text = "_" + a_ucPath.replace(/\s/g, "_") + "_3";
    } else if (i18n.t('suivicollab.menuAbsences') === a_ucPath) {
      //"menuAbsences": "Absences" sans Souhait
      l_text = i18n.t('suivicollab.menuAbsences') + "_4";
    } else if (i18n.t('suivicollab.menuCircuitsValidation') === a_ucPath) {
      l_text = "resp_suivicollab";
    } else if (i18n.t('suivicollab.menuStructures') === a_ucPath ||
      i18n.t('suivicollab.menuOrgAdmin') === a_ucPath ||
      i18n.t('suivicollab.menuSiteGeograph') === a_ucPath ||
      i18n.t('suivicollab.menuZoneScolaire') === a_ucPath) {
      l_text = "_Rattachements_1";
    } else if (i18n.t('suivicollab.menuDelegations') === a_ucPath) {
      l_text = "resp_delegation"; //meilleur que utiliser "_Delegations"
    } else if (i18n.t('suivicollab.menuAbsencesSohuaits') === a_ucPath) {
      l_text = a_ucPath.replace(/\s/g, "_") + "_1";
    } else if (i18n.t('suivicollab.menuAutresEvenements') === a_ucPath) {
      l_text = "resp_evenements";
    } else {
      l_text = "_Fiche_collaborateur_2";
    }
    return l_text;
  },

  _helpFicheCollab: function(a_ucPath, a_pathDefault) {
    var l_text = a_ucPath;

    if (i18n.t('fichecollab.menuSituation') === a_ucPath ||
      i18n.t('fichecollab.menuEtatCivil') === a_ucPath ||
      i18n.t('fichecollab.menuContact') === a_ucPath ||
      i18n.t('fichecollab.menuHoraireRef') === a_ucPath ||
      i18n.t('fichecollab.menuChangements') === a_ucPath ||
      i18n.t('fichecollab.menuCaracteristiques') === a_ucPath ||
      i18n.t('fichecollab.menuPeriodePresence') === a_ucPath ||
      i18n.t('fichecollab.menuBadgeage') === a_ucPath ||
      i18n.t('fichecollab.menuClassification') === a_ucPath ||
      i18n.t('fichecollab.menuEmpTemps') === a_ucPath ||
      i18n.t('fichecollab.menuInfComp') === a_ucPath) {
      l_text = "_" + a_ucPath.replace(/\s/g, "_");
    } else if (i18n.t('fichecollab.menuProfilsMetier') === a_ucPath ||
      i18n.t('fichecollab.menuCompetences') === a_ucPath ||
      i18n.t('fichecollab.menuInaptitudes') === a_ucPath) {
      l_text = "_" + a_ucPath.replace(/\s/g, "_") + "_1";
    } else if (i18n.t('fichecollab.menuRecapitulatifs') === a_ucPath) {
      l_text = "_" + a_ucPath.split(" ")[0] + "_1";
    } else {
      l_text = a_pathDefault;
    }
    return l_text;
  },

  _clickOption: function(event) {
    if ($(event.target).hasClass("ui-state-disabled")) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    var menu = this.$el.find(".phx-menus-transversal-print-menu");
    var name = event.target.className.split(" ")[0];
    var self = this;
    //lanceetat dialog
    var view = null;
    var uc = objs.appRt.workflow.get("usecase");
    var zone = !STR.isBlank(uc) ? objs.appRt.workflow._searchZoneFromUc(uc) : objs.appRt.workflow.get("zone");

    switch (name) {
      case "lanceetat":
        view = GeneralView;
        break;
      case "suvietat":
        view = SuvietatGeneralView;
        break;

      default:
        break;
    }
    if (self.transversalViewDialog === undefined) {
      self.transversalViewDialog = new DialogView({
        view: view,
        viewData: {
          loc: {
            zone: zone,
            uc: uc
          }
        }
      });
    } else {
      self.transversalViewDialog.view = view;
      self.transversalViewDialog.viewData = {
        loc: {
          zone: zone,
          uc: uc
        }
      };
    }
    self.transversalViewDialog.setHeight(600);
    self.transversalViewDialog.setWidth(1200);
    self.transversalViewDialog.open();
    $(menu).hide();
    return false;
  },

  render: function() {
    var json = { i18n: i18n };
    this.$el.html(this.template(json));
    //Let navigation by keyboard (enter key acts as click pressed).
    var self = this;
    _.each(this.$el.find("#phx-menus-transversal-right span"), function(spanItem) {
      $(spanItem).keydown(function(e) {
        self._transversalRightKeyboardNav(e);
      });
    });

    // create zone menus
    _.each(this.model.configuration.get("confignav").get("zones"), function(zone) {
      var zView = new ZoneMenuView({
        id: "phx-menu-zone-" + zone.id,
        model: this.model,
        zJSON: zone
      });
      this.zViews[zone.id] = zView;

      var zoneSelectorMenu = self.$el.parent().parent().find(".phx-zone-selector .phx-zones-menu #phx-btn-zone-" + zone.id);
      zoneSelectorMenu.after(zView.render().el);
      self.$el.parent().parent().find(".phx-zone-selector .phx-zones-menu #phx-menu-zone-" + zone.id).css("position", "absolute").css("top", "1.55em").css("margin-left", "-0.1em");
      // By default it's hidden
      zView.$el.hide();
    }, this);
    this._renderMenuRefresh();
    this._renderMenuPrint();
    this._renderMenuCalend();
    this._renderMenuHelp();

    return this;
  },

  _transversalRightKeyboardNav: function(e) {
    if (e.keyCode === 13 || e.keyCode === 32 || e.keyCode === 40) { //enter, spacebar,  down arrow
      //Open-Close menu
      $(e.target).click();

      var targetClass = e.target.className.split(" ")[0];;
      var parentMenu = $(this.el).find("#phx-menus-transversal-right");
      //Select first element if it exist
      if (parentMenu.find("div." + targetClass + "-menu").length > 0) {
        parentMenu.find("div." + targetClass + "-menu").find("li").first().focus();
      }

      e.preventDefault();
    }
  },
  _transversalPrintKeyboardNav: function(e) {
    var $currentLi = $(e.target);
    if (e.keyCode === 38) { // up
      if ($currentLi.prevAll('li.phx-menu-link').first().length === 0) {
        var lastMenu = $currentLi.nextAll('li.phx-menu-link').last();
        lastMenu.focus();
      } else {
        var prevMenu = $currentLi.prevAll('li.phx-menu-link').first();
        prevMenu.focus();
      }
    } else if (e.keyCode === 40) { // down
      if ($currentLi.nextAll('li.phx-menu-link').first().length === 0) {
        var firstMenu = $currentLi.prevAll('li.phx-menu-link').last();
        firstMenu.focus();
      } else {
        var nextMenu = $currentLi.nextAll('li.phx-menu-link').first();
        nextMenu.focus();
      }
    } else if (e.keyCode === 32) { // spacebar
      e.target.click();
    } else if (e.keyCode === 27) { // escape
      // Close menu
      var printMenu = $(this.el).find(".phx-menus-transversal-print");
      printMenu.click();
      printMenu.focus();
    } else if (e.keyCode === 9) //tab
    {
      //Close menu before tab
      this._clickMenu(e);

    }
    if (e.keyCode === 13 || e.keyCode === 32) { //enter, spacebar
      //Open-Close menu
      $(e.target).find("a").first()[0].click();
    }
  }
});
