import { AuthModel } from '../../app/models/auth.model.js';
import { ConfigModel } from '../../app/models/config.model.js';
import { GLOBAL_DATA } from '../../globalData.js';
import { i18n } from '../../i18n.js';
import { LOG } from '../../utils/log.js';
import { MSGS } from '../../utils/msgs.js';
import { objs } from '../../objectsRepository.js';
import { ParametresDiversColl } from '../../app/models/parametresDivers.collection.js';
import { ReadOnlyModel } from 'core/models/readOnly.model.js';
import { SESSION } from '../../utils/session.js';
import { STR } from '../../utils/str.js';
import { SubRoute } from 'core/routers/subRoute.js';
import { SYNC } from '../../utils/sync.js';
import { TransversalSyncModel } from '../../app/models/transversalSync.model.js';
import { TypoStandardColl } from '../../app/models/typoStandard.collection.js';
import { TypoEvtGenColl } from '../../app/models/typoEvtGen.collection.js';
import { ThemeRollConfigModel } from '../../app/models/themeRollConfig.model.js';
import { LoginLicenseModel } from 'core/components/login/loginLicense.model.js';
import { forkJoin } from 'rxjs';

export var WorkFlowModelFrame = ReadOnlyModel.extend({

  /**
   * Lougout event.
   *
   */

  /**
   * Lougout event.
   *
   */

  /**
   * Lougout event.
   *
   */

  /**
   * Lougout event.
   *
   */

  /**
   * Lougout event.
   *
   */

  /**
   * Lougout event.
   *
   */

  /**
   * Lougout event.
   *
   */

  /**
   *		ready : false,
   *		fullscreen : false,
   *		zone : "",
   *		usecase : "",
   *		operation : "",
   *		uri : ""
   * }
   */

  defaults: {
    ready: false,
    fullscreen: false,
    zone: "",
    usecase: "",
    operation: "",
    uri: ""
  },

  /**
   * Lougout event.
   *
   */

  /**
   * Constructor
   * Workflow model for App
   * Links to: authModel, navModel
   * Attributes: zone, usecase, operation, uri,
   * 			   tabs-[zoneId] = number of open tabs, "lastuc-[zoneId]" last uc open in zone
   * Events: user:logout, change:zone, change:usecase, change:operation (operation is categories/[list] / [8] ... )
   *         add:tab, remove:tab
   */
  initialize: function() {
    this.ZONES = {};
    this.BRIQUES = {};
    this.TRANSVERSES = {};
    this.paramEcranDef = null;
    /**
     * Contains a model with the authentication information
     */
    this.authModel = new AuthModel({ id: "session" });
    this.configuration = new ConfigModel();
    GLOBAL_DATA.paramDivers = new ParametresDiversColl();
    GLOBAL_DATA.typologies = new TypoStandardColl();
    GLOBAL_DATA.typoevtgen = new TypoEvtGenColl();
    GLOBAL_DATA.licences = {};
    GLOBAL_DATA.licences = new LoginLicenseModel();
    GLOBAL_DATA.theme = {};
    GLOBAL_DATA.theme = new ThemeRollConfigModel();
    this.syncModel = new TransversalSyncModel();
    this.set("ready", false);
  },

  /**
   * Set up the workflow. Link the models between them
   */
  setUp: function(callback) {

    // declare event consumers
    this.on("user:prelogout", this._prelogout, this);
    this.on("user:logout", this._logout, this);
    this.on("change:usecase", this._usecaseChange, this);
    this.on("add:tab", this._addTab, this);
    this.on("remove:tab", this._removeTab, this);

    var self = this;
    // Data of the connected user
    this.authModel.fetch({
      success: function(model) {

        self._themeConfiguration();

        // Launch time synchronization
        SYNC.calculateDelay();

        var userid = model.get("login");
        LOG.debug("User logged is : " + userid);
        self.configuration.fetch({
          success: function() {
            //Initialize << PARAMETRE DIVERS >>
            GLOBAL_DATA.paramDivers.fetch({
              success: function() {
                var language = self.configuration.get("langue").toLowerCase();
                if (STR.isBlank(language)) {
                  language = "fr";
                }
                var languageDefer = $.Deferred();
                i18n.changeLanguage(language, function(err) {
                  if (err) {
                    languageDefer.reject();
                  } else {
                    languageDefer.resolve();
                  }

                });

                var paramDiversDefer = GLOBAL_DATA.paramDivers.fetch();
                var typologiesDefer = GLOBAL_DATA.typologies.fetch();

                var typoevtgenDefer = GLOBAL_DATA.typoevtgen.fetch();
                var licencesDefer = GLOBAL_DATA.licences.fetch();

                var calls = [paramDiversDefer, typologiesDefer, typoevtgenDefer, licencesDefer, languageDefer];
                var sub = forkJoin(calls);
                sub.subscribe(function() {
                    self._menuConfiguration(callback);
                  },
                  function() {
                    self._logout();
                  });
              },
              error: function() {
                self._logout();
              }
            });
          }
        });

      }
    });
    // Screen model is Ready
    this.set("ready", true);
  },

  /**
   * Set up the recorded themplate (themeroll) EVO 569
   */
  _themeConfiguration: function() {
    var model = GLOBAL_DATA.theme;
    model.fetch({
      success: function(model) {
        var theme = model.get("preferences")[0].varchar1;
        if (!theme || theme.length <= 0) {
          theme = "app";
        }
        $("link[rel=stylesheet][id=ui-theme]").attr({ href: "css/" + theme + ".theme.css?_=" + _.random(1, 1000000000000000) });
      }
    });
  },

  _menuConfiguration: function(callback) {
    var self = this;

    // gestion transverses
    var numTransversalMenus = self.configuration.get("confignav").get("transverses").length;
    LOG.debug("Number of transversal menus for the user : " + numTransversalMenus);

    self.TRANSVERSES["user"] = [];

    var transverses = self.configuration.get("confignav").get("transverses");
    _.each(transverses, function(transverse, index) {
      if (transverse["id"] == "ppmpd") {
        self.TRANSVERSES["user"].push(transverse["id"]);
      }

      // ....
    });

    // gestion zones
    var numZones = self.configuration.get("confignav").get("zones").length;
    LOG.debug("Number of zones for the user : " + numZones);
    if (_.isEmpty(self.ZONES)) {
      var zones = self.configuration.get("confignav").get("zones");
      // To manage the numbers of tabs
      self.set("tabstotal", 0);
      _.each(zones, function(zone, index) {
        self.ZONES[zone["id"]] = [];
        self.BRIQUES[zone["id"]] = [];
        self.set("tabs-" + zone["id"], 0);
        _.each(zone.menus, function(menu, index) {
          if (menu.id) {
            self.ZONES[zone["id"]].push(menu["id"]);
          }
          _.each(menu.ecrans, function(uc, index) {
            _.each(uc.ecrans, function(uc2, index) {
              self.ZONES[zone["id"]].push(uc2["id"]);
            });
            if (uc["id"]) {
              self.ZONES[zone["id"]].push(uc["id"]);
            };
          });
        });
        // briques
        _.each(zone.briques, function(brique, index) {
          if (brique["id"]) {
            self.BRIQUES[zone["id"]].push(brique["id"]);
          }
        });

      });
    }

    GLOBAL_DATA.types = self.configuration.get("typeapplicatif");
    GLOBAL_DATA.rights = self.configuration.get("droit");

    //Initialize << PARAMETRE DIVERS >>
    // ecranDef
    self.paramEcranDef = GLOBAL_DATA.paramDivers.get("ecranDef");
    // pers_numer
    self.paramPers_numer = GLOBAL_DATA.paramDivers.get("pers_numer");

    callback();
  },

  isGoingToZoneHome: function() {
    // Check if there are only one in the uri.
    var isGoing = false;

    var match = this.get("uri").match(/\//g);
    if (match != null) {
      isGoing = match.length == 1;
    }

    return isGoing;
  },

  /**
   * Sets the uri when the zone changes
   */
  _zoneChange: function() {
    var zoneId = this.get("zone");
    if (zoneId == "") {
      this.set("uri", "");
    } else {
      this.set("uri", "z/" + zoneId);
    }
  },

  _usecaseChange: function() {
    var zoneId = this.get("zone");
    var ucId = this.get("usecase");
    // Stores the last zone id
    this.set("lastuc-" + zoneId, ucId);
    //		if (ucId == "") {
    //			this.set("uri", "z/" + zoneId);
    //			return;
    //		}
    //		if (!_.contains(this.ZONES[zoneId], ucId)) {
    //			LOG.error("The required zone/usecase is not valid : [" + zoneId + "," + ucId + "]");
    //			return;
    //		}
    //		this.set("uri", "z/" + zoneId + "/uc/" + ucId);
  },

  _operationChange: function() {
    var zoneId = this.get("zone");
    var ucId = this.get("usecase");
    var operation = this.get("operation");
    if (ucId == "") {
      this.set("uri", "z/" + zoneId);
      return;
    }
    if (!_.contains(this.ZONES[zoneId], ucId)) {
      LOG.error("The required zone/usecase is not valid : [" + zoneId + "," + ucId + "]");
      return;
    }
    this.set("uri", "z/" + zoneId + "/uc/" + ucId + "/" + operation);
  },

  /**
   * Realize actions before logout
   */
  _prelogout: function() {
    var stopLogout = false;
    var self = this;

    var subRouters = _.filter(objs, function(obj) {
      return (obj instanceof SubRoute) && (!STR.isBlank(obj._treatDesconnection));
    });

    var treatDesconnection = function(index) {
      subRouters[index]._treatDesconnection(function(cancel) {
        if (!cancel && index < (subRouters.length - 1)) {
          treatDesconnection(index + 1);
        }
        if (index >= (subRouters.length - 1) && !cancel)
          self.trigger("user:logout");
      });
    };
    if (subRouters.length > 0) {
      treatDesconnection(0);
    } else
      self.trigger("user:logout");
  },

  _logout: function() {
    LOG.debug("logout required");
    // Clear user rights
    GLOBAL_DATA.rights = {};
    // Clear session data
    SESSION.clear();

    var self = this;
    this.authModel.destroy({
      success: function(fresh, response) {
        LOG.debug("logout successful");
        self._goToLoginPage();
      },
      error: function(model, xhr, options) {
        LOG.debug("logout failed");
        MSGS.showError("Failed to log out the application !!");
      }
    });
  },

  _addTab: function(zoneId) {
    var attrName = "tabs-" + zoneId;
    var currentNumber = this.get(attrName);
    currentNumber = currentNumber + 1;
    this.set(attrName, currentNumber);
    this.set("tabstotal", this.get("tabstotal") + 1);
  },

  _removeTab: function(zoneId) {
    var attrName = "tabs-" + zoneId;
    var currentNumber = this.get(attrName);
    currentNumber = currentNumber - 1;
    this.set(attrName, currentNumber);
    this.set("tabstotal", this.get("tabstotal") - 1);
  },

  _goToLoginPage: function() {
    window.location.href = "login/login.html";
  },
  _loadPreferedUc: function() {
    var param = this.paramEcranDef;
    // When there is a parameter for prefered uc in start up
    if (param !== undefined && param.get("valeur") != null && param.get("valeur").length > 0) {
      var uc = param.get("valeur");
      var zone = this._searchZoneFromUc(uc);
      if (!STR.isBlank(zone)) { // When it's possible to load the prefered uc
        switch (uc) {
          case "planresp":
            objs.appRt.navigate("z/" + zone + "/pl/" + uc, true);
            break;
          default:
            objs.appRt.navigate("z/" + zone + "/uc/" + uc, true);
        }
      }
    }
  },
  _searchZoneFromUc: function(ucToFind) {
    var foundZone = null;
    for (var zone in this.ZONES) {
      for (var uc in this.ZONES[zone]) {
        if (this.ZONES[zone][uc] == ucToFind) {
          foundZone = zone;
          break;
        }
      }
      if (foundZone != null) {
        break;
      }
    }
    return foundZone;
  }

});
