
import { DroitColl } from './droit.collection.js';
import { TDAColl } from './tda.collection.js';
import { TDAModel } from './tda.model.js';

/**
 * Configuration Model. To be used with these ids: "session" --> Authentication
 * and user in session data "date" --> Server date "langues" --> langues
 * available
 */
export var ConfigModel = Backbone.Model.extend({

  dummy: "",
  /**
   * UrlRoot
   */
  urlRoot: Configuration.restRoot + "/rest/configuration",

  defaults: {},

  /**
   * Process to manage the response
   */
  parse: function(response) {
    if (response.confignav) {
      this.set("confignav", new Backbone.Model(response.confignav));
      delete response.confignav;
    }
    if (response.droit) {
      this.set("droit", new DroitColl(response.droit, { parse: true }));
      delete response.droit;
    }
    if (response.typeapplicatif) {
      this.set("typeapplicatif", new TDAColl());
      var length = response.typeapplicatif.length;
      for (var i = 0; i < length; i++) {
        this.get("typeapplicatif").add(new TDAModel(response.typeapplicatif[i], {
          parse: true
        }));
      }
      delete response.typeapplicatif;
    }
    return response;
  }

});