import { BaseModel } from '../../../../../../core/models/base.model.js';
import { i18n } from '../../../../../../i18n.js';
import { STR } from '../../../../../../utils/str.js';

/**
 * Prets Model
 */
export var PretModel = BaseModel.extend({
  defaults: {
    "id": null,
    "code": "",
    "libelle": "",
    "type": {
      "code": "",
      "libelle": ""
    },
    "motif": {
      "code": "",
      "libelle": ""
    },
    "collaborateur": {
      "nom": "",
      "prenom": "",
      "matric": null,
      "matricaux": "",
      "dmaj": "",
      "indicpret_resp": ""
    },
    "typepret": "",
    "indicateurp": false,
    "indicateurr": false,
    "emprunt": false,
    "datedeb": null,
    "datefin": null,
    "unitedeb": {
      "code": "",
      "libelle": ""
    },
    "unitefin": {
      "code": "",
      "libelle": ""
    },
    "heuredeb": null,
    "heurefin": null,
    "duree": null,
    "pourcentage": null,
    "dureecalculee": null,
    "perimetre": {
      "code": null,
      "libelle": ""
    },
    "droit": "",
    "profils": [],
    "structures": [],
    "indicateurpj": false,
    "piecesjointes": [],
    "etat": "",
    "statut": {
      "code": "",
      "libelle": ""
    },
    "derniereaction": {
      "action": "",
      "date": "",
      "auteur": ""
    }
  },

  validate: function( /*attrs*/ ) {
    var errors = {};

    if (STR.isBlank(this.selectedProfil) || this.selectedProfil.code == "0") {
      var profilError = i18n.t('common:required', { "0": i18n.t('common:listerprets.role') });
      var profilId = 'comboProfil_' + this.cid;
      errors[profilId] = profilError;
      this.profilErrors = profilError;
    }

    if (!_.isEmpty(errors)) {
      var errorMap = {
        errorValidation: STR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
    return null;
  },

  parse: function(response) {
    if (response.code !== undefined) {
      response.id = response.code;
    }
    if (response.collaborateur.indicpret_resp == "R") {
      response.typepret = "R";
    } else {
      response.typepret = "P";
    }

    return response;
  }
});