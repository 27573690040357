import { BaseModel } from 'core/models/base.model.js';

export var WkfHistoModel = BaseModel.extend({

  defaults: {
    "acte": null,
    "auteur": {
      "@auteur": "",
      "message": null
    },
    "codefonctionnel": "CO_AJ_D",
    "commentaire": "",
    "moments": [{
      "@date": "20130925",
      "message": null
		}]
  }
});