import { BaseModel } from '../../../../../../core/models/base.model.js';
import { EditableGridModel } from '../../../../../../core/grids/editablegrid/editableGrid.model.js';
import { FormView } from '../../../../../../core/views/form.view.js';
import { HABILITATION } from '../../../../../../utils/habilitation.js';
import { HabilitationContext } from '../../../../../../core/models/habilitationContext.js';
import { i18n } from '../../../../../../i18n.js';
import { MenuGridView } from '../../../../../../core/grids/menugrid/menuGrid.view.js';
import { MSGS } from '../../../../../../utils/msgs.js';
import { PiecesJointesColl } from '../models/piecesJointes.collection.js';
import { RemoteFileModel } from '../../../../../../core/models/remoteFile.model.js';
import { STR } from '../../../../../../utils/str.js';
import { GererpiecesjointesWorkflow } from '../models/gererpiecesjointes.workflow.js';
import TPL_gererpiecesjointes_gererpiecesjointes from '../gererpiecesjointes.tpl.html';

/**
 *
 * Render gerer pieces jointes view
 */
export var GererPiecesJointesView = FormView.extend({

  initialize: function(options) {

    this.template = TPL_gererpiecesjointes_gererpiecesjointes;

    this.model = new BaseModel({
      value: new RemoteFileModel(),
      mode: "C"
    });

    this.workflow = new GererpiecesjointesWorkflow();
    this.workflow.gererPiecesJointes = this;
    this.workflow.setUp();
    if (options) {
      if (options.context) {
        this.context = options.context;
      }
      if (options.coll) {
        this.coll = this._listToCollection(options.coll);
      }

      if (options.parent) {
        this.parent = options.parent;
      }

      if (options.fileTransferView) {
        this.fileTransferView = options.fileTransferView;
      }
      if (options.description) {
        this.description = options.description;
      }
      if (options.maxPieces) {
        this.maxPieces = options.maxPieces;
      }
    }

    this.table = this._initTable();
    this._initFileTransfer();
  },

  events: {
    "click div.phx-piece-jointe span.piece-jointe-file": "_manageFileClick",
  },

  _initFileTransfer: function() {
    if (this.fileTransferView) {
      this.fileTransferView.model.off("uploadReady");
      this.fileTransferView.model.on("uploadReady", function() {
        if (this.parent) {
          this.model.trigger("piecejointeAdded", this.fileTransferView);
          this.fileTransferView = null;
          this.parent.close();
        }
      }, this);
      this.fileTransferView.model.on("cancelUpload", function() {
        if (this.parent) {
          //this.model.trigger("piecejointeAdded", this.fileTransferView);
          this.fileTransferView = null;
          this.parent.close();
        }
      }, this);
    }
  },

  _listToCollection: function(list) {
    var collection = new PiecesJointesColl();

    if (list) {
      _.each(list, function(item) {
        if (item.deleted !== true) {
          var model = new RemoteFileModel(item);
          collection.add(model);
        }
      }, this);
    }
    collection.paginated = false;
    return collection;
  },

  setContext: function(context) {
    var self = this;
    this.context = context;

    self._manageModeRepresentation();
    self._manageUtilisateur();
    self._manageDescription();
    self._manageModeInitialisation();
  },

  setPJ: function(coll, description) {
    this.coll = this._listToCollection(coll);
    this.description = description;

    this.setContext(this.context);

    this.table.model.coll.reset(this.coll.models);
  },

  _manageModeRepresentation: function() {
    this._setTitlePopUp();
  },

  _setTitlePopUp: function() {
    var title = i18n.t('common:gererpiecesjointes.piecejointe_title');

    this.parent._setTitle(title);
  },

  _manageUtilisateur: function() {
    var collab$ = this.$el.find(".ctxGestionCollab");

    if (this.context && this.context.ctxGestionCollab && !STR.isBlank(this.context.ctxGestionCollab.nom)) {
      // information du collaborateur
      var collabText = this.context.ctxGestionCollab.nom + " " + this.context.ctxGestionCollab.prenom;
      if (STR.isBlank(this.diver) || this.diver === "matric") {
        collabText += " (" + this.context.ctxGestionCollab.matricule + ")";
      } else {
        collabText += " (" + this.context.ctxGestionCollab.matric_aux + ")";
      }
      collab$.html(collabText);
      collab$.show();
    } else {
      collab$.remove();
    }
  },

  _manageDescription: function() {
    var description$ = this.$el.find(".ctxDescription");

    if (this.context && this.description) {
      var text = this.description;
      description$.html(text);
      description$.show();
    } else {
      description$.remove();
    }
  },

  _manageModeInitialisation: function() {},

  render: function() {
    var json = { "i18n": i18n, model: this.model.get("value") };
    this.$el.empty();

    this.$el.html(this.template(json));

    if (this.context) {
      this.setContext(this.context);
    }
    if (this.fileTransferView) {
      $(this.el).find(".file_transfer").hide();
      $(this.el).find(".file_transfer").html(this.fileTransferView.render().el);
    }
    $(this.el).find(".table_pieces_jointes").html(this.table.el);
    this.table.render();
    this.table.setTotalRowsVisible(false);

    // Customize the new action of the buttonBar
    this.table.buttonBar.buttons.habilitation["new"] = this.canAddPieceJointe();
    this.table.buttonBar.buttons.habilitation["delete"] = this.canDeletePieceJointe();
    this.table.buttonBar.model.set("mode", "C");
    //Check Items Limit
    this._checkItemLimit();

    this.table.overrideMenu(this._customBtnAction, this);

    if (this.coll.length == 0 && this.fileTransferView) {
      $(this.el).find(".table_pieces_jointes").hide();
      $(this.el).find(".file_transfer").show();
    }

    if (!this.canAddPieceJointe()) {
      $(this.el).find(".table_pieces_jointes").show();
      $(this.el).find(".file_transfer").hide();
    }

    if (this.parent) {
      this.parent.resize();
    }

    return this;
  },

  canAddPieceJointe: function() {
    var manage = this.context &&
      this.context.ctxHabilitation &&
      this.context.ctxHabilitation.HabilitationGestion &&
      HABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion);

    return (manage && this.context.ctxActionsPossibles && this.context.ctxActionsPossibles.indexOf("Creer") != -1);
  },

  canDeletePieceJointe: function() {
    var manage = this.context &&
      this.context.ctxHabilitation &&
      this.context.ctxHabilitation.HabilitationGestion &&
      HABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion);

    return (manage && this.context.ctxActionsPossibles && this.context.ctxActionsPossibles.indexOf("Supprimer") != -1);
  },

  /**
   * Checks if can add more files, if not hide the Add button.
   */
  _checkItemLimit: function() {
    if (!_.isUndefined(this.maxPieces) && this.coll.length >= this.maxPieces) {
      this.table.buttonBar.model.trigger("hide:new");
    }
  },

  _initTable: function() {
    var self = this;
    var tblColumns = [{ title: "", property: "icon", width: 25 },
      { title: i18n.t('common:gererpiecesjointes.libelle'), property: "libelle", width: 400 }
    ];

    var tableModel;
    if (this.coll) {
      tableModel = new EditableGridModel({ coll: this.coll });
    } else {
      tableModel = new EditableGridModel({ coll: new PiecesJointesColl() });
    }

    var table = new MenuGridView({
      id: "piece_jointe_table",
      columns: tblColumns,
      model: tableModel,
      title: i18n.t('common:gererpiecesjointes.table_title'),
      showFilteredRowsInTitle: true,
      gridHeightType: "auto",
      maxVisibleRows: "5",
      parentContainer: this.parent
    });

    table.cellRenderers["icon"] = function(model) {
      var div = $("<div>");
      var icon = $("<span>");

      div.addClass("phx-piece-jointe");

      icon.addClass("piece-jointe-icon");
      icon.addClass(self.mimeClass(model.get("mime")));

      div.html(icon);
      return div;
    };

    table.cellRenderers["libelle"] = function(model) {
      var div = $("<div>");
      var file = $("<span>");

      div.addClass("phx-piece-jointe");

      try {
        // var libelle = !STR.isBlank(model.get("reelNom")) ? model.get("reelNom") : model.get("libelle").substring(model.get("libelle").lastIndexOf("_") + 1);
        var libelle = model.get("reelNom") ? model.get("reelNom") : model.get("code") === null ? model.get("libelle") : model.get("libelle").substring(model.get("libelle").lastIndexOf("_") + 1);
        file.attr("data-code", model.get("code"));
        file.html(libelle);
      } catch (e) {
        file.html(!STR.isBlank(model.get("reelNom")) ? model.get("reelNom") : model.get("libelle"));
      }

      // show text as a link to open pieces jointes
      file.addClass("piece-jointe-file");

      div.append(file);
      if (!STR.isBlank(model.get("description"))) {
        div.append("<br>");
        div.append(model.get("description"));
      }

      return div;
    };
    table.height = 200;

    table.setSortableColumns();

    return table;
  },

  mimeClass: function(mime) {
    switch (mime) {
      case "doc":
      case "docx":
        return "phx-icon-doc";
      case "pdf":
        return "phx-icon-pdf";
      case "png":
      case "bmp":
      case "jpg":
      case "jpeg":
        return "phx-icon-img";
      case "xls":
        return "phx-icon-xls";
      default:
        return "ui-phx-piece-jointe-icon";

    }
  },

  // open file in another navigator tab
  _manageFileClick: function(event) {
    var code = $(event.target).attr("data-code");

    if (code) {
      window.open(Configuration.restRoot + "/rest/fichier/download?id=" + encodeURIComponent(code) + "&x_token_key=" + encodeURIComponent(Configuration.x_token_key), "_blank");
    }

  },

  _customBtnAction: function(buttonId) {
    var self = this;
    var current = this.table.model.get("value");

    switch (buttonId) {
      case "new":
        if (this.fileTransferView) {
          $(this.el).find(".table_pieces_jointes").hide();
          $(this.el).find(".file_transfer").show();
        }
        break;
      case "delete":
        MSGS.showConfirm(i18n.t('common:delconfirm'), function(result) {
          if (result === "Y") {
            if (STR.isBlank(current.getHabContext())) {
              if (!STR.isBlank(self.parent) && !STR.isBlank(self.parent.viewData) && !STR.isBlank(self.parent.viewData.context)) {
                current.setHabContext(new HabilitationContext({
                  onglet: self.parent.viewData.context.ctxEcran,
                  fonc_cour: self.parent.viewData.context.ctxHabilitation.HabilitationGestion,
                  nat_gest: "S"
                }));
              }
            }
            if (current.get("code") === current.id) //The pj is in the server, postpone the destroy to piecesjointes/views.js:applyPendingActions
            {
              self.model.trigger("piecejointeRemoved", current.get("code") ? current.get("code") : current.get("cid"));
              self.parent.close();
            } else {
              current.destroy({
                success: function() { //Will never run?
                  if (self.parent) {
                    self.model.trigger("piecejointeRemoved", current.get("code") ? current.get("code") : current.get("cid"));
                    self.parent.close();
                  }
                },
                error: function() { //Deleted an a non uploaded pj
                  if (self.parent) {
                    self.parent.close();
                  }
                }
              });
            }
          }
        });
        break;
    }
  }
});
