module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<li role="menuitemradio" aria-haspopup="true" tabindex="0" id="phx-btn-zone-'+
((__t=(id))==null?'':__t)+
'">\n  '+
((__t=(nom))==null?'':__t)+
'\n</li>|';
}
return __p;
};
