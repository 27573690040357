
import { PaginatedCollection } from 'core/models/paginated.collection.js';
import { SearchListeGroupesModel } from './searchListeGroupes.model.js';

/**
 * Collection of SearchListeGroupes
 */
export var SearchListeGroupesColl = PaginatedCollection.extend({

  model: SearchListeGroupesModel,

  url: function() {
    return Configuration.restRoot + "/rest/resultatsgroupecpt/groupes/" + encodeURIComponent(this.typegroupe);
  },

  initialize: function() {
    PaginatedCollection.prototype.initialize.call(this);
  },

  clone: function() {
    var temp = PaginatedCollection.prototype.clone.call(this);
    return temp;
  }
});