import { BaseModel } from 'core/models/base.model.js';
import { ComboBoxView2 } from 'core/components/combo/comboBoxView2.js';
import { FORMS } from '../../../../utils/forms.js';
import { FormView } from 'core/views/form.view.js';
import { i18n } from '../../../../i18n.js';
import { SearchListeGroupesData } from '../models/searchListeGroupesData.js';
import { TYPE } from '../../../../tda/tda.js';
import TPL_resultatgrpcompteurs_consultation from '../consultation.tpl.html';

/**
 * DialogView of the ConsultationDialogView
 */
export var ConsultationDialogView = FormView.extend({

  initialize: function(options) {
    this.template = TPL_resultatgrpcompteurs_consultation;

    this.model = new BaseModel({
      value: new SearchListeGroupesData()
    });
    if (options && options.comboOptions) {
      this.comboOptions = options.comboOptions;
      this.model = options.model;
    }

    this.configSouhait = options.configSouhait;
  },

  events: _.defaults({
    "change :input:not([readonly])": "_change"
  }, TYPE.DATE.events()),

  render: function() {
    var json = { 'i18n': i18n };
    $(this.el).html(this.template(json));

    if (this.comboOptions.length > 1) {
      this.comboGroupe = new ComboBoxView2({
        enum: this.comboOptions.toJSON(),
        name: "groupe",
        required: true
      });
      $(this.el).find(".cmbGroupe").html(this.comboGroupe.render().el);
    } else {
      $(this.el).find(".groupecompteurs-consultation-groupe").hide();
    }

    if (this.configSouhait === false) {
      $(this.el).find(".checkbox-zone-souhait").hide();
    }

    FORMS.setDatepicker(this, ".date");

    this._mapToForm();
    return this;
  },

  _mapToForm: function() {
    var consu = this.model;

    // Error management
    consu.off("invalid");
    consu.on("invalid", this._showValidationErrors, this);
    // Clear current validation erros
    this._cleanValidationErrors();

    // Map the inputs, checkboxes, selects, textareas from the model
    var fieldset = this.$el.find("fieldset");
    this._mapModelToForm(fieldset, consu);
  },

  _getModel: function() {
    return this.model;
  }
});