import { PaginatedCollection } from '../../../../../../core/models/paginated.collection.js';
import { SaisieOptionsModel } from './saisieOptions.model.js';
import { STR } from '../../../../../../utils/str.js';

/**
 * Collection for Type d'estructures
 */
export var TypeStructuresColl = PaginatedCollection.extend({

  model: SaisieOptionsModel,

  initialize: function() {
    PaginatedCollection.prototype.initialize.call(this);
    this.paginated = false;
  },

  url: function() {
    if (!STR.isBlank(this.datedeb))
      return Configuration.restRoot + "/rest/composant/pret/structures/" + this.datedeb;
    else
      return Configuration.restRoot + "/rest/composant/pret/structures";
  }

});