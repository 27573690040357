import { i18n } from '../i18n.js';
import { LOG } from '../utils/log.js';
import { MSGS } from '../utils/msgs.js';
import { objs } from '../objectsRepository.js';
import { STR } from '../utils/str.js';
import { UTILS } from '../utils/utils.js';
import { LoginTestConnModel } from 'core/components/login/loginTestConn.model.js';

export var onError = function(e, xhr, options, thrownError) {

  if (xhr.omit === true) {
    return;
  }
  if (window.location.pathname.indexOf("login.html") === -1 && objs && objs.appRt.workflow && objs.appRt.blocked === true) {
    //Prevent app of being blocked if there is an error
    objs.appRt.workflow.trigger("unblock:app");
  }

  try {
    LOG.error("ERROR Ajax : " + JSON.stringify(xhr));
  } catch (e) {
    try {
      LOG.error("ERROR Ajax : " + JSON.stringify(_.omit(xhr, "responseXML")));
    } catch (e1) {
      LOG.error("ERROR Ajax : unknown");
    }
  }

  var customMsg = {};
  var status = xhr.status;
  var l_msg = "";

  try {
    if (xhr.responseText) {
      customMsg = JSON.parse(xhr.responseText);
    } else {
      customMsg = e.responseData || {};
    }
  } catch (error) {
    customMsg = {};
  }

  if (status === 200 && _.isEmpty(customMsg)) {
    // It is a correct response, but empty, without results.
    return;
  }

  if (status === 0 || status > 12000) {
    if (window.location.pathname.indexOf("login.html") === -1) {
      var testConnectionModel = new LoginTestConnModel();
      testConnectionModel.fetch({
        timeout: testConnectionModel.timeout,
        error: function() {
          if (UTILS._isLoged() === true) {
            //Forbidden. Security issue, redirect to login page
            MSGS.showWarning(i18n.t('common:sesionExpire'), UTILS._logear);
          } else {
            UTILS._logear();
          }
        },
        success: function() {
          //Nothing
        }
      });
    }
  }

  if (status === 403 || status === 440) {
    if (customMsg && customMsg.message) {
      MSGS.showError(customMsg.message);
    } else {
      if (UTILS._isLoged() === true) {
        //Forbidden. Security issue, redirect to login page
        if (objs.appRt.workflow.authModel) {
          objs.appRt.workflow.authModel.clear();
        }
        MSGS.showWarning(i18n.t('common:sesionExpire'), UTILS._logear);
      } else {
        UTILS._logear();
      }
    }
    return;
  }

  if (status === 404) {
    if (options.url.indexOf("/tpl/") !== -1) {
      throw new Error("Error 404 on getting missing templates: " + options.url + "\nError code:(LoadingUCException)");
    } else if (options.url.indexOf(".js") !== -1) {
      throw new Error("Error 404 on getting wrong js file: " + options.url + "\nError code:(LoadingUCException)");
    } else {
      l_msg = i18n.t(['messages:GT_1316', 'common:server_error'], { "error": xhr.status + ": " + xhr.statusText });
      MSGS.showError(l_msg);
    }
    return;
  }

  if (status === 410) {
    //Error already treated by a component
    return;
  }

  //No right enough to access to a webservice
  //Security tag is missing
  //XSS intrusion
  //Jeton erreur
  if (status === 550 || status === 551 ||
    status === 552 || status === 553) {

    MSGS.showError(i18n.t('common:security', { '0': status }));
  } else if (status === 406 || status === 500) {
    // spécial case with grid column ordering
    if (customMsg.message === "-904") {
      customMsg.message = "Tri non disponible";
    }

    if (!STR.isBlank(customMsg.titre) && !STR.isBlank(customMsg.message)) {
      // notification message returned by server that needs processing...
      MSGS.showNotification(customMsg, function(result) {
        switch (result) {
          case "ABORT":
            break;
          case "CONTINUE":
            options.success();
            break;
          default:
            options.url = Configuration.restRoot + "/rest/" + result;
            $.ajax(options);
        }
      }, options);
    } else {
      if (STR.isBlank(customMsg.message)) {
        MSGS.showError("ERROR : " + xhr.status + ": " + xhr.statusText);
      } else {
        MSGS.showError(customMsg.message);
      }
    }
  } else if (customMsg.errorBoMsg) {
    MSGS.showError("ERROR : " + customMsg.errorBoMsg);
  } else if (customMsg.errorMessage) {
    MSGS.showError("ERROR : " + xhr.status + " / " + customMsg.errorMessage);
  } else if (status >= 400 && status <= 599) {
    l_msg = i18n.t(['messages:GL_1068', 'common:server_error'], { "error": xhr.status + ": " + xhr.statusText });

    MSGS.showError(l_msg);
  }
  // TODO waiting Céline and Dany response.
  //	else if ( status != 0 && status <= 12000 ) {
  //		MSGS.showError(i18n.t('messages:GT_1317'));
  //	}
  else {
    // status = 0 or status = 12029 (IE9)
    LOG.error("ERROR : " + xhr.status + ": " + xhr.statusText);
  }
};
