import { BaseModel } from 'core/models/base.model.js';
import { ComboBoxView2 } from 'core/components/combo/comboBoxView2.js';
import { CombosColl } from 'core/components/combo/combos.collection.js';
import { CriteresColl } from '../models/criteres.collection.js';
import { CriteresReinitModel } from '../models/criteresReinit.model.js';
import { CritidComboColl } from '../models/critidCombo.collection.js';
import { DynamicComboModel } from '../models/dynamicCombo.model.js';
import { DynamicComboModelColl } from '../models/dynamicComboModel.collection.js';
import { FORMS } from '../../../../utils/forms.js';
import { FormView as CommonFormView } from 'core/views/form.view.js';
import { HABILITATION } from '../../../../utils/habilitation.js';
import { HabilitationContext } from 'core/models/habilitationContext.js';
import { HEADERS } from '../../../../utils/headers.js';
import { i18n } from '../../../../i18n.js';
import { LancerEtatModel } from '../models/lancerEtat.model.js';
import { objs } from '../../../../objectsRepository.js';
import { PopulationComboModel } from '../models/populationCombo.model.js';
import { SelecteurCheminView } from 'core/components/selecteur_chemin/selecteurChemin.view.js';
import { SelecteurRefDialogView } from './selecteurRefDialog.view.js';
import { SelecteurReferentielView } from 'core/components/selecteur_referentiel/selecteurReferentiel.view.js';
import { STR } from '../../../../utils/str.js';
import { TYPE } from '../../../../tda/tda.js';
import TPL_lanceetat_detail from '../detail.tpl.html';
import { LOG } from '../../../../utils/log.js';

export var FormView = CommonFormView.extend({

  initialize: function(options) {
    this.template = TPL_lanceetat_detail;

    this.coll = new CriteresColl();
    var criteresHab = new HabilitationContext({
      onglet: "lanceetat",
      fonc_cour: "RES_RECAP.E",
      nat_gest: ""
    });
    this.coll.setHabContext(criteresHab);

    this.groupe = 1;
    this.isValidCollection = false;

    if (options && options.workflow) {
      this.workflow = options.workflow;
      this.workflow.formView = this;
    }

    this.model = new BaseModel({
      value: new BaseModel()
    });

    this.listenTo(this.coll, "sync", this.mapToForm);
    this.listenTo(this.coll, "validateColl", this._isValidCollection);

    this.combos = {};
    this.referentielArray = [];
  },

  mapToForm: function(model) {
    this._cleanValidationErrors();
    $(this.el).find(".phx-lanceetat-detail-content").empty();
    this.groupe = []; //	on initialise pour groupes
    this.subgroupe = []; //	on initialise pour subgroupes
    this.numSubGroupe = []; //un compteur de subgroupes
    this._checkButtons();
    this.workflow.btnBarModel.trigger("enable:newhj");
    this._prepareGroupes(model); // révise les groupes de les sous-groupes
    if (!STR.isBlank(model)) {
      this.referentielArray = [];
      this._manageGroupes(model);
      this._chargeFieldData(model, false);
    }
  },

  _checkButtons: function() {
    if (this.workflow && this.workflow.btnBarModel && this.workflow.tableModel && this.workflow.tableModel.get("value")) {
      var lb_print = this.workflow.tableModel.get("value").get("imprimer");
      var lb_export = this.workflow.tableModel.get("value").get("exporter");
      var ls_action = "";

      if (lb_print === true) {
        ls_action = "enable:print";
      } else {
        ls_action = "disable:print";
      }
      this.workflow.btnBarModel.trigger(ls_action);
      if (lb_export === true) {
        ls_action = "enable:export";
      } else {
        ls_action = "disable:export";
      }
      this.workflow.btnBarModel.trigger(ls_action);
    }
  },

  _numberSubgroup: function(a_groupe) {
    var l_rtn = -1;
    if (!STR.isBlank(a_groupe) && this.subgroupe[a_groupe] && this.numSubGroupe.length > 0) {
      l_rtn = this.numSubGroupe[a_groupe]; //_.values(this.subgroupe[a_groupe]).length;
    }
    return l_rtn;
  },

  _orderCodeSubgroup: function(a_groupe, a_code) {
    var l_rtn = -1;

    if (!STR.isBlank(a_groupe) && this.subgroupe[a_groupe] && this.subgroupe[a_groupe][a_code]) {
      l_rtn = this.subgroupe[a_groupe][a_code];
    }
    return l_rtn;
  },

  _ExistInSousCriteres: function(a_code, a_model) {
    var lb_rtn = false;
    var element = a_model.get("souscriteres");

    if (element.length > 0) {
      for (var i = 0; i < element.length; i++) {

        if (element.at(i).get("alignement") == a_code) {
          lb_rtn = true;
          break;
        } else {
          if (element.at(i).get("souscriteres").length > 0) {
            lb_rtn = this._ExistInSousCriteres(a_code, element.at(i));
          }
        }
      }
    }
    return lb_rtn;
  },

  _existAlignementForGroup: function(a_code, a_models) {
    var lb_rtn = false;

    if (a_code && a_models && a_models.length > 0) {
      for (var i = 0; i < a_models.length; i++) {
        if (a_models.at(i).get("alignement") == a_code) {
          lb_rtn = true;
          break;
        } else {
          lb_rtn = this._ExistInSousCriteres(a_code, a_models.at(i));
          if (lb_rtn) {
            break;
          }
        }
      }
    }
    return lb_rtn;
  },

  _searchGroup: function(a_code) {
    var l_rtn = -1;

    if (a_code && this.coll && this.coll.models.length > 0) {
      if (_.isEmpty(this.coll.get(a_code))) {
        for (var i = 0; i < this.coll.models.length; i++) {
          if (this._ExistInSousCriteres(a_code, this.coll.models[i])) {
            l_rtn = this.coll.models[i].get("groupe");
            break;
          }
        }
      } else {
        l_rtn = this.coll.get(a_code).get("groupe");
      }
    }
    return l_rtn;
  },

  _manageGroupes: function(a_model) {
    var value = a_model.get("value") !== undefined ? a_model.get("value") : a_model;

    if (value) {
      var $div = $(this.el).find(".phx-lanceetat-detail-content");
      var lb_firstAlig = false;

      _.each(value.models, function(model) {
        var l_aux = [];
        if (model) {
          var l_groupe = model.get("groupe");

          if (this.groupe.indexOf(l_groupe) === (-1)) {
            this.groupe.push(l_groupe);
          }
          if (this.subgroupe[l_groupe]) {
            if ((this._existAlignementForGroup(model.get("code"), value)) || lb_firstAlig) {
              lb_firstAlig = false;
              l_aux = this.subgroupe[l_groupe];
              this.numSubGroupe[l_groupe]++;
              l_aux[model.get("code")] = this.numSubGroupe[l_groupe]; //nouveau groupe
              this.subgroupe[l_groupe] = l_aux;
            } else {
              l_aux = this.subgroupe[l_groupe];
              l_aux[model.get("code")] = this.numSubGroupe[l_groupe]; //même groupe
              this.subgroupe[l_groupe] = l_aux;
            }
          } else {
            l_aux[model.get("code")] = 1;
            lb_firstAlig = false;
            if (this._existAlignementForGroup(model.get("code"), value)) {
              lb_firstAlig = true;
            }
            this.subgroupe[l_groupe] = l_aux;
            this.numSubGroupe[l_groupe] = 1;
          }
        }
      }, this);
      //on crée la structure
      for (var i = 0; i < this.groupe.length; i++) {
        var l_div1 = $('<div>').addClass("phx-lanceetat-detail-content-groupe" + this.groupe[i]).css({ "display": "table", "width": "100%" });
        var l_len = this._numberSubgroup(this.groupe[i]);
        var l_tmp = null;

        if (l_len > 1) {
          for (var k = 1; k <= l_len; k++) {
            l_tmp = $('<div>').addClass("phx-lanceetat-detail-content-groupe" + this.groupe[i] + "-subgroupe" + k + "-row").attr('style', "width:100%; display:table-row; min-width:796px;");
            l_tmp.append($('<div>').addClass("phx-lanceetat-detail-content-groupe" + this.groupe[i] + "-subgroupe" + k + "-column1").attr('style', "width:48%; display:table-cell; vertical-align: top; min-width:398px;"));
            l_tmp.append($('<div>').addClass("phx-lanceetat-detail-content-groupe" + this.groupe[i] + "-subgroupe" + k + "-column2").attr('style', "width:48%; display:table-cell; vertical-align: top; min-width:398px;"));
            l_div1.append(l_tmp);
          }
        } else {
          l_tmp = $('<div>').addClass("phx-lanceetat-detail-content-groupe" + this.groupe[i] + "-row").attr('style', "width:100%; display:table-row; min-width:720px;");
          l_tmp.append($('<div>').addClass("phx-lanceetat-detail-content-groupe" + this.groupe[i] + "-column1").attr('style', "width:48%; display:table-cell; vertical-align: top; min-width:398px;"));
          l_tmp.append($('<div>').addClass("phx-lanceetat-detail-content-groupe" + this.groupe[i] + "-column2").attr('style', "width:48%; display:table-cell; vertical-align: top; min-width:398px;"));
          l_div1.append(l_tmp);
        }
        if (i < (this.groupe.length - 1)) {
          l_div1.css({ "border-bottom": "1px solid gainsboro" });
          $div.append(l_div1);
        } else {
          $div.append(l_div1);
        }
      }
    }
  },

  _chargeFieldData: function(model, secondary) {
    var value = model.get("value") !== undefined ? model.get("value") : model;

    if (value) {
      for (var i = 0; i < value.length; i++) {
        var element = value.at(i);

        //render simple field
        this._manageFields(element, secondary);
        if (element.get("souscriteres").length > 0) {
          this._manageSouscriteres(element);
        }
      }
    }
    return this;
  },

  _manageFields: function(model) {
    var self = this;
    var libelle = model.get("libelle");
    var obligatoire = model.get("obligatoire");
    var parent = model.get("parent");
    var tda = model.get("tda");
    var code = model.get("code");
    var type = model.get("type");
    var groupe = model.get("groupe");
    var defaut = model.get("defaut");
    var l_colonne = model.get("colonne");
    var l_alignement = model.get("alignement");
    var container = null;
    //form model
    var formValue = this.model.get("value");
    var valeurParentSelected = "";
    var populationComboModel = null;
    var ls_find = "",
      l_width = null;

    //Avoid population if no habilitation
    if (code == "lst_pers" && !HABILITATION.canView("RES_POPCOLLAB.V")) {
      return true;
    }
    if (this.combos[parent]) {
      valeurParentSelected = !STR.isBlank(parent) && this.combos[parent].getItem() ? this.combos[parent].getItemId() : "";
    } else {
      valeurParentSelected = !STR.isBlank(parent) ? $(this.el).find("." + parent).val() : "";
    }

    var name = "edition/definition/" + this.workflow.tableModel.get("value").get("code");
    var comboId = "critere/" + code;

    if (code == "lst_pers") {
      populationComboModel = PopulationComboModel;
    }

    var valeurColl = new CombosColl({
      name: name,
      comboId: comboId,
      model: populationComboModel
    });

    valeurColl.setHabContext(this.workflow.getHabContext().clone());

    if (!STR.isBlank(valeurParentSelected)) {
      var obj = {
        valeur: valeurParentSelected
      };
      valeurColl.params = obj;
    }
    formValue.set(code, defaut);

    //chercher container s'il y a "alignement" (il dépend du groupe avec valeur de alignement)
    if (!STR.isBlank(l_alignement) && l_alignement !== parent) {
      //je cherche le groupe auquel il appartient
      groupe = this._searchGroup(l_alignement);

      if (this._numberSubgroup(groupe) <= 1) {
        ls_find = ".phx-lanceetat-detail-content-groupe" + groupe;
      } else {
        var l_sub = this._orderCodeSubgroup(groupe, l_alignement);
        ls_find = ".phx-lanceetat-detail-content-groupe" + groupe + "-subgroupe" + l_sub;
      }
    } else {
      if (this._numberSubgroup(groupe) <= 1) {
        ls_find = ".phx-lanceetat-detail-content-groupe" + groupe;
      } else {
        var l_sub = this._orderCodeSubgroup(groupe, code);
        if (l_sub < 1) {
          //parent
          l_sub = this._orderCodeSubgroup(groupe, parent);
        }
        ls_find = ".phx-lanceetat-detail-content-groupe" + groupe + "-subgroupe" + l_sub;
      }
    }
    if (!STR.isBlank(l_alignement) && l_alignement == parent && l_colonne === 1) {
      ls_find += "-column2";
    } else {
      ls_find += "-column" + l_colonne;
    }
    container = $(this.el).find(ls_find);
    container.show();

    var field = null;

    //span container
    var div = $(this.el).find(".div_" + code).length > 0 ? $(this.el).find(".div_" + code) : $('<div>').addClass("div_" + code);
    div.empty();

    //label
    var label = $('<label>');
    label.attr("for", code);
    label.text(libelle);
    if (obligatoire === true && type !== "libelle" && type !== "checkbox" && type !== "radio" && type !== "info" && type !== "titre") {
      label.addClass("required");
    }

    //span for errors
    var errorSpan = $('<span>');
    errorSpan.addClass(code + "-error-container");

    switch (type) {
      case "radio":
        l_width = (container.width() * 0.48); //48% du container
        //div container
        div.css("display", "inline-table");

        //paint div and label
        container.append(div);
        label.addClass("phx-lanceetat-labelRadio");
        $(this.el).find(".div_" + code).before(label);
        $(this.el).find(".div_" + code).append(errorSpan);

        //load radio
        _.each(model.get("radio"), function(aux) {
          if (aux && !_.isEmpty(aux)) {
            var parentClass = self._buildParentClass(parent);
            //create and paint each option
            field = $('<input>');
            field.attr({ "type": "radio", "name": code, "value": aux.code, "parent": parentClass });
            field.addClass(code);

            var spanText = $('<span>');
            spanText.text(aux.libelle);
            spanText.addClass("phx-lanceetat-textRadio");
            if (l_width && l_width >= 0) {
              //spanText.css("width",l_radioWidth+"px");
              spanText.width(l_width);
            }
            $(self.el).find(".div_" + code).append(field);
            $(self.el).find(".div_" + code).append(spanText);
            $(self.el).find(".div_" + code).append("<br>");
          }
        });

        //default
        $(this.el).find("." + code + "[value='" + defaut + "']").prop('checked', true);
        model.set("value", defaut);
        this._resizeFields(code);

        break;
      case "checkbox":
        var parentClass = this._buildParentClass(parent);

        //input
        field = $('<input>');
        field.attr({ "type": "checkbox", "parent": parentClass });
        field.addClass(code);

        //paint fields
        container.append(div);
        label.addClass("phx-lanceetat-labelCheck");
        $(this.el).find(".div_" + code).append(label);
        $(this.el).find(".div_" + code).append(field);
        $(this.el).find(".div_" + code).append(errorSpan);
        $(this.el).find(".div_" + code).append("<br>");

        //default
        if (defaut === "checked") { //oui
          $(this.el).find("." + code).attr('checked', true);
          model.set("value", true);
        } else {
          model.set("value", false);
        }
        this._resizeFields(code);

        break;
      case "date":
        var parentClass = this._buildParentClass(parent);

        //input
        field = $('<input>');
        field.attr("parent", parentClass);
        field.addClass(code);

        //paint fields
        container.append(div);
        label.addClass("phx-lanceetat-labelDate");
        $(this.el).find(".div_" + code).append(label);
        field.addClass("phx-lanceetat-fieldDate");
        $(this.el).find(".div_" + code).append(field);
        $(this.el).find(".div_" + code).append(errorSpan);
        $(this.el).find(".div_" + code).append("<br>");

        //set datepicker
        FORMS.setDatepicker(this, "." + code);
        $(this.el).find("." + code).addClass(TYPE._getTypeClassByCode(tda));

        //default
        var date = TYPE.DATE.format(defaut, "DD/MM/YYYY");
        $(this.el).find("." + code).val(date);
        model.set("value", defaut);
        this._resizeFields(code);

        break;
      case "select":
        //add class parent
        var parentClass = this._buildParentClass(parent);

        // This would be the value of  the "selchoix param
        var typeSel = model.get("selchoix");

        if (typeSel !== "SELREF") {
          //create combo
          var combo = null;
          var codeSetItem = defaut;
          l_width = (container.width() * 0.43) + "px"; //43% du container

          if (!STR.isBlank(objs.populationMenu)) {
            var codeGlobal = objs.populationMenu.model.get("ident");
            var typeGlobal = objs.populationMenu.model.get("type");
            var valuePopulation = codeGlobal + "," + typeGlobal;
          }

          if (code === "critid" && this.workflow.openedFromPlanning) {
            valeurColl = new CritidComboColl({
              name: name,
              comboId: comboId,
            });
            valeurColl.setHabContext(this.workflow.getHabContext().clone());

            combo = this._createCombo(code, obligatoire, valeurColl, l_width);
            model.set("value", defaut);
          } else if (code === "lst_pers") {
            combo = this._createCombo(code, true, valeurColl, l_width);
            //This code , sets population property, coming from planning in another lancement distinct to lancement "planning individuel"
            //if(this.workflow.openedFromPlanning){
            //		codeSetItem = (this.workflow.preferencesPlanningPopulation.popid!=='0')?this.workflow.preferencesPlanningPopulation.popid+','+this.workflow.preferencesPlanningPopulation.poptype:defaut;
            //		model.set("value", codeSetItem);
            //}else{
            if (!STR.isBlank(codeGlobal)) {
              codeSetItem = valuePopulation;
              model.set("value", valuePopulation);
            } else {
              model.set("value", defaut);
            }

            //}
            this.comboPopulation = combo;
            //This code , sets population property, coming from planning in another lancement distinct to lancement "planning individuel"
            //if(this.workflow.openedFromPlanning){
            //	this.popId = this.workflow.preferencesPlanningPopulation.popid + '';
            //	this.popType = this.workflow.preferencesPlanningPopulation.poptype;
            //}
            //else{
            if (!STR.isBlank(codeGlobal)) {
              var valuePopulationArray = valuePopulation.split(",");
              this.popId = !STR.isBlank(valuePopulationArray[0]) ? valuePopulationArray[0] : null;
              this.popType = !STR.isBlank(valuePopulationArray[1]) ? valuePopulationArray[1] : null;
            }
            //}
          } else {
            combo = this._createCombo(code, obligatoire, valeurColl, l_width);
            model.set("value", defaut);
          }

          valeurColl.updateHabContext({
            fonc_cour: this.workflow.tableModel.get("value").get("fonction").code
          });

          // span for combo
          field = $('<span>');
          field.addClass('cmb_' + code);
          field.append(combo.render().el);

          if (code == "lst_pers") {
            // initialize with the population transverse, if not found set 0.
            combo.setItem({ code: codeSetItem }, function(response) {
              if (STR.isBlank(_.find(response, { id: model.get("value") }))) {
                model.set("value", "0");
              }
            });
          } else {
            if (!STR.isBlank(codeSetItem) || !obligatoire) { //seul quand codeSetitem is null et obligatorie est true (combobox avec blanc), on ne peut pas faire un setitem
              combo.setItem({ code: codeSetItem });
            }
          }

          container.append(div);
          label.addClass("phx-lanceetat-labelSelect");
          $(this.el).find(".div_" + code).append(label);
          $(this.el).find(".div_" + code).append(field);
          //No errorSpan comme les autres cas
          $(this.el).find(".div_" + code).append("<br>");
          $(this.el).find("." + code).attr("parent", parentClass);

          this._resizeFields(code);
        } else {
          var combo = null;
          var codeSetItem = defaut;
          l_width = (container.width() * 0.43);

          var urlService = model.get("selservice");
          var modifyHeader = this.workflow.openedFromPlanning ? true : false;

          if (!STR.isBlank(objs.populationMenu)) {
            var codeGlobal = objs.populationMenu.model.get("ident");
            var typeGlobal = objs.populationMenu.model.get("type");
            var valuePopulation = codeGlobal + "," + typeGlobal;
          }

          if (!STR.isBlank(this.model.get("value").get("date"))) {
            this.datedeb = this.model.get("value").get("date");
            this.datefin = this.model.get("value").get("date");
          } else if (!STR.isBlank(this.model.get("value").get("date_a")) || !STR.isBlank(this.model.get("value").get("date_de"))) {
            this.datedeb = this.model.get("value").get("date_de");
            this.datefin = this.model.get("value").get("date_a");
          }

          var comboModelCollOptions = {
            url: urlService,
            modifyHeader: modifyHeader,
            defaut: defaut,
            refForm: this
          }

          if (code == "nom_du" || code == "matr_du" || code == "nom_au" || code == "matr_au") {
            if (this.popId && this.popId !== '0') {
              _.extend(comboModelCollOptions, { headers: HEADERS.populationContext(this.popId, this.popType) });
              comboModelCollOptions.filtre = true;
            }
          }

          var valeurColl = new DynamicComboModelColl(comboModelCollOptions);

          valeurColl.datedeb = this.datedeb;
          valeurColl.datefin = this.datefin;
          valeurColl.setHabContext(this.workflow.getHabContext().clone());

          var first = null;
          if (code == "nom_du" || code == "matr_du") {
            var first = true;
          } else if (code == "nom_au" || code == "matr_au") {
            var first = false;
          }

          valeurColl.updateHabContext({
            fonc_cour: this.workflow.tableModel.get("value").get("fonction").code
          });

          combo = this._createReferentiel(code, obligatoire, valeurColl, l_width, model);

          this.referentielArray.push(combo);

          if (!STR.isBlank(defaut)) {
            var options, currentCode;
            if (this.popId && this.popId !== '0') {
              options = {
                model: { filtre: true }
              };
              options = _.extend(options, { headers: HEADERS.populationContext(this.popId, this.popType) });
            }
            this._initReferentielWithCode(defaut, urlService, combo, model, options);
          }

          // span for combo
          field = $('<span>');
          field.addClass('cmb_' + code);
          field.css("display", "inline-block");
          field.append(combo.render().el);

          container.append(div);
          label.addClass("phx-lanceetat-labelSelect");
          $(this.el).find(".div_" + code).append(label);
          $(this.el).find(".div_" + code).append(field);
          //No errorSpan comme les autres cas
          $(this.el).find(".div_" + code).append("<br>");
          $(this.el).find("." + code).attr("parent", parentClass);

          this._resizeFields(code);
        }

        break;
      case "text":
        var parentClass = this._buildParentClass(parent);

        l_width = (container.width() * 0.43); //43% du container
        l_width = (l_width > 2) ? l_width - 2 : 0;
        //input
        field = $('<input>');
        field.attr({ "type": "text", "value": "", "size": "15", "parent": parentClass });
        field.addClass(code).addClass(TYPE._getTypeClassByCode(tda));

        //paint fields
        container.append(div);
        label.addClass("phx-lanceetat-labelText");
        $(this.el).find(".div_" + code).append(label);
        field.width(l_width);
        $(this.el).find(".div_" + code).append(field);
        $(this.el).find(".div_" + code).append(errorSpan);
        $(this.el).find(".div_" + code).append("<br>");

        //default
        var mask = TYPE._getMaskByCode(tda);
        var valAux = TYPE.MASK.applyFormatMask(defaut, mask);

        $(this.el).find("." + code).val(valAux);
        model.set("value", defaut);
        this._resizeFields(code);

        break;
      case "textarea":
        var parentClass = this._buildParentClass(parent);

        l_width = (container.width() * 0.43); //43% du container
        l_width = (l_width > 2) ? l_width - 2 : 0;
        //input
        field = $('<textarea>');
        field.attr({ "parent": parentClass, "rows": 4, "cols": 50 });
        field.addClass(code);
        field.width(l_width);
        //paint fields
        container.append(div);
        label.addClass("phx-lanceetat-labelTextArea");
        $(this.el).find(".div_" + code).append(label);
        $(this.el).find(".div_" + code).append(field);
        $(this.el).find(".div_" + code).append(errorSpan);
        $(this.el).find(".div_" + code).append("<br>");

        //default
        $(this.el).find("." + code).val(defaut);
        model.set("value", defaut);
        this._resizeFields(code);

        break;
      case "libelle":
        var parentClass = this._buildParentClass(parent);

        //paint fields
        container.append(div);
        label.addClass("phx-lanceetat-labelLibelle");
        $(this.el).find(".div_" + code).append(label);

        //default
        model.set("value", defaut);
        if (STR.isBlank(defaut)) {
          label.hide();
        } else {
          label.show();
          this._resizeFields(code);
        }

        break;
      case "organisation": //chemin
        var parentClass = this._buildParentClass(parent);
        l_width = (container.width() * 0.43); //43% du container

        //input
        field = $('<span>');
        field.addClass("chemin_" + code);
        field.css("display", "inline-block");

        //paint fields
        if (container.find(".div_" + code).length == 0) {
          container.append(div);
        }

        if (STR.isBlank(this.$el.find("." + parent).val())) {
          $(this.el).find("." + code).hide();
        }

        label.addClass("phx-lanceetat-labelOrganisation");
        $(this.el).find(".div_" + code).append(label);
        $(this.el).find(".div_" + code).append(field);
        $(this.el).find(".div_" + code).append(errorSpan);
        $(this.el).find(".div_" + code).append("<br>");

        field.empty();
        model.set("value", null);
        var id = "";
        if (this.combos[parent].getItem()) {
          id = this.combos[parent].getItemId();
        }
        if (!STR.isBlank(id)) {
          var chemin = new SelecteurCheminView({
            name: code,
            listRenderStyle: 1, //format
            fieldWidth: l_width,
            height: 500,
            width: 850,
            structid: id, //code
            addItemCallback: function(data) {
              self._responseCallbackChemin(data, code);
            },
            intermediaire: true,
            partial: false,
            modeComplet: true,
            multiselection: false,
            habContext: valeurColl.getHabContext()
          });
          field.append(chemin.render().el);
          $(self.el).find("." + code).attr("parent", parentClass);
          $(self.el).find("." + code).show();
        }
        this._resizeFields(code);

        break;
      case "titre":
        var parentClass = this._buildParentClass(parent);

        //paint fields
        container.append(div);
        label.addClass("phx-lanceetat-typeTitre");
        label.addClass("ui-phx-ihm-texte-application-important");
        $(this.el).find(".div_" + code).append(label);
        $(this.el).find(".div_" + code).append("<br>");

        //default
        model.set("value", defaut);
        this._resizeFields(code);

        break;
      case "info":
        var parentClass = this._buildParentClass(parent);
        var parInfo = $("<p>");

        l_width = (container.width() * 0.49); //49% du container
        //paint fields
        container.append(div);
        label = null;
        parInfo.width(l_width);
        parInfo.text(libelle);
        parInfo.attr("data-info", code);
        parInfo.addClass("phx-lanceetat-typeInfo");
        $(this.el).find(".div_" + code).append(parInfo);

        //default
        model.set("value", defaut);
        if (STR.isBlank(defaut)) {
          parInfo.hide();
        } else {
          parInfo.show();
        }

        break;
      case "increment":
        var parentClas = this._buildParentClass(parent);

        //input
        field = $('<input>');
        field.attr({ "type": "number", "parent": parentClas, "min": "0", "maxlength": "2" }); //de 0 à 99
        field.addClass(code);

        //paint fields
        container.append(div);
        label.addClass("phx-lanceetat-labelIncrement");
        $(this.el).find(".div_" + code).append(label);
        field.addClass("phx-lanceetat-fieldIncrement");
        $(this.el).find(".div_" + code).append(field);
        $(this.el).find(".div_" + code).append(errorSpan);
        $(this.el).find(".div_" + code).append("<br>");

        //default
        if (!STR.isBlank(defaut)) {
          $(this.el).find("." + code).attr('value', defaut);
          model.set("value", defaut);
        } else {
          model.set("value", "0");
        }
        this._resizeFields(code);
    }
    model.set("visible", true);
  },

  _resizeFields: function(code) {
    var labelHeight = $(this.el).find(".div_" + code + " label").height();
    var divHeight = $(this.el).find(".div_" + code).height();

    if (labelHeight > divHeight) {
      $(this.el).find(".div_" + code).css("height", $(this.el).find(".div_" + code + " label").height());
    }
  },

  _buildParentClass: function(parent) {
    var parentClass = "";

    if (!STR.isBlank(parent) && this.$el.find("." + parent).length > 0) {
      parentClass += this.$el.find("." + parent).attr("parent");
      if (!STR.isBlank(parentClass)) {
        parentClass += " ";
      }
    }
    parentClass += parent;
    return parentClass;
  },

  //callback chemin
  _responseCallbackChemin: function(data, code) {
    var target = $(this.el).find("." + code);
    var parent = target.attr("parent");
    var model = this._getModelByCritere(code, parent);
    //Obtain chemin
    var parents = "";

    if (!STR.isBlank(data.parents)) {
      _.each(data.parents, function(parent) {
        parents += "/" + parent.code;
      });
      parents += "/" + data.code + "/";
    } else {
      if (!STR.isBlank(data.code)) {
        parents = "/" + data.code + "/";
      } else {
        parents = "";
      }
    }
    model.set("value", parents);
  },

  _createCombo: function(code, obligatoire, ws, a_width) {
    var combo = new ComboBoxView2({
      ws: ws,
      id: code + "_comboId",
      name: code,
      width: a_width,
      required: obligatoire,
      habContext: ws.getHabContext()
    });

    this.combos[code] = combo;
    return combo;
  },

  _createReferentiel: function(code, obligatoire, ws, a_width, model) {
    var self = this;
    var searchParams = {};

    var referentiel = new SelecteurReferentielView({
      coll: ws,
      name: code,
      view: SelecteurRefDialogView,
      title: model.get("libelle"),
      height: 500,
      width: 850,
      required: false,
      fieldWidth: a_width,
      readonly: false,
      addItemCallback: function(data) {
        self._responseCallback(data, model);
      },
      displayTitle: false,
      displayAllColumns: false,
      displayColumns: ["libelle"],
      searchParams: searchParams
    });

    return referentiel;
  },

  _responseCallback: function(data, model) {
    model.set("value", data.code);

    if (this.host && this.host._responseCallback) {
      this.host._responseCallback(data, this.idComponent);
    }
  },

  _initReferentielWithCode: function(code, url, combo, model, options) {
    var headerPopulation = (options && options.headers) ? options.headers : headerPopulation;
    var optionsFetch = {};
    var optionsModel = {
      url: url,
      defaut: code,
      nbrang: 2
    };
    (!_.isEmpty(options) && !_.isEmpty(options.model)) ? _.extend(optionsModel, options.model): '';
    var originalModel = new DynamicComboModel(optionsModel);
    originalModel.setHabContext(this.workflow.getHabContext().clone());

    var originalCollection = new Backbone.Collection();

    originalModel.code = code;
    optionsFetch = {
      success: function(fresh) {
        if (_.size(fresh.attributes) >= 1) {
          originalCollection.push(fresh.get("list")[0]);
          model.set("value", code);
        }
        combo.setValues(originalCollection);
      }
    };
    optionsFetch = (headerPopulation) ? _.extend(optionsFetch, { headers: headerPopulation }) : optionsFetch;
    originalModel.fetch(optionsFetch);
  },

  events: _.extend({
    "keyup .phx-populations-detail :input:not([readonly])": "_notifyEdition",
    "change .lst_pers": "_populationDispatcher",
    "change .phx-lanceetat-detail :input:not([readonly])": "_changeView"
  }, TYPE.SHORT.events(), TYPE.LONG.events(), TYPE.DATE.events(), TYPE.HOUR_MINUTE.events()),
  _populationDispatcher: function(event) {
    var self = this;
    this.workflow.once("end:ManageSouscriteurs", self._updateSouscriteurs, this);
    this._updateCombosCollection(event);
  },
  _getModelByCritere: function(id, parent) {
    var collection = this.coll;

    if (!STR.isBlank(parent)) {
      var parentArray = parent.split(" ");

      for (var i = 0; i < parentArray.length; i++) {
        collection = collection.get(parentArray[i]).get("souscriteres");
      }
    }
    return collection.get(id);
  },
  _updateSouscriteurs: function(event) {
    var model = this._getModelByCritere("sel_crit", "");
    if (model) {
      this._manageSouscriteres(model);
    }
  },
  _updateCombosCollection: function(event, data) {

    if (this.workflow && this.workflow.formView && this.workflow.formView.comboPopulation && this.workflow.formView.comboPopulation.currentCode) {
      data = this.workflow.formView.comboPopulation.currentCode.split(',');
    } else {
      data = event.currentTarget.value.split(",");
    }
    this.popId = !STR.isBlank(data[0]) ? data[0] : null;
    this.popType = !STR.isBlank(data[1]) ? data[1] : null;
  },

  validate: function(attrs, options) {
    var errors = {};

    //Period validation
    var datedeb = {
      "name": "date_de",
      "value": attrs.date_de,
      "label": undefined
    };
    var datefin = {
      "name": "date_a",
      "value": attrs.date_a,
      "label": undefined,
      "isInfinity": false
    };
    var errorMsg = TYPE.DATE.validatePeriod(datedeb, datefin, options);
    if (!STR.isBlank(errorMsg)) {
      errors.date_de = errorMsg.date_de;
      errors.date_a = errorMsg.date_a;
    }

    if (!_.isEmpty(errors)) {
      var errorMap = {
        errorValidation: STR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    } else {
      return null;
    }
  },

  _changeView: function(event) {
    var self = this;
    var targetClass = event.currentTarget.className.split(" ")[0];
    var target = $(this.el).find("." + targetClass);
    var parent = target.attr("parent");
    var model = this._getModelByCritere(targetClass, parent);
    var type = model.get("type");
    var l_newVal = null;

    switch (type) {
      case "radio":
        l_newVal = this.$el.find('.' + targetClass + ':checked').val();
        model.set("value", l_newVal);
        break;
      case "select":
        var typeSel = model.get("selchoix");

        if (typeSel !== "SELREF") {
          l_newVal = event.target["data-code"];
          model.set("value", l_newVal);
        }
        break;
      case "checkbox":
        l_newVal = this.$el.find('.' + targetClass).is(':checked');
        model.set("value", l_newVal); //returns true or false
        break;
      case "date":
        l_newVal = this.$el.find("." + targetClass).val();
        model.set("value", TYPE.DATE.parse(l_newVal).val);
        if (targetClass === "date") {
          for (var i = 0; i < this.referentielArray.length; i++) {
            this.referentielArray[i].coll.datedeb = TYPE.DATE.parse(l_newVal).val;
            this.referentielArray[i].coll.datefin = TYPE.DATE.parse(l_newVal).val;
          }
        } else if (targetClass === "date_de") {
          for (i = 0; i < this.referentielArray.length; i++) {
            this.referentielArray[i].coll.datedeb = TYPE.DATE.parse(l_newVal).val;
          }
        } else if (targetClass === "date_a") {
          for (i = 0; i < this.referentielArray.length; i++) {
            this.referentielArray[i].coll.datefin = TYPE.DATE.parse(l_newVal).val;
          }
        }
        break;
      case "text":
        l_newVal = this.$el.find("." + targetClass).val();
        if (!STR.isBlank(model.get("tda")) && TYPE._getTypeByCode(model.get("tda"))) {
          l_newVal = TYPE._getTypeByCode(model.get("tda")).parse(l_newVal).val;
        }
        model.set("value", l_newVal);
        break;
      case "textarea":
        l_newVal = this.$el.find("." + targetClass).val();
        model.set("value", l_newVal);
        break;
      case "heure": //needs parse
        l_newVal = this.$el.find("." + targetClass).val();
        if (!STR.isBlank(model.get("tda"))) {
          l_newVal = TYPE._getTypeByCode(model.get("tda")).parse(l_newVal).val;
        }
        model.set("value", l_newVal);
        break;
      case "increment":
        l_newVal = this.$el.find("." + targetClass).val();
        model.set("value", l_newVal);
        break;

      default:
        //none
        //We don't update model for organisation type, because it is already updated when additemcallback is executed.
    }

    //hide when the selected element has criteres and has no parent
    this._checkHideSouscriteres(model, parent);

    var l_funcCallback = function() {
      self._manageSouscriteres(model);
      self._change(event, model, false);
    };

    //S'il y a "dependance", on doit appeler à WS Reinit
    if (model.get("dependance") === true) {
      /*Pour les critères de type « checkbox », la valeur sera :
      	-	« »  (vide) : case non cochée
      	-	« checked » : case cochée
      	Pour les autres types, affecter la valeur.
      */
      if (type === "checkbox") {
        if (l_newVal === true) {
          l_newVal = "checked";
        } else {
          l_newVal = "";
        }
      }
      this.reinitModel(event, l_newVal, l_funcCallback);
    } else {
      l_funcCallback();
    }
  },

  _manageSouscriteres: function(model) {
    var element = model.get("souscriteres");
    if (element.length > 0) {
      for (var i = 0; i < element.length; i++) {
        element.at(i).set("visible", false);
        if (element.at(i).get("parent") == model.get("code") && (element.at(i).get("valeurparent") == model.get("value")) ||
          (element.at(i).get("parent") == model.get("code") && (STR.isBlank(element.at(i).get("valeurparent")) ||
            ((element.at(i).get("valeurparent") === "x" || element.at(i).get("valeurparent") === "checked") && model.get("value") === true))) ||
          (element.at(i).get("parent") == model.get("code") && model.get("type") === "radio" && element.at(i).get("valeurparent") == "checked")) {
          this._manageFields(element.at(i), true);
          if (element.at(i).get("souscriteres").length > 0) {
            this._manageSouscriteres(element.at(i));
          }
        }
      }
    }
    this.workflow.trigger("end:ManageSouscriteurs");
  },

  render: function() {
    var json = { 'i18n': i18n };
    this.$el.html(this.template(json));
    return this;
  },

  _isValidCollection: function(callback) {
    var collection = this.coll;
    this._cleanValidationErrors();
    var errors = {};
    var errorMap;
    var errorRange = [];

    this.isValidCollection = true;
    this._validateCollection(collection, errors, errorMap, errorRange);
    if (this.isValidCollection === true) {
      var lancerEtatModel = new LancerEtatModel();
      this._buildURL(collection, lancerEtatModel);
      if (callback) {
        callback(lancerEtatModel);
      }
    }
  },

  _validateCollection: function(collection, errors, errorMap, errorRange) {
    /*Redmine 008-Codage et TU #12369
      [ANO] Affichage du message fourni dans une popup avec en titre « Action impossible » et autres actions.
    L'IHM ne doit faire aucun contrôle, c'est le métier qui les fait. Il faut donc supprimer les contrôles sur :
    	>   - les dates constituant une période
    	>   - les bornes des matricules quand on choisit une population "par matricule"
    	>   - les bornes des noms quand on choisit une population "par nom"
    */

    for (var i = 0; i < collection.length; i++) {
      var element = collection.at(i);

      //visible and valid model
      if (element.get("visible") === true) {
        if (element.get("obligatoire") === true && STR.isBlank(element.get("value"))) {
          errors[element.get("code")] = {};
          errors[element.get("code")] = i18n.t('common:required', { "0": element.get("libelle") });
        } else if (element.get("code").indexOf("_") >= 0) {
          var l_cad = element.get("code").split("_");
          if (l_cad.length === 2) {
            var l_val = { value: null, libelle: "" };

            l_val.value = element.get("value");
            LOG.debug("l_val.value : " + l_val.value);

            l_val.libelle = !STR.isBlank(element.get("libelle")) ? element.get("libelle") : "";
            if (_.keys(errorRange).length === 0 || _.keys(errorRange[l_cad[0]]).length === 0) {
              var l_range = [];

              l_range[l_cad[1]] = l_val;
              errorRange[l_cad[0]] = l_range;
            } else {
              errorRange[l_cad[0]][l_cad[1]] = l_val;
            }
          }
        }
        if (!STR.isBlank(element.get("souscriteres")) && element.get("souscriteres").length > 0) {
          this._validateCollection(element.get("souscriteres"), errors, errorMap, errorRange);
        }
      }
    }
    if (_.keys(errorRange).length > 0) {
      _.each(_.keys(errorRange), function(item) {
        var l_val = _.keys(errorRange[item]);
        if (l_val.length === 2) {
          var l_err1 = errors[item + "_" + l_val[0]];
          var l_err2 = errors[item + "_" + l_val[1]];

          //il n'existe pas avant comme une erreur
          if (STR.isBlank(l_err1) && STR.isBlank(l_err2)) {
            var l_cont1 = errorRange[item][l_val[0]];
            var l_cont2 = errorRange[item][l_val[1]];
            // Probeme ID: 216682 - AGR 24/04/2019
            if (!isNaN(parseInt(l_cont1.value)) && !isNaN(parseInt(l_cont2.value))) {
              l_cont1.value = parseInt(l_cont1.value);
              l_cont2.value = parseInt(l_cont2.value);
              if (!STR.isBlank(l_cont1.value) && !STR.isBlank(l_cont2.value) && !(l_cont1.value <= l_cont2.value)) {
                errors[item + "_" + l_val[1]] = i18n.t('lanceetat.valeurSupEq', { "0": l_cont1.libelle });
              }
            } else {
              var compare = l_cont1.value.localeCompare(l_cont2.value);
              if (!STR.isBlank(l_cont1.value) && !STR.isBlank(l_cont2.value) && (compare > 0)) {
                errors[item + "_" + l_val[1]] = i18n.t('lanceetat.valeurSupEq', { "0": l_cont1.libelle });
              }
            }
            // Fin Probleme ID: 216682
          }
        }
      }, this);

    }
    if (!_.isEmpty(errors)) {
      var errorMap = {
        errorValidation: STR.formatMessage(errors),
        errors: errors
      };
      var l_model = null; //il n'est pas nécessaire pour cettes erreurs

      this.isValidCollection = false;
      this._showValidationErrors(l_model, errorMap);
    }
  },

  _buildURL: function(collection, model) {
    for (var i = 0; i < collection.length; i++) {
      var element = collection.at(i);
      if (element.get("visible") === true) {
        var code = element.get("code");
        var value = element.get("value");
        model.set(code, value);
      }

      if (element.get("souscriteres").length > 0) {
        this._buildURL(element.get("souscriteres"), model);
      }
    }
  },

  reinitModel: function(a_event, a_newval, a_callback, openedFromPlanning) {
    if ((a_event || openedFromPlanning) && !STR.isBlank(a_newval) && this.workflow && this.workflow.tableModel &&
      this.workflow.tableModel.get("value") && this.workflow.tableModel.get("value").id) {
      var self = this;
      var l_id = this.workflow.tableModel.get("value").get("code");
      var targetClass;
      if (openedFromPlanning) {
        targetClass = "critid";
      } else {
        targetClass = a_event.currentTarget.className.split(" ")[0];
      }
      var target = $(this.el).find("." + targetClass);
      var parent = target.attr("parent");
      var model = this._getModelByCritere(targetClass, parent);
      var l_reinit = new CriteresReinitModel();
      var l_hab = new HabilitationContext({
        onglet: "lanceetat",
        fonc_cour: "RES_RECAP.E",
        nat_gest: ""
      });

      l_reinit.setHabContext(l_hab);
      l_reinit.newValeur = a_newval;
      l_reinit.id = l_id;
      l_reinit.code = model.get("code");
      l_reinit.fetch({
        success: function(fresh) {
          if (!_.isEmpty(fresh)) {
            //je parcours tous les attributs
            var l_tokens = _.keys(fresh.attributes);
            var l_len = l_tokens.length;

            for (var i = 0; i < l_len; i++) {
              self._changeValeurType(a_event, l_tokens[i], fresh.get(l_tokens[i]));
              var lf_target = $(self.el).find("." + l_tokens[i]);
              if (lf_target.length > 0) {
                var lf_parent = lf_target.attr("parent");
                var lf_model = self._getModelByCritere(l_tokens[i], lf_parent);
                if (lf_model) {
                  self._manageSouscriteres(lf_model);
                }
              }
            }
          }
          if (a_callback) {
            a_callback();
          }
        },
        error: function() {
          if (a_callback) {
            a_callback();
          }
        }
      });
    } else {
      if (a_callback) {
        a_callback();
      }
    }
  },

  _changeValeurType: function(a_event, a_targetClass, a_newVal) {
    var target = $(this.el).find("." + a_targetClass);
    var parent = target.attr("parent");
    var model = this._getModelByCritere(a_targetClass, parent);
    if (model) {
      var type = model.get("type");
      var l_val = null,
        l_label = null;

      switch (type) {
        case "radio":
          l_val = this.$el.find('.' + a_targetClass + ':checked').val();
          if (l_val !== a_newVal) {
            this.$el.find('.' + a_targetClass + "[value='" + a_newVal + "']").prop("checked", true)
            model.set("value", a_newVal);
          }
          this._resizeFields(a_targetClass);
          break;
        case "select":
          l_val = event.target["data-code"];
          if (l_val !== a_newVal) {
            event.target["data-code"] = a_newVal;
            model.set("value", a_newVal);
          }
          this._resizeFields(a_targetClass);
          break;
        case "checkbox":
          l_val = this.$el.find('.' + a_targetClass).is(':checked');
          if (l_val !== a_newVal) {
            if (a_newVal === true || a_newVal === "checked" || a_newVal === "x") {
              this.$el.find('.' + a_targetClass).prop("checked", true);
              model.set("value", true);
            } else {
              this.$el.find('.' + a_targetClass).prop("checked", false);
              model.set("value", false);
            }
          }
          this._resizeFields(a_targetClass);
          break;
        case "date":
          l_val = this.$el.find("." + a_targetClass).val();
          if (l_val !== a_newVal) {
            this.$el.find("." + a_targetClass).val(a_newVal)
            model.set("value", TYPE.DATE.parse(a_newVal).val);
          }
          this._resizeFields(a_targetClass);
          break;
        case "text":
          l_val = this.$el.find("." + a_targetClass).val();
          if (l_val !== a_newVal) {
            this.$el.find("." + a_targetClass).val(a_newVal);
            if (!STR.isBlank(model.get("tda"))) {
              model.set("value", TYPE._getTypeByCode(model.get("tda")).parse(a_newVal).val);
            } else {
              model.set("value", a_newVal);
            }
          }
          this._resizeFields(a_targetClass);
          break;
        case "textarea":
          l_val = this.$el.find("." + a_targetClass).val();
          if (l_val !== a_newVal) {
            this.$el.find("." + a_targetClass).val(a_newVal);
            model.set("value", a_newVal);
          }
          this._resizeFields(a_targetClass);
          break;
        case "increment":
          l_val = this.$el.find("." + a_targetClass).val();
          if (l_val !== a_newVal) {
            this.$el.find("." + a_targetClass).val(a_newVal);
            model.set("value", a_newVal);
          }
          this._resizeFields(a_targetClass);
          break;
        case "libelle":
          l_label = $(this.el).find(".div_" + a_targetClass + " label[for='" + a_targetClass + "']");
          l_val = l_label.html()
          if (l_val !== a_newVal) {
            l_label.html(a_newVal);
            model.set("value", a_newVal);
          }
          if (STR.isBlank(a_newVal)) {
            l_label.hide();
          } else {
            l_label.show();
            this._resizeFields(a_targetClass);
          }
          break;
        case "titre":
          l_label = $(this.el).find(".div_" + a_targetClass + " label[for='" + a_targetClass + "']");
          l_val = l_label.html()
          if (l_val !== a_newVal) {
            l_label.html(a_newVal);
            model.set("value", a_newVal);
          }
          this._resizeFields(a_targetClass);
          break;
        case "info":
          var parInfo = $(this.el).find(".div_" + a_targetClass + " p[data-info='" + a_targetClass + "']");
          l_val = parInfo.html()
          if (l_val !== a_newVal) {
            parInfo.html(a_newVal);
            model.set("value", a_newVal);
          }
          if (STR.isBlank(a_newVal)) {
            parInfo.hide();
          } else {
            parInfo.show();
          }
          break;
          //We don't update model for organisation type, because it is already updated when additemcallback is executed.
        default:
          //none
      }
      //hide when the selected element has criteres and has no parent
      this._checkHideSouscriteres(model, parent);
    }
  },

  _prepareSubGroupes: function(model) {
    var element = model.get("souscriteres");

    if (element.length > 0) {
      for (var i = 0; i < element.length; i++) {
        if (model.get("groupe") !== element.at(i).get("groupe")) {
          element.at(i).set("groupe", model.get("groupe"));
        }
        if (element.at(i).get("souscriteres").length > 0) {
          this._prepareSubGroupes(element.at(i));
        }
      }
    }
  },

  _prepareGroupes: function(a_model) {
    var value = a_model.get("value") !== undefined ? a_model.get("value") : a_model;

    if (value) {
      _.each(value.models, function(model) {
        if (model) {
          this._prepareSubGroupes(model);
        }
      }, this);
    }
  },

  _checkHideSouscriteres: function(model, parent) {
    //hide when the selected element has criteres and has no parent
    if (model && STR.isBlank(parent) && model.get("souscriteres") && model.get("souscriteres").length > 0) {
      var ls_find = "";
      var l_div = null;

      if (this._numberSubgroup(model.get("groupe")) <= 1) {
        ls_find = ".phx-lanceetat-detail-content-groupe" + model.get("groupe") + "-column2"; //+l_colonne;
      } else {
        var l_sub = this._orderCodeSubgroup(model.get("groupe"), model.get("code"));

        if (l_sub < 1) {
          //parent
          l_sub = this._orderCodeSubgroup(model.get("groupe"), model.get("parent"));
        }
        ls_find = ".phx-lanceetat-detail-content-groupe" + model.get("groupe") + "-subgroupe" + l_sub + "-column2"; //+l_colonne;
      }
      l_div = $(this.el).find(ls_find);
      if (l_div.length > 0) {

        l_div.empty();

        //  quelquefois le titre 'Voir les totalisations individuelles :' ne s'effacait pas
        if ($(this.el).find(".div_voirtot2").is(':empty') == false) {
          l_div.parent().parent().find(".div_voirtot2").empty();
        }
      }
    }
  }
});
