import { AutocompleteColl } from '../combo/autocomplete.collection.js';
import { DialogView } from '../../views/dialog.view.js';
import { FORMS } from '../../../utils/forms.js';
import { i18n } from '../../../i18n.js';
import { LOG } from '../../../utils/log.js';
import { ReadOnlyModel } from '../../models/readOnly.model.js';
import { SelecteurReferentielResultItemView } from './selecteurReferentielResultItem.view.js';
import { STR } from '../../../utils/str.js';
import TPL_common_selecteur_referentiel from './selecteur_referentiel.tpl.html';

export var SelecteurReferentielView = Backbone.View.extend({

  dummy: "",

  /**
   * Name of the html tag which involves the view
   */
  tagName: "div",

  /**
   * Name of the container class of the view
   */
  className: "phx-selecteur-referentiel",

  /**
   * Alias for the messages to show in this view
   */
  //REVIEW: 	i18n : phx.i18n.common,

  /**
   * Minimum length of the string to activate the search
   */
  minSearchLength: Configuration.selecteurReferentiel.minCharactersToSearch,

  /**
   * Indicator for the multiselection
   */
  multiselection: false,

  /**
   * Maximum number of results to display in every search
   */
  maxResultItems: Configuration.selecteurReferentiel.maxResultItems,

  /**
   * Indicator to show or not the complet mode
   */
  modeComplet: false,

  /**
   * Indicator for the readonly issue
   */
  readonly: false,

  /**
   * Indicator for display title
   */
  displayTitle: true,

  /**
   * Indicator for columns to display
   */
  displayAllColumns: true,

  /**
   * Columns to display in case that display columns is false,
   */
  displayColumns: null,
  /*
   *Add extra button to dialog
   */
  extraButton: {},

  showPlusIcon: false,

  /**
   * Event launched when the complete mode button is clicked
   *
   */
  /**
   * Event launched when the autocompletion list is showed
   *
   */
  /**
   * Event launched when an element of the autocompletion list is selected
   *
   */
  /**
   * Event launched when the input field of the component is focused
   *
   */
  /**
   * Event launched when the value of the input field of the component changes
   *
   */

  events: {
    "click .phx-referentiel-button": "_openDetailPopup",
    "autocompleteselect": "_selectItem",
    "focus .phx-referentiel-input": "_focused",
    "change .phx-referentiel-input": "_changeItem",
    "autocompleteopen": "_openlist"
  },

  /**
   * Constructor
   * Model underlying a referentiel selector component view
   */
  initialize: function(a_params) {
    var params = a_params || {};

    /**
     * Model of the view
     */
    this.model = new ReadOnlyModel({ defaults: { value: "" } });

    /**
     * HTML template of the view
     */
    this.template = TPL_common_selecteur_referentiel;

    /**
     * Title of the view
     */
    this.title = "";

    /**
     * Height of the component
     */
    this.height = 500;

    /**
     * Width of the component
     */
    this.width = 800;

    /**
     * Width of the input field of the component
     */
    this.fieldWidth = "100%";

    /**
     * Required indicator of the component
     */
    this.required = false;

    /**
     * Additional filter params
     * */
    this.searchParams = {};

    /**
     * Collection of the list
     */
    this.coll = undefined;
    if (!STR.isBlank(params.coll)) {
      this.coll = params.coll;
      this.coll.pagination.size = this.maxResultItems;
    }

    /**
     * Name of the component
     */
    this.name = undefined;
    if (!STR.isBlank(params.name)) {
      this.name = params.name;
    }

    if (params.minLength > 1) {
      this.minSearchLength = params.minSearchLength;
    }

    this.multiselection = (params.multiselection === true);

    if (params && !STR.isBlank(params.readonly)) {
      this.readonly = params.readonly;
    }

    if (!STR.isBlank(params.view) && this.readonly === false) {
      /**
       * View of the complet mode
       */
      this.view = params.view;

      /**
       * View data of the complet mode
       */
      this.viewData = params.viewData;

      /**
       * Mode complet indicator
       */
      this.modeComplet = true;
    }

    /**
     * Displays columns title and content options
     */

    if (params && !STR.isBlank(params.displayTitle)) {
      this.displayTitle = params.displayTitle;
    }

    if (params && !STR.isBlank(params.displayAllColumns)) {
      this.displayAllColumns = params.displayAllColumns;
    }

    if (params && !STR.isBlank(params.displayColumns)) {
      this.displayColumns = params.displayColumns;
    }

    /**
     * Render function for every component of the list
     */
    this.itemRenderer = null;
    if (!STR.isBlank(params.itemRenderer)) {
      this.itemRenderer = params.itemRenderer;
    }

    if (!STR.isBlank(params.title)) {
      this.title = params.title;
    }

    if (!STR.isBlank(params.height)) {
      this.height = params.height;
    }

    if (!STR.isBlank(params.width)) {
      this.width = params.width;
    }

    /**
     * Width of the component's list
     */
    if (!STR.isBlank(params.listWidth)) {
      var l_regxplist = /^([0-9\.]+)[%]?$/g;
      if (l_regxplist.test(params.listWidth) || params.listWidth === "auto") {
        this.listWidth = params.listWidth;
      } else {
        throw new Error("the listWidth must be numeric (" + this.name + ")");
      }
    }

    if (!STR.isBlank(params.fieldWidth)) {
      var l_regxp = /^([0-9\.]+)[%]?$/g;
      if (l_regxp.test(params.fieldWidth) || params.fieldWidth === "auto") {
        this.fieldWidth = params.fieldWidth;
      } else {
        throw new Error("the fieldWidth must be numeric (" + this.name + ")");
      }
    }

    /**
     * height of the component's list
     */
    if (!STR.isBlank(params.listHeight)) {
      var l_regxplistHeight = /^([0-9\.]+)[%]?$/g;
      if (l_regxplistHeight.test(params.listHeight) || params.listHeight === "auto") {
        this.listHeight = params.listHeight;
      } else {
        throw new Error("the listHeight must be numeric (" + this.name + ")");
      }
    }

    /**
     * Function to generate itemtooltip
     */
    if (params && !STR.isBlank(params.itemTitle)) {
      this.itemTitle = params.itemTitle;
    } else {
      this.itemTitle = null;
    }

    if (!STR.isBlank(params.required)) {
      this.required = params.required;
    }
    if (!STR.isBlank(params.searchParams)) {
      this.searchParams = params.searchParams;
    }

    // called with the selected items
    /**
     * Callback function executed when we add an item to the selection
     * This function will be executed for EACH element on the list, so
     * is not executed once when list has 4 elements and we added one more..
     */
    this.addItemCallback = params.addItemCallback;

    /**
     * Callback function executed when we added one item to the list, so
     * we clear the list to fill from zero.
     *
     * Used to notify when the list must be cleared
     */
    this.clearItemsCallback = params.clearItemsCallback;

    /**
     * Callback function executed when we remove an item from the selection
     */
    this.removeItemCallback = params.removeItemCallback;

    // response list filled with user selection
    /**
     * Selection of elements of the component
     */

    /**
     * Type selecteur is tree or not
     */
    this.typeTree = false
    if (!STR.isBlank(params.typeTree)) {
      this.typeTree = params.typeTree;
    }

    this.selection = new AutocompleteColl();
    this.selection.itemRenderer = this.itemRenderer;
    this.selection.on("click:item", this._removeItem, this);
    this.selection.on("reset", this._resetItems, this);

    if (params && !_.isUndefined(params.extraButton)) {
      this.extraButton = params.extraButton;
    }

    if (params && !_.isUndefined(params.hideRecherche)) {
      this.hideRecherche = params.hideRecherche;
    }

    if (params && !_.isUndefined(params.hideAppliquerSelection)) {
      this.hideAppliquerSelection = params.hideAppliquerSelection;
    }

    if (params && !_.isUndefined(params.hideAnnulerSelection)) {
      this.hideAnnulerSelection = params.hideAnnulerSelection;
    }

    if (params && !_.isUndefined(params.showIconPlus)) {
      this.showIconPlus = params.showIconPlus;
    }

    if (params && !_.isUndefined(params.customPositionItems)) {
      this.customPositionItems = params.customPositionItems;
    }

    if (params && !_.isUndefined(params.overrideButtonActions) && !_.isNull()) {
      this.overrideButtonActions = params.overrideButtonActions;
    }
    if (params && params.parent) {
      this.parent = params.parent;
    }
  },

  /**
   * Returns the selected values of the component
   */
  _valuesFilter: function(values) {
    // Override this method to treat the values before showing them
    return values;
  },

  /**
   * Returns the selected values of the component
   */
  _valuesHeader: function(values) {
    // Override this method to add non selectable values at the header before showing them
    return values;
  },

  /**
   * Paints the view of the selecteur de referentiel component
   */
  render: function() {
    var self = this,
      options = {
        "showIconPlus": this.showIconPlus
      }
    var horpe = null;
    var horpo = null;

    $(this.el).html(this.template(options));

    // instantiate/configure component

    $(this.el).find(".phx-referentiel-input").removeClass("phx-referentiel-input").addClass(this.name + " phx-referentiel-input");
    $(this.el).find(".referentiel-error-container").css("display", "table");
    $(this.el).find(".referentiel-error-container").removeClass("referentiel-error-container").addClass(this.name + "-error-container");

    if (!this.readonly) {
      this.appended = false;
      this.input = $(this.el).find(".phx-referentiel-input").autocomplete({
        minLength: this.minSearchLength,
        source: function(request, response) {
          var term = request.term;
          if (self.coll) {
            /*            if (!STR.isBlank(self.coll.context)) {
                          if (!STR.isBlank(self.coll.context.ctxUtilCollab)) {
                            horpe = self.coll.context.ctxUtilCollab || false;
                          }
                          if (!STR.isBlank(self.coll.context.ctxUtilAct)) {
                            horpo = self.coll.context.ctxUtilAct || false;
                          }
                        }*/
            var paramsFil = {
              "search": term
            };
            /*            if (horpe) {
                          paramsFil.horpe = true;
                        }
                        if (horpo) {
                          paramsFil.horpo = true;
                        }*/
            self.coll.applyFilter(_.extend({}, self.searchParams, paramsFil));
            self.coll.pagination.size = self.maxResultItems;
            self.coll.fetch({
              success: function() {
                var collection = new AutocompleteColl();
                collection.itemRenderer = self.itemRenderer;
                self.coll = self._valuesHeader(self.coll);

                // add message to list if more results exists
                if (self.coll.totalRecords > self.maxResultItems) {
                  var message = "";
                  if (self.modeComplet === true) {
                    message = i18n.t('messages:GL_1007');
                  } else {
                    message = i18n.t('messages:GL_1006');
                  }
                  var info = new ReadOnlyModel({ code: null, libelle: message });
                  collection.add([info.toJSON()], { parse: true });
                } else {
                  collection.add(self.coll.toJSON(), { parse: true });
                }
                var values = self._valuesFilter(collection.toJSON());

                response(values);
              },
              error: function() {
                $(self.input).val('');
                LOG.error("Error loading list");
              }
            });
          }
        },
        position: {
          my: "left top",
          at: "left bottom",
          of: $(this.el).find(".phx-referentiel-input"),
          collision: "flipfit"
        },
        create: function() {
          var menu = $(this).data("ui-autocomplete").menu;
          if ($('html').is('.ie6, .ie7, .ie8, .ie9')) {
            $(menu.activeMenu).css("position", "relative");
          } else {
            $(menu.activeMenu).css("position", "fixed");
          }
          $(this).data('ui-autocomplete')._resizeMenu = function() {
            if (self.coll.totalRecords <= self.maxResultItems) {
              //Show element list with the width of the widthst element
              var lis = this.menu.element.find("li");
              var padding = parseInt(this.menu.element.css("padding-left").replace("px", "")) + parseInt(this.menu.element.css("padding-right").replace("px", ""));
              var size = lis.length;
              var minWidth = 20;
              for (var i = 0; i < size; i++) {
                var currentWith = FORMS.getWidthFromMirror(lis.eq(i).text(), lis.eq(i));
                currentWith += padding;
                if (currentWith > minWidth) {
                  minWidth = currentWith;
                }
              }
              minWidth += 12;
              if (size > 20) {
                minWidth += 12; // adjusted to show the scroll
              }
              this.menu.element.innerWidth(minWidth);
            } else { //When there are more items than maximum allowed, show sentence with the same width than input width
              var parentWidth = $(self.el).css("width");
              $(".phx-referentiel-input", self.el).autocomplete("widget").css("width", parentWidth);
            }
            if (self.appended === false) {
              //AppendTo option in order to let jquery recalculate z-index for each element
              $(self.input).autocomplete("option", "appendTo", null);
              self.appended = true;
            }

          };
        },
        open: function() {
          var menu = $(this).data("ui-autocomplete").menu;
          menu.activeMenu.addClass("phx-selecteur-referentiel-autocomplete-menu");
          self._registerOneWheelEvent();
        }
      });
      // color search term in result list
      $(this.input).data("ui-autocomplete")._renderItem = function(ul, item) {

        var keywords = $.trim(this.term).split(' ').join('|');
        var output = item.label;
        try {
          output = item.label.replace(new RegExp("(" + keywords + ")", "gi"),
            '<span class="phx-searched-char-sequence">$1</span>');
        } catch (err) {
          LOG.debug("Selecteur referentiel (erreur): " + err);
        }

        $(ul).css("z-index", 110);

        if (STR.isBlank(item.attrs.id)) {
          if (item.attrs.useAsFilter) {
            return $("<li>").addClass("ui-state-disabled").html("<b>" + item.label + "</b>").appendTo(ul);
          } else {
            return $("<li>").addClass("ui-state-disabled").html('<span class="phx-label-style phx-referentiel-moreitems-info">' + item.label + '</span>').appendTo(ul);
          }
        }
        return $("<li>").append($("<a>").html(output)).appendTo(ul);
      };
    }

    //apply width to the component wrapper
    this.$el.find(".phx-referentiel-wrap").css("width", this.fieldWidth);

    //If this referentiel is required reduce 12 px to prevent bad placement in tables
    if (!STR.isBlank(this.required) && this.required === true) {
      var selWidth = this.$el.find(".phx-referentiel-wrap").css("width");
      this.$el.find(".phx-referentiel-wrap").css("width", "calc(" + selWidth + " - 1em)");
      this.$el.find(".phx-referentiel-input").attr("aria-required", "true");

    }
    //apply width class to the input and remove button if not complete mode
    if (this.modeComplet) {
      if ((this.fieldWidth + "").indexOf("%") === -1) {
        if (this.showIconPlus == true) {
          this.$el.find(".phx-referentiel-input").css("width", this.fieldWidth - 38 + "px");
        } else {
          this.$el.find(".phx-referentiel-input").css("width", this.fieldWidth - 22 + "px");
        }
      } else {
        if (this.showIconPlus == true) {
          this.$el.find(".phx-referentiel-input").css("width", "calc(" + this.fieldWidth + " - 38px)");
        } else {
          this.$el.find(".phx-referentiel-input").css("width", "calc(" + this.fieldWidth + " - 22px)");
        }
      }
    } else {
      $(this.el).find(".phx-referentiel-button").remove();
      this.$el.find(".phx-referentiel-input").css("width", "calc(100% - 4px)");
    }

    this.$el.find(".phx-referentiel-selection").css("display", "inherit");

    //create view reference
    this.$el.find(".phx-referentiel-input").prop("viewRef", this);

    return this;
  },

  /**
   * Sets and paints the selected values
   */
  setValues: function(coll) {
    this.selection.reset(null, { silent: true });
    if (coll && coll instanceof Backbone.Collection) {
      this.selection.add(coll.toJSON(), { parse: true });
    }

    // paint selected values
    this._paintItems();
  },

  /**
   * Gets the selected values
   */
  getValues: function() {
    return this.selection;
  },

  /**
   * Sets the readonly indicator
   */
  setReadOnly: function(readOnly) {
    var fieldset = this.$el.find(".phx-referentiel-wrap");

    this.readonly = readOnly;
    fieldset = this.$el.find(".phx-referentiel-wrap");
    FORMS.setInputFieldReadonly(fieldset, this.name, this.readonly);

    if (this.modeComplet === true) {
      if (readOnly === true) {
        this.$el.find(".phx-referentiel-button").hide();
      } else {
        this.$el.find(".phx-referentiel-button").show();
      }
    }
  },

  /**
   * Paints the selected elements of the component
   */
  _paintItems: function() {
    var self = this;
    $(this.el).find(".phx-referentiel-selection").empty();

    if (this.multiselection) {
      _.each(this.selection.models, function(value) {
        var itemView = new SelecteurReferentielResultItemView({ "model": value, "itemTitle": self.itemTitle, "readonly": self.readonly });
        $(self.el).find(".phx-referentiel-selection").append(itemView.render().el);
      });

      if (this.selection.models.length === 0) {
        this.$el.find(".phx-referentiel-selection").css("display", "inherit");
      } else {
        this.$el.find(".phx-referentiel-selection").css("display", "");
      }

      this.$el.find(".phx-referentiel-selection").position({
        my: "left top",
        at: (_.isUndefined(this.customPositionItems)) ? "left bottom" : this.customPositionItems,
        of: self.$el.find(".phx-referentiel-wrap")
      });
    } else {
      // render item in input field (no multiselection)
      if (this.selection.models.length > 0) {
        if (this.selection.at(0).get("attrs").multiDomain) {
          this.selection.at(0).set("label", this.selection.at(0).get("attrs").libelle);
        }
        this.$el.find(".phx-referentiel-input").val(this.selection.at(0).get("label"));
        this.$el.find(".phx-referentiel-selection").css("display", "inherit");
      } else {
        this.$el.find(".phx-referentiel-input").val("");
      }

    }
  },

  /**
   * Add a new item to the selection of the component
   */
  _selectItem: function(event, ui) {
    this._addItem(ui.item.attrs);
    return false;
  },

  /**
   * Changes a item in the current selection
   */
  _changeItem: function(event) {
    var value = $(event.target).val();
    var model = null;
    if (this.multiselection === false && STR.isBlank(value) && this.addItemCallback) {
      model = new this.coll.model;
      this.addItemCallback(model.attributes);
    } else {
      if (this.multiselection === false) {
        if (!this.selection.at(0) || value !== this.selection.at(0).get("label")) { //User typed an invalid value
          model = new this.coll.model;
          if (this.addItemCallback) {
            this.addItemCallback(model.attributes);
          }
          $(this.el).find(".phx-referentiel-selection").empty();
          this.selection.reset();
        }
      }
    }
    var $input = $(this.el).find(".phx-referentiel-input");
    if ($input.data("ui-tooltip") && this.selection.length === 0) {
      $input.tooltip("option", "content", "");
    }
  },

  /**
   * Adds an element to the selection and executes its related callback if exists
   */
  _addItem: function(attrs) {
    if (this.multiselection === true) {
      // clear input field and set focus to it.
      $(this.el).find(".phx-referentiel-input").val("");
      $(this.el).find(".phx-referentiel-input").focus();
    } else {
      $(this.el).find(".phx-referentiel-selection").empty();
      this.selection.reset();
    }

    // add item to selection list
    if (STR.isBlank(this.selection.get(attrs.id))) {
      this.selection.add([attrs], { parse: true });
      if (this.addItemCallback) {
        this.addItemCallback(attrs);
      }

      // paint items
      this._paintItems();
      // Notify edition
      $(this.el).find(".phx-referentiel-input").trigger("change");
    }

    // return false to prevent bubbling of event
    return false;
  },

  /**
   * Adds some elements to the selection and paints the selection
   */
  _addItems: function(list) {
    if (this.multiselection === true) {
      // clear input field and set focus to it.
      $(this.el).find(".phx-referentiel-input").val("");
      $(this.el).find(".phx-referentiel-input").focus();
    } else {
      $(this.el).find(".phx-referentiel-selection").empty();
      this.selection.reset(null, { silent: true });

    }

    if (this.clearItemsCallback) {
      this.clearItemsCallback();
    }

    _.each(list, function(item) {
      // add item to selection list
      var attrs = item.attributes;
      if (STR.isBlank(this.selection.get(attrs.id))) {
        this.selection.add([attrs], { parse: true });
        if (this.addItemCallback) {
          this.addItemCallback(attrs);
        }

      }

    }, this);

    // paint items
    this._paintItems();

    // Notify edition
    $(this.el).find(".phx-referentiel-input").trigger("change");

    // return false to prevent bubbling of event
    return false;
  },

  /**
   * Close autocomplete dropdown when scrolling outside.
   */
  _registerOneWheelEvent: function() {
    var self = this;

    $(document).one("wheel." + this.cid, function(event) {
      self.shouldCloseMenu = false;

      // wheel on the list
      try {
        if ($(event.target).attr("id") === $(self.input).autocomplete("widget").attr("id") || $(self.input).autocomplete("widget").find(event.target).length > 0) {
          self._registerOneWheelEvent();
          return;
        } else if ($(self.input).autocomplete("widget").find(event.target).length === 0) {
          if ($(self.input).autocomplete("widget").is(":visible")) {
            $(self.input).autocomplete("close");
            if (STR.isBlank(self.currentCode)) {
              self._paintItems();
            }
          }
          self.shouldCloseMenu = false;
        }
      } catch (e) {
        LOG.error("Autocomplete__registerOneWheelEvent: " + e);
      }
    });
  },

  /**
   * Repaints the items of the component
   */
  _resetItems: function() {
    // paint items
    this._paintItems();
    // Notify edition
    $(this.el).find(".phx-referentiel-input").trigger("change");
  },

  /**
   * Deletes an element from the selection
   */
  _removeItem: function(model) {
    // remove item from collection
    this.selection.remove(model);

    if (this.removeItemCallback) {
      this.removeItemCallback(model.attributes.attrs);
    }

    $(this.el).find(".phx-referentiel-input").trigger("focus");
    // repaint
    this._paintItems();
    // Notify edition
    $(this.el).find(".phx-referentiel-input").trigger("change");
  },

  /**
   * Resets the selection
   */
  clean: function() {
    this.$el.find(".phx-referentiel-input").val("");
    this.selection.reset();
    var $input = this.$el.find(".phx-referentiel-input");
    if ($input.data('ui-tooltip')) {
      $input.tooltip('destroy');
    }
  },

  /**
   * Opens the detail opoup view
   */
  _openDetailPopup: function() {
    var self = this;
    this.coll.pagination.size = Configuration.pageSize;
    if (!STR.isBlank(this.popup)) {
      this.popup.remove();
      this.popup = null;
    }
    /**
     * View of the detail popup of the component
     */
    this.popup = new DialogView({
      view: this.view,
      viewData: {
        coll: this.coll,
        name: this.name,
        currentSelection: this.selection,
        context: this.context,
        excludedItems: this.arrExcludeIds,
        data: this.viewData,
        multiselection: this.multiselection,
        displayTitle: this.displayTitle,
        displayAllColumns: this.displayAllColumns,
        displayColumns: this.displayColumns,
        searchParams: this.searchParams
      }
    });
    /*
    if (STR.isBlank(this.arrExcludeIds)) {
      if (this.coll.workflow.attributes.utilCode) {
        var utilCode = parseInt(this.coll.workflow.get("utilCode"));
        this.popup.viewData.excludedMySelf = [utilCode];
      }
    }
    */
    this.popup.setHeight(this.height);
    this.popup.setWidth(this.width);
    this.popup._eventsBB = function(event, value) {
      if (event === "response") {
        self.popupResponse = value;
      }

      if (event === "close") {
        self.popup.close();
        self._popupResponseCallback(value);
      }
    };

    // set buttons
    var btn = [{
      text: i18n.t('common:apply'),
      click: function() {
        $(this).dialog("close");
        self._popupResponseCallback("y");
      }
    }, {
      text: i18n.t('common:cancel'),
      click: function() {
        $(this).dialog("close");
        self._popupResponseCallback("n");
      }
    }];

    if (this.hideAppliquerSelection === true) {
      btn.shift();
    }

    if (!_.isEmpty(this.extraButton) && this.hideAnnulerSelection === false) {
      this.extraButton.click = this.extraButton.click.bind(self);
      btn.push(this.extraButton);
    }

    if (!_.isEmpty(this.overrideButtonActions) && _.isArray(this.overrideButtonActions)) {
      btn = [];
      this.overrideButtonActions.forEach(function(element, index) {
        self.overrideButtonActions[index].click = self.overrideButtonActions[index].click.bind(self);
      });
      btn = this.overrideButtonActions;
    }

    try {
      this.popup.open();
      if (this.hideRecherche === true) {
        this.popup.$el.find("fieldset.motscles-selecteur-view-size").hide();
      }

    } catch (err) {
      LOG.debug("referentiel: " + err);
    }
    this.popup.dialog.dialog("option", "buttons", btn);
    this.popup._setTitle(this.title);
  },

  /**
   * Method to be executed when the detail popup is closed
   */
  _popupResponseCallback: function(a_action) {
    var action = a_action.toString().toLowerCase();
    if (action === "y" && !STR.isBlank(this.popupResponse)) {
      var list = this.popupResponse;
      if (this.popupResponse instanceof Backbone.Collection) {
        list = this.popupResponse.models;
      } else {
        if (!_.isArray(list)) {
          list = list ? [list] : [];
        }
      }
      var oldSelectedIds = _.map(this.selection.toJSON(), function(num) { return num.id; });
      var oldSelection = this.selection.clone();
      this.selection.reset(null, { silent: true });
      this._addItems(list);

      if (this.typeTree === false) {
        var size = oldSelectedIds.length;
        var newList = new Backbone.Collection(list);
        for (var i = 0; i < size; i++) {
          var element = newList.get(oldSelectedIds[i]);
          if (STR.isBlank(element)) {
            this._removeItem(oldSelection.get(oldSelectedIds[i]));
          }
        }
      }
    }
    $(this.el).find(".phx-referentiel-input").focus();
  },

  /**
   * Method to be executed when the input field of the component is focused
   */
  _focused: function() {
    LOG.debug("focused...");
  },

  /**
   * Opens a new list of autocompletion results only if 'listWidth' or 'listHeight'exists and with width 'listWidth' or width height 'listHeight'
   */
  _openlist: function() {
    var lo_css = {};

    if (this.listWidth && !STR.isBlank(this.listWidth)) {
      lo_css["width"] = this.listWidth;
      //on change "overflow-x" de "hidden" à "auto" s'il y a listWidth
      lo_css["overflow-x"] = "auto";
    }
    if (this.listHeight && !STR.isBlank(this.listHeight)) {
      lo_css["height"] = this.listHeight;
    }
    if (!_.isEmpty(lo_css)) {
      this.$el.find("input").autocomplete("widget").css(lo_css).position({
        my: "left top",
        at: "left bottom",
        of: $(this.el).find(".phx-referentiel-input"),
        collision: "flipfit"
      });
    }
  }
});
