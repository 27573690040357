import { BaseModel } from '../../../../../core/models/base.model.js';

export var TypeStructureModel = BaseModel.extend({

  url: function() {
    var url = Configuration.restRoot + "/rest/structorga/structvalideur";

    return url;
  },

  defaults: {
    "structid": null,
  },
});
