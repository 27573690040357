import { GLOBAL_DATA } from '../../../../../globalData.js';
import { PaginatedCollection } from '../../../../../core/models/paginated.collection.js';

/**
 * Briques configuration.
 */

/**
 * Model for Brique 'Situations à Régulariser'
 */

export var SituationsARegulariserColl = PaginatedCollection.extend({
  defaults: {
    "nbrang": 10,
    "total": null,
    "index": 1,
    "situation": {

      "prenom": "",
      "typeano": "B",
      "libelle": "Badg. en attente de validation",
      "minuteano": 1,
      "dateano": "20130910",
      "comment": "",
      "matricule": "110359",
      "heureano": 0,
      "nom": "",
      "matriculeaux": ""
    }
  },

  initialize: function() {
    PaginatedCollection.prototype.initialize.call(this);
    this.pagination.size = parseInt(GLOBAL_DATA.paramDivers.get("BRCnbElt").get("valeur"));

    // param divers to control max lines in the "brique."
    // Nombre maximum d'éléments à charger dans une brique d'accueil
    this.paramMaxElements = GLOBAL_DATA.paramDivers.get("BRCnbElt").get("valeur");

  },

  url: Configuration.restRoot + "/rest/brique/respo/situation",

  parse: function(response) {

    if (this.paramMaxElements < response.total) {
      response.total = parseInt(this.paramMaxElements);
    }

    response.list = response.situation;

    delete response.situation;

    return PaginatedCollection.prototype.parse.call(this, response);
  }

});