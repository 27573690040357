import { BaseModel } from '../../../../../core/models/base.model.js';

/**
 * Model for Brique 'Effectifs'
 */
export var EffectifsModel = BaseModel.extend({

  url: Configuration.restRoot + "/rest/brique/respo/effectif",

  defaults: {
    "present": 0,
    "absentprevu": 0,
    "absentnonprevu": 0,
    "indefini": 0,
    "heure": 0
  }
});