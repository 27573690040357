import { PaginatedCollection } from 'core/models/paginated.collection.js';
import {TypeStructureModel} from './typeStructure.model.js';

/**
 * Collection of typeStructure
 */

export var TypeStructureColl = PaginatedCollection.extend({
  model: TypeStructureModel,
  //url: Configuration.restRoot + "/rest/structorga",

  url: function() {
    var url = Configuration.restRoot + "/rest/structorga";
    if (this.codestructureRequest) {
      url = url + '/' + encodeURIComponent(this.codestructureRequest);
    }
    if (this.indselpop === true) {
      url = url + "?indselpop=true&attributs=abrege,code,codef,libelle,principal,rattcollab,rattactivite,rattaffectationsec,rattcolpar,rattpret,typeremdesc,libselpop";
    }

    return url;
  },

  initialize: function(options){
    PaginatedCollection.prototype.initialize.call(this);
    this.paginated = false;
    if (options && !_.isUndefined(options.indselpop)) {
      this.indselpop = options.indselpop;
    }
  }

});
