module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if(readonly) { 
__p+='\n  <span class="phx-chemin-item-text" title="'+
((__t=(model.label ))==null?'':__t)+
'">'+
((__t=(model.label ))==null?'':__t)+
'</span>\n  ';
 } else { 
__p+='\n    <span class="phx-chemin-item-text" title="'+
((__t=(model.label ))==null?'':__t)+
'">'+
((__t=(model.label ))==null?'':__t)+
'</span>\n    <span class="phx-chemin-item-icon ui-icon ui-icon-close"></span>\n    ';
 }
__p+='';
}
return __p;
};
