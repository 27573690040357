import { BaseModel } from 'core/models/base.model.js';

/**
 * Model of SearchListeGroupes
 */
export var SearchListeGroupesModel = BaseModel.extend({

  parse: function(response) {
    var groupe = {};
    groupe.code = response.groupe;
    groupe.libelle = response.libelle;
    return groupe;
  }
});