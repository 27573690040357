import { BaseModel } from '../../../../../../core/models/base.model.js';

import { i18n } from '../../../../../../i18n.js';
import { STR } from '../../../../../../utils/str.js';
import { TYPE } from '../../../../../../tda/tda.js';

/**
 * Model for gerer badgeages
 */
export var ListeBadgeageModel = BaseModel.extend({
  checkComments: false,

  //REVIEW: 	i18n : phx.i18n.common.gererbadgeages,
  //REVIEW: 	i18nCom : phx.i18n.common,

  /**
   * Builds the url for the REST call
   */
  url: function() {
    return Configuration.restRoot + "/rest/badgeage/composant/" + this.action;
  },

  /**
   * Default data model
   *		id : null,
   *		code : null,
   *		matricule: null,
   *		statut: {
   *			code : "",
   *			libelle : ""
   *		},
   *		motif: {
   *			code : "",
   *			libelle : ""
   *		},
   *		saisie: {
   *			date : "",
   *			heure : "",
   *			sens : {
   *			   code : "",
   *			   libelle : ""
   *		    }
   *		},
   *		valorise: {
   *			date : "",
   *			heure : "",
   *			sens : {
   *			   code : "",
   *			   libelle : ""
   *		    }
   *		},
   *		initial: {
   *			date : "",
   *			heure : "",
   *			sens : {
   *			   code : "",
   *			   libelle : ""
   *		    }
   *		},
   *		fonction: "",
   *		commentaire: "",
   *		droit: null,
   *		iddemande: null,
   *		provenance: "",
   *		origine: null,
   *		terminal: {
   *			   code : "",
   *			   libelle : ""
   *		},
   *		genere: null
   * }
   */
  defaults: {
    "id": null,
    "code": null,
    "matricule": null,
    "statut": {
      "code": "",
      "libelle": ""
    },
    "motif": {
      "code": "",
      "libelle": ""
    },
    "saisie": {
      "date": "",
      "heure": "",
      "sens": {
        code: "",
        libelle: ""
      }
    },
    "valorise": {
      "date": "",
      "heure": "",
      "sens": {
        code: "",
        libelle: ""
      }
    },
    "initial": {
      "date": "",
      "heure": "",
      "sens": {
        code: "",
        libelle: ""
      }
    },
    "fonction": "",
    "commentaire": "",
    "droit": null,
    "iddemande": null,
    "provenance": "",
    "origine": null,
    "terminal": {
      code: "",
      libelle: ""
    },
    "genere": null

  },

  /**
   * Validate the model before launch the REST
   */
  validate: function(attrs) {
    var errors = {};
    var errorMsg;
    errors.saisie = {};
    if (!STR.isBlank(attrs.saisie.date)) {
      errorMsg = TYPE.DATE.validate(attrs.saisie.date);
      if (!STR.isBlank(errorMsg)) {
        errors.saisie.date = errorMsg;
      }
    } else {
      errors.saisie.date = i18n.t('common:required', { "0": i18n.t('common:gererbadgeages.date') });
    }
    if (!STR.isBlank(attrs.saisie.heure)) {
      errorMsg = TYPE.HOUR_MINUTE.validate(attrs.saisie.heure);
      if (!STR.isBlank(errorMsg)) {
        errors.saisie.heure = errorMsg;
      }
    } else {
      errors.saisie.heure = i18n.t('common:required', { "0": i18n.t('common:gererbadgeages.heure') });
    }
    if (STR.isBlank(attrs.saisie.sens)) {
      errors.saisie.sens = i18n.t('common:required', { "0": i18n.t('common:gererbadgeages.sens') });
    }
    if (this.checkComments) {
      if (STR.isBlank(attrs.commentaire)) {
        errors.origine = i18n.t('common:required', { "0": i18n.t('common:gererbadgeages.commentaire') });
      }
    }

    if (!_.isEmpty(errors.saisie) || !_.isEmpty(errors.origine)) {
      var errorMap = {
        errorValidation: STR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
    return null;
  },

  /**
   * Parsed the results returned by the REST,
   * Copy the return matricule + code in the object id
   */
  parse: function(response) {
    if (response.matricule != undefined && response.code != undefined) {
      response.id = response.matricule + "," + response.code;
    }

    if (response.result) {
      return null;
    }
    return response;
  }
});
