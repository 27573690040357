import { GLOBAL_DATA } from '../../../../../globalData.js';
import { HabilitationContext } from '../../../../../core/models/habilitationContext.js';
import { i18n } from '../../../../../i18n.js';
import { ResultatsModel } from '../models/resultats.model.js';
import { STR } from '../../../../../utils/str.js';
import { TYPE } from '../../../../../tda/tda.js';
import TPL_bresultats_briqueResultatsCompteurs from '../briqueResultatsCompteurs.tpl.html';

/**
 * View of Brique 'Resultats'
 */
export var ResultatsView = Backbone.View.extend({

  initialize: function(options) {
    this.usePopulation = false;
    if (options && options.usePopulation) {
      this.usePopulation = options.usePopulation;
    }
    if (options) {
      if (options.parent) {
        this.parent = options.parent;
      }
      if (options.brique) {
        this.brique = options.brique;
      }
    }
    this.template = TPL_bresultats_briqueResultatsCompteurs;

    this.model = new ResultatsModel({ userId: this.brique.personnalisation.userid, codeBrique: this.brique.personnalisation.codebrique });
    this.model.usePopulation = this.usePopulation;

    this.habContext = new HabilitationContext({
      onglet: "bresultats" + this.cid.substring(4),
      fonc_cour: this.brique.fonctions[0],
      nat_gest: ""
    });

    this.model.setHabContext(this.habContext);

    this.empty = false;
  },

  render: function() {
    if (!(GLOBAL_DATA.paramDivers.get("bloq_bresR") && GLOBAL_DATA.paramDivers.get("bloq_bresR") == 1)) {
      var json = { 'i18n': i18n };
      $(this.el).html(this.template(json));
      $(this.el).css("max-height", "232px");

      return this;
    }
  },

  isEmpty: function() {
    return this.empty;
  },

  refreshBrique: function() {
    var self = this;
    this.model.fetch({
      success: function(fresh) {
        if (fresh.get("cpt").length > 0 && !(fresh.get("cpt").length == 1 && STR.isBlank(fresh.get("cpt")[0].code))) {
          self.empty = false;
          self.$el.parents("#bresultats" + self.cid.substring(4)).show();
          self.$el.find(".table_ResultatsCompteurs ").html(self._arrayToTable(fresh.get("cpt"), fresh.get("dateref")));

          self.parent.setTitle(fresh.get("libelle") + " " + i18n.t('common:bresultats.au') + " " + TYPE.DATE.format(fresh.get("dateref")));

          //update margins
          self.$el.parents(".phx-portlet-div").css({
            "padding": "0px 0px 0px 2px",
            "width": "calc(100% - 4px)"
          });

          if (fresh.get("cpt").length == 0)
            $(".table_ResultatsCompteurs").hide();
        } else {
          self.empty = true;
          self.$el.parents("#bresultats" + self.cid.substring(4)).hide();
        }
      }
    });
  },

  _arrayToTable: function(array, dateref) {
    var self = this;
    var cellRenderers = [];
    var tblColumns = [
      { property: "libelle", width: 165 },
      { property: "valeur", width: 50 },
      { property: "typevalo", width: 17 },
      { property: "datevalo", width: 90 }
    ];

    cellRenderers["valeur"] = function(model) {
      var style = "";
      if (model.valeur.indexOf("-") !== -1) { //if(model.valeur < 0)
        style = "class='ui-phx-valorisation-negative'";
      }
      return "<span " + style + " style='float: right;'>" + model.valeur + "</span>";
    };

    cellRenderers["typevalo"] = function(model) {
      var icon = "";
      var title = "";
      if (model.typevalo == "J") {
        icon = "class = 'phx-icon phx-icon-resultatgrpcompteurs-window'";
        title = "title = '" + i18n.t('common:bresultats.valeurJournee') + "'";
      } else if (model.typevalo == "P") {
        icon = "class = 'phx-icon phx-icon-resultatgrpcompteurs-windows'";
        title = "title = '" + i18n.t('common:bresultats.valeurPeriodique') + "'";
      }
      return "<span " + icon + " " + title + "></span>";
    };

    cellRenderers["datevalo"] = function(model) {
      if (model.datevalo != dateref)
        return "<span class='ui-phx-ihm-texte-donnees-alternatif'>" + TYPE.DATE.format(model.datevalo, TYPE._getFormatByCode("DATE_A")) + "</span>";
      return "";
    };

    var table = "<table class='ui-grid-table phx-grid ui-grid'>";

    //paint the data
    table += "<tbody class='ui-grid-body'>";
    for (var i = 0; i < array.length; i++) {
      table += "<tr class='ui-grid-row'>";
      for (var j = 0; j < tblColumns.length; j++) {
        table += "<td class='phx-td" + j + "' style='width:" + tblColumns[j].width + "px;'>";
        if (cellRenderers[tblColumns[j].property]) {
          table += cellRenderers[tblColumns[j].property](array[i]);
        } else {
          table += array[i][tblColumns[j].property];
        }
        table += "</td>";
      }
      table += "</tr>";
    }
    table += "</tbody>";

    return table;
  }
});