import { ReadOnlyModel } from '../models/readOnly.model.js';
import { STR } from '../../utils/str.js';

export var RadioBoxView = Backbone.View.extend({

  /** Mark to use like instanceof */
  isRadioBoxView: true,

  tagName: "fieldset",
  className: "phx-ui-radio",

  initialize: function(params) {
    this.model = new ReadOnlyModel({ value: "" });
    this.name = params.name;
    this.label = params.label;
    this.group = "radio";
    if (!STR.isBlank(params.group)) {
      this.group = params.group;
    }
    this.options = params.options;
    this.selectedOption = params.selectedOption;
    this.title = params.title;
    if (!STR.isBlank(params.orientation)) {
      this.orientation = params.orientation;
    } else {
      this.orientation = "vertical";
    }
    this.inContainer = params.inContainer;
    this.additionalViews = [];
    this.additionalRenderedViews = [];
    this.valueSelected = "";
    if (!STR.isBlank(params.additionalViews)) {
      this.additionalViews = params.additionalViews;
    }
  },

  options: [{
    code: "",
    libelle: ""
  }],

  events: {
    "change": "_changeView",
    "click [readonly]": "_clickEvent"
  },

  _clickEvent: function(e) {
    e.preventDefault();
    e.stopPropagation();
  },

  _changeView: function(event) {
    var target = event.target;
    var inputClass = event.target.className.split(" ")[0];
    if (!$(event.target).is(":radio")) {
      this.model.set(inputClass, event.target.value);
    } else if (this.model) {

      this.manageAdditionalViewState(target.value);

      //			this.model.set("value", target.code);
      this.model.set("value", target.value);
    }
  },

  _disableAdditionalView: function(code) {
    if (!STR.isBlank(code) && this.additionalRenderedViews[code] && this.additionalRenderedViews[code].disableView) {
      this.additionalRenderedViews[code].disableView();
    }
  },

  _enableAdditionalView: function(code) {
    if (!STR.isBlank(code) && this.additionalRenderedViews[code] && this.additionalRenderedViews[code].enableView) {
      this.additionalRenderedViews[code].enableView();
    }
  },

  // DEPRECATED ?¿?¿
  //	setDisabled : function(isDisabled){
  //		this.$el.find(".typehoraire\\.code").attr("disabled", isDisabled);
  //	},

  //	setReadOnly : function(){
  //		var this.$el.find(".typehoraire\\.code").val();
  //
  //	},

  render: function() {
    // Legend
    if (!STR.isBlank(this.label)) {
      this.$el.append($("<legend>").html(this.label));
    }
    // Options
    var optionsDiv = $("<div>").addClass("phx-ui-radio-options");
    var self = this;
    _.each(this.options, function(labelValue) {
      /*var optAttr = {
      		value : labelValue.code,
      		name : this.name + "_" + this.group,
      		"class" : this.name,
      		title : this.title,
      		type : "radio"
      		};
      if (this.selectedOption && this.selectedOption == labelValue.code) {
      	optAttr.selected = true;
      }
      var opt = this.make("input", optAttr);*/

      var opt = null;
      var l_divCont = $("<div>");
      //there is a view for this radio option
      var optAttr = " value=\"" + labelValue.code + "\" name=\"" + this.name + "_" + this.group + "\"";
      //"\" title=\"" + this.title +

      optAttr = optAttr + " class=\"" + this.name + "\" type=\"radio\"";
      if (!STR.isBlank(this.selectedOption) && this.selectedOption == labelValue.code) {
        self.valueSelected = this.selectedOption;
        optAttr = optAttr + " checked=\"checked\"";
      }
      opt = $("<input" + optAttr + "></input>");
      opt.prop("viewRef", self);
      l_divCont.append(opt);
      l_divCont.append("&nbsp;");
      //Defect 121271 - Now we set the info-bulle to the text to don't have it in the button
      if (!STR.isBlank(this.title)) {
        l_divCont.append("<label style='float:none' for=\"" + this.name + labelValue.code + "\" title=\"" + this.title + "\">" + labelValue.libelle + "</label>");
      } else if (!STR.isBlank(labelValue.libelle)) {
        l_divCont.append("<label style='float:none' for=\"" + this.name + labelValue.code + "\" >" + labelValue.libelle + "</label>");
      }
      if (this.inContainer === true) {
        optionsDiv.append(l_divCont);
      } else {
        optionsDiv.append(l_divCont.html());
      }
      if (self.additionalViews && self.additionalViews[labelValue.code]) {
        var additionalView = self.additionalViews[labelValue.code];
        var view = new additionalView.view(additionalView.viewData);
        self.additionalRenderedViews[labelValue.code] = view;
        view.render();
        view.$el.css("display", "inline-block");
        optionsDiv.append(view.$el);

        if (!STR.isBlank(this.selectedOption) && this.selectedOption != labelValue.code) {
          view.disableView();
        }
        optionsDiv.append('<div class="' + view.$el.find('input').attr("class").split(" ").shift() + '-error-container"></div>');
      } else {
        if (this.orientation === "vertical") {
          var br = $("</br>")[0];
          optionsDiv.append(br);
        } else if (this.orientation === "horizontal") {
          optionsDiv.append("&nbsp;&nbsp;");
        }
      }
    }, this);
    this.$el.append(optionsDiv);
    return this;
  },

  getAdditionalViews: function() {
    return this.additionalRenderedViews;
  },

  getAdditionalView: function(code) {
    return this.additionalRenderedViews[code];
  },

  manageAdditionalViewState: function(value) {
    //disable old option
    this._disableAdditionalView(this.valueSelected);
    //enable new option
    this._enableAdditionalView(value);

    this.valueSelected = value;
  },
  setItem: function(code) {
    var domEl = this.$el.find("input." + this.name + "");
    if (domEl.length > 0) {
      if (domEl.val() == code) {
        this.manageAdditionalViewState(code);
      }
      domEl.val([code]);
    }
  }
});