import { ReadOnlyModel } from '../../models/readOnly.model.js';

export var LoginTestConnModel = ReadOnlyModel.extend({
  /**
   * Add this to have Outline
   *
   */
  dummy: "",
  /**
   * habilitationV
   */
  habilitationV: "N",
  /**
   * habilitationG
   */
  habilitationG: "N",
  /**
   * usecase
   */
  usecase: "Login_test",
  /**
   * url
   */
  url: Configuration.restRoot + "/rest/authentification/testconnexion",

  /**
   * Constructor
   * Login Test connection model
   */
  initialize: function(options) {
    if (options && options.param) {
      this.param = options.param;
    }
    this.timeout = 5000;
    if (options && options.timeout) {
      this.timeout = options.timeout;
    }
  },
  parse: function(response) {
    if (response.x_token_key) {
      Configuration.x_token_key = response.x_token_key;
    }
  }

});