
import { ListeBadgeageModel } from './listeBadgeage.model.js';
import { PaginatedCollection } from '../../../../../../core/models/paginated.collection.js';
import { STR } from '../../../../../../utils/str.js';

export var ListeBadgeageColl = PaginatedCollection.extend({

  model: ListeBadgeageModel,
  version: "2.2.0",
  /**
   * Constructor - renove the paginated (this.paginated = false)
   */
  initialize: function() {
    PaginatedCollection.prototype.initialize.call(this);
    //this.sortings = {valorise.date : true, valorise.heure : true}; //TODO
    this.matricule = null;
    this.statuts = null;
    this.datedeb = null;
    this.datefin = null;
    this.horaire = null;
    this.paginated = false;
  },

  /**
   * Builds the url for the REST call
   */
  url: function() {
    //return Configuration.restRoot + "/rest/badgeages/" + this.vueJourneeCollab + encodeURIComponent(",") + this.vueJourneeDate;
    var txtUrl = "?matricule=" + this.matricule + "&statuts=" + this.statuts;
    if (!STR.isBlank(this.datedeb)) {
      txtUrl += "&datedeb=" + this.datedeb;
    }
    if (!STR.isBlank(this.datefin)) {
      txtUrl += "&datefin=" + this.datefin;
    }
    if (!STR.isBlank(this.horaire)) {
      txtUrl += "&horaire=" + this.horaire;
    }
    if (!STR.isBlank(this.validation)) {
      txtUrl += "&ctxActionValider=" + this.validation;
    }
    return Configuration.restRoot + "/rest/badgeage/composant/liste" + txtUrl;
  },

  clone: function() {
    var temp = PaginatedCollection.prototype.clone.call(this);
    return temp;
  }
});