import { onBeforeSend } from './Interceptors/onBeforeSend.js';
import { onComplete } from './Interceptors/onComplete.js';
import { onError } from './Interceptors/onError.js';
import { objs } from './objectsRepository.js';

var defaults = function(obj, source) {
  for (var prop in source) {
    if (obj[prop] === undefined) {
      obj[prop] = source[prop];
    }
  }
  return obj;
};

var stringifyGETParams = function(url, data) {
  var query = '';
  var newUrl = url;
  for (var key in data) {
    if (data[key] === null) {
      continue;
    }
    query += '&' +
      encodeURIComponent(key) + '=' +
      encodeURIComponent(data[key]);
  }
  if (query) {
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + query.substring(1);
  }
  return newUrl;
};

var getData = function(response) {
  var resp = null;
  var regExp = new RegExp(/application\/.*json/);
  var contentType = response.headers.get("content-type");

  if (contentType && regExp.test(contentType)) {
    try {
      resp = response.json();
    } catch (err) {
      resp = response.text();
    }
  } else {
    resp = response.text();
  }
  return resp;
};

var ajax = function(options) {
  var optionRequest = options;
  if (options.type === 'GET' && typeof options.data === 'object') {
    options.url = stringifyGETParams(options.url, options.data);
    delete options.data;
  }

  defaults(options, {
    method: options.type,
    headers: defaults(options.headers || {}, {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "x_token_key": Configuration.x_token_key
    }),
    credentials: 'include',
    body: options.data,
    cache: "no-store"
  });
  if (objs.isWhatwgFetch) {
    if (options.url.indexOf("?") > 0) {
      options.url += "&_=" + _.uniqueId(Math.random() * 1000 | 0);
    } else {
      options.url += "?_=" + _.uniqueId(Math.random() * 1000 | 0);
    }
  }
  onBeforeSend(options.headers);
  var inPromise = false;
  var error = null;
  return fetch(options.url, options)
    .then(function(response) {
      onComplete(options.headers);
      var promise = getData(response, options.dataType);

      if (response.ok) {
        return promise.then(function(responseData) {
          if (options.success) {
            inPromise = true;
            options.success(responseData);
            inPromise = false;
          }
          return responseData;
        });
      }

      error = new Error(response.statusText);
      return promise.then(function(responseData) {
        response = _(response).clone();
        response.responseText = JSON.stringify(responseData);
        response.responseJSON = responseData;
        error.response = response;
        error.responseData = responseData;
        if (options.error) {
          inPromise = true;
          options.error(response);
          inPromise = false;
          onError(error, response, optionRequest);
        }

      });
    }).catch(function(response) {
      if (inPromise === true) {
        inPromise = false;
        console.error(response); // eslint-disable-line no-console
        window.onerror(response.message);
        error = new Error(response.statusText);
        response = _(response).clone();
        error.response = response;
        if (options.error) {
          onError(error, response, optionRequest);
        }
      } else {
        onComplete(options.headers);
        error = new Error(response.statusText);
        response = _(response).clone();
        error.response = response;
        if (options.error) {
          options.error(error);
          onError(error, response, optionRequest);
        }
      }
    });
};

export default ajax;
