import { GroupeCompteursDialogView } from '../../resultatgrpcompteurs/views/groupeCompteursDialog.view.js';
import { i18n } from '../../../../i18n.js';

/*
 * Intermediate view to avoid fetching at initialize and show hidder when openning dialog.
 */
export var IntermediateSituationView = Backbone.View.extend({
  className: "gmsituation",

  initialize: function(options) {
    this.typejson = null;

    if (options && options.context)
      this.context = options.context;

    if (options && options.parent) {
      this.parent = options.parent;
      if (this.parent._setTitle) {
        this.parent._setTitle(i18n.t('common:gmsituation.dialogGroupeCompteurs'));
      }
    }
  },

  render: function() {
    $(this.el).height("579px");
    this.compteur = new GroupeCompteursDialogView({ context: this.context, parent: this.parent });

    var self = this;
    this.listenTo(this.compteur, "resizeDialog", function() {
      var heightContainer = $('.phx-portlet-container').height();
      var heightPopup = parseInt(this.parent.$el.css('height'), 10) + parseInt(this.parent.$el.css('padding-top'), 10) + parseInt(this.parent.$el.css('padding-bottom'), 10) + 33;
      var incremento = (heightContainer - heightPopup) / 2;

      if (!incremento < 0) {
        self.parent.dialog.dialog("option", "position", { my: "top", at: "center top" + incremento, of: $('.phx-zone') });
      } else {
        self.parent.dialog.dialog("option", "position", { my: "center", at: "center", of: window });
      }
      self.parent.resize();

    });

    this.$el.append(this.compteur.render().el);

    return this;
  },

  setContext: function(context) {
    // set context
    this.compteur.setContext(context);
  }
});
