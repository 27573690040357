import { BaseModel } from 'core/models/base.model.js';
import { CalendaccElementsModel } from './calendaccElements.model.js';
import { CalendaccVascoModel } from './calendaccVasco.model.js';
import { CalendaccZonesModel } from './calendaccZones.model.js';

/**
 * Model for the main table
 */
export var CalendaccModel = BaseModel.extend({

  habilitationV: "CALENDRIER.V",
  habilitationG: "N",
  usecase: "calendacc",

  defaults: {
    "elements": [],
    "vasco": [],
    "zones": [],
    "natures": []
  },

  urlRoot: function() {
    return Configuration.restRoot + "/rest/calendriercollab/" + encodeURIComponent(this.datedeb + "," + this.datefin);
  },

  parse: function(response) {
    if (response.elements) {
      response.elements = new Backbone.Collection(response.elements, { model: CalendaccElementsModel });
    }
    if (response.vasco) {
      response.vasco = new Backbone.Collection(response.vasco, { model: CalendaccVascoModel });
    }
    if (response.zones) {
      response.zones = new Backbone.Collection(response.zones, {
        model: CalendaccZonesModel,
        parse: true
      });
    }
    return response;
  }
});