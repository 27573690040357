/**
 * Right Model.
 */
export var DroitModel = Backbone.Model.extend({

  dummy: "",

  defaults: {},

  /**
   * Process to manage the response
   */
  parse: function(response) {
    if (response && response.fonction) {
      this.id = response.fonction;
    }
    return response;
  }

});