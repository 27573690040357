import { ButtonBarView } from '../../../../../core/controls/buttonBar.view.js';
import { HABILITATION } from '../../../../../utils/habilitation.js';
import { i18n } from '../../../../../i18n.js';
import { STR } from '../../../../../utils/str.js';
import TPL_persobriques_briqueLine from '../briqueLine.tpl.html';
/**
 * View for each line of brique
 */
export var BriquesLineView = Backbone.View.extend({

  initialize: function(options) {
    this.template = TPL_persobriques_briqueLine;
    if (options.model) {
      this.model = options.model;
    }
    if (options.context) {
      this.context = options.context;
    }
    if (options && !STR.isBlank(options.position)) {
      this.position = options.position;
    }
    if (options && options.totalActivesLength) {
      this.totalActivesLength = options.totalActivesLength;
    }
    this.btnBar = new ButtonBarView({ id: "persobriques_btnbar_" + this.model.getUniqueId() });
    this.btnBar.model.on("btn:click", this._buttonAction, this);
  },
  render: function() {
    var self = this;
    var json = { "i18n": i18n };
    this.$el.append(this.template(json));
    this.$el.find(".persobriques-brique-line").attr("briqueUId", this.model.getUniqueId());

    this.$el.find("input.persobriques-brique-line-check").attr("position", this.position);
    //Checkbox
    if (this.model.get("active") == true) {
      this.$el.find("input.persobriques-brique-line-check").prop("checked", true);
    }
    if (this._isLineFictive(this.model) == true) {
      this.$el.find("input.persobriques-brique-line-check").hide();
    }

    //disable the check for monresp so its always visible
    if (this.model.get("id") === "bmonresp") {
      this.$el.find("input.persobriques-brique-line-check").prop("disabled", true);
    }

    this._manageCheckBoxHabilitations();

    //Titre
    this.$el.find(".persobriques-brique-line-text").html();
    var spanText = $("<span>").html(this.model.get("nom"));
    spanText.css("margin-right", "4px");
    this.$el.find(".persobriques-brique-line-text").append(spanText);
    if (!STR.isBlank(this.model.get("personnalisation"))) {
      if (this.model.get("personnalisation").visibilite == true) { //ICON public
        this._showIconPublic();
      }
      if (this._isPersonnalisable(this.model) || this._isLineFictive(this.model)) { //ICON personnalisable
        var spanPerso = $("<span>").addClass("phx-icon").addClass("phx-icon-personnalisable");
        spanPerso.css("display", "inline-block").css("margin-left", "3px").css("margin-right", "3px");;
        this.$el.find(".persobriques-brique-line-text").append(spanPerso);
      }
    } else {
      if (!this._isPersonnalisable(this.model)) { //ICON public
        this._showIconPublic();
      }
    }

    //Buttons
    this.$el.find(".persobriques-brique-line-btns").append(this.btnBar.render().el);
    this.$el.find(".persobriques-brique-line-btns").hide();
    this._generateButtonBar();

    this._manageButtonBarHabilitations();
    this._manageActions();

    //		$(this.el).find(".persobriques-brique-line").hover(function(e){
    //			self._hoverBtnTitle(e);
    //		},function(e){
    //			self._hoverBtnTitle(e);
    //		});

    $(this.el).find(".persobriques-brique-line").on("mouseenter", function(e) {
      self._hoverBtnTitle(e);
    });
    $(this.el).find(".persobriques-brique-line").on("mouseleave", function(e) {
      self._hoverBtnTitle(e);
    });
    return this;
  },
  _showIconPublic: function() {
    var spanPublic = $("<span>").addClass("phx-icon").addClass("phx-icon-visibilite-publique");
    spanPublic.css("display", "inline-block").css("margin-left", "3px").css("margin-right", "3px");
    this.$el.find(".persobriques-brique-line-text").append(spanPublic);
  },
  _generateButtonBar: function() {
    this.btnBar.model.trigger("hide:save");
    this.btnBar.model.trigger("hide:revert");
    this.btnBar.model.trigger("hide:new");
    this.btnBar.model.trigger("hide:delete");
    this.btnBar.model.trigger("hide:copy");

    this.btnBar.addButton("monter", "", "ui-icon-arrowthick-1-n", null, null, i18n.t('common:persobriques.button_remonter'));
    this.btnBar.addButton("descendre", "", "ui-icon-arrowthick-1-s", null, null, i18n.t('common:persobriques.button_descendre'));

    this.btnBar.addButton("detail", "", "", null);
    //AddIcon
    var span = $("<span>").addClass("phx-icon").addClass("phx-icon-personnaliser");
    span.css("position", "absolute").css("top", "58%").css("left", ".5em").css("margin-top", "-11px").css("opacity", "1");
    this.$el.find(".persobriques-brique-line-btns button.detail").prepend(span);

    this.btnBar.addButton("creer", "", "", null);
    this.$el.find(".persobriques-brique-line-btns button.creer").css("width", "40px");
    //AddIcon
    var span = $("<span>").addClass("phx-icon").addClass("phx-icon-ajouter-personnalisation");
    span.css("position", "absolute").css("top", "58%").css("left", "1em").css("margin-top", "-9px").css("opacity", "1");
    this.$el.find(".persobriques-brique-line-btns button.creer").prepend(span);

    this.btnBar.addButton("supprimer", "", "", null);
    this.$el.find(".persobriques-brique-line-btns button.supprimer").css("width", "40px");
    //AddIcon
    var span = $("<span>").addClass("phx-icon").addClass("phx-icon-supprimer-personnalisation");
    span.css("position", "absolute").css("top", "58%").css("left", "1em").css("margin-top", "-9px").css("opacity", "1");
    this.$el.find(".persobriques-brique-line-btns button.supprimer").prepend(span);

  },
  _manageCheckBoxHabilitations: function() {
    if (!HABILITATION.canView(this.context.ctxHabilitation.HabilitationAcces)) {
      this.$el.find("input.persobriques-brique-line-check").hide();
    }
  },
  _manageButtonBarHabilitations: function() {
    if (!HABILITATION.canView(this.context.ctxHabilitation.HabilitationAcces)) {
      this.$el.find("input.persobriques-brique-line-check").hide();
    }
    if (!HABILITATION.canView(this.context.ctxHabilitation.HabilitationAcces)) {
      this.btnBar.model.trigger("hide:monter");
      this.btnBar.model.trigger("hide:descendre");
      this.btnBar.model.trigger("hide:detail");
    }
    if (!HABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion)) {
      this.btnBar.model.trigger("hide:creer");
      this.btnBar.model.trigger("hide:supprimer");
    }
    if (!HABILITATION.canCreate(this.context.ctxHabilitation.HabilitationGestion)) {
      this.btnBar.model.trigger("hide:creer");
    }
    if (!HABILITATION.canDelete(this.context.ctxHabilitation.HabilitationGestion)) {
      this.btnBar.model.trigger("hide:supprimer");
    }
    if (this._visibilitePublic(this.model) && !HABILITATION.canView("PER_BRACCTOUS.G")) {
      this.btnBar.model.trigger("hide:supprimer");
    }
  },

  _manageActions: function() {
    //Monter
    if (!this._isActive(this.model) || this.position == 0 || this.position === 1) {
      this.btnBar.model.trigger("disable:monter");
    } else {
      this.btnBar.model.trigger("enable:monter");
    }
    //Descendre
    if ((!this._isActive(this.model) || this.position >= (this.totalActivesLength - 1)) || this.model.get("id") === "bmonresp") {
      this.btnBar.model.trigger("disable:descendre");
    } else {
      this.btnBar.model.trigger("enable:descendre");
    }
    //detail
    if (this._isPersonnalisable(this.model) && !this._isLineFictive(this.model)) {
      this.btnBar.model.trigger("enable:detail");
      this.$el.find("button.detail").css("visibility", "visible");
    } else {
      this.btnBar.model.trigger("disable:detail");
      this.$el.find("button.detail").css("visibility", "hidden");
    }
    //creer
    if (!this._isPersonnalisable(this.model) && !this._isLineFictive(this.model)) {
      this.btnBar.model.trigger("disable:creer");
      this.$el.find("button.creer").css("visibility", "hidden");
    } else {
      this.btnBar.model.trigger("enable:creer");
      this.$el.find("button.creer").css("visibility", "visible");
    }
    //supprimer
    if (this._isPersonnalisable(this.model) && !this._isLineFictive(this.model)) {
      this.btnBar.model.trigger("enable:supprimer");
      this.$el.find("button.supprimer").css("visibility", "visible");
    } else {
      this.btnBar.model.trigger("disable:supprimer");
      this.$el.find("button.supprimer").css("visibility", "hidden");
    }

  },

  _isActive: function(model) {
    var active = false;
    if (model.get("active") == true) {
      active = true;
    }
    return active;
  },
  _isStandard: function(model) {
    var standard = true;
    if (!STR.isBlank(model.get("personnalisation"))) {
      standard = false;
    }
    return standard;
  },
  _isPersonnalisable: function(model) {
    var perso = false;
    if (!STR.isBlank(model.get("personnalisation")) && !STR.isBlank(model.get("personnalisation").userid)) {
      perso = true;
    }
    return perso;
  },
  _isLineFictive: function(model) {
    var fictive = false;
    if (!STR.isBlank(model.get("personnalisation")) && STR.isBlank(model.get("personnalisation").userid) && STR.isBlank(model.get("personnalisation").codebrique)) {
      fictive = true;
    }
    return fictive;
  },
  _visibilitePublic: function(model) {
    var publ = false;
    if (!STR.isBlank(model.get("personnalisation")) && model.get("personnalisation").visibilite == true) {
      publ = true;
    }
    return publ;
  },
  /**
   *  When we hover the title, it will show a button bar
   */
  _hoverBtnTitle: function(event) {
    if (event.type === "mouseenter") { // When the mouse enter on the title block
      this.model.trigger("hideButtons");
      this.$el.find(".persobriques-brique-line-btns").show();
    } else if (event.type === "mouseleave") { // When the mouse get out the line
      if (event.target == event.delegateTarget) {
        this.$el.find(".persobriques-brique-line-btns .phx-buttonBar div").trigger("mouseleave"); // made to hide tooltips
        this.$el.find(".persobriques-brique-line-btns").hide();
      }
    }
  },
  /**
   * Function that manages button clicked in right form
   */
  _buttonAction: function(buttonId) {
    this.model.trigger("buttonAction", buttonId, this.position);
  }
});
