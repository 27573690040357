import { BaseModel } from 'core/models/base.model.js';

/**
 * Criteres du calendrier Model
 */
export var CriteresCalModel = BaseModel.extend({

  defaults: {
    "datedeb": "",
    "datefin": ""
  }
});