import { objs } from '../../objectsRepository.js';
import { STR } from '../../utils/str.js';
/**
 * Phoenix custom router
 */
export var SubRoute = Backbone.SubRoute.extend({

  dummy: "",

  /**
   * Constructor
   */
  constructor: function(prefix, options) {
    if (options && options.module) {
      this.module = options.module;
    }
    // Create a new routes replacing module_mane by the real name.
    var keys = _.keys(this.routes);
    var values = _.values(this.routes);
    keys = _.map(keys, function(key) {
      return key.replace("<module_name>", this.module);
    }, this);
    this.routes = _.object(keys, values);

    this.listenTo(objs.appRt.workflow, "change:usecase", this._treatUsecaseFocusInternal);
    Backbone.SubRoute.prototype.constructor.call(this, prefix, options);
  },

  /**
   * Cleans screen and initializes the layout
   */
  list: function() {
    // Check dependencies
    if (STR.isBlank(this.workflow)) {
      throw new Error("This router need a workflow");
    }
    if (STR.isBlank(this.workflow.tableModel)) {
      throw new Error("The wokflow of this model needs a tableModel");
    }

    // clean screen and initializes the layout
    var self = this;
    this._initLayoutUC(function() {
      var table = self.workflow.tableModel;
      if (table.coll.length > 0) {
        if (table.coll.pagination.startIndex === 0) {
          var firstRow = table.coll.at(0);
          if (_.isNull(table.get("value")) || table.get("value").id !== firstRow.id) {
            table.selectRow(firstRow);
          }
        } else {
          table.selectFirstRow();
        }
      }
    });
  },
  /**
   * Details for this subroute
   */
  details: function(id) {
    // Check dependencies
    if (!this.workflow) {
      throw new Error("This router need a workflow");
    }
    if (!this.workflow.tableModel) {
      throw new Error("The wokflow of this model needs a tableModel");
    }

    var table = this.workflow.tableModel;

    //0 - clean screen and initializes the layout
    this._initLayoutUC(function() {
      // 1 - Do the required actions over the already painted screen
      // In this case, select the row passed by param
      var model = new Backbone.Model({ id: decodeURIComponent(id) });
      if (_.isNull(table.get("value")) || table.get("value").id !== model.id) {
        table.selectRow(model);
      }
    });
  },

  /**
   *
   */
  getCurrentMode: function() {
    var mode = "R";
    if (this.workflow && this.workflow.btnBarModel && this.workflow.btnBarModel instanceof Backbone.Model) {
      mode = this.workflow.btnBarModel.get("mode");
    }
    return mode;
  },

  /**
   * Throws error because this function is not implemented yet.
   */
  _initLayoutUC: function() {
    throw new Error('Method "_initLayoutUC" not implemented yet');
  },
  /**
   * Returns true if it is on accueil
   */
  isOnAccueil: function() {
    return this.prefix.indexOf("acc") > 0;
  },
  /**
   * Returns true if it is Mon Compte
   */
  isOnMonCompte: function() {
    return this.prefix.indexOf("coll") > 0;
  },
  /**
   * Returns true if it is on Mon Equipe
   */
  isOnMonEquipe: function() {
    return this.prefix.indexOf("resp") > 0;
  },
  /**
   * Returns true if it is on Referentiel
   */
  isOnReferentiel: function() {
    return this.prefix.indexOf("ref") > 0;
  },
  /**
   * Returns true if it is on Administration
   */
  isOnAdministration: function() {
    return this.prefix.indexOf("adm") > 0;
  },

  _treatUsecaseFocusInternal: function(appRtWorkflow, focusedModule) {
    if (this.module === focusedModule) {
      this._treatUsecaseFocus();
    }
  },

  _treatUsecaseFocus: function() {
    // Override this method to capture when the UCs is selected
  }

});