import { DataGridRowView } from '../data_grid/dataGridRow.view.js';
import { EditableGridView } from '../editablegrid/editableGrid.view.js';
import { LOG } from '../../../utils/log.js';
import { STR } from '../../../utils/str.js';
import TPL_common_table_cell_editaction from '../basegrid/table_cell_editaction.tpl.html';
import TPL_common_table_cell_multiselectaction from '../basegrid/table_cell_multiselectaction.tpl.html';

/**
 * View underlying a row form the Tree type DataGrid
 */
export var TreeTypeGridRowView = DataGridRowView.extend({

  dummy: "",

  /**
   * Name of the html tag which involves the view
   */
  tagName: "tr",

  /**
   * Name of the container class of the view
   */
  className: "ui-grid-row",

  /**
   * Indicator to know if the row is edited or not
   */
  edited: false,

  /**
   * Indicator to know if an element is being dragged
   */
  dragging: false,

  /**
   * Event launched when the expand icon is clicked
   *
   */
  /**
   * Event launched when the row is clicked
   *
   */
  /**
   * Event launched when the row is clicked twice
   *
   */
  /**
   * Event launched when the expand icon is clicked
   *
   */
  events: {
    "click .treetbl_expbtn": "_clickTreeTableBtn",
    "click": "_rowListener",
    "dblclick": "_rowListener",
    "keydown": "keyEvent"
  },
  initialize: function(options) {
    DataGridRowView.prototype.initialize.call(this, options);
    this.model.dragging = false;
    this.visible = true;
    if (options && _.isBoolean(options.visible)) {
      this.visible = options.visible;
    }
  },
  /**
   * Method which expands or not the row of the table grid when we click the related button.
   */
  keyEvent: function(event) {
    if (event.keyCode == 13 || event.keyCode == 32) { // enter or space
      this.model.trigger("treetbl_exp", this.model);
      event.preventDefault();
    }
  },

  /**
   * Method which expands or not the row of the table grid when we click the related button.
   */
  _clickTreeTableBtn: function(event) {
    this.model.trigger("treetbl_exp", this.model);
    event.stopPropagation();
  },

  /**
   * Overrides basegrid rowListener to prevent click launched when dragg finnished
   * Method which manages the row click or double click in order to select it or edit it.
   */
  _rowListener: function(event) {
    if (this.model.dragging === false && $(event.target).hasClass("preventRowListener") === false) {
      DataGridRowView.prototype._rowListener.call(this, event);
    }
  },
  /**
   * Paints the row view of the tree grid
   */
  render: function() {
    var self = this;
    var rowThis = $(this.el);

    this._addTreeHtmlData();
    var isSelected = rowThis.hasClass("phx-selected");
    if (this.dataGrid.selectable) {
      rowThis.css("cursor", "pointer");
    }
    if (!this.visible) {
      rowThis.css("display", "none");
    }
    rowThis.empty();

    if (this.edited === true &&
      (this.dataGrid instanceof EditableGridView)) {
      this._renderForm();
    } else {
      _.each(this.dataGrid.currentVue.columns, function(column, index) {
        var cell = null;
        switch (column.property) {
          case "phx-multiselect":
            if (this.dataGrid.currentVue._columns["phx-multiselect"] && this.dataGrid.currentVue._columns["phx-multiselect"].visible) {
              cell = this._addActionCell(column, index, TPL_common_table_cell_multiselectaction);
            }
            break;
          case "phx-action":
            if (this.dataGrid.currentVue._columns["phx-action"] && this.dataGrid.currentVue._columns["phx-action"].visible) {
              cell = this._addActionCell(column, index, TPL_common_table_cell_editaction);
            }
            break;
          case "phx-last":
            break;
          default:
            cell = this._addCell(column, index);
        }

        if (cell != null) {
          if (column.treecol === true) {
            var marginLeft = this.model.level * 10;
            var span = null;
            if (this.model.isExpanded === true && !STR.isBlank(this.model.hasChild)) {
              span = $("<div style='position:relative;display:inline-block;width:16px;'><span class='treetbl_expbtn ui-corner-all " + this.dataGrid.expCollIcons.collapsible + "' branch='" + this.model.branch +
                "' level='" + this.model.level + "' item='" + this.model.item + "' treetype_path='" + this.model.path +
                "' style='position:absolute;top:-13px;'></span></div>");
            } else {
              span = $("<div style='position:relative;display:inline-block;width:16px;'><span class='treetbl_expbtn ui-corner-all  " + this.dataGrid.expCollIcons.expandable + "' branch='" + this.model.branch +
                "' level='" + this.model.level + "' item='" + this.model.item + "' treetype_path='" + this.model.path +
                "' style='position:absolute;top:-13px;'></span></div>");
            }
            cell.$el.prepend(span);
            cell.$el.css("height", "17px");
            if (this.model.collapsible === true && (this.model.hasChild === true || STR.isBlank(this.model.hasChild))) {
              span.show();
            } else {
              span.hide();
              marginLeft += 16;
            }
            cell.$el.css("padding-left", marginLeft + "px");
          }
          rowThis.append(cell.el);
          this.dataGrid.currentVue._columns[column.property].cells.push(cell);
          if (!this.dataGrid.currentVue._columns[column.property].visible) {
            cell.$el.hide();
          }
          if (column.draggable === true && STR.isBlank(this.dataGrid.draggableClass) === false) {
            cell.$el.find("." + this.dataGrid.draggableClass).attr("modelId", this.model.id);
            cell.$el.find("." + this.dataGrid.draggableClass).draggable({
              revert: true,
              delay: 150,
              //make a copy of the content and adds to a floating on the cursor
              helper: "clone",
              start: function(event, ui) {
                self._calculateDrops(event, ui);
              },
              stop: function(event, ui) {
                self._resetDrops(event, ui);
              }
            });
            this.$el.find("." + this.dataGrid.draggableClass).droppable({
              //Don't propagate the drop event
              greedy: true,
              //Set the drag interact on cursor
              tolerance: "pointer",
              //Class when hover accepted element
              hoverClass: "ui-drop-hover",
              //Function when drop an acceptable element
              drop: function(event, ui) {
                var targetId = $(event.target).attr("modelId");
                var origId = ui.draggable.attr("modelId");
                var targetModel = self.dataGrid.model.coll.get(targetId);
                var origModel = self.dataGrid.model.coll.get(origId);
                LOG.debug("DROPPED SUCCES !!! From: " + origModel.get("libelle") + " To:" + targetModel.get("libelle"));
                self.dataGrid.model.trigger("dropped:element", origModel, targetModel);
              }
            });
          }
        }
      }, this);

      if (this.dataGrid.currentVue._columns["phx-multiselect"]) {
        this._showMultiSelection();
      }

      if (isSelected) {
        this._selectRow();
      }
    }
    return this;
  },
  /**
   * add hierarchie info to the html tr that represents a row
   */
  _addTreeHtmlData: function() {
    $(this.el).attr("data-level", this.model.level);
    $(this.el).attr("data-branch", this.model.branch);
    $(this.el).attr("data-path", this.model.path);
    $(this.el).attr("data-id", this.model.get("id"));

  },
  /**
   * Function called when start the drag.
   */
  _calculateDrops: function(event) {
    LOG.debug("dragged");
    var self = this;
    var modelId = $(event.target).attr("modelId");
    var model = this.dataGrid.model.coll.get(modelId);
    model.dragging = true;
    var path = "" + model.path;

    //Disable own
    $(event.target).droppable("disable");

    //Disable the childs
    if (model.hasChild === true && model.isExpanded == true) {
      //disable droppable from childrens
      var rowsInBranch = this.dataGrid.$el.find("tr[data-branch='" + model.branch + "']");
      _.each(rowsInBranch, function(row) {
        var rowmodelId = $(row).find("." + self.dataGrid.draggableClass).attr("modelId");
        var rowModel = self.dataGrid.model.coll.get(rowmodelId);
        var elementPathString = "" + rowModel.path;
        if (rowModel.level >= model.level && elementPathString != path && elementPathString.indexOf(path) === 0) {
          //If the element is a son of the dragging element, disable drop
          $(row).find("." + self.dataGrid.draggableClass).droppable("disable");
        }
      });
    }

  },

  /**
   * Function called when end the drag.
   */
  _resetDrops: function(event) {
    var modelId = $(event.target).attr("modelId");
    //		if(!modelId){
    //			return
    //		}
    LOG.debug("End Drag");
    var model = this.dataGrid.model.coll.get(modelId);
    model.dragging = false;
    this.dataGrid.$el.find("." + this.dataGrid.draggableClass + ".ui-droppable-disabled").droppable("enable");
  }

});