import { i18n } from '../i18n.js';
import { LOG } from './log.js';
import { MSGS } from './msgs.js';
import { objs } from '../objectsRepository.js';
import { STR } from './str.js';

/**
 * Utilities to navigate
 */
export var NAV = {

  /**
   * Maxium number of opened tabs allowed
   */
  MAX_TABS: 10,

  /**
   * Navigate to a some part of the application, if the zone is empty it navigates to the home
   */
  navigate: function(zone, module, type, a_trigger, force) {
    var l_zone = zone;

    // Use uc as default type
    if (type === undefined) {
      type = "uc";
    } else if (_.isBoolean(type)) { // Use default type and trigger parametrized.
      a_trigger = type;
      type = "uc";
    }
    // Use the trigger parameter to determine if the navigation must trigger a route event
    if (_.isBoolean(a_trigger) && !a_trigger) {
      a_trigger = false;
    } else { // true by default
      a_trigger = true;
    }

    if (!STR.isBlank(module)) {
      l_zone = objs.appRt.workflow._searchZoneFromUc(module);
      if (module === "agenda_R") {
        //l_zone="resp";
        if (!_.isEqual(l_zone, "resp")) {
          l_zone = objs.appRt.workflow._searchZoneFromUc("planresp"); //la zone doit être 'resp' mais la  meilleure forme de garantir qu'elle est la correcte, c'est utiliser celle-là du planning
        }
      }
    }
    LOG.debug("navigate:" + l_zone + " _ " + module + " _ " + type + " _ " + a_trigger);

    if (!l_zone) {
      objs.appRt.workflow.set("uri", "", { "trigger": a_trigger });
    } else {
      if (!module) {
        var attributes = {};

        attributes["uri"] = "z/" + l_zone;
        attributes["selectedTab-" + l_zone] = { "module": "", "type": "" };
        objs.appRt.workflow.set(attributes, { "trigger": a_trigger });
      } else {
        var mod = module;
        var routerName = module.split("/")[0] || module;
        var attributes = {};

        if (force !== true && objs[routerName + "Rt"]) {
          mod = objs[routerName + "Rt"].workflow.get("uri") || module;
        }
        attributes["uri"] = "z/" + l_zone + "/" + type + "/" + mod;
        attributes["selectedTab-" + l_zone] = { "module": mod, "type": type };
        objs.appRt.workflow.set(attributes, { "trigger": a_trigger });
      }
    }
  },

  /**
   * Navigate to a some part of the application, if the zone is empty it navigates to the home
   * usually called from a router loading inside current zone
   */
  navigateToModule: function(module, type, trigger) {
    // get current zone
    var zone = objs.appRt.workflow.get("zone");

    if (!STR.isBlank(module)) {
      if (module === "agenda_R") {
        //zone="resp";
        zone = objs.appRt.workflow._searchZoneFromUc("planresp"); //la zone doit être 'resp' mais la  meilleure forme de garantir qu'elle est la correcte, c'est utiliser celle-là du planning
      } else {
        zone = objs.appRt.workflow._searchZoneFromUc(module.split("/")[0])
      }
    }
    this.navigate(zone, module, type, trigger);
  },

  checkMaximumTabs: function() {
    var isMaximum = false;
    var numTabs = objs.appRt.workflow.attributes.tabstotal;
    if (numTabs >= this.MAX_TABS) {
      var usecaseGoingTo = Backbone.history.getFragment().split("/")[3];
      var zoneGoingTo = Backbone.history.getFragment().split("/")[1];
      var currentAddress = objs.appRt.workflow.get("uri").split("/")[3];
      if (usecaseGoingTo == currentAddress) {
        objs.appRt.navigate(objs.appRt.workflow.previous("uri"), { "trigger": false });
        var attributes = {};
        attributes["uri"] = objs.appRt.workflow.previous("uri");
        attributes["selectedTab-" + zoneGoingTo] = objs.appRt.workflow.previous("selectedTab-" + zoneGoingTo);
        objs.appRt.workflow.set(attributes, { silent: true });
      } else {
        objs.appRt.navigate(objs.appRt.workflow.get("uri"), { "trigger": false });
      }

      MSGS.showWarning(i18n.t('common:tabs_maximum_size', { "0": this.MAX_TABS }));
      isMaximum = true;
    }
    return isMaximum;
  }

};
