import { BaseModel } from '../../../../../../core/models/base.model.js';

import { i18n } from '../../../../../../i18n.js';
import { STR } from '../../../../../../utils/str.js';

// Model of the Typologies Des Domaines : code, libelle, type gestion, regroupement, evenement, genre
export var TypologiesDomaineModel = BaseModel.extend({

  /**
   * URL root to use in every call to webservices
   */
  urlRoot: Configuration.restRoot + "/rest/activite/typodom",

  /**
   * Default attribute values of the model
   */
  defaults: {
    code: "",
    libelle: "",
    //TODO include field typegestion
    //	     typegestion : {
    //	    	 code: "",
    //	    	 libelle: ""
    //	    	 },
    regroupement: "",
    evenement: "",
    genre: {
      code: "M",
      libelle: "Masculín"
    },
    chevauchement: true
  },

  /**
   * Constructor
   * Model for the  Typologies Des Domaines
   */
  initialize: function() {
    var isOK = true;
  },
  /**
   * This method is executed when we make a call to the webservice to do post or put and when we change the attributes
   * values of the model
   */
  validate: function(attrs) {
    var errors = {};
    if (STR.isBlank(attrs.code)) {
      errors.code = i18n.t('common:required', { "0": i18n.t('typodom.code') });
    }

    if (STR.isBlank(attrs.libelle)) {
      errors.libelle = i18n.t('common:required', { "0": i18n.t('typodom.libelle') });
    }

    if (STR.isBlank(attrs.regroupement)) {
      errors.regroupement = i18n.t('common:required', { "0": i18n.t('typodom.regroupement') });
    }

    if (STR.isBlank(attrs.evenement)) {
      errors.evenement = i18n.t('common:required', { "0": i18n.t('typodom.evenement') });
    }

    if (STR.isBlank(attrs.genre) || (attrs.genre.code != "M" && attrs.genre.code != "F")) {

      errors.genre = i18n.t('common:required', { "0": i18n.t('typodom.genre') });
    }

    if (!_.isEmpty(errors)) {
      var errorMap = {
        errorValidation: STR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
  },

  /**
   * This method is executed when we receive a response after a successfull call to a webservice
   */
  parse: function(response) {
    if (response.code !== undefined) {
      this.id = response.code;
    }
    return response;
  }
});