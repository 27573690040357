import { FormView } from './form.view.js';
import { STR } from '../../utils/str.js';
import { TYPE } from '../../tda/tda.js';

export var EditedRowView = FormView.extend({
  /** Used to check like instanceof */
  isEditedRowView: true,

  _getModel: function() {
    return this.model;
  },

  _enablePk: function(enabled) {
    this.pkSelectors = this.pks;
    FormView.prototype._enablePk.call(this, enabled);
  },

  _getFieldset: function() {
    return this.$el;
  },

  /*
   * When an input in a editedRow has no tooltip,
   * it is associated to a corresponding column text in order to meet accessibility criterias.
   */
  _linkColumnNamesToInputs: function(jQueryRootSelector, tableid) {

    jQueryRootSelector.find(":input").each(function(index, element) {
      var domEl = $(element);
      var name = domEl.attr("class").split(" ")[0];

      if (STR.isBlank(element.title) && element.type !== "button") { //Associate to column text if there is no title for this input
        var classnameSelector = name.replace(/\./g, "\\.");
        var newid = _.uniqueId(name + "_uid_");
        var columnNumber = null;
        var row = $(jQueryRootSelector.closest("tr"));
        //Find out number of column
        $(row).children("td").each(function(a_index, el) {
          var index = a_index;

          if ($(el).find($(element)).length > 0) {
            index = $(jQueryRootSelector.closest("#" + tableid)).find(".phx-grid-header-scroll .phx-tristate-box").length > 0 ? index + 1 : index;
            columnNumber = TYPE.LONG.format(index, "&&");
          }
        });

        //Add new id for the input
        if (element.type === "select-one") {
          jQueryRootSelector.find("select." + classnameSelector).attr("id", newid).attr("aria-describedby", newid + "-error");
          var currentTitleEl = $(jQueryRootSelector.closest("#" + tableid)).find(" .phx-grid-header-scroll .phx-th" + columnNumber + " div").children().first();
          var titleText = !STR.isBlank($(element).parent().attr("title")) ? $(element).parent().attr("title") : $(currentTitleEl).text();
          $(jQueryRootSelector.closest("#" + tableid)).find(".phx-grid-header-scroll .phx-th" + columnNumber + " div").children().first().remove();
          $($(jQueryRootSelector.closest("#" + tableid)).find(".phx-grid-header-scroll .phx-th" + columnNumber + " div")[0]).prepend($("<label class='WCAG_table_title' for='" + newid + "' style=\"display:inline-block;\">" + titleText + "</label>"));

        } else {
          // other fields case
          switch (domEl.attr("type")) {
            case "text":
              var oldId = domEl.attr("id");
              if (!STR.isBlank(oldId)) {
                newid = oldId;
              }
            case "checkbox":
              jQueryRootSelector.find("input." + classnameSelector).attr("id", newid).attr("aria-describedby", newid + "-error");
              break;
            default:
              break;
          }

          var currentTitleEl = $(jQueryRootSelector.closest("#" + tableid)).find(" .phx-grid-header-scroll .phx-th" + columnNumber + " div").children().first();
          var titleText = $(currentTitleEl).text();
          $(jQueryRootSelector.closest("#" + tableid)).find(".phx-grid-header-scroll .phx-th" + columnNumber + " div").children().first().remove();
          $($(jQueryRootSelector.closest("#" + tableid)).find(".phx-grid-header-scroll .phx-th" + columnNumber + " div")[0]).prepend($("<label class='WCAG_table_title' for='" + newid + "' style=\"display:inline-block;\">" + titleText + "</label>"));
        }
        //Substitute column span title by a label with for=inputId

        //required
        if ($(element).parents("td:first").find("[for]").hasClass("required") === true) {
          $(element).attr("aria-required", true);
        }
      }

    });
  },
  /*
   * When a row has finished editing it transforms column title from label to span
   */
  _unlinkColumnNamesToInputs: function(jQueryRootSelector) {

    //Select each column title with label and transform it into a span
    jQueryRootSelector.find(".phx-grid-header-scroll tr th").each(function(index, element) {
      var titleLabel = $(element).find("label.WCAG_table_title");

      if (titleLabel && titleLabel.length > 0) {
        var currentTitle = $(element).text();
        $(element).find("div label.WCAG_table_title").remove();
        $($(element).find("div")[0]).prepend($("<span class='title' style=\"display:inline-block;\">" + currentTitle + "</label>"));
      }

    });
  }
  //Discomment after accesibility revision.
  //_mapModelToForm : function(fieldset, model, renderers){
  //	FormView.prototype._mapModelToForm.call(this,fieldset, model, renderers);

  //	this._linkColumnNamesToInputs(this.$el,this.parentId);
  //}

});