import { BaseModel } from 'core/models/base.model.js';
import { STR } from '../../utils/str.js';

export var ThemeRollConfigModel = BaseModel.extend({

  habilitationV: "N",
  habilitationG: "PER_PERSOGEN.M",
  usecase: "themeroll",

  urlRoot: Configuration.restRoot + "/rest/application/preferences",

  defaults: {
    "preferences": [{
      "varchar5": "",
      "varchar3": "",
      "varchar4": "",
      "varchar1": "", //Theme name
      "code": "AP_THEME",
      "varchar2": "",
      "varint3": null,
      "varint2": null,
      "varint1": null,
      "varint5": null,
      "varint4": null,
      "vardat5": "",
      "vardat4": "",
      "vardat3": "",
      "vardat2": "",
      "vardat1": ""
    }]
  },

  parse: function(response) {
    if (STR.isBlank(response.id) && !STR.isBlank(response.code)) {
      response.id = response.code;
    }

    return response;
  }
});