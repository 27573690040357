import { ProfileModel } from './profile.model.js';
import { STR } from '../../../../utils/str.js';

/**
 * Collection of profiles
 */
export var ProfilesColl = Backbone.Collection.extend({

  model: ProfileModel,

  profilesNumber: function() {
    var result = 0;
    if (this.length > 0) {
      for (var i = 0; i < this.length; i++) {
        result += this.at(i).profilesNumber();
      }
    }
    return result;
  },

  getProfile: function(code) {
    var element = this.get(code);
    if (STR.isBlank(element)) {
      for (var i = 0; i < this.length && STR.isBlank(element); i++) {
        var profil = this.at(i);
        element = profil.getProfile(code);
      }
    }
    return element;
  }
});