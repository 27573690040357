import { BaseModel } from 'core/models/base.model.js';
import { STR } from '../../utils/str.js';

/**
 * Le modèle du contexte actif (true) ou inactif (false).
 */
export var ContexteActifModel = BaseModel.extend({
  habilitationV: "N",
  habilitationG: "N",
  usecase: "app",

  url: function() {
    var l_url = "/rest/typeevt/contexte/actif/";

    if (STR.isBlank(this.contexteActif)) {
      throw new Error("Context actif not supported");
    }
    if (STR.isBlank(this.typeEvt)) {
      throw new Error("Type Event not supported");
    }
    l_url += encodeURIComponent(this.contexteActif + "," + this.typeEvt);
    if (!STR.isBlank(this.typologie)) {
      l_url += encodeURIComponent("," + this.typologie);
    }

    return Configuration.restRoot + l_url;
  },

  defaults: {
    active: false,
  },

  initialize: function(model, options) {
    this.contexteActif = null;
    this.typeEvt = null;
    this.typologie = null;
    if (options) {
      if (!STR.isBlank(options.contexteActif)) {
        this.contexteActif = options.contexteActif;
      }
      if (!STR.isBlank(options.typeEvt)) {
        this.typeEvt = options.typeEvt;
      }
      if (!STR.isBlank(options.typologie)) {
        this.typologie = options.typologie;
      }
    }
  }
});
