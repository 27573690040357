import { DataGridRowView } from '../data_grid/dataGridRow.view.js';
import { EditableGridView } from '../editablegrid/editableGrid.view.js';
import { STR } from '../../../utils/str.js';
import TPL_common_table_cell_multiselectaction_tristate from './table_cell_multiselectaction_tristate.tpl.html';

/**
 * View underlying a row form the Tree type DataGrid
 */
export var EditableGroupeGridRowView = DataGridRowView.extend({

  dummy: "",

  /**
   * Name of the html tag which involves the view
   */
  tagName: "tr",

  /**
   * Name of the container class of the view
   */
  className: "ui-grid-row",

  /**
   * Indicator to know if the row is edited or not
   */
  edited: false,

  /**
   * Event launched when the expand icon is clicked
   *
   */
  /**
   * Event launched when the row is clicked
   *
   */
  /**
   * Event launched when the row is clicked twice
   *
   */
  /**
   * Event launched when the expand icon is clicked
   *
   */
  events: {
    "click .goupetbl_expbtn": "_clickTreeTableBtn",
    "click .phx-tristate-box": "_selectCheckBoxJours"
  },

  initialize: function(options) {
    DataGridRowView.prototype.initialize.call(this, options);

    this.model.off("destroy");
    this.model.off("row:select");
    this.model.off("row:unselect");
    this.model.off("row:edit");
    this.model.off("row:unedit");
    this.model.off("row:checked");
    this.model.off("row:unchecked");
    this.checkGroup = false; //pour indiquer que c'est un groupe spécial de regroupement (avec sous-groupes) ou non
    if (options && options.checkGroup) {
      this.checkGroup = options.checkGroup;
    }
    this.filterGroupMultiSelect = "";
    this.listenTo(this.dataGrid.model, "toggle:column", this._toggleColumn);
  },
  /**
   * Method which expands or not the row of the table grid when we click the related button.
   */
  _clickTreeTableBtn: function(event) {
    this.model.trigger("treetbl_exp", this.model);
    event.stopPropagation();
  },

  /**
   * Paints the row view of the tree grid
   */
  render: function() {
    var rowThis = $(this.el);
    rowThis.attr("cid", this.cid);
    var isSelected = rowThis.hasClass("phx-selected");
    if (this.dataGrid.selectable) {
      rowThis.css("cursor", "pointer");
    }
    rowThis.empty();

    rowThis.attr("cid", this.cid);
    var groupeByColumnValue = this.dataGrid._groupeByColumnValue(this.model);
    rowThis.attr("groupe-header", groupeByColumnValue);
    if (this.edited === true &&
      (this.dataGrid instanceof EditableGridView)) {
      this._renderForm();
    } else {
      _.each(this.dataGrid.currentVue.columns, function(column, index) {
        var cell = null,
          cellCheck = null;

        if (column.property === this.dataGrid.model.groupeByColumn) {
          if (this.checkGroup && this.dataGrid.currentVue._columns["phx-multiselect"] && this.dataGrid.currentVue._columns["phx-multiselect"].visible) {
            cellCheck = this._addActionCell(column, index, TPL_common_table_cell_multiselectaction_tristate);
            if (cellCheck) {
              cellCheck.el = cellCheck.el.innerHTML; //remove <td>
            }
          }
          cell = this._addCell(column, index);

          var marginLeft = this.model.level * 10;
          var span = null;
          if (STR.isBlank(this.model.isExpanded)) {
            this.model.isExpanded = true;
          }
          if (this.model.isExpanded === true) {
            span = $("<div style='position:relative;display:inline-block;width:16px;'><span class='goupetbl_expbtn ui-corner-all ui-icon ui-icon-triangle-1-s' style='position:absolute;top:-13px;'></span></div>");
          } else {
            span = $("<div style='position:relative;display:inline-block;width:16px;'><span class='goupetbl_expbtn ui-corner-all  ui-icon ui-icon-triangle-1-e' style='position:absolute;top:-13px;'></span></div>");
          }
          if (this.checkGroup) {
            $(cell.el).prepend(cellCheck.el);
          }
          $(cell.el).prepend(span);
          $(cell.el).css("height", "17px");
          $(cell.el).css("padding-left", marginLeft + "px");
          $(cell.el).css("font-weight", "bold");

          var keys = _.keys(this.dataGrid.currentVue._columns);

          // Get the number of visible columns
          var visibles = _.filter(keys, function(columKey) {
            var visible = this.dataGrid.currentVue._columns[columKey].visible;
            if (columKey.indexOf("phx-last") >= 0) {
              visible = false;
            }
            return visible;
          }, this);

          $(cell.el).attr("colspan", visibles.length);

          rowThis.prepend(cell.el);
          this.dataGrid.currentVue._columns[this.dataGrid.model.groupeByColumn].cells.push(cell);
        }

      }, this);

      if (this.dataGrid.currentVue._columns["phx-multiselect"]) {
        this._showMultiSelection();
      }

      if (isSelected) {
        this._selectRow();
      }
    }

    return this;
  },

  _toggleColumn: function() {

    if (!STR.isBlank(this.dataGrid.model.groupeByColumn)) {
      var keys = _.keys(this.dataGrid.currentVue._columns);

      // Get the number of visible columns
      var visibles = _.filter(keys, function(columKey) {
        var visible = this.dataGrid.currentVue._columns[columKey].visible;
        if (columKey.indexOf("phx-last") >= 0) {
          visible = false;
        }
        return visible;
      }, this);

      var cells = this.dataGrid.currentVue._columns[this.dataGrid.model.groupeByColumn].cells;

      _.forEach(cells, function(cell) {
        if (cell.$el.is('[colspan]')) {
          cell.$el.attr("colspan", visibles.length);
        }
      }, this);
    }
  },

  /**
   * Perform a Selection/Unselection on group rows in a multiselectable grid.
   */
  _selectCheckBoxJours: function(event) {
    if (this.checkGroup) {
      var selected = this.dataGrid.$el.has('.ui-grid-row.phx-selected');
      //only if exist checkGroup
      if ($(event.currentTarget).hasClass("phx-tristate-unchecked") && selected.length > 0) {
        var SelectGroupeCompatible = this.dataGrid.selectAllGroupRows(this.model);
        if (SelectGroupeCompatible) {
          this._setTristateGroupChecked();
          this._changeVisibilityButtonsForCheckJours(true);
        }
      } else {
        this.dataGrid.clearSelectedGroupRows(this.model);
        this._setTristateGroupUnchecked();
        this._changeVisibilityButtonsForCheckJours();
      }

      this.dataGrid.trigger("end:selectAllGroupRows", this);
    }
  },

  _changeVisibilityButtonsForCheckJours: function(valor) {
    if (valor) {
      this.dataGrid.model.btnBarModel.trigger("hide:delete");
      this.dataGrid.model.btnBarModel.trigger("show:copy");
      this.dataGrid.$el.find("input[type=checkbox].phx-bistate-box").attr("disabled", true);
    } else if (_.filter(this.dataGrid.model.multiselectColl.models, function(model) { return !model.hasOwnProperty('isChecked'); }).length === 0) {
      this.dataGrid.model.btnBarModel.trigger("show:delete");
      this.dataGrid.model.btnBarModel.trigger("hide:copy");
      this.dataGrid.$el.find("input[type=checkbox].phx-bistate-box").attr("disabled", false);
    }
  },

  /**
   * Manages when click on the header group checkbox on multiselection grid.
   */
  _manageGroupChecking: function() {
    var groupCode = this.model.code;
    if (this.dataGrid.multiselection && this.checkGroup && groupCode) {
      var l_filter = _.filter(this.dataGrid.model.multiselectColl.models, function(model) {
        if (model.code === groupCode) {
          return true;
        }
        return false;
      });
      var l_lonmulti = l_filter.length;

      if (l_lonmulti === 0) {
        this._setTristateGroupUnchecked();
      } else {
        if (this.dataGrid.model && this.dataGrid.model.coll) {
          var l_where = _.filter(this.dataGrid.model.coll.models, function(model) {
            if (model.code === groupCode) {
              return true;
            }
            return false;
          });

          var l_lon = l_where.length;

          if (l_lonmulti !== l_lon) {
            this._setTristateGroupUndefined();
          } else {
            this._setTristateGroupChecked();
          }
        } else {
          this._setTristateGroupUnchecked();
        }
      }
    }
  },

  /**
   * Sets the Tristate checkbox to Checked state.
   */
  _setTristateGroupChecked: function() {
    var jQtarget = this.$el.find(".phx-tristate-box");
    jQtarget.html('<span class="ui-icon ui-icon-closethick"></span>');
    jQtarget.addClass("phx-tristate-checked");
    jQtarget.removeClass("phx-tristate-unchecked");
    jQtarget.removeClass("phx-tristate-undefined");
  },

  /**
   * Sets the Tristate checkbox to Unchecked state.
   */
  _setTristateGroupUnchecked: function() {
    var jQtarget = this.$el.find(".phx-tristate-box");
    jQtarget.html('');
    jQtarget.removeClass("phx-tristate-checked");
    jQtarget.addClass("phx-tristate-unchecked");
    jQtarget.removeClass("phx-tristate-undefined");
  },

  /**
   * Sets the Tristate checkbox to Undefined state.
   */
  _setTristateGroupUndefined: function() {
    var jQtarget = this.$el.find(".phx-tristate-box");
    jQtarget.html('<span class="ui-icon ui-icon-minusthick"></span>');
    jQtarget.removeClass("phx-tristate-checked");
    jQtarget.removeClass("phx-tristate-unchecked");
    jQtarget.addClass("phx-tristate-undefined");
  }
});