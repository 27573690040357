import { DataGridActionCellView } from './dataGridActionCell.view.js';
import { DataGridCellView } from './dataGridCell.view.js';
import { LOG } from '../../../utils/log.js';
import { STR } from '../../../utils/str.js';
import TPL_common_table_cell_editaction from '../basegrid/table_cell_editaction.tpl.html';
import TPL_common_table_cell_multiselectaction from '../basegrid/table_cell_multiselectaction.tpl.html';
import TPL_common_table_cell_revertaction from '../basegrid/table_cell_revertaction.tpl.html';
import TPL_common_table_cell_saverevertaction from '../basegrid/table_cell_saverevertaction.tpl.html';

export var DataGridRowView = Backbone.View.extend({

  dummy: "",

  tagName: "tr",
  className: "ui-grid-row",

  edited: false,

  /**
   * Event triggered when select the row.
   */
  /**
   * Event triggered when unselect the row.
   */
  /**
   * Event triggered when the row becomes editable.
   */
  /**
   * Event triggered when the row becomes to read only.
   */
  /**
   * Event triggered when ckeck the row on multiselection row.
   */
  /**
   * Event triggered when unckeck the row on multiselection row.
   */

  /**
   * Constructor
   * View underlying a row of generic data grid
   */
  initialize: function(options) {
    /**
     * The instance of the editable Grid
     */
    this.dataGrid = null;
    /**
     * Columns that are being showed by the edition.
     */
    this.showedColumns = [];
    /**
     * Columns that are being blocked to avoid edition.
     */
    this.blokedColumns = [];

    if (this.model) {
      this.model.off("destroy");
      this.model.off("row:select");
      this.model.off("row:unselect");
      this.model.off("row:edit");
      this.model.off("row:unedit");
      this.model.off("row:checked");
      this.model.off("row:unchecked");
      this.model.off("row:notSelectable");
      this.model.off("row:Selectable");
      this.model.off("row:supprimed");

      this.model.on("destroy", this.close, this);
      this.model.on("row:select", this._selectRow, this);
      this.model.on("row:unselect", this._unselectRow, this);
      this.model.on("row:edit", this._editRow, this);
      this.model.on("row:unedit", this._uneditRow, this);
      this.model.on("row:checked", this._showMultiSelection, this);
      this.model.on("row:unchecked", this._showMultiSelection, this);
      this.model.on("cell:select", this._selectCell, this);
      this.model.on("row:notSelectable", this._notSelectable, this);
      this.model.on("row:Selectable", this._selectable, this);
      this.model.on("row:supprimed", this._supprimed, this);

    }
    if (!options.dataGrid) {
      throw Error("You must use a DataGridRowView just inside a DataGridView");
    }
    this.dataGrid = options.dataGrid;

    if (!(this.dataGrid.isEditableGridView === true) && this.model) {
      this.listenTo(this.model, "change", this.render);
    }

  },

  /**
   * Event triggered when permon a click inside the view.
   */
  /**
   * Event triggered when permon a double click inside the view.
   */
  events: {
    "click": "_rowListener",
    "dblclick": "_rowListener",
    "keydown": "keyEvent"
  },

  /**
   * Method which manages the row click or double click in order to select it or edit it.
   */
  _rowListener: function(event) {
    LOG.debug("event.type=" + event.type);
    var className = event.target.className.split(" ")[0];

    if ($(event.currentTarget) && ($(event.currentTarget).hasClass("phx-notSelectable") || $(event.currentTarget).hasClass("phx-supprimed"))) {
      return;
    }

    if (className === "phx-cell-save-action") {
      this.dataGrid.model.trigger("action:save");
    }

    if (className === "phx-cell-revert-action") {
      this.dblclick = 0;
      if (STR.isBlank(this.model.id)) {
        this.dataGrid.model.trigger("action:revert", false);
        event.stopPropagation();
        return;
      } else {
        this.dataGrid.model.trigger("action:revert", true);
        event.stopPropagation();
        return;
      }
    }

    if (className === "phx-bistate-box") {
      //put the focus to the checkbox due to this function does a return and the click code is not executed.
      $(event.currentTarget).parents(".phx-grid:first").find(".grid-cell-focus").attr("tabindex", "-1").removeClass("grid-cell-focus");
      $(event.target).attr("tabindex", "0").addClass("grid-cell-focus").focus();
      this._toggleMultiSelection();
      event.stopPropagation();
      return;
    }

    if ($(event.target).hasClass("phx-grid-not-selectable-cell") || this.$el.find($(event.target).closest(".phx-grid-not-selectable-container-in-cell")).length >= 1) {
      return;
    }

    //If the row is being edited, no click event must be propagated
    if (this.edited) {
      event.stopPropagation();
      return;
    }

    var isCheckbox = $(event.target).is(":checkbox");
    var isLink = $(event.target).is("a");
    var isSelect = $(event.target).is("select");
    var isOption = $(event.target).is("option");
    var isSelected = $(this.el).hasClass("phx-selected");
    var self = this;

    if (this.dataGrid.selectable) {
      // Trigger the custom even select to be interpreted by the parent view
      if (event.type === "click") {

        //Accessibility. select the correct cell when click a row
        var currentCell = $(event.target).is("td") ? $(event.target) : $(event.target).parents("td:first");
        //set the cell clicked to do a correct focus
        this.dataGrid.cellSelected = currentCell.parents("tr:first").children().index(currentCell);

        var editActionClicked = false;
        // if we click on the edit icon in the row then we trigger a dblclick event to enter in edition mode
        if (className === "phx-cell-edit-action") {
          if (this.dataGrid.isEditableGridView === true && this.dataGrid.editable && this.dataGrid.habilitationUpdate) {
            //this.dblclick = 2;
            editActionClicked = true;
            this.model.trigger("row:dblclick", this.model);
            event.stopPropagation();
          }
        }
        // If no double click then simple click
        if (!editActionClicked) {

          setTimeout(function() {
            var double = self.dblclick;
            if (double > 0) {
              self.dblclick = double - 1;
              return false;
            } else {
              self.dataGrid.model.lastCode = null;

              // case clicked item is a checkbos or link
              if (!isSelected && (isCheckbox || isLink || isSelect)) {
                self.model.trigger("row:click", self.model);
              }

              if (!(isCheckbox || isLink || isSelect || isOption)) {
                self.model.trigger("row:click", self.model);
              }
            }
          }, 300);

        }
      } else if (event.type === "dblclick") {
        if ($(event.target).is("td")) {
          this.model.collection.editModeCellSelected = $(event.currentTarget).children().index(event.target);
        }

        if (this.dataGrid.enableSelectionOnDblclick === true) {
          this.dblclick = 2;
          this.model.trigger("row:dblclick", this.model);
          event.stopPropagation();
        } else {

          if (this.dataGrid.isEditableGridView === true && this.dataGrid.editable && this.dataGrid.habilitationUpdate) {

            if (STR.isBlank(this.dataGrid.viewRow.canEdit) && STR.isBlank(this.dataGrid.viewRow.canEditCallback)) {
              this.dblclick = 2;
              this.model.trigger("row:dblclick", this.model);
              event.stopPropagation();
            }
            // synchronized method to know if a row is editable
            if (!STR.isBlank(this.dataGrid.viewRow.canEdit) && this.dataGrid.viewRow.canEdit(this.model) === true) {
              this.dblclick = 2;
              this.model.trigger("row:dblclick", this.model);
              event.stopPropagation();
            }
            // asynchronized method to know if a row is editable
            if (!STR.isBlank(this.dataGrid.viewRow.canEditCallback)) {
              self.dblclick = 2;
              event.stopPropagation();
              this.dataGrid.viewRow.canEditCallback(this.model, function(canEdit) {
                if (canEdit) {
                  self.model.trigger("row:dblclick", self.model);
                }
              });
            }
          }
        }
      }
    }
  },

  keyEvent: function(e) {
    var ret = true;
    //accessibility
    if (e.keyCode === 32 && e.shiftKey === true) { //space && shift
      this.model.trigger("row:click", this.model);
      ret = false;
    }
    return ret;
  },

  _visible: function(element) {
    if (element && element.width() > 0 &&
      element.css("opacity") > 0 &&
      element.css("visibility") !== "hidden" &&
      element.css("display") !== "none") {
      return true;
    }
    return false;
  },

  /**
   * Manages the row selection.
   */
  _selectRow: function() {
    if (!$(this.el).hasClass("notSelectable")) {
      var visibleRow = this.$el.parents(".ui-grid-table").is(":visible");

      if (visibleRow === false) { //when false, another checking(four IE)
        visibleRow = this._visible(this.dataGrid.$el);
      }
      if (visibleRow === false) {
        this.$el.parents(".ui-grid-table").show();
      }
      $(this.el).addClass("phx-selected");
      $(this.el).children("td").addClass("ui-state-active");
      $(this.el).find("div.phx-bistate-box").addClass("ui-state-active");
      $(this.el).focus();
      this.hideRequieredColumns();

      if (!STR.isBlank(this.dataGrid.cellSelected)) {
        //select the next row when the table has pagination
        this.dataGrid._selectAdjacentCell($(this.el).find("td"), 1, this.dataGrid.cellSelected - 1, "td");
      } else if (this.$el.find(".grid-cell-focus").length === 0 && STR.isBlank(this.dataGrid.cellSelected)) {
        //when the page is loaded for first time this code selet the first cell of the selected column. No focus

        this.$el.parents(".phx-grid:first").find(".grid-cell-focus").attr("tabindex", "-1").removeClass("grid-cell-focus");

        var nextCellPosition = -1;
        var nextCell = null;
        var container = this.$el.children();

        //obtain the new cell
        if (container && container.length > 0) {
          do {
            nextCellPosition = nextCellPosition + 1;
            nextCell = !STR.isBlank(container[nextCellPosition]) ? container[nextCellPosition] : null;
          } while ((STR.isBlank(nextCell) && nextCellPosition < container.length || (!STR.isBlank(nextCell) && !$(nextCell).is(":visible"))));
        }

        if (!STR.isBlank(nextCell) && $(nextCell).is(":visible")) {
          this.dataGrid.cellSelected = 0;
          if ($(nextCell).find(":input").is(":input")) {
            $(nextCell).find(":input").attr({ "tabindex": "0" }).addClass("grid-cell-focus");
          } else {
            $(nextCell).attr({ "tabindex": "0", "aria-selected": "true" }).addClass("grid-cell-focus");
          }
        }
      }

      //Check permissions
      if (this.dataGrid.isEditableGridView === true &&
        !STR.isBlank(this.dataGrid.viewRow.canRemove) && this.dataGrid.viewRow.canRemove(this.model) === false) {
        this.dataGrid.disableDeleteButton(true);
      }

      if (visibleRow === false) {
        this.$el.parents(".ui-grid-table").hide();
      }
    }
  },

  /**
   * Manages the row unselection.
   */
  _unselectRow: function() {
    $(this.el).children("td").removeClass("ui-state-active");
    $(this.el).find("div.phx-bistate-box").removeClass("ui-state-active");
    $(this.el).removeClass("phx-selected");
    this.hideRequieredColumns();
    if (this.dataGrid.isEditableGridView === true) {
      this.dataGrid.disableDeleteButton(false);
      if (!STR.isBlank(this.dataGrid.viewRow)) {
        this.dataGrid.viewRow._unlinkColumnNamesToInputs(this.dataGrid.$el, this.dataGrid.id);
      }
    }

  },

  _notSelectable: function() {
    this._unselectRow();
    if (!$(this.el).hasClass("phx-notSelectable")) {
      $(this.el).addClass("phx-notSelectable");
    }
  },

  _selectable: function() {
    if ($(this.el).hasClass("phx-notSelectable")) {
      $(this.el).removeClass("phx-notSelectable");
    }
  },

  _supprimed: function() {
    this._unselectRow();
    if (!$(this.el).hasClass("phx-supprimed")) {
      $(this.el).addClass("phx-supprimed");
    }
  },

  /**
   * Make the row editable.
   */
  _editRow: function() {
    this.edited = true;
    this.model.off("invalid");
    this.model.on("invalid", this._showValidationErrors, this);
    this.render();
    if (!STR.isBlank(this.model) && this.model.isNew()) {
      this.showRequieredColumns();
    }
    this.dataGrid.viewRow._linkColumnNamesToInputs(this.$el, this.dataGrid.id);
    $(this.dataGrid.el).find(".phx-bistate-box").attr("disabled", true);
    this.model.store(); // Store old attributes
  },

  /**
   * Make the row uneditable.
   */
  _uneditRow: function() {
    this.edited = false;
    $(this.dataGrid.el).find(".phx-bistate-box").removeAttr("disabled");
    $(this.el).css("vertical-align", "");
    this._unselectRow();
    this.render();

    this.hideRequieredColumns();
  },

  /**
   * Enable/Disable the multiselection
   */
  _toggleMultiSelection: function() {
    this.model.isChecked = !this._isChecked(this.model.id);

    if (this.model.isChecked) {
      this.dataGrid.model.multiselectColl.add(this.model);
      if (!STR.isBlank(this.filterSelection)) {
        this.model.multiselectColl.models = this.model.multiselectColl.where(this.filterMultiSelectColl);
      } else if (this.filterSelectionFunction) {
        var self = this;
        this.model.multiselectColl.models = _.filter(this.model.multiselectColl.models, function(model) {
          return self.filterSelectionFunction(model);
        })
      }
      if (STR.isBlank(this.dataGrid.model.get("value"))) {
        // if no rows are selected and a row is checked for multiselection, we select the current row
        this.model.trigger("row:click", this.model);
      }
    } else {
      this.dataGrid.model.multiselectColl.remove(this.model);
    }

    this._showMultiSelection();
  },

  /**
   * Check/Uncheck the row for multiselection
   */
  _showMultiSelection: function() {
    var checked = this._isChecked(this.model.id);
    var jQtarget = this.$el.find(".phx-bistate-box");
    jQtarget.prop("checked", checked);
  },

  /**
   * Look if the row is checked in multiselection
   */
  _isChecked: function(id) {
    var item = this.dataGrid.model.multiselectColl.get(id);
    if (!STR.isBlank(item)) {
      return true;
    }
    return false;
  },

  /**
   * Show validation Error for the row form, calling the viewRow generic function.
   */
  _showValidationErrors: function(model, errors) {
    $(this.el).css("vertical-align", "top");
    this.dataGrid.viewRow._showValidationErrors(model, errors);
  },

  /**
   * Grid Row render Function
   */
  render: function() {
    var rowThis = $(this.el);
    rowThis.attr("cid", this.cid);
    var isSelected = rowThis.hasClass("phx-selected");
    if (this.dataGrid.selectable) {
      rowThis.css("cursor", "pointer");
    }
    rowThis.empty();
    if (this.edited === true &&
      (this.dataGrid.isEditableGridView === true)) {
      this._renderForm();
    } else {
      _.each(this.dataGrid.currentVue.columns, function(column, index) {
        var cell = null;
        switch (column.property) {
          case "phx-multiselect":
            if (this.dataGrid.currentVue._columns["phx-multiselect"] && this.dataGrid.currentVue._columns["phx-multiselect"].visible) {
              cell = this._addActionCell(column, index, TPL_common_table_cell_multiselectaction);
              //						$(cell.el).addClass("phx-cell-multiselect-action");
            }
            break;
          case "phx-action":
            if (this.dataGrid.currentVue._columns["phx-action"] && this.dataGrid.currentVue._columns["phx-action"].visible) {
              cell = this._addActionCell(column, index, TPL_common_table_cell_editaction);
            }
            break;
          case "phx-last":
            break;

          default:
            cell = this._addCell(column, index);
        }

        if (!STR.isBlank(cell)) {
          rowThis.append(cell.el);
          this.dataGrid.currentVue._columns[column.property].cells.push(cell);
          if (!this.dataGrid.currentVue._columns[column.property].visible) {
            $(cell.el).hide();
          }
        }

        //if this render is called after exit of an editable mode its necessary to set all inputs of the row with tabindex -1
        if (!STR.isBlank(cell) && cell.$el.children().length > 0) {
          cell.$el.find("a").is("a") ? cell.$el.find("a").attr("tabindex", "-1") : null;
          cell.$el.find("select").is("select") ? cell.$el.find("select").attr("tabindex", "-1") : null;
          cell.$el.find(":input").is(":input") ? cell.$el.find("input").attr("tabindex", "-1") : null;
          cell.$el.find("button").is("button") ? cell.$el.find("button").attr("tabindex", "-1") : null;
        }

      }, this);

      if (this.dataGrid.currentVue._columns["phx-multiselect"]) {
        this._showMultiSelection();
      }

      if (isSelected) {
        this._selectRow();
      }
    }

    $(this.el).attr({ "role": "row" });
    return this;
  },

  /**
   * Adds the cell for the column with the "Save" "Revert" actions.
   */
  _addActionCell: function(column, index, action) {
    var cell;
    if (!STR.isBlank(this.dataGrid.filterSelection) && !this.model.attributes[this.dataGrid.filterSelection]) {
      cell = new DataGridCellView({ dataGridRow: this }).render();
      cell.setText("");
    } else {
      if (this.dataGrid.filterSelectionFunction && !this.dataGrid.filterSelectionFunction(this.model)) {
        cell = new DataGridCellView({ dataGridRow: this }).render();
        cell.setText("");
      } else {
        var cellView = new DataGridActionCellView({ dataGridRow: this, action: action });
        cell = cellView.render();

        $(cell.el).addClass("phx-td" + index);
      }
    }
    return cell;
  },

  /**
   * Create the cells views to be rendered on the row.
   */
  _addCell: function(column, index) {
    var property = column.property;
    var alignment = (this.dataGrid.currentVue._columnsAlign && this.dataGrid.currentVue._columnsAlign[property]) ? this.dataGrid.currentVue._columnsAlign[property] : "left";
    var value = null;
    var cellRender = null;
    //If cellrenderer is definied for one view, use it. If not, use table's cellrenderer.
    if (!STR.isBlank(this.dataGrid.currentVue.cellRenderers) &&
      !STR.isBlank(this.dataGrid.currentVue.cellRenderers[property])) {
      cellRender = this.dataGrid.currentVue.cellRenderers[property];
    } else {
      cellRender = this.dataGrid.cellRenderers[property];
    }
    var cell = new DataGridCellView({ dataGridRow: this }).render();
    if (!STR.isBlank(cellRender)) {
      value = cellRender.call(this, this.model, property, cell);
    } else {
      value = STR.getElValue(this.model, property);
    }
    cell.setText(value);
    $(cell.el).addClass("phx-td" + index).attr("role", "gridcell");

    switch (alignment) {
      case "right":
        $(cell.el).css("text-align", "right");
        break;
      case "center":
        $(cell.el).css("text-align", "center");
        break;
      default:
        $(cell.el).css("text-align", "left");
        break;
    }

    if (column.paddingZero) {
      $(cell.el).css("padding", "0");
    }

    return cell;
  },

  /**
   * Renders the form, auxiliar function used by the view render.
   */
  _renderForm: function() {
    this.dataGrid.viewRow.model = this.model;
    this.dataGrid.viewRow.pks = this.dataGrid.model.propertyPK;
    this.dataGrid.viewRow.setElement($(this.el));
    var self = this;

    var rowContent = this.dataGrid.viewRow.render().el;

    var keys = _.keys(this.dataGrid.currentVue._columns);
    var dataGrid = this.dataGrid;
    var columnsIndex = 0;

    if (dataGrid.multiselection === true) {
      columnsIndex++;
    }
    $(rowContent).children("td").each(function(index, el) {
      $(el).addClass("phx-edited");
      $(el).addClass("phx-td" + columnsIndex);
      dataGrid.currentVue._columns[keys[columnsIndex]].editableCell = $(el);
      if (!dataGrid.currentVue._columns[keys[columnsIndex]].visible && keys[columnsIndex] !== "phx-multiselect") {
        dataGrid.currentVue._columns[keys[columnsIndex]].editableCell.hide();
      }
      var alignment = (dataGrid.currentVue._columnsAlign && dataGrid.currentVue._columnsAlign[keys[columnsIndex]]) ? dataGrid.currentVue._columnsAlign[keys[columnsIndex]] : "left";
      switch (alignment) {
        case "right":
          $(el).css("text-align", "right");
          break;
        case "center":
          $(el).css("text-align", "center");
          break;
        default:
          $(el).css("text-align", columnsIndex);
          break;
      }
      columnsIndex++;
    });
    var actionCell = null;
    if (dataGrid._isRowUpdatable) {
      actionCell = this._addActionCell(dataGrid.currentVue._columns[keys[columnsIndex]], columnsIndex, TPL_common_table_cell_saverevertaction);
    } else {
      actionCell = this._addActionCell(dataGrid.currentVue._columns[keys[columnsIndex]], columnsIndex, TPL_common_table_cell_revertaction);
    }
    if (!STR.isBlank(actionCell)) {
      dataGrid.currentVue._columns[keys[columnsIndex]].cells.push(actionCell);
      $(rowContent).append(actionCell.el);
      $(rowContent).find("td:last").css("padding", "0");
    }

    if (dataGrid.multiselection === true) {
      var cell = this._addActionCell(dataGrid.currentVue._columns["phx-multiselect"], 0, TPL_common_table_cell_multiselectaction);
      $(cell.el).insertBefore($(rowContent).find("td:first"));
      this._showMultiSelection();
    }

    var cellSelected = false;
    if (!STR.isBlank(self.model.collection) && !STR.isBlank(self.model.collection.editModeCellSelected)) {
      var cell = $($(rowContent).children()[this.model.collection.editModeCellSelected]).find("input");
      if ($(cell).is(":input") && !$(cell).is("[readonly]")) {
        $(cell).attr({ "tabindex": "0" }).addClass("grid-cell-focus").focus();
        cellSelected = true;
      }
    }

    if (!cellSelected) {
      // select first input not readonly and not multiselection checkbox
      if (dataGrid.multiselection === true) {
        $(rowContent).find(":input:not([readonly])").eq(1).focus();
      } else {
        $(rowContent).find(":input:not([readonly])").eq(0).focus();
      }
    }
  },

  /**
   * Show the required columns
   */
  showRequieredColumns: function() {
    var rowContent = this.dataGrid.viewRow.el;
    var dataGrid = this.dataGrid;
    var self = this;
    // Show required columns
    $(rowContent).find("label.required").each(function(index, label) {
      var property = $(label).attr("for");
      dataGrid.model.block(property);
      self.blokedColumns.push(property);
      if (!dataGrid.currentVue._columns[property].visible) {
        dataGrid.toggleColumn(property);
        self.showedColumns.push(property);
      }
    });
  },

  /**
   * Hide required columns
   */
  hideRequieredColumns: function() {
    var dataGrid = this.dataGrid;
    _.each(this.showedColumns, function(property) {
      dataGrid.toggleColumn(property);
    }, this);
    _.each(this.blokedColumns, function(property) {
      dataGrid.model.unblock(property);
    }, this);
    this.showedColumns = [];
    this.blokedColumns = [];
  },

  /**
   * Remove the view and his events.
   */
  close: function() {
    this.off();
    this.remove();
  },

  //Accessibility
  _selectCell: function(index) {
    var cell = !STR.isBlank(index) ? $(this.$el.children()[index]) : $(this.$el.children()[0]);

    $(this.el).parent().find(".grid-cell-focus").removeClass("grid-cell-focus").attr({ "tabindex": "-1" });

    if ($(cell).find(":input").is(":input")) {
      $(cell).find(":input").attr({ "tabindex": "0" }).addClass("grid-cell-focus").focus();
    } else {
      $(cell).attr({ "tabindex": "0", "aria-selected": "true" }).addClass("grid-cell-focus").focus();
      LOG.debug("focus the first cell");
    }
  },

  isEditableGridView: function() {
    return false;
  }
});
