import { FormView } from '../../../../core/views/form.view.js';
import { i18n } from '../../../../i18n.js';
import { SelecteurCheminView } from '../../../../core/components/selecteur_chemin/selecteurChemin.view.js';
import TPL_simpleSelectourStructureDialog from "../simpleSelectourStructureDialog.tpl.html"

export var PopupSelectStructureView = FormView.extend({

  className: "populationsmenu",

  initialize: function(options) {
    this.template = TPL_simpleSelectourStructureDialog;
    var self = this;
    /*
     *Recuperar opciones del contexto
     */
    Object.keys(options || {}).forEach(function(key) {
      self[key] = options[key];
    });

    /*
     Instancia del selector
     */
    this.structSelecteurChemin = new SelecteurCheminView({
      titlePopup: this.context.name,
      name: this.context.name, //Datos Recogidos de la structura
      listRenderStyle: this.context.format, //Datos Recogidos de la structura
      fieldWidth: 280,
      height: 700,
      width: 850,
      structid: this.context.code, //Datos Recogidos de la structura
      datedebut: this.context.datedeb, //Datos Recogidos de la structura
      datefin: this.context.datefin, //Datos Recogidos de la structura
      intermediaire: true,
      elementCode: options,
      partial: true,
      modeComplet: true,
      multiselection: false,
      context: this.context
    });

    this.structSelecteurChemin.on("generatedTooltip:selectourChemin", function(data) {
      if (data.multiple === false) {
        self.tooltipDataSelected = data
      }
    }, this);
  },

  events: {
    "click .buttonAppliquer": "_appliquerStruct",
  },

  _appliquerStruct: function() {
    this.parent._clearTooltipStructure();

    if (this.tooltipDataSelected && this.tooltipDataSelected.tooltip) {
      $(this.parent.el).find(".phx-population-menu-header").html(this.structSelecteurChemin.selection.at(0).get("label"));
      $(this.parent.el).find(".phx-population-menu-header").get(0).title = this.tooltipDataSelected.tooltip;
      $(this.parent.el).find(".phx-population-menu-header").tooltip({ content: this.tooltipDataSelected.tooltip });
      var descendenceSelected = this._getPopulationDescendence();
      this.parent._setStructureOnTraversalComponent(this.structSelecteurChemin.selection, descendenceSelected);
    }
    this.parent.dialog[this.context.name].close();
  },

  _getPopulationDescendence: function() {
    return $(".phx-populations-detail-structure-simple input.populationDescendence").prop("checked");
  },

  render: function() {
    var json = {
      i18n: i18n,
      context: this.context
    };
    this.$el.html(this.template(json));
    $(this.el).find(".buttonAppliquer").button();
    $(this.el).find("#selectorEstructura").html(this.structSelecteurChemin.render().el);
    return this;
  }
});
