import { BaseModel } from 'core/models/base.model.js';

import { STR } from '../../../../utils/str.js';

export var ElementLevel3Model = BaseModel.extend({
  /**
   * Code of the nivel2 model
   */

  usecase: "droitsdel",

  /**
   * Builds the url for the REST call
   */
  url: function() {
    return Configuration.restRoot + "/rest/delegation/fonctionnalites/" + encodeURIComponent(this.code) + "?delegable=true";
  },

  /**
   * Constructor
   * Model for load the children elements of the element nivel2 models
   */
  initialize: function(options) {
    if (options && options.code) {
      this.code = options.code;
    }
  },

  validate: function(attrs) {
    var errors = {};

    if (!_.isEmpty(errors)) {
      var errorMap = {
        errorValidation: STR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
    return null;
  }
});