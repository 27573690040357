import { BaseModel } from 'core/models/base.model.js';


export var ModeBadgModel = BaseModel.extend({

  defaults: {
    modebadg: ""
  },

  url: function() {
    return Configuration.restRoot + "/rest/badgvirtuel/" + this.matricule + "/modebadg";
  },

  initialize: function(options) {
    if (options && options.matricule) {
      this.matricule = options.matricule;
    }
  }
});