module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<div class="phx-profiles-menu">\n  <div class="phx-menu">\n    <span class="phx-profiles-first-icon" style="heigth:14px">\n			<span></span>\n    </span>\n    <span class="phx-profiles-menu-header ui-phx-ihm-texte-application" tabindex="0"></span>\n    <span class="phx-profiles-menu-icon ui-icon ui-icon-triangle-1-s"></span>\n  </div>\n  <ul class="phx-profiles-menu-list ui-menu ui-corner-all">\n    <div class="phx-profiles-menu-mesProfils" style="display:none">\n      <span class="generated-list-elements-container ui-phx-ihm-texte-donnees-important"></span>\n      '+
((__t=(i18n.t("profilutilisateur.mesProfils")))==null?'':__t)+
'\n        </span>\n    </div>\n\n    ';
 if(tousCheck == 1){ 
__p+='\n      <div class="phx-profiles-tous-check">\n        <input type="checkbox" name="tous" class="tous phx-bistate-box" value="true" checked="checked" />\n        <label for="tous" class="phx-bistate-box">'+
((__t=(i18n.t("profilutilisateur.tous_check")))==null?'':__t)+
' </label>\n      </div>\n      ';
 } 
__p+='\n\n        <div class="phx-profiles-menu-profilsRecus" style="display:none">\n          <li class=\'phx-menu-link ui-menu-divider\'></li>\n          <span class="phx-profiles-recus"></span>\n\n        </div>\n\n        <li class=\'phx-menu-link ui-menu-divider\'></li>\n        <li>\n          <div class="phx-buttonBar">\n            <button type="button" class="defaut ui-button-special ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" value="new" role="button" style="display: inline-block;">\n	    			<span class="ui-button-text">'+
((__t=(i18n.t("profilutilisateur.defaut")))==null?'':__t)+
'</span>\n	    		</button>\n          </div>\n        </li>\n  </ul>\n</div>';
}
return __p;
};
