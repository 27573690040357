import { TYPE } from '../../../tda/tda.js';

export var ScheduleTextItemView = Backbone.View.extend({
  /**
   * Add this to have Outline
   *
   */
  dummy: "",

  /**
   * HORIZONTAL
   */
  HORIZONTAL: "H",
  /**
   * VERTICAL
   */
  VERTICAL: "V",

  /**
   * tagName
   */
  tagName: "span",
  /**
   * className
   */
  className: "phx-schedule-text-item",
  /**
   * Constructor
   * Representation of each Text (time) interval
   */
  initialize: function(options) {
    /**
     * Plage
     */
    this.plage = options.plage;
    /**
     * Direction (vertical or horizontal)
     */
    this.direction = options.direction || this.HORIZONTAL;
  },

  /**
   * Renders this view
   */
  render: function() {
    var property = "width";
    if (this.direction === this.VERTICAL) {
      property = "height";
      $(this.el).css("width", "100%");
      $(this.el).css("display", "block");
    }
    $(this.el).css(property, this.plage.range + "%");

    if (this.plage.type === "toldeb") {
      $(this.el).html("&nbsp");
    } else {
      $(this.el).html(TYPE.HOUR_MINUTE.format(this.plage.hdebut));
    }

    return this;
  }
});