import { BaseModel } from '../../../../../../core/models/base.model.js';

import { i18n } from '../../../../../../i18n.js';
import { STR } from '../../../../../../utils/str.js';

/**
 * Model for accept, reject or view is modificable
 */
export var GererBadgeagesGestion = BaseModel.extend({

  //REVIEW: 	i18n : phx.i18n.common.gererbadgeages,
  //REVIEW: 	i18nCom : phx.i18n.common,

  /**
   * Builds the url for the REST call
   */
  url: function() {
    return Configuration.restRoot + "/rest/badgeage/composant/" + this.action + "/" + this.id;
  },

  /**
   * Validate the model before launch the REST
   */
  validate: function(attrs) {
    var errors = {};
    if (this.action == "refuser") {
      if (STR.isBlank(attrs.commentaire)) {
        errors.commentaire = i18n.t('common:required', { "0": i18n.t('common:gererbadgeages.comment') });
      }
    }

    if (!_.isEmpty(errors)) {
      var errorMap = {
        errorValidation: STR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
    return null;
  },

  /**
   * Default data model
   *		commentaire : "",
   * }
   */
  defaults: {
    "commentaire": ""
  },

  /**
   * Parsed the results returned by the REST,
   * Copy the return code in the object id
   */
  parse: function(response) {
    if (response.id == undefined && response.code != undefined) {
      response.id = response.code;
    }
    return response;
  }
});