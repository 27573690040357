import { AffectationEnCoursModel } from './affectationEnCours.model.js';

import { PaginatedCollection } from '../../../../../core/models/paginated.collection.js';

//Table remplacements EVO 450
export var AffectationRemplaColl = PaginatedCollection.extend({

  model: AffectationEnCoursModel,
  url: Configuration.restRoot + "/rest/brique/affectations/remplacements",

  initialize: function() {
    PaginatedCollection.prototype.initialize.call(this);
    this.paginated = false;
  },

  comparator: function(model) {
    return model.get("datedeb");
  },

  parse: function(response) {
    response.list = response.affectation;

    delete response.affectation;

    return PaginatedCollection.prototype.parse.call(this, response);
  }

});