import { BaseModel } from '../../../../../core/models/base.model.js';

/**
 * Model for Brique 'info / memos' for viser messages
 */

export var ViserInfoMemosModel = BaseModel.extend({

  idAttribute: "code",

  initialize: function(options) {
    if (options.code) {
      this.code = options.code;
    }
  },

  url: function() {
    return Configuration.restRoot + "/rest/composant/message/" + encodeURIComponent(this.code) + "/viser";
  }
});