import { i18n } from '../../i18n.js';
import { STR } from '../../utils/str.js';
import { BadgeageVirtuelMenuView } from './badgeageVirtuelMenu.view.js';
import { GmSituationView } from 'common/gmsituation/views/gmSituation.view.js';
import { GLOBAL_DATA } from '../../globalData.js';
import TPL_badgeage from '../rgaaBadgeage.tpl.html';

export var RgaaBadgeageView = Backbone.View.extend({

  className: "uix-rgaa-content",

  events: {
    "click button": "_manageLink"
  },

  initialize: function() {

    this.template = TPL_badgeage;
    if(GLOBAL_DATA.paramDivers.get("SPEValideu") && (GLOBAL_DATA.paramDivers.get("SPEValideu").get("valeur") == 1)){
      this.hideCET = true;
    } else {
      this.hideCET = false;
    }

  },

  _loadComponents: function() {
    var components = _.keys(this.model.configuration.get("components"));

    this.components = [];
    var size = components.length;
    for (var i = 0; i < size; i++) {
      if (components[i] === "badgeage") {
        this.components[i] = new BadgeageVirtuelMenuView({
          graphic: true
        });
      }
    }
    //Create badgeages menu
    this._renderMenuComponents();

  },

  _renderMenuComponents: function() {
    var tmenu = this.$el.find(".phx-menu-badgeages");
    if (!STR.isBlank(tmenu) && this.components) {
      for (var i = 0; i < this.components.length; i++) {
        tmenu.append(this.components[i].el);
      }
    }
    Backbone.trigger("resize:header");
  },

  _manageLink: function(event) {
    var element = event.currentTarget.className.split(" ")[0];

    switch (element) {
      case "agenda":
      window.location.href = "#z/coll/uc/agenda";
        break;
      case "consulter":
        this._gmsituation();
        break;
      case "absences":
        window.location.href = "#z/coll/uc/mabsences";
        break;
      case "cet":
        window.location.href = "#z/coll/uc/mregulacet";
        break;
      default:
        break;
    }

  },

  _gmsituation: function() {
    const gmsituation = new GmSituationView();
    gmsituation.open();
  },


  render: function() {
    var json = { i18n: i18n, hideCET: this.hideCET };
    this.$el.html(this.template(json));

    this._loadComponents();
    return this;
  }

});
