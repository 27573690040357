
import { i18n } from '../i18n.js';
import { LOG } from '../utils/log.js';
import { MSGS } from '../utils/msgs.js';
import { objs } from '../objectsRepository.js';
import { onBeforeSend } from '../Interceptors/onBeforeSend.js';
import { onComplete } from '../Interceptors/onComplete.js';
import { onError } from '../Interceptors/onError.js';
import { STR } from '../utils/str.js';
import { UTILS } from '../utils/utils.js';

export var config = function() {
  /**
   * Support CORS specification and allow session management
   */
  $.support.cors = true; //Support for cross domain requests
  $.ajaxSetup({
    cache: false,
    xhrFields: {
      // The 'xhrFields' property sets additional fields on the XMLHttpRequest.
      // This can be used to set the 'withCredentials' property.
      // Set the value to 'true' if you'd like to pass cookies to the server.
      // If this is enabled, your server must respond with the header
      // 'Access-Control-Allow-Credentials: true'.
      withCredentials: true
    },
    headers: { 'fake-conf-filter': 'false' },
    beforeSend: function(jqXHR) {
      jqXHR.setRequestHeader("x_token_key", Configuration.x_token_key);
      if (this.dataType === "json") {
        onBeforeSend(this.headers);
      }
    },
    complete: function() {
      if (this.dataType === "json") {
        onComplete(this.headers);
      }
    }
  });

  /********************
   * ERROR MANAGEMENT
   *******************/

  /**
   * Network error management
   * Configure default management for Ajax Errors
   */
  $(document).ajaxError(onError);

  /**
   * JavaScript error management
   */

  //TODO control when browser closes if there is a change at usecase.
  /* window.onbeforeunload = function()
   {
       return "Vous voulez sortir de l'application ?";
   };*/

  // Set global error handling
  window.onerror = function(message, url, linenumber) { // Other parameters are not supported by IE9

    if (message.indexOf("LoadingUCException") > -1) {
      objs.appRt.workflow.trigger("predestroy:usecase", objs.appRt.workflow.get("usecase"));
    }
    //	  alert("JavaScript error: " + message + " on line " + linenumber + " for " + url + " colno " + colno + " error " + error);
    // FIXME Send the error to the server.

    // Write errors on file sistem
    //	function errorHandler(e) {
    //		LOG.debug(e.name+': ' + e.message);
    //	}
    //
    //	function onInitFs(fs) {
    //
    //		fs.root.getFile('already_there.txt', {
    //			create : true
    //		}, function(fileEntry) {
    //
    //			// Create a FileWriter object for our FileEntry (log.txt).
    //			fileEntry.createWriter(function(fileWriter) {
    //
    //				fileWriter.seek(fileWriter.length); // Start write position
    //													// at EOF.
    //
    //				// Create a new Blob and write it to log.txt.
    //				var aFileParts = ["JavaScript error: " + message + " on line " + linenumber + " for " + url + " colno "
    //									+ colno + " error " + error+"\n"];
    //				var oMyBlob = new Blob(aFileParts, {type : 'text/html'}); // the blob
    //				fileWriter.write(oMyBlob);
    //
    //			}, errorHandler);
    //
    //		}, errorHandler);
    //
    //	}
    //
    //	var fileSystem = window.requestFileSystem || window.webkitRequestFileSystem;
    //	fileSystem(window.TEMPORARY, 1024 * 1024, onInitFs, errorHandler);
    //

    //// You can view the information in an alert to see things working
    //// like so:
    //   alert("Error: " + msg + "\nurl: " + url + "\nline #: " + line);
    //
    //   // TODO: Report this error via ajax so you can keep track
    //   //       of what pages have JS issues
    //
    //   var suppressErrorAlert = true;
    //   // If you return true, then error alerts (like in older versions of
    //   // Internet Explorer) will be suppressed.
    //   return suppressErrorAlert;

    var l_msg = (i18n.t('messages:GT_1317')) ? i18n.t('messages:GT_1317') : "ERREUR";

    MSGS.showError(l_msg, function() {
      UTILS.resetHidder();
      // Add feedback to Ajax requests
      $("#phx-loading_feedback").hide();
    });

    UTILS.resetHidder();
    // Add feedback to Ajax requests
    $("#phx-loading_feedback").hide();
  };

  window.onbeforeunload = function(evento) {
    LOG.debug("onbeforeunload: should logout:" + !window.phxRefreshing);
    if (window.phxRefreshing !== true) {
      LOG.debug("onbeforeunload: logout");
      if (navigator.appVersion.indexOf('Trident/') > 0) {
        if (!STR.isBlank(evento.target.document.URL.indexOf) && evento.target.document.URL.indexOf("index.html") > 0 && objs && objs.appRt && objs.appRt.workflow && UTILS._isLoged() == true) {
          objs.appRt.workflow._logout(true, true);
        }
      } else {
        if (!STR.isBlank(evento.target.URL.indexOf) && evento.target.URL.indexOf("index.html") > 0 && objs && objs.appRt && objs.appRt.workflow && UTILS._isLoged() == true) {
          objs.appRt.workflow._logout(true, true);
        }
      }
    }
    window.phxRefreshing = false;
  };

  window.addEventListener("keydown", function(event) {
    if (navigator.userAgent.indexOf('Mac OS X') != -1) {
      if (event.metaKey == true && event.keyCode == 82) {
        LOG.debug('onbeforeunload: F5 pressed!');
        window.phxRefreshing = true;
      }
    } else {
      if (event.keyCode == 116 || (event.keyCode == 82 && event.ctrlKey == true)) {
        LOG.debug('onbeforeunload: F5 pressed!');
        window.phxRefreshing = true;
      }
    }
  });
  //Prevent launching event onbeforeunload for ie browser when clicking on a link with href=javascript:void(0) or href=javascript:void(0);
  window.onclick = function(event) {
    if ($(event.target).prop("tagName") === "A" && _.isString($(event.target).attr("href")) && $(event.target).attr("href").indexOf("javascript:void(0)") === 0) {
      //Prevent launching event onbeforeunload for ie browser when clicking on a link with href=javascript:void(0) or href=javascript:void(0);
      event.preventDefault();
    }
  }

  //Optional parameter includeMargin is used when calculating outer dimensions
  $.fn.getHiddenDimensions = function(includeMargin) {
    var $item = this,
      props = { position: 'absolute', visibility: 'hidden', display: 'block' },
      dim = { width: 0, height: 0, innerWidth: 0, innerHeight: 0, outerWidth: 0, outerHeight: 0 },
      $hiddenParents = $item.parents().andSelf().not(':visible'),
      includeMargin = (includeMargin == null) ? false : includeMargin;

    var oldProps = [];
    $hiddenParents.each(function() {
      var old = {};

      for (var name in props) {
        old[name] = this.style[name];
        this.style[name] = props[name];
      }

      oldProps.push(old);
    });

    dim.width = $item.width();
    dim.outerWidth = $item.outerWidth(includeMargin);
    dim.innerWidth = $item.innerWidth();
    dim.height = $item.height();
    dim.innerHeight = $item.innerHeight();
    dim.outerHeight = $item.outerHeight(includeMargin);

    $hiddenParents.each(function(i) {
      var old = oldProps[i];
      for (var name in props) {
        this.style[name] = old[name];
      }
    });

    return dim;
  };

};