import { FormView } from '../../../evenements/lister/listerprets/views/form.view.js';
import { HabilitationContext } from '../../../../../core/models/habilitationContext.js';
import { i18n } from '../../../../../i18n.js';
import { LOG } from '../../../../../utils/log.js';
import { PretColl } from '../../../evenements/lister/listerprets/models/pret.collection.js';
import { SYNC } from '../../../../../utils/sync.js';
import { TYPE } from '../../../../../tda/tda.js';
import TPL_bemprunt_briqueMesEmprunts from '../briqueMesEmprunts.tpl.html';

/**
 * View of Brique 'Liste des Emprunts'
 */
export var MesEmpruntsView = Backbone.View.extend({

  initialize: function(options) {

    this.briqueToday = TYPE.DATE.dateToStr(SYNC.getServerDate(), TYPE.DATE.DEFAULT_MASK_BBDD);
    this.template = TPL_bemprunt_briqueMesEmprunts;
    this.usePopulation = false;

    if (options && options.usePopulation) {
      this.usePopulation = options.usePopulation;
    }
    this.parent = null;
    if (options && options.parent) {
      this.parent = options.parent;
    }
    this.context = null;
    if (options && options.context) {
      this.context = options.context;
    }

    // Sets the context for listerPrets
    this.initContext();

    this.tableMesEmprunts = new FormView({ "context": this.mesEmpruntsContext });
    this.mesEmpruntsColl = this.tableMesEmprunts.table.model.coll;
    this.tableMesEmprunts.usePopulation = this.usePopulation;
    this.mesEmpruntsColl.on("ready", this.refreshListeAll, this);
  },

  render: function() {
    var json = { 'i18n': i18n };
    $(this.el).html(this.template(json));

    // Gets empunts table empunts from listeprets table in refreshBrique

    return this;
  },

  events: {
    "click .filtre_statut_input": "_manageCheckboxChanges"
  },

  /*
   * Reads checkboxs states from filter statut
   */
  _manageCheckboxChanges: function(event) {
    var filter = {};

    filter.encours = $(this.el).find(".filtre_statut_input#encours").is(":checked")
    filter.aceptes = $(this.el).find(".filtre_statut_input#aceptes").is(":checked")
    filter.refuses = $(this.el).find(".filtre_statut_input#refuses").is(":checked")
    LOG.debug("Checkbox values-> encours:" + filter.encours + " aceptes:" + filter.aceptes + " refuses:" + filter.refuses);

    this._filterEmpruntsCollection(filter);
  },
  /*
   * Set checkboxes initial states
   */
  _resetCheckboxes: function() {
    $(this.el).find(".filtre_statut_input").prop('checked', true);
  },
  /*
   *  Filters the collection used for listePrets
   */
  _filterEmpruntsCollection: function(filter) {
    var filterStatut = [];
    if (filter.encours === true) { filterStatut.push("D", "I", "T") };
    if (filter.aceptes === true) { filterStatut.push("A", "H") };
    if (filter.refuses === true) { filterStatut.push("R") };

    LOG.debug("filterStatut-> " + filterStatut);

    var filteredMesEmpruntModels = this.collAllMesEmprunts.filter(function(model) {
      return filterStatut.indexOf(model.get('statut').code) > -1;
    });

    this.mesEmpruntsColl.reset(filteredMesEmpruntModels);
  },

  /*
   * Preserves the complete collection of prets
   * Adjust the size of the brique
   */
  refreshBrique: function() {
    this._resetCheckboxes();
    // Gets empunts table empunts
    this.tableMesEmprunts.render();
    $(this.el).find(".table_BriqueMesEmprunts").html(this.tableMesEmprunts.table.el);

    this.$el.parents(".phx-portlet-div").css({
      "padding": "0px 0px 0px 2px",
      "width": "calc(100% - 4px)"
    });
  },

  refreshListeAll: function() {
    this.collAllMesEmprunts = new PretColl(
      this.mesEmpruntsColl.models
    );
    this.disabledCombo(this.mesEmpruntsColl.models);
  },

  disabledCombo: function(model) {
    var encours = false;
    var aceptes = false;
    var refuses = false;

    for (var i in model) {
      switch (model[i].get("etat")) {
        case "D":
          encours = true;
          break;
        case "I":
          encours = true;
          break;
        case "T":
          encours = true;
          break;
        case "A":
          aceptes = true;
          break;
        case "H":
          aceptes = true;
          break;
        case "R":
          refuses = true;
          break;
      }
    }

    if (!encours) {
      $("#encours").attr("disabled", true);
    } else {
      $("#encours").attr("disabled", false);
    }
    if (!aceptes) {
      $("#aceptes").attr("disabled", true);
    } else {
      $("#aceptes").attr("disabled", false);
    }
    if (!refuses) {
      $("#refuses").attr("disabled", true);
    } else {
      $("#refuses").attr("disabled", false);
    }
  },

  initContext: function() {
    /*
      Context
      ctxUtilisateur Responsable
      ctxHabilitation ctxHabilitation.HabilitationAcces RES_PRET.V
      ctxCollab vide
      ctxGestionCollective OUI
      ctxVisibilite OUI
      ctxGestion OUI
      ctxValidation NON
      ctxValideur NON
      ctxFiltrePrêts Emprunts
      ctxFiltreApplique
       Prêts/Emprunts acceptés : oui
       Prêts/Emprunts refusés : oui
       Prêts/Emprunts demandés/en cours de validation : oui
       Prêts/Emprunts hors contexte de workflow : oui
      ctxTypePret EMPRUNTS
      ctxPrêtsReçus OUI
      ctxPrésentationGrille Habillée
      ctxRestitutionPrêts Eclatée 
      ctxVisuStatut NON
      ctxEncoursOuAVenir OUI
      ctxPeriode Laissé vide
      ctxTriDate Ascendant
      ctxPretsID  Laissé vide
     */
    this.mesEmpruntsContext = {};
    this.mesEmpruntsContext.ctxUtilisateur = "Responsable";
    this.mesEmpruntsContext.ctxHabilitation = {};
    this.mesEmpruntsContext.ctxHabilitation.HabilitationAcces  = "RES_PRET.V";
    this.mesEmpruntsContext.ctxHabilitation.HabilitationValidation = "RES_VAL_PRET.G";
    this.mesEmpruntsContext.ctxCollab = null;
    this.mesEmpruntsContext.ctxGestionCollective = true;
    this.mesEmpruntsContext.ctxValidation = false;
    this.mesEmpruntsContext.ctxValideur = false;
    this.mesEmpruntsContext.ctxFiltreApplique = ["A", "D", "I", "T", "R", "H"]; //"A"=accepte "D"=demande "H"=hors validation "I"=en cours "R"=refusé "T"=demande
    this.mesEmpruntsContext.ctxVisuStatut = true;
    this.mesEmpruntsContext.ctxEncoursOuAVenir = true;
    this.mesEmpruntsContext.ctxPeriode = {};
    this.mesEmpruntsContext.ctxPeriode.datedeb = this.briqueToday;
    this.mesEmpruntsContext.ctxPeriode.datefin = this.briqueToday;
    this.mesEmpruntsContext.ctxTriDate = "Ascendant";
    this.mesEmpruntsContext.ctxPretsID = [];
    this.mesEmpruntsContext.ctxPretRecus = true;
    this.mesEmpruntsContext.ctxTypePret = "EMPRUNTS";
    this.mesEmpruntsContext.ctxRestitutionPrets = "Regroupee";
    this.mesEmpruntsContext.ctxPresentationGrille = "Neutralisee";
    this.mesEmpruntsContext.habContext = new HabilitationContext({
      "onglet": "bemprunt",
      "fonc_cour": { "acces": "RES_PRET.V", "validation": "RES_VAL_PRET.G" },
      "nat_gest": ""
    });
  },
});