import { ComboBoxView2 } from 'core/components/combo/comboBoxView2.js';
import { CombosColl } from 'core/components/combo/combos.collection.js';
import { EditedRowView } from 'core/views/editedRow.view.js';
import { FORMS } from '../../../../utils/forms.js';
import { i18n } from '../../../../i18n.js';
import { STR } from '../../../../utils/str.js';
import TPL_parametragebresultats_editedRowCompteurs from '../editedRowCompteurs.tpl.html';

export var EditedRowCompteursView = EditedRowView.extend({

  dummy: "",

  /**
   * Event launched when any editable field of the view change its value
   *
   */

  events: _.extend({
    "change :input:not([readonly])": "_changeView"
  }),

  /**
   * Constructor
   * View of the Edit Row for the table of Contextes d'utilisation
   */
  initialize: function(options) {
    this.habContext = options.habContext;
    this.workflow = options.workflow;
    /**
     * HTML template of the view
     */
    this.template = TPL_parametragebresultats_editedRowCompteurs;

    if (options && options.context) {
      this.context = options.context;
    }

  },

  /**
   * Paints the row view in edition mode of the UC
   */
  render: function() {
    var json = { "i18n": i18n };
    this.$el.html(this.template(json));
    //Combo Contexte Utilisation
    this.comboCompteur = new ComboBoxView2({
      ws: new CombosColl({
        name: "ls",
        comboId: "compteur?tri=code"
      }),
      name: "compteur.code",
      required: true,
      optionsRender: function(value) {
        if (!value || STR.isBlank(value.code)) {
          return "";
        }
        return value.libelle + " (" + value.code + ")";
      },
      width: 250,
      habContext: this.model.getHabContext()
    });

    $(this.el).find(".cmbCompteur").html(this.comboCompteur.render().el);

    this.comboType = new ComboBoxView2({
      ws: new CombosColl({
        name: "utilgroupecpt",
        comboId: "typeresultats"
      }),
      name: "typeresultat.code",
      required: true,
      optionsRender: function(value) {
        if (!value || STR.isBlank(value.code)) {
          return "";
        }
        return value.libelle + " (" + value.code + ")";
      },
      width: 210,
      habContext: this.model.getHabContext()
    });

    $(this.el).find(".cmbTypeRes").html(this.comboType.render().el);

    this.comboPeriodicites = new ComboBoxView2({
      ws: new CombosColl({
        name: "utilresultatsgroupecpt",
        comboId: "periodicites"
      }),
      name: "periodicite.code",
      required: true,
      optionsRender: function(value) {
        if (!value || STR.isBlank(value.code)) {
          return "";
        }
        return value.libelle + " (" + value.code + ")";
      },
      width: 210,
      habContext: this.model.getHabContext()
    });

    $(this.el).find(".cmbPeriodicite").html(this.comboPeriodicites.render().el);

    this.comboPeriodicites.setFilter(this._filterComboPeriodicite());

    this.comboPeriodeValo = new ComboBoxView2({
      ws: new CombosColl({
        name: "ls/divcod",
        comboId: "periodevalorisation"
      }),
      name: "periodevalo.code",
      required: true,
      optionsRender: function(value) {
        if (!value || STR.isBlank(value.code)) {
          return "";
        }
        return value.libelle + " (" + value.code + ")";
      },
      width: 210,
      habContext: this.model.getHabContext()
    });

    $(this.el).find(".cmbPeriodeValo").html(this.comboPeriodeValo.render().el);

    if (this.model.get("compteurs") && !STR.isBlank(this.model.get("compteurs").format)) {
      this.$el.find(".format").html(this.model.get("compteurs").format);
    }

    this._manageTypeResultat();

    this._mapModelToForm(this.$el, this.model);

    return this;
  },
  _changeView: function(event, data) {
    this._change(event, data);

    var className = event.target.className.split(" ")[0];
    //Infobulle if email is too long
    if (className === "typeresultat.code") {
      this._manageTypeResultat();
      if (STR.getElValue(this.model, "typeresultat.code") == "A" && STR.getElValue(this.model, "periodicite.code") == "J") {
        //empty periodicite
        STR.setElValue(this.model, "periodicite.code", null);
        this.comboPeriodicites.setItem({ code: "", label: "" });
      }
    }
  },
  _manageTypeResultat: function() {
    if (STR.getElValue(this.model, "typeresultat.code") != "A" && STR.getElValue(this.model, "typeresultat.code") != "R") {
      this._disableCombos();
    } else {
      this._enableCombos();
    }
  },
  _enableCombos: function() {
    FORMS.setFieldReadonly(this.$el.find(".periodicite\\.code"), false, false);
    FORMS.setFieldReadonly(this.$el.find(".periodevalo\\.code"), false, false);
    this.$el.find("label[for='periodicite.code']").show();
    this.$el.find("label[for='periodevalo.code']").show();
  },
  _disableCombos: function() {
    //empty periodicite
    STR.setElValue(this.model, "periodicite.code", null);
    this.comboPeriodicites.setItem({ code: "", label: "" });
    //empty periodevalo
    STR.setElValue(this.model, "periodevalo.code", null);
    this.comboPeriodeValo.setItem({ code: "", label: "" });

    //Set to readonly
    FORMS.setFieldReadonly(this.$el.find(".periodicite\\.code"), true, false);
    FORMS.setFieldReadonly(this.$el.find(".periodevalo\\.code"), true, false);
    this.$el.find("label[for='periodicite.code']").hide();
    this.$el.find("label[for='periodevalo.code']").hide();
  },
  _filterComboPeriodicite: function() {
    var self = this;
    var filter = function(collection) {
      var typeRes = STR.getElValue(self.model, "typeresultat.code");

      var filterCollection = [];

      _.each(collection, function(model, key) {
        if (typeRes == "A") {
          if (model.attrs.code != "J") {
            filterCollection.push(model);
          }
        } else {
          filterCollection.push(model);
        }
      });

      return filterCollection;
    };
    return filter;
  }
});