import { BaseModel } from '../../../../../../core/models/base.model.js';
import { STR } from '../../../../../../utils/str.js';

/**
 * Model for the complement combos
 */
export var ComplementComboModel = BaseModel.extend({

  defaults: {
    "id": null,
    "code": "",
    "libelle": ""
  },

  parse: function(response) {
    var result = {};
    if (!STR.isBlank(response.code)) {
      result.id = response.code;
      result.code = response.code;
    }
    if (response.code == 0) {
      result.id = "0";
      result.code = 0;
    }
    if (!STR.isBlank(response.libellecourt)) {
      result.libelle = response.libellecourt;
    }

    return result;
  }
});