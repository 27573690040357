module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<div class="groupecompteurs-collab" style="display:none;">\n  <br/>\n  <span class="ctxGestionCollab"></span>\n</div>\n<div class="groupecompteurs-detail">\n  <fieldset class="groupecompteurs-detail-panel">\n    <div class="phx-formTable">\n      <div class="phx-formErrors ui-state-error"></div>\n      <div class="title_compteurs">\n        <span class="valorisation"></span>\n        <span class=\'iconConsul ui-icon ui-icon-search\' style="display: block; cursor: pointer;"></span>\n      </div>\n\n    </div>\n  </fieldset>\n  <span class="tableresultats"></span>\n  <span class="showCompteurs filter-link-btn filter-more-btn" style="display:none">'+
((__t=(i18n.t("common:resultatgrpcompteurs.show")))==null?'':__t)+
'</span>\n  <span class="hideCompteurs filter-link-btn filter-more-btn" style="display:none">'+
((__t=(i18n.t("common:resultatgrpcompteurs.hide")))==null?'':__t)+
'</span>\n  <div class="tableCompteursInstant" style="display:none"></div>\n</div>\n';
}
return __p;
};
