import { BaseModel } from 'core/models/base.model.js';

export var CalendaccZonesModel = BaseModel.extend({

  idAttribute: "code",

  parse: function(response) {

    response.colorRGB = "RGB(" + response.couleur.coder + "," + response.couleur.codeg + "," + response.couleur.codeb + ")";

    return response;
  }
});