import { BaseModel } from 'core/models/base.model.js';
import { STR } from '../../../../utils/str.js';

export var PopulationComboModel = BaseModel.extend({
  parse: function(response) {
    if (!STR.isBlank(response.code)) {
      if (!STR.isBlank(response.type)) {
        response.code = response.code + "," + response.type;
      }
      response.id = response.code;
    }
    return response;
  }
});