import { AutocompleteColl } from '../combo/autocomplete.collection.js';
import { i18n } from '../../../i18n.js';
import { SelecteurCheminColl } from './selecteurChemin.collection.js';
import { SelecteurCheminResultItemView } from './selecteurCheminResultItem.view.js';
import { STR } from '../../../utils/str.js';
import { SYNC } from '../../../utils/sync.js';
import { TreeView } from '../../tree/tree.view.js';
import { TYPE } from '../../../tda/tda.js';
import TPL_common_selecteur_chemin_dialog from './selecteur_chemin_dialog.tpl.html';
import { GLOBAL_DATA } from '../../../globalData.js';

export var SelecteurCheminDialogView = Backbone.View.extend({

  dummy: "",

  /**
   * Name of the html tag which involves the view
   */
  tagName: "span",

  /**
   * Name of the container class of the view
   */
  className: "phx-selecteur-chemin-dialog",

  /**
   * Event launched when the find button is clicked
   *
   */
  /**
   * Event launched when the expand menu icon is clicked
   *
   */
  /**
   * Event launched when a menu element is clicked
   *
   */
  /**
   * Event launched when a menu element is clicked
   *
   */
  /**
   * Event launched when a element is selected or deleted
   *
   */

  events: {
    "click .phx_chemin_rechbtn": "_doSearch",
    "click .phx_chemin_menuicon": "_toggleMenu",
    "click .phx_chemin_menucontent li": "_changeRenderer",
    "click .phx_chemin_menucontent span": "_changeRenderer"
  },

  /**
   * Constructor
   * Selecteur de chemin mode complet dialog view
   */
  initialize: function(params) {
    /**
     * HTML template of the view
     */
    this.template = TPL_common_selecteur_chemin_dialog;
    var today = TYPE.DATE.parse(TYPE.DATE.dateToStr(SYNC.getServerDate())).val;
    if (params && params.parameters) {

      /**
       * Parameters of the initialization
       */
      this.params = params.parameters;
      this.params.complet = true;
      this.habContext = this.params.habContext;
      if (this.params.context && this.params.context.ctxStructureSelpop === true) {
        this.params.selectpopu = true;
      }
    } else {
      this.params = {
        structid: null,
        parentcode: null,
        parentdebsitu: null,
        parentfinsitu: null,
        datedebut: today,
        datefin: today,
        multiple: false,
        intermediaire: true,
        partial: true,
        complet: true,
        selectionnes: "",
        niveau: null,
        racine: null,
        nonrattachable: null,
        nonrattactiv: null,
        elementcode: null,
        exclure_collab: null
      };
    }

    /**
     * Style of visualization for the list of this view
     */
    this.listRenderStyle = 1;
    if (params && params.listRenderStyle && !STR.isBlank(GLOBAL_DATA.paramDivers.get("SPEValideu")) && GLOBAL_DATA.paramDivers.get("SPEValideu").get("valeur") !== "1") {
      this.listRenderStyle = params.listRenderStyle;
    }

    if (params.context) {
      this.context = params.context;
    }

    // response list filled with user selection
    /**
     * Collection of the current selection
     */
    this.selection = new AutocompleteColl();
    this.selection.itemRenderer = this._getAttrsRenderer();
    this.selection.on("click:item", this._removeItem, this);

    // add already selected items
    if (params.currentSelection) {
      _.each(params.currentSelection.models, function(item) {
        var obj = new Backbone.Model(item.toJSON());
        obj.tooltip = item.tooltip;
        this.selection.add(obj);
      }, this);

    }

    /**
     * Collection of the elements in the selector
     */
    this.coll = new SelecteurCheminColl(this.params);
    this.coll.setHabContext(this.habContext);

  },

  setHabContext: function(habContext) {
    this.habContext = habContext;
  },

  updateHabContext: function(attributes) {
    if (this.habContext) {
      this.habContext.update(attributes);
    }
  },

  getHabContext: function() {
    return this.habContext;
  },
  /**
   * Called the first time to initialize the context of the combo
   */
  habilitationContext: function(context) {
    if (this.coll) {
      if (STR.isBlank(this.coll.habContext) || !_.isEqual(this.coll.habContext.toJSON(), context.toJSON())) {
        this.clearCache();
      }

      this.stopListening();
      this.coll.setHabContext(context);
      this.listenTo(context, "change", this.clearCache);
    }

  },

  clearCache: function() {
    for (var i in this.cache) {
      if (this.cache.hasOwnProperty(i)) {
        this.cache[i].length = 0;
        delete this.cache[i];
      }
    }
    this.cache["pendingInUse"] = this.cache["pendingInUse"] || [];
  },

  /**
   * Changes and sets the style of the visualization of the list of elements to show.
   */
  _changeRenderer: function(event) {
    switch (event.target.className.split(" ")[0]) {
      case "phx_chemin_opt1":
        this.listRenderStyle = 1;
        break;
      case "phx_chemin_opt2":
        this.listRenderStyle = 2;
        break;
      case "phx_chemin_opt3":
        this.listRenderStyle = 3;
        break;
      case "phx_chemin_opt4":
        this.listRenderStyle = 4;
        break;
      default:
        break;
    }

    this.tree.setRenderer(this._getRenderer());

    this.tree.root.repaintLabel(this._getRenderer());

    this._formatSelection();

    return false;
  },

  /**
   * Paints the items of the component depending on their renderers.
   */
  _formatSelection: function() {

    _.each(this.selection.models, function(item) {
      var renderer = this._getAttrsRenderer();
      item.set("label", renderer(item.get("attrs")));
    }, this);

    this.selection.itemRenderer = this._getAttrsRenderer();

    this._paintItems();
  },

  /**
   * Expands or collapses the menu
   */
  _toggleMenu: function() {
    var menuContent = $(".phx_chemin_menucontent", this.el);
    var menuFonctionContent = $(".phx_chemin_menucontent", this.el);
    if (menuContent.is(":visible")) {
      menuFonctionContent.hide();
      menuContent.hide();
    } else {
      var menuBtn = $(".phx_chemin_menuicon", this.el);
      menuContent.show().position({
        my: "right top",
        at: "right bottom",
        of: menuBtn
      });

      //If you click out of the menu, close the menu.
      var self = this;
      $(document).one("mousedown", function(event) {
        var element = $(self.el).find(event.target);
        if (element.length === 0) {
          self._toggleMenu();
        }
      });
      //If you click in one option, the menu is closed
      $(document).one("mouseup", function(event) {
        var element = $(self.el).find(event.target);
        if (element.length > 0 && event.target.className.split(" ")[0] !== "phx_chemin_menuicon") {
          self._toggleMenu();
        }
      });
    }
  },

  /**
   * Returns the render function depending on the render style for every element in the tree
   */
  _getRenderer: function() {
    var result = null,
      self = this;
    switch (this.listRenderStyle) {
      case 1:
        result = function(item) {
          if (!STR.isBlank(self.params) && self.params.ecran === "coll" && !STR.isBlank(GLOBAL_DATA.paramDivers.get("SPEValideu")) && GLOBAL_DATA.paramDivers.get("SPEValideu").get("valeur") === "1") {
            return item.get("libelle") + " (" + item.get("codef") + ")" + (!STR.isBlank(item.get("libelle_structprincipale")) ? '\xa0\xa0' + item.get("libelle_structprincipale") : '');
          } else {
            return item.get("libelle") + " (" + item.get("codef") + ")";
          }
        };
        break;
      case 2:
        result = function(item) {
          return item.get("codef") + " (" + item.get("libelle") + ")";
        };
        break;
      case 3:
        result = function(item) {
          return item.get("libelle");
        };
        break;
      case 4:
        result = function(item) {
          return item.get("codef");
        };
        break;
      default:
        break;
    }
    return result;
  },

  /**
   * Returns the render function depending on the render style for every element
   */
  _getAttrsRenderer: function() {
    var result = null;
    var l_resultaux = "";

    switch (this.listRenderStyle) {
      case 1:
        result = function(item) {
          l_resultaux = "";
          if (item.niveau === 0) {
            l_resultaux = i18n.t('common:chemin.structurecomp');
          } else {
            l_resultaux = item.libelle + " (" + item.codef + ")";
          }
          return l_resultaux;
        };
        break;
      case 2:
        result = function(item) {
          l_resultaux = "";
          if (item.niveau === 0) {
            l_resultaux = i18n.t('common:chemin.structurecomp');
          } else {
            l_resultaux = item.codef + " (" + item.libelle + ")";
          }
          return l_resultaux;
        };
        break;
      case 3:
        result = function(item) {
          l_resultaux = "";
          if (item.niveau === 0) {
            l_resultaux = i18n.t('common:chemin.structurecomp');
          } else {
            l_resultaux = item.libelle;
          }
          return l_resultaux;
        };
        break;
      case 4:
        result = function(item) {
          l_resultaux = "";
          if (item.niveau === 0) {
            l_resultaux = i18n.t('common:chemin.structurecomp');
          } else {
            l_resultaux = item.codef;
          }
          return l_resultaux;
        };
        break;
      default:
        break;
    }
    return result;
  },

  /**
   * Does the search in the chemin dialog components view
   */
  _doSearch: function() {
    var term = this.$el.find(".phx-chemin-strinp").val();
    if (STR.isBlank(term)) {
      this._paintTree();
    } else {
      var structColl = new SelecteurCheminColl(this.params);
      structColl.search = "&search=" + term;
      structColl.setHabContext(this.coll.getHabContext());

      var self = this;
      structColl.fetch({
        success: function(fresh) {
          self.tree.setColl(structColl);
          self.tree.model.on("nodeSelected", self._setSelection, self);
          self.tree.model.on("nodeChoosen", self._chooseSelection, self);
          self.tree.root.empty();
          self.tree.root.render();
          if (fresh.models.length > 0) {
            self.tree.root.fillSubTree(fresh.models[0].get("enfants"));
          }
        }
      });
    }
  },

  /**
   * Paints the tree for the dialog component
   */
  _paintTree: function() {
    var term = this.$el.find(".phx-chemin-strinp").val();

    //Gets the collection to build the tree
    var structColl = new SelecteurCheminColl(this.params);
    structColl.setHabContext(this.coll.getHabContext());
    if (!STR.isBlank(term)) {
      structColl.search = "&search=" + term;
    }

    var self = this;

    if (!this.tree) {
      //Create the tree
      /**
       * Tree view of the dialog component
       */
      this.tree = new TreeView({
        coll: null,
        name: null,
        draggable: false,
        rootModel: this.racineModel,
        buildUrlColl: function(parentNiveau, node, collection) {
          collection.setNiveau(parentNiveau + 1);
          collection.setParentCode(node.get("code"));
          collection.parentdebsitu = node.get("debsitu");
          collection.parentfinsitu = node.get("finsitu");
          collection.datedebut = self.params.datedebut;
          collection.datefin = self.params.datefin;
          collection.selectpopu = self.params.selectpopu;
        },
        renderer: this._getRenderer(),
        tooltipRenderer: function(model, view) {
          var text = "";
          //add tooltip icon
          var label = $(view.$el.find(".phx-treenode-label")[0]).text();
          //(&#9679)= (●)
          var tooltipIcon = " <span class=\"tree-tooltip-icon\" title=\"\">(&#9679)</span>";
          if (model.get("datespartielles").length > 0) {
            view.$el.find(".phx-treenode-label").html(label + tooltipIcon);
            var tooltip = view.$el.find(".tree-tooltip-icon").tooltip({ content: "" });
            var first = true;
            for (var i = 0; i < model.get("datespartielles").length; i++) {
              if (first === false) {
                text += "<br/>";
              }
              if (model.get("datespartielles")[i].datedebut === model.get("datedebut")) {
                //bold
                text += "<b>[" + TYPE.DATE.format(model.get("datespartielles")[i].datedebut) + " - " + TYPE.DATE.format(model.get("datespartielles")[i].datefin) + "]</b>";
              } else {
                text += "[" + TYPE.DATE.format(model.get("datespartielles")[i].datedebut) + " - " + TYPE.DATE.format(model.get("datespartielles")[i].datefin) + "]";
              }
              first = false;
            }
            tooltip.tooltip("option", "content", text);
          } else {
            var displayIcon = false;
            if (model.get("datedebut") <= self.params.datedebut && model.get("datefin") < self.params.datefin) {
              text += "[... - " + TYPE.DATE.format(model.get("datefin")) + "]";
              displayIcon = true;
            } else if (model.get("datedebut") > self.params.datedebut && model.get("datefin") >= self.params.datefin) {
              text += "[" + TYPE.DATE.format(model.get("datedebut")) + " - ...]";
              displayIcon = true;
            } else if (model.get("datedebut") > self.params.datedebut && model.get("datefin") < self.params.datefin) {
              text += "[" + TYPE.DATE.format(model.get("datedebut")) + " - " + TYPE.DATE.format(model.get("datefin")) + "]";
              displayIcon = true;
            }
            if (displayIcon) {
              view.$el.find(".phx-treenode-label").html(label + tooltipIcon);
              var tooltip = view.$el.find(".tree-tooltip-icon").tooltip({ content: "" });
              tooltip.tooltip("option", "content", text);
            }
          }
        },

        selectableNode: function(model) {

          var lb_selectable = true;

          if (self.params.selRacineAuto === false && model.get("niveau") === 0) {
            lb_selectable = false;
          }

          if (self.params.selNoeudChemin === false && model.get("niveau") !== 0 && model.get("feuille") !== true) {
            lb_selectable = false;
          }

          if (model.get("selectionnable") !== true) {
            lb_selectable = false;
          }

          if (!STR.isBlank(this.collection.models)) {
            if (_.isArray(this.collection.models) && this.collection.models.length > 0) {
              // init with one or more elements in arrays
              for (var i = 0; i < this.collection.models.length; i++) {
                if (!STR.isBlank(this.collection.models[i].get("selectionnable") && this.collection.models[i].get("selectionnable") === true)) {
                  lb_selectable = true;
                } else {
                  lb_selectable = false;
                }
              }
            }
          }

          return lb_selectable

        }
      });

      this.$el.find(".phx_chemin_tree").html(this.tree.el);
    }
    this.tree.setSortFunction(this._sortTree);
    this.tree.setColl(structColl);
    this.tree.model.on("nodeSelected", this._setSelection, this);
    this.tree.model.on("nodeChoosen", this._chooseSelection, this);
    this.tree.render();
  },

  /**
   * Compares two elements from the tree and returns 1 if a>b, 0 if a=b or -1 if a<b
   */
  _sortTree: function(a, b) {
    var compA = a.model.get("label");
    var compB = b.model.get("label");
    var comp = (compA < compB) ? -1 : (compA > compB) ? 1 : 0;
    if (comp === 0) {
      // comparing with datedeb de validité
      compA = a.model.node.get("datedebut");
      compB = b.model.node.get("datedebut");
      comp = (compA < compB) ? 1 : (compA > compB) ? -1 : 0;
    }
    return comp;
  },

  /**
   * Sets the current selection
   */
  _chooseSelection: function(model) {
    this._setSelection(model);
  },

  /**
   * Sets the current selection
   */
  _setSelection: function(model) {
    if (this.params && this.params.context && this.params.context.ctxStructureSelpop == true) {
      this.setSelectionTraversalComponentPopulation(model);
    } else {
      this.setSelectionCommon(model)
    }
  },

  setSelectionCommon: function(model) {
    var renderer = model.root.renderer;
    var node = model.node;
    //Provisional hasta ver si el atributo NivStructSelPopActiv se corresponde con node.selectionnable
    if (this.params.multiple === false || node.get("niveau") === 0 || (this.selection.length === 1 && this.selection.models[0].get("attrs").niveau === 0)) {
      this.selection.reset();
    }

    this.selection.add([node.attributes], { parse: true });
    var label = "";

    if (this.params.multiple === false) {
      var end = false;
      var actualModel = node;

      if (node.get("niveau") === 0) {
        label = i18n.t('common:chemin.structurecomp');
      } else {
        while (!end) {
          if (STR.isBlank(actualModel.parent)) {
            end = true;
            label = label.substring(1, label.length);
          } else {
            label = "/" + renderer.call(this, actualModel) + label;
            actualModel = actualModel.parent;
          }
        }
      }
      this.$el.find(".phx-chemin-selval").text(label);
    } else {
      this._paintItems();
    }
    this.trigger("response", this.selection);
    return this.selection;
  },

  setSelectionTraversalComponentPopulation: function(model) {
    var renderer = model.root.renderer;
    var node = model.node;
    //Provisional hasta ver si el atributo NivStructSelPopActiv se corresponde con node.selectionnable
    if (node.get('selectionnable') == true) {
      if (this.params.multiple === false || node.get("niveau") === 0 || (this.selection.length === 1 && this.selection.models[0].get("attrs").niveau === 0)) {
        this.selection.reset();
      }

      this.selection.add([node.attributes], { parse: true });
      var label = "";

      if (this.params.multiple === false) {
        var end = false;
        var actualModel = node;

        if (node.get("niveau") === 0) {
          label = i18n.t('common:chemin.structurecomp');
        } else {
          while (!end) {
            if (STR.isBlank(actualModel.parent)) {
              end = true;
              label = label.substring(1, label.length);
            } else {
              label = "/" + renderer.call(this, actualModel) + label;
              actualModel = actualModel.parent;
            }
          }
        }
        this.$el.find(".phx-chemin-selval").text(label);
      } else {
        this._paintItems();
      }
    }
    this.trigger("response", this.selection);
    return this.selection;
  },
  /**
   * Paints every element of the dialog component
   */
  _paintItems: function() {
    $(this.el).find(".phx-chemindlg-selection").empty();

    var self = this;

    if (this.params.multiple) {

      _.each(this.selection.models, function(value) {
        var itemView = new SelecteurCheminResultItemView({ model: value });
        $(self.el).find(".phx-chemindlg-selection").append(itemView.render().el);

        $(itemView.el).tooltip({ content: value.tooltip });

      });

      if (this.selection.models.length === 0) {
        this.$el.find(".phx-chemindlg-selection").css("display", "inherit");
      } else {
        this.$el.find(".phx-chemindlg-selection").css("display", "");
      }

      this.$el.find(".phx-chemindlg-selection").position({
        my: "left center",
        at: "right center",
        of: self.$el.find(".phx_chemin_sellbl")
      });
    }
  },

  /**
   * Deletes an element from the dialog component list
   */
  _removeItem: function(model) {
    // remove item from collection
    this.selection.remove(model);

    this.trigger("response", this.selection);
    // repaint
    this._paintItems();
  },

  /**
   * Paints the view of the selecteur de chemin dialog component
   */
  render: function() {
    var json = {
      "i18n": i18n,
      "context": this.params.context
    };
    var self = this;

    $(this.el).append(this.template(json));

    //Buttons
    this.$el.find(".phx_chemin_rechbtn").button();

    if (this.params.datedebut === this.params.datefin) {
      this.$el.find(".phx_chemin_restitle").text(i18n.t('common:chemin.dlgresultat', { "0": TYPE.DATE.format(this.params.datedebut), interpolation: { escapeValue: false } }));
    } else {
      this.$el.find(".phx_chemin_restitle").text(i18n.t('common:chemin.dlgresultatper', { "0": TYPE.DATE.format(this.params.datedebut), "1": TYPE.DATE.format(this.params.datefin), interpolation: { escapeValue: false } }));
    }

    //paint the structure type label
    var parameters = {
      structid: this.params.structid,
      parentcode: this.params.parentcode,
      parentdebsitu: this.params.parentdebsitu,
      parentfinsitu: this.params.parentfinsitu,
      datedebut: this.params.datedebut,
      datefin: this.params.datefin,
      multiple: this.params.multiple,
      intermediaire: this.params.intermediaire,
      partial: this.params.partial,
      complet: true,
      selectionnes: this.params.selectionnes,
      niveau: 0,
      racine: this.params.racine,
      nonrattachable: this.params.nonrattachable,
      nonrattactiv: this.params.nonrattactiv,
      elementcode: this.params.elementcode,
      selectpopu: this.params.selectpopu,
      exclure_collab: this.params.exclure_collab
    };
    var racineColl = new SelecteurCheminColl(parameters);
    racineColl.setHabContext(this.coll.getHabContext());
    this.$el.find(".phx_chemin_rechbtn").attr("disabled", "disabled");
    racineColl.fetch({
      success: function(fresh) {
        if (fresh.models && fresh.models.length > 0) {
          var label = fresh.models[0].get("libelle");
          label = label.replace("[", "");
          label = label.replace("]", "");
          self.$el.find(".phx_chemin_strlbl").text(label);
          self.racineModel = racineColl.models[0];
          self._paintTree();
          self.$el.find(".phx_chemin_rechbtn").removeAttr("disabled");
        }
      }
    });

    //Build the menu
    var menuContent = $(".phx_chemin_menucontent", this.el);
    menuContent.menu().css("width", "150px");
    menuContent.hide();

    var pDiversSPEValideu = GLOBAL_DATA.paramDivers.get("SPEValideu");

    if (!STR.isBlank(pDiversSPEValideu) && pDiversSPEValideu.get("valeur") === "1") {
      this.$el.find(".phx_chemin_menuicon").hide();
    }

    this._paintItems();

    return this;
  }

});
