import TPL_common_combo_box_item from './combo_box_item.tpl.html';

export var ComboBox2ResultItemView = Backbone.View.extend({

  dummy: "",

  /**
   * Name of the container class of the view
   */
  className: "phx-list-builder-item ui-corner-all",

  /**
   * Name of the html tag which involves the view
   */
  tagName: "span",

  /**
   * Event launched when the remove icon is clicked
   *
   */

  events: {
    "click .phx-list-builder-item-icon": "_removeItem"
  },

  /**
   * Constructor
   * View underlying a List builder element
   */
  initialize: function(params) {
    this.template = TPL_common_combo_box_item;
    this.data = {};
    this.data.label = "";
    if (params && params.label) {
      this.data.label = params.label;
    }
    if (params && params.model) {
      this.model = params.model;
    }
  },

  /**
   * Paints the view of List builder element
   */
  render: function() {
    $(this.el).html(this.template(this.data));

    return this;
  },

  /**
   * Removes the current element
   */
  _removeItem: function(event) {
    this.model.trigger("click:item", this.model, event);
  }

});