import { FilterModel } from '../models/filter.model.js';
import { FORMS } from '../../../../utils/forms.js';
import { FormView } from 'core/views/form.view.js';
import { i18n } from '../../../../i18n.js';
import { RadioBoxView } from 'core/views/radioBox.view.js';
import { SelectUtilisateurView } from 'common/referentiel/referentielutilisateur/views/selectUtilisateur.view.js';
import { STR } from '../../../../utils/str.js';
import { TYPE } from '../../../../tda/tda.js';
import TPL_droitsdel_filter from '../filter.tpl.html';

export var FilterContentView = FormView.extend({

  /**
   * Constructor
   * View for the content of the filter part
   */
  initialize: function(options) {
    this.template = TPL_droitsdel_filter;
    if (options && options.workflow) {
      this.workflow = options.workflow;
    }
    this.ctxEcran = options.ctxEcran;
    this.model = new FilterModel();

    this.model.off("invalid");
    this.model.on("invalid", this._showValidationErrors, this);

    this.model.setHabContext(this.workflow.getHabContext());

    if (this.workflow.ctxPeriode && this.workflow.ctxPeriode.datedeb) {
      this.model.set("datedeb", this.workflow.ctxPeriode.datedeb);
    }
    if (this.workflow.ctxPeriode && this.workflow.ctxPeriode.datefin) {
      this.model.set("datefin", this.workflow.ctxPeriode.datefin);
    }
  },

  /**
   * Return the models of the use case
   */
  _getModel: function() {
    return this.model;
  },

  /**
   * Load the internal events of the view, and the events of TDA Date
   */
  events: _.extend({
    "change :input:not([readonly])": "_change"
  }, TYPE.DATE.events()),

  /**
   * Renders the view to the form
   */
  render: function() {
    var json = { "i18n": i18n };

    this.$el.html(this.template(json));
    return this;
  },

  /**
   * Renders the view to the form (referentiels, radiobuttons)
   */
  renderData: function() {
    FORMS.setDatepicker(this, ".datedeb");
    FORMS.setDatepicker(this, ".datefin");

    var context = {};
    context = {
      Habilitation: this.workflow.getHabContext(),
      name: "delegue.matricule",
      ctxEcran: this.ctxEcran

    };

    this._mapModelToForm(this.$el, this.model);

    this.referentiel = new SelectUtilisateurView({ host: this, idComponent: "refutiliz", context: context });
    this.$el.find(".droisDeleguesA").html(this.referentiel.render().el);
    this.$el.find(".droisDeleguesA .phx-selecteur-referentiel").css("width", "220px");

    this.referentiel.filterOut(this.referentielFilter);

    //generate the options of radioButon
    var options = [];
    var option = {};
    option.code = "all";
    option.libelle = i18n.t('droitsdel.retroAll');
    options.push(option);
    var option2 = {};
    option2.code = true;
    option2.libelle = i18n.t('droitsdel.retroTrue');
    options.push(option2);
    var option3 = {};
    option3.code = false;
    option3.libelle = i18n.t('droitsdel.retroFalse');
    options.push(option3);

    var radioRetro = new RadioBoxView({
      options: options,
      name: "retro",
      required: true,
      selectedOption: "all"
      //title : i18n.t('droitsdel.methode_title')
    }).render().el;
    this.$el.find(".radioRetro").html(radioRetro);
    //
    //		if (!STR.isBlank(this.model.get("datedeb"))) {
    //			this.$el.find(".datedeb").val(this.model.get("datedeb"));
    //		}
    //		if (!STR.isBlank(this.model.get("datefin"))) {
    //			this.$el.find(".datefin").val(this.model.get("datefin"));
    //		}

  },

  /**
   * Action performed when choosing a data or close a referentiel
   */
  _responseCallback: function(data) {
    var model = this.model;
    if (_.isNull(model.get("delegue")) || STR.isBlank(model.get("delegue"))) {
      model.set("delegue", {});
    }

    model.get("delegue").matricule = data.matricule;
    model.get("delegue").matriculeaux = data.matriculeaux;
    model.get("delegue").nom = data.nom;
    model.get("delegue").prenom = data.prenom;
    model.get("delegue").utilcode = data.utilcode;
    model.get("delegue").utilnom = data.utilnom;
  },

  /**
   * Filter the data return of the referentiel
   */
  referentielRestriction: function(matricule) {
    if (this.referentiel) {
      if (!STR.isBlank(matricule)) {
        this.referentiel.filterOut([matricule]);
      } else {
        this.referentiel.filterOut([]);
      }
    } else {
      this.referentielFilter = [matricule];
    }
  }

});