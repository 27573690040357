import { BaseModel } from '../../../../../../core/models/base.model.js';
import { BlockView } from '../../../../wkfhisto/views/block.view.js';
import { ComboBoxView2 } from '../../../../../../core/components/combo/comboBoxView2.js';

import { DataGridView } from '../../../../../../core/grids/data_grid/dataGrid.view.js';
import { DialogView } from '../../../../../../core/views/dialog.view.js';
import { EditableGridModel } from '../../../../../../core/grids/editablegrid/editableGrid.model.js';
import { EditableGroupeGridModel } from '../../../../../../core/grids/editablegroupegrid/editableGroupeGrid.model.js';
import { EditableGroupeGridView } from '../../../../../../core/grids/editablegroupegrid/editableGroupeGrid.view.js';
import { EditedRowView } from '../../../../../../core/views/editedRow.view.js';
import { EVENEMENT } from '../../../../../../utils/evenement.js';
import { FORMS } from '../../../../../../utils/forms.js';
import { FormView as CommonFormView } from '../../../../../../core/views/form.view.js';
import { GererPiecesJointesView } from '../../../gerer/gererpiecesjointes/views/gererPiecesJointes.view.js';
import { GererPretView } from '../../../gerer/gererprets/views/gererPret.view.js';
import { GLOBAL_DATA } from '../../../../../../globalData.js';
import { HABILITATION } from '../../../../../../utils/habilitation.js';
import { HabilitationContext } from '../../../../../../core/models/habilitationContext.js';
import { HeaderPopupView } from './headerPopup.view.js';
import { i18n } from '../../../../../../i18n.js';
import { ListerHistoriqueModel } from '../models/listerHistorique.model.js';
import { LOG } from '../../../../../../utils/log.js';
import { objs } from '../../../../../../objectsRepository.js';
import { PretColl } from '../models/pret.collection.js';
import { ProgressBarModel } from '../models/progressBar.model.js';
import { ResumePopUpView } from './resumePopUp.view.js';
import { RowPopupView } from './rowPopup.view.js';
import { STR } from '../../../../../../utils/str.js';
import { SYNC } from '../../../../../../utils/sync.js';
import { TYPE } from '../../../../../../tda/tda.js';
import { UTILS } from '../../../../../../utils/utils.js';
import TPL_listerprets_detail from '../detail.tpl.html';

export var FormView = CommonFormView.extend({
  /**
   * Add this to have Outline
   *
   */

  initialize: function(options) {
    this.template = TPL_listerprets_detail;

    // Auxi vars to print the historification title after the data is loaded.
    this.histoInfoBulles = [];
    this.titleCont = 0;

    if (options && options.workflow) {
      this.workflow = options.workflow;
    }

    if (options && options.context) {
      this.context = options.context;
    }

    if (options && options.emptyMessage) {
      this.emptyMessage = options.emptyMessage;
    }

    this.model = new BaseModel({
      // This values are set in the workflow.
      progressBarModel: new ProgressBarModel(),
      globalComment: "",
      isForced: false
    });

    this.model.setHabContext(new HabilitationContext());

    this.historiqueModel = new ListerHistoriqueModel();
    if (this.workflow && this.workflow.getHabContext()) {
      this.historiqueModel.setHabContext(this.workflow.getHabContext());
    }
    this.wkfEvenements = null;

    // Output mode of characteristics and grille:
    if (this.context && this.context.ctxPresentationGrille && this.context.ctxPresentationGrille === "Neutralisee") {
      // Regroupé & grille Neutralisée
      this.table = this._initTableEmprunts(this.emptyMessage);
    } else {
      // Éclaté & grille Habillée
      this.table = this._initTable(this.emptyMessage);
    }

    this.model.on("cleanGridErrors", this._cleanErrorsTable, this);
    this.table.model.coll.on("revert:tableNotProfils", function() { this._revertTable(false); }, this);
    this.table.model.coll.on("revert:table", function() { this._revertTable(true); }, this);

    this.copyModelsState = [];

    // To manage the btnBar, we initialize the counter to have the number of prets to treat.
    this.table.model.coll.nbrecEtatValid = 0;
    this.table.model.coll.nbrecEtatA = 0;
    this.table.model.coll.nbrecEtatR = 0;

    this.isSortedAsc = true;
  },

  _hideZonesGrid: function() {
    //header
    $(this.table.el).find(".phx-hd-btn").hide();
    $(this.table.el).find(".phx-grid-title-bar").hide();
    $(this.table.el).find(".phx-grid-header-scroll").hide();
    //footer
    $(this.table.el).find(".phx-grid-footer-left").hide();
    $(this.table.el).find(".phx-grid-footer-right").hide();
  },

  resetAfterContextChange: function() {
    // To manage the btnBar, we initialize the counter to have the number of prets to treat.
    this.table.model.coll.nbrecEtatValid = 0;
    this.table.model.coll.nbrecEtatA = 0;
    this.table.model.coll.nbrecEtatR = 0;
    this.copyModelsState = this._copyModels(this.table.model.coll);

    this.workflow.btnBarModel.set("mode", "R");

    $(this.el).find(".checkBoxHeader.R").prop("checked", false);
    $(this.el).find(".checkBoxHeader.A").prop("checked", false);

    STR.setElValue(this.model, "globalComment", "");
  },

  events: {
    "click .linkCom": "_openRowPopup",
    "click .checkBoxHeader": "_manageHeaderCheckbox",
    "click .checkBoxLine": "_manageLineCheckbox",
    "click button": "_clickListener",
    "change input[class*='comboProfil']": "_manageRowProfil",
    "click a.pret-link": "_detailPret",
    "click .piece-jointe": "_managePieceJointe",
    "mouseover .infoBulleStatut": "_paintStatusInfoBull"
  },

  _revertTable: function(all) {
    var revertAll = false;
    if (all === true) {
      revertAll = true;
    }
    var self = this;
    var tableColl = this.table.model.coll;
    _.each(tableColl.models, function(model) {
      self._revertRow(model, revertAll);
    });

    STR.setElValue(this.model, "globalComment", "");
    $(this.el).find(".checkBoxHeader.A").prop("checked", false);
    $(this.el).find(".checkBoxHeader.R").prop("checked", false);

    this.table.model.coll.nbrecEtatValid = 0;
    this.table.model.coll.nbrecEtatA = 0;
    this.table.model.coll.nbrecEtatR = 0;
    this.copyModelsState = this._copyModels(this.table.model.coll);

    this._displayHeaderLeftText();
    this._displayHeaderRightText();
    this.table.model.coll.trigger("updateHeader:coll");
    this.table.model.coll.trigger("display:alert", "hide");

    this._cleanErrorsTable();
    this.workflow.btnBarModel.set("mode", "R");
  },

  _revertRow: function(model, all) {
    STR.setElValue(model, "commentaire", "");

    if (model.recEtatValid === "A") {
      $(this.el).find(".checkBoxLine.A").prop("checked", false);
    } else if (model.recEtatValid === "R") {
      $(this.el).find(".checkBoxLine.R").prop("checked", false);
    }

    //		$(this.el).find(".linkCom").toggleClass('phx-icon-comment-black phx-icon-comment-white');

    model.recEtatValid = "";

    if (all === true) {
      var comboProfil = $(this.el).find(".comboProfil_" + model.cid);
      if (comboProfil.length > 0 && model.selectedProfil && model.selectedProfil.code != "0") {
        var item = $(this.el).find(".comboProfil_" + model.cid).prop("viewRef");
        item.setItem("");
        model.selectedProfil = {
          code: "0",
          libelle: ""
        };
      }
    }
  },

  _openHeaderPopup: function(event) {
    var self = this;
    var comment = this.model.get("globalComment");
    var isforced = this.model.get("isForced");
    var action = event.currentTarget.className.split(" ")[1];

    if (this.headerDialog === undefined) {
      this.headerDialog = new DialogView({
        view: HeaderPopupView,
        viewData: { commentaire: comment, isForced: isforced, action: action }
      });
    } else {
      this.headerDialog.viewData = { commentaire: comment, isForced: isforced, action: action };
    }
    this.headerDialog.setHeight(430);
    this.headerDialog.setWidth(500);

    var dialogModel = this.headerDialog.model;
    dialogModel.trigger("dlg:open", function() {
      if (dialogModel.get("isCommentChanged") === true) {
        var newCommentaire = dialogModel.get("globalComment");
        var isForced = dialogModel.get("isForced");
        self._manageComments(newCommentaire, isForced);
        self.model.set("globalComment", newCommentaire);
        self.model.set("isForced", isForced);
        self.copyModelsState = self._copyModels(self.table.model.coll);
      }
    });
    this.headerDialog._setTitle(i18n.t('common:listerprets.commentaireGlobalPopup'));
  },

  _openRowPopup: function(event) {
    var id = event.currentTarget.className.split(" ")[2];
    var model = this.table.model.coll.get(id);
    if (model == undefined) {
      $.each(this.table.model.coll.models, function(i, line) {
        if (UTILS.escapeJQueryString(line.get("code")) === id) {
          model = line;
        }
      });
    }
    var comment = !STR.isBlank(model.get("commentaire")) ? model.get("commentaire") : "";
    var refEvenement = model.get("evenement");

    if (this.rowDialog === undefined) {
      this.rowDialog = new DialogView({
        view: RowPopupView,
        viewData: {
          commentaire: comment,
          action: model.recEtatValid,
          refEvenement: refEvenement,
          workflow: this.workflow
        }
      });
    } else {
      this.rowDialog.viewData = {
        commentaire: comment,
        action: model.recEtatValid,
        refEvenement: refEvenement,
        workflow: this.workflow
      };
    }

    this.rowDialog.setHeight(430);
    this.rowDialog.setWidth(500);

    var self = this;
    var dialogModel = this.rowDialog.model;
    dialogModel.trigger("dlg:open", function() {
      if (dialogModel.get("isCommentChanged") === true) {
        var commentaire = dialogModel.get("commentaire");
        model.set("commentaire", commentaire);
        self.copyModelsState = self._copyModels(self.table.model.coll);
      }
    });
    this.rowDialog._setTitle(i18n.t('common:listerprets.commentairePopup'));
  },

  /*
   *  Change the comment in each absence if it is selected and it has the old global comment.
   *
   */
  _manageComments: function(newCommentaire, isForced) {
    var ancientCommentaire = this.model.get("globalComment");
    var tableColl = this.table.model.coll;
    _.each(tableColl.models, function(tableRow) {
      if (!STR.isBlank(tableRow.recEtatValid)) {
        if (isForced === true) {
          tableRow.set("commentaire", newCommentaire);
        } else if (tableRow.get("commentaire") === ancientCommentaire || STR.isBlank(tableRow.get("commentaire"))) {
          tableRow.set("commentaire", newCommentaire);
        }
      }
    });
  },

  _manageHeaderCheckbox: function(event) {
    var checked = event.currentTarget.checked;
    var action = event.currentTarget.className.split(" ")[1];

    if (checked === true) {
      this.table.model.coll.nbrecEtatValid = 0;
      this.table.model.coll.nbrecEtatA = 0;
      this.table.model.coll.nbrecEtatR = 0;
    }

    this._manageAllRows(action, checked);
    this._manageBtnBarActivation();
    this._manageFiltreEnabled();
    this._displayHeaderLeftText();
    this._displayHeaderRightText();
    this._checkNbAlert();
    this.copyModelsState = this._copyModels(this.table.model.coll);

    switch (action) {
      case "A":
        if (checked === true) {
          $(this.el).find(".checkBoxLine.A").prop("checked", true);
          $(this.el).find(".checkBoxLine.R").prop("checked", false);
          $(this.el).find(".checkBoxHeader.R").prop("checked", false);
          this._openHeaderPopup(event);
        } else {
          $(this.el).find(".checkBoxLine.A").prop("checked", false);
          $(this.el).find(".checkBoxHeader.A").prop("checked", false);
        }
        break;
      case "R":
        if (checked === true) {
          $(this.el).find(".checkBoxLine.R").prop("checked", true);
          $(this.el).find(".checkBoxLine.A").prop("checked", false);
          $(this.el).find(".checkBoxHeader.A").prop("checked", false);
          this._openHeaderPopup(event);
        } else {
          $(this.el).find(".checkBoxLine.R").prop("checked", false);
          $(this.el).find(".checkBoxHeader.R").prop("checked", false);
        }
        break;
      default:
        break;
    }
    this.table.model.coll.trigger("updateHeader:coll");
  },

  _checkNbAlert: function() {
    var nbAlert = this.workflow.pDiversValRAalertModel.get("valeur");
    var nbChecked = this.table.model.coll.nbrecEtatValid;

    if (STR.isBlank(nbAlert)) { nbAlert = 5; } // Default Value

    if (nbChecked >= nbAlert) {
      this.table.model.coll.trigger("display:alert", "show");
    } else {
      this.table.model.coll.trigger("display:alert", "hide");
    }
  },

  _manageAllRows: function(action, checked) { // action A, R, ""
    var tableColl = this.table.model.coll;
    var self = this;

    _.each(tableColl.models, function(tableRow) {
      tableRow.recEtatValid = "";
    });

    _.each(tableColl.models, function(tableRow) {
      self._manageRow(tableRow, action, checked);
    });
    this.copyModelsState = this._copyModels(this.table.model.coll);
  },

  _manageRow: function(model, action, checked) { // action A, R, ""
    var tableColl = this.table.model.coll;
    if (checked === true) {
      if (model.recEtatValid == "R") {
        tableColl.nbrecEtatR--;
        tableColl.nbrecEtatValid--;
      } else if (model.recEtatValid == "A") {
        tableColl.nbrecEtatA--;
        tableColl.nbrecEtatValid--;
      }

      model.recEtatValid = action === "A" ? "A" : "R";
      model.nbrecEtatValid = action === "A" ? tableColl.nbrecEtatA++ : tableColl.nbrecEtatR++;

      tableColl.nbrecEtatValid++;
    } else {
      model.nbrecEtatValid = action === "A" ? tableColl.nbrecEtatA-- : tableColl.nbrecEtatR--;
      model.recEtatValid = "";
      tableColl.nbrecEtatValid--;
    }
  },

  _manageBtnBarActivation: function() {
    var tableColl = this.table.model.coll;

    if (tableColl.nbrecEtatValid === 0) {
      this._cleanErrorsTable();
      this.workflow.btnBarModel.set("mode", "R");
    } else {
      this.workflow.btnBarModel.set("mode", "E");
    }
  },

  _manageFiltreEnabled: function() {
    var tableColl = this.table.model.coll;

    if (tableColl.nbrecEtatValid === 0) {
      this.workflow.enableFiltering();
    } else {
      this.workflow.disableFiltering();
    }
  },

  _displayHeaderLeftText: function() {
    var context = this.workflow.context;
    if (context && context.ctxGestionCollective == true && context.ctxValidation == true) {
      this.table.model.coll.trigger("display:leftText", "show");
    } else {
      this.table.model.coll.trigger("display:leftText", "hide");
    }
  },

  _displayHeaderRightText: function() {
    var tableColl = this.table.model.coll;

    if (tableColl.nbrecEtatValid === 0) {
      this.table.model.coll.trigger("display:rightText", "hide");
    } else {
      this.table.model.coll.trigger("display:rightText", "show");
    }
  },

  _manageLineCheckbox: function(event) {
    var checked = event.currentTarget.checked;
    var action = event.currentTarget.className.split(" ")[1];
    var id = event.currentTarget.className.split(" ")[2];

    var model = this.table.model.coll.get(id);
    if (model == undefined) {
      $.each(this.table.model.coll.models, function(i, line) {
        if (UTILS.escapeJQueryString(line.get("code")) === id) {
          model = line;
        }
      });
    }
    this._manageRow(model, action, checked);
    this._manageBtnBarActivation();
    this._manageFiltreEnabled();
    this._displayHeaderLeftText();
    this._displayHeaderRightText();
    this._checkNbAlert();

    this.copyModelsState = this._copyModels(this.table.model.coll);
    switch (action) {
      case "A":
        if (checked === true) {
          $(this.el).find(".checkBoxLine.R." + id).prop("checked", false);
          $(this.el).find(".checkBoxHeader.R").prop("checked", false);
        } else {
          $(this.el).find(".checkBoxHeader.A").prop("checked", false);
        }
        break;
      case "R":
        if (checked === true) {
          $(this.el).find(".checkBoxLine.A." + id).prop("checked", false);
          $(this.el).find(".checkBoxHeader.A").prop("checked", false);
          this._openRowPopup(event);
        } else {
          $(this.el).find(".checkBoxHeader.R").prop("checked", false);
        }
        break;
      default:
        break;
    }

    if (this._checkAllRowsActived(action) === true) {
      $(this.el).find(".checkBoxHeader." + action).prop("checked", true);
    }
    if (!checked || model.isValid()) {
      model.trigger("cleanError", model);
    }
    this.table.model.coll.trigger("updateHeader:coll");
  },

  _checkAllRowsActived: function(action) {
    var arrayCheck = $(this.el).find(".checkBoxLine." + action);
    for (var i = 0; i < arrayCheck.length; i++) {
      if (arrayCheck[i].checked === false) {
        return false;
      }
    }
    return true;
  },

  render: function() {
    var json = { "i18n": i18n };
    this.$el.html(this.template(json));

    if (this.context && this.context.ctxPresentationGrille && this.context.ctxPresentationGrille === "Neutralisee") {
      return this.renderNeutralisee();
    }
    $(this.el).find(".tablePrets").html(this.table.el);
    this.table.render();

    this.table.setTitle(this._getTitle());
    this.table.model.coll.trigger("display:alert", "hide");
    this.table.model.coll.trigger("display:rightText", "hide");
    this.table.model.coll.trigger("display:leftText", "hide");

    return this;
  },

  renderNeutralisee: function() {
    this.table.model.coll.fetch({
      success: function(fresh) {
        // allows bemprunt to clone collection
        fresh.trigger("ready");
      },
    });
    $(this.el).find(".tablePrets").html(this.table.el);
    this.table.render();
    // mode grille Neutralisee
    this._hideZonesGrid();

    return this
  },

  _clickListener: function(event) {
    var btnClicked = event.currentTarget.value;
    this.model.get("progressBarModel").trigger("btn:click", btnClicked);
  },

  _showGridValidationErrors: function(model, errors) {
    var self = this;
    if (errors && _.has(errors, "errorValidation") && _.has(errors, "errors")) {
      var fieldErrors = errors.errors;
      var comboName = "comboProfil_" + model.cid;
      var combo = $(self.el).find("." + comboName);
      var td = combo.closest('td');
      FORMS.showErrors(td, fieldErrors);
    }
  },

  _cleanGridValidationErrors: function(model) {
    var comboName = ".comboProfil_" + model.cid;
    var combo = $(this.el).find(comboName);
    var td = combo.closest('td');
    FORMS.cleanErrors(td);
  },

  /**
   * Open detail du pret
   */
  _detailPret: function(event) {
    LOG.debug("-------------------- detailPret called --------------------------");
    var currentTarget = event.currentTarget;
    var modelID = $(currentTarget).attr("data-id");
    var pretModel = this.table.model.coll.get(modelID);

    if (_.isUndefined(pretModel)) {
      pretModel = _.find(this.table.model.coll.models, function(model) {
        if (UTILS.escapeJQueryString(model.get("code")) === modelID) {
          return model;
        }
        return null;
      });
    }
    if (!STR.isBlank(pretModel)) {
      var context = {};
      context.ctxUtilisateur = this.workflow.context.ctxUtilisateur;

      if (pretModel.get("emprunt") == true) {
        context.ctxPerimetre = pretModel.get("perimetre").code;
        context.ctxTypeEvtGere = "E";
      } else {
        context.ctxPerimetre = "";
        context.ctxTypeEvtGere = "P";
      }

      context.ctxHabilitation = {};
      if (this.workflow.context.ctxUtilisateur == this.workflow.COLLABORATEUR) {
        context.ctxHabilitation.HabilitationAcces = "COL_PRET.V";
        context.ctxHabilitation.HabilitationGestion = "";
        context.ctxHabilitation.HabilitationValidation = "";
        context.ctxActionsPossibles = "";
      } else {
        context.ctxHabilitation.HabilitationAcces = "RES_PRET.V";
        context.ctxHabilitation.HabilitationValidation = "RES_VAL_PRET.G";
        context.ctxActionsPossibles = ["Creer", "Modifier", "Supprimer", "Valider"];

        if (context.ctxTypeEvtGere == "P") {
          context.ctxHabilitation.HabilitationGestionNormal = "RES_PRET_N.G";
          context.ctxHabilitation.HabilitationGestionEtendu = "RES_PRET_E.G";
        } else {
          context.ctxHabilitation.HabilitationGestionNormal = "RES_EMPR_N.G";
          context.ctxHabilitation.HabilitationGestionEtendu = "RES_EMPR_E.G";
        }
      }

      context.ctxEcran = this.workflow.context.ctxEcran;
      context.ctxModeRepresentation = "pop-up";

      context.ctxCollab = {};
      context.ctxCollab.matricule = pretModel.get("collaborateur").matric;
      context.ctxCollab.matric_aux = pretModel.get("collaborateur").matricaux;
      context.ctxCollab.nom = pretModel.get("collaborateur").nom;
      context.ctxCollab.prenom = pretModel.get("collaborateur").prenom;

      context.ctxListeCollab = "";
      context.ctxModeInitialisation = "Consultation";
      context.ctxIdentifiantEvt = pretModel.get("code");
      context.ctxHistoriqueWKF = true;
      context.ctxPieceJointe = true;

      if (!STR.isBlank(pretModel.get("collabremplace")) && !STR.isBlank(pretModel.get("collabremplace").matric)) {
        context.ctxCollabReference = {};
        context.ctxCollabReference.matricule = pretModel.get("collabremplace").matric;
        context.ctxCollabReference.matric_aux = pretModel.get("collabremplace").matricaux;
        context.ctxCollabReference.nom = pretModel.get("collabremplace").nom;
        context.ctxCollabReference.prenom = pretModel.get("collabremplace").prenom;
        context.ctxCollabReference.datedeb = pretModel.get("collabremplace").datedeb;
        context.ctxCollabReference.datefin = pretModel.get("collabremplace").datefin;
      }

      context.ctxPerimetreFige = false;
      if (!STR.isBlank(this.workflow.context.ctxSimulation)) {
        context.ctxHabilitation.HabilitationValidation = "";
        context.ctxSimulation = this.workflow.context.ctxSimulation;
        context.ctxEvtEstSimule = this.workflow.context.ctxEvtEstSimule;
        context.ctxDonneeSimulee = this.workflow.context.ctxDonneeSimulee;
        context.ctxPerimetreFige = true;
        context.ctxHistoriqueWKF = false;
        context.ctxPieceJointe = false;
        if ( /*this.context.ctxJourEstSimule == 3 && */ HABILITATION.canView("RES_PLANSIM.G")) {
          context.ctxActionsPossibles = ["Creer", "Modifier", "Supprimer"];
        } else {
          context.ctxActionsPossibles = [];
        }
      }

      this._initPopupGererPret(context);

      this.popupGerePretDialog.open();

      var self = this;
      this.listenTo(this.popupGerePretDialog.internalView.model, "pretChanged", function(model, action) {
        self.workflow.setUp();
        self.workflow.trigger("listerpretsUpdated");
        self.workflow.trigger("pretChanged", model, action);
      });
    }
  },

  _initPopupGererPret: function(context) {
    if (this.popupGerePretDialog === undefined) {

      this.popupGerePretDialog = new DialogView({
        view: GererPretView,
        viewData: {
          context: context
        }
      });
    } else {
      this.popupGerePretDialog.viewData = { context: context };
    }

    this.popupGerePretDialog.setHeight(750);
    this.popupGerePretDialog.setWidth(600);
    return this.popupGerePretDialog;
  },

  _initTableEmprunts: function(emptyMessage) {
    var self = this;
    var tableModel = new EditableGridModel({
      coll: new PretColl(),
      emptyMessage: emptyMessage
    });

    var tblColumnsSynt = [
      { title: "Collab demande Emprunt", property: "matricule", width: 260 },
      { title: "periode,statud,rattachemment", property: "composed", width: "100%" }
    ];

    var table = new DataGridView({
      id: "listeEmprunts",
      columns: tblColumnsSynt,
      model: tableModel,
      columnsChooser: false,
      removeGridStyle: true,
      title: null,
      showFilteredRowsInTitle: false,
      maxVisibleRows: 10,
      selectable: false,
      gridHeightType: "auto",
      habilitations: "RES_PRET.V",
      emptyMessage: i18n.t('common:listerprets.grid_emprunt_empty')
    });

    tableModel.coll.usePopulation = this.usePopulation;
    tableModel.coll.setHabContext(this.context.habContext);
    tableModel.coll.setParametres(this.context);
    table.setSortableColumns([]);

    table.cellRenderers["matricule"] = function(model) {
      var paramIdCollab = self.workflow ? self.workflow.pDiversIdCollabModel.get("valeur") : GLOBAL_DATA.paramDivers.get("idCollab").get("valeur");
      var matricule = paramIdCollab === "matric_aux" ? model.get("collaborateur").matricaux : model.get("collaborateur").matric;
      var ligne1 = model.get("collaborateur").nom.toUpperCase() + " " + model.get("collaborateur").prenom + " (" + matricule + ")";

      return ligne1;
    };
    table.cellRenderers["composed"] = function(model) {

      //periode
      var ligneError = "";
      var periode = "<a class='pret-link' data-id='" + UTILS.escapeJQueryString(model.get("code")) + "'>";
      switch (model.get("typesaisie")) {
        case "D":
          periode += i18n.t('common:listerprets.le') + " <span class='bold'>" + TYPE.DATE.format(model.get("datedeb"), TYPE._getFormatByCode("DATE_A")) + "</span>";
          break;
        case "R":
          periode += i18n.t('common:listerprets.du') + " <span class='bold'>" + TYPE.DATE.format(model.get("datedeb"), TYPE._getFormatByCode("DATE_A")) + "</span> " + i18n.t('common:listerprets.au') + " <span class='bold'>" + TYPE.DATE.format(model.get("datefin"), TYPE._getFormatByCode("DATE_A")) + "</span>";
          break;
        case "P":
          var deb = "",
            fin = "";
          if (model.get("unitedeb").code == "H") {
            deb = TYPE.HOUR_MINUTE.format(model.get("heuredeb"));
          } else {
            if (model.get("unitefin").code != "J") {
              deb = model.get("unitedeb").libelle;
            }
          }
          if (model.get("unitefin").code == "H") {
            fin = " " + TYPE.HOUR_MINUTE.format(model.get("heurefin"));
          } else {
            if (model.get("unitefin").code != "J") {
              fin = " " + model.get("unitefin").libelle;
            }
          }
          periode += i18n.t('common:listerprets.du') + " <span class='bold'>" + TYPE.DATE.format(model.get("datedeb"), TYPE._getFormatByCode("DATE_A")) + "</span> " + deb + " " + i18n.t('common:listerprets.au') + "  <span class='bold'>" + TYPE.DATE.format(model.get("datefin"), TYPE._getFormatByCode("DATE_A")) + "</span>" + fin;
          break;
        default:
          break;
      }
      periode += "</a>";
      //Error
      //			if(self.model.objErrors && self.model.objErrors.length > 0){
      //				var error = self._findError(model.get("code"));
      //				if(!STR.isBlank(error)){
      //					ligneError = self._buildMessageErrorPer(error);
      //				}
      //			}
      if (!STR.isBlank(ligneError)) {
        periode += " <br>" + ligneError;
      }

      //statud
      var stat = model.get("statut").code;
      var style = "ui-phx-statut-demande";
      switch (stat) {
        case "E":
          style = "ui-phx-statut-en-cours-saisie";
          break;
        case "F":
        case "G":
          style = "ui-phx-statut-en-cours-revision";
          break;
        case "C":
        case "M":
          style = "ui-phx-statut-en-cours-modification";
          break;
        case "Y":
        case "Z":
          style = "ui-phx-statut-a-revoir";
          break;
        case "A":
          style = "ui-phx-statut-accepte";
          break;
        case "D":
          style = "ui-phx-statut-demande";
          break;
        case "H":
          style = "ui-phx-statut-hors-validation";
          break;
        case "R":
          style = "ui-phx-statut-refuse";
          break;
        case "I":
        case "T":
          style = "ui-phx-statut-en-cours";
          break;
        default:
          break;
      }

      var statud = "<span class='" + style + "'>" + model.get("statut").libelle + "</span>";

      //Rattachement
      var rattachement = "";
      if (!_.isUndefined(model.get("structures")) && model.get("structures") != null &&
        model.get("structures").length > 0) {
        var libelleFormat = EVENEMENT.getLibelle(model.get('structures')[0].format, model.get('structures')[0].itemcode, model.get('structures')[0].itemlib);
        rattachement += "<a class='pret-link' data-id='" + UTILS.escapeJQueryString(model.get("code")) + "'>" + libelleFormat + "</a>";
      }

      //forms the line
      var composed = "";
      if (stat === "H") {
        composed = periode + ", " + rattachement;
      } else {
        composed = periode + ", " + statud + ", " + rattachement;
      }
      return composed;
    };

    tableModel.off("vue:change");
    tableModel.on("vue:change", this._vueChanged, this);

    return table;
  },

  _initTable: function(emptyMessage) {
    var self = this;

    ///////////////////////////////////////

    var tableModel = new EditableGroupeGridModel({
      coll: new PretColl(),
      isEmptyRowSelection: emptyMessage
    });

    this.listenTo(tableModel.coll, "sync", function() {
      self.stopListening(null, "cleanError");
      tableModel.coll.forEach(function(model) {
        model.off("invalid");
        model.on("invalid", self._showGridValidationErrors, self);
        if (self.managingVueChange == true) {
          self.managingVueChange = false;
        }
        self.listenTo(model, "cleanError", self._cleanGridValidationErrors);
      });
    });

    var disabled = "";
    if (HABILITATION.canView("RES_VAL_PRET.G") == false) {
      disabled = " disabled='disabled'";
    }

    var tblColumnsDetaille = [
      { title: "", property: "typepret", width: 30, groupe: true },
      { title: i18n.t('common:listerprets.grid_role'), property: "profils", width: 120 },
      { title: i18n.t('common:listerprets.grid_collaborateur'), property: "matricule", width: 220 },
      { title: i18n.t('common:listerprets.grid_periode'), property: "periode", width: 300 },
      { title: i18n.t('common:listerprets.grid_emprunt'), property: "emprunt", width: 35 },
      { title: i18n.t('common:listerprets.grid_motif'), property: "motif", width: 120 },
      { title: i18n.t('common:listerprets.grid_partiejournee'), property: "partiejournee", width: 100 },
      { title: i18n.t('common:listerprets.grid_rattach'), property: "structures", width: 100 },
      { title: i18n.t('common:listerprets.grid_multiple'), property: "multiple", width: 30 },
      { title: i18n.t('common:listerprets.grid_statut'), property: "statut", width: 90 },
      { title: "<span class='linkComHeader'>" + i18n.t('common:listerprets.grid_comentaire') + "</span>", property: "commentaire", width: 45 },
      { title: i18n.t('common:listerprets.grid_accepter') + "<br><input " + disabled + " class='checkBoxHeader A' type='checkbox'  value='a'/>", property: "a", width: 25 },
      { title: i18n.t('common:listerprets.grid_refuser') + "<br><input  " + disabled + " class='checkBoxHeader R' type='checkbox'  value='r'/>", property: "r", width: 25 },
      { title: i18n.t('common:listerprets.grid_pj'), property: "pj", width: 25 }
    ];
    var tblColumnsSynt = [
      { title: "", property: "typepret", width: 30, groupe: true },
      { title: i18n.t('common:listerprets.grid_role'), property: "profils", width: 120 },
      { title: i18n.t('common:listerprets.grid_collaborateur'), property: "matricule", width: 220 },
      { title: i18n.t('common:listerprets.grid_periode'), property: "periode", width: 300 },
      { title: i18n.t('common:listerprets.grid_emprunt'), property: "emprunt", width: 35 },
      { title: i18n.t('common:listerprets.grid_motif'), property: "motif", width: 120 },
      { title: i18n.t('common:listerprets.grid_partiejournee'), property: "partiejournee", width: 100 },
      { title: i18n.t('common:listerprets.grid_rattach'), property: "structures", width: 100 },
      { title: i18n.t('common:listerprets.grid_multiple'), property: "multiple", width: 30 },
      { title: i18n.t('common:listerprets.grid_statut'), property: "statut", width: 90 },
      { title: "<span class='linkComHeader'>" + i18n.t('common:listerprets.grid_comentaire') + "</span>", property: "commentaire", width: 45 },
      { title: i18n.t('common:listerprets.grid_accepter') + "<br><input " + disabled + " title='" + i18n.t('common:listerprets.grid_toutaccepter_title') + "' class='checkBoxHeader A' type='checkbox'  value='a'/>", property: "a", width: 25 },
      { title: i18n.t('common:listerprets.grid_refuser') + "<br><input " + disabled + " title='" + i18n.t('common:listerprets.grid_toutrefuser_title') + "' class='checkBoxHeader R' type='checkbox'  value='r'/>", property: "r", width: 25 },
      { title: i18n.t('common:listerprets.grid_pj'), property: "pj", width: 25 }
    ];

    var vues = {};
    vues[i18n.t('common:listerprets.vue_synt')] = { columns: tblColumnsSynt };
    vues[i18n.t('common:listerprets.vue_detaille')] = { columns: tblColumnsDetaille };

    var title = this._getTitle();

    //Added "nolimite" to suivicollab and planning
    var viewRow = new EditedRowView();

    var table = new EditableGroupeGridView({
      id: "listerprets_table",
      viewRow: viewRow,
      vues: vues,
      defaultVue: i18n.t('common:listerprets.vue_synt'),
      model: tableModel,
      title: title,
      showFilteredRowsInTitle: true,
      gridHeightType: "auto",
      checkGroup: false, //pour indiquer que c'est un groupe spécial de regroupement (avec sous-groupes) ou non
      enableEmptyRow: true,
    });
    table.allowEdition(false);
    if (self.context && self.context.ctxEcran && self.context.ctxEcran == "suivicollab" &&
      (HABILITATION.canCreate("RES_PRET_N.G") || HABILITATION.canCreate("RES_PRET_E.G"))) {
      table.buttonBar.addButton(i18n.t('common:listerprets.btnCreer'), "newPret", false);
      table.buttonBar.buttons.visibleInModeC["newPret"] = true;
      table.overrideMenu(this.newPret, this);
    }
    table.buttonBar.model.trigger("hide:new");
    table.buttonBar.model.trigger("hab.hide:delete");
    table.buttonBar.model.trigger("hide:copy");

    table.cellRenderers["typepret"] = function(model) {
      if (model.get("typepret") == "R") {
        return i18n.t('common:listerprets.grid_emprunt_a_valider');
      } else {
        return self._getTitle();
      }
    };

    table.cellRenderers["profils"] = function(model) {
      var profils = model.get("profils");

      if (profils && profils.length == 1) {
        model.selectedProfil = profils[0];
        return profils[0].libelle;

      } else if (profils && profils.length > 1) {
        var comboName = "comboProfil_" + model.cid;
        var combo = new ComboBoxView2({
          enum: profils,
          name: comboName,
          width: "100",
          habContext: self.model.getHabContext()
        });

        //render combo and error or only combo.
        var label = $("<label>").attr("for", comboName);
        var container = $("<div>");
        /*if(!STR.isBlank(model.profilErrors)){
        	label.attr("title",model.profilErrors).addClass("griderror");
        	combo.name = combo.name + " ui-state-error";
        }*/
        container.append(label).append(combo.render().el);
        var item = null;
        if (model.selectedProfil && model.selectedProfil.code != "0") {
          item = { code: model.selectedProfil.code, libelle: model.selectedProfil.libelle };
        }
        combo.setItem(item);
        return container;
      }
      return "";
    };

    //TODO add icon
    table.cellRenderers["matricule"] = function(model) {
      var paramIdCollab = self.workflow.pDiversIdCollabModel.get("valeur");
      var matricule = paramIdCollab === "matric_aux" ? model.get("collaborateur").matricaux : model.get("collaborateur").matric;
      var ligne1 = model.get("collaborateur").nom.toUpperCase() + " " + model.get("collaborateur").prenom + " (" + matricule + ")";
      ligne1 += "<span style='display:inline-block;margin-left:3px;'>";
      switch (model.get("collaborateur").indicpret_resp) {
        case "P":
          ligne1 += " <span class='phx-icon ui-phx-icon-metier ui-phx-marqueur-collab-prete'>&nbsp;</span>";
          break;
        case "R":
          ligne1 += " <span class='phx-icon ui-phx-icon-metier ui-phx-marqueur-collab-recu'>&nbsp;</span>";
          break;
        case "I":
          ligne1 += " <span class='phx-icon ui-phx-icon-metier ui-phx-marqueur-collab-prete-interne'>&nbsp;</span>";
          break;
      }
      ligne1 += "</span>";
      return ligne1;
    };

    table.vues[i18n.t('common:listerprets.vue_detaille')].cellRenderers["periode"] = function(model) {
      var ligneError = "";
      var periode = "<a class='pret-link' data-id='" + UTILS.escapeJQueryString(model.get("code")) + "'>";
      switch (model.get("typesaisie")) {
        case "D":
          periode += i18n.t('common:listerprets.le') + " <span class='bold'>" + TYPE.DATE.format(model.get("datedeb"), TYPE._getFormatByCode("DATE_A")) + "</span>";
          break;
        case "R":
          periode += i18n.t('common:listerprets.du') + " <span class='bold'>" + TYPE.DATE.format(model.get("datedeb"), TYPE._getFormatByCode("DATE_A")) + "</span> " + i18n.t('common:listerprets.au') + " <span class='bold'>" + TYPE.DATE.format(model.get("datefin"), TYPE._getFormatByCode("DATE_A")) + "</span>";
          break;
        case "P":
          var deb, fin;
          if (model.get("unitedeb").code == "H") {
            deb = TYPE.HOUR_MINUTE.format(model.get("heuredeb"));
          } else {
            deb = model.get("unitedeb").libelle;
          }
          if (model.get("unitefin").code == "H") {
            fin = TYPE.HOUR_MINUTE.format(model.get("heurefin"));
          } else {
            fin = model.get("unitefin").libelle;
          }
          periode += i18n.t('common:listerprets.du') + " <span class='bold'>" + TYPE.DATE.format(model.get("datedeb"), TYPE._getFormatByCode("DATE_A")) + "</span> " + deb + " " + i18n.t('common:listerprets.au') + "  <span class='bold'>" + TYPE.DATE.format(model.get("datefin"), TYPE._getFormatByCode("DATE_A")) + "</span> " + fin;
          break;
        default:
          break;
      }
      periode += "</a>";

      //Dernière action
      var histoObj = self._getHistoObj(model.get("evenement"));
      if (!STR.isBlank(histoObj) && !STR.isBlank(histoObj.acte)) {
        var acteur = "";
        var strDernierAction = "";
        var date = TYPE.DATE.format(histoObj.moments[0]["@date"], TYPE._getFormatByCode("DATE_A"));
        if (!STR.isBlank(histoObj.auteur["@auteur"])) {
          acteur = histoObj.auteur["@auteur"];
          strDernierAction = i18n.t('common:listerprets.grid_derniereaction1', { "0": acteur, "1": date, interpolation: { escapeValue: false } });
        } else {
          acteur = histoObj.auteur["message"];
          strDernierAction = i18n.t('common:listerprets.grid_derniereaction2', { "0": acteur, "1": date, interpolation: { escapeValue: false } });
        }
        periode += "<br><span title='" + i18n.t('common:listerprets.grid_derniereaction_title') + "'>" + strDernierAction + "</span>";
      }

      //Error
      if (self.model.objErrors.length > 0) {
        var error = self._findError(model.get("code"));
        if (!STR.isBlank(error)) {
          ligneError = self._buildMessageErrorPer(error);
        }
      }
      if (!STR.isBlank(ligneError)) {
        periode += " <br>" + ligneError;
      }
      return periode;
    };

    table.vues[i18n.t('common:listerprets.vue_synt')].cellRenderers["periode"] = function(model) {
      var ligneError = "";
      var periode = "<a class='pret-link' data-id='" + UTILS.escapeJQueryString(model.get("code")) + "'>";
      switch (model.get("typesaisie")) {
        case "D":
          periode += i18n.t('common:listerprets.le') + " <span class='bold'>" + TYPE.DATE.format(model.get("datedeb"), TYPE._getFormatByCode("DATE_A")) + "</span>";
          break;
        case "R":
          periode += i18n.t('common:listerprets.du') + " <span class='bold'>" + TYPE.DATE.format(model.get("datedeb"), TYPE._getFormatByCode("DATE_A")) + "</span> " + i18n.t('common:listerprets.au') + " <span class='bold'>" + TYPE.DATE.format(model.get("datefin"), TYPE._getFormatByCode("DATE_A")) + "</span>";
          break;
        case "P":
          var deb, fin;
          if (model.get("unitedeb").code == "H") {
            deb = TYPE.HOUR_MINUTE.format(model.get("heuredeb"));
          } else {
            deb = model.get("unitedeb").libelle;
          }
          if (model.get("unitefin").code == "H") {
            fin = TYPE.HOUR_MINUTE.format(model.get("heurefin"));
          } else {
            fin = model.get("unitefin").libelle;
          }
          periode += i18n.t('common:listerprets.du') + " <span class='bold'>" + TYPE.DATE.format(model.get("datedeb"), TYPE._getFormatByCode("DATE_A")) + "</span> " + deb + " " + i18n.t('common:listerprets.au') + "  <span class='bold'>" + TYPE.DATE.format(model.get("datefin"), TYPE._getFormatByCode("DATE_A")) + "</span> " + fin;
          break;
        default:
          break;
      }
      periode += "</a>";
      //Error
      if (self.model.objErrors.length > 0) {
        var error = self._findError(model.get("code"));
        if (!STR.isBlank(error)) {
          ligneError = self._buildMessageErrorPer(error);
        }
      }
      if (!STR.isBlank(ligneError)) {
        periode += " <br>" + ligneError;
      }
      return periode;
    };

    table.cellRenderers["emprunt"] = function(model) {
      if (model.get("emprunt")) {
        return '<span title="' + i18n.t('common:listerprets.grid_emprunt_title') + '" style="margin-left:calc(50% - 8px);" class="ui-icon ui-icon-check"></span>';
      } else {
        return "";
      }
    };

    table.cellRenderers["motif"] = function(model) {
      var motif = "";
      var idCollab = self.workflow.pDiversIdCollabModel.get("valeur").replace('_', '');

      // EVO 450 - Docu 955
      if (!STR.isBlank(model.get("collabremplace")) && !STR.isBlank(model.get("collabremplace").matric)) {
        if (_.isEmpty("idCollab")) {
          motif += "<span>" + i18n.t('messages:GT_1848') + " " + model.get("collabremplace").nom + " " + model.get("collabremplace").prenom + " (" + model.get("collabremplace").matric + ")</span>";
        } else {
          motif += "<span>" + i18n.t('messages:GT_1848') + " " + model.get("collabremplace").nom + " " + model.get("collabremplace").prenom + " (" + model.get("collabremplace")[idCollab] + ")</span>";
        }
      } else {
        // Old code
        motif += "<a class='pret-link' data-id='" + UTILS.escapeJQueryString(model.get("code")) + "'>" + model.get("motif").libelle + "</a>";
      }

      return motif;
    };

    table.cellRenderers["partiejournee"] = function(model) {
      if (model.get("typesaisie") === "D" || model.get("typesaisie") === "R") {
        switch (model.get("modesaisie")) {
          case "PLH":
            return TYPE.HOUR_MINUTE.format(model.get("heuredeb")) + " - " + TYPE.HOUR_MINUTE.format(model.get("heurefin"));
          case "UNITE":
            if (model.get("unitedeb").code !== "J") {
              return model.get("unitedeb").libelle;
            }
            return "";
          case "DUREE":
            return TYPE.DURATION.HOUR_MINUTE.format(model.get("duree"));
          case "POURCENT":
            return model.get("pourcentage") + "%";
          default:
            break;
        }
      }
      return "";
    };

    table.cellRenderers["structures"] = function(model) {
      //TODO check visibility
      var rattachement = "";
      if (!_.isUndefined(model.get("structures")) && model.get("structures") != null &&
        model.get("structures").length > 0) {
        var libelleFormat = EVENEMENT.getLibelle(model.get('structures')[0].format, model.get('structures')[0].itemcode, model.get('structures')[0].itemlib);
        rattachement += "<a class='pret-link' data-id='" + UTILS.escapeJQueryString(model.get("code")) + "'>" + libelleFormat + "</a>";
      }
      return rattachement;
    };

    table.cellRenderers["multiple"] = function(model) {
      if (!_.isUndefined(model.get("structures")) && model.get("structures") != null &&
        model.get("structures").length > 1)
        return "<span title='" + i18n.t('common:listerprets.grid_rattachmult') + "'>*</span>";
      return "";
    };

    table.cellRenderers["statut"] = function(model) {
      var stat = model.get("statut").code;
      if (stat != null) {
        var style = "ui-phx-statut-demande";
        switch (stat) {
          case "E":
            style = "ui-phx-statut-en-cours-saisie";
            break;
          case "F":
          case "G":
            style = "ui-phx-statut-en-cours-revision";
            break;
          case "C":
          case "M":
            style = "ui-phx-statut-en-cours-modification";
            break;
          case "Y":
          case "Z":
            style = "ui-phx-statut-a-revoir";
            break;
          case "A":
            style = "ui-phx-statut-accepte";
            break;
          case "D":
            style = "ui-phx-statut-demande";
            break;
          case "H":
            style = "ui-phx-statut-hors-validation";
            break;
        }

        var spanElem = $("<span>").addClass(style).html(model.get("statut").libelle);
        var tooltipText = self._manageWKFHisto(model);
        $(spanElem).attr("title", tooltipText);
        $(spanElem).addClass("infoBulleStatut");
        return String(spanElem[0].outerHTML);
      }
    };

    table.cellRenderers["commentaire"] = function(model) {
      if (self.managingVueChange == true) {
        self._updateCheckAndCommentsModels();
      }
      var linkCom = "";
      if (STR.isBlank(model.get("commentaire"))) {
        linkCom = "<span title='" + i18n.t('common:listerprets.grid_commentaire_title') + "' class='linkCom A " + UTILS.escapeJQueryString(model.get("code")) + " phx-icon phx-icon-comment-white ' style='cursor:pointer; text-decoration:underline;margin:auto'></span>";
      } else {
        linkCom = "<span title='" + i18n.t('common:listerprets.grid_commentaire_title') + "' class='linkCom A " + UTILS.escapeJQueryString(model.get("code")) + " phx-icon phx-icon-comment-black ' style='cursor:pointer; text-decoration:underline;margin:auto'></span>";
      }

      return linkCom;
    };

    table.cellRenderers["a"] = function(model) {
      // We don't show checkbox when it's not possible to valid
      if (model.get("profils") == null || model.get("profils").length == 0) {
        return "";
      }
      var disabled = "";
      if (HABILITATION.canView("RES_VAL_PRET.G") == false) {
        disabled = " disabled='disabled'";
      }
      if (self.managingVueChange == true) {
        self._updateCheckAndCommentsModels();
      }
      var checkedAttribute = model.recEtatValid === "A" ? "checked" : "";

      return "<input title='" + i18n.t('common:listerprets.grid_accepter_title') + "' " + disabled + " class='checkBoxLine A " + UTILS.escapeJQueryString(model.get("code")) + "' type='checkbox'  value='a' " + checkedAttribute + "/>";

    };

    table.cellRenderers["r"] = function(model) {
      // We don't show checkbox when it's not possible to valid
      if (model.get("profils") == null || model.get("profils").length == 0) {
        return "";
      }
      var disabled = "";
      if (HABILITATION.canView("RES_VAL_PRET.G") == false) {
        disabled = " disabled='disabled'";
      }
      if (self.managingVueChange == true) {
        self._updateCheckAndCommentsModels();
      }
      var checkedAttribute = model.recEtatValid === "R" ? "checked" : "";
      //Prets out of workflow cannot be accepter or refused
      return "<input title='" + i18n.t('common:listerprets.grid_refuser_title') + "' " + disabled + "  class='checkBoxLine R " + UTILS.escapeJQueryString(model.get("code")) + "' type='checkbox'  value='r' " + checkedAttribute + "/>";
    };

    table.cellRenderers["pj"] = function(model) {
      if (model.get("piecesjointes")) {
        var indicateurpj = model.get("indicateurpj");

        if (indicateurpj) {
          var icon = $("<span>");
          icon.addClass("piece-jointe ui-phx-piece-jointe-icon phx-grid-not-selectable-cell");
          icon.attr({ "title": i18n.t('common:listerprets.grid_pj_title'), "data-code": UTILS.escapeJQueryString(model.get("code")) });
          return icon[0];
        }
        return "";
      } else {
        return "";
      }

    };

    table.setSortableColumns(["profils", "matricule", "periode", "motif"], i18n.t('common:listerprets.vue_detaille'));
    table.setSortableColumns(["profils", "matricule", "periode", "motif"], i18n.t('common:listerprets.vue_synt'));
    table.setOmittedColumns(["typepret", "periode", "commentaire", "a", "r"]);

    //the column periode is the datedeb for the webServices
    var customNames = {
      "periode": ["datedeb"]
    };
    table.setSortableCustomNames(customNames, i18n.t('common:listerprets.vue_detaille'));
    table.setSortableCustomNames(customNames, i18n.t('common:listerprets.vue_synt'));

    tableModel.off("vue:change");
    tableModel.on("vue:change", this._vueChanged, this);

    return table;

  },
  newPret: function(buttonId) {

    if (buttonId == "newPret") {
      var context = {};
      context.ctxUtilisateur = "Responsable";
      context.ctxHabilitation = {};
      context.ctxUtilisation = "Gérer un prêt par un responsable"; //Not used at the moment
      context.ctxTypeEvtGere = "P";
      context.ctxHabilitation.HabilitationAcces = "RES_PRET.V";
      context.ctxHabilitation.HabilitationGestionNormal = "RES_PRET_N.G";
      context.ctxHabilitation.HabilitationGestionEtendu = "RES_PRET_E.G";
      context.ctxHabilitation.HabilitationValidation = "RES_VAL_PRET.G";

      context.ctxEcran = "suivicollab";
      context.ctxCollab = {};
      context.ctxCollab.matricule = this.workflow.context.ctxCollab.matricule;
      context.ctxCollab.matric_aux = this.workflow.context.ctxCollab.matriculeAux;
      context.ctxCollab.nom = this.workflow.context.ctxCollab.nom;
      context.ctxCollab.prenom = this.workflow.context.ctxCollab.prenom;
      context.ctxModeRepresentation = "pop-up";
      context.ctxPieceJointe = (this.workflow.paramDivPieceJointe.get("valeur") === "1");
      context.ctxListeCollab = null;
      context.ctxPerimetreFige = false;
      context.ctxActionsPossibles = ["Creer", "Modifier", "Supprimer", "Valider"];
      context.ctxModeInitialisation = "Ajout";
      context.ctxIdentifiantEvt = null;
      context.ctxValeursEvenement = null;
      context.ctxValeursCreation = {};
      context.ctxValeursCreation.PretType = "";
      context.ctxValeursCreation.PretPerimetre = HABILITATION.canCreate("RES_PRET_N.G") ? "N" : "E";
      context.ctxValeursCreation.PretEstUnEmprunt = false;
      context.ctxValeursCreation.PretResponsable = objs.appRt.workflow.authModel.get("user"); //userid of current user
      context.ctxValeursCreation.PretDateDebut = TYPE.DATE.parse(TYPE.DATE.dateToStr(SYNC.getServerDate())).val;
      context.ctxValeursCreation.PretUniteDebut = "";
      context.ctxValeursCreation.PretDateFin = TYPE.DATE.parse(TYPE.DATE.dateToStr(SYNC.getServerDate())).val;
      context.ctxValeursCreation.PretUniteFin = "";
      context.ctxValeursCreation.PretHeureDebut = "";
      context.ctxValeursCreation.PretHeureFin = "";
      context.ctxValeursCreation.PretCommentaire = "";
      context.ctxHistoriqueWKF = false;
      this._initPopupGererPret(context);

      this.popupGerePretDialog.open();

      var self = this;
      this.listenTo(this.popupGerePretDialog.internalView.model, "pretChanged", function(model, action) {
        self.workflow.setUp();
        self.workflow.trigger("listerpretsUpdated");
        self.workflow.trigger("pretChanged", model, action);
      });

    }

  },
  /**
   * Returns the pret historique detail of this evenement.
   */
  _getHistoObj: function(even) { //TODO check
    var obj = null;
    _.each(this.historiqueModel.attributes, function(attr) {
      if (attr != null && even == attr.evenement)
        obj = attr;
    });
    return obj;
  },

  _manageWKFHisto: function(model) { //TODO check
    //TODO, change used component
    var typeLabel = "PRET";
    var contextComponentWkf = {
      ctxEcran: this.workflow.context.ctxEcran,
      ctxHabilitation: this.workflow.context.ctxHabilitation.HabilitationAcces,
      ctxTypeEvenement: typeLabel,
      ctxRefEvenement: model.get("evenement"),
      ctxDemId: null,
      ctxModeRestitution: "infobulle",
      ctxActivModeSynth: true,
      ctxModeDefaut: "Replie",
      ctxModeRech: true
    };

    var composantWorkflow = new BlockView();
    $(this.el).find(".listerprets-wkfhisto").html(composantWorkflow.render().el);
    composantWorkflow.setContext(contextComponentWkf);

    // store the component to reload it when the data is available.
    this.histoInfoBulles[this.titleCont] = composantWorkflow;
    this.titleCont++;

    return composantWorkflow.$el.html();

  },

  /**
   * This function prints the status infobull (historification component) after all data is loaded.
   * we assure that the data is shown, in case the fetch has not finisted to load the data.
   */

  _paintStatusInfoBull: function() {

    var numElements = $("[class*='infoBulleStatut']").length;

    for (var cont = 0; cont < numElements; cont++) {
      if (this.histoInfoBulles[cont]) {
        this.$el.find(".infoBulleStatut:eq(" + cont + ")").attr("title", this.histoInfoBulles[cont].$el.html());
      }
    }

  },

  /**
   * This function manages the change of the vue for  table, in order to get historiquedata
   */
  _vueChanged: function() {
    var self = this;
    this._historique(function() {
      self.table.model.coll.trigger("reset");
      self.workflow.trigger("lock:tableColumns");
      self.managingVueChange = true;
    });
  },
  _copyModels: function(coll) {
    var modelArray = [];
    _.each(coll.models, function(model) {
      modelArray.push(model);
    });
    return modelArray;
  },
  _updateCheckAndCommentsModels: function() {
    var self = this;
    _.each(self.table.model.coll.models, function(model) {
      _.each(self.copyModelsState, function(copyModel) {
        if (copyModel.get("id") == model.get("id")) {
          model.recEtatValid = copyModel.recEtatValid;
          model.nbrecEtatValid = copyModel.nbrecEtatValid;
          model.set("commentaire", copyModel.get("commentaire"), { silent: true });
        }
      });
    });
    this.managingVueChange = false;
  },
  _historique: function(callback) {
    var params = [];
    _.each(this.table.model.coll.models, function(item) {
      params.push(item.get("evenement"));
    }, this);

    this.wkfEvenements = params;

    this.historiqueModel = new ListerHistoriqueModel();
    this.historiqueModel.setHabContext(this.workflow.getHabContext());
    this.historiqueModel.set("evenements", this.wkfEvenements);
    //			this.historiqueModel.applyFilter({evenements : this.wkfEvenements});
    this.historiqueModel.save(null, {
      success: function() {

        if (callback) {
          callback();
        }
      },
      error: function() {

        if (callback) {
          callback();
        }
      }
    });
  },

  /**
   * Gets the table title depending on ctxValideur
   */
  _getTitle: function() {
    if (this.context && this.context.ctxValideur == true) {
      return i18n.t('common:listerprets.grid_title_a_valider');
    } else if (this.context && this.context.ctxEncoursOuAVenir == true) {
      return i18n.t('common:listerprets.grid_title') + i18n.t('common:listerprets.grid_title_encours');
    } else {
      return i18n.t('common:listerprets.grid_title');
    }
  },

  /**
   * Manage pieces jointes
   */
  _managePieceJointe: function(event) {
    var self = this;
    var maxPieces = !STR.isBlank(this.workflow.maxPieces) ? this.workflow.maxPieces.get("maxi") : 0;
    var modelCode = $(event.currentTarget).attr("data-code");
    var model = this.table.model.coll.get(modelCode);

    if (_.isUndefined(model)) {
      model = _.find(this.table.model.coll.models, function(item) {
        if (UTILS.escapeJQueryString(item.get("code")) === modelCode) {
          return item;
        }
        return null;
      });
    }
    if (model.get("piecesjointes").length == 1) {
      var code = model.get("piecesjointes")[0].code;
      window.open(Configuration.restRoot + "/rest/fichier/download?id=" + encodeURIComponent(code) + "&x_token_key=" + encodeURIComponent(Configuration.x_token_key), "_blank");
    } else if (model.get("piecesjointes").length > 1) {

      if (this.pjPopupView) {
        this.pjPopupView.internalView.setPJ(model.get("piecesjointes"), "");
      } else {

        var context = {};
        context.ctxRefEvenement = model.get("evenement");
        context.ctxHabilitation = {};
        context.ctxHabilitation.HabilitationAcces = this.context.ctxHabilitation.HabilitationAcces;
        context.ctxHabilitation.HabilitationGestion = this.context.ctxHabilitation.HabilitationValidation;

        context.ctxEcran = this.context.ctxEcran;
        context.ctxGestionCollab = {};
        context.ctxGestionCollab.nom = model.get("collaborateur").nom;
        context.ctxGestionCollab.prenom = model.get("collaborateur").prenom;
        context.ctxGestionCollab.matricule = model.get("collaborateur").matric;
        context.ctxGestionCollab.matric_aux = model.get("collaborateur").matricaux;

        context.ctxActionsPossibles = [];
        context.ctxModeInitialisation = "Consultation";

        this.pjPopupView = this._initPJPopupView(context, model.get("piecesjointes"), "", maxPieces);
        this.pjPopupView.open(function() {
          self.pjPopupView = null;
        });
      }
    }
    return false;
  },
  _formatedParametres: function(model) {
    var objResponse = {};

    objResponse.datedeb = this._addClassToText(TYPE.DATE.format(model.get("datedeb"), TYPE._getFormatByCode("DATE_A")), "phx-bold-label-style");
    objResponse.datefin = this._addClassToText(TYPE.DATE.format(model.get("datefin"), TYPE._getFormatByCode("DATE_A")), "phx-bold-label-style");
    var competence = model.get("libelle") + " (" + model.get("code") + ")";
    objResponse.competence = this._addClassToText(competence, "phx-bold-label-style");

    return objResponse;
  },

  _addClassToText: function(text, objClass) {
    return $("<span>").text(text).addClass(objClass);
  },

  _initPJPopupView: function(context, list, description, maxpieces) {
    var dialog = new DialogView({
      view: GererPiecesJointesView,
      viewData: {
        context: context,
        coll: list,
        description: description,
        modal: false,
        maxPieces: maxpieces
      }
    });
    dialog.setHeight("auto");
    dialog.setWidth(600);
    return dialog;
  },

  _findError: function(objCode) {
    var res = "";

    _.each(this.model.objErrors, function(obj) {
      if (obj.code == objCode) {
        res = obj;
      }
    });

    return res;
  },

  _cleanErrorsTable: function() {
    this.table.model.coll.forEach(function(model) {
      model.trigger("cleanError", model);
    });
  },

  _buildMessageErrorRec: function(objError) {
    var styleError = "ui-phx-msg-erreur-validation";
    var htmlError = "";
    if (objError.type == "A") {
      htmlError += i18n.t('messages:GT_1370') + ":";
    } else if (objError.type == "R") {
      htmlError += i18n.t('messages:GT_1371') + ":";
    }

    htmlError = this._textToStyle(htmlError, styleError);

    return htmlError;
  },

  _buildMessageErrorPer: function(objError) {
    var styleError = "ui-phx-msg-erreur-validation";
    var htmlError = "";
    if (STR.isBlank(objError.message)) {
      htmlError += i18n.t('messages:GT_1224');
    } else {
      htmlError += objError.message;
    }
    htmlError = this._textToStyle(htmlError, styleError);
    return htmlError;
  },

  _textToStyle: function(txt, style) {
    var newTxt = "";
    newTxt = "<span class='" + style + "'>" + txt + "</span>";

    return newTxt;
  },

  _initPopupResumeView: function(data) {

    if (this.resumeDialog === undefined) {
      this.resumeDialog = new DialogView({
        view: ResumePopUpView,
        viewData: {
          data: data
        }
      });
    } else {
      this.resumeDialog.viewData = { data: data };
    }
    this.resumeDialog.setHeight(150);
    this.resumeDialog.setWidth(620);

    this.resumeDialog.model.trigger("dlg:open", function() {
      // Close Callback
      LOG.debug("callback from dialog view");
    });
    this.resumeDialog._setTitle(i18n.t('common:listerprets.resumePopUp_title'));
  },

  _manageRowProfil: function(event) {
    var className = event.currentTarget.className;
    var id = !STR.isBlank(className.split(" ")[0].split("_")[1]) ? className.split(" ")[0].split("_")[1] : "";
    var item = $(event.currentTarget).prop("viewRef").getItem();
    var model = this.table.model.coll.get(id);

    if (_.isUndefined(model)) {
      model = _.find(this.table.model.coll.models, function(l_item) {
        if (UTILS.escapeJQueryString(l_item.get("code")) === id) {
          return l_item;
        }
        return null;
      });
    }
    if (model !== undefined) {
      if (!STR.isBlank(item.id)) {
        model.selectedProfil = {
          code: item.id,
          libelle: item.label
        };
        model.trigger("cleanError", model);
      } else {
        model.selectedProfil = {
          code: "0",
          libelle: ""
        };
      }
    }
  }
});