import { DataGridRowView } from '../data_grid/dataGridRow.view.js';
import { EditableGridView } from '../editablegrid/editableGrid.view.js';
import TPL_common_table_cell_editaction from '../basegrid/table_cell_editaction.tpl.html';
import TPL_common_table_cell_multiselectaction from '../basegrid/table_cell_multiselectaction.tpl.html';
import { STR } from '../../../utils/str.js';

export var EditableGroupeItemGridRowView = DataGridRowView.extend({

  dummy: "",

  tagName: "tr",
  className: "ui-grid-row",

  edited: false,

  initialize: function(options) {
    if (options && options.sliceEmptyRows) {
      this.sliceEmptyRows = options.sliceEmptyRows;
    }
    DataGridRowView.prototype.initialize.apply(this, arguments);
  },

  /**
   * Event triggered when permon a click inside the view.
   */
  /**
   * Event triggered when permon a double click inside the view.
   */
  events: {
    "click": "_rowListener",
    "dblclick": "_rowListener"
  },

  /**
   * Grid Row render Function
   */
  render: function() {
    var rowThis = $(this.el);
    var isSelected = rowThis.hasClass("phx-selected");
    var self = this;
    if (this.dataGrid.selectable) {
      rowThis.css("cursor", "pointer");
    }
    rowThis.empty();
    rowThis.attr("cid", this.cid);
    if (this.dataGrid.model.groupeByColumn) {
      var groupeByColumnValue = this.dataGrid._groupeByColumnValue(this.model);
      rowThis.attr("groupe-item", groupeByColumnValue);
    }

    if (this.edited === true &&
      (this.dataGrid instanceof EditableGridView)) {
      this._renderForm();
    } else if (this.dataGrid.enableEmptyRow && this.dataGrid.isEmptyRow(this.model)) {
      var cell = this._addCell({ property: "emptyRow" }, 0);
      if (this.sliceEmptyRows) {

        var columnsVisibles = _.reduce(self.dataGrid.currentVue._columns, function(acum, item) {
          return (item.visible === true) ? (acum + 1) : acum;
        }, 0);

        cell.$el.attr("colspan", columnsVisibles - this.sliceEmptyRows);
      } else {
        cell.$el.attr("colspan", this.dataGrid.currentVue.columns.length);
      }
      rowThis.append(cell.el);
    } else {
      _.each(this.dataGrid.currentVue.columns, function(column, index) {
        var cell = null;
        switch (column.property) {
          case "phx-multiselect":
            if (this.dataGrid.currentVue._columns["phx-multiselect"] && this.dataGrid.currentVue._columns["phx-multiselect"].visible) {
              cell = this._addActionCell(column, index, TPL_common_table_cell_multiselectaction);
            }
            break;
          case "phx-action":
            if (this.dataGrid.currentVue._columns["phx-action"] && this.dataGrid.currentVue._columns["phx-action"].visible) {
              cell = this._addActionCell(column, index, TPL_common_table_cell_editaction);
            }
            break;
          case "phx-last":
            break;

          default:
            cell = this._addCell(column, index);
        }

        if (!STR.isBlank(cell)) {
          rowThis.append(cell.el);
          this.dataGrid.currentVue._columns[column.property].cells.push(cell);
          if (!this.dataGrid.currentVue._columns[column.property].visible) {
            $(cell.el).hide();
          }
          if (column.property === this.dataGrid.model.groupeByColumn) {
            $(cell.el).html("");
          }
        }
      }, this);

      if (this.dataGrid.currentVue._columns["phx-multiselect"]) {
        this._showMultiSelection();
      }

      if (isSelected) {
        this._selectRow();
      }
    }
    return this;
  }

});