module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<li class=\'ui-menu-item phx-populations-menu-select-structure phx-menu-link phx-hover ui-corner-all\'>\n  <a class="structureSelectorMenu" data-codeStructure="'+
((__t=( element.code ))==null?'':__t)+
'" href="#">\n    '+
((__t=(i18n.t("messages:GT_2128",{"1":element.libselpop})))==null?'':__t)+
'...\n  </a>\n</li>\n';
}
return __p;
};
