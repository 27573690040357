import { i18n } from '../i18n.js';

/**
 * String format utility class
 */
export var STR = {

  /**
   * Checks if a text is empty
   */
  isEmpty: function(str) {
    if (str == 0) {
      str = str + ""; // To avoid problems when id is 0.
    }
    return (!str || 0 === str.length);
  },

  /**
   * Checks if a text is blank or null
   */
  isBlank: function(str) {
    if (str == 0) {
      str = str + ""; // To avoid problems when id is 0.
    }
    return (!str || /^\s*$/.test(str));
  },

  /**
   * Replaces the parametres on the message with the parametres value.
   */
  buildMessageParametres: function(message, parametres) {
    var result = "";
    if (!STR.isBlank(message)) {
      result = message;
      if (!STR.isBlank(parametres)) {
        var array = [];
        for (var i in parametres) {
          array.push(i);
        }
        array.sort(function(a, b) {
          return b.length - a.length;
        });
        for (var j = 0; j < array.length; j++) {
          result = result.replace(array[j], parametres[array[j]]);
        }
      }
    }
    return result;
  },

  /**
   * Format an array of Messages by joining all of them and putting a back
   */
  formatMessage: function(msgs) {
    var strMsg = "";
    // Valid for arrays ["msg1","msg2"] or objects {1:"msg1",2:"msg2"}, or
    // complex objects like {1:{2:"msg1"},2:"msg2"}
    _.each(msgs, function(value) {
      if (_.isObject(value)) {
        value = this.formatMessage(value);
        value = value.substring(2, value.length - 1);
      }
      strMsg = strMsg + "* " + value + "\n";
    }, this);
    return strMsg;
  },

  /**
   * Get an el value from a model : getElValue(model, "regleCalcul.libelle")
   *
   */
  getElValue: function(model, property) {
    var tokens = property.split(".");
    var rootValue = model == null ? null : model.get(tokens[0]);
    var value = rootValue;
    for (var i = 1; i < tokens.length; i++) {
      if (value == null) {
        return null;
      }
      value = value[tokens[i]];
    }
    return value;
  },

  /**
   * Similar to getElValue but from a JSON object instead of a model.
   *
   */
  getElValueFromObj: function(object, property) {
    var tokens = property.split(".");
    if (!_.has(object, tokens[0])) {
      return null;
    }
    var rootValue = object[tokens[0]];
    var value = rootValue;
    for (var i = 1; i < tokens.length; i++) {
      if (value == null) {
        return null;
      }
      value = value[tokens[i]];
    }
    return value;
  },

  /**
   * Set an el value to a model : setElValue(model, "regleCalcul.code",
   * "REGUL")
   *
   */
  setElValue: function(model, property, value, options) {
    var tokens = property.split(".");
    var objectValue = {};
    if (tokens.length > 1) {
      // Complex element
      objectValue = _.clone(model.get(tokens[0]));
      if (objectValue == undefined) {
        objectValue = {};
      }
      objectValue = this._extendObject(objectValue, tokens, 1, value);
    } else {
      // Simple element
      objectValue = value;
    }

    if (STR.isBlank(model)) {
      model = new Backbone.Model();
    }

    return model.set(tokens[0], objectValue, options);
  },

  /**
   * Replaces the char at the desired position for the string
   */
  replaceCharAt: function(str, index, chr) {
    if (index > str.length - 1) { return str; }
    return str.substr(0, index) + chr + str.substr(index + 1);
  },

  /**
   * Recursive method that insert a value in a complex object.
   */
  _extendObject: function(object, tokens, index, value) {
    if (tokens.length - 1 == index) {
      object[tokens[index]] = value;
      return object;
    }
    if (!object[tokens[index]]) { object[tokens[index]] = {}; }
    _.extend(object[tokens[index]], this._extendObject(object[tokens[index]], tokens, index + 1, value));
    return object;
  },

  extractObjectValues: function(object) {
    var auxArray = _.values(object);
    _.each(auxArray, function(it, index) {
      if (_.isObject(it)) {
        auxArray[index] = this.extractObjectValues(it);
      }
    }, this);
    _.flatten(auxArray);
    return auxArray;
  },

  toFirstUpperCase: function(string) {
    return string.substring(0, 1).toUpperCase() + string.substring(1);
  },
  convertToAscii: function(string) {
    var i = 0;
    var resultString = "";
    if (!STR.isBlank(string)) {
      for (i = 0; i < string.length; i++) {
        resultString += "&#" + string.charCodeAt(i) + ";";
      }
    }
    return resultString;
  }
};