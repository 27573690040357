/**
 * Utility for time synchrinization
 */
export var SYNC = {

  delay: 0,
  oldDelay: 0,
  ready: false,
  delays: [],
  firstTime: true,

  /**
   * Method to calculate the time delay between the server time and the local
   * time.
   */
  calculateDelay: function() {
    this.delays.splice(0, this.delays.length);
    var now = SYNC.getServerDate();
    var nowUTC = now.getTime(); // By default the time in javascript is stored as UTC
    this._manageDelay(nowUTC, 1, 10);
  },

  /**
   * Returns the calculated delay between the time of the server and the local time.
   */
  _getDelay: function() {
    if (this.ready == true) {
      return this.delay;
    } else {
      return this.oldDelay;
    }
  },

  /**
   * Set the calculated delay between the time of the server and the local time.
   */
  _setDelay: function(delay) {
    this.oldDelay = this.delay;
    this.ready = false;
    this.delay = delay;
    this.ready = true;
  },

  /**
   * Send n calls to the server, removing the transmission time,
   * to tell the time on the server. Select the least time of transmission has
   */
  _manageDelay: function(time1UTC, i, max) {
    var self = this;
    var model = new Backbone.Model();
    model.fetch({
      url: Configuration.restRoot + "/rest/authentification/date",
      success: function(data) {
        var date2 = new Date();
        var time2UTC = date2.getTime(); // By default the time in javascript is stored as UTC
        var latency = (time2UTC - time1UTC) / 2;
        var delta = (data.get("date") + latency) - time2UTC;
        self.delays.push(delta);
        // if is the first time set the delay immediately
        if (self.firstTime) {
          self._setDelay(delta);
          self.firstTime = false;
        }
        // if is an intermediate time, I continue.
        if (i < max) {
          var time = Math.floor((Math.random() * 3));
          window.setTimeout(function() {
            self._manageDelay(time2UTC, i + 1, 10);
          }, time);
        } else if (i == max) {
          var length = self.delays.length;
          var sum = 0;
          for (var j = 0; j < length; j++) {
            sum += self.delays[j];
          }
          self._setDelay(sum / i);
        }
      }
    });
  },

  /**
   * Method to get the server date in the local zone with a delay.
   *
   */
  getServerDate: function() {
    var del = this._getDelay();
    var now = new Date().getTime();
    return new Date(now + del);
  },

  //-----------------------------------------------
  //
  // GLOBAL DATA SYNCHRONIZATION
  //
  //-----------------------------------------------

  /**
   *
   */
  data: {
    lastModificationPool: new Backbone.Model(),

    notifyChange: function(dateType, time) {
      this.lastModificationPool.set(dateType, time);
      //			this.lastModificationPool.trigger("data_change:"+dateType, change);
    },

    startListeningChanges: function(listener, dateType, method) {
      if (listener.listenTo) {
        listener.listenTo(this.lastModificationPool, "change:" + dateType, method);
      } else {
        throw Error("The listener object is not a Backbone Model or a Backbone View");
      }
    },

    getLastModificationTime: function(dateType) {
      return this.lastModificationPool.get(dateType);
    }
  }
};