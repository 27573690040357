import { DataGridModel } from '../../../../../core/grids/data_grid/dataGrid.model.js';
import { DataGridView } from '../../../../../core/grids/data_grid/dataGrid.view.js';
import { GLOBAL_DATA } from '../../../../../globalData.js';
import { HabilitationContext } from '../../../../../core/models/habilitationContext.js';
import { i18n } from '../../../../../i18n.js';
import { NAV } from '../../../../../utils/nav.js';
import { SituationsARegulariserColl } from '../models/situationsARegulariser.collection.js';
import { STR } from '../../../../../utils/str.js';
import { STYLE } from '../../../../../utils/style.js';
import { TYPE } from '../../../../../tda/tda.js';
import TPL_bsitreg_briqueSituationsARegulariser from '../briqueSituationsARegulariser.tpl.html';

export var SituationsARegulariserView = Backbone.View.extend({

  //REVIEW: 	i18n : phx.i18n.common.bsitreg,

  _maxLines: 10,

  events: {
    "click .bsitreg_link": "_linkToValidation"
  },

  /**
   * Constructor of this class
   */

  initialize: function(options) {
    this.usePopulation = false;
    if (options && options.usePopulation) {
      this.usePopulation = options.usePopulation;
    }
    this.template = TPL_bsitreg_briqueSituationsARegulariser;
    this.tablebriqueSituationsARegulariser = null;
    this.styleLabel = "phx-label-style";
    this.styleDate = "phx-bold-input-style";
    this.styleHoure = "phx-input-style";
    this.styleEnCours = "phx-input-encours-style";
    this.parent = null;
    this.table = this._initTable();

    if (options && options.parent) {
      this.parent = options.parent;
    }

    this.diversIdCollab = GLOBAL_DATA.paramDivers.get("idCollab");
    this.paramIdCollab = this.diversIdCollab.get("valeur");

    var self = this;

    this.listenTo(this.table.model.coll, "sync", function() {
      self.parent.setTitle(i18n.t('common:bsitreg.SituationsARegulariser') +
        " (" + self._indexRows(self.table.model.coll) + "/" + self.table.model.coll.totalRecords + ")");
      //update margins
      self.$el.parents(".phx-portlet-div").css({
        "padding": "0px 0px 0px 2px",
        "width": "calc(100% - 4px)"
      });

      self.table.removeGridStyle = true; //disable grid mode

    }, this);

  },

  render: function() {
    var json = { 'i18n': i18n };
    $(this.el).html(this.template(json));

    $(this.el).find(".table_BriqueSituationsARegulariser").html(this.table.el);
    this.table.render();
    this._hideZonesGrid();

    return this;
  },

  refreshBrique: function() {
    var self = this;

    this.table.model.canViewTreatment = true;
    this.table.model.coll.fetch({
      success: function(fresh) {
        self.parent.setTitle(i18n.t('common:bsitreg.SituationsARegulariser') +
          " (" + self._indexRows(fresh) + "/" + fresh.totalRecords + ")");
      },
      error: function() {
        self.parent.setTitle(i18n.t('common:bsitreg.SituationsARegulariser'));
      }
    });

    var lastScrollPosition = 0;
    $(self.el).parents(".phx-portlet-container").off("sortstart.scrollTop");
    $(self.el).parents(".phx-portlet-container").on("sortstart.scrollTop", function(event, ui) {
      lastScrollPosition = self.table.$el.find(".phx-grid-scroll").scrollTop();
    });
    $(self.el).parents(".phx-portlet-container").off("sortstop.scrollTop");
    $(self.el).parents(".phx-portlet-container").on("sortstop.scrollTop", function(event, ui) {
      self.table.$el.find(".phx-grid-scroll").scrollTop(lastScrollPosition);
      lastScrollPosition = 0;
    });

    this.delegateEvents();

    return this;
  },

  _indexRows: function(modelcoll) {
    var totalRecords = modelcoll.totalRecords;
    var startIndex = modelcoll.pagination.startIndex;
    var size = modelcoll.pagination.size;
    var rowNumber = Math.min(startIndex + size, totalRecords);

    if (modelcoll.paginated === false) {
      totalRecords = modelcoll.length;
      rowNumber = totalRecords;
      startIndex = 0;
    }

    return rowNumber;
  },

  _hideZonesGrid: function() {
    // corrects the last column space
    $(this.el).find(".table_BriqueSituationsARegulariser th:last-child").hide();
    //header
    $(this.el).find(".table_BriqueSituationsARegulariser .phx-hd-btn").hide();
    $(this.el).find(".table_BriqueSituationsARegulariser .phx-grid-title-bar").hide();
    $(this.el).find(".table_BriqueSituationsARegulariser .phx-grid-header-scroll").hide();

    //footer
    $(this.el).find(".table_BriqueSituationsARegulariser .phx-grid-footer-left").hide();
    $(this.el).find(".table_BriqueSituationsARegulariser .phx-grid-footer-right").hide();
  },

  /**
   * Gets type of anomalie depending on Type of anomalie
   */
  _getTitleFromAnomalie: function(anocode) {
    var title = "";
    switch (anocode) {
      case "B":
        title = i18n.t('common:bsitreg.bloqueante');
        break;
      case "P":
        title = i18n.t('common:bsitreg.persistante');
        break;
      case "N":
        title = i18n.t('common:bsitreg.non_bloqueante');
        break;
    }
    return title;
  },

  /**
   * Adds style to text
   */
  _textToStyle: function(txt, style) {
    var newTxt = "";
    newTxt = "<span class='" + style + "'>" + txt + "</span>";

    return newTxt;
  },

  _initTable: function() {
    var tableModel = new DataGridModel({ coll: new SituationsARegulariserColl() });

    var tblColumns = [
      { title: "Collaborateur", property: "collaborateur", width: 182 },
      { title: "Date", property: "date", width: 112 },
      { title: "Heure", property: "heure", width: 52 },
      { title: "Type", property: "type", width: 15 },
      { title: "Libelle", property: "libelle", width: "auto" } // "calc(100% - 361px)"}
    ];
    var table = new DataGridView({
      id: "bsitreg_table",
      columns: tblColumns,
      model: tableModel,
      title: i18n.t('common:bsitreg.SituationsARegulariser_title'),
      showFilteredRowsInTitle: false,
      gridHeightType: "auto",
      maxVisibleRows: 10,
      removeGridStyle: true,
      selectable: false
    });

    tableModel.coll.usePopulation = this.usePopulation;

    // set habilitations context that the table collection will use
    tableModel.coll.setHabContext(new HabilitationContext({
      onglet: "bsitreg",
      fonc_cour: "RES_LEVANO.V"
    }));

    var self = this;

    table.cellRenderers["collaborateur"] = function(model) {
      return self._collaborateurFormated(model);
    };

    table.cellRenderers["date"] = function(model) {
      return self._dateFormated(model);
    };

    table.cellRenderers["heure"] = function(model) {
      return self._heureFormated(model);
    };

    table.cellRenderers["type"] = function(model) {
      return self._typeFormated(model);
    };

    table.cellRenderers["libelle"] = function(model) {
      return self._libelleFormated(model);
    };

    return table;
  },

  /**
   * Prepares each model in the collection, adapting its attributes to html that will be used in table
   */

  _collaborateurFormated: function(obj) {
    //format prenom and nom into collaborateur
    var attributes = obj.toJSON();
    var collaborateur = attributes.nom + ", " + attributes.prenom;

    if (this.paramIdCollab == "matric_aux") {
      collaborateur += " (" + attributes.matriculeaux + ")";
    } else {
      collaborateur += " (" + attributes.matricule + ")";
    }

    collaborateur = "<span>" + collaborateur + "</span>";

    return collaborateur;
  },
  _dateFormated: function(obj) {
    //render the date
    var attributes = obj.toJSON();
    var dateFormated = TYPE.DATE.format(attributes.dateano, "Dy " + TYPE._getFormatByCode(TYPE.DATE.DEFAULT_MASK_CODE));
    dateFormated = "<span class='bsitreg_link' style='font-weight:bold;' data-url='anomalie/" + attributes.dateano + "/" + attributes.matricule + "'>" + dateFormated + "</span>";

    return dateFormated;
  },

  _heureFormated: function(obj) {
    //render the heure
    var attributes = obj.toJSON();
    var heure = "";

    if (attributes.heureano != 99) {
      var minute = (attributes.minuteano < 10) ? "0" + attributes.minuteano : "" + attributes.minuteano;
      heure = TYPE.HOUR_MINUTE.format(attributes.heureano + "" + minute);
    }
    return heure;
  },

  /**
   * Prepares ! symbol that will be used in table to identify type of anomalie
   */
  _typeFormated: function(obj) {
    //render the type
    var attributes = obj.toJSON();
    var img = "";
    var className = "";
    var tooltip = "";

    if (!STR.isBlank(attributes.typeano))
      className = STYLE.getClassColorFromAnomalie(attributes.typeano);
    tooltip = this._getTitleFromAnomalie(attributes.typeano);
    if (attributes.typeano == "B" || attributes.typeano == "P" || attributes.typeano == "N") {
      //TODO: Pass to the good way of create the html
      //img = $("<span>").addClass(className).attr("title", tooltip).css("font-weight","bold").html("!");
      img = "<span class='" + className + "' style='font-weight:bold;' title='" + tooltip + "'>!</span>";
    }
    return img;
  },

  _libelleFormated: function(obj) {
    //render the libelle
    var attributes = obj.toJSON();
    var libelle = "";

    libelle = "<span style='font-weight:bold;'>" + attributes.libelle + "</span>";

    if (!STR.isBlank(attributes.comment)) {
      libelle += ", " + attributes.comment;
    }
    return libelle;
  },

  /**
   * Opens vue journee panel at anomalie screen
   */
  _linkToValidation: function(event) {
    var target = event.target;
    var ecran = $(target).attr("data-url");

    NAV.navigate("resp", ecran, "uc", true, true);
  }

});