import { DataGridRowView } from '../data_grid/dataGridRow.view.js';
import { EditableGridView } from '../editablegrid/editableGrid.view.js';
import { EditableGroupeGridRowView } from './editableGroupeGridRow.view.js';
import { EditableGroupeItemGridRowView } from './editableGroupeItemGridRow.view.js';
import { STR } from '../../../utils/str.js';
import { UTILS } from '../../../utils/utils.js';

export var EditableGroupeGridView = EditableGridView.extend({

  dummy: "",

  /**
   * Default name of the view
   */
  DEFAULT_NANE: "Data",

  /**
   * Constructor
   * View underlying a Tree type DataGrid
   * inside current groupe (instead of checking position inside whole table).
   */
  initialize: function(options) {
    EditableGridView.prototype.initialize.call(this, options);
    this.checkGroup = false; //pour indiquer que c'est un groupe spécial de regroupement (avec sous-groupes) ou non
    if (options && options.checkGroup) {
      this.checkGroup = options.checkGroup;
    }
    this.enableEmptyRow = false;
    if (options && _.isBoolean(options.enableEmptyRow)) {
      this.enableEmptyRow = options.enableEmptyRow;
    }

    if (options && _.isFunction(options.isEmptyRow)) {
      this.isEmptyRow = options.isEmptyRow;
    }

    if (options && _.isBoolean(options.manageRowOrderByGroup)) {
      this.model.manageRowOrderByGroup = options.manageRowOrderByGroup;
    }
    if (options && _.isNumber(options.sliceEmptyRows)) {
      this.sliceEmptyRows = options.sliceEmptyRows
    }
    var self = this;
    this.model.coll.off("add");
    this.model.coll.on("add", function(model, property, options) {
      if (model.isNew()) {
        var l_tbody = $("tbody", self.el);
        var rowView = new DataGridRowView({ model: model, dataGrid: self });
        if (STR.isBlank(self.rows)) {
          self.rows = [];
        }
        if (self.model.coll.pagination.startIndex === 0) {
          self.rows.push(rowView);
          if (self.checkGroup && !_.isEmpty(options)) {
            var l_rowAjouter = parseInt(options.rowAjouter, 10);
            var l_newPos = self.rowViews[l_rowAjouter].$el;

            l_newPos.after(rowView.render().el);
          } else {
            l_tbody.prepend(rowView.render().el);
            self._scrollToIndex(0);
          }
        } else {
          // If the table is not at the beginning we need to paginate to the first row to avoid problems.
          self.model.coll.goTo(0, function() {
            self.rows.push(rowView);
            self._scrollToIndex(0);
            l_tbody.prepend(rowView.render().el);
            // After paginate I return to the edition state
            self.model.set("mode", "E");
          });
        }
        model.trigger("add:model", model);
        self.manageCustomMessageVisibility(false);

        if (!STR.isBlank(self.multiselection)) {
          self._focusFirstSelectibleCell();
        } else {
          self._focusFirstCell();
        }
      }
    });
    // Row views
    this.rowViews = [];
    this.rowViewsIndex = [];
  },

  /**
   * Expands and collapses every node of a row in the tree grid
   */
  _expandCollapseRow: function(model) {
    var groupeByColumnValue = this._groupeByColumnValue(model);

    if (this.model.groupeByColumn) {
      var header = this.$el.find("tr[groupe-header='" + groupeByColumnValue + "']");
      var rows = this.$el.find("tr[groupe-item='" + groupeByColumnValue + "']");

      if (model.isExpanded === false) { //expand row
        model.isExpanded = true;
        header.find("span.goupetbl_expbtn").removeClass("ui-icon-triangle-1-e");
        header.find("span.goupetbl_expbtn").addClass("ui-icon-triangle-1-s");

        rows.show();
      } else { //collapse rows
        model.isExpanded = false;
        header.find("span.goupetbl_expbtn").removeClass("ui-icon-triangle-1-s");
        header.find("span.goupetbl_expbtn").addClass("ui-icon-triangle-1-e");

        rows.hide();
      }
    }
    return false;
  },

  _groupeByColumnValue: function(model) {
    for (var i = 0; i < this.currentVue.columns.length; i++) {
      if (this.currentVue.columns[i].groupe === true) {
        this.model.groupeByColumn = this.currentVue.columns[i].property;
        break;
      }
    }
    if (this.model.groupeByColumn) {
      var attr = STR.getElValue(model, this.model.groupeByColumn);
      if (STR.isBlank(attr)) {
        return null;
      } else {
        return UTILS.escapeJQueryString(attr);
      }
    }
    return null;
  },

  _paintRows: function() {
    this._paintRowsGroupeView();
  },

  /**
   * Prints the row index with the total on the footer of the table
   */
  _showSelectedRowIndex: function() {
    var totalRecords = this.model.coll.totalRecords;
    if (this.model.coll.paginated === false) {
      totalRecords = this.model.coll.length;
    }

    var rowNumber = totalRecords;

    // if selection
    var row = $(".phx-grid-scroll tr > .ui-state-active", this.el);
    if (row) {
      var rowIndex = parseInt(row.parent().attr("rowindex"), 10);
      if (_.isNaN(rowIndex)) {
        rowIndex = 0;
      }
      if (row.length > 0 && this.rowHeight > 0) {
        var extraHeight = this.$el.find(".phx-grid-scroll-extra1").height();

        rowNumber = Math.floor((extraHeight) / this.rowHeight) + rowIndex;
      }
      $(".phx-grid-footer-right", this.el).html(rowNumber + "/" + totalRecords);
    }
    //Manage the visibility of the rows
    if (this.model.coll.length === 0) {
      this.$el.find(".ui-grid-table").hide();
    } else {
      this.$el.find(".ui-grid-table").show();
    }
  },

  /**
   * Overwrites the method _paintRows from the parent class
   */
  _paintRowsGroupeView: function() {
    var l_tbody = $(this.el).find("tbody");
    if (l_tbody.length === 0) {
      // Avoid an initial call during Workflow setUp before the page is rendered
      return;
    }

    if (this.rowViews) {
      for (var r = 0; r < this.rowViews.length; r++) {
        this.rowViews[r].remove();
        this.rowViews[r] = null;
      }
      this.rowViews.length = 0;

      this.rowViewsIndex = [];
      this.rowViewsIndex.length = 0;
    } else {
      this.rowViews = [];
      this.rowViewsIndex = [];
    }

    l_tbody.empty();
    _.each(this.currentVue._columns, function(column) {
      column.cells = [];
    });

    var groupeArray = [];
    var j = 0;
    for (var i = 0; i < this.model.coll.length; i++) {
      var rModel = this.model.coll.at(i);
      var row = null,
        rowView = null;
      var groupeByColumnValue = this._groupeByColumnValue(rModel);
      if (this.model.groupeByColumn) {

        if (STR.isBlank(groupeArray[groupeByColumnValue])) {

          rowView = new EditableGroupeGridRowView({
            model: rModel,
            dataGrid: this,
            checkGroup: this.checkGroup //pour indiquer que c'est un groupe spécial de regroupement (avec sous-groupes) ou non
          });
          row = rowView.render().el;
          rModel.off("treetbl_exp");
          rModel.on("treetbl_exp", this._expandCollapseRow, this);
          i--;
          groupeArray[groupeByColumnValue] = 1;
        } else {
          groupeArray[groupeByColumnValue] += 1;
          rowView = new EditableGroupeItemGridRowView({
            model: rModel,
            dataGrid: this,
            sliceEmptyRows: this.sliceEmptyRows
          });
          row = rowView.render().el;
        }
      } else {
        rowView = new EditableGroupeItemGridRowView({
          model: rModel,
          dataGrid: this,
          sliceEmptyRows: this.sliceEmptyRows
        });
        row = rowView.render().el;
      }

      $(row).addClass("ui-grid-row");
      $(row).attr("rowindex", j);
      if (j % 2 === 0) {
        $(row).addClass("ui-grid-row-alt");
      }
      j++;
      l_tbody.append(row);
      this.rowViews.push(rowView);
      this.rowViewsIndex[rModel.id] = i;
    }

    // Toggle control
    // Hide the columns that must be hidden by default.
    if (this.currentVue._visibleColumns.length > 0) {
      _.each(_.difference(_.keys(this.currentVue._columns), this.currentVue._visibleColumns), function(key) {
        if (key.indexOf("phx-") !== 0) {
          this.model.trigger("toggle:column", key);
        }
      }, this);

      /**
       * Visible columns of the tree grid
       */
      this.currentVue._visibleColumns = [];
    }

    // Hide the columns that must be hidden by default.
    if (this.currentVue._lockedColumns.length > 0) {
      _.each(this.currentVue._lockedColumns, function(key) {
        this.model.trigger("lock:column", key);
      }, this);

      /**
       * Locked columns of the tree grid
       */
      this.currentVue._lockedColumns = [];
    }

    // Adjust scroll
    if (!this.rowHeight || this.rowHeight === 0) {
      this._calculateRowsPerPage();
    }
    var totalRecords = this.model.coll.totalRecords;
    if (this.model.coll.paginated === false) {
      totalRecords = this.model.coll.length;
    }
    var startIndex = this.model.coll.pagination.startIndex;
    var size = this.model.coll.pagination.size;
    var endIndex = Math.min(startIndex + size, totalRecords);

    var extraHeight = (totalRecords - endIndex) * this.rowHeight;
    $(".phx-grid-scroll-extra2", this.el).height(extraHeight);

    var preHeight = (startIndex) * this.rowHeight;
    $(".phx-grid-scroll-extra1", this.el).height(preHeight);

    this._renderFilteredRowsInTitle();

    if (this.multiselection) {
      this.currentVue._columns["phx-multiselect"].header._manageChecking();
    }

    $(".phx-grid-footer-right", this.el).html(endIndex + "/" + totalRecords);

    // dynamic resize height of the grid
    this._resizeGrid();
  },

  /**
   * Select all group rows on the grid
   */
  selectAllGroupRows: function(group) {
    var self = this;
    var clonedCollection = this.model.coll.clone();

    clonedCollection.setHabContext(this.model.coll.getHabContext());

    var l_filterMultiSelectColl = _.extend({}, this.filterMultiSelectColl, { "code": group.code });

    if (this.model.coll.paginated === false) {
      clonedCollection.models = _.filter(clonedCollection.models, function(model) {
        if (_.find(l_filterMultiSelectColl, function(item) {
            if (item === model.code) {
              return true;
            }
            return false;
          })) {
          return true;
        }
        return false;

      });

      //		** Each by the collection selected. if at coencidance, return false. But not coencidance, continue process.
      var coencidanceRow = 0;
      _.each(clonedCollection.models, function(model) {
        if (self.periodeContainesPart(self.model.get('value').attributes, model.attributes)) {
          coencidanceRow++;
        }
      })
      if (coencidanceRow === 0) {
        this.model.multiselectColl.add(group);
        this._manageMultiSelection();
        return true;
      } else {
        return false;
      }

    } else {
      clonedCollection.paginated = false;
      clonedCollection.fetch({
        success: function() {
          clonedCollection.models = clonedCollection.where(l_filterMultiSelectColl);
          self.model.multiselectColl.add(group);
          self.model.coll.forEach(function(model) {
            model.trigger("row:checked");
          });
          self._manageMultiSelection();
        }
      });
    }
    return false;
  },

  periodeContainesPart: function(periodeParent, periodeSon) {
    if (periodeParent.hdeb < periodeSon.hdeb && periodeParent.hfin <= periodeSon.hdeb ||
      periodeParent.hdeb >= periodeSon.hfin && periodeParent.hfin > periodeSon.hfin || !periodeSon.hdeb && !periodeSon.hfin) {
      return false;
    } else {
      return true;
    }
  },
  /**
   * Clear group selected rows.
   */
  clearSelectedGroupRows: function(group) {
    var clonedCollection = this.model.coll.clone();

    clonedCollection.setHabContext(this.model.coll.getHabContext());
    clonedCollection.models = _.filter(clonedCollection.models, function(model) {
      if (model.code === group.code) {
        return true;
      }
      return false;
    });
    this.model.multiselectColl.remove(group);
    if (this.checkGroupe === false) {
      clonedCollection.forEach(function(model) {
        model.trigger("row:unchecked");
      });
    }
    this._manageMultiSelection();
  },

  isEmptyRow: function(model) {
    // To be used it must be override.
    return false;
  }
});