import { BaseModel } from './base.model.js';
import { STR } from '../../utils/str.js';

/**
 * Model used to retrieve authorized file extensions
 *
 *  The id will be used to know the set of extensions to return (examples of id are: PHCOLLAB, PHEVTABS, ...)
 */
export var FileExtensionModel = BaseModel.extend({

  dummy: "",
  /**
   * habilitationV
   */
  habilitationV: "",
  /**
   * habilitationG
   */
  habilitationG: "",
  /**
   * usecase
   */
  usecase: "",
  /**
   * canviewTreatment
   */
  canViewTreatment: true,

  /**
   * urlRoot
   */
  urlRoot: Configuration.restRoot + "/rest/fichier/extensions",
  /**
   * Process to manage the response
   */
  parse: function(response) {
    var data;
    if (_.isArray(response)) {
      _.each(response, function(item) {
        if (STR.isBlank(data)) {
          data = "." + item.extension;
        } else {
          data += ",." + item.extension;
        }
      }, this);
      return { "data": data };
    }
    return data;
  }

});