import { BaseModel } from 'core/models/base.model.js';
import { DelegationsDroitsCollabModel } from './delegationsDroitsCollab.model.js';
import { DelegationsModel } from './delegations.model.js';
import { GLOBAL_DATA } from '../../../../globalData.js';
import { HABILITATION } from '../../../../utils/habilitation.js';
import { i18n } from '../../../../i18n.js';
import { LOG } from '../../../../utils/log.js';
import { MSGS } from '../../../../utils/msgs.js';
import { ReadOnlyModel } from 'core/models/readOnly.model.js';
import { STR } from '../../../../utils/str.js';
import { SYNC } from '../../../../utils/sync.js';
import { TYPE } from '../../../../tda/tda.js';

export var DroitsdelWorkflow = ReadOnlyModel.extend({

  /**
   * Event launch for Backbone when a model change the property value
   *
   */

  /**
   * Constructor
   * Workflow model for component droits delegations
   */
  initialize: function(options) {
    // Initialize the constant
    this.EMPTY_MOTIF = new DelegationsModel();
    //this.combosModel = null;
    this.filterModel = null;
    this.tableModel = null;
    this.btnBarModel = null;
    this.formModel = null;
    //this.headerModel = null;
    this.navigationModel = null;

    this.droitsBase = new DelegationsDroitsCollabModel();

    this.collectionBase = null;
    this.isCreation = false;
    //params
    this.pDiversIdCollabModel = GLOBAL_DATA.paramDivers.get("idCollab");
    this.pDiversModifDeleg = GLOBAL_DATA.paramDivers.get("modifDeleg");
    this.paramIdCollab = this.pDiversIdCollabModel.get("valeur");
    this.deleAfRole = 0; //valeur par defaut
    this.deleNiveau = 0; //valeur par defaut

    if (GLOBAL_DATA.paramDivers.get("deleAfRole") && GLOBAL_DATA.paramDivers.get("deleAfRole").get("valeur")) {
      this.deleAfRole = parseInt(GLOBAL_DATA.paramDivers.get("deleAfRole").get("valeur"), 10);
    }

    if (GLOBAL_DATA.paramDivers.get("deleNiveau") && GLOBAL_DATA.paramDivers.get("deleNiveau").get("valeur")) {
      this.deleNiveau = parseInt(GLOBAL_DATA.paramDivers.get("deleNiveau").get("valeur"), 10);
    }

    // ready = true when the setUp is finished. Just one setUp per life
    // cycle of the module
    this.set("ready", false);

    //set the utilCode of actual collaborateur
    if (options && options.utilCode) {
      this.utilCode = options.utilCode;
    }

    this.fullDroids = true;
    this.fullModels = null;

    if (options && options.ctxPeriode) {
      this.ctxPeriode = options.ctxPeriode;
      this.isCreation = true;
    }
  },

  /**
   * Set up the workflow. Link the models between them
   */
  setUp: function(callback) {
    // Load data
    var self = this;

    this.filterModel.off("search");
    this.formModel.off("form:edited");
    this.navigationModel.off("change:navigation");
    // Declare events consumers
    this.filterModel.on("search", this._filterTable, this);
    this.tableModel.off("change:value");
    this.tableModel.on("change:value", this._tableValueChange, this);
    this.btnBarModel.off("btn:click");
    this.btnBarModel.on("btn:click", this._buttonAction, this);
    this.formModel.on("form:edited", this._formEdited, this);
    this.navigationModel.on("change:navigation", this._navigateRow, this);
    this.listenTo(this.tableModel.coll, "collectionParams:cleaned", function() {
      self.filterModel.trigger("clean:filter");
    });

    if (this.utilCode && HABILITATION.canView(this.getHabContext().get("fonc_cour"))) {
      this.droitsBase.setHabContext(this.getHabContext());

      this.tableModel.coll.setHabContext(this.getHabContext());
      this.tableModel.coll.utilCode = this.utilCode;

      if (this.filterModel) {
        var filter = {};

        if (this.ctxPeriode && this.ctxPeriode.datedeb) {
          filter.datedeb = this.ctxPeriode.datedeb;
        }

        if (this.ctxPeriode && this.ctxPeriode.datefin) {
          filter.datefin = this.ctxPeriode.datefin;
        }

        filter.utilCode = this.utilCode;

        this.tableModel.coll.applyFilter(filter)
      }

      this.tableModel.coll.fetch({
        success: function() {
          if (self.tableModel.coll.length == 0) {
            if (!self.isCreation) {
              self.btnBarModel.set("mode", "C");
              self.formModel.trigger("hide:form");
            } else {
              //Customer#188579: initiate in creation mode if the list of the left screen is empty
              self.btnBarModel.set("mode", "E");
              if (self.get("ctxPeriode") && !STR.isBlank(self.get("ctxPeriode").datedeb) && !STR.isBlank(self.get("ctxPeriode").datefin)) {
                self.formModel.get("value").set("datedeb", self.get("ctxPeriode").datedeb);
                self.formModel.get("value").set("datefin", self.get("ctxPeriode").datefin);
              }
              if (self.form.ctxEcran === "suivicollab" || self.form.ctxEcran === "utilisateur" || self.form.ctxEcran === "droitsdel") {
                self.droitsBase.datefrom = self.formModel.get("value").get("datedeb");
                self.droitsBase.queretro = true;
                self.droitsBase.delegation = undefined;
              }
              self.droitsBase.utilCode = self.utilCode;
              self.droitsBase.fetch({
                success: function(fresh) {
                  self.collectionBase = self._arrayToCollection(fresh.get("profils"));
                  self.form.table.model.coll.reset(self.collectionBase.models);
                  self.form._manageInitChecks();

                  //change the view droits to masquer
                  $(self.form.$el).find(".masquerAfficherDroits").text(i18n.t('droitsdel.masquer'));
                  self.fullDroids = true;
                }
              });
              self.formModel.trigger("show:form");
            }
          } else if (self.tableModel.coll.length > 0) {
            self.btnBarModel.set("mode", "R");
            self.formModel.trigger("show:form");
          }
          if (callback) {
            callback();
          }
        },
        error: function() {
          if (callback) {
            callback();
          }
        }
      });
    } else {
      if (callback) {
        callback();
      }
    }

    this.set("ready", true);
  },

  /**
   * Masquer or afficher the droits delegues
   */
  _masquerDroits: function(type) {
    if (type === "masquer") {
      this.fullDroids = false;
      this.fullModels = this.form.table.model.coll.models;
    } else if (type === "afficher") {
      this.fullDroids = true;
    }

    this._viewDroits();
  },

  _isPostDate: function(date) {
    var post = false;
    if (!STR.isBlank(date)) {
      var today = TYPE.DATE.parse(TYPE.DATE.dateToStr(SYNC.getServerDate())).val;
      post = today >= date ? false : true;
    }
    return post;
  },

  _isPostPeriode: function(datedeb, datefin) {
    var post = false;
    if (this._isPostDate(datedeb) && this._isPostDate(datefin)) {
      post = true;
    }
    return post;
  },

  /**
   * View the droits of the collection in the table
   */
  _viewDroits: function() {
    var self = this;
    if (this.fullDroids) {
      this.form.table.model.coll.reset(this.fullModels);
    } else {
      var collection = new Backbone.Collection();
      _.each(this.fullModels, function(model) {
        //If the filter "masquer" is active, only show droits type "D"
        if (model.get("droit") === "D") {
          if (model.get("nivel") == 1 && self._findChildsN2N3(model.get("nivel1"))) {
            collection.add(model);
          } else if (model.get("nivel") == 2) {
            //adds level 2 if it is a group
            if (model.levelType === "group") {
              collection.add(model);
              //adds level 2 if it is a role with children
            } else if (model.levelType !== "group" && self._findChildsN3(model.get("nivel1"), model.get("nivel2"))) {
              collection.add(model);
            }
            //adds level 3
          } else if (model.get("nivel") == 3) {
            collection.add(model);
          }
        }
      });
      this.form.table.model.coll.reset(collection.models);
    }

    this.form._manageInitChecks();
  },

  /**
   * Find if there are a n2 or n3 model for n1 model
   */
  _findChildsN2N3: function(n1) {
    var objResult = false;
    var self = this;
    _.each(this.form.table.model.coll.models, function(model) {
      if (model.get("nivel") == 2 && model.get("nivel1") == n1 && self._findChildsN3(model.get("nivel1"), model.get("nivel2"))) {
        objResult = true;
      }
    });

    return objResult;
  },

  /**
   * Find if there are a n3 model for n2 model
   */
  _findChildsN3: function(n1, n2) {
    var objResult = false;

    _.each(this.form.table.model.coll.models, function(model) {
      if ((model.get("nivel") == 3 || (model.get("nivel") == 2 && model.levelType === "group")) &&
        model.get("nivel1") == n1 && model.get("nivel2") == n2 && model.get("droit") === "D") {
        objResult = true;
      }
    });

    return objResult;
  },

  /**
   * Load the collection in the table
   */
  _loadData: function(utilCode, callback) {
    this.btnBarModel.set("mode", "R");

    var self = this;
    this.EMPTY_MOTIF.set(this.EMPTY_MOTIF.defaults, { silent: true });

    if (utilCode) {
      this.tableModel.coll.utilCode = utilCode;
      self.tableModel.coll.fetch({
        success: function() {
          if (self.tableModel.coll.models.length === 0) {
            self.tableModel.set("value", null);
            self.formModel.set("value", self.EMPTY_MOTIF, { silent: true });
            self.trigger("resetForm");
            self.btnBarModel.set("mode", "C");
            self.formModel.trigger("hide:form");
          }
          if (callback) {
            callback();
          }
        },
        error: function() {
          if (callback) {
            callback();
          }
        }
      });

    } else {
      this.tableModel.coll.reset();
      this.tableModel.set("value", null);
      this.formModel.set("value", this.EMPTY_MOTIF, { silent: true });
      this.trigger("resetForm");
      if (callback) {
        callback();
      }
    }
  },

  /**
   * Move the model when pulsed on the navigation bar
   */
  _navigateRow: function(action) {
    switch (action) {
      case 1: // navigate to first row (see NavigationView)
        this.tableModel.selectFirstRow();
        break;
      case 2: // navigate to prev row (see NavigationView)
        this.tableModel.selectPreviousRow();
        break;
      case 3: // navigate to next row (see NavigationView)
        this.tableModel.selectNextRow();
        break;
      case 4: // navigate to last row (see NavigationView)
        this.tableModel.selectLastRow();
        break;
    }
  },

  /**
   * Apply the filter to the collection
   */
  _filterTable: function(filter) {
    LOG.debug("Filter table " + filter);
    var self = this;
    var newFilter = {};
    if (filter.datedeb) {
      newFilter.datedeb = filter.datedeb;
    }

    if (filter.datefin) {
      newFilter.datefin = filter.datefin;
    }

    if (!STR.isBlank(filter.delegue) && !STR.isBlank(filter.delegue.matricule)) {
      newFilter.delegue = filter.delegue.utilcode;
    }

    if (filter.retro) {
      if (filter.retro !== "all") {
        newFilter.retro = filter.retro;
      }
    }

    var tableColl = this.tableModel.coll;
    if (!STR.isBlank(tableColl.utilCode)) {
      tableColl.applyFilter(newFilter);
      tableColl.fetch({
        success: function() {
          if (tableColl.length > 0) {
            var row = self.tableModel.coll.at(0);
            self.tableModel.resetScroll();
            row.trigger("row:click", row);
          } else {
            self.tableModel.set("value", null);
          }
        }
      });
    }
  },

  /**
   * Change the value to the left table and change the form model
   */
  _tableValueChange: function(model) {
    var value = model.get("value");
    var valueForm = new DelegationsModel();
    var modelId = (value == null) ? null : encodeURIComponent(value.get("id"));
    var modelUtilCode = this.utilCode;
    LOG.debug("Table Value Changed to : " + modelId);

    // Update Btn Bar and Form
    if (value == null) {
      this.btnBarModel.set("mode", "R");
      this.btnBarModel.set("mode", "C");
      this.formModel.trigger("hide:form");
      this.EMPTY_MOTIF.set(this.EMPTY_MOTIF.defaults, {
        silent: true
      });
      this.formModel.set("value", this.EMPTY_MOTIF);
      //this.headerModel.set("value", this.EMPTY_MOTIF);
      this.navigationModel.set("navigationState", 3); // all navigationbar buttons disabled
      this.isCreation = false;
    } else {
      // Fetch the complete data of the model before notifying the form
      this.btnBarModel.set("mode", "C");
      this.btnBarModel.set("mode", "R");
      var self = this;
      valueForm.utilCode = modelUtilCode;
      valueForm.id = modelId;
      valueForm.usecase = self.form.ctxEcran;
      valueForm.setHabContext(this.getHabContext());
      if (modelUtilCode != null) {
        valueForm.fetch({
          success: function(freshModel) {
            // Store new values to the model
            freshModel.store();
            self._setModelOnFormModeRead(freshModel);
            self.isCreation = false;
          },
          data: { datefrom: value.get("datedeb") }
        });

      } else {
        valueForm.revert();
        self._setModelOnFormModeRead(valueForm);
        this.isCreation = false;
      }
    }

  },

  _setModelOnFormModeRead: function(value) {
    this.formModel.set("value", value);

    var allChecked = this.form.isAllChecked;
    if (this.fullModels && this.fullModels.length === 0) {
      allChecked = false;
    }
    if (this.form.table.$el) {
      (this.form.table.$el).find(".checkAll").prop("checked", allChecked);
    }

    this.fullModels = this.form.table.model.coll.models;
    this._viewDroits();

    if (_.first(this.tableModel.coll.models) == value) {
      if (this.tableModel.coll.length === 1) {
        this.navigationModel.set("navigationState", 3); // only 1 element, disable all
      } else {
        this.navigationModel.set("navigationState", 1); // first,prev navigationbar buttons disabled
      }
    } else if (_.last(this.tableModel.coll.models) == value) {
      this.navigationModel.set("navigationState", 2); // first,prev navigationbar buttons disabled
    } else {
      this.navigationModel.set("navigationState", 0); // all navigationbar buttons enabled
    }
    this.formModel.trigger("show:form");
  },
  /**
   * Load the action to the Button Bar
   */
  _buttonAction: function(buttonId) {
    LOG.debug("Button clicked in droitsdel : " + buttonId);
    var self = this;
    switch (buttonId) {
      case "save":
        var target = this.formModel.get("value");
        var objArray = this._collectionToArray(this.form.table.model.coll);

        target.utilCode = this.utilCode;
        target.set("profils", objArray);
        target.usecase = this.form.ctxEcran;
        target.setHabContext(this.getHabContext());

        target.save(null, {
          success: function(fresh) {
            self.tableModel.coll.fetch({
              success: function() {
                self.isCreation = false;
                self.isCopying = false;
                self.tableModel.selectRow(fresh);
              }
            });
          }
        });
        break;
      case "revert":
        this.isCreation = false;
        this.isCopying = false;
        if (this.tableModel.get("value") == null) {
          // Revert a create operation.
          var row = null;
          if (this.tableModel.formerLineID) {
            row = this.tableModel.coll.get(this.tableModel.formerLineID);
            this.tableModel.formerLineID = null;
          }

          if (row === undefined || _.isNull(row)) {
            this.formModel.trigger("hide:form");
            this.btnBarModel.set("mode", "R");
            this.EMPTY_MOTIF.set(this.EMPTY_MOTIF.defaults, { silent: true });
            this.formModel.trigger("change:value");
            this.btnBarModel.set("mode", "C");
            this.tableModel.set("value", null);
          } else {
            this.EMPTY_MOTIF.set(this.EMPTY_MOTIF.defaults, { silent: true });
            row.trigger("row:click", row);

          }
        } else if (this.tableModel.get("value").get("id") != this.formModel.get("value").get("id")) {
          // Revert a duplicate operation
          var models = this.form.table.model.coll.models;
          var row = this.tableModel.get("value");
          this.formModel.set("value", row);
          this.form.table.model.coll.reset(models);
          this.btnBarModel.set("mode", "R");
          self._setModelOnFormModeRead(row);

        } else {
          // Revert an update operation.
          this.formModel.get("value").usecase = this.form.ctxEcran;
          this.formModel.get("value").setHabContext(this.getHabContext());

          this.formModel.get("value").revert();
          this.formModel.trigger("change:value");
          this.fullModels = this.form.table.model.coll.models;
          this._viewDroits();

          this.btnBarModel.set("mode", "R");
        }
        this.form._manageInitChecks();

        break;
      case "new":
        this.form.isAllChecked = false;
        this.isCreation = true;
        this.tableModel.formerLineID =
          this.tableModel.get("value") && this.tableModel.get("value").get("id") ?
          this.tableModel.get("value").get("id") : null;
        if (this.tableModel.get("value") != null) {
          this.tableModel.get("value").trigger("row:unselect", this.tableModel.get("value"));
          this.tableModel.set("value", null);

          if (self.form.ctxEcran == "suivicollab" || self.form.ctxEcran == "utilisateur" || self.form.ctxEcran == "droitsdel") {
            self.droitsBase.datefrom = self.formModel.get("value").get("datedeb");
            self.droitsBase.queretro = true;
            self.droitsBase.delegation = undefined;
          }

          self.droitsBase.utilCode = self.utilCode;

          self.droitsBase.fetch({
            success: function(fresh) {
              self.collectionBase = self._arrayToCollection(fresh.get("profils"));
              self.form.table.model.coll.reset(self.collectionBase.models);
              self.form._manageInitChecks();

              //change the view droits to masquer
              $(self.form.$el).find(".masquerAfficherDroits").text(i18n.t('droitsdel.masquer'));
              self.fullDroids = true;
            }
          });

        } else {
          this.EMPTY_MOTIF.set(this.EMPTY_MOTIF.defaults, { silent: true });
          this.formModel.set("value", this.EMPTY_MOTIF, { silent: true });
          this.formModel.trigger("change:value");
          this.form.table.model.coll.reset();

          if (self.form.ctxEcran == "suivicollab" || self.form.ctxEcran == "utilisateur" || self.form.ctxEcran == "droitsdel") {
            self.droitsBase.datefrom = self.formModel.get("value").get("datedeb");
            self.droitsBase.queretro = true;
            self.droitsBase.delegation = undefined;
          }
          self.droitsBase.utilCode = self.utilCode;

          self.droitsBase.fetch({
            success: function(fresh) {
              self.collectionBase = self._arrayToCollection(fresh.get("profils"));
              self.form.table.model.coll.reset(self.collectionBase.models);
              self.form._manageInitChecks();

              //change the view droits to masquer
              $(self.form.$el).find(".masquerAfficherDroits").text(i18n.t('droitsdel.masquer'));
              self.fullDroids = true;
            }
          });
        }
        this.btnBarModel.set("mode", "E");
        this.formModel.trigger("show:form");
        break;

      case "copy":
        if (this.tableModel.get("value") != null) {
          this.isCreation = true;
          var copy = new DelegationsModel(this.formModel.get("value").toJSON());
          var models = this.form.table.model.coll.models;
          //Delete attributes that are not needed for DelegationsModel
          copy.unset("auteur", { silent: true });
          copy.unset("datetrace", { silent: true });
          copy.unset("delegant", { silent: true });
          copy.unset("heuretrace", { silent: true });
          copy.unset("profil", { silent: true });
          copy.set({
            codeDelegation: copy.id,
            id: null,
            code: null
          }, {
            silent: true
          });
          this.formModel.set("value", copy);
          this.form.table.model.coll.reset(models);
          this.btnBarModel.set("mode", "E");
          this.form._manageInitChecks();
        }
        break;
      case "delete":
        var target = this.formModel.get("value");
        if (this.pDiversModifDeleg.get("valeur") == 1 && !this._isPostPeriode(target.get("datedeb"), target.get("datefin"))) {
          this.form._showSupprimerError();
        } else {
          MSGS.showConfirm(i18n.t('droitsdel.delconfirm'), function(result) {
            if (result == "Y") {
              //						var target = self.formModel.get("value");
              var position = self._findObject(self.tableModel.coll.models, target, "code");
              target.usecase = self.form.ctxEcran;
              target.setHabContext(self.getHabContext());
              target.destroy({
                success: function() {
                  self.tableModel.coll.fetch({
                    success: function() {
                      var next = self.tableModel.coll.at(position);
                      if (next === undefined && position > 0) {
                        next = self.tableModel.coll.at(position - 1);
                      }
                      if (next != undefined) {
                        next.trigger("row:click", next);
                      } else {
                        self.tableModel.set("value", null);
                      }
                    }
                  });
                },
                wait: true
              });
            }
          });
          break;
        }
    }

  },

  /**
   * Find the object into the collection with the value of attribute
   */
  _findObject: function(collection, object, attrib) {
    var resp = -1;
    _.each(collection, function(obj, index) {
      if (obj.get(attrib) == object.get(attrib)) {
        resp = index;
      }
    });

    return resp;
  },

  /**
   * Function launched when a field is edited
   */
  _formEdited: function() {
    this.btnBarModel.set("mode", "E");
  },

  /**
   * Transforms a Backbone collection to an array
   */
  _collectionToArray: function(objCollection) {
    var self = this;
    var elements = [];

    if (objCollection && objCollection.models) {
      _.each(objCollection.models, function(obj) {
        var objElement = {};
        if (obj.get("nivel") == 1) { //profils
          objElement.code = obj.get("code");
          objElement.libelle = obj.get("libelle");
          objElement.complet = obj.get("complet");
          elements.push(objElement);
        } else if (obj.get("nivel") == 2 && obj.levelType === "role") { //roles
          var objNivel1 = _.last(elements);
          //roles container
          if (!objNivel1.roles) {
            objNivel1.roles = [];
          }
          objElement.code = obj.get("nivel2");
          objElement.libelle = obj.get("libelle");
          objElement.perimetres = obj.get("perimetres");
          objNivel1.roles.push(objElement);
        } else if (obj.get("nivel") == 3 || (obj.get("nivel") == 2 && obj.levelType === "group")) { //groupes
          //find the last profil of nivel 1
          var objNivel1 = _.last(elements);
          var profilComplet = objNivel1.complet;

          if (obj.get("nivel") == 2) {
            // group in level 2 needs a role father
            if (!objNivel1.roles && !STR.isBlank(obj.role)) {
              objNivel1.roles = [];
              var objRole = obj.role;
              objNivel1.roles.push(objRole);
            }
            objNivel1 = objNivel1.roles[0];
          } else {
            objNivel1 = _.last(objNivel1.roles);
          }
          //group container
          if (!objNivel1.groupes) {
            objNivel1.groupes = [];
          }
          objElement.code = obj.get("code");
          objElement.libelle = obj.get("libelle");
          objElement.datedeb = obj.get("datedeb");
          objElement.datefin = obj.get("datefin");
          objElement.perimetre = obj.get("perimetre");
          if (self.deleNiveau === 1) {
            // group without chekbox value from profil checkbox
            objElement.delegue = profilComplet;
          } else {
            objElement.delegue = obj.get("delegue");
          }

          objElement.parent = obj.get("parent");
          objElement.droit = obj.get("droit");
          //delegant
          var delegant = {};
          if (!_.isNull(obj.get("delegant"))) {
            delegant.matricule = obj.get("delegant").get("matricule");
            delegant.matriculeaux = obj.get("delegant").get("matriculeaux");
            delegant.nom = obj.get("delegant").get("nom");
            delegant.prenom = obj.get("delegant").get("prenom");
            delegant.utilcode = obj.get("delegant").get("utilcode");
            delegant.utilnom = obj.get("delegant").get("utilnom");
          } else {
            delegant = null;
          }
          objElement.delegant = delegant;

          //insert in the array if delegue = true
          if (objElement.delegue) {
            objNivel1.groupes.push(objElement);
          }
        }

      });
    }

    return elements;
  },

  /**
   * Transforms a Array to Backbone collection
   */

  _arrayToCollection: function(objArray) {
    var collection = new Backbone.Collection();
    var self = this;
    if (objArray) {
      var maxRoles = 0;

      _.each(objArray, function(obj) {
        var roles = (obj && obj.roles) ? obj.roles.length : 0;
        maxRoles = (roles > maxRoles) ? roles : maxRoles;
      });

      //nivel 1 (profils)
      _.each(objArray, function(obj1, index1) {
        var model = new BaseModel();

        model.set("nivel", 1);
        model.set("nivel1", obj1.code);
        model.set("code", obj1.code);
        model.set("libelle", obj1.libelle);
        model.set("complet", obj1.complet);
        model.set("droit", obj1.droit);
        model.set("id", index1 + "," + obj1.code);

        //atributes for tree table view
        model.isExpanded = false;
        model.level = 1;
        model.collapsible = true;
        model.branch = index1;
        //have a children
        if (obj1.roles && obj1.roles.length > 0) {
          model.hasChild = true;
        } else {
          model.hasChild = false;
        }

        collection.add(model);
        // No Role-Depends ParamDivers deleAfRole
        if (maxRoles <= 1 && self.deleAfRole === 0) {
          //hidden roles -> nivel 2 (groups)
          var obj2 = obj1.roles[0];
          //nivel 3  (habs)
          _.each(obj2.groupes, function(obj3, index3) {
            //var nivel = 3;
            var model3 = new BaseModel();

            model3.set("nivel", 2);
            model3.set("nivel1", obj1.code);
            model3.set("nivel2", obj2.code);
            model3.set("code", obj3.code);
            model3.set("libelle", obj3.libelle);
            model3.set("datedeb", obj3.datedeb);
            model3.set("datefin", obj3.datefin);
            model3.set("perimetre", obj3.perimetre);
            model3.set("delegue", obj3.delegue);
            model3.set("parent", obj3.parent);
            model3.set("droit", obj3.droit);
            model3.set("id", index1 + "," + index3 + "," + obj3.code);
            model3.set("id1", index1 + "," + obj1.code);
            model3.set("id2", index1 + "," + null + "," + obj2.code);

            //atributes for tree table view
            model3.isExpanded = true;
            model3.level = 2;
            model3.levelType = "group";
            model3.collapsible = true;
            model3.branch = index1;
            //have a children
            model3.hasChild = undefined;
            //role information
            model3.role = {};
            model3.role.code = obj2.code;
            model3.role.libelle = obj2.libelle
            model3.role.perimetres = obj2.perimetres

            //delegant
            var delegant = new BaseModel;
            if (!_.isNull(obj3.delegant)) {
              delegant.set("matricule", obj3.delegant.matricule);
              delegant.set("matriculeaux", obj3.delegant.matriculeaux);
              delegant.set("nom", obj3.delegant.nom);
              delegant.set("prenom", obj3.delegant.prenom);
              delegant.set("utilcode", obj3.delegant.utilcode);
              delegant.set("utilnom", obj3.delegant.utilnom);
            } else {
              delegant = null;
            }
            model3.set("delegant", delegant);
            collection.add(model3);
          });
        } else { // Role Painted
          //nivel 2 (Roles)
          _.each(obj1.roles, function(obj2, index2) {
            var model2 = new BaseModel();
            model2.set("nivel", 2);
            model2.set("nivel1", obj1.code);
            model2.set("nivel2", obj2.code);
            model2.set("libelle", obj2.libelle);
            model2.set("perimetres", obj2.perimetres);
            model2.set("droit", obj1.droit);

            if (typeof(index2) === "undefined") {
              model2.set("id2", index1 + "," + null + "," + obj2.code);
            } else {
              model2.set("id2", index1 + "," + index2 + "," + obj2.code);
            }
            model2.set("id1", index1 + "," + obj1.code);
            //atributes for tree table view
            model2.isExpanded = true;
            model2.level = 2;
            model2.levelType = "role";
            model2.collapsible = true;
            model2.branch = index1;
            //have a children
            if (obj2.groupes && obj2.groupes.length > 0) {
              model2.hasChild = true;
            } else {
              model2.hasChild = false;
            }

            collection.add(model2);

            //nivel 3 (groups)
            _.each(obj2.groupes, function(obj3, index3) {
              var model3 = new BaseModel();
              model3.set("nivel", 3);
              model3.set("nivel1", obj1.code);
              model3.set("nivel2", obj2.code);
              model3.set("code", obj3.code);
              model3.set("libelle", obj3.libelle);
              model3.set("datedeb", obj3.datedeb);
              model3.set("datefin", obj3.datefin);
              model3.set("perimetre", obj3.perimetre);
              model3.set("delegue", obj3.delegue);
              model3.set("parent", obj3.parent);
              model3.set("droit", obj3.droit);
              model3.set("id", index1 + "," + index2 + "," + index3 + "," + obj3.code);
              model3.set("id1", index1 + "," + obj1.code);

              if (typeof(index2) === "undefined") {
                model3.set("id2", index1 + "," + null + "," + obj2.code);
              } else {
                model3.set("id2", index1 + "," + index2 + "," + obj2.code);
              }
              //atributes for tree table view
              model3.isExpanded = true;
              model3.level = 3;
              model3.collapsible = true;
              model3.branch = index1;
              //have a children
              model3.hasChild = undefined;

              //delegant
              var delegant = new BaseModel;
              if (!_.isNull(obj3.delegant)) {
                delegant.set("matricule", obj3.delegant.matricule);
                delegant.set("matriculeaux", obj3.delegant.matriculeaux);
                delegant.set("nom", obj3.delegant.nom);
                delegant.set("prenom", obj3.delegant.prenom);
                delegant.set("utilcode", obj3.delegant.utilcode);
                delegant.set("utilnom", obj3.delegant.utilnom);
              } else {
                delegant = null;
              }
              model3.set("delegant", delegant);
              collection.add(model3);
            });
          });
        }
      });
    }

    return collection;
  }

});
