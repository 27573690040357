import { GLOBAL_DATA } from '../globalData.js';
import { STR } from './str.js';

/**
 * Utilities to check habilitations
 */
export var HABILITATION = {

  dummy: "",

  /**
   * Check if we can view with this habilitation
   */
  canView: function(fonction) {
    try {
      var right = "";
      var fonctions = this.toArray(fonction);

      for (var i = 0, l = fonctions.length; i < l; i++) {
        right = right || GLOBAL_DATA.rights.get(fonctions[i]);
      }
      return !STR.isBlank(right);
    } catch (err) {
      return false;
    }
  },

  /**
   * Check if we have all view habilitations
   */
  canViewAll: function(fonction) {
    try {
      var right = true;
      var fonctions = !_.isArray(fonction) ? this.toArray(fonction) : fonction;

      for (var i = 0, l = fonctions.length && right; i < l; i++) {
        right = right && !STR.isBlank(GLOBAL_DATA.rights.get(fonctions[i]));
      }
      return right;
    } catch (err) {
      return false;
    }
  },

  /**
   * Check if we can create with this habilitation
   */
  canCreate: function(fonction) {
    try {
      var right = false;
      var fonctions = this.toArray(fonction);

      for (var i = 0, l = fonctions.length; i < l; i++) {
        right = right || (!STR.isBlank(GLOBAL_DATA.rights.get(fonctions[i])) && GLOBAL_DATA.rights.get(fonctions[i]).get("gesta") == "O");
      }
      return right;
    } catch (err) {
      return false;
    }
  },

  /**
   * Check if we can update with this habilitation
   */
  canUpdate: function(fonction) {
    try {
      var right = false;
      var fonctions = this.toArray(fonction);

      for (var i = 0, l = fonctions.length; i < l; i++) {
        right = right || (!STR.isBlank(GLOBAL_DATA.rights.get(fonctions[i])) && GLOBAL_DATA.rights.get(fonctions[i]).get("gestm") == "O");
      }
      return right;
    } catch (err) {
      return false;
    }
  },

  /**
   * Check if we can delete with this habilitation
   */
  canDelete: function(fonction) {
    try {
      var right = false;
      var fonctions = this.toArray(fonction);

      for (var i = 0, l = fonctions.length; i < l; i++) {
        right = right || (!STR.isBlank(GLOBAL_DATA.rights.get(fonctions[i])) && GLOBAL_DATA.rights.get(fonctions[i]).get("gests") == "O");
      }
      return right;
    } catch (err) {
      return false;
    }
  },

  /**
   * Transforms a string into a array
   */
  toArray: function(fonction) {
    return fonction.split(",");
  }

};