import { BadgeageVirtuelMenuView } from 'common/badgeage/views/badgeageVirtuelMenu.view.js';
import { LOG } from '../../utils/log.js';
import { NAV } from '../../utils/nav.js';
import { objs } from '../../objectsRepository.js';
import { PopulationMenuView } from 'common/populationsmenu/views/populationMenu.view.js';
import { STR } from '../../utils/str.js';
import TPL_app_zone_item from '../zone_item.tpl.html';

/**
 * Zone Selector View, Application Header
 */
export var ZoneSelectorView = Backbone.View.extend({

  tagName: "span",

  initialize: function() {
    this.template = TPL_app_zone_item;
    //Store as constant the ids of the zones
    this._zones = _.keys(this.model.ZONES);
    this.model.on("change:zone", this._manageZoneChange, this);
    _.each(this._zones, function(zone) {
      this.model.on("change:tabs-" + zone, this._numTabsChanged, this);
    }, this);
    // listen resize event
    this.listenTo(Backbone, "resize:views", this._resizeHeader);
    this.listenTo(Backbone, "resize:header", this._resizeHeader);
    this.listenTo(this.model, "hideZone", this._hideZone);
    this.listenTo(this.model, "showZone", this._showZone);
    //this.listenTo(GLOBAL_DATA.rights, "sync", this._checkPopulations);
  },

  _checkPopulations: function() {
    if (this.model.RESP_POPULATION.showMenu === true) {
      this._loadPopulations();
    }
  },

  _loadPopulations: function() {
    var self = this;
    if (this.$el.find(".phx-populations-menu-zone").length) {
      this.$el.find(".phx-populations-menu-zone").remove();
    }
    var component = [];
    component[0] = "populationsmenu";
    objs.populationMenu = new PopulationMenuView();
    self.$el.append(objs.populationMenu.render().el);
    if (self.model.get("zone") !== "resp") {
      self.$el.find(".phx-populations-menu-zone").css("display", "none");
    }
  },

  events: {
    "click .phx-zones-menu > li": "_clickListener"
  },

  _clickListener: function(event) {
    if ($(event.target).hasClass("ui-state-disabled")) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    var btnClicked = event.currentTarget.id;
    var zoneId = btnClicked.replace("phx-btn-zone-", "");
    //Mark ARIA to indentify whichi option is checked.
    this._updateAriaChecked(event.currentTarget, zoneId);
    var module = null;
    var type = null;
    if (!STR.isBlank(this.model.get("selectedTab-" + zoneId))) {
      module = this.model.get("selectedTab-" + zoneId).module;
      type = this.model.get("selectedTab-" + zoneId).type;
    }
    NAV.navigate(zoneId, module, type);
  },

  _hideZone: function(zone) {
    this.$el.find(".phx-zones-menu li#phx-btn-zone-" + zone.id).addClass("ui-state-disabled");
  },

  _showZone: function(zone) {
    this.$el.find(".phx-zones-menu li#phx-btn-zone-" + zone.id).removeClass("ui-state-disabled");
  },

  /**
   * Updates the view to the new zone selected
   */
  _manageZoneChange: function() {
    var zone = this.model.get("zone");

    // Set all zones to default state
    _.each(this._zones, function(tempZone) {
      $("#phx-btn-zone-" + tempZone, this.$el).removeClass("ui-state-active");
    }, this);
    // Set selected zone to active state
    $("#phx-btn-zone-" + zone, this.$el).addClass("ui-state-active");

    if (objs.gmsituation) {
      objs.gmsituation.close();
      objs.gmsituation = null;
    }

    if (zone === "resp") {
      this.$el.find(".phx-populations-menu-zone").css("display", "inline-block");
    } else {
      this.$el.find(".phx-populations-menu-zone").css("display", "none");
    }

    this._moveMenuComponentsToZone();
  },

  /*
   * Update data for accesibility
   */
  _updateAriaChecked: function(target) {
    _.each(this.$el.find(">.phx-zones-menu > li"), function(liElement) {
      $(liElement).attr("aria-checked", "false");
    });
    $(target).attr("aria-checked", "true");
  },

  _numTabsChanged: function(model) {
    var zone = _.find(_.keys(model.changed), function(element) { return element.indexOf("tabs-") === 0; }).split("-")[1];
    var numTabs = this.model.get("tabs-" + zone);
    LOG.debug("Num Tabs of " + zone + " has changed to : " + numTabs);
    // Change label
    var zname = $("#phx-btn-zone-" + zone, this.$el).html().split("(")[0];
    if (numTabs > 0) {
      zname = zname + " (" + numTabs + ")";
    }
    $("#phx-btn-zone-" + zone, this.$el).html(zname);
  },

  render: function() {
    this.$el.empty();
    var containerdiv = "<div class='phx-zones-menu' role='menubar'  style='display:inline-block'></div>";
    this.$el.append(containerdiv);
    _.each(this.model.configuration.get("confignav").get("zones"), function(zone, index) {
      var zoneHtml = this.template(zone);
      if (index === this._zones.length - 1) {
        // Remove the last "|" char
        zoneHtml = zoneHtml.substring(0, zoneHtml.length - 1);
      }
      this.$el.find(".phx-zones-menu").append(zoneHtml);
      if (index === 0) {
        this.$el.find(".phx-zones-menu").find("li").last().attr("aria-checked", "true");
      } else {
        this.$el.find(".phx-zones-menu").find("li").last().attr("aria-checked", "false");
      }
      //Prepare keyboard Navigation
      var self = this;
      this.$el.find(".phx-zones-menu #phx-btn-zone-" + zone.id).keydown(function(e) {
        self._zoneSelectorKeyboardNav(e);
      });
    }, this);
    _.each(this.model.configuration.get("confignav").get("zones"), function(zone) {
      if (zone.id === "resp") {
        this._checkPopulations();
      }
    }, this);
    this.$el.attr("role", "navigation");
    this.$el.attr("tabindex", "0");
    this.$el.append("<div class='phx-menus-transversal phx-menus-zone'></div>");

    //Create transversal menus
    this._loadComponents();
    return this;
  },

  _zoneSelectorKeyboardNav: function(e) {
    if (e.keyCode === 37) { // left
      e.preventDefault();
      if ($(e.target).prevAll('li').first().length === 0) {
        $(e.target).parent('.phx-zones-menu').find('> li').last().focus();
      } else {
        $(e.target).prevAll('li').first().focus();
      }
    } else if (e.keyCode === 39) { // right
      e.preventDefault();
      if ($(e.target).nextAll('li').first().length === 0) {
        $(e.target).parent('.phx-zones-menu').find('> li').first().focus();
      } else {
        $(e.target).nextAll('li').first().focus();
      }
    } else if (e.keyCode === 13 || e.keyCode === 32 || e.keyCode === 40) { //enter, spacebar, up arrow, down arrow
      $(e.target).click();

      //Navigate to first submenu in zonemenuView
      var btnClicked = e.target.id;
      var zoneId = btnClicked.replace("phx-btn-zone-", "");
      this.$el.find("#phx-menu-zone-" + zoneId + " > div").first().focus();
      e.preventDefault();
    }

  },

  /**
   * Resize header to adapt the height when the menu relocate its menuitems
   */
  _resizeHeader: function() {
    // Resize header
    var headerHeight = this.$el.find(".phx-zones-menu").outerHeight() + this.$el.find(".phx-menu-zone").filter(":visible").outerHeight() + 8; // Adjusted to give a margin.
    this.$el.height(headerHeight);
    // Resize center depending on the header resize
    Backbone.trigger("resize:center");
  },

  _loadComponents: function() {
    var components = _.keys(this.model.configuration.get("components"));

    this.components = [];
    var size = components.length;
    for (var i = 0; i < size; i++) {
      if (components[i] === "badgeage") {
        this.components[i] = new BadgeageVirtuelMenuView();
      }
    }
    //Create transversal menus
    this._renderMenuComponents();

  },

  _renderMenuComponents: function() {
    var tmenu = this.$el.find(".phx-menus-transversal");
    if (!STR.isBlank(tmenu) && this.components) {
      for (var i = 0; i < this.components.length; i++) {
        tmenu.append(this.components[i].el);
      }
    }
    Backbone.trigger("resize:header");
  },

  _moveMenuComponentsToZone: function() {
    var zone = this.model.get("zone");
    var tmenu = this.$el.find(".phx-menus-transversal");
    tmenu.css("display", "inline-block");
    tmenu.detach();
    tmenu.css("margin-top", "0");
    var zoneEl = this.$el.find("#phx-menu-zone-" + zone);
    zoneEl.append(tmenu);
  }
});
