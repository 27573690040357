import { BaseModel } from 'core/models/base.model.js';
import { i18n } from '../../../../i18n.js';
import { STR } from '../../../../utils/str.js';

/**
 * Model the table "liste de compteurs"
 */
export var CompteursTableModel = BaseModel.extend({

  dummy: "",

  initialize: function() {
    if (STR.isBlank(this.get("compteur")) == true) {
      this.set("compteur", { code: null, libelle: "", format: "" });
    }
    if (STR.isBlank(this.get("periodicite")) == true) {
      this.set("periodicite", { code: null, libelle: "" });
    }
    if (STR.isBlank(this.get("typeresultat")) == true) {
      this.set("typeresultat", { code: null, libelle: "" });
    }
    if (STR.isBlank(this.get("periodevalo")) == true) {
      this.set("periodevalo", { code: null, libelle: "" });
    }
  },

  defaults: {
    "rowid": "",
    "ordre": null,
    "compteur": null,
    "periodicite": null,
    "typeresultat": null,
    "periodevalo": null
  },

  validate: function(attrs) {
    var errors = {};

    if (STR.isBlank(attrs.compteur) || STR.isBlank(attrs.compteur.code)) {
      errors.compteur = {};
      errors.compteur.code = i18n.t('common:required', { "0": i18n.t('common:parametragebresultats.table_col_compteur') });
    }

    if (STR.isBlank(attrs.typeresultat) || STR.isBlank(attrs.typeresultat.code)) {
      errors.typeresultat = {};
      errors.typeresultat.code = i18n.t('common:required', { "0": i18n.t('common:parametragebresultats.table_col_type') });
    }

    if ((attrs.typeresultat.code == "A" || attrs.typeresultat.code == "R") && (STR.isBlank(attrs.periodicite) || STR.isBlank(attrs.periodicite.code))) {
      errors.periodicite = {};
      errors.periodicite.code = i18n.t('common:required', { "0": i18n.t('common:parametragebresultats.table_col_periodicite') });
    }

    if ((attrs.typeresultat.code == "A" || attrs.typeresultat.code == "R") && (STR.isBlank(attrs.periodevalo) || STR.isBlank(attrs.periodevalo.code))) {
      errors.periodevalo = {};
      errors.periodevalo.code = i18n.t('common:required', { "0": i18n.t('common:parametragebresultats.table_col_periode') });
    }

    if (!_.isEmpty(errors)) {
      var errorMap = {
        errorValidation: STR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
  },
  parse: function(response) {
    if (response.rowid !== undefined) {
      response.id = response.rowid;
    }
    return response;
  }

});