module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="motpassediv">\n  <br/>\n  <span class="ctxGestionCollab"></span>\n  <span id="messages" class="messages"></span>\n  <br/>\n  <br/>\n  <br/>\n  <span id="motpassetitle" class="phx-details-header-text">'+
((__t=(i18n.t('login:pwdtitle2')))==null?'':__t)+
'</span>\n  <br/>\n  <span id="messageInfor" class=""></span>\n  <br/>\n  <br/>\n  <br/>\n  <fieldset id="motpasseform" name="motpasseform" action="#">\n    <span class="old_password">\n				<label for="password" title="">'+
((__t=(i18n.t('login:pwdmotpassact')))==null?'':__t)+
'</label>\n				<input id="password" class="password" tabindex="20" type="password" size="35" maxlength="32" />\n				<br/>\n				<br/>\n			</span>\n    <br/>\n    <br/>\n    <label for="pwd_new">'+
((__t=(i18n.t('login:pwdmotpassnou')))==null?'':__t)+
'</label>\n    <input id="pwd_new" class="pwd_new" tabindex="20" type="password" size="35" maxlength="32" />\n    <br/>\n    <br/>\n    <label for="pwd_confirm">'+
((__t=(i18n.t('login:pwdmotpassconf')))==null?'':__t)+
'</label>\n    <input id="pwd_confirm" class="pwd_confirm" tabindex="20" type="password" size="35" maxlength="32" />\n    <br/>\n    <br/>\n    <br/>\n    <br/>\n  </fieldset>\n  <table id="logmotpassebuttons" class="ui-dialog-buttonpane">\n    <tr>\n      <td align="left">\n        <button type="button" class="motpassecontinue" type="button">'+
((__t=(i18n.t('login:pwdenregistrer')))==null?'':__t)+
'</button>\n      </td>\n      <td align="right">\n        <button type="button" class="motpassecancel" type="button">'+
((__t=(i18n.t('login:pwdannuler')))==null?'':__t)+
'</button>\n      </td>\n    </tr>\n  </table>\n</div>';
}
return __p;
};
