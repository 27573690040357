import { BaseModel } from './base.model.js';


export var RemoteFileModel = BaseModel.extend({

  dummy: "",

  /**
   * habilitationV
   */
  habilitationV: "",
  /**
   * habilitationG
   */
  habilitationG: "",
  /**
   * usecase
   */
  usecase: "",
  /**
   * canViewTreatment
   */
  canViewTreatment: true,
  /**
   * urlRoot
   */
  urlRoot: Configuration.restRoot + "/rest/fichier",

  /**
   * Constructor
   * Remote File Model
   */
  initialize: function() {
    //empty
  },
  /**
   * Default data model
   *	"code": null,
   *	"libelle": "",
   *}
   */
  defaults: {
    "code": null,
    "libelle": ""
  },
  /**
   * Process to manage the response
   */
  parse: function(response) {
    if (response && response.code) {
      this.id = response.code;
    }
    return response;
  },

  setHabContext: function(habContext) {
    this.habContext = habContext;
  },

  updateHabContext: function(attributes) {
    if (this.habContext) {
      this.habContext.update(attributes);
    }
  },

  getHabContext: function() {
    return this.habContext;
  }
});