import { DialogView } from 'core/views/dialog.view.js';
import { GeneralView } from 'common/droitsdel/views/general.view.js';
import { GLOBAL_DATA } from '../../globalData.js';
import { i18n } from '../../i18n.js';
import { LOG } from '../../utils/log.js';
import { LoginChangePwdDialogView } from 'core/components/login/loginChangePwdDialog.view.js';
import TPL_app_menu_item_niv1 from '../menu_item_niv1.tpl.html';
import TPL_app_menu_item_niv2 from '../menu_item_niv2.tpl.html';
import TPL_app_menu_item_section from '../menu_item_section.tpl.html';
import TPL_app_usermenu from '../usermenu.tpl.html';

/**
 * UsereMenu View
 *
 * Contain the menus specific to a user
 *
 */
export var UserMenuView = Backbone.View.extend({

  events: {
    "click a": "clickOption",
    "click .phx-menu *": "_clickMenu"
  },

  initialize: function() {
    this.template = TPL_app_usermenu;
    this.templateItemNiv1 = TPL_app_menu_item_niv1;
    this.templateItemNiv2 = TPL_app_menu_item_niv2;
    this.templateItemSection = TPL_app_menu_item_section;
    this.listenTo(GLOBAL_DATA.rights, "sync", this._reloadMenu);
  },

  _reloadMenu: function() {
    this.model.configuration.get("confignav").get("transverses").forEach(function(trans) {
      if (GLOBAL_DATA.rights.menuVisibility.get(trans.emid)) {
        this.$el.find("." + trans.emid).removeClass("ui-state-disabled");
      } else {
        this.$el.find("." + trans.emid).addClass("ui-state-disabled");
      }
    }, this);
  },

  render: function() {
    //Create the DOM for the menu

    var json = { nom: "", alt: "", index: 0, i18n: i18n };
    this.$el.append(this.template(json));
    var menuDom = $(".phx-menu", this.el);
    var ulDom = $("ul", this.el);
    var ucsInThisMenu = ["ppmpd", "droitsdel"];
    var visibleMenu = false;

    _.each(this.model.configuration.get("confignav").get("transverses"), function(uc, index) {
      if (_.contains(ucsInThisMenu, uc.id)) {
        if (uc.id) {
          uc.href = "";
          uc.target = "";
          var itemNiv1 = this.templateItemNiv1(uc);
          $(ulDom).append(itemNiv1);
          //Prepare keyboard navigation for last item added
          $(ulDom).find("li").last().keydown(function(e) {
            self._userMenuLinksKeyboardNav(e);
          });
          visibleMenu = true;
        }
      }
    }, this);
    $("div", menuDom).hide();

    //Prepare keyboard Navigation for user menu icon
    var self = this;
    this.$el.find(".phx-menu span.ui-icon").keydown(function(e) {
      self._userMenuIconKeyboardNav(e);
    });

    if (visibleMenu == false) {
      menuDom.hide();
    } else {
      menuDom.show();
    }

    return this;
  },

  _userMenuIconKeyboardNav: function(e) {
    if (e.keyCode == 13 || e.keyCode == 32 || e.keyCode == 40) { //enter, spacebar,  down arrow
      //Open-Close menu
      $(e.target).click();

      //Navigate to first submenu in zonemenuView
      var parentDiv = $(this.el).find(".phx-menu");
      if (parentDiv.is(":visible")) { //If menu has been opened navigate to first element
        parentDiv.find("ul li").first().focus();
      }
      e.preventDefault();
    }
  },

  _userMenuLinksKeyboardNav: function(e) {
    if (e.keyCode == 38) { // up
      var currentLi = $(e.target);
      if (currentLi.prevAll('li.phx-menu-link').first().length == 0) {
        var lastMenu = currentLi.nextAll('li.phx-menu-link').last();
        lastMenu.first().focus();
      } else {
        var prevMenu = currentLi.prevAll('li.phx-menu-link').first();
        prevMenu.first().focus();
      }
    } else if (e.keyCode == 40) { // down
      var currentLi = $(e.target);
      if (currentLi.nextAll('li.phx-menu-link').first().length == 0) {
        var firstMenu = currentLi.prevAll('li.phx-menu-link').last();
        firstMenu.first().focus();
      } else {
        var nextMenu = currentLi.nextAll('li.phx-menu-link').first();
        nextMenu.first().focus();
      }
    } else if (e.keyCode == 27) { // escape
      // Close menu
      var menuZone = $(this.el).find(".phx-menu");
      menuZone.find("span.ui-icon").click();

      //Focus parent element
      menuZone.find("span.ui-icon").focus();
    } else if (e.keyCode == 9) //tab
    {
      // If there is a submenu being show for any item in this zoneMenu, we hide it
      var menuZone = $(this.el).find(".phx-menu");
      var divmenuZone = $(this.el).find(".phx-menu div");

      //Is menu is shown close it
      if (divmenuZone.is(":visible"))
        menuZone.find("span.ui-icon").click();

      //var accountMenu=$(e.target).closest("#phx-account");
    } else if (e.keyCode == 13 || e.keyCode == 32) { //enter, spacebar,  down arrow
      //Open-Close menu
      $(e.target).find("a").click();
    }
  },

  _clickMenu: function(event) {
    var menu = $(event.target).parents("div.phx-menu");
    var index = menu.attr("data-value");
    LOG.debug("menu clicked: " + index);
    if (index != undefined) {
      if ($("div", menu).is(":visible")) {
        $("div", menu).hide();
      } else {

        $("div", menu).show();
        //If you click out of the menu, close the menu.
        var self = this;
        $(document).one("mousedown", function(event) {
          var element = $(self.el).find(event.target);
          if (element.length == 0) {
            $("div", menu).hide();
          }
        });
      }
    }
  },

  clickOption: function(event, nameEvent, options) {
    var name = nameEvent ? nameEvent : event.target.className.split(" ")[0];

    var self = this;
    switch (name) {
      case "ppmpd":
        if (self.chgPwdDialog === undefined) {
          self.chgPwdDialog = new DialogView({
            view: LoginChangePwdDialogView
          });
        }
        self.chgPwdDialog.setHeight(450);
        self.chgPwdDialog.setWidth(500);
        self.chgPwdDialog.open();
        break;
      case "droitsdel":
        // load dependencies to component
        if (!options) {
          options = {};
        }
        if (!options.view) {
          options.view = GeneralView;
        }
        self.droitsdelDialog = new DialogView(options);
        self.droitsdelDialog.setHeight(600);
        self.droitsdelDialog.setWidth(1210);
        self.droitsdelDialog.open();
        break;
      default:
        break;
    }

    return false;
  }
});