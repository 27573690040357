module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n<fieldset class="agenda_plage_tooltip">\n  <table>\n    <tr>\n      <td>\n        <label for="agenda_horaire"><b>'+
((__t=(i18n.t("agenda.plagHoraire")))==null?'':__t)+
'</b></label>\n        <span class="agenda_horaire"></span>\n      </td>\n      <td></td>\n    </tr>\n    <tr>\n      <td width="50%">\n        <label for="agenda_presence">'+
((__t=(i18n.t("agenda.plagPresence")))==null?'':__t)+
'</label>\n        <span class="agenda_presence"></span>\n      </td>\n      <td width="50%">\n        <label for="agenda_temps">'+
((__t=(i18n.t("agenda.plagTemps")))==null?'':__t)+
'</label>\n        <span class="agenda_temps"></span>\n      </td>\n    </tr>\n  </table>\n\n  <table>\n    <tr>\n      <td width="15%">\n        <label for="agenda_plageGraph">'+
((__t=(i18n.t("agenda.plagRepart")))==null?'':__t)+
'</label>\n      </td>\n      <td>\n        <span class="agenda_plageGraph"></span>\n      </td>\n    </tr>\n  </table>\n</fieldset>\n';
}
return __p;
};
