
import { ProfilesColl } from './profiles.collection.js';

/**
 * Profiles model
 */
export var ProfilesModel = Backbone.Model.extend({

  url: Configuration.restRoot + "/rest/utilisateur/connecte/profil/selecteur",

  parse: function(response) {
    response.mesprofils = new ProfilesColl(response.mesprofils, { parse: true });
    response.profilsrecus = new ProfilesColl(response.profilsrecus, { parse: true });
    return response;
  },

  _profilesCounter: null,
  profilesNumber: function() {
    if (this._profilesCounter === null && null != this.get("mesprofils") && null != this.get("profilsrecus")) {
      this._profilesCounter = this.get("mesprofils").profilesNumber() + this.get("profilsrecus").profilesNumber();
    }
    return this._profilesCounter;
  }
});