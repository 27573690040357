import { GLOBAL_DATA } from '../globalData.js';

/**
 * Utilities to check LICENCE
 */
export var LICENCE = {

  dummy: "",

  /**
   * Check if the module exists
   */
  hasModule: function(module) {
    try {
      var hasModule = false;
      var modules = GLOBAL_DATA.licences.get("modules");
      for (var i = 0, l = modules.length; i < l; i++) {
        if (modules[i] == module) {
          hasModule = true;
        }
      }
      return hasModule;
    } catch (err) {
      return false;
    }
  }

};