import { BaseModel } from '../../models/base.model.js';
import { STR } from '../../../utils/str.js';

/**
 * Model for Combos
 */
export var CombosModel = BaseModel.extend({
  parse: function(response) {
    if (!STR.isBlank(response.code)) {
      response.id = response.code;
    }
    return response;
  }
});