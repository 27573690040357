import { CustomBarView } from './customBar.view.js';
import { FormView } from 'core/views/form.view.js';
import { i18n } from '../../../../i18n.js';
import { SimplePanelView } from 'core/layouts/simplePanel.view.js';
import { SuvietatView } from './suvietat.view.js';
import { SuvietatWorkflow } from '../models/suvietat.workflow.js';

/**
 * It will paint all the tab for the UC.
 */
export var GeneralView = FormView.extend({
  className: 'suvietat',

  initialize: function(options) {
    if (options && options.parent) {
      this.parent = options.parent;
    }

    // Initializes the main parts of the UI and its workflow
    this.workflow = new SuvietatWorkflow();
    //		this.workflow.on("change:uri", function (wfl) {
    //			NAV.navigateToModule(wfl.get("uri"),false);
    //		}, this);

    this.layout = new SimplePanelView();
    this.table = this._initTable(); //table view
    this.workflow.tableModel = this.table.model;
    this.customBar = new CustomBarView({ workflow: this.workflow });
    this.workflow.customBarModel = this.customBar.model;
  },

  /**
   * Init screen
   */
  list: function() {
    this._initLayoutUC(function() {});
  },

  _initLayoutUC: function(callback) {
    if (this.workflow.get("ready") == true) {
      if (callback) {
        callback();
      }
    } else {
      var self = this;
      this.workflow.setUp(function() {
        //When the workflow is ready, we can render the view without concerns
        self.layout.$el.find(".phx-panelA").empty();
        self.layout.$el.find(".phx-panelA").append(self.customBar.render().el);
        self.layout.$el.find(".phx-panelA").append(self.table.render().el);

        if (callback) {
          callback();
        }
        // Function call to recalculate the size of the panels once the
        // burden of everything you need to show the use case
        self.trigger("uc:ready");
      });
    }
  },

  _initTable: function() {
    var tableView = new SuvietatView({ workflow: this.workflow, showFilteredRowsInTitle: true, parent: this.parent });
    return tableView;
  },

  render: function() {
    var self = this;
    this.$el.html(this.layout.render().el);
    // allow auto height of dialog
    this.parent._setTitle(i18n.t('suvietat.title_popup'));
    this._initLayoutUC(function() {
      if (self.parent) {
        self.parent.resize();
      }
    });
    return this;
  }
});