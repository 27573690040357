import { objs } from '../../objectsRepository.js';
import { STR } from '../../utils/str.js';
import TPL_appFrame_tabsheet from '../tabsheet.tpl.html';
import TPL_appFrame_tabsheet_item from '../tabsheet_item.tpl.html';

/**
 * Tabsheet View, usecase tabs loading and closing
 */
export var TabSheetViewFrame = Backbone.View.extend({

  // Stores the level of every uc in the tree. See _optionChange
  _ucStorage: undefined,
  _openTabs: undefined,

  initialize: function(options) {
    this.template = TPL_appFrame_tabsheet;
    this.templateItem = TPL_appFrame_tabsheet_item;
    this.zJSON = options.zJSON;

    //Calculate and store every uc in the zone
    this._ucStorage = {};
    this._openTabs = [""];
    _.each(this.zJSON.menus, function(menu) {
      if (menu.id) {
        this._ucStorage[menu.id] = menu; // eslint-disable-line no-invalid-this
      }
      _.each(menu.ecrans, function(uc) {
        if (uc.id) {
          this._ucStorage[uc.id] = uc; // eslint-disable-line no-invalid-this
        }
        _.each(uc.ecrans, function(uc2) {
          this._ucStorage[uc2.id] = uc2; // eslint-disable-line no-invalid-this
        }, this); // eslint-disable-line no-invalid-this

      }, this); // eslint-disable-line no-invalid-this
    }, this);
    this.model.on("change:usecase", this._usecaseChanged, this);
    this.model.on("destroy:usecase", this._usecaseDestroyed, this);
    // Home view
    this.home = null;
  },

  _usecaseChanged: function() {
    // Be careful, all the TabSheets are hearing the same model
    if (this.zJSON["id"] != this.model.get("zone")) {
      return;
    }
    var ucid = this.model.get("usecase");

    if (_.indexOf(this._openTabs, ucid) == -1) {
      this._createTab(this._ucStorage[ucid]);
    }
    var tabNumber = _.indexOf(this._openTabs, ucid);
    this.tabSelector.tabs("option", "active", tabNumber);

    this.model.trigger("changed:usecase", ucid);
  },

  _createTab: function(uc) {
    var tempUc = uc;
    if (STR.isBlank(uc.typ)) {
      tempUc.typ = "uc";
    }
    this.tabSelector.append($("<div id=\"" + uc.id + "\" class='phx-proto-resizable '></div>"));
    this._openTabs.push(uc.id);
    if (objs[uc.id + "Rt"]) {
      $("#" + uc.id).append(objs[uc.id + "Rt"].layout.el);
      if (objs[uc.id + "Rt"]._applyResizes) {
        objs[uc.id + "Rt"]._applyResizes();
      }
    }
    //Notify tab addition
    this.model.trigger("add:tab", this.zJSON.id);
  },

  render: function() {
    this.$el.html(this.template(this.zJSON));
    // Create TabSheet
    var self = this;
    this.tabSelector = $("#phx-tabs-" + this.zJSON["id"], this.el);
    this.tabSelector.tabs({
      activate: function(event, ui) {
        self._selectTab(event, ui);
      },
      beforeLoad: function(event, ui) {
        ui.jqXHR.omit = true;
        event.preventDefault();
      }
    });
    return this;
  }
});