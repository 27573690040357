import { BaseModel } from 'core/models/base.model.js';

import { GLOBAL_DATA } from '../../../../globalData.js';
import { i18n } from '../../../../i18n.js';
import { STR } from '../../../../utils/str.js';
import { SYNC } from '../../../../utils/sync.js';
import { TYPE } from '../../../../tda/tda.js';

/**
 * Model for load the data the Delegations
 */
export var DelegationsModel = BaseModel.extend({
  /**
   * Code of the Utilisateur
   */

  usecase: "droitsdel",

  initialize: function() {
    this.groupedErrors = {
      datedeb: ["datedeb", "datefin"],
      datefin: ["datedeb", "datefin"]
    };
  },

  /**
   * Builds the url for the REST call
   */
  url: function() {
    var base = Configuration.restRoot + "/rest/delegation/" + encodeURIComponent(this.utilCode) + "/element";
    if (this.isNew()) { return base; }
    return base + (base.charAt(base.length - 1) === '/' ? '' : '/') + encodeURIComponent(this.id);
  },

  /**
   * Default data model
   *		id : null,
   *		code : "",
   *		datedeb : "",
   *		datefin : "",
   *		retro : true,
   *		commentaire : "",
   *		delegue : null,
   *		profils : []
   * }
   */
  defaults: {
    "id": null,
    "code": "",
    "datedeb": "",
    "datefin": "",
    "retro": true,
    "commentaire": "",
    "delegue": null,
    "profils": []
  },

  /**
   * Validate the model before launch the REST
   */
  validate: function(attrs, options) {
    var errors = {};

    //Period validation
    var datedeb = {
      "name": "datedeb",
      "value": attrs.datedeb,
      "label": i18n.t('droitsdel.datedeb')
    };
    var datefin = {
      "name": "datefin",
      "value": attrs.datefin,
      "label": i18n.t('droitsdel.datefin'),
      "isInfinity": true
    };
    var errorMsg = TYPE.DATE.validatePeriod(datedeb, datefin, options);
    if (!STR.isBlank(errorMsg)) {
      errors.datedeb = errorMsg.datedeb;
      errors.datefin = errorMsg.datefin;
    }

    var pDiversModifDeleg = GLOBAL_DATA.paramDivers.get("modifDeleg");
    if (!STR.isBlank(pDiversModifDeleg) && pDiversModifDeleg.get("valeur") == 1) {
      if (STR.isBlank(errors.datefin) && !STR.isBlank(attrs.datefin)) {
        var today = TYPE.DATE.parse(TYPE.DATE.dateToStr(SYNC.getServerDate())).val;
        if (today >= attrs.datefin) {
          errors.datefin = i18n.t('messages:GT_2109');
        }
      }
    }

    var pDiversSPEDelegfi = GLOBAL_DATA.paramDivers.get("SPEDelegFi"),
      pDiversSPEValideu = GLOBAL_DATA.paramDivers.get("SPEValideu");

    if (!STR.isBlank(pDiversSPEValideu) && pDiversSPEValideu.get("valeur") === "1") {
      if (STR.isBlank(errors.datefin) && !STR.isBlank(attrs.datefin) && !STR.isBlank(pDiversSPEDelegfi)) {
        if (parseInt(pDiversSPEDelegfi.get("valeur")) < TYPE.DATE.getDiffInMonth(SYNC.getServerDate(), datefin.value)) {
          errors.datefin = i18n.t('messages:SPE_2');
        }
      }
    }

    if (STR.isBlank(attrs.delegue) || (STR.isBlank(attrs.delegue.matricule) && STR.isBlank(attrs.delegue.utilnom))) {
      errors.delegue = {};
      errors.delegue.matricule = i18n.t('common:required', { "0": i18n.t('droitsdel.delegues') });
    }

    if (!_.isEmpty(errors)) {
      var errorMap = {
        errorValidation: STR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
    return null;
  },

  /**
   * Parsed the results returned by the REST,
   * Copy the return code in the object id
   */
  parse: function(response) {
    if (response) {
      response.id = response.code;
    }
    return response;
  }

});
