import { BaseModel } from '../../../../../../core/models/base.model.js';


/**
 * Model to obtain EvtRecapActSoumisWkf - BADGEAGE.
 */
export var EvtBadgeageSoumisWkf = BaseModel.extend({
  /**
   * Add this to have Outline
   *
   */
  urlRoot: Configuration.restRoot + "/rest/assocircuitevt/type?code=BADGEAGE"

});