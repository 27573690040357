import { BaseModel } from '../../../../../../core/models/base.model.js';
import { BlockView } from '../../../../piecesjointes/views/block.view.js';
import { BlockView as WkfhistoBlockView } from '../../../../wkfhisto/views/block.view.js';
import { ButtonBarView } from '../../../../../../core/controls/buttonBar.view.js';
import { ComboBoxView2 } from '../../../../../../core/components/combo/comboBoxView2.js';
import { CombosColl } from '../../../../../../core/components/combo/combos.collection.js';
import { ComplementComboColl } from '../models/complementCombo.collection.js';
import { GLOBAL_DATA } from '../../../../../../globalData.js';
import { FORMS } from '../../../../../../utils/forms.js';
import { FormView } from '../../../../../../core/views/form.view.js';
import { GererPretsGestion } from '../models/gererPretsGestion.js';
import { GererPretsModel } from '../models/gererPrets.model.js';
import { HABILITATION } from '../../../../../../utils/habilitation.js';
import { HabilitationContext } from '../../../../../../core/models/habilitationContext.js';
import { i18n } from '../../../../../../i18n.js';
import { InitRattEmpruntCollab } from '../models/initRattEmpruntCollab.js';
import { LOG } from '../../../../../../utils/log.js';
import { ModeSaisieOptionsColl } from '../models/modeSaisieOptions.collection.js';
import { MSGS } from '../../../../../../utils/msgs.js';
import { MultiLabel } from '../../../../../../core/views/multiLabel.js';
import { objs } from '../../../../../../objectsRepository.js';
import { SelecteurCheminItemModel } from '../../../../../../core/components/selecteur_chemin/selecteurCheminItem.model.js';
import { SelecteurCheminView } from '../../../../../../core/components/selecteur_chemin/selecteurChemin.view.js';
import { SIMULATION } from '../../../../../../utils/simulation.js';
import { STR } from '../../../../../../utils/str.js';
import { TYPE } from '../../../../../../tda/tda.js';
import { TypeAffDefaults } from '../models/typeAffDefaults.js';
import { TypeSaisieOptionsColl } from '../models/typeSaisieOptions.collection.js';
import { TypeStructuresColl } from '../models/typeStructures.collection.js';
import { GererpretsWorkflow } from '../models/gererprets.workflow.js';
import TPL_gererprets_gererPrets from '../gererPrets.tpl.html';
import TPL_gererprets_multiLabel_modeSaisie from '../multiLabel-modeSaisie.tpl.html';
import TPL_gererprets_multiLabel_typeSaisie from '../multiLabel-typeSaisie.tpl.html';

/**
 * Render gerer pret view
 */
export var GererPretView = FormView.extend({

  initialize: function(options) {
    this.isContextInitialized = false;
    this.id = null;
    //		this.listeMotifs = new ListeMotifColl();
    this.disabled = false;

    this.model = new BaseModel({
      value: new GererPretsModel(),
      mode: "C"
    });
    this.hasBeenFocused = false;
    var self = this;
    if (options && options.context) {
      this.context = options.context;

      this.model.setHabContext(new HabilitationContext({
        onglet: this.context.ctxEcran,
        fonc_cour: this.context.ctxHabilitation.HabilitationAcces
      }));
    }

    this.workflow = new GererpretsWorkflow();
    this.btnBar = new ButtonBarView({ id: "pretcollab_btnbar" });
    this.workflow.btnBarModel = this.btnBar.model;
    this.workflow.gererPrets = this;
    this.workflow.formModel = this.model;
    this.workflow.setUp();
    this.diver = this.workflow.pDiversIdCollabModel.get("valeur");

    this.affTypeSaisieOptionsColl = new TypeSaisieOptionsColl();
    this.affModeSaisieOptionsColl = new ModeSaisieOptionsColl();
    this.pretDefaults = null;
    this.profilModel = null;

    this._initChangeFlags();
    //List of selecteur de chemin views
    this.listeCheminViews = [];

    this.typeStructures = null;

    if (options && options.parent) {
      this.parent = options.parent;
    }

    this.template = TPL_gererprets_gererPrets;

    // When the value is changed, we have to map the new value to the form
    this.model.on("change:value", this._mapToForm, this);
    this.model.on("change:mode", this._manageMode, this);

    this._pretParPeriode = true;

    this.hasBeenFocused = false;

    this._initCombos();
    this.typeFormatByClass = {};
  },

  // ::::::: EVO - 458 ::::::::
  // call the init of "rattachements" for the collab "emprunté",
  // when the component is open (creation/modication) and the datedebut or the perimetre exist or changes.
  _initRattEmpruntCollab: function() {

    var collabremplace = null;
    var datedeb = null;
    var perType = null;

    // ::::::::::: COLLAB remplace :::::::::::

    if (this.model.get("value") && this.model.get("value").get("collabremplace") &&
      this.model.get("value").get("collabremplace").matric) {

      collabremplace = this.model.get("value").get("collabremplace").matric;

    } else {
      // création à partir du remplacement d'un collaborateur (emprunt),
      // le collaborateur remplacé est celui que l'on a sélectionné au départ
      if (this.context.ctxCollabReference && this.context.ctxCollabReference.matricule) {
        collabremplace = this.context.ctxCollabReference.matricule;
      }
    }

    // :::::::: DATE DEBUT ::::::::::

    datedeb = $(".gererprets-detail .datedeb").val();
    // we are in modification
    if (!STR.isBlank(datedeb)) {
      var myDateArray = datedeb.split("/");
      datedeb = myDateArray[2] + myDateArray[1] + myDateArray[0];
    }

    // we are in creation - consultation
    if (STR.isBlank(datedeb)) {
      if (this.model.get("value") && this.model.get("value").get("datedeb")) {
        datedeb = this.model.get("value").get("datedeb");
      } else if (this.context.ctxValeursCreation) {
        datedeb = this.context.ctxValeursCreation.PretDateDebut;
      }
    }

    // ::::::: PERIODE ::::::::::

    // in consultation - or creation

    if (this.model.get("value") && this.model.get("value").get("perimetre") && this.model.get("value").get("perimetre").code) {
      perType = this.model.get("value").get("perimetre").code;
    } else {
      perType = this.context.ctxPerimetre;
    }

    // Call to the new WS to initialise 'the rattachements aux structures'
    // Dans le collab emprunté
    // InitialiserRattachCollabEmprunte

    var initRattEmpCollab = new InitRattEmpruntCollab({});
    initRattEmpCollab.set("collabremplace", collabremplace);
    initRattEmpCollab.set("datedeb", datedeb);

    // set context and permissions in the new Webservice

    if (perType == "N") { // if perimetre type Normal
      var habContext = new HabilitationContext({
        onglet: "gererprets",
        fonc_cour: {
          "collaborateur": "RES_GCOLLAB.G",
          "emprunt": "RES_EMPR_N.G"
        }
      });
    }

    if (perType == "E") { // if perimetre type Entendu
      var habContext = new HabilitationContext({
        onglet: "gererprets",
        fonc_cour: {
          "collaborateur": "RES_GCOLLAB.G",
          "emprunt": "RES_EMPR_E.G"
        }
      });
    }

    initRattEmpCollab.setHabContext(habContext);

    var self = this;

    // if we got the collabRemplace and date - we call the WS 'InitialiserRattachCollabEmprunte.'
    if (!STR.isBlank(collabremplace) && !STR.isBlank(datedeb)) {

      initRattEmpCollab.save(null, {
        success: function(fresh) {
          LOG.debug("calling WS initRattEmpCollab");
          // : this takes all informations about "rattachements"
          // as Webservice "readPretCollab"  - StructureLibelle1 à n et PretElementStructure1 à n

          var arrayStructures = [];
          var cont = 0;

          while (fresh.attributes[cont]) {
            arrayStructures[cont] = fresh.attributes[cont];
            cont++;
          }

          self.model.get("value").set("structures", arrayStructures);
          self.model.trigger("change:value");

        },
        error: function() {
          console.error("Error calling WS initRattEmpCollab");
        }
      });
    } else { // if not we update the rest of the data.
      this.model.trigger("change:value");
    }
  },
  _initChangeFlags: function() {
    this.saisieChanged = null;
    this.motifChanged = null;
    this.origineChanged = null;
  },
  _getHabilitationGestion: function(model) {
    var perimetre = STR.isBlank(STR.getElValue(model, "perimetre.code")) ? this.context.ctxPerimetre : STR.getElValue(model, "perimetre.code");
    var habilitationGestion = perimetre == "N" ? this.context.ctxHabilitation.HabilitationGestionNormal : this.context.ctxHabilitation.HabilitationGestionEtendu;
    return habilitationGestion;
  },

  _getPerimetre: function(model) {
    var perimetre = STR.isBlank(STR.getElValue(model, "perimetre.code")) ? this.context.ctxPerimetre : STR.getElValue(model, "perimetre.code");
    return perimetre;
  },

  _initCombos: function() {
    this.typeComboColl = new CombosColl({
      name: "ls/typeaffectation?nature=P"

    });
    this.typeComboColl.setHabContext(this.model.getHabContext());
    this.comboType = new ComboBoxView2({
      ws: this.typeComboColl,
      habContext: this.model.getHabContext(),
      autocomplete: true,
      name: "type.code",
      width: "260px",
      optionsRender: function(item) {
        return (!item || STR.isBlank(item.code)) ? "" : item.libelle + " (" + item.code + ")";
      }
    });

    this.perimetreComboColl = new CombosColl({
      name: "ls/divcod/perimetrespret"

    });
    this.perimetreComboColl.setHabContext(this.model.getHabContext());
    this.comboPerimetre = new ComboBoxView2({
      ws: this.perimetreComboColl,
      habContext: this.model.getHabContext(),
      required: true,
      autocomplete: true,
      name: "perimetre.code",
      width: "260px",
      optionsRender: function(item) {
        return (!item || STR.isBlank(item.code)) ? "" : item.libelle + " (" + item.code + ")";
      }
    });

    this.motifComboColl = new CombosColl({
      name: "ls/motifpret"

    });
    this.motifComboColl.setHabContext(this.model.getHabContext());
    this.comboMotif = new ComboBoxView2({
      ws: this.motifComboColl,
      habContext: this.model.getHabContext(),
      autocomplete: true,
      name: "motif.code",
      width: "160px",
      optionsRender: function(item) {
        return (!item || STR.isBlank(item.code)) ? "" : item.libelle + " (" + item.code + ")";
      }
    });

    this.origineContext = (this.context.ctxUtilisateur === "Responsable") ? "respo" : "collab";
    this.comboOrigine = new ComboBoxView2({
      ws: new CombosColl({
        urlMine: Configuration.restRoot + "/rest/ls/origine/" + this.origineContext
      }),
      autocomplete: true,
      required: false,
      name: "origine.code",
      width: "160px",
      habContext: this.model.getHabContext(),
      optionsRender: function(item) {
        return (!item || STR.isBlank(item.code)) ? "" : item.libelle;
      }

    });

    this.uniteDebComboColl = new CombosColl({
      name: "utilevenement/modesaisie/periodedebut/affectation"

    });
    this.uniteDebComboColl.setHabContext(this.model.getHabContext());
    this.comboUniteDebut = new ComboBoxView2({
      ws: this.uniteDebComboColl,
      externalCache: this.cache,
      syncExternalCache: false,
      name: "modesai_periodeb.code",
      required: true,
      width: "100px",
      habContext: this.model.getHabContext()
    });
    this.uniteFinComboColl = new CombosColl({
      name: "utilevenement/modesaisie/periodefin/affectation"

    });
    this.uniteFinComboColl.setHabContext(this.model.getHabContext());
    this.comboUniteFin = new ComboBoxView2({
      ws: this.uniteFinComboColl,
      externalCache: this.cache,
      syncExternalCache: false,
      name: "modesai_periofin.code",
      required: true,
      width: "100px",
      habContext: this.model.getHabContext()
    });

    this.unitesComboColl = new CombosColl({
      name: "utilevenement/unite/ls"

    });
    this.unitesComboColl.setHabContext(this.model.getHabContext());
    this.comboUnites = new ComboBoxView2({
      ws: this.unitesComboColl,
      name: "unitedeb.code",
      required: true,
      width: 120,
      externalCache: this.cache,
      syncExternalCache: false,
      habContext: this.model.getHabContext()
    });

    var self = this;

    this.listenTo(this.comboType, "comboEdited", this._formEdited);
    this.listenTo(this.comboPerimetre, "comboEdited", this._formEdited);
    this.listenTo(this.comboMotif, "comboEdited", this._formEdited);
    this.listenTo(this.comboOrigine, "comboEdited", this._formEdited);
    this.listenTo(this.comboUniteDebut, "comboEdited", this._formEdited);
    this.listenTo(this.comboUniteFin, "comboEdited", this._formEdited);
    this.listenTo(this.comboUnites, "comboEdited", this._formEdited);
  },
  /**
   * Initialize collections of authorized type saisies and modesaisies for a type
   * If collections are empty, all type and modes are allowed.
   */
  _initializeModeAndTypeColl: function() {
    //When no activity is selected affTypeSaisieOptionsColl adn affModeSaisieOptionsColl are initialized
    this.affTypeSaisieOptionsColl = new TypeSaisieOptionsColl();
    this.affTypeSaisieOptionsColl.setHabContext(new HabilitationContext({
      onglet: this.context.ctxEcran,
      fonc_cour: this.context.ctxHabilitation.HabilitationAcces
    }));
    this.affModeSaisieOptionsColl = new ModeSaisieOptionsColl();
    this.affModeSaisieOptionsColl.setHabContext(new HabilitationContext({
      onglet: this.context.ctxEcran,
      fonc_cour: this.context.ctxHabilitation.HabilitationAcces
    }));

  },
  _panelStateIsReadonly: function() {
    var model = this._getModel();
    var isReadOnly = false;

    var habilitationGestion = this._getHabilitationGestion(this.model.get("value"));
    if (this.context && this.context.ctxHabilitation && habilitationGestion) {
      var canUpdate = HABILITATION.canUpdate(habilitationGestion);
      isReadOnly = !canUpdate && !model.isNew();
    }
    if (isReadOnly)
      this.disabled = true;
    return isReadOnly;
  },

  _formEdited: function() {
    this.model.trigger("form:edited");
  },

  events: _.extend({
    "change .gererprets-detail :input": "_changeView",
    "change .gererprets-detail .perimetre\\.code": "_initRattEmpruntCollab",
    "change .gererprets-detail .datedeb": "_initRattEmpruntCollab",
    "keyup :input:not([readonly])": "_notifyEdition",
    "click li.phx-multiLabel-option a": "_changeTypeSaisie",
    "keyup textarea": "_notifyEdition",
    "click .menuItem": "menuItemListener",
    "focus .gererprets-detail .gererpret-form-inputs :input:not([readonly])": "_isModifiable",
    "click .valider": "valider"
  }, TYPE.DATE.events(), TYPE.CUSTOM.events(), TYPE.HOUR_MINUTE_NIGHTLY.events()),

  /**
   * Apply the context to the Component.
   *
   * ctxUtilisateur			> "Responsable" | "Collaborateur"
   * ctxHabilitation			HabilitationAcces 		COL_PRET.V, RES_PRET.V
   * 							HabilitationGestion
   *                                    perimetreNormal	 RES_PRET_N.G,RES_EMPR_N.G
   *                                    perimetreEtendu   RES_PRET_E.G,RES_EMPR_E.G
   * 							HabilitationValidation	RES_VAL_PRET.G
   * ctxEcran					> Identifiant de l’écran.
   * ctxModeRepresentation	> "Imbrique" | "pop-up"
   * ctxCollab			> Information du collaborateur concerné 
   *         matricule
   *         matric_aux
   *         nom
   *         prenom
   * ctxListeCollab           > Liste des collaborateurs concernés par le prêt
   * ctxCollabReference		< Information du collaborateur remplacé
   * 		   matricule
   * 		   matric_aux
   *         nom
   *  	   prenom
   *    	   datedeb
   *    	   datefin
   * ctxPerimetre             >Indique le périmètre du prêt   Normal|Etendu
   * ctxPerimetreFige         >  Oui |Non
   * ctxTypeEvtGere           >  P (Prêt) | E (Emprunt)
   * ctxActionsPossibles		> ["Creer", "Supprimer", "Modifier", "Valider"
   * ctxModeInitialisation	> "Consultation" | "Ajout"]
   * ctxIdentifiantEvt		> En cas de restitution d’un événement existant, identifiant de celui-ci.
   * ctxValeursEvenement      >
   * PretCodeStatutWKF =
   *	PretEnSuppression = OUI / NON
   *	PretType =
   *	PretPerimetre =
   *	PretListeElementStructure=
   *	PretTypeSaisie =
   *	PretModeSaisie =
   *	PretDateDebut =
   *	PretUniteDebut =
   *	PretDateFin =
   *	PretUniteFin =
   *	PretHeureDebut =
   *	PretHeureFin =
   *	PretDuree =
   *	PretPourcentage=
   *	PretMotif=
   *	PretOrigine=
   *	PretLibelle=
   *	PretCommentaire=
   *	PretListeInfoComplValeur=
   *	PretCommentaireWKF =
   * ctxValeursCréation 
   *      PretType =
   *		PretPerimetre =
   *		PretEstUnEmprunt =  vrai si ctxTypeEvtGere = Emprunt sinon faux
   *		PretResponsable = identifiant de l’utilisateur
   *		PretDateDebut = donnée obligatoire
   *		PretUniteDebut =
   *		PretDateFin = donnée obligatoire
   *		PretUniteFin =
   *		PretHeureDebut =
   *		PretHeureFin =
   *		PretCommentaire=
   */
  setContext: function(context) {
    var self = this;
    this.isContextInitialized = true;
    if (STR.isBlank(this.model.getHabContext())) {
      this.model.updateHabContext({
        onglet: context.ctxEcran,
        fonc_cour: this.context.ctxHabilitation.HabilitationAcces,
        nat_gest: ""
      });
    }

    this.context = context;
    //		this._closeTooltipListener();
    //obtain masxpieces
    this.workflow.maxPieces.fetch({
      success: function() {

        self._manageModeRepresentation();
        self._manageModeInitialisation(function() {
          if (self.context.ctxModeInitialisation == "") {
            self._disableForm(true);
          }
          self.hasBeenFocused = false;
          self.btnBar.hideButton("copy");
        });
      }
    });

    //Check for périmètre habilitations
    this.comboPerimetre.filterPerimetres = [];
    if (!HABILITATION.canView(this.context.ctxHabilitation.HabilitationGestionNormal)) {
      this.comboPerimetre.filterPerimetres.push("N");
    }
    if (!HABILITATION.canView(this.context.ctxHabilitation.HabilitationGestionEtendu)) {
      this.comboPerimetre.filterPerimetres.push("E");
    }
    if (this.comboPerimetre.filterPerimetres.length > 0) {
      this.comboPerimetre.setFilter(this._filterCombo);
      if (_.indexOf(this.comboPerimetre.filterPerimetres, this.context.ctxValeursCreation.PretPerimetre) != -1) {
        this.context.ctxValeursCreation.PretPerimetre = "";
      }
      if (_.indexOf(this.comboPerimetre.filterPerimetres, this.context.ctxPerimetre) != -1) {
        this.context.ctxPerimetre = "";
      }
    }

    if (STR.isBlank(this.context.ctxDonneeSimulee)) {
      this.context.ctxDonneeSimulee = false;
    }

    if (this.context.ctxDonneeSimulee) {
      this.model.get("value").activeSimulation = true;
    }

    if (this.context.ctxEcran === "planresp" && this.context.ctxSimulation) {
      if (STR.isBlank(this.context.ctxEvtEstSimule)) {
        this.context.ctxEvtEstSimule = SIMULATION.getSimulationState(4);
      }

      var divcod = new CombosColl({
        name: "utilitaire/divcod",
        comboId: "STEVS",
      });
      divcod.setHabContext(this.model.getHabContext());
      divcod.fetch({
        success: function(data) {
          if (data.models.length > 0) {
            for (var i = 0; i < data.models.length; i++) {
              if (data.models[i] && data.models[i].get("code") == self.context.ctxEvtEstSimule.valeur) {
                self.context.ctxEvtEstSimule.libelle = data.models[i].get("libelle");
                break;
              }
            }
          }
          self._paintSimulateModeHeader();
        },
      });
    }
  },

  _filterCombo: function(options) {
    var self = this;
    var result = [];
    result = _.filter(options, function(obj) {
      if (_.indexOf(self.filterPerimetres, obj.attrs.code) == -1)
        return true;
      else
        return false;
    });
    return result;
  },

  _manageModeRepresentation: function() {
    if (this.context && this.context.ctxModeRepresentation && this.context.ctxModeRepresentation == "pop-up") { // Pop up
      this.$el.find(".gererpret-content").append(this.btnBar.render().el);
      this.$el.find("#pretcollab_btnbar").css("float", "left");
      this._setTitlePopUp();
    } else {
      this.$el.find(".gererprets-utilisateur").hide();
      if (this.context && this.context.ctxEcran !== "saisiescoll") {
        this.$el.find(".gererpret-content").prepend(this.btnBar.render().el);
      }
    }
  },
  _manageHeader: function() {
    if (this.context && this.context.ctxModeRepresentation && this.context.ctxModeRepresentation == "pop-up") { // Pop up
      this._setHeaderPopUp();
    } else {
      this._setHeaderImbrique();
    }
  },

  _configBtnBar: function() {
    if (HABILITATION.canView(this.context.ctxHabilitation.HabilitationValidation)) {
      this.btnBar.addButton("valider", i18n.t('common:gererprets.btnValider'));
    }
  },
  /**
   * Generate different profil options in validate button
   */
  _configureValidationButton: function() {
    var model = this.model.get("value");
    var self = this;
    if (!STR.isBlank(this.profilModel)) {
      var profils = STR.getElValue(this.profilModel, "profils");

      var ul = $(".gererprets-detail", this.el).find("ul.validerListMenu");
      ul.empty();
      if (!STR.isBlank(profils) && profils.length > 0) {
        if (profils.length === 1 && profils[0] && ul) {
          var accepter = $("<li>");
          var refuser = $("<li>");
          var a = $("<a class='menuItem' data-value='2' >" + i18n.t('common:gererprets.btnAccepter') + "</a>");
          a.attr("data-code", profils[0].code);
          accepter.append(a);
          ul.append(accepter);

          a = $("<a class='menuItem' data-value='3' >" + i18n.t('common:gererprets.btnRefuser') + "</a>");
          a.attr("data-code", profils[0].code);
          refuser.append(a);
          ul.append(refuser);
        }
        if (profils.length > 1 && ul) {
          var accepter = $("<li>");
          var refuser = $("<li>");
          accepter.append("<span class='ui-corner-all'>" + i18n.t('common:gererprets.btnAccepter') + "</span>");
          ul.append(accepter);
          _.each(profils, function(obj) {
            var item = $("<li>");
            var a = $("<a class='menuItem' data-value='2' >" + "- " + obj.libelle + "</a>");
            a.attr("data-code", obj.code);
            item.append(a);
            ul.append(item);
          }, this);

          refuser.append("<span class='ui-corner-all'>" + i18n.t('common:gererprets.btnRefuser') + "</span>");
          ul.append(refuser);
          _.each(profils, function(obj) {
            var item = $("<li>");
            var a = $("<a class='menuItem' data-value='3'>" + "- " + obj.libelle + "</a>");
            a.attr("data-code", obj.code);
            item.append(a);
            ul.append(item);
          }, this);
        }
      } else {
        this.btnBar.model.trigger("hide:valider");
      }

      $(".gererprets-detail", this.el).find("button.valider")
        .button({ icons: { secondary: "ui-icon-triangle-1-s" }, text: true })
        .click(function() {
          var menu = $(".gererprets-detail", self.el).find("ul.validerListMenu").show().position({
            my: "left top",
            at: "left bottom",
            of: this
          });
          $(document).one("click", function() {
            menu.hide();
          });
          return false;
        });

      $(".gererprets-detail", this.el).find("ul.validerListMenu").hide().menu();
    }

  },

  menuItemListener: function(event) {
    var action = event.currentTarget.attributes["data-value"].nodeValue;
    var code = event.currentTarget.attributes["data-code"].nodeValue;
    switch (action) {
      case "2":
        this.btnBar.model.trigger("btn:click", "accepter", code);
        break;
      case "3":
        this.btnBar.model.trigger("btn:click", "refuser", code);
        break;
    }
  },

  _setTitlePopUp: function() {
    var title = "";
    if (this.context.ctxTypeEvtGere == "P") {
      title += i18n.t('common:gererprets.title_pret_de_collaborateur');
      if (this.context.ctxActionsPossibles && this.context.ctxActionsPossibles.length == 1 && _.contains(this.context.ctxActionsPossibles, "Creer")) {
        title = i18n.t('common:gererprets.nouvelle_pret_title') + " " + i18n.t('common:gererprets.title_sur_perimetre');
        var perim = this._getPerimetre(this.model.get("value"));
        var perimetre = perim == "N" ? i18n.t('common:gererprets.normal') : i18n.t('common:gererprets.etendu');
        title += " " + perimetre;
      }
    } else if (this.context.ctxTypeEvtGere == "E") {
      title += i18n.t('common:gererprets.title_emprunt_de_collaborateur');
      if (this.context.ctxActionsPossibles && this.context.ctxActionsPossibles.length == 1 && _.contains(this.context.ctxActionsPossibles, "Creer")) {
        title = i18n.t('common:gererprets.nouvel_emprunt_title') + " " + i18n.t('common:gererprets.title_sur_perimetre');
        var perim = this._getPerimetre(this.model.get("value"));
        var perimetre = perim == "N" ? i18n.t('common:gererprets.normal') : i18n.t('common:gererprets.etendu');
        title += " " + perimetre;
      }
    }

    this.parent._setTitle(title);
  },

  _setHeaderPopUp: function() {
    var gererPret = this.model.get("value");
    if (gererPret) {
      this.$el.find(".imbrique-header").hide();
    }
    //COLLABORATEUR
    this._manageUtilisateur();

    //STATUT
    var statut = "";
    if (this.context.ctxModeInitialisation != "Ajout" && !STR.isBlank(gererPret.get("statut")) && !STR.isBlank(gererPret.get("statut").code)) { //Statut
      this._paintWorkflowStatus();
    }
    //DESCRIPTION
    this._manageDescription();

  },

  _setHeaderImbrique: function() {
    var gererPret = this.model.get("value");
    this.$el.find(".popup-header").hide();
    //PERIODE
    if (this.context.ctxModeInitialisation == "Ajout") {
      if (this.context.ctxTypeEvtGere == "P") //Pret
        this.$el.find(".periode-header").html(i18n.t('common:gererprets.nouvelle_pret_title'));
      else //Emprunt
        this.$el.find(".periode-header").html(i18n.t('common:gererprets.nouvel_emprunt_title'));
    } else {
      var periode = i18n.t('common:gererprets.header_du') + " " + TYPE.DATE.format(gererPret.get("datedeb"));
      if (!STR.isBlank(gererPret.get("datefin")) && gererPret.get("datedeb") != gererPret.get("datefin"))
        periode += " " + i18n.t('common:gererprets.header_au') + " " + TYPE.DATE.format(gererPret.get("datefin"));
      this.$el.find(".periode-header").html(periode);
    }

    //PERIMETRE
    var perimetre = "";
    if (this.context.ctxTypeEvtGere == "P") { //Pret
      perimetre = i18n.t('common:gererprets.header_pret_sur_perimetre');
      var perim = this._getPerimetre(this.model.get("value"));
      if (perim == "N") //Normal
        perimetre = " " + i18n.t('common:gererprets.normal');
      else
        perimetre = " " + i18n.t('common:gererprets.etendu');
    }

    //STATUT
    var statut = "";
    if (this.context.ctxModeInitialisation != "Ajout" && !STR.isBlank(gererPret.get("statut")) && !STR.isBlank(gererPret.get("statut").code)) { //Statut
      this._paintWorkflowStatus();
    }

    //DESCRIPTION
    this._manageDescription();

  },
  _manageUtilisateur: function() {
    if (this.context.ctxUtilisateur == "Responsable" && !STR.isBlank(this.context.ctxCollab) && this.context.ctxModeRepresentation == "pop-up") {
      this.$el.find(".gererprets-utilisateur").show();
      // information du collaborateur
      var collabText = this.context.ctxCollab.nom + " " + this.context.ctxCollab.prenom;
      if (STR.isBlank(this.diver) || this.diver === "matric") {
        collabText += " (" + this.context.ctxCollab.matricule + ")";
      } else {
        collabText += " (" + this.context.ctxCollab.matric_aux + ")";
      }
      this.$el.find(".gererprets-utilisateur").html(collabText).addClass("ctxGestionCollab");
    }
  },

  _manageDescription: function() {
    if (this.context.ctxTypeEvtGere == "P" || this.context.ctxUtilisateur == "Collaborateur") {
      this.$el.find(".description-header").html(i18n.t('common:gererprets.header_descripton_du_pret'));
    } else {
      this.$el.find(".description-header").html(i18n.t('common:gererprets.header_descripton_du_emprunt'));
    }
    if (this.context.ctxValeursEvenement && !STR.isBlank(this.context.ctxValeursEvenement.PretEnSuppression) && this.context.ctxValeursEvenement.PretEnSuppression == true) {
      var statutLibelle = "<span class='ui-phx-titre-demande-suppression' >" + i18n.t('common:gererprets.header_info_lies_suppresion') + "</span>";
      this.$el.find(".description-header").html(statutLibelle);
    }
  },

  _manageModeInitialisation: function(originalCallback) {
    var self = this;
    var initMode = this.context.ctxModeInitialisation;
    var pret = new GererPretsModel();

    if (this.pretDefaults == null) {
      this.pretDefaults = new TypeAffDefaults();
      this.pretDefaults.setHabContext(new HabilitationContext({
        onglet: this.context.ctxEcran,
        fonc_cour: this.context.ctxHabilitation.HabilitationAcces
      }));
    }
    this._initializeModeAndTypeColl();

    switch (initMode) {
      case "Consultation":
        this.model.updateHabContext({ fonc_cour: this.context.ctxHabilitation.HabilitationAcces, nat_gest: "" });
        pret.setHabContext(this.model.getHabContext());

        this._enableForm();
        this.btnBar.model.trigger("enable:delete");
        //Responsable and valider --> show
        if (this.context.ctxActionsPossibles.indexOf("Valider") == 1 && this.context.ctxUtilisateur === "Responsable")
          this.btnBar.model.trigger("show:valider");
        this.btnBar.model.set("mode", "R");

        pret.id = this.context.ctxIdentifiantEvt;

        pret.action = "element";
        this.typeStructures = new TypeStructuresColl();

        var callback = function(fresh) {

          self.affTypeSaisieOptionsColl.affectation = STR.getElValue(fresh, "type.code");
          self.affTypeSaisieOptionsColl.fetch({
            success: function() {
              self.affModeSaisieOptionsColl.affectation = STR.getElValue(fresh, "type.code");
              self.affModeSaisieOptionsColl.fetch({
                success: function() {
                  self.model.set("value", fresh, { silent: true }); //This will trigger a _mapToForm
                  self.model.get("value").store();
                  self.original = fresh.clone();
                  var hc = self.model.getHabContext().copy();
                  var habilitationGestion = self._getHabilitationGestion(self.model.get("value"));
                  hc.update({ fonc_cour: habilitationGestion, nat_gest: "" });
                  self.typeStructures.setHabContext(hc);
                  //Get structures in consultation in order to show their libelles.
                  self.typeStructures.fetch({
                    success: function() {
                      self.model.get("value").structures = self.model.get("value").get("structures");

                      self._manageHeader();
                      self._manageWKFHisto(self.context.ctxHistoriqueWKF);

                      self._manageRestrictions();
                      self._manageActionsPossibles();
                      self._manageHabilitation();

                      self._showHideFields();
                      self._renderSelChemins();
                      self._showPieceJointe();

                      self._paintSimulateModeHeader();

                      // EVO 458. if we have date and collabremplace we create initialise 'les rattachements aux structures'
                      self._initRattEmpruntCollab(); //Launch _mapToForm in the last CALLBACK.

                      self._enableDisableFields();

                      if (self.$el.find(".liste-structures label:eq(0)").text().length > 18) {
                        self.$el.find(".liste-structures label:eq(0)").css("margin-right", 30);
                      }

                      if (self.$el.find("button.valider").is(":visible") === true) {

                        // As in SFD.
                        if (self.context.ctxUtilisateur === "Responsable" && self.model.get("value").get("etat") === "D") {
                          self.btnBar.model.trigger("show:valider");
                        } else {
                          self.btnBar.model.trigger("hide:valider");
                        }

                        var profilId = pret.get("code");
                        self.profilModel = new BaseModel();
                        self.profilModel.url = function() {
                          return Configuration.restRoot + "/rest/composant/pret/profils/" + encodeURIComponent(profilId);
                        };
                        var hc = self.model.getHabContext().copy();
                        hc.update({ fonc_cour: self.context.ctxHabilitation.HabilitationValidation, nat_gest: "M" });
                        self.profilModel.setHabContext(hc);
                        //self.profilModel.updateHabContext({fonc_cour :self.context.ctxHabilitation.HabilitationAcces, nat_gest : ""});

                        //self.profilModel.updateHabContext({fonc_cour :self.context.ctxHabilitation.HabilitationAcces, nat_gest : ""});

                        self.profilModel.fetch({
                          success: function(freshProfil) {
                            if (freshProfil.get("profils") && freshProfil.get("profils").length > 0) {
                              self._configureValidationButton();
                              self.btnBar.model.trigger("show:valider");
                            } else {
                              self.btnBar.model.trigger("hide:valider");
                            }
                          }
                        });
                      }

                      if (originalCallback) {
                        originalCallback();
                      }
                    }
                  });
                }
              });
            }
          });
        };

        if (this._allValeursEvenementInformed() === true) {
          //If all valeurs evenement are informed in the entering context
          this._initializePretFromContext(pret);
          callback(pret);
        } else {
          //Initialize from server
          if (!STR.isBlank(this.context.ctxSimulation)) {
            pret.activeSimulation = true;
            pret.ctxVueJourneeDate = this.context.ctxVueJourneeDate;
          }
          pret.fetch({
            success: function(fresh) {
              self.context.ctxEvtEstSimule = SIMULATION.getSimulationState(fresh.get("etatsimu"));
              callback(fresh);
            }
          });
        }
        break;
      case "Ajout":
        var habilitationGestion = this._getHabilitationGestion();
        this.model.updateHabContext({ fonc_cour: habilitationGestion, nat_gest: "A" });

        this.btnBar.model.trigger("disable:new");
        this.btnBar.model.trigger("disable:delete");
        this.btnBar.model.trigger("hide:valider");

        STR.setElValue(pret, "type.code", this.context.ctxValeursCreation.PretType || "");
        STR.setElValue(pret, "perimetre.code", this.context.ctxValeursCreation.PretPerimetre || this.context.ctxPerimetre);
        STR.setElValue(pret, "emprunt", this.context.ctxValeursCreation.PretEstUnEmprunt || this.context.ctxTypeEvtGere === "E" ? true : false); // si ctxTypeEvtGere = Emprunt sinon faux

        //pret.set("createur", this.context.ctxValeursCreation.PretResponsable  || "");
        pret.set("createur", this.model.get("value").get("est_createur") || "");
        STR.setElValue(pret, "datedeb", this.context.ctxValeursCreation.PretDateDebut || "");

        if (pret.get("typesaisie") === "P") {
          STR.setElValue(pret, "modesai_periodeb.code", this.context.ctxValeursCreation.PretUniteDebut || "");
        } else {
          STR.setElValue(pret, "unitedeb.code", this.context.ctxValeursCreation.PretUniteDebut || "");
        }
        STR.setElValue(pret, "datefin", this.context.ctxValeursCreation.PretDateFin || "");
        STR.setElValue(pret, "modesai_periofin.code", this.context.ctxValeursCreation.PretUniteFin || "");
        STR.setElValue(pret, "heuredeb", this.context.ctxValeursCreation.PretHeureDebut || "");
        STR.setElValue(pret, "heurefin", this.context.ctxValeursCreation.PretHeureFin || "");
        STR.setElValue(pret, "commentaire", this.context.ctxValeursCreation.PretCommentaire || "");
        STR.setElValue(pret, "motif.code", this.context.ctxValeursCreation.PretMotif || "");

        var matric = objs.appRt.workflow.authModel.get("matricule");
        STR.setElValue(pret, "createur", matric);

        var callbackAjout = function() {

          self.model.set("value", pret, { silent: true });
          self.model.get("value").store();
          self._manageHeader();

          self._manageRestrictions();
          self.btnBar.model.trigger("hide:valider");
          self._manageActionsPossibles();
          self._manageHabilitation();

          self._renderSelChemins();
          self._showPieceJointe();
          self._enableDisableFields();
          self._showHideFields();

          self._initRattEmpruntCollab(); //Launch _mapToForm in the last CALLBACK.

          self.btnBar.model.set("mode", "E");
        };

        this.typeStructures = new TypeStructuresColl();
        var callbackInSuccess = function() {
          self._getStructuresPerTypeEvtAndper(pret.get("datedeb"), pret, callbackAjout);
        };
        self._initializeCreation(pret, callbackInSuccess);

        break;
      default:
        //Nothing
    }
  },
  _allValeursEvenementInformed: function() {
    var allInformed = false;
    var valerusEvenement = this.context.ctxValeursEvenement;
    if (!STR.isBlank(valerusEvenement) && !STR.isBlank(valerusEvenement.PretCodeStatutWKF) && !STR.isBlank(valerusEvenement.PretEnSuppression) &&
      !STR.isBlank(valerusEvenement.PretType) && !STR.isBlank(valerusEvenement.PretPerimetre) && !STR.isBlank(valerusEvenement.PretListeElementStructure) &&
      !STR.isBlank(valerusEvenement.PretTypeSaisie) && !STR.isBlank(valerusEvenement.PretModeSaisie) && !STR.isBlank(valerusEvenement.PretDateDebut) &&
      !STR.isBlank(valerusEvenement.PretUnite) &&
      !STR.isBlank(valerusEvenement.PretUniteDebut) && !STR.isBlank(valerusEvenement.PretDateFin) && !STR.isBlank(valerusEvenement.PretUniteFin) &&
      !STR.isBlank(valerusEvenement.PretHeureDebut) && !STR.isBlank(valerusEvenement.PretHeureFin) && !STR.isBlank(valerusEvenement.PretDuree) &&
      !STR.isBlank(valerusEvenement.PretPourcentage) && !STR.isBlank(valerusEvenement.PretMotif) && !STR.isBlank(valerusEvenement.PretOrigine) &&
      !STR.isBlank(valerusEvenement.PretLibelle) && !STR.isBlank(valerusEvenement.PretCommentaire) && !STR.isBlank(valerusEvenement.PretListeInfoComplValeur)) {
      allInformed = true;
    }

    if (this.context.ctxHistoriqueWKF == true) {
      allInformed = allInformed && !STR.isBlank(valerusEvenement.PretCommentaireWKF);
    }
    return allInformed;
  },
  /**
   * Check if there is only one type of pret available and select it and initialize the rest of fields depending on it
   * in case there is only one available
   */
  _initTypePret: function(pret, callback) {
    var self = this;
    this.typeComboColl.fetch({
      success: function(freshTypes) {
        if (freshTypes.models.length === 1) {
          //si l’utilisateur est habilité à un seul type de prêt, le proposer,
          STR.setElValue(pret, "type.code", freshTypes.models[0].get("code"));
          STR.setElValue(pret, "type.libelle", freshTypes.models[0].get("libelle"));
          self._typeChanged(pret, callback);
        } else {
          callback();
        }

      }
    });
  },
  /**
   * Initialize values of model as described in Règles d’initialisation du composant en mode création.
   */
  _initializeCreation: function(pret, callback) {
    var self = this;
    var pretType = this.context.ctxValeursCreation.PretType;
    var dateDeb = this.context.ctxValeursCreation.PretDateDebut;
    var dateFin = this.context.ctxValeursCreation.PretDateFin;
    if (STR.isBlank(pretType) && STR.isBlank(dateDeb) && STR.isBlank(dateFin)) {
      //Si aucune donn�e n�est renseign�e dans ctxValeursCr�ation �:
      pret.set("typesaisie", "D");
      pret.set("modesaisie", "UNITE"); //TODO initialize to heures when modesaisie heures available
      this._initTypePret(pret, callback);
    } else if (STR.isBlank(pretType) && !STR.isBlank(dateDeb) && !STR.isBlank(dateFin)) {
      //Si le type de pr�t n�est pas renseign� et les dates de d�but et fin sont renseign�es dans ctxValeursCr�ation
      if (dateDeb == dateFin) {
        pret.set("typesaisie", "D");
        pret.set("modesaisie", "UNITE"); //TODO delete when another modesaisie available
      } else {
        pret.set("typesaisie", "P");
        pret.set("modesaisie", "PERIOC");
      }

      if (!STR.isBlank(this.context.ctxValeursCreation.PretUniteDebut) && !STR.isBlank(this.context.ctxValeursCreation.PretUniteFin)) {
        if (pret.get("typesaisie") == "P") {
          //Dans le cas o� le type de saisie est � P�riode � et que l�unit� de d�but n�est pas autoris�e en 1er jour de p�riode ou que l�unit� de fin n�est pas autoris�e en dernier jour de p�riode proposer l�unit� Jour
          pret.set("modesaisie", "PERIOC");
          self.unitesComboColl.fetch({
            success: function() {
              self.uniteDebComboColl.fetch({
                success: function() {
                  self.uniteFinComboColl.fetch({
                    success: function() {
                      var unitedeb = self.uniteDebComboColl.findWhere({ code: self.context.ctxValeursCreation.PretUniteDebut });
                      var unitefin = self.uniteFinComboColl.findWhere({ code: self.context.ctxValeursCreation.PretUniteFin });
                      if (STR.isBlank(unitedeb) || STR.isBlank(unitefin)) {
                        pret.get("modesai_periodeb").code = "J";
                        pret.get("modesai_periofin").code = "J";
                      } else {
                        pret.get("modesai_periodeb").code = self.context.ctxValeursCreation.PretUniteDebut;
                        pret.get("modesai_periofin").code = self.context.ctxValeursCreation.PretUniteFin;
                      }
                      self.checkUnitesCoherence(pret);
                      self._initTypePret(pret, callback);
                    }
                  });
                }
              });
            }
          });
        } else {
          //	Si l�unit� de d�but et l�unit� de fin sont renseign�es dans ctxValeursCr�ation, le mode de saisie est initialis� � � Unit� �.
          STR.setElValue(pret, "unitedeb.code", this.context.ctxValeursCreation.PretUniteDebut || "");
          self._initTypePret(pret, callback);
        }
      } else {
        //Sinon  (Unité de début et/ou fin non renseignées), initialiser le mode de saisie avec le mode de saisie par défaut associé au type de saisie positionné.
        this._initTypePret(pret, callback);
      }

    } else if (STR.isBlank(pretType) && !STR.isBlank(dateDeb) && STR.isBlank(dateFin)) {
      //Si la date de d�but est renseign�e et pas le type de pr�t et la date de fin dans ctxValeursCr�ation
      pret.set("typesaisie", "D");
      //TODO add when modesaisie differente of UNITE is available
      //			if (!STR.isBlank(this.context.ctxValeursCreation.PretUniteDebut)){
      pret.set("modesaisie", "UNITE");
      STR.setElValue(pret, "unitedeb.code", this.context.ctxValeursCreation.PretUniteDebut || "");
      //			}else{
      //				pret.set("modesaisie","PLH");
      //			}
      this._initTypePret(pret, callback);
    } else if (STR.isBlank(pretType) && STR.isBlank(dateDeb) && !STR.isBlank(dateFin)) {
      //			//Si la date de fin est renseign�e et pas le type de pr�t et la date de d�but dans ctxValeursCr�ation
      pret.set("datefin", "");
      this._initTypePret(pret, callback);
    } else if (!STR.isBlank(pretType)) {
      //When type pret is informed, obtain default pret values, authorized typesaisie,modesaisie, unitedeb unitedeb for periode and unitefin for periode
      this.pretDefaults.id = pretType;
      this._updateCombosForType(pretType);
      this.pretDefaults.fetch({
        success: function(fresh) {
          self.affTypeSaisieOptionsColl.affectation = pretType;
          self.affTypeSaisieOptionsColl.fetch({
            success: function() {
              self.affModeSaisieOptionsColl.affectation = pretType;
              self.affModeSaisieOptionsColl.fetch({
                success: function() {
                  self.unitesComboColl.fetch({
                    success: function() {
                      self.uniteDebComboColl.fetch({
                        success: function() {
                          self.uniteFinComboColl.fetch({
                            success: function() {
                              self.initCreationModeWithType(pret, fresh, callback);
                            }
                          });
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        }
      });
    }

  },

  initCreationModeWithType: function(pret, defaultValues, callback) {
    var self = this;
    var pretType = this.context.ctxValeursCreation.PretType;
    var dateDeb = this.context.ctxValeursCreation.PretDateDebut;
    var dateFin = this.context.ctxValeursCreation.PretDateFin;
    if (!STR.isBlank(pretType) && STR.isBlank(dateDeb) && STR.isBlank(dateFin)) {
      //Si seule type de pret est renseignée dans ctxValeursCréation
      this.setDefaultTypeModeValues(pret, defaultValues, true);
    } else if (!STR.isBlank(pretType) && !STR.isBlank(dateDeb) && !STR.isBlank(dateFin)) {
      //Si le type de pr�t et les dates de d�but et fin sont renseign�es dans ctxValeursCr�ation :�
      if (dateDeb == dateFin)
        pret.set("typesaisie", "D");
      else {
        var periodeFound = this.affTypeSaisieOptionsColl.findWhere({ code: "P" });
        var repetitiveFound = this.affTypeSaisieOptionsColl.findWhere({ code: "R" });
        if (!STR.isBlank(periodeFound) && !STR.isBlank(repetitiveFound)) {
          if (this.pretDefaults.get("modadef").code == "P" || this.pretDefaults.get("modadef").code == "R") {
            pret.set("typesaisie", this.pretDefaults.get("modadef").code);
          } else {
            pret.set("typesaisie", "R");
          }
        } else if ((STR.isBlank(periodeFound) && !STR.isBlank(repetitiveFound)) ||
          (!STR.isBlank(periodeFound) && STR.isBlank(repetitiveFound))) {
          pret.set("typesaisie", this.pretDefaults.get("modadef").code);
        } else {
          pret.set("typesaisie", "D");
          pret.set("datefin", "");
        }
      }
      if (!STR.isBlank(this.context.ctxValeursCreation.PretUniteDebut) && !STR.isBlank(this.context.ctxValeursCreation.PretUniteFin)) {
        if (pret.get("typesaisie") == "P") {
          var unitedeb = this.uniteDebComboColl.findWhere({ code: this.context.ctxValeursCreation.PretUniteDebut });
          var unitefin = this.uniteFinComboColl.findWhere({ code: this.context.ctxValeursCreation.PretUniteFin });
        } else {
          var unitedeb = this.unitesComboColl.findWhere({ code: this.context.ctxValeursCreation.PretUniteDebut });
          var unitefin = this.unitesComboColl.findWhere({ code: this.context.ctxValeursCreation.PretUniteFin });
        }
        if ((pret.get("typesaisie") == "P" && !STR.isBlank(unitedeb) && !STR.isBlank(unitefin)) ||
          (pret.get("typesaisie") != "P" && !STR.isBlank(unitedeb))) {
          //�	Si elles sont autoris�es pour le type de pr�t et le type de saisie : le mode de saisie est initialis� � � Unit� �.
          var modesaisie = pret.get("typesaisie") == "P" ? "PERIOC" : "UNITE";
          pret.set("modesaisie", modesaisie);
          var callbackControlPeriode = function() {
            if (pret.get("typesaisie") == "P") {
              var unitedeb = self.uniteDebComboColl.findWhere({ code: self.context.ctxValeursCreation.PretUniteDebut });
              var unitefin = self.uniteFinComboColl.findWhere({ code: self.context.ctxValeursCreation.PretUniteFin });
              if (STR.isBlank(unitedeb) || STR.isBlank(unitefin)) {
                pret.set("modesai_periodeb", self.uniteDebComboColl.at(0));
                pret.set("modesai_periofin", self.uniteFinComboColl.at(0));
              }
            }
          };
          this.checkUnitesCoherence(pret, callbackControlPeriode);
        } else { //Initialisation Mode de saisie et composants associ�s Cas 1
          this._initModeCas1(pret, defaultValues);
        }
      } else {
        this.setDefaultTypeModeValues(pret, defaultValues, false);
      }

    } else if (!STR.isBlank(pretType) && !STR.isBlank(dateDeb) && STR.isBlank(dateFin)) {
      //Si le type de pr�t et la date de d�but sont renseign�s et pas la date de fin dans ctxValeursCr�ation
      pret.set("typesaisie", "D");
      if (!STR.isBlank(this.context.ctxValeursCreation.PretUniteDebut)) { //Si l�unit� de d�but est renseign�e dans ctxValeursCr�ation
        var unitedeb = this.unitesComboColl.findWhere({ code: this.context.ctxValeursCreation.PretUniteDebut });
        if (!STR.isBlank(unitedeb)) { //Si elle est autorisée pour le type du pret et le type de saisie « Date »
          pret.set("modesaisie", "UNITE");
          STR.setElValue(pret, "unitedeb.code", this.context.ctxValeursCreation.PretUniteDebut || "");
          this.checkUnitesCoherence(pret);
        } else {
          // Initialisation Mode de saisie et composants associ�s Cas 1
          this._initModeCas1(pret, defaultValues);
        }
      } else {
        // Initialisation Mode de saisie et composants associ�s Cas 1
        this._initModeCas1(pret, defaultValues);
      }
    } else if (!STR.isBlank(pretType) && STR.isBlank(dateDeb) && !STR.isBlank(dateFin)) {
      //Si le type de pr�t et la date de fin sont renseign�s et pas la date de d�but dans ctxValeursCr�ation
      this.setDefaultTypeModeValues(pret, defaultValues, true);
    }

    if (callback)
      callback();
  },

  _initModeCas1: function(pret, defaultValues) {
    if (pret.get("typesaisie") == "P") {
      //Type de saisie « Période » : Initialiser le mode de saisie par défaut pour une période continue
      //Et initialiser les composants de saisie associés à vide sans tenir compte des valeurs de ctxValeursCréation
      pret.set("heuredeb", "");
      pret.set("heurefin", "");
    }
    this.setDefaultTypeModeValues(pret, defaultValues, false);
  },

  /**
   * Obtain structures to be used in the form. They depend on the typeEvt (pret or emprunt) and the perimetre.
   * Depending this fields, the habilitation used to filter will be one or another and values will change.
   * Used to obtain the structures to be used in Ajout mode. Should be reloaded when perimetre changes
   */
  _getStructuresPerTypeEvtAndper: function(datedeb, model, callback) {
    var self = this;
    var hc = this.model.getHabContext().copy();
    var habilitationGestion = this._getHabilitationGestion(model);
    // Update the main model habilitation with the perimetre (normal or etendu)
    this.model.getHabContext().update({ fonc_cour: habilitationGestion });
    var structRattPret = false;
    var structRattPretAndCollab = false;
    hc.update({ fonc_cour: habilitationGestion, nat_gest: "" });
    this.typeStructures.setHabContext(hc);

    if (!STR.isBlank(datedeb))
      this.typeStructures.datedeb = datedeb;

    this.typeStructures.fetch({
      success: function() {
        var structures = [],
          structuresFull = [];
        _.each(self.typeStructures.models, function(structModel) {
          var obj = { type: structModel.get("code"), element: "" };
          var objFull = { type: structModel.get("code"), element: "", rattcollab: structModel.get("rattcollab") };
          if (!structRattPret && structModel.get("rattpret")) {
            structRattPret = true;
          }

          if (!structRattPretAndCollab && structModel.get("rattpret") && structModel.get("rattcollab") == "O") {
            structRattPretAndCollab = true;
          }
          // Type is "Non concerne"
          if (structModel.get("rattcollab") == "N") {
            // Hide it
            return;
          }
          structuresFull.push(objFull);
          structures.push(obj);
        });

        if (!structRattPret) {
          MSGS.showError(i18n.t('messages:GT_1429'));
          self.parent.close();
        } else if (!structRattPretAndCollab) {
          MSGS.showError(i18n.t('messages:GT_1430'));
          self.parent.close();
        } else {
          model.set("structures", structures);
          model.structures = structuresFull;
          //self._initRattEmpruntCollab(); // we update the structures. EVO 458

          if (callback)
            callback();
        }

      }
    });
  },

  /**
   *
   */
  _paintSimulateModeHeader: function() {
    if (this.context.ctxEcran === "planresp" && this.context.ctxModeRepresentation == "pop-up" && !STR.isBlank(this.context.ctxSimulation) && !STR.isBlank(this.context.ctxEvtEstSimule)) {
      var msg = this.context.ctxEvtEstSimule.libelle;
      this.$el.find(".journeeSimulated").html(msg).show();
    } else {
      this.$el.find(".journeeSimulated").hide();
    }
  },

  _paintWorkflowStatus: function() {
    if (this.context.ctxModeInitialisation === "Consultation") {
      this.$el.find(".statutPretLibelle").show();

      var gererPret = this.model.get("value");

      if (!STR.isBlank(gererPret.get("statut").code)) {
        var statutWKFStyle = this._getWorkflowStyle(gererPret.get("statut").code);
        var statutLibelle = "<span class='" + statutWKFStyle + "' >" + gererPret.get("statut").libelle + "</span>";

        this.$el.find(".statutPretLibelle").html(statutLibelle);
      }

    } else {
      this.$el.find(".statutPretLibelle").hide();
    }
  },
  /**
   * Get the statut style of the statut of a pret
   * Posible values are the same than posible values for prets
   */
  _getWorkflowStyle: function(statut) {
    switch (statut) {
      case "A":
        return "ui-phx-statut-accepte";
      case "D":
      case "T":
        return "ui-phx-statut-demande";
      case "I":
        return "ui-phx-statut-en-cours";
      case "R":
        return "ui-phx-statut-refuse";
      case "H":
        return "ui-phx-statut-hors-validation";
    }
  },

  _manageActionsPossibles: function() {
    //["Creer","Supprimer","Modifier","Valider"]
    var actionsPossibles = this.context.ctxActionsPossibles;
    if (!STR.isBlank(this.context.ctxSimulation)) {
      if (this.context.ctxDonneeSimulee == false) {
        actionsPossibles = _.reject(actionsPossibles, function(d) { return (d == "Modifier" || d == "Supprimer") });
      }
      var evtEstSimule = this.context.ctxEvtEstSimule && this.context.ctxEvtEstSimule.valeur ? this.context.ctxEvtEstSimule.valeur : this.context.ctxEvtEstSimule;
      if (evtEstSimule != 3 && evtEstSimule != 4 && evtEstSimule != 5 && this.context.ctxCollab.type !== "S") {
        actionsPossibles = _.reject(actionsPossibles, function(d) { return (d == "Supprimer") });
      }
    }

    if (actionsPossibles && this.context.ctxModeInitialisation === "Ajout") {
      if (actionsPossibles.indexOf("Creer") == -1) {
        this.btnBar.model.trigger("hide:new");
      }
    } else if (actionsPossibles && this.context.ctxModeInitialisation === "Consultation") {
      if (actionsPossibles.indexOf("Creer") == -1) {
        this.btnBar.model.trigger("hide:new");
      }
      if (actionsPossibles.indexOf("Supprimer") == -1) {
        this.btnBar.model.trigger("hide:delete");
      }
      if (actionsPossibles.indexOf("Modifier") == -1) {
        this._disableForm();
      }
      if (actionsPossibles.indexOf("Creer") == -1 && actionsPossibles.indexOf("Modifier") == -1) {
        this.btnBar.model.trigger("hide:save");
        this.btnBar.model.trigger("hide:revert");
      }
      if (actionsPossibles.indexOf("Valider") == -1) {
        this.btnBar.model.trigger("hide:valider");
      }
    }
  },

  _manageHabilitation: function() {
    var habilitationGestion = this._getHabilitationGestion(this.model.get("value"));
    if (!HABILITATION.canCreate(habilitationGestion)) {
      this.btnBar.model.trigger("hide:new");
    }
    if (!HABILITATION.canUpdate(habilitationGestion) && !HABILITATION.canCreate(habilitationGestion)) {
      this.btnBar.model.trigger("hide:revert");
      this.btnBar.model.trigger("hide:save");
    }
    if (!HABILITATION.canDelete(habilitationGestion)) {
      this.btnBar.model.trigger("hide:delete");
    }
    if (!HABILITATION.canView(this.context.ctxHabilitation.HabilitationValidation)) {
      this.btnBar.model.trigger("hide:valider");
    }
    if (this.context.ctxModeInitialisation === "Ajout" && !HABILITATION.canView(habilitationGestion)) {
      this._disableForm();
    }
  },

  _updateSaveButtonState: function() {
    this._manageRestrictions();
  },

  /**
   * Restrictions to be set depending on the current statut
   */
  _manageRestrictions: function() {
    this._configBtnBar();
    var gererPrets = this.model.get("value");
    var model = this.model.get("value");
    if (this.context && this.context.ctxUtilisateur == "Collaborateur" && !STR.isBlank(model) && !STR.isBlank(model.get("statut"))) {
      this.btnBar.model.trigger("hide:valider");
      this.btnBar.model.trigger("hide:delete");
      this.btnBar.model.trigger("hide:new");
      this._disableForm();
    } else if (this.context && this.context.ctxUtilisateur == "Responsable" && !STR.isBlank(model.get("statut"))) {
      if (gererPrets.get("est_createur") != true && this.model.get("value").get("etat") == "D") {
        this.btnBar.model.trigger("hide:delete");
      } else if ((gererPrets.get("est_createur") == true && this.model.get("value").get("etat") == "D") || (this.model.get("value").get("etat") == "A")) {
        this.btnBar.model.trigger("show:delete");
      }
      switch (model.get("etat")) {
        case "D": //Demande
          if (gererPrets.get("est_createur") != true) {
            this._disableForm();
          }
          break;
        case "A": //Accepte
          //valideurfinal is checked in metiere part by ws updateControlPretCollab(is modifiable)
          this.btnBar.model.trigger("hide:valider");
          break;
        case "R": //Refusé
          this.btnBar.model.trigger("hide:delete");
          this.btnBar.model.trigger("hide:valider");
          this._disableForm();
          break;
        default:
          this._enableForm();
          break;
      }
    }
  },

  _disableForm: function() {
    FORMS.setFormReadonly(this.$el, true, false);
    this.disabled = true;
  },

  _enableForm: function() {
    FORMS.setFormReadonly(this.$el, false, false);
    this.disabled = false;
  },

  _disableInfoComp: function() {
    FORMS.setFormReadonly(this.$el.find(".gererprets-infocomp"), true, false);
    this.disabled = true;
  },

  _enableInfoComp: function() {
    FORMS.setFormReadonly(this.$el.find(".gererprets-infocomp"), false, false);
    this.disabled = false;
  },

  _enableDisableFields: function() {
    var self = this;
    var gererPret = this.model.get("value");
    if (!gererPret.isNew()) { //This is not a creation
      FORMS.setFieldReadonly(this.$el.find(".type\\.code"), true, false);
      //FORMS.setFieldReadonly(this.$el.find(".perimetre\\.code"), true, false);
      this._enableDisablePerimetre();
      _.each(this.listeCheminViews, function(selChemin) {
        selChemin.setReadOnly(true);
      });
      this.typeSaisie.setMenuAccess(false);
      this._enableModeSaisie();

      FORMS.setFieldReadonly(this.$el.find(".typeSaisie .modesai_periodeb\\.code"), true, false);
      FORMS.setFieldReadonly(this.$el.find(".modeSaisie .unitedeb\\.code"), true, false);
      FORMS.setFieldReadonly(this.$el.find(".typeSaisie .modesai_periofin\\.code"), true, false);
      if (gererPret.get("typesaisie") == "P") { //For typeSaisie=Periode, dates modifiable only in creation mode
        FORMS.setFieldReadonly(this.$el.find(".datedeb"), true, false);
        FORMS.setFieldReadonly(this.$el.find(".datefin"), true, false);
      }
    } else {
      FORMS.setFieldReadonly(this.$el.find(".type\\.code"), false, false);
      this.typeSaisie.setMenuAccess(true);
      this.modeSaisie.setMenuAccess(true);

      _.each(this.listeCheminViews, function(selChemin) {
        selChemin.setReadOnly(false);
      });

      FORMS.setFieldReadonly(this.$el.find(".unitedeb\\.code"), false, false);
      FORMS.setFieldReadonly(this.$el.find(".typeSaisie .modesai_periodeb\\.code"), false, false);
      FORMS.setFieldReadonly(this.$el.find(".typeSaisie .modesai_periofin\\.code"), false, false);

      FORMS.setFieldReadonly(this.$el.find(".datedeb"), false, false);
      this._enableDisablePerimetre();
    }
  },
  _enableModeSaisie: function() {
    var gererPret = this.model.get("value");
    var lb_val = true; //par defaut

    if (!gererPret.isNew() || STR.isBlank(STR.getElValue(gererPret, "type.code"))) {
      lb_val = false;
    }
    if (this.modeSaisie) {
      this.modeSaisie.setMenuAccess(lb_val);
    }
  },
  _enableDisablePerimetre: function() {
    var gererPret = this.model.get("value");
    if (this.context.ctxPerimetreFige == true ||
      (!HABILITATION.canView(this.context.ctxHabilitation.HabilitationGestionNormal) && !HABILITATION.canView(this.context.ctxHabilitation.HabilitationGestionEtendu)))
      FORMS.setFieldReadonly(this.$el.find(".perimetre\\.code"), true, false);
    else if (this.disabled != true) {
      FORMS.setFieldReadonly(this.$el.find(".perimetre\\.code"), false, false);
    }
  },
  _showHideFields: function() {
    var self = this;
    var gererPret = this.model.get("value");
    if (this.context.ctxUtilisateur == "Collaborateur") {
      this.$el.find("comment_pret_div").hide();
    } else {
      this.$el.find("comment_pret_div").show();
    }

    if (this.context.ctxUtilisateur == "Responsable" && !STR.isBlank(this.context.ctxIdentifiantEvt)) {
      this.$el.find("gererprets-infocomp").show();
    } else {
      this.$el.find("gererprets-infocomp").hide();
    }

    if (this.context.ctxHistoriqueWKF == true) {
      this.$el.find(".commentaire_div").show();
    } else {
      this.$el.find(".commentaire_div").hide();
    }
  },
  _initializePretFromContext: function(fresh) {
    if (!STR.isBlank(this.context.ctxValeursEvenement)) {
      if (!STR.isBlank(this.context.ctxValeursEvenement.PretType))
        STR.setElValue(fresh, "type.code", this.context.ctxValeursEvenement.PretType);
      if (!STR.isBlank(this.context.ctxValeursEvenement.PretListeElementStructure) && this.context.ctxValeursEvenement.PretListeElementStructure.length > 0) {
        var arrayStructures = [];
        _.each(this.context.ctxValeursEvenement.PretListeElementStructure, function(structObj) {
          arrayStructures.push(structObj);
        });
        STR.setElValue(fresh, "structures", arrayStructures);
      }
      if (!STR.isBlank(this.context.ctxValeursEvenement.PretEnSuppression))
        STR.setElValue(fresh, "demandesuppression", this.context.ctxValeursEvenement.PretEnSuppression);

      if (!STR.isBlank(this.context.ctxValeursEvenement.PretTypeSaisie))
        STR.setElValue(fresh, "typesaisie", this.context.ctxValeursEvenement.PretTypeSaisie);
      if (!STR.isBlank(this.context.ctxValeursEvenement.PretDateDebut))
        STR.setElValue(fresh, "datedeb", this.context.ctxValeursEvenement.PretDateDebut);
      if (!STR.isBlank(this.context.ctxValeursEvenement.PretDateFin))
        STR.setElValue(fresh, "datefin", this.context.ctxValeursEvenement.PretDateFin);
      if (!STR.isBlank(this.context.ctxValeursEvenement.PretModeSaisie))
        STR.setElValue(fresh, "modesaisie", this.context.ctxValeursEvenement.PretModeSaisie);
      //Hors perimetre V2
      //			if (!STR.isBlank(this.context.ctxValeursEvenement.PretHeureDebut))
      //				STR.setElValue(fresh,"heuredeb",this.context.ctxValeursEvenement.PretHeureDebut);
      //			if (!STR.isBlank(this.context.ctxValeursEvenement.PretHeureFin ))
      //				STR.setElValue(fresh,"heurefin",this.context.ctxValeursEvenement.PretHeureFin);

      if (!STR.isBlank(this.context.ctxValeursEvenement.PretUnite))
        STR.setElValue(fresh, "unitedeb.code", this.context.ctxValeursEvenement.PretUniteDebut);
      if (!STR.isBlank(this.context.ctxValeursEvenement.PretUniteDebut)) {
        STR.setElValue(fresh, "modesai_periodeb.code", this.context.ctxValeursEvenement.PretUniteDebut);
      }
      if (!STR.isBlank(this.context.ctxValeursEvenement.PretUniteFin))
        STR.setElValue(fresh, "modesai_periofin.code", this.context.ctxValeursEvenement.PretUniteFin);
      if (!STR.isBlank(this.context.ctxValeursEvenement.PretMotif))
        STR.setElValue(fresh, "motif.code", this.context.ctxValeursEvenement.PretMotif);
      if (!STR.isBlank(this.context.ctxValeursEvenement.PretOrigine))
        STR.setElValue(fresh, "origine.code", this.context.ctxValeursEvenement.PretOrigine);

      if (!STR.isBlank(this.context.ctxValeursEvenement.PretLibelle))
        STR.setElValue(fresh, "libelle", this.context.ctxValeursEvenement.PretLibelle);

      if (!STR.isBlank(this.context.ctxValeursEvenement.PretCommentaire))
        STR.setElValue(fresh, "commentaire_pret", this.context.ctxValeursEvenement.PretCommentaire);
      if (!STR.isBlank(this.context.ctxValeursEvenement.PretCommentaireWKF))
        STR.setElValue(fresh, "commentaire", this.context.ctxValeursEvenement.PretCommentaireWKF);

      if (!STR.isBlank(this.context.ctxValeursEvenement.PretListeInfoComplValeur))
        STR.setElValue(fresh, "infocomp", this.context.ctxValeursEvenement.PretListeInfoComplValeur);
    }
  },

  _manageLink: function(event) {
    var element = event.target.className.split(" ")[0];
    switch (element) {
      case "montreraide": // SHOW HELP
        this.$el.find(".montreraide").hide();
        this.$el.find(".masqueraide").show();
        this.$el.find(".indication").show();
        break;
      case "masqueraide": // HIDE HELP
        this.$el.find(".indication").hide();
        this.$el.find(".masqueraide").hide();
        this.$el.find(".montreraide").show();
        break;
      default:
        break;
    }
  },

  render: function() {
    var json = { "i18n": i18n };
    this.$el.empty();

    this.$el.html(this.template(json));

    this._renderDeroulantLabels();
    this.renderForm();

    //		//Dynamic Data
    //		this._LoadDynamicFieldData();

    var self = this;
    if (self.context) {
      self.setContext(self.context);
    }

    //Dialog Position
    if (this.parent) {
      this.parent.dialog.dialog("option", "position", { my: "center", at: "center", of: window });
    }

    return this;
  },
  _renderDeroulantLabels: function() {
    var self = this;
    var json = { "i18n": i18n };
    var tplTypeSaisie = TPL_gererprets_multiLabel_typeSaisie;
    this.typeSaisie = new MultiLabel({
      template: tplTypeSaisie(json),
      required: true,
      name: "typesaisie",
      changeLabelCallback: this._changeMultiLabel,
      parentView: this
    });
    this.$el.find(".typeSaisie").html(this.typeSaisie.render().el);

    //Filter typeSaisie
    this.typeSaisie.setFilter(function(response, index) {
      //return only unités checked
      return _.filter(response, function(resp) {
        var found = false;
        if (!STR.isBlank(self.affTypeSaisieOptionsColl) && self.affTypeSaisieOptionsColl.models.length > 0) {
          _.each(self.affTypeSaisieOptionsColl.models, function(typeSaisie) {
            if (typeSaisie.get("code") == resp.name)
              found = true;
          });
        } else {
          found = true;
        }

        return found;
      });
    });

    var tplModeSaisie = TPL_gererprets_multiLabel_modeSaisie;
    this.modeSaisie = new MultiLabel({
      template: tplModeSaisie(json),
      required: true,
      name: "modesaisie",
      changeLabelCallback: this._changeMultiLabel,
      parentView: this
    });
    this.$el.find(".modeSaisie").html(this.modeSaisie.render().el);

    //Filter typeSaisie
    this.modeSaisie.setFilter(function(response, index) {
      //return only unités checked
      return _.filter(response, function(resp) {
        var found = false;
        if (!STR.isBlank(self.affModeSaisieOptionsColl) && self.affModeSaisieOptionsColl.models.length > 0) {
          _.each(self.affModeSaisieOptionsColl.models, function(modeSaisie) {
            if (modeSaisie.get("code") == resp.name)
              found = true;
          });
        } else {
          found = true;
        }

        return found;
      });
    });
  },

  _renderSelChemins: function() {
    var self = this;
    this.listeCheminViews = [];
    var gererPrets = this.model.get("value");
    var structures = gererPrets.structures;

    $(this.el).find(".liste-structures").html("");
    _.each(structures, function(struct) {
      var type = _.find(self.typeStructures.models, function(structModel) {
        return structModel.get("code") == struct.type;
      });
      if (!STR.isBlank(type)) {
        var label = "<label for='structures_itemid_" + struct.type + "' class='" + (struct.rattcollab === "O" ? "required" : "") + "'>" + type.get("libelle") + "</label>";
        $(self.el).find(".liste-structures").append(label);

        var readonly = gererPrets.isNew() ? false : true;
        var comboChemin = new SelecteurCheminView({
          name: "structures_itemid_" + struct.type,
          listRenderStyle: 1, //format
          fieldWidth: 200,
          height: 500,
          width: 850,
          structid: struct.type, //code
          addItemCallback: function(data, index) {
            self._responseCallbackChemin(data, index, struct.type);
          },
          intermediaire: true,
          partial: false,
          modeComplet: true,
          multiselection: false,
          nonrattachable: false,
          readonly: readonly,
          habContext: self.model.getHabContext() // Use the current habilitation context
        });
        $(self.el).find(".liste-structures").append(comboChemin.render().el);
        var errorContainer = "<span class='structures_itemid_" + struct.type + "-error-container'></span>";
        $(self.el).find(".liste-structures").append(errorContainer);
        self.listeCheminViews.push(comboChemin);
      }
    });

  },

  /**
   * Same as Change but for multilabel component
   */
  _changeMultiLabel: function(name, selectedValue) {
    var gererPret = this.model.get("value");
    STR.setElValue(gererPret, name, selectedValue);

    if (gererPret.isNew()) //Flag to know if modesaisie or typesaisie have changed in creation mode;
      this.saisieChanged = true;
  },
  /**
   * Type Saisie change event.
   */
  _changeTypeSaisie: function(event, data) {
    var className = event.target.className.split(" ")[0];

    if (className == "P") {
      this.model.get("value").set("modesaisie", "PERIOC");
      this.$el.find(".modeSaisie").hide();
    } else {
      var value = this.model.get("value");
      if (value.get("modesaisie") == "PERIOC") {
        //When changing from type saisie PERIOD to another typesaisie select first valid modesaisie
        var modeSaisieOptionsInformed = !STR.isBlank(this.affModeSaisieOptionsColl) && this.affModeSaisieOptionsColl.models.length > 0;
        var modesaisie = "UNITE"; //Default mode saisie=unites//TODO when adding new modesaisie, select the first as default
        if (modeSaisieOptionsInformed == true) {
          modesaisie = modeSaisieOptionsInformed == true ? this.affModeSaisieOptionsColl.at(0).get("code") : "PLH";
        }
        value.set("modesaisie", modesaisie);
      }
      this.$el.find(".modeSaisie").show();
    }
    this._mapToForm();
  },

  renderForm: function(callback) {
    //Render combo
    this.$el.find(".cmbType").html(this.comboType.render().el);
    this.$el.find(".cmbPerimetre").html(this.comboPerimetre.render().el);
    this.$el.find(".cmbMotif").html(this.comboMotif.render().el);
    this.$el.find(".cmbOrigine").html(this.comboOrigine.render().el);
    this.$el.find(".comboUniteDeb").html(this.comboUniteDebut.render().el);
    this.$el.find(".comboUniteFin").html(this.comboUniteFin.render().el);
    this.$el.find(".comboUnite").html(this.comboUnites.render().el);

    // Dates
    FORMS.setDatepicker(this, ".datedeb");
    FORMS.setDatepicker(this, ".datefin");

    if (callback) {
      callback();
    }
    return this;
  },

  /**
   *
   */
  _updateCombosForType: function(typeCode) {
    if (this.unitesComboColl) {
      this.unitesComboColl.params = { affectation: typeCode };
    }
    if (this.comboUnites) {
      this.comboUnites.clearCache();
      this.comboUnites.clearColl();
    }
    if (this.uniteDebComboColl) {
      this.uniteDebComboColl.params = { code: typeCode };
    }
    if (this.comboUniteDebut) {
      this.comboUniteDebut.clearCache();
      this.comboUniteDebut.clearColl();
    }
    if (this.uniteFinComboColl) {
      this.uniteFinComboColl.params = { code: typeCode };
    }
    if (this.comboUniteFin) {
      this.comboUniteFin.clearCache();
      this.comboUniteFin.clearColl();
    }
  },
  /**
   *
   */
  _emptyCombosUnite: function() {
    if (this.unitesComboColl) {
      this.unitesComboColl.params = {};
    }
    if (this.comboUnites) {
      this.comboUnites.clearCache();
      this.comboUnites.clearColl();
    }
    if (this.uniteDebComboColl) {
      this.uniteDebComboColl.params = {};
    }
    if (this.comboUniteDebut) {
      this.comboUniteDebut.clearCache();
      this.comboUniteDebut.clearColl();
    }
    if (this.uniteFinComboColl) {
      this.uniteFinComboColl.params = {};
    }
    if (this.comboUniteFin) {
      this.comboUniteFin.clearCache();
      this.comboUniteFin.clearColl();
    }
  },

  /**
   * When type  has changed, or when iniitalizing type because there is only one available for user
   * - prepare combos url for that type.
   * - Obtain default values for the type
   * - And set them for : typeSaisie and modesaisie
   *                      motifs and origine
   */
  _typeChanged: function(gererPret, callbackType) {

    var typeCode = STR.getElValue(gererPret, "type.code");
    if (!STR.isBlank(typeCode)) {
      if (this.pretDefaults == null) {
        this.pretDefaults = new TypeAffDefaults();
        this.pretDefaults.setHabContext(new HabilitationContext({
          onglet: this.context.ctxEcran,
          fonc_cour: this.context.ctxHabilitation.HabilitationAcces
        }));
      }
      this.pretDefaults.id = typeCode;
      this._updateCombosForType(this.pretDefaults.id);

      var self = this;
      this.pretDefaults.fetch({
        success: function(fresh) {
          gererPret.set("infocomp", fresh.get("infocomp"));
          self.model.structures = fresh.get("structures");

          self.affTypeSaisieOptionsColl.affectation = typeCode;
          self.affTypeSaisieOptionsColl.fetch({
            success: function() {
              self.affModeSaisieOptionsColl.affectation = typeCode;
              self.affModeSaisieOptionsColl.fetch({
                success: function() {
                  //Set default motif and origine
                  self.setDefaultMotifInitiaValues(gererPret, fresh, false);

                  var currentTypeSaisie = gererPret.get("typesaisie");
                  var currentModeSaisie = gererPret.get("modesaisie");
                  var typeSaisieFound = _.find(self.affTypeSaisieOptionsColl.models, function(typesaisieModel) {
                    return typesaisieModel.get("code") == currentTypeSaisie;
                  });
                  var modeSaisieFound = null;
                  if (currentTypeSaisie == "P")
                    //When current typesaisie=Period, current modesaisie is going to be  perioc but this modesaisie doesn't come in the WS of mode
                    //Saisies allowed, so we suppose it is allowed
                    modeSaisieFound = "PERIOC";
                  else {
                    modeSaisieFound = _.find(self.affModeSaisieOptionsColl.models, function(modesaisieModel) {
                      return modesaisieModel.get("code") == currentModeSaisie;
                    });
                  }

                  //Le type de saisie ou le mode de saisie ne fait pas partie des types et modes de saisies autorisés paramétrés pour l’activité,
                  //Le type de saisie et le mode de saisie correspondent à une initialisation suite à un contexte de création sans valeurs renseignées
                  //et il n’y a pas eu de modification du type et/ou du mode ou de saisie de valeurs par l’utilisateur

                  var emptyEnteringContext = false;

                  if (self.context.ctxValeursCreation) {

                    emptyEnteringContext = STR.isBlank(self.context.ctxValeursCreation.PretType) && STR.isBlank(self.context.ctxValeursCreation.PretDateDebut) && STR.isBlank(self.context.ctxValeursCreation.PretDateFin);
                  } else {
                    if (gererPret.isNew()) {
                      emptyEnteringContext = true;
                    }
                  }

                  if (STR.isBlank(typeSaisieFound) || STR.isBlank(modeSaisieFound) || (gererPret.isNew() && emptyEnteringContext == true && self.saisieChanged != true)) {
                    self.setDefaultTypeModeValues(gererPret, fresh, true);
                    self._mapToForm();
                    if (callbackType)
                      callbackType();
                  } else {
                    self.uniteDebComboColl.fetch({
                      success: function() {
                        self.uniteFinComboColl.fetch({
                          success: function() {
                            self.unitesComboColl.fetch({
                              success: function() {
                                var callback = function() {
                                  self._mapToForm();
                                  if (callbackType)
                                    callbackType();
                                };

                                if (self.context.ctxValeursCreation) {
                                  if (STR.isBlank(self.context.ctxValeursCreation.PretUniteDebut) && STR.isBlank(self.context.ctxValeursCreation.PretHeureDebut) &&
                                    STR.isBlank(self.context.ctxValeursCreation.PretUniteFin) && STR.isBlank(self.context.ctxValeursCreation.PretHeureFin)) {
                                    self.setDefaultTypeModeValues(gererPret, fresh, false);
                                  }
                                }
                                self.checkUnitesCoherence(gererPret, callback);
                              }
                            });
                          }
                        });
                      }
                    });
                  }
                }
              });
            }
          });
        }
      });
    } else {
      this._emptyCombosUnite();
      this._initializeModeAndTypeColl();
      if (callbackType)
        callbackType();
    }
  },

  /**
   * Set the default pret values for motif and origine if the user hasn't selected a value for them before
   */
  setDefaultMotifInitiaValues: function(gererPret, defaultValues) {
    if (this.motifChanged != true) {
      STR.setElValue(gererPret, "motif.code", defaultValues.get("motif").code);
    }
    if (this.origineChanged != true) {
      STR.setElValue(gererPret, "origine.code", defaultValues.get("origine").code);
    }
  },

  /**
   * Set the default pret values for current type
   */
  setDefaultTypeModeValues: function(gererPret, defaultValues, changeTypeSaisie) {
    if (!STR.isBlank(defaultValues.get("repetdef")) && !STR.isBlank(defaultValues.get("repetdef").code)) {
      gererPret.set("modesaisie", defaultValues.get("repetdef").code);
    } else {
      //Check that current modesaisie is valid for pret, if it is not,set one by default
      var modeSaisieFound = this.affModeSaisieOptionsColl.findWhere({ code: gererPret.get("modesaisie") });
      if (STR.isBlank(modeSaisieFound)) {
        var firstModel = this.affModeSaisieOptionsColl.at(0);
        var code = !STR.isBlank(firstModel) ? firstModel.get("code") : null;
        gererPret.set("modesaisie", code);
      }

    }
    if (!STR.isBlank(defaultValues.get("modadef")) && !STR.isBlank(defaultValues.get("modadef").code)) {
      if (changeTypeSaisie != false)
        gererPret.set("typesaisie", defaultValues.get("modadef").code);
      if (gererPret.get("typesaisie") == "P") //If typesaisie=P, modesaisie is PERIOC
        gererPret.set("modesaisie", "PERIOC");
    }

    gererPret.set("unitedeb", defaultValues.get("unitedef"));
    gererPret.set("modesai_periodeb", { code: null, libelle: "" });
    gererPret.set("modesai_periofin", { code: null, libelle: "" });

    if (!STR.isBlank(defaultValues.get("perdef")) &&
      !STR.isBlank(defaultValues.get("perdef").code)) {
      switch (defaultValues.get("perdef").code) {
        // Cas du mode saisie en Unite de début /fin
        case "U":
          var periodeb = { code: "", libelle: "" };
          var periofin = { code: "", libelle: "" };
          if (!STR.isBlank(defaultValues.get("unitedebdef")) && !STR.isBlank(defaultValues.get("unitedebdef").code)) {
            periodeb = defaultValues.get("unitedebdef");
            if (periodeb.code.indexOf("U_") != 0)
              periodeb.code = "U_" + periodeb.code;
          }
          if (!STR.isBlank(defaultValues.get("unitefindef")) && !STR.isBlank(defaultValues.get("unitefindef").code)) {
            periofin = defaultValues.get("unitefindef");
            if (periofin.code.indexOf("U_") != 0)
              periofin.code = "U_" + periofin.code;
          }
          gererPret.set("modesai_periodeb", periodeb);
          gererPret.set("modesai_periofin", periofin);
          break;

        case "H":
          //--  Cas du mode saisie en Heures, cas heures : non prevu
          gererPret.set("modesai_periodeb", { code: "H", libelle: "" });
          gererPret.set("modesai_periofin", { code: "H", libelle: "" });
          break;
        case "E":
          //  --Cas du mode saisie en Heures, cas mode : non prevu
          gererPret.set("modesai_periodeb", { code: "S", libelle: "" });
          gererPret.set("modesai_periofin", { code: "E", libelle: "" });
          break;
      }

      this._mapToForm();
    }
    // perderf (affsaip_typedef) n'est pas valorisé: il s'agit du cas ou les périodes ne sont pas typées (H ou U)
    else {
      if (!STR.isBlank(defaultValues.get("modadef")) && !STR.isBlank(defaultValues.get("modadef").code)) {
        // S'il s'agit d'un type en periode continue
        if (defaultValues.get("modadef").code == "P") {
          var periodeb = { code: "", libelle: "" };
          var periofin = { code: "", libelle: "" };
          if (!STR.isBlank(defaultValues.get("unitedebdef")) && !STR.isBlank(defaultValues.get("unitedebdef").code)) {
            periodeb = defaultValues.get("unitedebdef");
            if (periodeb.code.indexOf("U_") != 0)
              periodeb.code = "U_" + periodeb.code;
          }
          if (!STR.isBlank(defaultValues.get("unitefindef")) && !STR.isBlank(defaultValues.get("unitefindef").code)) {
            periofin = defaultValues.get("unitefindef");
            if (periofin.code.indexOf("U_") != 0)
              periofin.code = "U_" + periofin.code;
          }
          gererPret.set("modesai_periodeb", periodeb);
          gererPret.set("modesai_periofin", periofin);
          this._mapToForm();
        }
      }
    }
  },

  checkUnitesCoherence: function(gererPret, callback) {
    var allowedUnite = this.unitesComboColl.findWhere({ code: gererPret.get("unitedeb").code });

    var allowedUniteDeb = null;
    if (!STR.isBlank(gererPret.get("modesai_periodeb")) && !STR.isBlank(gererPret.get("modesai_periodeb").code))
      allowedUniteDeb = this.uniteDebComboColl.findWhere({ code: gererPret.get("modesai_periodeb").code });

    var allowedUniteFin = null;
    if (!STR.isBlank(gererPret.get("modesai_periofin")) && !STR.isBlank(gererPret.get("modesai_periofin").code))
      allowedUniteFin = this.uniteFinComboColl.findWhere({ code: gererPret.get("modesai_periofin").code });

    if (STR.isBlank(allowedUnite)) {
      gererPret.set("unitedeb", { code: "", libelle: "" });
    } else {
      gererPret.get("unitedeb").libelle = allowedUnite.get("libelle");
    }
    if (STR.isBlank(allowedUniteDeb)) {
      gererPret.set("modesai_periodeb", { code: "", libelle: "" });
    } else if (STR.isBlank(gererPret.get("modesai_periodeb").libelle)) { //Set libelle value in order to avoid another call to combo WS
      gererPret.get("modesai_periodeb").libelle = allowedUniteDeb.get("libelle");
    }
    if (STR.isBlank(allowedUniteFin)) {
      gererPret.set("modesai_periofin", { code: "", libelle: "" });
    } else if (STR.isBlank(gererPret.get("modesai_periofin").libelle)) { //Set libelle value in order to avoid another call to combo WS
      gererPret.get("modesai_periofin").libelle = allowedUniteFin.get("libelle");
    }

    if (callback)
      callback();

  },

  /**
   * The View Custom change event, peform logics when the inputs change his value and
   * performs put the new values on the current model with the FormView _change function.
   */
  _changeView: function(event, data) {
    var self = this;
    var target = event.target;
    var gererPret = this.model.get("value");
    var className = event.target.className.split(" ")[0];

    this._change(event, data);
    // Addition default change Logic
    switch (className) {
      case "type.code":
        this._enableModeSaisie();
        this._typeChanged(gererPret);
        break;
      case "perimetre.code":
        //Reset title and header to match the selected perimetre
        this._manageHeader();
        if (this.context && this.context.ctxModeRepresentation && this.context.ctxModeRepresentation == "pop-up") // Pop up
          this._setTitlePopUp();
        //Perimetre is going to be modifiable only in creation mode
        //if it has been changed, it is necessary to reload structures for the current fonctionalite
        var callback = function() {
          self._renderSelChemins();
        };
        //En création : autant de lignes « Libellé de type de structure - champ Elément de structure » que de types de structure « de prêt » présents dans le filtre de l’habilitation de la fonctionnalité
        if (gererPret.isNew())
          this._getStructuresPerTypeEvtAndper(gererPret.get("datedeb"), gererPret, callback);
        //In consultation mode we don't have to update the list of structures because it only depends on la liste des éléments de structure destinataires du prêt
        // Apply new perimetre in context
        this.context.ctxPerimetre = STR.getElValue(gererPret, "perimetre.code");
        break;
      case "motif.code":
        this.motifChanged = true;
        break;
      case "origine.code":
        this.origineChanged = true;
        break;
      case "modesai_periodeb.code":
        this._manageUniteDeb(data);
        break;
      case "modesai_periofin.code":
        this._manageUniteFin(data);
        break;
      default:
        break;
    }

  },

  _mapToForm: function() {
    if (this.$el) {
      var self = this;
      var gererPret = this.model.get("value");
      if (gererPret == null) {
        this.$el.find(".gererpret-form-inputs").hide();
      } else {
        this.$el.find(".gererpret-form-inputs").show();
        gererPret.setHabContext(this.model.getHabContext());
        var fieldset = this.$el.find("fieldset");

        var renderers = {};
        renderers["collabremplace"] = function(value) {
          var collabRemplace = "";
          if (!STR.isBlank(self.context.ctxCollabReference) && !STR.isBlank(self.context.ctxCollabReference.matricule)) {
            collabRemplace = self.context.ctxCollabReference.nom + " " + self.context.ctxCollabReference.prenom;
            if (STR.isBlank(self.diver) || self.diver === "matric") {
              collabRemplace += " (" + self.context.ctxCollabReference.matricule + ")";
            } else {
              collabRemplace += " (" + self.context.ctxCollabReference.matric_aux + ")";
            }
          } else if (!STR.isBlank(gererPret.get("collabremplace")) && !STR.isBlank(gererPret.get("collabremplace").matric)) {
            collabRemplace = gererPret.get("collabremplace").nom + " " + gererPret.get("collabremplace").prenom;
            if (STR.isBlank(self.diver) || self.diver === "matric") {
              collabRemplace += " (" + gererPret.get("collabremplace").matric + ")";
            } else {
              collabRemplace += " (" + gererPret.get("collabremplace").matricaux + ")";
            }
          }
          return collabRemplace;
        };
        this.$el.find(".collabremplace").attr("readonly", true);

        // Error management
        gererPret.off("invalid");
        gererPret.on("invalid", this._showValidationErrors, this);

        // Clear current validation erros
        this._cleanValidationErrors();

        this._panelStateRender(gererPret);

        // Manage Delorants Labels
        this._mapMultiLabels();

        //Dynamic Data
        this._LoadDynamicFieldData();

        this._mapModelToForm(fieldset, gererPret, renderers, true);

        //Manual Label Linking
        FORMS.linkLabelsToInputs(this.$el.find(".typeSaisie div[data-bloc-name='D']"));
        FORMS.linkLabelsToInputs(this.$el.find(".typeSaisie div[data-bloc-name='P']"));
        FORMS.linkLabelsToInputs(this.$el.find(".typeSaisie div[data-bloc-name='R']"));
        FORMS.linkLabelsToInputs(this.$el.find(".modeSaisie"));
        FORMS.linkLabelsToInputs(this.$el.find(".beginning-form"));
        FORMS.linkLabelsToInputs(this.$el.find(".end-form"));

        this._manageUniteDeb(STR.getElValue(gererPret, "modesai_periodeb.code"));
        this._manageUniteFin(STR.getElValue(gererPret, "modesai_periofin.code"));

        this._manageModeSaisie();

        if (gererPret.isNew()) {
          fieldset.find(".type\\.code").focus();
        }

        // Map structures chemins
        this._mapStructures();

        if (this.disabled == true) {
          fieldset.find("label.required").css("background", "none");
        }
        this._updateVisibiliteOrigine();
      }
    }
  },
  /**
   * Map the multi Labels with the model data
   */
  _mapMultiLabels: function() {
    var gererPret = this.model.get("value");

    this.typeSaisie.setLabelByName(gererPret.get("typesaisie"));
    this.modeSaisie.setLabelByName(gererPret.get("modesaisie"));
  },

  _manageModeSaisie: function() {
    var gererPret = this.model.get("value");
    if (gererPret.get("typesaisie") == "P") {
      this.$el.find(".modeSaisie").hide();
    } else {
      this.$el.find(".modeSaisie").show();
    }
  },

  /**
   * Map structures to form
   */
  _mapStructures: function() {
    var self = this;
    var gererPret = this.model.get("value");
    var structures = gererPret.get("structures");
    if (structures != null && structures.length > 0) {
      _.each(structures, function(structure) {
        var structid = structure.type;
        var itemid = structure.element;
        var cheminView = _.find(self.listeCheminViews, function(cheminView) {
          return cheminView.structid == structid;
        });

        if (!STR.isBlank(cheminView) && !STR.isBlank(itemid)) {
          //Other cases
          var item = new SelecteurCheminItemModel({
            "structid": structid,
            "elementCode": itemid
          });

          cheminView.addValue(item, function(response) {
            var obj = {};
            obj.structcode = structid;
            obj.elementcode = itemid;
          });
          //Set width
          if (cheminView.readonly == true)
            cheminView.$el.find("input").css("width", "200px");
        }

      });
    }
  },

  //callback chemin
  _responseCallbackChemin: function(data, index, structid) {
    var self = this;
    _.each(this.model.get("value").get("structures"), function(struct) {
      if (struct.type == structid)
        struct.element = data.code;
    });
  },

  _manageUniteDeb: function(value) {
    if (value == "H") {
      this.$el.find(".heuredeb-container").show();
    } else {
      this.$el.find(".heuredeb-container").hide();
    }
  },

  _manageUniteFin: function(value) {
    if (value == "H") {
      this.$el.find(".heurefin-container").show();
    } else {
      this.$el.find(".heurefin-container").hide();
    }
  },

  /**
   * Create the Workflow Composant and atach to the form
   */
  _manageWKFHisto: function(historique) {
    var histo = STR.isBlank(historique) ? true : historique;
    if (this.context.ctxHistoriqueWKF == true && histo == true && this.context.ctxModeInitialisation === "Consultation") {
      this.contextComponentWkf = {
        ctxEcran: this.context.ctxEcran,
        ctxHabilitation: this.context.ctxHabilitation.HabilitationAcces,
        ctxTypeEvenement: "PRET", //Define is this is PRET
        ctxRefEvenement: STR.getElValue(this.model.get("value"), "evenement"),
        ctxDemId: STR.getElValue(this.model.get("value"), "iddemande"),
        ctxModeRestitution: "Imbrique",
        ctxActivModeSynth: true,
        ctxModeDefaut: "Replie",
        ctxModeRech: false
      };

      this.composantWorkflow = new WkfhistoBlockView();
      $(this.el).find(".gererprets-wkfhisto").show();
      $(this.el).find(".gererprets-wkfhisto").html(this.composantWorkflow.render().el);
      this.composantWorkflow.setContext(this.contextComponentWkf);
    } else {
      $(this.el).find(".gererprets-wkfhisto").hide();
    }
  },

  _showPieceJointe: function() {

    var maxPieces = !STR.isBlank(this.workflow.maxPieces) ? this.workflow.maxPieces.get("maxi") : 0;

    if (this.model && this.model.get("value") && this.model.get("value").get("piecesjointes") && this.model.get("value").get("piecesjointes").length > 0) {
      this.context.ctxPieceJointe = true;
      this.model.get("value").set("indicateurpj", true);
    }

    //Dont' show pieces jointes when creating or in ajout mode in contexte multiple (ctxListeCollab renseigne)
    if (this.context.ctxPieceJointe == true && !STR.isBlank(this.context.ctxCollab) && this.context.ctxUtilisateur == "Responsable" &&
      maxPieces > 0 && !(this.context.ctxModeInitialisation === "Ajout" && !STR.isBlank(this.context.ctxListeCollab))) {
      var self = this;
      this.pieceJointeModified = false;

      //Prepare habilitations
      var habilitations = {};
      habilitations.HabilitationAcces = this.context.ctxHabilitation.HabilitationAcces;
      var habilitationGestion = this._getHabilitationGestion(this.model.get("value"));
      habilitations.HabilitationGestion = habilitationGestion;
      habilitations.HabilitationAcces = this.context.ctxHabilitation.HabilitationAcces;
      var refEvenement = STR.getElValue(this.model.get("value"), "evenement");
      var context = {
        ctxEcran: this.context.ctxEcran,
        ctxHabilitation: habilitations,
        ctxRefEvenement: refEvenement,
        ctxModeRestitution: "Imbrique",
        ctxModeDefaut: "Replie",
        ctxActionsPossibles: this.disabled ? [] : ["Creer", "Supprimer"],
        ctxGestionCollab: this.context.ctxCollab
      };

      // Obtain the max number of pieces availables
      // Create the component
      this.pieceJointe = new BlockView({ context: context, type: "PJPRT", maxPieces: maxPieces });

      //overwrite managebuttons function to check if the absence can be modified or not.
      this.pieceJointe._oldManageButtons = this.pieceJointe._manageButtons;
      this.pieceJointe._manageButtons = function(event) {
        self._isModifiable(event, function() {
          self.pieceJointe._oldManageButtons(event);
        });
      };

      $(this.el).find(".gererprets-piecesjointes").html(this.pieceJointe.render().el);
      this.pieceJointe.setContext(context);
      this.pieceJointe.setPiecesJointes(this.model.get("value").get("piecesjointes"));

      this.pieceJointe.model.on("piecejointeChanged", function(fresh, action) {
        self.model.trigger("form:edited");
        self.pieceJointeModified = true;
      }, this);

      $(this.el).find(".gererprets-piecesjointes").show();
    } else {
      $(this.el).find(".gererprets-piecesjointes").hide();
    }
  },

  /**
   * Check if the Form is Modifiable
   */
  _isModifiable: function(event, callback) {
    this.mouseUpDone = false;
    if (this.context.ctxModeInitialisation === "Consultation" && !STR.isBlank(this.hasBeenFocused) && !this.hasBeenFocused) {
      var target = this.model.get("value");

      this.hasBeenFocused = true;
      if (target && target.id) {
        var self = this;
        var isModifiableModel = new GererPretsGestion();

        isModifiableModel.id = target.id;
        isModifiableModel.action = "modifiable";
        var habilitationGestion = this._getHabilitationGestion(this.model.get("value"));
        isModifiableModel.setHabContext(new HabilitationContext({
          onglet: this.context.ctxEcran,
          fonc_cour: habilitationGestion,
          nat_gest: ""
        }));

        if (this.context && !STR.isBlank(this.context.ctxDonneeSimulee) && this.context.ctxDonneeSimulee === true) {
          isModifiableModel.activeSimulation = true;
        }

        isModifiableModel.fetch({
          success: function(model, response) {
            if (response && response.result === "ok") {
              if ($(event.target).hasClass("phx-combobox-input")) {
                $(event.target.parentElement).find(".phx-combobox-button").click();
              } else {
                if ($(event.target).hasClass("ui-datepicker-trigger")) {
                  if (self.mouseUpDone === true) {
                    $(event.target).click();
                  }
                } else {
                  $(event.target).click();
                }
              }
              if (callback) {
                callback();
              }
            }
          },
          error: function(model, response) {
            $(event.target).mouseout();
            self.workflow.btnBarModel.set("mode", "R");
            self.workflow.btnBarModel.trigger("disable:delete");
            self.workflow.btnBarModel.trigger("disable:valider");
            self._disableForm();
          }
        });
        $(document).one("mouseup", function() {
          self.mouseUpDone = true;
        });
      }
    } else {
      if (callback) {
        callback();
      }
    }
  },

  /**************************************************/
  /** INFORMATIONS COMPLEMENTARIES PERSONALIZABLES **/
  /**************************************************/
  /**
   * Creates all the combos or input fields or date fields when are necessary.
   */
  _LoadDynamicFieldData: function() {
    this.combos = [];
    //Reset the component
    this.$el.find(".gererprets-infocomp").html("");

    var gererPret = this.model.get("value");
    var infoCompArray = gererPret.get("infocomp");
    var infoCompOrder = _.sortBy(infoCompArray, function(item) { return item.ordre; });
    var self = this;
    _.each(infoCompOrder, function(it, key) {
      self._manageFields(it, it.code);
    });
    if (this.disabled == true ||
      (this.context.ctxUtilisateur == "Collaborateur" || (this.context.ctxActionsPossibles && this.context.ctxModeInitialisation === "Consultation" && this.context.ctxActionsPossibles.indexOf("Modifier") == -1))) {
      this._disableInfoComp();
    } else {
      this._enableInfoComp();
    }
  },

  _manageFields: function(fieldData, fieldName) {
    var self = this;
    if (!STR.isBlank(fieldData.codif)) {
      // PAINT A COMBO
      var combo = self._createCombo(fieldName, fieldData.codif);
      self._paintField(fieldData, combo);
    } else {
      // PAINT AN INPUT TEXT
      var input = this._createInputText(fieldName, fieldData.valeur, 14, 10);
      this._paintField(fieldData, input);
    }
  },

  /**
   * Paints the input fields or combos and their labels.
   */
  _paintField: function(fieldData, fieldToPaint) {
    var fieldName = fieldData.code;
    var pre = "infocomp";

    var $domEl = $("<span>").attr("id", fieldName);
    var $labelEl = $("<label>").attr("for", pre + "." + fieldName + ".valeur");
    if (fieldData.oblig) $labelEl.addClass("required");
    $domEl.append($labelEl);

    this.$el.find(".gererprets-infocomp").append($domEl);

    if (!STR.isBlank(fieldData.codif)) {
      if (fieldToPaint.type != "select-one") {
        $domEl.append(fieldToPaint.render().el);
      } else {
        $domEl.append(fieldToPaint);
      }
      $labelEl.text(fieldData.libelle);
    } else {
      // Paint the input fields
      $domEl.append(fieldToPaint);
      // Add typeLong mask and events changed in # customer 146014
      if (fieldData.type == "N") {
        if (fieldData.masque) {
          $domEl.find("input." + pre + "\\." + fieldName + "\\.valeur").addClass("typeCustom");
          this.typeFormatByClass[pre + "." + fieldName + ".valeur"] = fieldData.masque;
        } else {
          $domEl.find("input." + pre + "\\." + fieldName + "\\.valeur").addClass("typeLong");
        }
        $domEl.find("input." + pre + "\\." + fieldName + "\\.valeur").removeAttr("maxlength");
      }

      //fills the dates if needed
      if (fieldData.type == "D") {
        FORMS.setDatepicker(this, "." + pre + "\\." + fieldName + "\\.valeur");
        $domEl.find("." + pre + "\\." + fieldName + "\\.valeur").addClass("typeDate");
        $domEl.find("." + pre + "\\." + fieldName + "\\.valeur").removeAttr("maxlength");
        var errorContainer = "<span class='" + pre + "." + fieldName + ".valeur" + "-error-container'></span>";
        $domEl.append(errorContainer);
      }

      if (fieldData.type == "J") {
        $domEl.find("input." + pre + "\\." + fieldName + "\\.valeur").addClass("typeJour");
      }
      $labelEl.text(fieldData.libelle);
    }
    $domEl.append($("<br>"));

    // evaluate if this must be hidden or not
    if (fieldData.libelle && fieldData.libelle.indexOf(".") == 0) {
      $domEl.hide();
    } else {
      $domEl.show();
    }

  },

  /**
   * Creates and returns a combo field.
   */
  _createCombo: function(idCombo, codif) {
    var combo = new ComboBoxView2({
      ws: new ComplementComboColl({ codif: codif }),
      width: 200,
      name: "infocomp." + idCombo + ".valeur",
      habContext: this.model.getHabContext(),
      optionsRender: function(value) {
        if (!value || STR.isBlank(value.code)) {
          return "";
        }
        return value.libelle + " (" + value.code + ")";
      },
      preprocessBeforeSetItem: function(item) {
        return { "code": item.valeur, "libelle": "" };
      }
    });
    this.combos[idCombo] = combo;

    this.listenTo(combo, "comboEdited", this._formEdited);
    return combo;
  },

  /**
   * Creates and returns an input text field.
   */
  _createInputText: function(idInput, value, size, maxLength) {
    var input = $("<input>").addClass("infocomp." + idInput + ".valeur").attr({ "type": "text", "size": size, "maxlength": maxLength });
    return input;
  },

  savePretCollab: function() {
    var self = this;
    var target = this.model.get("value");

    target.activeSimulation = !STR.isBlank(this.context.ctxSimulation);

    target.set("collaborateur", { matric: this.context.ctxCollab.matricule });
    if (STR.isBlank(target.get("collabremplace").matricule) && !STR.isBlank(this.context.ctxCollabReference)) {
      var datedeb, datefin;
      if (!STR.isBlank(this.context.ctxCollabReference.datedeb)) {
        datedeb = this.context.ctxCollabReference.datedeb;
      } else {
        datedeb = this.context.ctxValeursCreation.PretDateDebut;
      }
      if (!STR.isBlank(this.context.ctxCollabReference.datefin)) {
        datefin = this.context.ctxCollabReference.datefin;
      } else {
        datefin = this.context.ctxValeursCreation.PretDateFin;
      }

      target.set("collabremplace", {
        matric: this.context.ctxCollabReference.matricule,
        matricaux: this.context.ctxCollabReference.matric_aux,
        nom: this.context.ctxCollabReference.nom,
        prenom: this.context.ctxCollabReference.prenom,
        datedeb: datedeb,
        datefin: datefin
      });
    }

    target.action = "demande";
    var saveAction = "update";
    if (target.isNew()) {
      saveAction = "create";
    }

    var saveCallback = function(fresh) {
      target.store();
      self.savePiecesJointes(fresh, function() {
        if (self.context.ctxModeRepresentation === "pop-up") {
          self.parent.close();
        }
        self.model.trigger("pretChanged", fresh, saveAction);

      });
    };
    var errorSaveCallback = function(model, x, h) {
      //if(self._pretParPeriode == false){
      if (!STR.isBlank(model.validationError)) {
        // server does not send the error in datedebut (only in datefin)
        // so we create the error manually in the save process.
        if (!STR.isBlank(model.validationError.errors.datefin)) {
          model.validationError.errors.datedeb = model.validationError.errors.datefin;
          delete model.validationError.errors.datefin;
          self._showValidationErrors(model, model.validationError);
        }
      }
      //}

    };

    this._manageTypeSaisieDate(target);
    target.save(null, {
      success: saveCallback,
      error: errorSaveCallback
    });
  },

  _manageTypeSaisieDate: function(target) {
    //In order to send datefin=datedeb for type saisie date
    if (target.get("typesaisie") == "D") {
      var datedeb = target.get("datedeb");
      target.set("datefin", datedeb);
    }
  },
  savePiecesJointes: function(pret, callback) {
    if (pret && this.pieceJointeModified === true) {
      var evenement = pret.get("evenement");
      this.pieceJointe.applyPendingActions(evenement, callback);
    } else {
      if (callback) {
        callback();
      }
    }
  },
  newPretCollab: function() {
    var self = this;
    var target = new GererPretsModel();
    var habilitationGestion = this._getHabilitationGestion(target);
    this.model.updateHabContext({ fonc_cour: habilitationGestion, nat_gest: "A" });

    //Initialize collaborateur
    target.set("collaborateur", { matric: this.context.ctxCollab.matricule });
    var matric = objs.appRt.workflow.authModel.get("matricule");
    //Initialize perimetre to context perimetre
    STR.setElValue(target, "perimetre.code", this.context.ctxPerimetre);
    STR.setElValue(target, "emprunt", this.context.ctxTypeEvtGere == "E" ? true : false); // si ctxTypeEvtGere = Emprunt sinon faux

    this.model.trigger("mode:create");

    this._enableForm();
    this.model.set("value", target);
    this.model.trigger("form:edited", true);
    var gererPret = this.model.get("value");
    var callback = function() {
      self._manageWKFHisto(false);
      self._showPieceJointe();
      self._renderSelChemins();
      self._enableDisableFields();
      self._initChangeFlags();
    };

    this._getStructuresPerTypeEvtAndper(gererPret.get("datedeb"), gererPret, callback);
  },

  revertView: function() {
    if (this.context && this.context.ctxModeRepresentation == "pop-up" && this.context.ctxModeInitialisation == "Ajout") {
      this.parent.close();
    } else {
      this.hasBeenFocused = false;

      this.workflow.btnBarModel.trigger("enable:valider");
      this.model.updateHabContext({ fonc_cour: this.context.ctxHabilitation.HabilitationAcces, nat_gest: "" });
      if (this.model.get("value").isNew()) {
        var previous = this.model.previous("value");
        if (previous) {
          this.model.unset("value", { silent: true });
          this.model.set("value", previous);
          this.workflow.btnBarModel.set("mode", "R");
        } else if (STR.isBlank(this.context.ctxIdentifiantEvt)) {
          this.model.set("value", null);
          this.workflow.btnBarModel.set("mode", "C");
        }
      } else {
        this.model.get("value").revert();
        this.model.trigger("change:value");

        this.workflow.btnBarModel.set("mode", "R");
      }
      this._manageWKFHisto(this.context.ctxHistoriqueWKF);
      this._showPieceJointe();
      this._enableDisableFields();

      // Set WAI-ARIA attributes
      $(this.el).find(".type\\.code").focus();
    }
  },

  deletePretCollab: function(commentaire) {
    var self = this;
    var target = this.model.get("value");
    var droit = target.get("droit");
    var habilitationGestion = this._getHabilitationGestion(this.model.get("value"));
    this.model.updateHabContext({ fonc_cour: habilitationGestion, nat_gest: "S" });
    if (!droit) {
      droit = "N";
    }
    target.action = "demande";

    if (this.context.ctxDonneeSimulee) {
      target.activeSimulation = true;
    }

    var destroyCallback = function() {
      if (self.context.ctxModeRepresentation === "pop-up") {
        self.parent.close();
      }
      self.model.trigger("pretChanged", target, "delete");
    };

    if (!STR.isBlank(commentaire)) {
      target.set("commentaire", commentaire);
    } else {
      target.set("commentaire", " ");
      target.set("droit", "N");
    }

    target.destroy({
      contentType: 'application/json',
      url: Configuration.restRoot + "/rest/composant/pret/" + target.action + "/" + encodeURIComponent(target.id) + "/" + encodeURIComponent(target.get("commentaire")) + "/" + encodeURIComponent(droit),
      success: destroyCallback,
      error: destroyCallback,
      wait: true
    });
  },

  deletePretFromMenuButton: function(pret) { // EVO 524
    pret.setHabContext(this.model.getHabContext());
    this.model.set("value", pret, { silent: true });
    this.btnBar.model.trigger("btn:click", "delete");
  },

  accepterPretCollab: function(commentaire, profil) {
    var self = this;
    var values = this.model.get("value");
    var valuesProfil = null;
    var l_profil = {
      code: "",
      libelle: ""
    };
    var id = values.get("id");
    var droit = "";
    var target = new GererPretsGestion();
    var saveCallback = function(fresh) {
      if (self.context.ctxModeRepresentation === "pop-up") {
        self.parent.close();
      }
      self.model.trigger("pretChanged", self.model.get("value"), "update");
    };

    if (this.profilModel && this.profilModel.get("profils")[0]) {
      valuesProfil = this.profilModel.get("profils")[0];
      l_profil = {
        code: !STR.isBlank(profil) ? profil : "",
        libelle: !STR.isBlank(valuesProfil.libelle) ? valuesProfil.libelle : ""
      };
    } else if (!STR.isBlank(this.model.get("value").profil)) {
      l_profil = {
        code: this.model.get("value").profil.code,
        libelle: this.model.get("value").profil.libelle
      };
    }

    if (!STR.isBlank(values.get("droit"))) {
      droit = values.get("droit");
    } else {
      droit = "O";
    }

    target.setHabContext(new HabilitationContext({
      onglet: this.context.ctxEcran,
      fonc_cour: this.context.ctxHabilitation.HabilitationValidation,
      nat_gest: ""
    }));

    target.action = "accepter";
    target.id = id;
    target.set("commentaire", commentaire);
    target.set("droit", droit);
    target.set("profil", l_profil);

    target.save(null, {
      success: saveCallback,
      error: saveCallback
    });
  },

  accepterPretFromMenuButton: function(pret) { // EVO 524
    this.id = encodeURIComponent(pret.id);
    pret.setHabContext(this.model.getHabContext());
    this.model.set("value", pret, { silent: true });
    this.model.get("value").set("id", this.id);

    this.btnBar.model.trigger("btn:click", "accepter");
  },

  refuserPretCollab: function(commentaire, profil) {
    var self = this;
    var values = this.model.get("value");
    var id = values.get("code");
    var profil = values.get("profil");

    if (!STR.isBlank(values.get("droit"))) {
      var droit = values.get("droit");
    } else {
      var droit = "N";
    }

    var target = new GererPretsGestion();
    target.setHabContext(new HabilitationContext({
      onglet: this.context.ctxEcran,
      fonc_cour: this.context.ctxHabilitation.HabilitationValidation,
      nat_gest: ""
    }));

    target.action = "refuser";
    target.id = id;
    target.set("commentaire", commentaire);
    target.set("droit", droit);
    target.set("profil", {});
    target.set("profil", profil);

    var saveCallback = function(fresh) {
      self.model.trigger("pretChanged", self.model.get("value"), "update");
      if (self.context.ctxModeRepresentation === "pop-up") {
        self.parent.close();
      }
    };
    target.save(null, {
      success: saveCallback,
      error: saveCallback
    });
  },

  refuserPretFromMenuButton: function(pret) { // EVO 524
    this.id = encodeURIComponent(pret.id);
    pret.setHabContext(this.model.getHabContext());
    this.model.set("value", pret, { silent: true });
    this.btnBar.model.trigger("btn:click", "refuser");
  },

  valider: function(callback) {
    var habilitationGestion = this._getHabilitationGestion(this.model.get("value"));
    this.model.updateHabContext({ fonc_cour: habilitationGestion, nat_gest: "" });
    var target = this.model.get("value");

    target.action = "validesaisie";
    target.setHabContext(this.model.getHabContext());

    var saveCallback = function(model, response) {
      if (response.result === "ok") {
        if (callback) {
          callback();
        }
      }
    };
    target.save(null, {
      success: saveCallback,
      error: saveCallback
    });
  },

  _updateVisibiliteOrigine: function() {
    var l_posOrigin = this.$el.find(".originActif"); //label and combo
    var l_contextActif = GLOBAL_DATA.contextActif.contextActifPret;
    //La présence de cette information dans le formulaire ne sera effective que si,
    // pour le type d’événement « Prêt », la saisie de l’origine de la demande est autorisée.

    if (STR.isBlank(l_contextActif) || l_contextActif.get("active") !== true) {
      l_posOrigin.hide();
    } //Il n'est pas necessaire l'action "show" parce qu'il vient de le faire(la création)
  }
});
