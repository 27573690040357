import { AffectationComplemColl } from '../models/affectationComplem.collection.js';
import { AffectationEnCoursModel } from '../models/affectationEnCours.model.js';
import { AffectationPrincipColl } from '../models/affectationPrincip.collection.js';
import { AffectationRemplaColl } from '../models/affectationRempla.collection.js';
import { DataGridModel } from '../../../../../core/grids/data_grid/dataGrid.model.js';
import { DataGridView } from '../../../../../core/grids/data_grid/dataGrid.view.js';
import { GLOBAL_DATA } from '../../../../../globalData.js';
import { HABILITATION } from '../../../../../utils/habilitation.js';
import { HabilitationContext } from '../../../../../core/models/habilitationContext.js';
import { i18n } from '../../../../../i18n.js';
import { LICENCE } from '../../../../../utils/licence.js';
import { objs } from '../../../../../objectsRepository.js';
import { SelecteurCheminItemModel } from '../../../../../core/components/selecteur_chemin/selecteurCheminItem.model.js';
import { SelecteurCheminView } from '../../../../../core/components/selecteur_chemin/selecteurChemin.view.js';
import { STR } from '../../../../../utils/str.js';
import { SYNC } from '../../../../../utils/sync.js';
import { TYPE } from '../../../../../tda/tda.js';
import { TypeStructureModel } from '../models/typeStructure.model.js';
import TPL_baffec_briqueAffectationEnCours from '../briqueAffectationEnCours.tpl.html';

export var AffectationEnCoursView = Backbone.View.extend({

  events: {
    "click .table_BriqueAffectationEnCours_link": "_linkToValidation",
    'render': 'refreshBrique'
  },

  initialize: function(options) {
    this.usePopulation = false;
    if (options && options.usePopulation) {
      this.usePopulation = options.usePopulation;
    }
    this.template = TPL_baffec_briqueAffectationEnCours;
    this.model = new AffectationEnCoursModel();

    this.habContext = new HabilitationContext({
      onglet: "baffec",
      fonc_cour: "COL_AFFPRINC.V",
      nat_gest: ""
    });

    this.modelAffPrincipal = new AffectationPrincipColl();
    this.modelAffPrincipal.usePopulation = this.usePopulation;
    this.tableP = this._initTablePrincip();

    // Table remplacements EVO 450
    this.modelRemplacements = new AffectationRemplaColl();
    this.modelRemplacements.usePopulation = this.usePopulation;
    this.tableR = this._initTableRempl();
    // Table remplacements EVO 450

    // Flag to know if the affectation complementaire is activated
    this.infoCompActivated = HABILITATION.canView("COL_AFFSECOND.V") || HABILITATION.canView("COL_PRET.V");
    if (this.infoCompActivated) {
      this.habContext = new HabilitationContext({
        onglet: "baffec",
        fonc_cour: {
          "affsec": "COL_AFFSECOND.V",
          "affexc": "COL_PRET.V"
        },
        nat_gest: ""
      });
      this.tableC = this._initTableComplem();
    }

    this.StrutureP = new TypeStructureModel();
    this.strutureHabContext = new HabilitationContext({
      onglet: "baffec",
      fonc_cour: "PAR_TYPESTR.V",
      nat_gest: ""
    });
    this.StrutureP.setHabContext(this.strutureHabContext);

    this.hasPrets = false;
    this.hasAffect = false;
    this.isStructurePrincipal = true;

    // by default all are activated.
    this.ctxTypesAffectations = ["Affectation principale", "Affectations secondaires", "Prêts"];
  },

  _initTablePrincip: function() {
    var tableModel = new DataGridModel({ coll: this.modelAffPrincipal });
    var tblColumns = [
      { title: i18n.t('common:baffec.colonneDu'), property: "datedeb", width: 200 },
      { title: i18n.t('common:baffec.colonneAu'), property: "datefin", width: 200 },
      { title: i18n.t('common:baffec.rattachementPrincipal'), property: "itemid", width: 300 }
    ];
    var table = new DataGridView({
      id: "baffec_tablePrincip",
      columns: tblColumns,
      model: tableModel,
      showFilteredRowsInTitle: false,
      title: i18n.t('common:baffec.affectationPrincipal'),
      gridHeightType: "auto",
      maxVisibleRows: 10,
      habilitations: "COL_AFFPRINC.V"
    });
    tableModel.coll.usePopulation = this.usePopulation;
    table.setSortableColumns([]);
    //To show a different value in the cell
    table.cellRenderers["datedeb"] = function(model) {
      if (model.get("datedeb")) {
        return TYPE.DATE.format(model.get("datedeb"), TYPE._getFormatByCode("DATE_A"));
      }
    };

    table.cellRenderers["datefin"] = function(model) {
      if (model.get("datefin")) {
        return TYPE.DATE.manageInfinity(model.get("datefin"), TYPE._getFormatByCode("DATE_A"));
      }

    };

    table.cellRenderers["itemid"] = function(model) {
      var structCode = model.get("structid");
      var self = this;

      this.structureSelected = new SelecteurCheminView({
        name: "itemid",
        listRenderStyle: 1, //format
        height: 500,
        width: 850,
        structid: structCode, //code
        datedebut: model.get("datedeb"),
        datefin: model.get("datefin"),
        partial: false,
        modeComplet: false,
        racine: false,
        nonrattachable: false,
        readonly: true,
        title: model.get("itemlib") + " (" + model.get("itemcode") + ")"
      });

      var item = new SelecteurCheminItemModel({
        "structid": structCode,
        "date": model.get("datedeb") ? model.get("datedeb") : TYPE.DATE.INITIAL,
        "elementCode": model.get("itemid") ? model.get("itemid") : ""
      });

      if (!STR.isBlank(item.get("elementCode")))
        this.structureSelected.addValue(item);

      var result = this.structureSelected.render().el;

      return result;
    };
    // set habilitations context that the table collection will use
    tableModel.coll.setHabContext(this.habContext);
    return table;
  },

  // table of 'affectation remplacements' EVO 450
  _initTableRempl: function() {
    var tableModel = new DataGridModel({ coll: new AffectationRemplaColl() });
    var tblColumns = [
      { title: "", property: "remplacement", width: 600 }
    ];

    var table = new DataGridView({
      id: "baffec_tableRempla",
      columns: tblColumns,
      model: tableModel,
      showFilteredRowsInTitle: false,
      selectable: false,
      habilitations: "COL_PRET.V",
      //title : i18n.t('common:baffec.affectationRemplacement'),
      gridHeightType: "auto",
      maxVisibleRows: 10
    });
    tableModel.coll.usePopulation = this.usePopulation;
    table.setSortableColumns([]);
    var self = this;

    table.cellRenderers["remplacement"] = function(model) {
      var newCell = self._formattedRemplacementLines(model);
      return newCell;
    };

    // set habilitations context that the table collection will use
    tableModel.coll.setHabContext(new HabilitationContext({
      onglet: "baffec",
      fonc_cour: "COL_PRET.V",
      nat_gest: ""
    }));

    return table;
  },

  _formattedRemplacementLines: function(model) {
    var text = [];
    var temporelle = "";
    var motif = "";
    var libelle = "";
    var today = TYPE.DATE.parse(TYPE.DATE.dateToStr(SYNC.getServerDate())).val;

    // Indication temporelle
    if (model.get("pret") && model.get("pret").typesaisie) {
      if (model.get("pret").typesaisie == "C" &&
        (STR.isBlank(model.get("pret").heuredeb) && STR.isBlank(model.get("pret").heurefin))) {

        if (model.get("pret").unitedeb && model.get("pret").unitedeb.code === "D" &&
          (model.get("pret").unitefin && model.get("pret").unitefin.code == model.get("pret").unitedeb.code)) {
          temporelle += "<span class='ui-phx-ihm-texte-application'>" + i18n.t('messages:GT_1184', { "1": TYPE.DATE.format(model.get("pret").datedeb, TYPE._getFormatByCode("DATE_A")), "2": TYPE.DATE.format(model.get("pret").datefin, TYPE._getFormatByCode("DATE_A")) }) + "</span>";
        } else if (model.get("pret").unitedeb && model.get("pret").unitedeb.code !== "D" &&
          (model.get("pret").unitefin && model.get("pret").unitefin.code !== "D")) {
          temporelle += "<span class='ui-phx-ihm-texte-application'>" + i18n.t('messages:GT_1187', { "1": TYPE.DATE.format(model.get("pret").datedeb, TYPE._getFormatByCode("DATE_A")), "2": model.get("pret").unitedeb.libelle, "3": TYPE.DATE.format(model.get("pret").datefin, TYPE._getFormatByCode("DATE_A")), "4": model.get("pret").unitefin.libelle }) + "</span>";
        }
      } else if (model.get("pret").typesaisie === "D" &&
        (model.get("datedeb") <= today <= model.get("datefin"))) {
        if (model.get("pret").modesaisie === "UNITE" &&
          (model.get("pret").unitedeb && model.get("pret").unitefin && model.get("pret").unitedeb.code == "D" && model.get("pret").unitefin.code == model.get("pret").unitedeb.code)) {
          temporelle += "<span class='ui-phx-ihm-texte-donnees'>" + i18n.t('messages:GT_1181', { "1": TYPE.DATE.format(model.get("pret").datedeb) }) + "</span>";
        } else if (model.get("pret").modesaisie === "UNITE" &&
          (model.get("pret").unitedeb && model.get("pret").unitefin && model.get("pret").unitedeb.code !== "D" && model.get("pret").unitefin.code == model.get("pret").unitedeb.code)) {
          temporelle += "<span class='ui-phx-ihm-texte-donnees'>" + i18n.t('messages:GT_1180', { "1": TYPE.DATE.format(model.get("pret").datedeb, TYPE._getFormatByCode("DATE_A")), "2": model.get("pret").unitedeb.libelle }) + "</span>";
        } else if ((model.get("pret").modesaisie === "PLH")) {
          temporelle += "<span class='ui-phx-ihm-texte-donnees-application'>" +
            i18n.t('messages:GT_1721', {
              "1": "<span class='ui-phx-ihm-texte-donnees'>" + TYPE.HOUR_MINUTE.format(model.get("pret").heuredeb) + "</span>",
              "2": "<span class='ui-phx-ihm-texte-donnees'>" + TYPE.HOUR_MINUTE.format(model.get("pret").heurefin) + "</span>",
              "3": "(<span class='ui-phx-ihm-texte-donnees'>" + TYPE.DURATION.HOUR_MINUTE.format(model.get("pret").duree) + "</span>"
            }) + "</span>";

        } else if (model.get("pret").modesaisie === "DUREE") {
          temporelle += "<span class='ui-phx-ihm-texte-donnees'>(" + TYPE.DURATION.HOUR_MINUTE.format(model.get("duree")) + ")</span>";
        } else if (model.get("pret").modesaisie === "POURCENT") {
          temporelle += "<span class='ui-phx-ihm-texte-donnees'>" + TYPE.PERCENTAGE.format(model.get("pret").pourcentage, null) + "</span>";
        }
      }
    }
    if (!_.isEmpty(temporelle)) {
      text.push(temporelle.replace(',', ''));
    }

    // Motif du prêt
    var idCollab = GLOBAL_DATA.paramDivers.get("idCollab").get("valeur").replace('_', '');
    var ctxCode = objs.appRt.workflow.authModel.get("matricule");

    if (_.isEmpty("idCollab")) {
      motif += "<span class='ui-phx-ihm-texte-donnees-alternatif'>" + i18n.t('messages:GT_1849') + " " + model.get("pret").collaborateur.nom + " " + model.get("pret").collaborateur.prenom + " (" + model.get("pret").collaborateur.matric + ")</span>";
    } else {
      motif += "<span class='ui-phx-ihm-texte-donnees-alternatif'>" + i18n.t('messages:GT_1849') + " " + model.get("pret").collaborateur.nom + " " + model.get("pret").collaborateur.prenom + " (" + model.get("pret").collaborateur[idCollab] + ")</span>";
    }

    if (!_.isEmpty(motif)) {
      text.push(motif);
    }

    // Libelle et code du rattachement
    // Faltan campos EltStructCode y EltStructLibelle
    libelle += "<span class='ui-phx-ihm-texte-donnees'>" + model.get("pret").itemlib + "(" + model.get("pret").itemcode + ")</span>";
    if (!_.isEmpty(libelle)) {
      text.push(libelle);
    }

    return text.join(", ");
  },
  // Table remplacements EVO 450

  /* table of 'affectation complementaire'*/
  _initTableComplem: function() {
    var tableModel = new DataGridModel({ coll: new AffectationComplemColl() });
    var tblColumns = [
      { title: i18n.t('common:baffec.colonneDate'), property: "date", width: 200 },
      { title: i18n.t('common:baffec.colonneAffectation'), property: "itemlib", width: 200 },
      { title: i18n.t('common:baffec.colonneTypeAffectation'), property: "nature", width: 300 }
    ];

    var table = new DataGridView({
      id: "baffec_tableComplem",
      columns: tblColumns,
      model: tableModel,
      showFilteredRowsInTitle: false,
      title: i18n.t('common:baffec.affectationComplementaire'),
      gridHeightType: "auto",
      maxVisibleRows: 10,
      habilitations: "COL_AFFSECOND.V"
    });
    tableModel.coll.usePopulation = this.usePopulation;

    table.setSortableColumns([]);
    var self = this;
    //shall compare if new date is the same lastdate
    var lastdate = "";
    var newdate = "";
    //To show a different value in the cell
    table.cellRenderers["date"] = function(model) {
      newdate = self._formatteddates(model);
      //date shall not be displayed if new line as the same date than last line
      if (newdate == lastdate) {
        return "";
      }
      lastdate = newdate;
      return lastdate;
    };

    table.cellRenderers["itemlib"] = function(model) {
      return self._formattedlines(model);
    };

    table.cellRenderers["nature"] = function(model) {
      return self._formattedlinesNature(model);
    };

    // set habilitations context that the table collection will use
    tableModel.coll.setHabContext(this.habContext);

    return table;
  },

  _formattedlinesNature: function(model) {

    var text = "";
    // EVO 450
    var auxText = "";
    var ctxCode = objs.appRt.workflow.authModel.get("matricule");

    if (model.get("collabremplace") && model.get("collabremplace").matric == ctxCode) {
      auxText += model.get("motif").code;
    } else {
      auxText += model.get("nature").libelle;
    }
    // EVO 450

    text += "<span class='ui-phx-ihm-texte-donnees'>" + auxText + "</span>";
    return text;
  },

  _formatteddates: function(model) { //
    var formattedText = "";
    if (model.get("nature").code == "S") {
      formattedText = TYPE.DATE.format(model.get("datedeb"), TYPE._getFormatByCode("DATE_A"));
    } else if (model.get("nature").code == "P") {
      formattedText = TYPE.DATE.format(model.get("date"), TYPE._getFormatByCode("DATE_A"));
    }
    return formattedText;
  },
  _formattedlines: function(model) { //
    var formattedText = "";
    if (model.get("nature").code === "S") {
      formattedText = this._formattedAffecSecond(model);
    } else if (model.get("nature").code === "P") {
      formattedText = this._formattedPret(model);
    }
    return formattedText;
  },

  _formattedAffecSecond: function(model) {
    var text = "";
    var code = model.get("itemcode");

    if (model.get("couv") === "P") {
      text += "<span class='phx-icon phx-icon-metier ui-phx-part-pris-en-compte'>&asymp;</span>";
    }

    if (model.get("unite").code !== "D") {
      text += "<span class='ui-phx-ihm-texte-donnees'>" + model.get("unite").libelle + ", " + "</span>";
    }

    text += "<span class='ui-phx-ihm-texte-donnees'>" + model.get("itemlib") + " (" + code + ")" + "</span>";

    return text;
  },

  _formattedPret: function(model) {
    var text = "";
    var today = TYPE.DATE.parse(TYPE.DATE.dateToStr(SYNC.getServerDate())).val;
    var datedebInformed = false;

    // Témoin amont

    if (!STR.isBlank(model.get("datefin"))) {
      //  PretDatedebut
      var datedeb = parseInt(model.get("datedeb"));
    }

    if (!STR.isBlank(model.get("date"))) {
      // journée restituée
      var dateRes = parseInt(model.get("date"));
    }

    if (datedeb < dateRes) { // si PretDateDebut < journée restituée ( same behaviour as in Agenda )
      text += "<span style='float:left' class='ui-icon  ui-icon-circle-arrow-w'></span>";
    }

    var idCollab = GLOBAL_DATA.paramDivers.get("idCollab").get("valeur").replace('_', '');
    var ctxCode = objs.appRt.workflow.authModel.get("matricule");

    if (model.get("typesaisie") === "C") {

      if (model.get("datedeb") == today) {
        if (!STR.isBlank(model.get("unitedeb")) && model.get("unitedeb").code != "D") {
          text += "<span style='float:left' class='ui-phx-ihm-texte-donnees'>" + model.get("unitedeb").libelle + "</span>";

          if (!STR.isBlank(model.get("unitefin")) && model.get("unitefin").code != "D") {
            text += " - <span style='float:left' class='ui-phx-ihm-texte-donnees'>" + model.get("unitefin").libelle + "</span>";
          }
        }
      } else if (model.get("datedeb") == model.get("date") && !STR.isBlank(model.get("heuredeb"))) {
        text += "<span style='float:left' class='ui-phx-ihm-texte-application'><span class='ui-phx-ihm-texte-donnees'>" + i18n.t('messages:GT_1747', { "1": TYPE.HOUR_MINUTE.format(model.get("heuredeb")) }) + "</span></span>";
      } else if (model.get("datefin") == model.get("date") && !STR.isBlank(model.get("heurefin"))) {
        text += "<span style='float:left' class='ui-phx-ihm-texte-application'><span class='ui-phx-ihm-texte-donnees'>" + i18n.t('messages:GT_1748', { "1": TYPE.HOUR_MINUTE.format(model.get("heurefin")) }) + "</span></span>";
      }

    } else if (model.get("typesaisie") == "D" &&
      (TYPE.DATE.strToDate(model.get("datedeb")) <= TYPE.DATE.strToDate(today) <= TYPE.DATE.strToDate(model.get("datefin")))) {

      if (model.get("modesaisie") == "UNITE" &&
        (model.get("unitedeb") && model.get("unitefin") && model.get("unitedeb").code != "D" && model.get("unitefin").code == model.get("unitedeb").code)) {
        text += "<span style='float:left' class='ui-phx-ihm-texte-donnees'>" + model.get("unitedeb").libelle + "</span>";

      } else if ((model.get("modesaisie") == "PLH")) {
        text += "<span style='float:left' class='ui-phx-ihm-texte-application'>" + i18n.t('messages:GT_1721', { "1": "<span class='ui-phx-ihm-texte-donnees'>" + TYPE.HOUR_MINUTE.format(model.get("heuredeb")) + "</span>", "2": "<span class='ui-phx-ihm-texte-donnees'>" + TYPE.HOUR_MINUTE.format(model.get("heurefin")) + "</span>", "3": "<span class='ui-phx-ihm-texte-donnees-alternatif'>" + TYPE.DURATION.HOUR_MINUTE.format(model.get("duree")) + "</span>" }) + "</span>";
      } else if ((model.get("modesaisie") == "DUREE")) {
        text += "<span style='float:left' class='ui-phx-ihm-texte-donnees'>(" + TYPE.DURATION.HOUR_MINUTE.format(model.get("duree")) + ")</span>";

      } else if ((model.get("modesaisie") == "POURCENT")) {
        text += "<span style='float:left' class='ui-phx-ihm-texte-donnees'>" + TYPE.PERCENTAGE.format(model.get("pourcentage")) + "</span>";
      }
    }

    // EVO 450
    // Motif du pret*/
    var result = [];

    if (!_.isEmpty(text)) {
      result.push(text.replace(',', ''));
    }

    var motif = "";
    if (!_.isEmpty(model.get("collabremplace"))) {
      motif += "<span style='float:left' class='ui-phx-ihm-texte-donnees'>" + i18n.t('messages:GT_1848') + "</span>";
    }

    // Libelle du pret
    var libelle = "";
    var content = [];
    var matric = "";
    if (!_.isEmpty(model.get("collabremplace"))) {

      var aux = "";

      if (!STR.isBlank(model.get("collabremplace").nom)) {
        content.push(model.get("collabremplace").nom);
      }
      if (!STR.isBlank(model.get("collabremplace").prenom)) {
        content.push(model.get("collabremplace").prenom);
      }

      if (!STR.isBlank("idCollab")) {

        if (idCollab === "matric") {
          matric = "(" + model.get("collabremplace").matric + ")";
        } else { // matricaux.
          matric = "(" + model.get("collabremplace").matricaux + ")";
        }

      } else {
        matric = "(" + model.get("collabremplace")[idCollab] + ")";
      }
      aux = (matric.replace("null", "").length > 2) ? matric : "";
      libelle += (!STR.isBlank(aux)) ? "<span style='float:left' class='ui-phx-ihm-texte-donnees-alternatif'>" + content.join(" ") + aux + "</span>" : "";
    }

    if (!STR.isBlank(motif) && !STR.isBlank(libelle)) {
      result.push(motif);
      result.push(libelle);
    }

    // Libelle et code du rattachement
    // EltStructCode, EltStructLibelle
    var libelle2 = "<span style='float:left' class='ui-phx-ihm-texte-donnees'>" + model.get("itemlib") + "(" + model.get("itemcode") + ")</span>";

    // Témoin aval

    if (!STR.isBlank(model.get("datefin"))) {
      //  PretDateFin
      var datefin = parseInt(model.get("datefin"));
    }

    if (!STR.isBlank(model.get("date"))) {
      // journée restituée
      var dateRes = parseInt(model.get("date"));
    }

    // si PretDateFin >= journée restituée ( same behaviour as in Agenda )
    if (!_.isEmpty(libelle2) && (datefin > dateRes)) {

      libelle2 += "<span style='float:left' class='ui-icon  ui-icon-circle-arrow-e'></span>";
      //libelle2 += "<span class='' style=\"display: inline-block\"><span class=\"phx-icon \"</span>";
    }
    result.push(libelle2);
    // EVO 450

    var aux = (result.join(", "));
    var pop = aux.replace(new RegExp('</span>,', 'g'), ",&nbsp;</span>");

    return pop;
  },

  /**
   * Renders this brique
   *
   */
  render: function() {
    var json = { 'i18n': i18n };
    $(this.el).html(this.template(json));
    this.$el.find(".table_AffectationPrincipale ").html(this.tableP.el);
    this.tableP.render();

    // EVO 450
    this.$el.find(".table_AffectationRemplacement ").html(this.tableR.el);
    this.tableR.render();
    this.tableR.model.coll.sort(); //sort by date
    // EVO 450

    if (this.infoCompActivated) {
      this.$el.find(".table_AffectationComplementaire ").html(this.tableC.el);
      this.tableC.render();
    }

    return this;
  },

  /*refreshes information*/

  _isAffectCompAllowed: function() {
    var hasComplementaires = false;
    if (_.contains(this.ctxTypesAffectations, "Affectations secondaires") && this.hasAffect && this.isStructurePrincipal && HABILITATION.canView("COL_AFFSECOND.V") && LICENCE.hasModule("GMA")) {
      hasComplementaires = true;
    }
    return hasComplementaires;
  },

  _isAffectExcepAllowed: function() {
    var hasExcep = false;
    if (this.hasPrets && _.contains(this.ctxTypesAffectations, "Prêts") && this.isStructurePrincipal && HABILITATION.canView("COL_PRET.V") && LICENCE.hasModule("GPR")) {
      hasExcep = true;
    }
    return hasExcep;
  },

  isEmpty: function() {
    return this.empty;
  },

  refreshBrique: function() {

    var self = this;

    this.tableP.model.coll.fetch({
      success: function(fresh) {

        // EVO 450
        var callBack = function() {
          if (self.StrutureP.get("code") == null && !_.isUndefined(fresh.models[0])) {

            self.StrutureP.set("code", fresh.models[0].get("structid"));
            self.StrutureP.set("codef", fresh.models[0].get("structid"));
            self.StrutureP.set("id", fresh.models[0].get("structid"));

            self.StrutureP.fetch({
              success: function(fresh) {
                if (_.isEmpty(fresh) || fresh.get("rattcolpar") == "N") {
                  self.empty = true;
                  self.$el.parents().find("#baffec").hide();
                } else {
                  self.empty = false;
                  self.hasPrets = fresh.get("rattpret");
                  self.hasAffect = fresh.get("rattaffectationsec");
                  self.isStructurePrincipal = fresh.get("principal");
                  if (self.tableR.model.coll.length <= 0) {
                    self.$el.find(".table_AffectationRemplacement").hide();
                  }
                  if (self.infoCompActivated) {
                    self.tableC.model.coll.fetch({
                      success: function(fresh) {
                        // we show this section only if all requirements are passed.
                        if (self._isAffectCompAllowed() == false) {
                          self.$el.find(".table_AffectationComplementaire").hide();
                        }

                      }
                    });
                  }
                }
              }
            });
          }
        }

        if (!HABILITATION.canView("COL_PRET.V")) {
          callBack();
        } else {
          self.tableR.model.coll.fetch({
            success: function() {
              callBack();
            }
          });
        }

        // EVO 450
      }
    });
  }

});