import { BaseGridModel } from '../basegrid/baseGrid.model.js';
import { FORMS } from '../../../utils/forms.js';
import { i18n } from '../../../i18n.js';
import { LOG } from '../../../utils/log.js';
import { MSGS } from '../../../utils/msgs.js';
import { STR } from '../../../utils/str.js';

export var EditableGridModel = BaseGridModel.extend({
  /**
   * Event launched when a row is clicked
   */
  /**
   * Event launched when a row is double clicked
   */
  /**
   * Event launched when a model is added
   */
  /**
   * Event launched when a model is added to multiselectcoll
   */
  /**
   * Event launched when a model is removed from multiselectcoll
   */
  /**
   * Event launched when a button in the table is clicked
   */
  /**
   * Event launched when the value changes
   *
   */
  /**
   * Add this to have Outline
   *
   */
  dummy: "",

  /**
   * Constructor
   * Model underlying a editable DataGrid.
   * Events out: change:value
   * Events in: row:click, row:select, row:unselect
   */
  initialize: function(attributes, options) {

    BaseGridModel.prototype.initialize.call(this, attributes, options);

    this.coll.off("row:click");
    this.coll.off("row:dblclick");
    this.coll.off("add:model");
    this.coll.on("row:click", this._manageRowSelection, this);
    this.coll.on("row:dblclick", this._manageRowEdition, this);
    this.coll.on("add:model", this._manageRowEdition, this);
    this.set("mode", "C");
    this.btnBarModel = undefined;
    this.propertyPK = [];

    this.multiselectColl.on("add", this._manageMultiSelection, this);
    this.multiselectColl.on("remove", this._manageMultiSelection, this);
    this.multiselectColl.on("reset", this._manageMultiSelection, this);

    this.readOnly = false;

    if (!STR.isBlank(options)) {
      this.context = options.context;
    }
  },

  /**
   * Set up the work flow of the model. Link the models between them
   */
  setUp: function() {
    // Declare events consumers
    this.on("change:mode", this._manageMode, this);
    this.btnBarModel.on("btn:click", this.buttonAction, this);

  },

  /**
   * Public default button action of the editable grid. It is public to allow
   * the extension of its behavior by doing somethign like this:
   * someCustomBtnAction : function(buttonId) { // Additional code...
   * this.tableModel.buttonAction(buttonId); }
   */
  buttonAction: function(buttonId, callback) {
    this._buttonAction(buttonId, callback);
  },
  /**
   * Set mode for btnbarModel and show error if it is not valid.
   */
  _manageMode: function() {
    var newMode = this.get("mode");
    if (!_.contains(["E", "R", "C"], newMode)) {
      throw new Error("Mode not supported in EditableGrid : " + newMode);
    }
    this.btnBarModel.set("mode", newMode);
  },
  /**
   * When a row is selected value is updated and mode is changed
   */
  _manageRowSelection: function(model) {
    if (model) {
      LOG.debug("_manageRowSelection mode:" + this.get("mode") + this.get("value"));
      if (this.get("mode") === "E") {
        var self = this;
        this.btnBarModel.trigger("btn:click", "revert", function() {
          //Added to avoid double select row when we click in a different row of the new one
          self.get("value").trigger("row:unselect", self.get("value"));
          model.trigger("row:select", model);
          self.set("value", model);
          self.set("mode", "R");
          self._manageRowOrdering();
        });
      } else {
        if (STR.isBlank(this.get("value"))) {
          this.set("value", model);
          model.trigger("row:select", model);
          this.set("mode", "R");
          this._manageRowOrdering();
        } else if (this.get("value") === model) {
          this.set("value", null);
          model.trigger("row:unselect", model);
          this.set("mode", "C");
        } else {
          this.get("value").trigger("row:unselect", this.get("value"));
          model.trigger("row:select", model);
          this.set("value", model);
          this.set("mode", "R");
          this._manageRowOrdering();
        }
      }

    }
  },
  /**
   * Manages row edition
   */
  _manageRowEdition: function(model) {
    // set habilitation context of the model
    var currentHabContext = null;
    if (!this.readOnly) {
      if (!STR.isBlank(model)) {
        if (this.habContext) {
          currentHabContext = this.getHabContext().copy();
          if (model.isNew()) {
            currentHabContext.update({ nat_gest: "A" });
          } else {
            currentHabContext.update({ nat_gest: "M" });
          }
          model.setHabContext(currentHabContext);
        }

        if (this.context) {
          model.updateHabContext({ fonc_cour: this.context.ctxHabilitation.HabilitationGestion })
        }

        if (this.get("mode") === "E") {
          var self = this;
          this.btnBarModel.trigger("btn:click", "revert", function() {
            model.trigger("row:select", model);
            model.trigger("row:edit", model);
            self.set("value", model);
            self.set("mode", "E");
          });
        } else {
          if (!STR.isBlank(this.get("value")) && this.get("value") !== model) {
            this.get("value").trigger("row:unselect", this.get("value"));
          }
          model.trigger("row:select", model);
          model.trigger("row:edit", model);
          this.set("value", model);
          this.set("mode", "E");
        }
      }
    }
  },

  /**
   * callback : callback function called when action succeeded (save, rever and delete)
   */
  _buttonAction: function(buttonId, callback) {
    var self = this;
    var currentHabContext = null;
    var editedModel = null;
    var value = null;

    if (this.habContext) {
      currentHabContext = this.habContext.copy();
    }
    switch (buttonId) {
      case "save":
        editedModel = this.get("value");

        if (!STR.isBlank(editedModel)) {
          editedModel.save(null, {
            success: function(fresh) {
              self.coll.fetch({
                success: function() {
                  // Select the record and paginate if required
                  self.set("mode", "R");
                  self.selectRow(fresh);
                  if (callback) {
                    callback(buttonId, fresh);
                  }
                }
              });
            },
            error: function(model, response) {
              if (response && response.status === 406 && !_.isEmpty(response.responseText)) {
                //If there was an error of saisi incorrect we want to show it on the edited row
                self.get("value").validationError = self.get("value").validationError || { errors: {}, errorValidation: {} };
                if (response.responseJSON && response.responseJSON.attribut) {
                  FORMS.assignValue(self.get("value").validationError.errors, response.responseJSON.attribut, response.responseJSON.message);
                  self.get("value").trigger("invalid", self, self.get("value").validationError);
                  //Avoid the creation of the pop up
                  response.status = 200;
                  response.responseText = null;
                }
              }
            }
          });
        }
        break;
      case "revert":
        if (this.get("mode") === "E") {
          editedModel = this.get("value");
          if (editedModel.isNew()) {
            editedModel.destroy();
            editedModel.trigger("row:unedit");
            var row = undefined;
            if (!_.isUndefined(this.lastCode)) {
              row = this.coll.get(this.lastCode);
              if (STR.isBlank(row)) {
                this.set("mode", "C");
              } else {
                this.set("mode", "R");
                this.coll.trigger("row:click", row);
              }
            } else {
              this.set("mode", "C");
            }
            if (callback) {
              callback(buttonId, row);
            }
          } else {
            editedModel.revert();

            // Select the record and paginate if required
            editedModel.trigger("row:unedit");
            self.set("mode", "R");

            if (callback) {
              callback(buttonId, editedModel);
            }
          }

        }
        break;
      case "new":
        // get previous row id if it exist
        this.lastCode = undefined;
        if (!STR.isBlank(this.get("value"))) {
          this.lastCode = this.get("value").id;
        }

        // create new row
        var fresh = new this.coll.model;

        this.coll.add(fresh);
        if (callback) {
          callback(buttonId, fresh);
        }

        break;
      case "copy":
        if (this.get("mode") !== "E" && !STR.isBlank(this.get("value"))) {
          var copy = new this.coll.model(this.get("value").toJSON());
          copy.id = null;
          _.each(this.propertyPK, function(property) {
            // check if property is part of the model attributes
            if (!STR.isBlank(STR.getElValue(copy, property))) {
              STR.setElValue(copy, property, null, { silent: true });
            }
          });
          if (callback) {
            callback(buttonId, copy);
          }
          this.coll.add(copy);
        }
        break;
      case "delete":

        editedModel = this.get("value");
        if (!STR.isBlank(editedModel)) {
          if (currentHabContext) {
            currentHabContext.update({ nat_gest: "S" });
            editedModel.setHabContext(currentHabContext);
          }
          var elements = this.multiselectColl.length;
          if (elements === 0) {
            MSGS.showConfirm(i18n.t('common:delconfirm'), function(result) {
              if (result === "Y") {
                var position = _.indexOf(self.coll.models, editedModel);
                editedModel.destroy({
                  success: function(fresh) {
                    self.coll.fetch({
                      success: function() {
                        if (callback) {
                          callback(buttonId, fresh);
                        }
                        if (self.coll.length > 0) {
                          var next = self.coll.at(position);
                          if (next) {
                            next.trigger("row:click", next);
                          } else {
                            next = self.coll.at(self.coll.length - 1);
                            if (next) {
                              next.trigger("row:click", next);
                            }
                          }
                        }
                      },
                      error: function() {
                        var next = self.coll.at(position);
                        next.trigger("row:click", next);
                      }
                    });

                  },
                  wait: true
                });
              }
            });
          } else {
            MSGS.showConfirm(i18n.t('common:delconfirm2', { "0": elements }), function(result) {
              if (result === "Y") {
                self._recursiveSuppress(self.multiselectColl, currentHabContext, 0, function(position) {
                  self.coll.fetch({
                    success: function() {
                      if (self.coll.length > 0) {
                        var next = self.coll.at(position);
                        if (next) {
                          next.trigger("row:click", next);
                        } else {
                          next = self.coll.at(self.coll.length - 1);
                          if (next) {
                            next.trigger("row:click", next);
                          }
                        }
                      }
                    },
                    error: function() {
                      var next = self.coll.at(position);
                      next.trigger("row:click", next);
                    }
                  });
                  if (callback) {
                    callback(buttonId);
                  }
                });
              }
            });
          }
        }
        break;
      case "monter":
        value = this.get("value");
        value.trigger("moved:up", value);
        this._manageRowOrdering();
        break;
      case "descendre":
        value = this.get("value");
        value.trigger("moved:down", value);
        this._manageRowOrdering();
        break;
      case "placer":
        value = this.get("value");
        value.trigger("moved:placer", value);
        this._manageRowOrdering();
        break;
      case "premier":
        value = this.get("value");
        value.trigger("moved:premier", value);
        this._manageRowOrdering();
        break;
      default:
        //nothing
    }
  },
  /**
   * Manages the order of the rows
   */
  _manageRowOrdering: function() {
    if (this.btnBarModel && this.btnBarModel.get("rowOrdering") === true) {
      var value = this.get("value");
      var index = this.coll.indexOf(value);
      var length = this.coll.length - 1;
      if (this.coll.length > 1) {
        if (index === 0) {
          this.btnBarModel.trigger("moved:top");
        } else if (index === length) {
          this.btnBarModel.trigger("moved:bottom");
        } else {
          this.btnBarModel.trigger("moved:other");
        }
      } else {
        this.btnBarModel.trigger("moved:hide");
      }
    }
  },
  /**
   * When removing rows for multiselection
   */
  _recursiveSuppress: function(models, currentHabContext, position, callback) {
    var self = this;
    var item = models.shift();
    var l_position = position;

    if (!STR.isBlank(item)) {
      l_position = _.indexOf(self.coll.models, item);
      if (STR.isBlank(item.urlRoot)) {
        item.urlRoot = self.coll.url();
      }
      item.setHabContext(currentHabContext);
      item.destroy({
        success: function() {
          self._recursiveSuppress(models, currentHabContext, l_position, callback);
        },
        error: function() {
          models.unshift(item);
          callback(l_position);
        }
      });
    } else {
      callback(l_position);
    }
  },

  _manageMultiSelection: function() {
    if (this.multiselectColl.length > 0) {
      this.btnBarModel.trigger("override.show:delete");
    } else {
      this.btnBarModel.trigger("override.hide:delete");
    }
  },

  /**
   * The current habilitation context that the grid should use perform the actions (add/modify/remove)
   *
   */
  setHabContext: function(habContext) {
    this.habContext = habContext;
  },

  updateHabContext: function(attributes) {
    if (this.habContext) {
      this.habContext.update(attributes);
    }
  },

  getHabContext: function() {
    return this.habContext;
  }
});
