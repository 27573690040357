import { EffectifsModel } from '../models/effectifs.model.js';
import { HabilitationContext } from '../../../../../core/models/habilitationContext.js';
import { i18n } from '../../../../../i18n.js';
import { LibellesModelColl } from '../models/libellesModel.collection.js';
import { NAV } from '../../../../../utils/nav.js';
import { objs } from '../../../../../objectsRepository.js';
import { STR } from '../../../../../utils/str.js';
import { TYPE } from '../../../../../tda/tda.js';
import TPL_beff_detail from '../detail.tpl.html';

/**
 * View of Brique 'Effectifs'
 */
export var EffectifsView = Backbone.View.extend({

  events: _.extend({
    "click .detail_link": "_goToDetail"
  }, TYPE.SHORT),

  initialize: function(options) {
    this.usePopulation = false;
    if (options && options.usePopulation) {
      this.usePopulation = options.usePopulation;
    }
    if (options && options.parent) {
      this.parent = options.parent;
    }
    this.template = TPL_beff_detail;

    this.habContext = new HabilitationContext({
      onglet: "beff",
      fonc_cour: "RES_EFFECT.V",
      nat_gest: ""
    });

    this.model = new EffectifsModel();
    this.model.usePopulation = this.usePopulation;
    this.collection = new LibellesModelColl();
    this.collection.usePopulation = this.usePopulation;

    this.model.setHabContext(this.habContext);

    this.dataEtat = null;
    this.dataColl = null;
    this.dataTotals = null;

    //class of etats
    this.ETAT_PRESENT = "ui-phx-etat-present";
    this.ETAT_ABSENT_PREVU = "ui-phx-etat-absent-prevu";
    this.ETAT_ABSENT_NON_PREVU = "ui-phx-etat-absent-non-prevu";
    this.ETAT_INDEFINI = "ui-phx-etat-indefini";
    this.ROWS_FOR_FILE = 3;

    this.replotIndex = 0;

    if (options && options.parent) {
      this.parent = options.parent;
    }
  },

  render: function() {
    $(this.el).html(this.template());
    this.$el.find(".graphiqueZone").attr("id", "graphiqueZone_" + this.cid);
    return this;
  },

  refreshBrique: function() {

    this._initializeColors();
    this._renderData();

    this.delegateEvents();

    return this;
  },

  refreshTitle: function(heure) {
    this.parent.setTitle(i18n.t('common:beff.brickTitle', { "0": TYPE.HOUR_MINUTE.format(heure) }));
  },

  _renderData: function() {
    var self = this;
    var table = null;
    this.model.fetch({
      success: function(fetchedModel) {
        self._renderGrafique(fetchedModel);
        self.model = fetchedModel;
        self.refreshTitle(fetchedModel.get("heure"));

        self.collection.fetch({
          success: function(collection) {
            self._renderSyntheseTable(collection);
            $(self.el).find(".zoneDetailLink").html("<a class=\"detail_link\" href=\"javascript:void(0)\">" + i18n.t('common:beff.linkDetail') + "</a>");
          }
        });
      }
    });
  },

  _goToDetail: function() {
    NAV.navigate("resp", "presabs");
  },

  _renderGrafique: function(model) {

    if (model.get("present") === 0 && model.get("absentprevu") === 0 && model.get("absentnonprevu") === 0 && model.get("indefini") === 0) {
      this.$el.find(".beff-detail").children().hide();
      var span = $("<div class='phx-grid-no-title-message emptyMessage'>").text(i18n.t('common:beff.noEffectifsAffiche'));
      this.$el.find(".beff-detail").append(span);
      this.replotIndex = 1;

    } else {
      this.$el.find(".beff-detail").children().show();
      this.$el.find(".emptyMessage").hide();

      var presentColor = $("." + this.ETAT_PRESENT).css("background-color");
      var absentsPColor = $("." + this.ETAT_ABSENT_PREVU).css("background-color");
      var absentsNPColor = $("." + this.ETAT_ABSENT_NON_PREVU).css("background-color");
      var indefiniColor = $("." + this.ETAT_INDEFINI).css("background-color");

      var valeurs = [
        ['Présents', model.get("present")],
        ['Absents prévus', model.get("absentprevu")],
        ['Absents non prévus', model.get("absentnonprevu")],
        ['Indéfini', model.get("indefini")]
      ];

      var colors = [presentColor, absentsPColor, absentsNPColor, indefiniColor];
      var plot2 = $.jqplot('graphiqueZone_' + this.cid, [valeurs], {
        height: 150,
        width: 150,
        grid: {
          drawBorder: false,
          drawGridlines: false,
          background: 'transparent',
          shadow: false
        },
        seriesColors: colors,
        seriesDefaults: {
          // Make this a pie chart.
          renderer: jQuery.jqplot.PieRenderer,
          rendererOptions: {
            diameter: 100, // auto-size by dafault
            showDataLabels: true,
            dataLabels: "value",
            highlightMouseOver: false
          }
        }
      });
      this.replotIndex = 0;
    }

    this.listenTo(objs.appRt.workflow, "change:usecase", function(workflow, zone) {
      if (workflow.get("zone") === "resp" && workflow.get("usecase") === "") {
        if (this.replotIndex == 0) {
          if (plot2 !== undefined) {
            plot2.replot();
            this.replotIndex++;
          }
        }
      }
    });

    this.listenTo(objs.appRt.workflow, "change:zone", function(workflow, zone) {
      var lastuc = "lastuc-" + zone;
      if (zone === "resp" && STR.isBlank(workflow.get(lastuc))) {
        if (this.replotIndex == 0) {
          if (plot2 !== undefined) {
            plot2.replot();
            this.replotIndex++;
          }
        }
      }
    });
  },

  _renderSyntheseTable: function(collection) {
    var presentColor = $("." + this.ETAT_PRESENT).css("background-color");
    var absentsPColor = $("." + this.ETAT_ABSENT_PREVU).css("background-color");
    var absentsNPColor = $("." + this.ETAT_ABSENT_NON_PREVU).css("background-color");
    var indefiniColor = $("." + this.ETAT_INDEFINI).css("background-color");
    var fetchedModel = this.model;
    for (var i = 0; i < collection.length; i++) {
      var effectif = null;
      var libelleClass = null;
      var printEffectif = true;
      var classColor = null;
      var cssClass;

      switch (collection.models[i].get("code")) {
        case "PRES":
          effectif = fetchedModel.get("present");
          libelleClass = ".libellePresent";
          classColor = presentColor;
          cssClass = this.ETAT_PRESENT;
          break;
        case "ABSPREV":
          effectif = fetchedModel.get("absentprevu");
          libelleClass = ".libelleAbsentPrevu";
          classColor = absentsPColor;
          cssClass = this.ETAT_ABSENT_PREVU;
          if (effectif === 0) {
            printEffectif = false;
          }
          break;
        case "ABSNPREV":
          effectif = fetchedModel.get("absentnonprevu");
          libelleClass = ".libelleAbsentNonPrevu";
          classColor = absentsNPColor;
          cssClass = this.ETAT_ABSENT_NON_PREVU;
          if (effectif === 0) {
            printEffectif = false;
          }
          break;
        case "INDEFINI":
          effectif = fetchedModel.get("indefini");
          libelleClass = ".libelleIndefini";
          classColor = indefiniColor;
          cssClass = this.ETAT_INDEFINI;
          if (effectif === 0) {
            printEffectif = false;
          }
          break;
        default:
          printEffectif = false;
          break;
      }

      if (printEffectif === true) {
        $(this.el).find(libelleClass).html("<td style=\"padding-left:5px;\"><b>" + effectif + "</b> " + collection.models[i].get("libelle") + "</td>");
        $(this.el).find(libelleClass).prepend("<td><div class=\"carreColor\"><div class=" + cssClass + " style=\"width:100%;height:100%;\"></div></div></td><td></td>");
      } else {
        $(this.el).find(libelleClass).html("");
      }
    }
  },

  _initializeColors: function() {
    $(this.el).find(".initializeColors").html("<span class=" + this.ETAT_PRESENT + "></span>" + "<span class=" + this.ETAT_ABSENT_PREVU + "></span>" + "<span class=" + this.ETAT_ABSENT_NON_PREVU + "></span>" + "<span class=" + this.ETAT_INDEFINI + "></span>");
  }
});
