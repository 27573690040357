import { AccepterPret } from '../models/accepterPret.js';
import { BaseModel } from '../../../../../../core/models/base.model.js';
import { FormView } from '../../../../../../core/views/form.view.js';
import { i18n } from '../../../../../../i18n.js';
import { RefuserPret } from '../models/refuserPret.js';
import { STR } from '../../../../../../utils/str.js';
import TPL_listerprets_headerPopup from '../headerPopup.tpl.html';

export var HeaderPopupView = FormView.extend({

  initialize: function(options) {
    this.template = TPL_listerprets_headerPopup;

    this.model = new BaseModel({
      value: null //new BaseModel({"commentaire" : "", "isForced" : false})
    });

    if (options.action) {
      this.action = options.action;

      if (this.action === "A") {
        this.model.set("value", new AccepterPret());
      } else if (this.action === "R") {
        this.model.set("value", new RefuserPret());
      }
    } else {
      this.model.set("value", new AccepterPret());
    }

    this.model.get("value").on("change", this._mapToForm, this);

    this.commentaire = STR.isBlank(options.commentaire) ? "" : options.commentaire;
    this.isForced = STR.isBlank(options.isForced) ? "" : options.isForced;

    if (options.parent) {
      this.parent = options.parent;
      this.parent.model.set("isCommentChanged", false);
    }
  },

  events: {
    "change :input:not([readonly])": "_change",
    "keyup :input:not([readonly])": "_enableButtons",
    "click :input.isForced": "_change",
    "click button": "_clickListener"
  },

  render: function() {
    var json = { "i18n": i18n };
    this.$el.append(this.template(json));

    // Render buttons
    $(this.el).find(".btnValider").button();
    $(this.el).find(".btnRevert").button({
      icons: {
        primary: "ui-icon-arrowrefresh-1-w"
      },
      text: false
    });

    //Initialize the comment textarea with the previous comment and the forced checkbox.
    this.model.get("value").isForced = this.isForced;
    STR.setElValue(this.model.get("value"), "commentaire", this.commentaire);

    if (this.action === "R") {
      $(this.el).find("fieldset.listerprets-dialogPopupHeader label[for=commentaire], fieldset.listerprets-dialogPopupHeader label[for^=commentaire_uid_]").addClass("required");
    }

    //Disable the buttons
    $(this.el).find("fieldset.listerprets-dialogPopupHeader button").button({ disabled: true });
    if (this.parent) {
      $(this.parent.el).css("height", "auto");
    }

    return this;
  },

  _change: function(event) {
    var element = event.currentTarget;

    //MapCheckBoxToModel
    if (element.className === "isForced") {
      this.model.get("value").isForced = element.checked;
    } else {
      FormView.prototype._change.call(this, event);
    }

    // Clear current validation erros
    this._cleanValidationErrors();
  },

  _enableButtons: function() {
    $(this.el).find("fieldset.listerprets-dialogPopupHeader button").button({ disabled: false });
  },

  _mapToForm: function() {
    var model = this.model.get("value");
    var fieldset = this.$el.find("fieldset");

    this._mapModelToForm(fieldset, model, null);

    $(this.el).find("fieldset.listerprets-dialogPopupHeader input.isForced").prop("checked", model.isForced);
  },

  _clickListener: function(event) {
    var btnClicked = event.currentTarget.value;
    var model = this.model.get("value");
    var commentaire = STR.getElValue(model, "commentaire");
    var isForced = model.isForced;

    switch (btnClicked) {
      case "btnValider":
        // Error management
        model.off("invalid");
        model.on("invalid", this._showValidationErrors, this);

        if (model.isValid()) {
          this.parent.model.set("globalComment", commentaire);
          this.parent.model.set("isForced", isForced);
          this.parent.model.set("isCommentChanged", true);
          this.parent.close();
        }
        break;
      case "btnRevert":
        model.isForced = this.isForced;
        STR.setElValue(model, "commentaire", this.commentaire);
        break;
      default:
        break;
    }
  }
});