import { EditableGridModel } from '../../../../../core/grids/editablegrid/editableGrid.model.js';
import { EditableGridView } from '../../../../../core/grids/editablegrid/editableGrid.view.js';
import { TypeStructureModel } from '../models/typeStructure.model';
import { GLOBAL_DATA } from '../../../../../globalData.js';
import { HABILITATION } from '../../../../../utils/habilitation.js';
import { HabilitationContext } from '../../../../../core/models/habilitationContext.js';
import { i18n } from '../../../../../i18n.js';
import { objs } from '../../../../../objectsRepository.js';
import { SelecteurCheminItemModel } from '../../../../../core/components/selecteur_chemin/selecteurCheminItem.model.js';
import { SelecteurCheminView } from '../../../../../core/components/selecteur_chemin/selecteurChemin.view.js';
import { STR } from '../../../../../utils/str.js';
import { SYNC } from '../../../../../utils/sync.js';
import { TYPE } from '../../../../../tda/tda.js';
import TPL_bmonresp_briqueMonResponsable from '../briqueMonResponsable.tpl.html';
import { MonRespCollection } from '../models/monResp.collection';
import { MonRespModel } from '../models/monResp.model';
import { EditedRowView } from '../views/editedRow.view';
import { MSGS } from '../../../../../utils/msgs.js';
import { avantAjoutModel } from "../models/avantAjout.model";

export var MonResponsableView = Backbone.View.extend({

  events: {},

  initialize: function(options) {
    this.usePopulation = false;
    if (options && options.usePopulation) {
      this.usePopulation = options.usePopulation;
    }
    this.template = TPL_bmonresp_briqueMonResponsable
    this.model = new MonRespModel();

    this.habContext = new HabilitationContext({
      onglet: "bmonresp",
      fonc_cour: "COL_SPE_MRESP.V",
      nat_gest: ""
    });

    this.structureP = new TypeStructureModel();
    this.strutureHabContext = new HabilitationContext({
      onglet: "bmonresp",
      fonc_cour: "",
      nat_gest: ""
    });
    this.structureP.setHabContext(this.strutureHabContext);

    this.MonRespCollection = new MonRespCollection();
    this.MonRespCollection.usePopulation = this.usePopulation;
    this.tableP = this._initTablePrincip();
    this.tableP.buttonBar.model.off("btn:click");
    this.listenTo(this.tableP.buttonBar.model, "btn:click", this._customButtonAction);
  },

  _initTablePrincip: function() {
    var tableModel = new EditableGridModel({ coll: this.MonRespCollection });
    var tblColumns = [
      { title: i18n.t('common:bmonresp.colonneDu'), property: "debsitu", width: 150, pk: true },
      { title: i18n.t('common:bmonresp.colonneAu'), property: "finsitu", width: 150 },
      { title: i18n.t('common:bmonresp.resp'), property: "itemid", width: 300, pk: true }
    ];
    var viewRow = new EditedRowView();

    var table = new EditableGridView({
      id: "bmonresp_table_MonResp",
      columns: tblColumns,
      model: tableModel,
      viewRow: viewRow,
      showFilteredRowsInTitle: true,
      title: i18n.t('common:bmonresp.title'),
      gridHeightType: "auto",
      maxVisibleRows: 5,
      minVisibleRows: 5,
      habilitations: HABILITATION.canUpdate("COL_SPE_MRESP.G") ? "COL_SPE_MRESP.G" : "COL_SPE_MRESP.V"
    });

    //table.editable = false;

    table.setSortableColumns([]);

    table.model.coll.off("row:dblclick");
    this.listenTo(table.model.coll, "row:dblclick", this._editRow)

    table.cellRenderers["debsitu"] = function(model) {
      if (model.get("debsitu")) {
        return TYPE.DATE.format(model.get("debsitu"), TYPE._getFormatByCode("DATE"));
      }
    };

    table.cellRenderers["finsitu"] = function(model) {
      if (model.get("finsitu")) {
        if (model.get("finsitu") == TYPE.DATE.INFINITY && GLOBAL_DATA.paramDivers.get("CACH_INFIN").get("valeur") == 1) {
          return null;
        } else {
          return TYPE.DATE.format(model.get("finsitu"), TYPE._getFormatByCode("DATE"));
        }
      }
    };

    table.cellRenderers["itemid"] = (model) => {
      var structCode;

      if (!STR.isBlank(model.collection)) {
        structCode = model.collection.structid
      } else if (!STR.isBlank(this.structureP)) {
        structCode = this.structureP.get("structid");
      }

      this.structureSelected = new SelecteurCheminView({
        name: "itemid",
        listRenderStyle: 1, //format
        height: 500,
        width: 300,
        structid: structCode, //code
        debsitu: model.get("datedeb"),
        finsitu: model.get("datefin"),
        partial: false,
        modeComplet: true,
        racine: false,
        nonrattachable: false,
        readonly: true,
        title: model.get("itemlib") + " (" + model.get("itemcode") + ")",
        habContext: this.habContext,
        ecran: objs.appRt.workflow.get("zone"),
        structval: this.structureP.get("structid")
      });

      var item = new SelecteurCheminItemModel({
        "structid": structCode,
        "date": model.get("debsitu") ? model.get("debsitu") : TYPE.DATE.INITIAL,
        "elementCode": model.get("itemid") ? model.get("itemid") : ""
      });

      if (!STR.isBlank(item.get("elementCode")))
        this.structureSelected.addValue(item);

      var result = this.structureSelected.render().el;
      this._disableTableButtons();
      return result;
    };
    tableModel.coll.setHabContext(this.habContext);
    var hc = this.habContext.copy();
    hc.update({ fonc_cour: "COL_SPE_MRESP.G", nat_gest: "" });
    table.model.setHabContext(hc);
    return table;
  },

  _editRow: function(event) {
    this.avantAjout = new avantAjoutModel;
    this.avantAjout.structid = this.structureSelected.structid;
    this.avantAjout.code = objs.appRt.workflow.authModel.get("matricule");
    this.avantAjout.setHabContext(this.habContext);
    var self = this;
    var eventDate = event.get("debsitu");
    this.avantAjout.fetch({
      success: function(resp) {
        if (resp.get("result") === "ok") {
          var today = TYPE.DATE.dateToStr(new Date());
          today = TYPE.DATE.parse(today).val;
          if (!STR.isBlank(self.tableP.model.get("value")) && eventDate === self.tableP.model.get("value").get("debsitu")) {
            if (parseInt(self.tableP.model.get("value").get("debsitu")) >= parseInt(today)) {
              self.tableP.model._manageRowEdition(self.tableP.model.get("value"));
            }
          }
        }
      }
    });
  },

  render: function() {
    var json = { 'i18n': i18n };
    $(this.el).html(this.template(json));
    this.$el.find(".table_MonResp ").html(this.tableP.el);
    this.tableP.render();
    if (GLOBAL_DATA.paramDivers.get("SPEValideu").get("valeur") == 1) {
      if (!GLOBAL_DATA.respDesig.get("existValideur")) {
        this.$el.find(".msgBloquant").html(i18n.t('common:bmonresp.msgBloquant'));
        this.$el.find(".msgBloquant").css({ "color": "red", "text-transform": "uppercase", "font-weight": "bolder" });
      }
    }
    return this;
  },

  _disableTableButtons: function() {
    this.tableP.disableDeleteButton(true);
    this.tableP.disableCopyButton(true);
  },

  _customButtonAction: function(buttonId) {
    var _self = this;
    var callback = function() {
      if (!GLOBAL_DATA.respDesig.get("existValideur")) {
        _self.$el.find(".msgBloquant").css({ "display": "none" });
        GLOBAL_DATA.rights.menuVisibility.set("EM_00014", true);
        GLOBAL_DATA.rights.menuVisibility.set("EM_00015", true);
        GLOBAL_DATA.rights.menuVisibility.set("EM_00019", true);
        GLOBAL_DATA.rights.trigger("sync");
        GLOBAL_DATA.respDesig.set("existValideur", true);
      }
    }
    var today = TYPE.DATE.dateToStr(SYNC.getServerDate(), "YYYYMMDD");
    switch (buttonId) {
      case "save":
        if (this.tableP.model.get("value").isValid()) {
          if (STR.isBlank(this.tableP.model.get("value").get("finsitu"))) {
            this.tableP.model.get("value").set("finsitu", TYPE.DATE.INFINITY);
          }
        }

        var todayTimestamp = TYPE.DATE.strToDate(today, "YYYYMMDD").getTime();
        var diffTimestamp = 0;
        if (!STR.isBlank(this.tableP.model.get("value").get("debsitu"))) {
          var datedebTimestamp = TYPE.DATE.strToDate(this.tableP.model.get("value").get("debsitu"), "YYYYMMDD").getTime();
          diffTimestamp = datedebTimestamp - todayTimestamp;
          if (diffTimestamp < 0) {
            MSGS.showError(i18n.t("common:bmonresp.datefutur"));
          } else {
            this.tableP.model._buttonAction(buttonId, callback);
          }
        }

        break;
      case "revert":
        this.model.set("id", this.model.get("itemid"));
        this.tableP.model.get("value").set("id", this.tableP.model.get("value").get("itemid"));
        this.tableP.model._buttonAction(buttonId);

        break;
      case "new":
        this.avantAjout = new avantAjoutModel;
        this.avantAjout.structid = this.structureSelected.structid;
        this.avantAjout.code = objs.appRt.workflow.authModel.get("matricule");
        this.avantAjout.setHabContext(this.habContext);
        var self = this;
        this.avantAjout.fetch({
          success: function(resp) {
            if (resp.get("result") === "ok") {
              self.tableP.model._buttonAction(buttonId);
              self.tableP.model.get("value").set("debsitu", today);
            }
          }
        });
      default:
        break;
    }
  },

  refreshBrique: function() {

    var self = this;

    this.structureP.fetch({
      success: function(fresh) {
        self.tableP.model.coll.structid = fresh.get("structid");
        self.tableP.model.coll.structval = fresh.get("structid");
        self.tableP.model.coll.matric = objs.appRt.workflow.authModel.get("matricule");
        self.tableP.model.coll.fetch({
          success: function(fresh) {
            self.tableP.model.selectFirstRow();
          }
        });
      }
    });
  }
});
