import { BaseModel } from 'core/models/base.model.js';

/**
 * Population model
 */
export var PopulationGenerationModel = BaseModel.extend({

  defaults: {
    "code": null,
    "nature": "",
    "desc": false
  },
  url: Configuration.restRoot + "/rest/population/generee/automatiquement",
  parse: function(response) {
    if (response.id === undefined && response.ident !== undefined) {
      this.id = response.ident;
    }
    return response;
  }
});