import { ListeBriquesModel } from './listeBriques.model.js';
import { PaginatedCollection } from '../../../../../core/models/paginated.collection.js';

export var ListeBriquesColl = PaginatedCollection.extend({

  //REVIEW: 	i18nCom : phx.i18n.common,

  model: ListeBriquesModel,

  url: Configuration.restRoot + "/rest/configuration/perso/brique/",

  initialize: function() {
    PaginatedCollection.prototype.initialize.call(this);
    this.paginated = false;
  }
});