import { BaseModel } from 'core/models/base.model.js';


export var LastBadgeModel = BaseModel.extend({

  defaults: {
    dervalide: null,
    derheure: "",
    derlibelle: "",
    derdate: ""
  },

  url: function() {
    return Configuration.restRoot + "/rest/badgvirtuel/" + this.matricule;
  },

  initialize: function(options) {
    if (options && options.matricule) {
      this.matricule = options.matricule;
    }
  }
});