import { BaseModel } from '../../../../../../core/models/base.model.js';
import { BlockView } from '../../../../wkfhisto/views/block.view.js';
import { ButtonBarView } from '../../../../../../core/controls/buttonBar.view.js';
import { ComboBoxView2 } from '../../../../../../core/components/combo/comboBoxView2.js';
import { CombosColl } from '../../../../../../core/components/combo/combos.collection.js';
import { DialogView } from '../../../../../../core/views/dialog.view.js';
import { FORMS } from '../../../../../../utils/forms.js';
import { FormView } from '../../../../../../core/views/form.view.js';
import { GererBadgeagesGestion } from '../../gererbadgeages/models/gererBadgeagesGestion.js';
import { GererOneBadgeagePopUpView } from './gererOneBadgeagePopUp.view.js';
import { GererOneBadgeageProfilsModel } from '../models/gererOneBadgeageProfils.model.js';
import { HABILITATION } from '../../../../../../utils/habilitation.js';
import { HabilitationContext } from '../../../../../../core/models/habilitationContext.js';
import { i18n } from '../../../../../../i18n.js';
import { ListeBadgeageModel } from '../models/listeBadgeage.model.js';
import { STR } from '../../../../../../utils/str.js';
import { TYPE } from '../../../../../../tda/tda.js';
import { GLOBAL_DATA } from '../../../../../../globalData.js';
import { GereronebadgeageWorkflow } from '../models/gereronebadgeage.workflow.js';
import TPL_gereronebadgeage_detail from '../detail.tpl.html';
import { LOG } from '../../../../../../utils/log.js';

export var GererOneBadgeageView = FormView.extend({

  /**
   * Event launched when a non-readonly input changes its value
   *
   */
  /**
   * Event launched when a keyup event is fired for an input of this view
   *
   */
  /**
   * Event launched when a keyup event is fired for a textarea input of this view
   *
   */
  /**
   * Event launched to manage tooltip when mouse is entered in gereronebadgeage-action
   *
   */
  /**
   * Event launched to manage tooltip when mouse is leaved in gereronebadgeage-action
   *
   */
  /**
   * Event launched when a click is done in a a menu item option (Accepter or Refuser)
   */
  /**
   * Event launched when value has changed and form must be refreshed
   */
  /**
   * Event launched when form mode has changed in order to check if new mode is avalid mode
   */
  /**
   * Event launched when current value model is not valid
   */

  /**
   * Constructor
   * View used for gerer one badgeage
   */
  initialize: function(options) {
    this.id = null;
    //this.listeMotifs = new phx.common.gereronebadgeage.ListeMotifColl();

    if (options.context) {
      this.context = options.context;
    }

    this.model = new BaseModel({
      value: new ListeBadgeageModel({ utilisateur: this.context.ctxUtilisateur }),
      mode: "C"
    });

    this.EMPTY_BADGEAGE = new ListeBadgeageModel({ utilisateur: this.context.ctxUtilisateur });
    this.workflow = new GereronebadgeageWorkflow();
    this.EMPTY_BADGEAGE.setHabContext(this.workflow.getHabContext());

    this.btnBar = new ButtonBarView({ id: "gereronebadgeage_btnbar" });
    this.workflow.btnBarModel = this.btnBar.model;
    this.workflow.gereronebadgeage = this;

    if (options && options.parent) {
      this.parent = options.parent;
    }

    if (this.context && this.context.ctxMode) {
      this.model.set("mode", this.context.ctxMode);
    }

    if (options.orientation) { // 0: Horizontal ; 1: Vertical
      this.orientation = options.orientation;
    }
    this.combos = {};

    this.template = TPL_gereronebadgeage_detail;

    // When the value is changed, we have to map the new value to the form
    this.model.on("change:value", this._mapToForm, this);
    this.model.on("change:mode", this._manageMode, this);

    if (this.model.get("value").iddemande == 0) {
      var ensemble = this.model.get("value").evenement;
      var demande = null;
    } else {
      var demande = this.model.get("value").iddemande;
      var ensemble = null;
    }

    this.typeMaskByClass = {};
    this.typeMaskByClass["saisie.date"] = "DATE_A";
    this.typeMaskByClass["saisie.heure"] = "INSTHM";
    this.ready = false;

    this.model.habContext = new HabilitationContext();
  },

  /**
   * Sets up the workflow for this view
   */
  setUp: function(callback) {
    var self = this;
    if (this.ready === false) {
      this.workflow.setUp(function() {
        self._loadData(callback);

      });
    } else {
      if (callback) {
        callback();
      }
    }
  },
  /**
   * Load the internal events of the view, and the events of TDA Date and TDA HOUR_MINUTE
   */
  events: _.extend({
    "change :input:not([readonly])": "_change",
    "keyup :input:not([readonly])": "_notifyEdition",
    "keyup textarea": "_notifyEdition",
    "mouseenter span.gereronebadgeage-action": "_openTooltipListener",
    "mouseleave span.gereronebadgeage-action": "_closeTooltipListener",
    "click .menuItem": "menuItemListener"
  }, TYPE.DATE.events(), TYPE.HOUR_MINUTE.events()),

  /**
   * Refreshes context and calls setup rendering again the view.
   */
  setContext: function(context) {
    this.context = context;
    this._manageContext();
  },

  _showOrHideCommentaire: function(valeur) { //0 standar behavior, 1 replace textarea for combo

    if (valeur == 0) {

      this.showCombo = false;
      $(this.el).find(".cmbBlockBadgeCommentaire").hide();
      $(this.el).find(".blockBadgeCommentaire").show();
    } else if (valeur == 1) {

      this.showCombo = true;
      $(this.el).find(".blockBadgeCommentaire").hide();
      $(this.el).find(".cmbBlockBadgeCommentaire").show();
      $(this.el).find(".objOrigine").addClass("required");
      $(this.el).find(".commentaireLibelleReq").addClass("required");
    }

  },

  _creacionCommentaire: function() {

    var self = this;
    var utilisateur = this.context.ctxUtilisateur;
    var default1 = null;

    if (this.context.ctxModeInitialisation == "Consultation") { //MODO CONSULTA
      if (utilisateur === "Collaborateur") {

        this._showOrHideCommentaire(1);

        if (!STR.isBlank(this.context.ctxValeursEvenement)) {
          default1 = _.find(this.comboCommentaire.coll.models, (modelo) => {
            return modelo.get("libelle") === this.context.ctxValeursEvenement.BadgeageCommentaire;
          });
        } else if (!STR.isBlank(this.context.ctxIdentifiantEvt)) {
          default1 = _.find(this.comboCommentaire.coll.models, (modelo) => {
            return modelo.get("libelle") === this.context.ctxIdentifiantEvt;
          });
        }

        if (STR.isBlank(default1)) {
          default1 = _.find(this.comboCommentaire.coll.models, (modelo) => {
            return modelo.get("libelle") === this.model.attributes.commentaire;
          });
        }

        if (!STR.isBlank(default1)) {

          let parseDefault1 = {
            code: default1.get("code"),
            libelle: default1.get("libelle")
          };

          self.comboCommentaire.setItem(parseDefault1);

          this.model.get("value").set("commentaire", default1.get("libelle"));
        }

      } else {
        this._showOrHideCommentaire(0);
      }
    } else if (this.context.ctxModeInitialisation == "Ajout") { //MODO LECTURA

      if (utilisateur === "Collaborateur") {
        this._showOrHideCommentaire(1);
      } else {
        this._showOrHideCommentaire(0);
      }
    }
  },

  /**
   * Calls the setup and refreshes habilitations
   */
  _manageContext: function() { //
    var self = this;
    this._manageTitle();
    this.setUp(function() {
      self._manageModeInitialisation(function() {
        self._manageActionsPossibles(); //OK
        self._manageActionsForStatut();
        self._manageHabilitation(); //OK
      }); //OK
    });

  },

  _manageWorkflowContect: function() {
    var auxNatGest
    if (this.context.ctxModeInitialisation == "Ajout") {
      auxNatGest = "A";
    } else if (this.context.ctxModeInitialisation == "Consultation") {
      auxNatGest = "M";
    } else {
      auxNatGest = "";

    }
    this.workflow.setHabContext(new HabilitationContext({
      onglet: this.context.ctxEcran,
      fonc_cour: this.context.ctxHabilitation.HabilitationAcces
    }));
    this.model.setHabContext(new HabilitationContext({
      onglet: this.context.ctxEcran,
      fonc_cour: this.context.ctxHabilitation.HabilitationGestion,
      nat_gest: auxNatGest
    }));
  },

  _loadData: function(callback) {
    var context = this.context;
    var self = this;
    var auxNatGest;

    //Update the workflow's habilitation
    this._manageWorkflowContect();

    self.renderForm();
    self.ready = true;

    var promesa = this.comboCommentaire.fetchComboPromise();

    promesa.then((res) => {
      this._creacionCommentaire();
    }).catch(() => {
      this._showOrHideCommentaire(0);
    });

    if (callback) {
      callback();
    }
  },

  /**
   * Refreshes title content
   */
  _manageTitle: function() {
    var context = this.context;

    if (context.ctxModeRepresentation == "pop-up") { //is a popUp
      if (context.ctxModeInitialisation == "Ajout") {
        this.parent._setTitle(i18n.t('common:gereronebadgeage.title_popup_creation'));
      } else { //mode Consultation
        this.parent._setTitle(i18n.t('common:gereronebadgeage.title_popup'));
      }
    }
  },

  /**
   * Sets model values from context data
   */
  _manageValeursCreation: function() {
    if (!STR.isBlank(this.context.ctxValeursCreation)) {
      STR.setElValue(this.model.get("value"), "saisie.date", this.context.ctxValeursCreation.BadgeageDate);
      STR.setElValue(this.model.get("value"), "saisie.heure", this.context.ctxValeursCreation.BadgeageHeure);
      STR.setElValue(this.model.get("value"), "saisie.sens", this.context.ctxValeursCreation.BadgeageSens);
      //STR.setElValue(this.model.get("value"),"saisie.date", this.context.ctxValeursCreation.BadgeageAbsence);
      this.model.get("value").get("motif").code = this.context.ctxValeursCreation.BadgeageAbsence;
      STR.setElValue(this.model.get("value"), "fonction", this.context.ctxValeursCreation.BadgeageFonction);
      STR.setElValue(this.model.get("value"), "commentaire", this.context.ctxValeursCreation.BadgeageCommentaire);
    }
  },

  /**
   * Shows or hides buttons depending on ctxActionsPossibles data
   */
  _manageActionsPossibles: function() {
    //["Creer","Supprimer","Modifier","Valider"]
    if (this.context.ctxActionsPossibles) {
      if (this.context.ctxActionsPossibles.indexOf("Creer") == -1) {
        this.btnBar.model.trigger("hide:new");
      }
      if (this.context.ctxActionsPossibles.indexOf("Supprimer") == -1) {
        this.btnBar.model.trigger("hide:delete");
      }
      if (this.context.ctxActionsPossibles.indexOf("Modifier") == -1) {
        this.btnBar.model.trigger("hide:save");
      } else {
        this.btnBar.model.trigger("show:save");
      }

      if (this.context.ctxActionsPossibles.indexOf("Modifier") == -1 && this.context.ctxActionsPossibles.indexOf("Creer") == -1) {
        this.btnBar.model.trigger("hide:save");
        this.btnBar.model.trigger("hide:revert");
      }
      if (this.context.ctxActionsPossibles.indexOf("Valider") == -1 || !this.model.get("value").get("profils") || this.model.get("value").get("profils").length == 0) {
        this.btnBar.model.trigger("hide:valider");
        //				this.btnBar.model.trigger("hide:accepter");
        //				this.btnBar.model.trigger("hide:refuser");
      }
    }
  },

  /**
   * Shows or hides buttons depending on habilitations
   */
  _manageHabilitation: function() {
    if (!HABILITATION.canCreate(this.context.ctxHabilitation.HabilitationGestion)) {
      this.btnBar.model.trigger("hide:new");
    }
    if (!HABILITATION.canUpdate(this.context.ctxHabilitation.HabilitationGestion)) {
      this.btnBar.model.trigger("hide:delete");
      FORMS.setFieldReadonly(this.$el.find(".saisie\\.sens"), true);
      FORMS.setFieldReadonly(this.$el.find(".motif\\.code"), true);
      FORMS.setFormReadonly(this.$el.find(".gereronebadgeage-content"), true, false);
      this.$el.find(".phx-combobox-button").hide();
    } else if (STR.isBlank(this.isReadOnly) || this.isReadOnly == false) {
      this.btnBar.model.trigger("show:save");
      FORMS.setFormReadonly(this.$el.find(".gereronebadgeage-content"), false, false);
      this.$el.find(".phx-combobox-button").show();
    }
    if (!HABILITATION.canUpdate(this.context.ctxHabilitation.HabilitationGestion) && !HABILITATION.canCreate(this.context.ctxHabilitation.HabilitationGestion)) {
      this.btnBar.model.trigger("hide:revert");
      this.btnBar.model.trigger("hide:save");
    }
    if (!HABILITATION.canView(this.context.ctxHabilitation.HabilitationValidation)) {
      this.btnBar.model.trigger("hide:valider");
    }

    if (this.model.get("mode") == "V") {
      this.btnBar.model.trigger("hide:delete");
      this.btnBar.model.trigger("hide:save");
      this.btnBar.model.trigger("hide:revert");
      this.btnBar.model.trigger("hide:valider");
      this.btnBar.model.trigger("hide:new");
      FORMS.setFormReadonly(this.$el, true, false);
    }
    if (GLOBAL_DATA.paramDivers.get("sai_lisf") && _.isEqual(GLOBAL_DATA.paramDivers.get("sai_lisf").get("valeur"), "0")) {
      FORMS.setFieldReadonly(this.$el.find(".fonction"), true);
    } else {
      FORMS.setFieldReadonly(this.$el.find(".fonction"), false);
    }
  },

  /**
   * Shows or hides buttons depending on habilitations
   */
  _manageActionsForStatut: function() {
    this.isReadOnly = false;

    if (!this._actionsForStatut("creation")) {
      this.btnBar.model.trigger("hide:new");
    }
    if (!this._actionsForStatut("modification")) {
      this.isReadOnly = true;
      this.btnBar.model.trigger("hide:new");
      this.btnBar.model.trigger("hide:save");
      this.btnBar.model.trigger("hide:revert");
      FORMS.setFormReadonly(this.$el.find(".gereronebadgeage-content"), true, true);
    } else {
      this.btnBar.model.trigger("show:new");
      this.btnBar.model.trigger("show:save");
      this.btnBar.model.trigger("show:revert");
      FORMS.setFormReadonly(this.$el.find(".gereronebadgeage-content"), false, false);
    }
    if (!this._actionsForStatut("suppression")) {
      this.btnBar.model.trigger("hide:delete");
    }
    if (!this._actionsForStatut("validation")) {
      this.btnBar.model.trigger("hide:valider");
      //			this.btnBar.model.trigger("hide:accepter");
      //			this.btnBar.model.trigger("hide:refuser");
    }
  },

  /**
   * Manage restrictions for each action
   * Accepte, En course, Demande, Hors validation, refuse, Demande -> 	"A","I","D","H","R","T"
   */
  //	Accepte, En course, Demande, Hors validation, refuse, Demande -> 	"A","I","D","H","R","T"
  //	Manage restrictions for actions
  _actionsForStatut: function(action, stat) {
    //view if can ejecute action where a one statut and a one collaborateur
    var utilisateur = this.context.ctxUtilisateur;
    if (!STR.isBlank(stat)) {
      var statut = stat;
    } else {
      var statut = this.model.get("value").get("statut").code;
    }

    var resp = false;
    //for ever statut
    if (action == "creation") {
      if (utilisateur == "Responsable") {
        resp = true;
      } else if (utilisateur == "Collaborateur") {
        resp = true;
      }
    } else if (action == "validation") {
      if (utilisateur == "Collaborateur") {
        resp = false;
      }
    }

    //for a one statut
    switch (statut) {
      case "D":
      case "T":
        if (action == "modification") {
          if (utilisateur == "Responsable") {
            resp = false;
          } else if (utilisateur == "Collaborateur") {
            resp = true;
          }
        } else if (action == "suppression") {
          if (utilisateur == "Responsable") {
            resp = false;
          } else if (utilisateur == "Collaborateur") {
            resp = true;
          }
        } else if (action == "validation") {
          if (utilisateur == "Responsable") {
            resp = true;
          }
        }
        break;
      case "I":
        if (action == "modification") {
          if (utilisateur == "Responsable") {
            resp = false;
          } else if (utilisateur == "Collaborateur") {
            resp = true;
          }
        } else if (action == "suppression") {
          if (utilisateur == "Responsable") {
            resp = false;
          } else if (utilisateur == "Collaborateur") {
            resp = true;
          }
        } else if (action == "validation") {
          if (utilisateur == "Responsable") {
            resp = true;
          }
        }
        break;
      case "A":
        if (action == "modification") {
          if (utilisateur == "Responsable") {
            resp = true;
          } else if (utilisateur == "Collaborateur") {
            resp = false;
          }
        } else if (action == "suppression") {
          if (utilisateur == "Responsable") {
            resp = true;
          } else if (utilisateur == "Collaborateur") {
            resp = true;
          }
        } else if (action == "validation") {
          if (utilisateur == "Responsable") {
            resp = false;
          }
        }
        break;
      case "R":
        if (action == "modification") {
          if (utilisateur == "Responsable") {
            resp = false;
          } else if (utilisateur == "Collaborateur") {
            resp = false;
          }
        } else if (action == "suppression") {
          if (utilisateur == "Responsable") {
            resp = false;
          } else if (utilisateur == "Collaborateur") {
            resp = false;
          }
        } else if (action == "validation") {
          if (utilisateur == "Responsable") {
            resp = false;
          }
        }
        break;
      case "H":
        if (action == "modification") {
          if (utilisateur == "Responsable") {
            resp = true;
          } else if (utilisateur == "Collaborateur") {
            resp = false;
          }
        } else if (action == "suppression") {
          if (utilisateur == "Responsable") {
            resp = true;
          } else if (utilisateur == "Collaborateur") {
            resp = true;
          }
        } else if (action == "validation") {
          if (utilisateur == "Responsable") {
            resp = false;
          }
        }
        break;
      default:
        resp = true;
        break;
    }
    return resp;
  },

  /**
   * Enables all buttons at initializing
   */
  _habilityButtons: function() {
    this.btnBar.model.trigger("show:save");
    this.btnBar.model.trigger("show:revert");
    this.btnBar.model.trigger("show:new");
    this.btnBar.model.trigger("show:delete");
    this.btnBar.model.trigger("show:accepter");
    this.btnBar.model.trigger("show:refuser");
  },

  /**
   * Manages initializing
   */
  _manageModeInitialisation: function(callback) {
    this._manageWorkflowContect();
    this._habilityButtons();

    if (this.context.ctxModeInitialisation == "Ajout") {
      this._manageValeursCreation();

      this.btnBar.model.trigger("hide:new");
      this.btnBar.model.trigger("hide:delete");
      this.btnBar.model.trigger("hide:accepter");
      this.btnBar.model.trigger("hide:refuser");
      this.btnBar.model.set("mode", "C");

      //New attribute for know if we are saving or deleting a new badgeage
      this.model.get("value").isCreation = true;
      this._mapToForm();

      if (callback) {
        callback();
      }
      this.model.get("value").store();
    } else if (this.context.ctxModeInitialisation == "Consultation") {
      if (!STR.isBlank(this.context.ctxGestionCollab) && !STR.isBlank(this.context.ctxIdentifiantEvt)) {
        var self = this;
        var gererBadgeage = this.model.get("value");
        this.id = encodeURIComponent(this.context.ctxGestionCollab.matricule + "," + this.context.ctxIdentifiantEvt);
        gererBadgeage.setHabContext(this.workflow.getHabContext());

        gererBadgeage.action = "element/" + this.id;
        gererBadgeage.fetch({
          success: function(fresh) {
            fresh.store();
            fresh.setHabContext(self.model.getHabContext());
            self._mapToForm();

            if (fresh.get("historique")) {
              // retrieve workflow information
              if (self.context.ctxHistoriqueWKF === true) {
                var contextComponentWkf = {
                  ctxEcran: self.context.ctxEcran,
                  ctxHabilitation: self.context.ctxHabilitation.HabilitationAcces,
                  ctxTypeEvenement: "BADGEAGE",
                  ctxRefEvenement: fresh.get("evenement"),
                  ctxDemId: fresh.get("iddemande"),
                  ctxModeRestitution: "Imbrique",
                  ctxActivModeSynth: true,
                  ctxModeDefaut: "Replie",
                  ctxModeRech: true

                };

                self.composantWorkflow = new BlockView(contextComponentWkf);
                $(self.el).find(".gereronebadgeage-wkfhisto").html(self.composantWorkflow.render().el);
                self.composantWorkflow.setContext(contextComponentWkf);
                $(self.el).find(".gereronebadgeage-wkfhisto").show();
              }
            }

            if (callback) {
              callback();
            }

            if (self.$el.find("button.valider").is(":visible") == true) {
              self.btnBar.model.trigger("hide:valider");
              var profilModel = new GererOneBadgeageProfilsModel();
              profilModel.profilId = gererBadgeage.get("matricule") + "," + gererBadgeage.get("code");
              profilModel.setHabContext(new HabilitationContext({
                onglet: self.context.ctxEcran,
                fonc_cour: self.context.ctxHabilitation.HabilitationValidation
              }));

              profilModel.fetch({
                success: function(freshProfil) {
                  if (profilModel.get("profils") && profilModel.get("profils").length > 0) {
                    self.model.get("value").set("profils", profilModel.get("profils"));
                    self._configureValidationButton();
                    self.btnBar.model.trigger("show:valider");
                  }
                }
              });
            }
          },
          error: function() {
            if (callback) {
              callback();
            }
          }
        });

      } else {
        if (callback) {
          callback();
        }
        this.model.get("value").store();
      }
    } else if (this.context.ctxModeInitialisation == "InitForAction") {
      if (callback) {
        callback();
      }
    }

    if (this.context.ctxModeSuppression == "SensX") {
      this.btnBar.model.trigger("hide:delete");
    }
  },

  _panelStateIsReadonly: function() {
    var model = this._getModel();
    var isReadOnly = false;

    if (this.context.ctxActionsPossibles.indexOf("Modifier") == -1 && this.context.ctxModeInitialisation == "Consultation") {
      isReadOnly = true;
    }

    //When contextutilisateur is 'Responsable' and statut is demande or en cours de validation,
    // fields are disabled.
    var currentStatut = !STR.isBlank(this.context.BadgeageCodeStatutWKF) ? this.context.BadgeageCodeStatutWKF : model.get("statut").code;
    if (this.context.ctxUtilisateur == "Responsable" &&
      this.context.ctxGestionCollective === false &&
      (currentStatut == "D" || currentStatut == "T" || currentStatut == "I")) {
      isReadOnly = true;
    }
    //When statut is refuser disable the form
    if (model.get("etat") == "R") {
      isReadOnly = true;
    }

    if (isReadOnly == false && model && model.getHabContext && model.getHabContext()) {
      var canUpdate = HABILITATION.canUpdate(model.getHabContext().get("fonc_cour"));
      isReadOnly = !canUpdate && !model.isNew();
    }
    return isReadOnly;
  },

  /**
   * Manages mode representation
   */
  _manageModeRepresentation: function() {
    if (this.context && this.context.ctxModeRepresentation && this.context.ctxModeRepresentation == "pop-up") { // Pop up
      this.$el.find(".gereronebadgeage-content").append(this.btnBar.render().el);
      this.btnBar.model.trigger("hide:copy");
      //				this.btnBar.addButton("accepter", i18n.t('common:gereronebadgeage.btnAccepter'));
      //				this.btnBar.addButton("refuser", i18n.t('common:gereronebadgeage.btnRefuser'));
      this.btnBar.addButton("valider", i18n.t('common:gereronebadgeage.btnValider'));
      this.$el.find("#regulcollab_btnbar").css("float", "left");

    } else {
      this.$el.find(".gereronebadgeage-utilisateur").hide();
      if (this.context && this.context.ctxEcran !== "saisiescoll") {
        this.$el.find(".gereronebadgeage-content").prepend(this.btnBar.render().el);
      }
      this.btnBar.model.trigger("hide:copy");
      //			this.btnBar.addButton("accepter", i18n.t('common:gereronebadgeage.btnAccepter'));
      //			this.btnBar.addButton("refuser", i18n.t('common:gereronebadgeage.btnRefuser'));
      this.btnBar.addButton("valider", i18n.t('common:gereronebadgeage.btnValider'));
    }
  },

  /**
   * Prepares HTML for validation button
   */
  _configureValidationButton: function() {
    var model = this.model.get("value");
    var self = this;
    var profils = model.get("profils");

    var ul = $(".gereronebadgeage-detail", this.el).find("ul.validerListMenu");
    ul.empty();
    if (profils && profils.length === 1 && profils[0] && ul) {
      var accepter = $("<li>");
      var refuser = $("<li>");
      var a = $("<a class='menuItem' data-value='2' >" + i18n.t('common:gereronebadgeage.btnAccepter') + "</a>");
      a.attr("data-code", profils[0].code);
      accepter.append(a);
      ul.append(accepter);

      a = $("<a class='menuItem' data-value='3' >" + i18n.t('common:gereronebadgeage.btnRefuser') + "</a>");
      a.attr("data-code", profils[0].code);
      refuser.append(a);
      ul.append(refuser);
    }
    if (profils && profils.length > 1 && ul) {
      var accepter = $("<li>");
      var refuser = $("<li>");
      accepter.append("<span class='ui-corner-all'>" + i18n.t('common:gereronebadgeage.btnAccepter') + "</span>");
      ul.append(accepter);
      _.each(profils, function(obj) {
        var item = $("<li>");
        var a = $("<a class='menuItem' data-value='2' >" + "- " + obj.libelle + "</a>");
        a.attr("data-code", obj.code);
        item.append(a);
        ul.append(item);
      }, this);

      refuser.append("<span class='ui-corner-all'>" + i18n.t('common:gereronebadgeage.btnRefuser') + "</span>");
      ul.append(refuser);
      _.each(profils, function(obj) {
        var item = $("<li>");
        var a = $("<a class='menuItem' data-value='3'>" + "- " + obj.libelle + "</a>");
        a.attr("data-code", obj.code);
        item.append(a);
        ul.append(item);
      }, this);
    }
    $(".gereronebadgeage-detail", this.el).find("button.valider")
      .button({ icons: { secondary: "ui-icon-triangle-1-s" }, text: true })
      .click(function() {
        var menu = $(".gereronebadgeage-detail", self.el).find("ul.validerListMenu").show().position({
          my: "left top",
          at: "left bottom",
          of: this
        });
        $(document).one("click", function() {
          menu.hide();
        });
        return false;
      });

    if (!profils || profils.length == 0) {
      this.btnBar.model.trigger("hide:valider");
    }

    $(".gereronebadgeage-detail", this.el).find("ul.validerListMenu").hide().menu();
  },

  /**
   * Manages menu button options
   */
  menuItemListener: function(event) {
    var action = event.currentTarget.attributes["data-value"].nodeValue;
    var code = event.currentTarget.attributes["data-code"].nodeValue;
    switch (action) {
      case "2":
        this.btnBar.model.trigger("btn:click", "accepter", code);
        break;
      case "3":
        this.btnBar.model.trigger("btn:click", "refuser", code);
        break;
    }
  },

  /**
   * Renders view
   */
  render: function() {
    var json = { "i18n": i18n, model: this.model.get("value") };
    this.$el.html(this.template(json));

    this._manageModeRepresentation();

    if (this.context) {
      this._manageContext();
    }

    return this;
  },

  /**
   * Renders form
   *
   */
  renderForm: function(callback) {
    var comboHab = this.workflow.getHabContext().copy().set("fonc_cour", this.context.ctxHabilitation.HabilitationGestion);

    // combos need nature.
    comboHab.set("nat_gest", "M");

    //Combo motif absence
    this.comboMotif = new ComboBoxView2({
      ws: new CombosColl({
        name: "absence/composant",
        comboId: "motifs"
      }),
      name: "motif.code",
      width: "190px",
      optionsRender: function(value) {
        if (!value || STR.isBlank(value.libelle) || STR.isBlank(value.code)) {
          return "";
        }
        return value.libelle + " (" + value.code + ")";
      },
      autocomplete: true,
      required: false,
      habContext: comboHab
    });
    this.$el.find(".cmbMotif").html(this.comboMotif.render().el);

    //Combo sens
    this.comboSens = new ComboBoxView2({
      ws: new CombosColl({
        name: "utilbadgeage",
        comboId: "sens"
      }),
      name: "saisie.sens",
      optionsRender: function(value) {
        if (!value || STR.isBlank(value.libelle) || STR.isBlank(value.code)) {
          return "";
        }
        return value.code + " (" + value.libelle + ")";
      },
      required: true,
      habContext: comboHab
    });
    this.comboSens.setFilter(this._filterComboSens());
    this.$el.find(".cmbSens").html(this.comboSens.render().el);

    // Dates
    FORMS.setDatepicker(this, ".saisie\\.date");

    //Combo cmbCommentaire
    let commentHab = this.workflow.getHabContext();
    commentHab.set('fonc_cour', 'COL_BADG.G');
    this.comboCommentaire = new ComboBoxView2({
      ws: new CombosColl({
        name: "utilbadgeage",
        comboId: "commentaires",
        params: {
          tri: "code"
        }
      }),
      name: "commentaireLibelle",
      habContext: commentHab
    });
    $(this.el).find(".cmbCommentaire").html(this.comboCommentaire.render().el);

    if (callback) {
      callback();
    }

    return this;
  },

  /**
   * Filters combo options leaving only options with code different from 'X'
   */
  _filterComboSens: function() {
    var self = this;
    var filter = function(collection) {
      if (self.context.ctxModeSuppression == "Button") {
        var filterCollection = [];

        _.each(collection, function(model, key) {
          if (model.attrs.code != "X") {
            filterCollection.push(model);
          };
        });

        return filterCollection;
      } else {
        return collection;
      }
    };
    return filter;
  },

  /**
   * Maps model to form
   */
  _mapToForm: function() {
    var self = this;
    var context = this.context;
    var objPeriode = null;
    var gererBadgeage = this.model.get("value");
    if (STR.isBlank(this.$el)) {
      return;
    }
    var fieldset = this.$el.find("fieldset");
    var spanStatut = $(this.el).find("fieldset.gereronebadgeage-detail span.actionbadgeage");

    // Affichage du type d’événement
    if (gererBadgeage.get("etat") == "X") {
      spanStatut.html(i18n.t('common:gereronebadgeage.demandeSuppressionBadgeage'));
      spanStatut.addClass("ui-phx-titre-demande-suppression");
    }

    // utilisateur
    if (context.ctxUtilisateur == "Responsable" &&
      context.ctxGestionCollective === false &&
      !STR.isBlank(context.ctxGestionCollab)) {
      var utilisateur = context.ctxGestionCollab.nom + " " + context.ctxGestionCollab.prenom;
      // Default value is matric
      if (this.divers == "matric_aux") {
        utilisateur += " (" + this.context.ctxGestionCollab.matric_aux + ")";
      } else {
        utilisateur += " (" + this.context.ctxGestionCollab.matricule + ")";
      }
      $(this.el).find(".gereronebadgeage-utilisateur").html(utilisateur);
    }

    //Date ou période
    if (!STR.isBlank(context.ctxGestionDate) && STR.isBlank(context.ctxGestionPeriode)) {
      var formatDate = TYPE.DATE.format(context.ctxGestionDate, TYPE._getFormatByCode("DATE_L"));
      objPeriode = i18n.t('messages:GT_1143', { "1": formatDate });

      $(this.el).find(".gereronebadgeage-date").html(objPeriode);
      $(this.el).find(".gereronebadgeage-date").append("<br/><br/>");
    } else if (!STR.isBlank(context.ctxGestionPeriode) && STR.isBlank(context.ctxGestionDate)) {
      var formatDateDeb = TYPE.DATE.format(context.ctxGestionPeriode.datedebut, TYPE._getFormatByCode("DATE_L"));
      var formatDateFin = TYPE.DATE.format(context.ctxGestionPeriode.datefin, TYPE._getFormatByCode("DATE_L"));
      var formatHeureDeb = TYPE.HOUR_MINUTE.format(context.ctxGestionPeriode.heuredebut);
      var formatHeureFin = TYPE.HOUR_MINUTE.format(context.ctxGestionPeriode.heurefin);

      if (context.ctxGestionPeriode.datedebut == context.ctxGestionPeriode.datefin) {
        objPeriode = i18n.t('messages:GT_1144', { "1": formatDateDeb, "2": formatHeureDeb, "3": formatHeureFin });
      } else {
        objPeriode = i18n.t('messages:GT_1196', { "1": formatDateDeb, "2": formatHeureDeb, "3": formatDateFin, "4": formatHeureFin });
      }

      $(this.el).find(".gereronebadgeage-date").html(objPeriode);
      $(this.el).find(".gereronebadgeage-date").append("<br/><br/>");
    }

    //action
    if (!gererBadgeage.isNew()) {
      var style = "ui-phx-statut-demande";

      switch (gererBadgeage.get("statut").code) {
        case "D":
        case "T":
          style = "ui-phx-statut-demande";
          break;
        case "I":
          style = "ui-phx-statut-en-cours";
          break;
        case "A":
          style = "ui-phx-statut-accepte";
          break;
        case "R":
          style = "ui-phx-statut-refuse";
          break;
        case "H":
          style = "ui-phx-statut-hors-validation";
          break;
      }
      $(this.el).find(".gereronebadgeage-action").html(gererBadgeage.get("statut").libelle);
      $(this.el).find(".gereronebadgeage-action").addClass(style);
      $(this.el).find(".gereronebadgeage-action").show();
    } else {
      //			spanStatut.empty();
    }

    var renderers = {};
    renderers["motif.code"] = function() {
      if (!STR.isBlank(gererBadgeage.get("motif").code)) {
        return gererBadgeage.get("motif").libelle + " (" + gererBadgeage.get("motif").code + ")";
      }
    };

    if (this.showCombo) {
      renderers["commentaireLibelle"] = function() {
        return gererBadgeage.get("commentaire");
      };
    }

    // Error management
    gererBadgeage.off("invalid");
    gererBadgeage.on("invalid", this._showValidationErrors, this);
    // Clear current validation erros
    this._cleanValidationErrors();

    this._convertSensBadgeageVirtual(gererBadgeage);

    this._mapModelToForm(fieldset, gererBadgeage, renderers);

    if (!STR.isBlank(context.ctxGestionDate) && STR.isBlank(context.ctxGestionPeriode)) {
      $(this.el).find(".optionsDate").hide();
      $(this.el).find(".heure_label").css("float", "left");
      $(this.el).find(".heure_label").css("padding-left", "0px");
      $(this.el).find(".heure_label").html(i18n.t('common:gereronebadgeage.heure'));
    }

    this.btnBar.model.set("mode", "R");
  },

  /**
   * Converts sens to uppercase
   */
  //convert the sens Badgeages from virtual (lower case) to saisie (upper case)
  _convertSensBadgeageVirtual: function(gererBadgeage) {
    if (gererBadgeage.get("saisie") && !STR.isBlank(gererBadgeage.get("saisie").sens)) {
      gererBadgeage.get("saisie").sens = gererBadgeage.get("saisie").sens.toUpperCase();
    }
  },

  /**
   * Reverts view
   */
  revertView: function() {
    var self = this;

    if (this.context.ctxModeRepresentation == "pop-up" && this.context.ctxModeInitialisation == "Ajout") {
      self.parent.close();
    } else {

      if (this.model.get("value").isNew()) {
        var previous = this.model.previous("value");
        if (previous) {
          this.model.unset("value", { silent: true });
          this.model.set("value", previous);
        } else {
          var oneBadgeage = new ListeBadgeageModel({ utilisateur: this.context.ctxUtilisateur }); //gererregularisations.GererRegularisationsModel();
          oneBadgeage.usecase = this.context.ctxEcran;
          oneBadgeage.habilitationG = this.context.ctxHabilitation.HabilitationGestion;

          this.model.unset("value", { silent: true });
          this.model.set("value", oneBadgeage);
          this._manageValeursCreation();
        }
      } else {
        this.model.get("value").revert();
      }
    }

    this.model.trigger("change:value");
    this.workflow.btnBarModel.set("mode", "R");

    //if a revert get the focus to 1º element
    // Set WAI-ARIA attributes
    if ($(this.el).find(".optionsDate").css("display") != "none") {
      $(this.el).find(".saisie\\.date").focus();
    } else {
      $(this.el).find(".saisie\\.heure").focus();
    }
  },

  /**
   * Manages tooltip for action label
   */
  _openTooltipListener: function(event) {
    if (this.context.ctxHistoriqueWKF === false) {
      var demande = null;
      var reference = null;
      if (this.model.get("value").get("iddemande") == 0) {
        reference = this.model.get("value").get("evenement");
      } else {
        demande = this.model.get("value").get("iddemande");
      }

      var jquery = event.currentTarget.attributes["aria-describedby"];
      if (jquery) {
        return;
      }

      var self = this;
      this.tooltipDemande = demande || reference;

      var infobulleWorkflow = new BlockView();
      var viewRender = infobulleWorkflow.render().el;

      var contextInfobulleWkf = {
        ctxEcran: this.context.ctxEcran,
        ctxHabilitation: this.context.ctxHabilitation.HabilitationAcces,
        ctxTypeEvenement: "BADGEAGE",
        ctxRefEvenement: this.model.get("value").get("evenement"),
        ctxDemId: this.model.get("value").get("iddemande"),
        ctxModeRestitution: "infobulle",
        ctxActivModeSynth: true,
        ctxModeDefaut: "Replie",
        ctxModeRech: true
      };
      infobulleWorkflow.setContext(contextInfobulleWkf);

      $(event.currentTarget).tooltip({ content: "" });
      $(event.currentTarget).tooltip("option", "content", function() {
        return infobulleWorkflow.$el.html();
      });
      $(event.currentTarget).prop("title", "");

      infobulleWorkflow.model.get("histoDem").once("sync", function() {
        try {
          var demande = event.currentTarget.attributes.demande.value;
          if (self.tooltipDemande == demande) {
            $(event.currentTarget).tooltip("open");
          }
        } catch (error) {

        }
      });

      infobulleWorkflow.model.get("histoEvent").once("sync", function() {
        try {
          var reference = event.currentTarget.attributes.ensemble.value;
          if (self.tooltipDemande == reference) {
            $(event.currentTarget).tooltip("open");
          }
        } catch (error) {

        }
      });
    }
  },
  /**
   * Manages closing tooltip for action label
   */
  _closeTooltipListener: function() {
    try {
      this.tooltipDemande = null;
      $(event.currentTarget).tooltip("destroy");

    } catch (error) {

    }
  },

  /**
   * Save one badgeage
   */
  saveOneBadgeages: function(action, callback) {
    var self = this;
    var target = this.model.get("value");
    target.set("matricule", this.context.ctxGestionCollab.matricule);
    target.action = action;
    if (target.isNew()) {
      target.id = null;
    }

    target.setHabContext(new HabilitationContext({
      onglet: this.context.ctxEcran,
      fonc_cour: this.context.ctxHabilitation.HabilitationGestion,
      nat_gest: "M"
    }));

    if (this.showCombo) {
      target.checkComments = true;
      target.set("commentaire", $(this.el).find("input.commentaireLibelle").val());
    }

    target.save(null, {
      success: function(fresh) {
        target.checkComments = false;
        if (callback) {
          callback(fresh);
        }
        self.parent.close();
      }
    });
  },
  /**
   * Modify one badgeage
   */
  modifiableOneBadgeages: function(callback) {
    var modifiableBadgeage = new GererBadgeagesGestion();

    modifiableBadgeage.action = "modifiable";
    modifiableBadgeage.id = encodeURIComponent(this.model.get("value").id);

    modifiableBadgeage.setHabContext(new HabilitationContext({
      onglet: this.context.ctxEcran,
      fonc_cour: this.context.ctxHabilitation.HabilitationAcces
    }));

    modifiableBadgeage.fetch({
      success: function(fresh) {
        if (callback) {
          callback(fresh);
        }
      }
    });
  },
  /**
   * Fetch the badgeage
   */
  fetchBadgeage: function(action, callback) {
    var self = this;
    var target = new ListeBadgeageModel({ utilisateur: this.context.ctxUtilisateur });
    target.setHabContext(new HabilitationContext({
      onglet: this.context.ctxEcran,
      fonc_cour: this.context.ctxHabilitation.HabilitationAcces
    }));

    target.action = action;
    target.fetch({
      success: function(freshModel) {
        self.model.set("value", freshModel);

        if (callback) {
          callback(freshModel);
        }
      }
    });
  },
  /**
   * Create new badgeage
   */
  newOneBadgeages: function() {
    //var proven = "";
    this.model.set("value", this.EMPTY_BADGEAGE, { silent: true });
    this._manageValeursCreation();
    this.model.get("value").set("matricule", this.context.ctxGestionCollab.matricule);
    //default values
    if (!STR.isBlank(this.context.ctxGestionDate)) {
      this.model.get("value").get("saisie").date = this.context.ctxGestionDate;
    }

    //if map default values
    this._mapToForm();

    //this.model.set("value", target);
    $(this.el).find(".gereronebadgeage-action").hide();
    $(this.el).find(".gereronebadgeage-wkfhisto").hide();
  },
  /**
   * Delete the badegeage
   */
  deleteOneBadgeages: function(comment, silent) {
    var self = this;
    var target = new GererBadgeagesGestion();
    var droit = this.model.get("value").get("droit");
    var originalBadgeage = this.model.get("value");
    target.action = "demande";
    target.id = encodeURIComponent(this.model.get("value").id);
    if (!droit) {
      droit = "N";
    }
    // nature should be "S". in deletion
    this.model.getHabContext().set("nat_gest", "S");

    target.setHabContext(this.model.getHabContext());
    if (!STR.isBlank(comment)) {
      target.set("commentaire", comment);
    } else {
      target.set("commentaire", " ");
      target.set("droit", "N");
    }

    target.destroy({
      contentType: 'application/json',
      url: Configuration.restRoot + "/rest/badgeage/composant/" + target.action + "/" + target.id + "/" + encodeURIComponent(target.get("commentaire")) + "/" + encodeURIComponent(droit),
      success: function(fresh) {
        if (self.context.ctxModeRepresentation === "pop-up") {
          self.parent.close();
        }

        self.model.trigger("badgeageChanged", originalBadgeage, "delete");
      }
    });
  },

  deleteBadgeageFromMenuButton: function(badgeage) {
    this.id = encodeURIComponent(badgeage.id);
    badgeage.setHabContext(this.model.getHabContext());
    this.model.set("value", badgeage, { silent: true });
    this.btnBar.model.trigger("btn:click", "delete");
  },

  deleteTousBadgeages: function(badgeages, length) {
    var self = this;
    var premier = true;
    var objModel;
    var statut;
    var dialog;
    var dialogModel;
    var badgeagesOk = [];

    for (var j = 0; j < length; j++) {
      if (this._actionsForStatut("suppression", badgeages[j].get("statut").code)) {
        badgeagesOk.push(badgeages[j]);
      }
    }

    if (badgeagesOk.length > 0) {
      dialog = this._initPopUp("suppressionTous", "", badgeagesOk.length);
      dialogModel = dialog.model;
      dialog.open(function() {
        if (dialogModel.get("action") == "OK") {
          for (var i = 0; i < badgeagesOk.length; i++) {
            objModel = badgeagesOk[i];
            statut = objModel.get("statut").code;
            self.model.set("value", objModel, { silent: true });

            if (statut === "A" || statut === "H" ||
              (statut === "I" && this.context.ctxUtilisateur.toUpperCase() == "COLLABORATEUR")) {
              self.deleteOneBadgeages(dialogModel.get("commentaire"), true);
            } else {
              self.deleteOneBadgeages("", true);
            }
          }
        }
      });
      dialog._setTitle(i18n.t('common:gereronebadgeage.title_popUp'));
    }

  },

  /**
   * Accept badgeage
   */
  accepterOneBadgeages: function(commentaire, profil) {
    var self = this;
    var target = new GererBadgeagesGestion();
    var droit = this.model.get("value").get("droit");
    var originalBadgeage = this.model.get("value");
    target.action = "accepter";
    target.id = encodeURIComponent(this.model.get("value").id);
    target.set("commentaire", commentaire);
    target.set("droit", droit);
    // Profil isn't a object always: It can be a string or null value
    // We can obtain it from originalBadgeage too.
    if (profil != null && profil != "") {
      if (profil instanceof Object && profil.code && profil.libelle) {
        target.set("profil", profil);
      } else {
        target.set("profil", { "code": profil, "libelle": profil });
      }
    } else {
      target.set("profil", originalBadgeage.get("profil"));
    }

    target.setHabContext(new HabilitationContext({
      onglet: this.context.ctxEcran,
      fonc_cour: this.context.ctxHabilitation.HabilitationValidation,
      nat_gest: "M"
    }));

    target.save(null, {
      success: function(fresh) {
        if (self.context.ctxModeRepresentation === "pop-up") {
          self.parent.close();
        }
        self.model.trigger("badgeageChanged", originalBadgeage, "update");
      }
    });

  },

  accepterBadgeageFromMenuButton: function(badgeage) {
    this.id = encodeURIComponent(badgeage.id);
    badgeage.setHabContext(this.model.getHabContext());
    this.model.set("value", badgeage, { silent: true });
    this.btnBar.model.trigger("btn:click", "accepter", badgeage.get("profil"));
  },

  accepterTousBadgeages: function(badgeages, length) {
    var self = this;
    var premier = true;
    var objModel;
    var statut;
    var dialog;
    var dialogModel;
    var badgeagesOk = [];

    for (var j = 0; j < length; j++) {
      if (this._actionsForStatut("validation", badgeages[j].get("statut").code)) {
        badgeagesOk.push(badgeages[j]);
      }
    }
    if (badgeagesOk.length > 0) {
      dialog = this._initPopUp("accepterTous", "D");
      dialogModel = dialog.model;
      dialog.open(function() {
        if (dialogModel.get("action") == "OK") {

          for (var i = 0; i < badgeagesOk.length; i++) {
            objModel = badgeagesOk[i];
            statut = objModel.get("statut").code;
            self.model.set("value", objModel, { silent: true });

            self.accepterOneBadgeages(dialogModel.get("commentaire"), objModel.get("profil"));
          }
        }
      });
      dialog._setTitle(i18n.t('common:gereronebadgeage.title_popUp'));
    }
  },

  /**
   * Refuse badgeage
   */
  refuserOneBadgeages: function(commentaire, profil) {
    var self = this;
    var target = new GererBadgeagesGestion();
    var droit = this.model.get("value").get("droit");
    var originalBadgeage = this.model.get("value");

    target.action = "refuser";
    target.id = encodeURIComponent(this.model.get("value").id);
    target.set("commentaire", commentaire);
    target.set("droit", droit);
    // Profil isn't a object always: It can be a string or null value
    // We can obtain it from originalBadgeage too.
    if (profil != null && profil != "") {
      if (profil instanceof Object && profil.code && profil.libelle) {
        target.set("profil", profil);
      } else {
        target.set("profil", { "code": profil, "libelle": profil });
      }
    } else {
      target.set("profil", originalBadgeage.get("profil"));
    }

    target.setHabContext(new HabilitationContext({
      onglet: this.context.ctxEcran,
      fonc_cour: this.context.ctxHabilitation.HabilitationValidation,
      nat_gest: "M"
    }));

    target.save(null, {
      success: function(fresh) {
        if (self.context.ctxModeRepresentation === "pop-up") {
          self.parent.close();
        }
        self.model.trigger("badgeageChanged", originalBadgeage, "update");
      }
    });

  },

  refuserBadgeageFromMenuButton: function(badgeage) {
    this.id = encodeURIComponent(badgeage.id);
    badgeage.setHabContext(this.model.getHabContext());
    this.model.set("value", badgeage, { silent: true });
    this.btnBar.model.trigger("btn:click", "refuser", badgeage.get("profil"));
  },

  refuserTousBadgeages: function(badgeages, length) {
    var self = this;
    var premier = true;
    var objModel;
    var statut;
    var dialog;
    var dialogModel;
    var badgeagesOk = [];

    for (var j = 0; j < length; j++) {
      if (this._actionsForStatut("validation", badgeages[j].get("statut").code)) {
        badgeagesOk.push(badgeages[j]);
      }
    }

    if (badgeagesOk.length > 0) {
      dialog = this._initPopUp("refuserTous", "D");
      dialogModel = dialog.model;
      dialog.open(function() {
        if (dialogModel.get("action") == "OK") {

          for (var i = 0; i < badgeagesOk.length; i++) {
            objModel = badgeagesOk[i];
            statut = objModel.get("statut").code;
            self.model.set("value", objModel, { silent: true });

            self.refuserOneBadgeages(dialogModel.get("commentaire"), objModel.get("profil"));
          }
        }
      });
      dialog._setTitle(i18n.t('common:gereronebadgeage.title_popUp'));
    }
  },

  _initPopUp: function(act, text, nombre) {
    if (text) {
      var stat = text;
    } else {
      var stat = this.model.get("value").get("statut").code;
    }

    var ctxUtilisateur = this.context.ctxUtilisateur;
    var dialog = new DialogView({
      view: GererOneBadgeagePopUpView,
      viewData: {
        action: act,
        statut: stat,
        utilisateur: ctxUtilisateur,
        nombre: nombre
      }
    });

    dialog.setHeight(220);
    dialog.setWidth(445);

    return dialog;
  },

  /**
   * Validate badgeage
   */
  valider: function(callback) {
    var self = this;
    var target = this.model.get("value");
    //var validerModel = new GererAbsenceSouhaitGestion();
    target.action = "validesaisie";

    // CUSTOMER 143376
    if (target.get("saisie") && target.attributes.datedeb) {
      target.get("saisie").date = target.get('datedeb');
      delete target.attributes.datedeb;
    }
    if (target.get("saisie") && target.attributes.datefin) {
      target.get("saisie").date = target.get('datefin');
      delete target.attributes.datefin;
    }
    //validerModel.id =  target.id;
    target.unset("result");

    target.setHabContext(this.model.getHabContext());

    target.save(null, {
      success: function(model, response) {
        if (response.result === "ok") {

          // CUSTOMER 143376
          // Old WS errors remain after ok WS call, so we have to clear them in 'ok' case.
          self._cleanValidationErrors();

          if (callback) {
            callback();
          }
          //self.model.trigger("validate");
        }
      }
    });
  }
});
