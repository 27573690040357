import { HABILITATION } from '../../../utils/habilitation.js';
import { STR } from '../../../utils/str.js';
import TPL_common_dropDownViewContainerComponent from './dropDownViewContainerComponent.tpl.html';

/**
 * Auxiliar view for Dialog Vue journée
 */
export var BlockView = Backbone.View.extend({

  initialize: function(options) {
    this.template = TPL_common_dropDownViewContainerComponent;
    this.templateHTML = $(this.template());
    this.$block = this.templateHTML;
    this.$icon = this.templateHTML.find(".phx-block-title-icon");
    this.$title = this.templateHTML.find(".phx-block-title-text");
    this.$content = this.templateHTML.find(".phx-block-content");

    //flag for build menu
    this.contentVoid = true;

    this.titleHTML = null;
    this.iconHidden = false;
    if (!STR.isBlank(options)) {
      if (options.hSeparator) {
        this.iconHidden = options.iconHidden;
      }
      this.detailTemplate = options.detailTemplate;
      if (!STR.isBlank(options.expanded)) {
        this.expanded = options.expanded;
      } else {
        this.expanded = false;
      }
      this.component = options.component;
      this.buttons = options.buttons;
      this.buttonsLine = options.buttonsLine;
      this.hSeparator = false;
      if (options.hSeparator) {
        this.hSeparator = options.hSeparator;
      }
      this.ctxEcran = options.ctxEcran;
      this.popupGerer = false;
      if (options.popupGerer) {
        this.popupGerer = options.popupGerer;
      }
      this.secondaryAff = false;
      if (options.component === "journee_affectations" && options.typeAff === "AS" || options.typeAff === "AE") {
        this.secondaryAff = true;
      }
    }
    // Set WAI-ARIA attributes
    this.$block.attr("role", "region");
    this.$block.attr("tabindex", "0");
    this.withFocus = false;
  },

  menuBar: false,

  setButtons: function(arrayButtons) {
    this.buttons = arrayButtons;
  },

  setButtonsLine: function(arrayButtons) {
    this.buttonsLine = arrayButtons;
  },

  events: {
    "click span.phx-block-title-icon": "_clickHandler",
    "keydown": "_keydownHandler"
  },

  canAccess: function() {
    return this.context && this.context.ctxHabilitation && this.context.ctxHabilitation.HabilitationAcces &&
      HABILITATION.canView(this.context.ctxHabilitation.HabilitationAcces);
  },

  canManage: function() {
    return this.context && this.context.ctxHabilitation && this.context.ctxHabilitation.HabilitationGestion &&
      HABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion);
  },

  canCreate: function() {
    return this.context && this.context.ctxHabilitation && this.context.ctxHabilitation.HabilitationGestion &&
      HABILITATION.canCreate(this.context.ctxHabilitation.HabilitationGestion);
  },

  canUpdate: function() {
    return this.context && this.context.ctxHabilitation && this.context.ctxHabilitation.HabilitationGestion &&
      HABILITATION.canUpdate(this.context.ctxHabilitation.HabilitationGestion);
  },

  canDelete: function() {
    return this.context && this.context.ctxHabilitation && this.context.ctxHabilitation.HabilitationGestion &&
      HABILITATION.canDelete(this.context.ctxHabilitation.HabilitationGestion);
  },

  canValidate: function() {
    return this.context && this.context.ctxHabilitation && this.context.ctxHabilitation.HabilitationValidation &&
      HABILITATION.canView(this.context.ctxHabilitation.HabilitationValidation);
  },

  render: function(keepVisualizationState) {
    this.$el.html(this.templateHTML);

    this.$title.html(this.titleHTML);

    if (this.iconHidden) {
      this.$icon.hide();

      if (this.ctxEcran === "agenda" && this.secondaryAff === false) {
        this.$title.css("margin-left", "0.4em");
      }
    }

    if (this.expanded === true) {
      this.expand();
    }

    // show the bar
    var self = this;
    // show the bar
    $(this.el).find(".phx-block-title").hover(
      function(e) {
        //self._hoverBtnTitle(e);
        self._onHoverBtnTitle(e);
      },
      function(e) {
        self._onHoverLeftBtnTitle(e);
      }
    );

    // slice the position of the bar
    $(this.el).find(".phx-block-title-text").mousemove(function(event) {
      if (event.target.className.indexOf("phx-block-title-text") !== -1) {
        $(".phx-block-menu-multi-icons").css("left", event.offsetX  + "px");
      }
    });

    if (this.hSeparator === true) {
      this.$el.append("<div class=\"\" style=\"background: none;color: none;border-right: none;border-top: none;border-left: none;\"></div>");
    }

    if (keepVisualizationState !== true) {
      this.hideBlock();
      if (STR.isBlank(this.context) === false) {
        this.showBlock();
      }
    }

    if (this.ctxEcran === "agenda" && this.secondaryAff === true) {
      this.templateHTML.find(".phx-block-title").css("margin-left", "2em");
      this.$content.css("margin-left", "4em");
    }

    return this;
  },

  _clickHandler: function() {
    if (!STR.isBlank(this.$icon) && this.$icon.length > 0 && this.$icon.css("display") !== "none") {
      if (this.$icon.hasClass("phx-icon-triangle-e")) {
        this.expand();
      } else {
        this.collapse();
      }
    }
  },

  _keydownHandler: function(e) {
    if (e.target.className.split(" ")[0] === "phx-block") {
      var key = e.which || e.keyCode;
      if (key === 13 /* enter */ || key === 32 /* space */ ) {
        e.preventDefault();
        this._clickHandler();
      }
    }
  },

  setTitle: function(html) {
    this.titleHTML = html;
    if (this.canAccess() && !STR.isBlank(this.$title) && this.$title.length > 0) {
      this.$title.html(this.titleHTML);
      // Set WAI-ARIA attributes
      this.$block.attr("labelledby", this.cid + "-vuejournee-component");
      this.$title.attr("id", this.cid + "-vuejournee-component");
    } else {
      // Set WAI-ARIA attributes
      this.$block.attr("labelledby", "");
    }
  },

  /**
   *  When we hover the title, it will show a button bar
   */
  _hoverBtnTitle: function(event) {
    if (event.type === "mouseenter") { // When the mouse enter on the title block
      var self = this;
      if (this.menuBar !== true) {
        // Define the JQuery buttons
        this.JQueryBtn = [];
        _.each(this.buttons, function(button) {
          var htmlButton = '<span class="btn_vuejour_component btn_vue_' + self.component + " " + button.id + '">' + button.text + '</span>';

          self.JQueryBtn.push($(htmlButton).button());
        });

        // We show a btnBar and handle the click event
        this.$el.find(".phx-block-title").prepend(this.JQueryBtn);
        //this.$el.find(".btn_vue_" + this.component).css("float : right");
        $(".btn_vue_" + this.component).click(function(event) {
          self._manageButtons(event);
        });
      } else {
        if (!STR.isBlank(this.buttons)) {
          var div = $("<div>").addClass("menu_btn_vue_" + this.component);
          div.addClass("phx-block-menu-multi-icons");
          div.css("display", "inline-block");
          //div.css("float", "right");
          _.each(this.buttons, function(button) {
            var span = "";

            if (!STR.isBlank(button.text)) {
              span = '<span class="phx-block-text-tous menu_btn_vuejour_component menu_btn_vue_' + self.component + " " +
                button.id + '" style="display:inline-block; vertical-align:top">' + button.text + '</span>';
            } else {
              span = '<span class="menu_btn_vuejour_component menu_btn_vue_' + self.component + " " + button.id + " " +
                button.icons + '" style="display:inline-block; vertical-align:top"></span>';
            }

            div.append(span);
          });

          this.$el.find(".phx-block-title").prepend(div);

          $(div).css("z-index", "100");
          $(div).css("position", "absolute"); // <<< also make it absolute!
          $(div).position({
            my: "left top",
            at: "left bottom",
            of: event.currentTarget
          });

          $(div).css("top", parseInt($(div).css("top")) - 4);

          $("span.menu_btn_vue_" + this.component).click(function(event) {
            self._manageButtons(event);
          });
          $("div.phx-block-menu-multi-icons").mouseleave(function(event) {
            self._hoverBtnTitle(event);
          });

        }
      }

    } else if (!(event.type === "mouseleave" && $(event.relatedTarget).hasClass("menu_btn_vue_" + this.component))) { // When the mouse get out the title block
      this.$el.find(".phx-block-title .btn_vue_" + this.component).remove();
      this.$el.find(".phx-block-title .phx-block-menu-multi-icons").remove();
    }
  },

  _onHoverBtnTitle: function(event) {
    var self = this;
    var parent = $(event.currentTarget);
    parent.css("position","relative");
    if (this.menuBar !== true) {
      var element = parent.find(".btn_vue_" + this.component);

      if (element.length === 0) {
        this.JQueryBtn = [];
        _.each(this.buttons, function(button) {
          var htmlButton = '<span class="btn_vuejour_component btn_vue_' + self.component + " " + button.id + '">' + button.text + '</span>';

          self.JQueryBtn.push($(htmlButton).button());
        });

        parent.prepend(this.JQueryBtn);

        $(".btn_vue_" + this.component).click(function(event) {
          self._manageButtons(event);
        });
      }
    } else {
      var element = parent.find(".menu_btn_vue_" + this.component);

      if (element.length === 0 && !STR.isBlank(this.buttons)) {
        var div = $("<div>").addClass("menu_btn_vue_" + this.component);
        div.addClass("phx-block-menu-multi-icons");
        div.css({
          "display": "inline-block",
          "position": "absolute",
          "float": "left",
          "z-index": "100",
          "left": event.offsetX ,
          "top": "75%"
        });

        _.each(this.buttons, function(button) {
          if (!STR.isBlank(button.text)) {
            var span = '<span class="phx-block-text-tous menu_btn_vuejour_component menu_btn_vue_' + self.component + " " +
              button.id + '" style="display:inline-block; vertical-align:top">' + button.text + '</span>';
          } else {
            var span = '<span class="menu_btn_vuejour_component menu_btn_vue_' + self.component + " " + button.id + " " +
              button.icons + '" style="display:inline-block; vertical-align:top"></span>';
          }

          div.append(span);
        });

        parent.prepend(div);

        $(".menu_btn_vue_" + this.component).click(function(e) {
          self._manageButtons(e);
        });

        $(event.currentTarget).children(":not(.menu_btn_vue_" + this.component + ")").bind('mousemove', function(e) {
          div.css({
            "left": parseInt(e.offsetX) ,
          });
        });

        div.bind('mouseleave', function(e) {
          var parent = $(e.relatedTarget);

          if (!parent.hasClass("phx-block-title")) {
            parent = parent.parents(".phx-block-title").first();
          }

          if (parent.find(".menu_btn_vue_" + self.component).length === 0) {
            self.$el.find(".phx-block-title .btn_vue_" + self.component).remove();
            self.$el.find(".phx-block-title .phx-block-menu-multi-icons").remove();
          }
        });
      }
    }
  },

  _onHoverLeftBtnTitle: function(event) {
    if (!$(event.relatedTarget).hasClass("menu_btn_vue_" + this.component)) {
      this.$el.find(".phx-block-title .btn_vue_" + this.component).remove();
      this.$el.find(".phx-block-title .phx-block-menu-multi-icons").remove();
    }
  },

  _onHoverBtnLine: function(event) {
    var self = this;
    var parent = $(event.currentTarget);
    parent.css("position","relative");
    var element = parent.find(".line_btn_vue_" + this.component);
    if (element.length === 0) {
      var div = $("<div>").addClass("line_btn_vue_" + this.component);
      div.addClass("phx-block-menu-multi-icons");
      div.css({
        "display": "inline-block",
        "position": "absolute",
        "float": "left",
        "z-index": "100",
        "left": event.offsetX ,
        "top": "75%"
      });

      var key = event.currentTarget.className.split(" ")[1];

      var showButton = false;

      _.each(this.buttonsLine, function(buttonLine) {
        var valid = true;

        if (buttonLine.validateLine) {
          valid = buttonLine.validateLine(buttonLine.id, key);
        }
        if (valid) {
          showButton = true;
        }

        if (valid) {
          var span = "";
          if (!STR.isBlank(buttonLine.text)) {
            span = '<span style="display:inline-block" class="phx-block-text-line line_btn_vuejour_component line_btn_vue_' + self.component + " " + buttonLine.id + '">' + buttonLine.text + '</span>';
          } else {
            span = '<span style="display:inline-block" class="line_btn_vuejour_component line_btn_vue_' + self.component + " " + buttonLine.id + " " + buttonLine.icons + '"></span>';
          }

          div.append(span);
        }
      });

      $(event.currentTarget).children().bind('mousemove', function(e) {
        div.css({
          "left": parseInt(e.offsetX) ,
        });
      });

      if (showButton) {
        parent.prepend(div);
      }

      $(".line_btn_vue_" + this.component).click(function(e) {
        self._manageButtonsLine(e, key);
        e.stopPropagation();
      });

      div.bind('mouseleave', function(e) {
        self._onHoverLeftBtnLine(e);
      });
    }
  },

  _onHoverLeftBtnLine: function(event) {
    if (!$(event.relatedTarget).hasClass("line_btn_vue_" + this.component)) {
      this.$el.find(".phx-block-content .phx-block-menu-multi-icons").remove();
    }
  },

  /**
   *  When we hover a line, it will show a button bar
   */
  _hoverBtnLine: function(event, key) {
    var parent = $(event.currentTarget);
    parent.parent().css("position","relative");
    if (event.type === "mouseenter") { // When the mouse enter on the title block
      var self = this;
      var spans = [];
      _.each(this.buttonsLine, function(buttonLine) {
        var valid = true;
        var disabled = false;
        if (buttonLine.validateLine) {
          valid = buttonLine.validateLine(buttonLine.id, key);
        }
        if (buttonLine.disableLine) {
          disabled = buttonLine.disableLine(buttonLine.id, key);
        }
        if (valid) {
          var span = "";

          if (disabled) {
            if (!STR.isBlank(buttonLine.text)) {
              span = '<span style="display:inline-block" class="phx-block-text-line line_btn_vuejour_component line_btn_vue_disabled_' + self.component + " " + buttonLine.id + '">' + buttonLine.text + '</span>';
            } else {
              span = '<span style="display:inline-block" class="nohover line_btn_vuejour_component line_btn_vue_disabled_' + self.component + " " + buttonLine.id + " " + buttonLine.icons + '"></span>';
            }
          } else {
            if (!STR.isBlank(buttonLine.text)) {
              span = '<span style="display:inline-block" class="phx-block-text-line line_btn_vuejour_component line_btn_vue_' + self.component + " " + buttonLine.id + '">' + buttonLine.text + '</span>';
            } else {
              span = '<span style="display:inline-block" class="line_btn_vuejour_component line_btn_vue_' + self.component + " " + buttonLine.id + " " + buttonLine.icons + '"></span>';
            }
          }

          spans.push(span);
        }

      });

      if (spans.length > 0) {
        var div = $("<div>").addClass("line_btn_vue_" + this.component);
        div.addClass("phx-block-menu-multi-icons");
        div.css("display", "inline-block");
        _.each(spans, function(span) {
          div.append(span);
        });
        this.$el.find(".phx-block-content").prepend(div);
        $(".line_btn_vue_" + this.component).click(function(event) {
          self._manageButtonsLine(event, key);
          event.stopPropagation();
        });

        $(div).css("z-index", "100");
        $(div).css("position", "absolute"); // <<< also make it absolute!

        $(div).position({
          my: "left top",
          at: "left bottom",
          of: event.currentTarget
        });

        $(div).css("top", parseInt($(div).css("top")) - 3);

        $("div.phx-block-menu-multi-icons").mouseleave(function(event) {
          self._hoverBtnLine(event);
        });
        $(".phx-block-content-line." + key).mousemove(function(event) {
          self._hoverBtnLine(event);
        });
      }

    } else if (event.type === "mousemove") {
      this.$el.find(".phx-block-content .phx-block-menu-multi-icons").css("left", event.offsetX  + "px");
    } else if (!(event.type === "mouseleave" && $(event.relatedTarget).hasClass("line_btn_vue_" + this.component))) {
      this.$el.find(".phx-block-content .phx-block-menu-multi-icons").remove();
    }
  },

  /**
   * JS Interface.
   */
  _manageButtons: function() {
    return false;
  },

  _manageButtonsLine: function() {
    return false;
  },

  setContent: function(html) {
    if (this.canAccess() && !STR.isBlank(this.$content) && this.$content.length > 0) {
      this.$content.html(html);
    }
  },

  hideIcon: function() {
    this.iconHidden = true;
    if (this.canAccess() && !STR.isBlank(this.$icon) && this.$icon.length > 0) {
      this.collapse();
      this.$icon.hide();
      if (this.ctxEcran === "agenda") {
        this.$title.css("margin-left", "0.4em");
      }
    }
  },

  showIcon: function() {
    this.iconHidden = false;
    if (this.canAccess() && !STR.isBlank(this.$icon) && this.$icon.length > 0) {
      this.$icon.show();
      if (this.ctxEcran === "agenda") {
        this.$title.css("margin-left", "");
      }
      if (this.context && this.context.ctxBlocEstDeplie) {
        if (this.context.ctxBlocEstDeplie === true) {
          this.expand();
        }
      }
    }
  },

  collapse: function() {
    if (this.canAccess() && !STR.isBlank(this.$icon) && this.$icon.length > 0 &&
      !STR.isBlank(this.$content) && this.$content.length > 0) {
      this.$icon.removeClass("phx-icon-triangle-s");
      this.$icon.addClass("phx-icon-triangle-e");
      this.$content.hide();
      this.expanded = false;
      this.model.trigger("collapse", this);
      // Set WAI-ARIA attributes
      this.$block.attr("aria-expanded", false);
      this.$content.attr("aria-hidden", true);
    }
  },

  expand: function() {
    if (this.canAccess() && !STR.isBlank(this.$icon) && this.$icon.length > 0 &&
      !STR.isBlank(this.$content) && this.$content.length > 0) {
      this.$icon.removeClass("phx-icon-triangle-e");
      this.$icon.addClass("phx-icon-triangle-s");
      this.$content.show();
      this.expanded = true;
      this.model.trigger("expand", this);
      // Set WAI-ARIA attributes
      this.$block.attr("aria-expanded", true);
      this.$content.attr("aria-hidden", false);
    }
  },

  hideBlock: function() {
    this.$el.hide();
  },

  showBlock: function() {
    if (this.canAccess()) {
      this.$el.show();
    }
  },

  /*
   * Returns True when icon is not hidden.
   */
  isEmpty: function() {
    return (this.$el.css("display") === "none") ||
      (!STR.isBlank(this.$icon) && this.$icon.length > 0 && this.$icon.css("display") === "none");
  },

  isExpanded: function() {
    return this.expanded;
  },

  _manageBlocEstDeplie: function() {
    if (this.context && this.context.ctxBlocEstDeplie === true) {
      this.expand();
    } else {
      this.collapse();
    }
  },

  _generateContent: function() {
    return false;
  },

  setContext: function() {
    return false;
  },

  _loadData: function() {
    return false;
  }
});
