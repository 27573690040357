import { BaseModel } from '../../../../../../core/models/base.model.js';
import { FormView } from '../../../../../../core/views/form.view.js';
import { GererPretsGestion } from '../models/gererPretsGestion.js';
import { i18n } from '../../../../../../i18n.js';
import TPL_gererprets_popUp from '../popUp.tpl.html';

export var GererPretPopUpView = FormView.extend({

  initialize: function(options) {
    this.template = TPL_gererprets_popUp;

    this.model = new BaseModel({
      value: new GererPretsGestion()
    });

    if (options.parent) {
      this.parent = options.parent;
    }
    if (options.action) {
      this.action = options.action;
      this.model.get("value").action = this.action;
    }
    if (options.statut) {
      this.statut = options.statut;
    }
  },

  render: function() {
    var json = { "i18n": i18n };
    this.$el.append(this.template(json));

    // Render buttons
    $(this.el).find(".btnAnnuler").button();
    $(this.el).find(".btnOK").button();

    var comment = "";
    switch (this.action) {
      case "accepter":
        comment = i18n.t('common:gererprets.commentaire_Accept');
        break;
      case "refuser":
        this.$el.find("label[for=commentaire],label[for^=commentaire_uid_]").addClass("required");
        comment = i18n.t('common:gererprets.commentaire_Refus');
        break;
      case "suppression":
        switch (this.statut) {
          case "I":
            comment = i18n.t('common:gererprets.commentaire_SuppresionCours');
            break;
          case "A":
            comment = i18n.t('common:gererprets.commentaire_SuppresionAccept');
            break;
          default:
            comment = i18n.t('common:gererprets.commentaire_SuppresionSupp');
        }
    }
    $(this.el).find(".spanComment").html(comment);
    return this;
  },

  events: {
    "change :input:not([readonly])": "_change",
    "click button": "_clickListener"
  },

  _clickListener: function(event) {
    var btnClicked = event.currentTarget.value;
    var model = this.model.get("value");

    switch (btnClicked) {
      case "btnOK":
        if (this.action == "accepter") {
          this.parent.model.set("action", "OK");
          this.parent.model.set("commentaire", model.get("commentaire"));
          this.parent.close();
        } else {
          if (this.action == "refuser") {
            model.off("invalid");
            model.on("invalid", this._showValidationErrors, this);

            if (model.isValid()) {
              this.parent.model.set("action", "OK");
              this.parent.model.set("commentaire", model.get("commentaire"));
              this.parent.close();
            }
          } else if (this.action == "suppression") {
            this.parent.model.set("action", "OK");
            this.parent.model.set("commentaire", model.get("commentaire"));
            this.parent.close();
          }
        }

        break;
      case "btnAnnuler":
        this.parent.model.set("action", "annuler");
        this.parent.close();
        break;
      default:
    }
  }
});