import { DelegationsUtilisateurModel } from './delegationsUtilisateur.model.js';
import { PaginatedCollection } from '../../../../../core/models/paginated.collection.js';
import { STR } from '../../../../../utils/str.js';
/**
 *referentiel coll for Utilisateurs Loading
 */
export var DelegationsUtilisateurColl = PaginatedCollection.extend({

  model: DelegationsUtilisateurModel,

  url: function() {
    this.datedeb = this.workflow.formModel.get("value").get("datedeb");
    this.datefin = this.workflow.formModel.get("value").get("datefin");
    var txtUrl = "";
    if (!STR.isBlank(this.datedeb)) {
      txtUrl += "?datedeb=" + this.datedeb;
    }
    if (!STR.isBlank(this.datefin)) {
      txtUrl += "&datefin=" + this.datefin;
    }
    if (this.workflow.utilCode) {
      txtUrl += _.isEqual(txtUrl, "") ? "?userID=" + this.workflow.utilCode : "&userID=" + this.workflow.utilCode
    }
    return Configuration.restRoot + "/rest/utildelegation/utilisateurs" + txtUrl;
  },

  initialize: function(options) {
    PaginatedCollection.prototype.initialize.call(this);
    this.workflow = null;
    if (options && !STR.isBlank(options.workflow)) {
      this.workflow = options.workflow;
    }
    /*
    if (options && options.removeId) {
      this.removeId = options.removeId;
    }
    */
  }
  /*
  parse: function(response) {
    if (!STR.isBlank(this.removeId)) {
      //je vais supprimer l'information de mon prope collabnorateur/utilisateur
    }
    return response;
  }
  */
});
