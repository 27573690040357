import TPL_common_selecteur_chemin_item from './selecteur_chemin_item.tpl.html';

export var SelecteurCheminResultItemView = Backbone.View.extend({

  dummy: "",

  /**
   * Name of the container class of the view
   */
  className: "phx-chemin-item ui-corner-all",

  /**
   * Name of the html tag which involves the view
   */
  tagName: "span",

  /**
   * Event launched when the item is clicked
   *
   */

  events: {
    "click": "_removeItem"
  },

  /**
   * Constructor
   * View underlying a selecteur de chemin result item
   */
  initialize: function(params) {
    /**
     * HTML template of the view
     */
    this.template = TPL_common_selecteur_chemin_item;
    this.readonly = false;
    if (params && params.readonly === true) {
      this.readonly = true;
    }
  },

  /**
   * Paints the row view of the selecteur de chemin result item
   */
  render: function() {
    var json = { readonly: this.readonly, model: this.model.attributes };

    $(this.el).html(this.template(json));

    return this;
  },

  /**
   * Method which removes an existing item
   */
  _removeItem: function() {
    if (this.readonly == false) {
      this.model.trigger("click:item", this.model);
    }
  }

});