module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<span class="filtre_Statut">\n	<div class="filtres_rapides">\n		<span class="title_statut" style="font-weight: bold;">Statut</span>\n<input type="checkbox" class="filtre_statut_input" id="encours" value="encours" checked><label class="filtre_statut_label" for="encours">'+
((__t=(i18n.t('common:bemprunt.fitre_en_cours')))==null?'':__t)+
'</label>\n<input type="checkbox" class="filtre_statut_input" id="aceptes" value="aceptes" checked><label class="filtre_statut_label" for="aceptes">'+
((__t=(i18n.t('common:bemprunt.filtre_aceptes')))==null?'':__t)+
'</label>\n<input type="checkbox" class="filtre_statut_input" id="refuses" value="refuses" checked><label class="filtre_statut_label" for="refuses">'+
((__t=(i18n.t('common:bemprunt.filtre_refuses')))==null?'':__t)+
'</label>\n</div>\n</span>\n<span class="table_BriqueMesEmprunts"></span>\n';
}
return __p;
};
