import { BaseModel } from 'core/models/base.model.js';

/**
 * The model of Deplacer Compteur.
 */
export var DeplacerCompteursModel = BaseModel.extend({

  url: function() {
    var url = Configuration.restRoot + "/rest/personnalisation/definition/brique/rescpt/";
    url += encodeURIComponent(this.userid + "," + this.codebrique);
    url += "/detail/";
    url += encodeURIComponent(this.id);
    url += "/ordre/";
    url += encodeURIComponent(this.ordre);

    return url;
  }
});