import { BaseModel } from 'core/models/base.model.js';

import { PaginatedCollection } from 'core/models/paginated.collection.js';

export var DelegationsDroitsCollabModel = BaseModel.extend({
  /**
   * Start date of the droids
   */
  /**
   * Code of the delegation
   */
  /**
   * Code of the Utilisateur
   */

  usecase: "droitsdel",

  /**
   * Builds the url for the REST call
   */
  url: function() {

    var extraParams = "?delegable=true";
    if (this.datefrom) {
      extraParams += "&datefrom=" + this.datefrom;
      if (this.queretro == true) {
        extraParams += "&queretro=true";
      }
    }
    if (this.delegation) {
      extraParams += "&delegation=" + this.delegation;
    }

    return Configuration.restRoot + "/rest/delegation/" + encodeURIComponent(this.utilCode) + "/droits" + extraParams;
  },

  /**
   * Default data model
   *		profils : []
   * }
   */
  defaults: {
    "profils": []
  },

  /**
   * Constructor
   * Model for load the droits of the utilisateur in the one Delegations
   */
  initialize: function(options) {
    PaginatedCollection.prototype.initialize.call(this);
    if (options && options.utilCode) {
      this.utilCode = options.utilCode;
    }
    this.paginated = false;
  }

});