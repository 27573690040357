
import { TypoStandardModel } from './typoStandard.model.js';
/**
 * Parametres Divers Collection
 */
export var TypoStandardColl = Backbone.Collection.extend({

  dummy: "",
  /**
   * habilitationV
   */
  habilitationV: "N",
  /**
   * habilitationG
   */
  habilitationG: "N",
  /**
   * usecase
   */
  usecase: "app",
  /**
   * url
   */
  url: Configuration.restRoot + "/rest/activite/typodom/standard",
  /**
   * model
   */
  model: TypoStandardModel,

  /**
   * Get a Typologye by code
   */
  get: function(id) {
    var element = Backbone.Collection.prototype.get.call(this, id);

    var result = element;
    if (element instanceof Backbone.Model) {
      result = element.clone();
    }
    return result;
  }
});