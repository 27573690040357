module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<fieldset class="listerprets-rapport">\n  <div class="phx-formTable">\n    <div class="phx-formErrors ui-state-error"></div>\n    <div class="treatedPrets"></div>\n\n    <div class="progress">\n      <span class="waitmessage"></span>\n      <div class="progressbar"><span class="valueText"></span></div>\n      <span class="buttons">\n				<button type="button" class="btnInterrompre" value="interrompre">'+
((__t=(i18n.t("common:listerprets.interrompre")))==null?'':__t)+
'</button>\n			</span>\n    </div>\n  </div>\n  <!-- 	<div class="profilsFilter"> -->\n  <!-- 		<label for="profils">'+
((__t=(i18n.t("common:listerprets.role")))==null?'':__t)+
'</label>  -->\n  <!-- 		<span class="cmbProfil"></span> -->\n  <!-- 	</div> -->\n</fieldset>\n<div class="tablePrets"></div>\n';
}
return __p;
};
