import { LOG } from '../../utils/log.js';
import { STR } from '../../utils/str.js';

/**
 * View that creates a multiple label input from the
 * template passed.
 */
export var MultiLabel = Backbone.View.extend({

  tagName: "div",
  className: "phx-multiLabel",

  initialize: function(options) {
    this.model = new Backbone.Model();
    this.selectionEnabled = true;
    this.required = false;
    this.avoidUniqueSelection = false;

    if (options) {
      //avoid Selector with one value.
      if (options.avoidUniqueSelection) {
        this.avoidUniqueSelection = options.avoidUniqueSelection;
      }
      //Template with the multiLabel HTML
      if (options.template) {
        this.template = options.template;
      }
      //Initial Selected Bloc
      if (options.initialBloc) {
        this.currentBloc = options.initialBloc;
      }
      //If we can change the label
      if (options.selectionEnabled) {
        this.selectionEnabled = options.selectionEnabled;
      }
      //Add required class to the label
      if (options.required) {
        this.required = options.required;
      }
      //Name of the label in the model
      if (options.name) {
        this.name = options.name;
      }
      //Function that will be called when change the label
      if (options.changeLabelCallback) {
        this.changeLabelCallback = options.changeLabelCallback;
      }
      //View that adds the MultiLabel component
      if (options.parentView) {
        this.parentView = options.parentView;
      }
      //Force the compontent to be rendered inside the parent view
      if (options.withinParent) {
        this.withinParent = options.withinParent;
      }
    }
    this.canAccess = null;

    var events = {};
    events["click ." + this.cid + ".phx-multiLabel-btn"] = "_showLabelsSelector";
    events["click ." + this.cid + ".phx-multiLabel-option a"] = "_changeLabel";

    this.delegateEvents(events);
  },

  render: function() {
    var self = this;
    this.$el.prop("viewRef", this);
    //Basic Component Template
    this.$label = $("<label>");
    if (this.required) {
      this.$label.addClass("required");
    }
    this.$icon = $("<span class='" + this.cid + " phx-multiLabel-btn phx-icon phx-icon-triangle-s'>");
    this.$blocContainer = $("<div class='phx-multiLabel-blocContainer'>").append(this.template);
    this.$el.html([this.$label, this.$icon, this.$blocContainer]);

    //Set the refView to the inputs
    this.$el.find(":input").prop("data-refview", this);

    //Create labels Array [{label,name,jquerySelector},...]
    this.labels = [];
    _.each(this.$el.find("div.phx-multiLabel-bloc"), function(it) {
      //Save the label in Array
      $(it).addClass(self.cid);
      self.labels.push({ "label": $(it).data("bloc-label"), "name": $(it).data("bloc-name"), "jquerySelector": $(it) });
    });

    if (this.labels.length > 0) {
      this._initializeLabelSelector();
      if (_.isUndefined(this.currentBloc)) {
        this.currentBloc = _.first(this.labels);
      }
      this.showCurrentBloc();
    }

    if (!this.selectionEnabled || this.labels.length == 1) {
      this.disableSelector();
    }

    return this;
  },

  /**
   * Hide all blocs, set the the label of the curret selected bloc and show it.
   */
  showCurrentBloc: function() {
    //Hide all blocs
    this.$el.find("div." + this.cid + ".phx-multiLabel-bloc").hide();
    //Set the selected Label
    this.$label.html(this.currentBloc.label);
    //Show the current selected Bloc
    this.currentBloc.jquerySelector.show();

    if (this.filterLabels().length == 1) {
      this.disableSelector();
    }
  },

  getItem: function() {
    //nothig
  },

  setItem: function() {
    //Empty
  },

  /**
   * Set a new Label by his bloc name
   */
  setLabelByName: function(name) {
    var labelBloc = _.find(this.labels, function(it) {
      return it.name == name;
    });

    if (labelBloc) {
      this.currentBloc = labelBloc;
      this.showCurrentBloc();
    } else {
      LOG.error("No Label Name found");
    }
  },

  enableSelector: function() {
    this.selectionEnabled = true;
    if (this.avoidUniqueSelection == false) {
      this.$icon.show();
    }
  },

  disableSelector: function() {
    this.selectionEnabled = false;
    this.$icon.hide();
  },

  _initializeLabelSelector: function() {
    this.$labelSelector = $("<ul class='phx-multiLabel-selector'>");
    this._generateLabelSelectorOptions();
    this.$labelSelector.menu();
    this.$labelSelector.hide();
    this.$label.after(this.$labelSelector);
  },

  _generateLabelSelectorOptions: function() {
    var self = this;
    var labelsFiltered = this.filterLabels();
    _.each(labelsFiltered, function(it) {
      var $a = $("<a>").addClass(it.name).html(it.label);
      self.$labelSelector.append($("<li class='" + self.cid + " phx-multiLabel-option'>").append($a));
    });
  },

  filterLabels: function() {
    var filtered = this.labels;
    if (this._filter) {
      filtered = this._filter(this.labels);
    }
    return filtered;
  },
  setFilter: function(filterCallback) {
    this._filter = filterCallback;
  },
  _showLabelsSelector: function(event) {
    if (this.$labelSelector.is(":visible")) {
      this.$labelSelector.hide();
    } else {
      if (this._canAccessMenu() == true) {
        this._initializeLabelSelector();
        var $icon = $(event.target);

        var positionOptions = {
          my: "right top",
          at: "right-5 bottom+5",
          of: $icon
        }

        if (!STR.isBlank(this.parentView) && this.withinParent) {
          positionOptions.collision = "";
          positionOptions.within = this.parentView.$el;
        }

        this.$labelSelector.show().position(positionOptions);

        //If you click out of the menu, close the menu.
        var self = this;
        $(document).one("mousedown", function(event) {
          var element = $(self.el).find(event.target);
          if (element.length == 0) {
            self._showLabelsSelector(event);
          }
        });
      }
    }
  },

  _canAccessMenu: function() {
    if (!STR.isBlank(this.canAccess)) {
      return this.canAccess;
    }
    return true;
  },

  setMenuAccess: function(access, onlyFirst) {
    this.canAccess = access;
    if (this.canAccess != true) {
      if (_.isBoolean(onlyFirst) && onlyFirst === true) {
        this.$el.find(".phx-multiLabel-btn").first().hide();
      } else {
        this.$el.find(".phx-multiLabel-btn").hide();
      }
    } else {
      this.$el.find(".phx-multiLabel-btn").show();
    }
  },

  _changeLabel: function(event) {
    var selectedElement = event.target.className.split(" ")[0];
    this.currentBloc = _.find(this.labels, function(item) { return item.name == selectedElement; });
    //Trigger on component model a change event
    if (!_.isUndefined(this.changeLabelCallback)) {
      this.changeLabelCallback.call(this.parentView, this.name, selectedElement);
    }
    //Perform Visual changes
    this.showCurrentBloc();
    if (this.$labelSelector.is(":visible")) {
      this.$labelSelector.hide();
    }
  },

  setReadOnly: function(isReadOnly) {
    if (isReadOnly == true) {
      this.disableSelector();
    } else {
      this.enableSelector();
    }
  }

});
