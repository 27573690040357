import { BaseModel } from 'core/models/base.model.js';
import { FormView } from 'core/views/form.view.js';
import { i18n } from '../../../../i18n.js';
import { LOG } from '../../../../utils/log.js';
import { TYPE } from '../../../../tda/tda.js';
import TPL_calendacc_customBar from '../customBar.tpl.html';

/**
 * Custom Bar View
 */
export var CustomBarView = FormView.extend({

  initialize: function(options) {
    this.model = new BaseModel();

    if (options && options.workflow) {
      this.workflow = options.workflow;
      this.model.set("year", this.workflow.yearDate);
    }

    this.template = TPL_calendacc_customBar;
  },

  _getModel: function() {
    return this.model;
  },

  events: _.defaults({
    "keyup :input:not([readonly])": "_changeYearKeyUp",
    "click button": "_changeYearBtn"
  }, TYPE.SHORT.events()),

  _changeYearKeyUp: function(event) {
    var element = event.currentTarget.className.split(" ")[0];
    var value = event.currentTarget.value;
    var key = event.keyCode;

    if (key != 9) { //TAB
      value = TYPE.SHORT.parse(value).val;
      if (value.length == 4) {
        this.model.set("year", value);
        this.workflow.formModel.trigger("changeYear", value);
      }
    }
  },

  _changeYearBtn: function(event) {
    var element = event.currentTarget.className.split(" ")[0];
    var value = event.currentTarget.value;

    switch (element) {
      case "phx-icon-prev":
        var anno = this.model.get("year");
        anno--;
        this.model.set("year", anno);
        this.$el.find(".year").val(anno);
        this.workflow.formModel.trigger("changeYear", anno);
        break;
      case "phx-icon-next":
        var anno = this.model.get("year");
        anno++;
        LOG.debug("año siguiente _changeYearBtn");
        this.model.set("year", anno);
        this.$el.find(".year").val(anno);
        this.workflow.formModel.trigger("changeYear", anno);
        break;
    }

  },

  render: function() {
    var json = { 'i18n': i18n };
    this.$el.html(this.template(json));

    this.$el.find(".year").val(this.workflow.yearDate);

    this.$el.find(".phx-icon-prev").button({ icons: { primary: "ui-icon-triangle-1-w" }, text: false });
    this.$el.find(".phx-icon-next").button({ icons: { primary: "ui-icon-triangle-1-e" }, text: false });

    return this;
  },

  _manageLink: function(event) {
    var target = event.target;
    var element = event.target.className.split(" ")[0];

  }
});