import { EditableGridModel } from 'core/grids/editablegrid/editableGrid.model.js';
import { EditableGridView } from 'core/grids/editablegrid/editableGrid.view.js';
import { i18n } from '../../../../i18n.js';
import { SuvietatColl } from '../models/suvietat.collection.js';
import { SuvietatEditedRowView } from './suvietatEditedRow.view.js';
import { TYPE } from '../../../../tda/tda.js';
import TPL_suvietat_suvietat from '../suvietat.tpl.html';

/**
 * General view
 */
export var SuvietatView = Backbone.View.extend({

  initialize: function(options) {
    this.template = TPL_suvietat_suvietat;

    if (options && options.workflow) {
      this.workflow = options.workflow;
    }

    this.parent = options.parent;
    this.model = null; //tableModel
    this.table = this._initTable();
  },

  render: function() {
    var json = { "i18n": i18n };
    this.$el.html(this.template(json));

    $(this.el).find(".suvietatTable").html(this.table.el);
    this.table.render();

    return this;
  },

  _initTable: function() {
    var self = this;
    var tableModel = new EditableGridModel({
      coll: new SuvietatColl()
    });
    this.model = tableModel;
    var tblColumns = [
      { title: i18n.t('suvietat.date_creation'), property: "date", width: 250 },
      { title: i18n.t('suvietat.theme'), property: "theme", width: 100 },
      { title: i18n.t('suvietat.nom'), property: "libelle", width: 400 },
      { title: i18n.t('suvietat.traitement'), property: "etat", width: 150 },
      { title: i18n.t('suvietat.id'), property: "code", width: 150 },
      { title: i18n.t('suvietat.resultat'), property: "type", width: 100 }
    ];

    var viewRow = new SuvietatEditedRowView();
    var table = new EditableGridView({
      id: "suvietat_table",
      columns: tblColumns,
      model: tableModel,
      title: i18n.t('suvietat.title_table'),
      showFilteredRowsInTitle: true,
      viewRow: viewRow,
      gridHeightType: "auto",
      parentContainer: this.parent
    });

    table.cellRenderers["date"] = function(model) {
      var date = TYPE.DATE.format(model.get("datedebut"), "Dy DD/MM/YYYY", "/");
      var hour = TYPE.HOUR_MINUTE_SECONDS.format(model.get("heuredebut"), TYPE._getFormatByCode("INSTHMS"));
      return date + " " + hour;
    };
    table.cellRenderers["libelle"] = function(model) {
      return "<b>" + model.get("libelle") + "</b>";
    };
    table.cellRenderers["etat"] = function(model) {
      var result = "";
      switch (model.get("etat").code) {
        case "0":
          result = model.get("etat").libelle;
          break;
        case "10":
          result = model.get("etat").libelle;
          break;
        case "20":
        case "40":
          result = "<label style='width:auto !important;'>" + model.get("etat").libelle + "</label>";
          break;
        case "30":
          result = "<span style='color:red;'>" + model.get("etat").libelle + "</span>";
          break;
        default:
          break;
      }
      return result;
    };
    table.cellRenderers["type"] = function(model) {
      var type = "";
      if (model.get("etat").code !== "20" && model.get("etat").code !== "30" && model.get("etat").code !== "40") {
        type = $('<a>');
        type.attr("href", model.get("url"));
        type.attr("target", "_blank");
        type.addClass("suvietat-icon");
        type.css("text-decoration", "underline");
        if (model.get("type") == "P") {
          type.html(i18n.t('suvietat.imp'));
        } else if (model.get("type") == "E") {
          type.html(i18n.t('suvietat.exp'));
        }
      }
      return type;
    };

    table.setVisibleColumns(["date", "theme", "libelle", "etat", "type"]);
    table.setSortableColumns(["date", "theme", "libelle"]);
    table.setSortableCustomNames({
      "date": ["datedebut", "heuredebut"]
    });

    table.buttonBar.model.trigger("hide:new");
    table.buttonBar.model.trigger("hide:copy");

    tableModel.coll.setHabContext(this.workflow.getHabContext());
    table.model.setHabContext(this.workflow.getHabContext());

    return table;
  }
});