import { GLOBAL_DATA } from '../../globalData.js';
import { GmSituationView } from 'common/gmsituation/views/gmSituation.view.js';
import { LOG } from '../../utils/log.js';
import { objs } from '../../objectsRepository.js';
import TPL_app_menu from '../menu.tpl.html';
import TPL_app_menu_item_niv1 from '../menu_item_niv1.tpl.html';
import TPL_app_menu_item_niv2 from '../menu_item_niv2.tpl.html';
import TPL_app_menu_item_section from '../menu_item_section.tpl.html';
import TPL_app_menuNiv1 from '../menuNiv1.tpl.html';

/**
 * ZoneMenu View
 *
 * Contain the menus specific to a zone
 *
 */
export var ZoneMenuView = Backbone.View.extend({

  className: "phx-menu-zone",

  events: {
    "click a": "_clickOption",
    "click .phx-menu *": "_clickMenu"
  },

  initialize: function(options) {
    this.template = TPL_app_menu;
    this.templateNiv1 = TPL_app_menuNiv1;
    this.templateItemNiv1 = TPL_app_menu_item_niv1;
    this.templateItemNiv2 = TPL_app_menu_item_niv2;
    this.templateItemSection = TPL_app_menu_item_section;
    this.listenTo(GLOBAL_DATA.rights, "sync", this._reloadNavigation);
    this.cMenu = {};
    this.zJSON = options.zJSON;
  },

  render: function() {
    var firstdrawnelement = true;
    var json = {};
    //Create the DOM for the menu
    _.each(this.zJSON.menus, function(menu, index) {
      var self = this;
      if (menu.aspect !== "hidden") {
        var tabindex = "-1";
        if (menu.id) {
          if (firstdrawnelement) {
            tabindex = "0";
          }
          firstdrawnelement = false;

          if (menu.href) {
            // The link specifies the url itself, so it's not a usecase
            // of the app. It will be opened in a new navigator tab
            menu.target = menu.id;
            menu.id = "";
          } else {
            // It's a usecase inside the application
            var zid = this.zJSON.id;
            var type = menu.typ || "uc"; // If it doesn't have type is an uc.
            menu.href = "#z/" + zid + "/" + type + "/" + menu.id;
            menu.target = "";
          }
          json = { menu: menu, index: index, tabindex: tabindex };
          this.$el.append(this.templateNiv1(json));
          if (GLOBAL_DATA.paramDivers.get("SPEValideu").get("valeur") == 1) {
            if (!GLOBAL_DATA.respDesig.get("existValideur")) {
              if (menu.emid === "EM_00014" || menu.emid === "EM_00015" || menu.emid === "EM_00019") {
                GLOBAL_DATA.rights.menuVisibility.set(menu.emid, false);
              } else {
                GLOBAL_DATA.rights.menuVisibility.set(menu.emid, true);
              }
            }
          }

          //Simulate click when enter is pressed for menu elements.

          this.$el.find("> div.phx-menu").last().keydown(function(e) {
            self._keyboardNav(e, self.zJSON.id, false);
          });
        } else {
          if (menu.nom) {
            if (firstdrawnelement) {
              tabindex = "0";
            } else {
              tabindex = "-1";
            }
            firstdrawnelement = false;

            json = { nom: menu.nom, alt: menu.alt, index: index, emid: menu.emid, tabindex: tabindex };
            this.$el.append(this.template(json));
            if (GLOBAL_DATA.paramDivers.get("SPEValideu").get("valeur") == 1) {
              if (!GLOBAL_DATA.respDesig.get("existValideur")) {
                if (menu.emid === "EM_00014" || menu.emid === "EM_00015" || menu.emid === "EM_00019") {
                  GLOBAL_DATA.rights.menuVisibility.set(menu.emid, false);
                } else {
                  GLOBAL_DATA.rights.menuVisibility.set(menu.emid, true);
                }
              }
            }

            //Prepare keyboard navigation for menu elements.
            this.$el.find("> div.phx-menu").last().keydown(function(e) {
              self._keyboardNav(e, self.zJSON.id, true);
            });

            //Simulate click qhen enter is pressed for menu elements.
            this.$el.find(".phx-menu").last().find("span.phx-menu-text").keypress(function(e) {
              if (e.keyCode === 13) {
                e.target.click();
              }
            });

            var menuDom = $(".phx-menu", this.el).last();
            var ulDom = $("ul", this.el).last();
            var zid = "";
            var type = "uc";
            _.each(menu.ecrans, function(uc) {
              if (uc.id) {
                if (uc.href) {
                  // The link specifies the url itself, so it's not a usecase
                  // of the app. It will be opened in a new navigator tab
                  uc.target = uc.id;
                  uc.id = "";
                } else {
                  // It's a usecase inside the application
                  zid = this.zJSON.id;
                  type = uc.typ || "uc"; // If it doesn't have type is an uc.
                  uc.href = "#z/" + zid + "/" + type + "/" + uc.id;
                  uc.target = "";
                }
                if (uc.id === "comptecollabresp") {
                  zid = this.zJSON.id;
                  type = uc.typ || "uc"; // If it doesn't have type is an uc.
                  uc.href = "#z/" + zid + "/" + type + "/" + "suivicollab/" + uc.id;
                  uc.target = "";
                } else if (uc.id === "gmsituation") {
                  // special case where no tab will be opened and UC will be shown inside popup
                  zid = this.zJSON.id;
                  type = uc.typ || "uc"; // If it doesn't have type is an uc.
                  uc.href = "javascript:void(0)";
                  uc.target = "";
                }
                $(ulDom).append(this.templateItemNiv1(uc));

                //Prepare keyboard navigation between menu-links
                ulDom.find("li.phx-menu-link").last().keydown(function(e) {
                  self._menuLinksKeyboardNav(e, self.zJSON.id);
                });
              } else {
                // add section name
                $(ulDom).append(this.templateItemSection(uc));

                // add section submenus
                _.each(uc.ecrans, function(uc2, index) {
                  if (uc2.href) {
                    // The link specifies the url itself, so it's not a usecase
                    // of the app. It will be opened in a new navigator tab
                    uc2.target = uc2.id;
                    uc2.id = "";
                  } else {
                    // It's a usecase inside the application
                    var zid = this.zJSON.id;
                    uc2.href = "#z/" + zid + "/uc/" + uc2.id;
                    uc2.target = "";
                  }
                  $(ulDom).append(this.templateItemNiv2(uc2));

                  //Prepare keyboard navigation between menu-links
                  ulDom.find("li.phx-menu-link").last().keydown(function(e) {
                    self._menuLinksKeyboardNav(e, self.zJSON.id);
                  });
                }, this);
              }
            }, this);
            $("div", menuDom).hide();
          }
        }
      }
    }, this);

    this.$el.attr("role", "menubar");
    this.$el.attr("aria-hidden", "true");

    this._reloadNavigation();

    return this;
  },

  /**
   * function to change navigation menu to have disabled states, visually only
   */
  _reloadNavigation: function() {
    var hiddenMenus = [];
    var hiddenEcrans = {};
    var menusEmid = [];
    var allTrue = function(b) { return b };
    var handleSubMenu = function(menu) {
      if (menu.id) {
        var _tmpBool = this._hideLink(menu);
        if (menusEmid.length > 0) { // Is into submenu
          hiddenEcrans[menusEmid[menusEmid.length - 1]].push(_tmpBool);
        } else {
          hiddenEcrans[menu.emid] = [_tmpBool];
        }
        hiddenMenus.push(_tmpBool);
      } else {
        menusEmid.push(menu.emid);
        hiddenEcrans[menu.emid] = [];

        menu.ecrans.forEach(handleSubMenu, this);
        var checkTrue = hiddenEcrans[menu.emid].every(allTrue);
        if (menusEmid.length > 1) { //is a submenu menu son. Needs set parent value
          if (checkTrue) {
            hiddenEcrans[menusEmid[0]].push(true);
          } else {
            hiddenEcrans[menusEmid[0]].push(false);
          }
        }
        this._hideLink(menu, checkTrue);
        menusEmid.pop();
      }
    };
    this.zJSON.menus.forEach(handleSubMenu, this);
    if (hiddenMenus.every(allTrue)) {
      this.model.trigger("hideZone", this.zJSON);
    } else {
      this.model.trigger("showZone", this.zJSON);
    }

    //		// populations usecase was not added before..
    //		this._hideLink({id: "populations"});
  },

  /**
   * This functions receives the array with the required permissions to view a link at the menu.
   */
  _hideLink: function(menu, forceCheck) {
    var hide = forceCheck || !GLOBAL_DATA.rights.menuVisibility.get(menu.emid),
      identifier = menu.id || menu.emid;
    if (hide) {
      this.$el.find("." + identifier).addClass("ui-state-disabled");
    } else {
      this.$el.find("." + identifier).removeClass("ui-state-disabled");
    }
    return hide;
  },

  /*
   * This function defines navigation with keyboard by items in zoneMenu
   */
  _keyboardNav: function(e, zoneId, isAMenu) {
    var $currentPhxMenu = $(e.target);
    if (e.target.className.split(" ")[0] === "phx-menu") {
      if (e.keyCode === 37) { // left
        e.preventDefault();
        //Current zoneItem menu
        if ($("div", $currentPhxMenu).is(":visible")) {
          $("div", $currentPhxMenu).hide();
          $("div", $currentPhxMenu).attr("aria-hidden", "true");
        }
        if ($currentPhxMenu.prevAll('.phx-menu').first().length === 0) {
          var lastMenu = $currentPhxMenu.parent('#phx-menu-zone-' + zoneId).find('> .phx-menu').last();
          this._selectFocusableElement(lastMenu, true);
        } else {
          var prevMenu = $currentPhxMenu.prevAll('.phx-menu').first();
          this._selectFocusableElement(prevMenu, true);
        }
      } else if (e.keyCode === 39) { // right
        e.preventDefault();
        if ($("div", $currentPhxMenu).is(":visible")) {
          $("div", $currentPhxMenu).hide();
          $("div", $currentPhxMenu).attr("aria-hidden", "true");
        }
        if ($currentPhxMenu.nextAll('.phx-menu').first().length == 0) {
          var firstMenu = $currentPhxMenu.parent('#phx-menu-zone-' + zoneId).find('> .phx-menu').first();
          this._selectFocusableElement(firstMenu, true);
        } else {
          var nextMenu = $currentPhxMenu.nextAll('.phx-menu').first();
          this._selectFocusableElement(nextMenu, true);
        }
      } else if (e.keyCode === 9) //tab
      {
        // If there is a submenu being show for any item in this zoneMenu, we hide it
        var menuZone = $(e.target).closest("div#phx-menu-zone-" + zoneId);
        _.each(menuZone.find("div.phx-menu"), function(menu) {
          if ($("div", menu).is(":visible")) {
            $("div", menu).hide();
            $("div", menu).attr("aria-hidden", "true");
          }
        });
      } else if (isAMenu === true) {
        var $menu;
        if (e.keyCode === 13 || e.keyCode === 32) {
          e.preventDefault();
          //enter spacebar
          var $currentTarget = $(e.target);

          $($currentTarget).find("span.phx-menu-text").click();
          //focus on first submenu element
          $menu = $($currentTarget);
          $("div", $menu).find("ul").first().find("li.phx-menu-link:not(.phx-menu-separator)").first().focus();
          e.preventDefault();

        } else if (e.keyCode === 40) { //down
          e.preventDefault();
          //If drop down menu is visible we navigate to last item
          if ($currentPhxMenu.find("div.ui-menu").is(":visible")) {
            $currentPhxMenu.find("div.ui-menu").find("li.phx-menu-link:not(.phx-menu-separator)").first().focus();
          } else {
            $(e.target).find("span.phx-menu-text").click();
            //focus on first submenu element
            $menu = $(e.target);
            $("div", $menu).find("ul").first().find("li.phx-menu-link:not(.phx-menu-separator)").first().focus();
          }

        }
      } else if (isAMenu == false) {
        if (e.keyCode === 13 || e.keyCode === 32) {
          e.preventDefault();
          //enter or spacebar simulates click when this menuBar item opens directly a usecase.
          $(e.target).find("a")[0].click();
        }
      }
    }
  },

  /*This function selects a focusable element inside a  zonemenuview phx-menu element
   * The focusable element can be a link a or a span
   */
  _selectFocusableElement: function(rootSelector, clickElement, selectFirstChild) {
    if (rootSelector.find("> .phx-hover > a").length === 0) //There is no direct link to a usecase
    {
      rootSelector.focus();

      if (clickElement === true) {
        rootSelector.find("> span").first().click();
      }

      if (selectFirstChild === true) { //Select first child at drop/down menu
        rootSelector.find("li.phx-menu-link:not(.phx-menu-separator)").first().focus();
      }
    } else { //There is a link to a usecase
      rootSelector.focus();
    }

  },

  _menuLinksKeyboardNav: function(e, zoneId) {
    if (e.keyCode === 38) { // up
      e.preventDefault();
      var currentLinkMenu = $(e.target);
      if (currentLinkMenu.prevAll('li.phx-menu-link:not(.phx-menu-separator)').first().length == 0) {
        var lastMenu = currentLinkMenu.nextAll('li.phx-menu-link:not(.phx-menu-separator)').last();
        lastMenu.focus();
      } else {
        var prevMenu = currentLinkMenu.prevAll('li.phx-menu-link:not(.phx-menu-separator)').first();
        prevMenu.focus();
      }
    } else if (e.keyCode == 40) { // down
      e.preventDefault();
      var currentLinkMenu = $(e.target);
      if (currentLinkMenu.nextAll('li.phx-menu-link:not(.phx-menu-separator)').first().length == 0) {
        var firstMenu = currentLinkMenu.prevAll('li.phx-menu-link:not(.phx-menu-separator)').last();
        firstMenu.focus();
      } else {
        var nextMenu = currentLinkMenu.nextAll('li.phx-menu-link:not(.phx-menu-separator)').first();
        nextMenu.focus();
      }
    }
    if (e.keyCode == 37) { // left
      e.preventDefault();
      //force click to hide current menu
      var $currentPhxMenu = $(e.target).closest("div.phx-menu");
      $currentPhxMenu.find("span.phx-menu-text").click();
      //move focus to previous zoneitem
      if ($currentPhxMenu.prevAll('.phx-menu').first().length == 0) {
        var lastMenu = $currentPhxMenu.parent('#phx-menu-zone-' + zoneId).find('> .phx-menu').last();
        this._selectFocusableElement(lastMenu, true, true);
      } else {
        var prevMenu = $currentPhxMenu.prevAll('.phx-menu').first();
        this._selectFocusableElement(prevMenu, true, true);
      }
    } else if (e.keyCode == 39) { // right
      e.preventDefault();
      //force click to hide current menu
      var $currentPhxMenu = $(e.target).closest("div.phx-menu");
      $currentPhxMenu.find("span.phx-menu-text").click();
      //move focus to next zoneitem
      if ($currentPhxMenu.nextAll('.phx-menu').first().length == 0) {
        var firstMenu = $currentPhxMenu.parent('#phx-menu-zone-' + zoneId).find('> .phx-menu').first();
        this._selectFocusableElement(firstMenu, true, true);
      } else {
        var nextMenu = $currentPhxMenu.nextAll('.phx-menu').first();
        this._selectFocusableElement(nextMenu, true, true);
      }
    } else if (e.keyCode == 27) { // escape
      var currentLinkMenu = $(e.target);
      var parentMenu = currentLinkMenu.closest('div.phx-menu');
      this._selectFocusableElement(parentMenu);
      //Simulate click in the menuBar in order to close drop/down menu
      parentMenu.find("> span").first().click();
    } else if (e.keyCode == 9) //tab
    {
      // If there is a submenu being show for any item in this zoneMenu, we hide it
      var menuZone = $(e.target).closest("div#phx-menu-zone-" + zoneId);
      _.each(menuZone.find("div.phx-menu"), function(menu) {
        if ($("div", menu).is(":visible")) {
          $("div", menu).hide();
          $("div", menu).attr("aria-hidden", "true");
          $("div", menu).find("ul").first().attr("aria-hidden", "true");
        }
      });
    } else if (e.keyCode == 13 || e.keyCode == 32) {
      e.preventDefault();
      //enter spacebar
      var currentTarget = $(e.target);
      $(currentTarget).find("a").first()[0].click();

    }
  },

  _clickMenu: function(e) {
    var menu = $(e.target).parents("div.phx-menu");
    if (menu.hasClass("ui-state-disabled")) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    var index = menu.attr("data-value");
    LOG.debug("menu clicked: " + index);
    if (index != undefined) {
      if ($("div", menu).is(":visible")) {
        $("div", menu).hide();
        $("div", menu).attr("aria-hidden", "true");
        $("div", menu).find("ul").first().attr("aria-hidden", "true");
      } else {
        this._closeAllMenus();
        $("div", menu).show();
        $("div", menu).attr("aria-hidden", "false");
        $("div", menu).find("ul").first().attr("aria-hidden", "false");
        //If you click out of the menu, close the menu.
        var self = this;
        $(document).one("mousedown", function(event) {
          var element = $(self.el).find(event.target);
          //					if(element.length == 0) {
          if (element.length == 0 || $(event.target).parents('.bvMenu').length) { //2nd cond: if some of bvMenu buttons is pressed, close this and only show bvMenu
            $("div", menu).hide();
            $("div", menu).attr("aria-hidden", "true");
            $("div", menu).find("ul").first().attr("aria-hidden", "true");
          }
        });
      }
    }
  },

  _clickOption: function(event) {

    var $menu = $(event.target);

    if ($menu.hasClass("ui-state-disabled")) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    // special case where no tab will be opened and UC will be shown inside popup
    if ($menu.hasClass("gmsituation")) {
      this._gmsituation();
    }
    // closes all menus
    this._closeAllMenus();
  },

  _closeAllMenus: function() {
    _.each(this.zJSON.menus, function(menu, index) {
      $("div[data-value=" + index + "] div", this.el).hide();
      $("div[data-value=" + index + "] div", this.el).attr("aria-hidden", "true");
    }, this);
  },

  // special case where no tab will be opened and UC will be shown inside popup
  _gmsituation: function() {

    // list of components to load
    var components = ["gmsituation"];

    // load dependencies to component
    objs.gmsituation = new GmSituationView();
    objs.gmsituation.open();
  }

});
