import { FormView } from 'core/views/form.view.js';
import { GLOBAL_DATA } from '../../../../globalData.js';
import { i18n } from '../../../../i18n.js';
import { PopulationSelectColaborateursModel } from '../models/populationSelectColaborateurs.model.js';
import { SelectCollabView } from 'common/referentiel/referentielcollaborateur/views/selectCollab.view.js';
import TPL_populationsmenu_popupSelectCollaborateur from '../popupSelectCollaborateur.tpl.html';

export var PopupSelectCollabView = FormView.extend({

  className: "populationsmenu",

  initialize: function(options) {
    this.template = TPL_populationsmenu_popupSelectCollaborateur;

    if (options && options.parent) {
      this.parent = options.parent;
    }
    if (options && options.parentView) {
      this.parentView = options.parentView;
    }

    this.diversIdCollab = GLOBAL_DATA.paramDivers.get("idCollab");
    var context = {
      habilitation: "RES_POPCOLLAB.G",
      usecase: "populationsmenu"
    };
    this.referenView = new SelectCollabView({
      context: context,
      exclure_fusionencours: false,
      type_collab: "R"
    });
    this.model = new PopulationSelectColaborateursModel();
    this.listenTo(this.referenView.model, "referentielChanged", this._modelChanged);
  },

  _modelChanged: function() {
    this.model.set("matric", this.referenView.model.get("matric"));
  },

  events: {
    "click .buttonAppliquer": "_appliquerCollab",
    "change :input:not([readonly])": "_change"
  },

  _getModel: function() {
    return this.model;
  },
  _appliquerCollab: function() {
    var self = this;

    this.model.set("matric", this.referenView.model.get("matric"));

    // Error management
    this.model.off("invalid");
    this.model.on("invalid", this._showValidationErrors, this);
    if (this.model.isValid()) {
      var model = this.referenView.model;

      this.parentView._setCollaborateurOnTraversalComponent(model, function(model_aux, rawData) {
        var libelle = model.get("nom") + " " + model.get("prenom") + " (";
        // Default value is matric
        if (self.diversIdCollab.get("valeur") === "matric_aux") {
          libelle += model.get("matricaux");
        } else {
          libelle += model.get("matric");
        }
        libelle += ")";
        self.referenView.model.set("libelle", libelle);
        self.parent.model.set("collab", model);
        self.parent.model.set("ident", rawData.ident);
        self.parent.close();
      });
    }
  },

  render: function() {
    var json = { "i18n": i18n };
    this.$el.append(this.template(json));
    var self = this;

    $(this.el).find(".buttonAppliquer").button();

    this.referenView.setIdCollab(this.diversIdCollab.get("valeur"));
    self.$el.find(".cmbCollab").html(this.referenView.render().el);

    this.parent._setTitle(i18n.t('populationsmenu.select_collab'));

    $(this.el).find(".cmbCollab input.nom").focus();

    return this;
  }
});
