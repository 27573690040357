import { HomeView } from './home.view.js';
import { i18n } from '../../i18n.js';
import { MSGS } from '../../utils/msgs.js';
import { NAV } from '../../utils/nav.js';
import { objs } from '../../objectsRepository.js';
import { STR } from '../../utils/str.js';
import { UTILS } from '../../utils/utils.js';
import TPL_app_tabsheet from '../tabsheet.tpl.html';
import TPL_app_tabsheet_item from '../tabsheet_item.tpl.html';

/**
 * Tabsheet View, usecase tabs loading and closing
 */
export var TabSheetView = Backbone.View.extend({

  // Stores the level of every uc in the tree. See _optionChange
  _ucStorage: undefined,
  _openTabs: undefined,

  initialize: function(options) {
    this.template = TPL_app_tabsheet;
    this.templateItem = TPL_app_tabsheet_item;
    this.zJSON = options.zJSON;

    //Calculate and store every uc in the zone
    this._ucStorage = {};
    this._openTabs = [""];
    _.each(this.zJSON.menus, function(menu) {
      if (menu.id) {
        this._ucStorage[menu.id] = menu;
      }
      _.each(menu.ecrans, function(uc) {
        if (uc.id) {
          this._ucStorage[uc.id] = uc;
        }
        _.each(uc.ecrans, function(uc2) {
          this._ucStorage[uc2.id] = uc2;
        }, this);

      }, this);
    }, this);
    this.model.on("change:usecase", this._usecaseChanged, this);
    this.model.on("destroy:usecase", this._usecaseDestroyed, this);
    // Home view
    this.home = null;
    this.ready = false;
  },

  events: {
    "click .ui-tabs-nav span.ui-icon-close": "_clickCross",
    "click .ui-tabs-nav span.ui-icon-extlink": "_clickReturn",
    "mousedown .ui-tabs-nav li": "_clickOnTab"
  },

  _clickCross: function(event) {
    var itemId = event.target.className.split(" ")[0];

    if (UTILS.usecase_loading_counter[itemId] > 0) {
      //il y a des demandes pendantes
      return false;
    }
    this.model.trigger("predestroy:usecase", itemId);
  },

  _clickReturn: function(event) {
    var currentMode = this.model.get("fullscreen");
    this.model.set("fullscreen", !currentMode);
  },

  _clickOnTab: function(event) {
    event.preventDefault();
    event.stopPropagation();
    event.returnValue = false;
    event.cancelBubble = true;

    var hasCloseIcon = $(event.target).parent().find(".ui-icon-close");
    if (event.button === 1 && hasCloseIcon.length > 0) {
      var itemId = hasCloseIcon[0].className.split(" ")[0];
      this.model.trigger("predestroy:usecase", itemId);
    }
    return false;
  },

  _usecaseChanged: function() {
    var self = this;

    // Be careful, all the TabSheets are hearing the same model
    if (this.zJSON["id"] != this.model.get("zone")) {
      return;
    }
    var ucid = this.model.get("usecase");

    if (this._openTabs.indexOf(ucid) === -1) {
      var baseModule = ucid.split("_")[0];
      if (STR.isBlank(this._ucStorage[baseModule])) {
        return;
      }
      this._createTab(this._ucStorage[baseModule], ucid);
    }

    var tabNumber = _.indexOf(this._openTabs, ucid);
    this.tabSelector.tabs("option", "active", tabNumber);

    if (!ucid) {
      ucid = "home" + this.model.get("zone");
    }

    if (this.zJSON.id === this.model.get("zone")) {
      var selectedTab = this.$el.find("ul.ui-tabs-nav li[aria-controls=" + ucid + "]");
      if (selectedTab.prop("reload")) {
        if (ucid.indexOf("home") === 0) {
          this.model.trigger("reload:brique");
        } else {
          this.model.trigger("predestroy:usecase", ucid, true, null, function() {
            self.model.trigger("changed:usecase", ucid);
          });
        }
        MSGS.showInfo(i18n.t('messages:GL_1072'));
        selectedTab.prop("reload", false);
      } else {
        this.model.trigger("changed:usecase", ucid);
      }
    }
  },

  _usecaseDestroyed: function(ucid, omitCloseTab, omittedCheck) {
    if (omitCloseTab !== true) {
      // Be careful, all the TabSheets are hearing the same model
      if (this.zJSON["id"] !== this.model.get("zone") && !omittedCheck) {
        return;
      }
      this._destroyTab(ucid, omittedCheck);
    }
  },

  _selectTab: function(event, ui) {

    var itemId = ui.newTab.attr("aria-controls");
    var type = ui.newTab.attr("uc-type"); // If the tab doesn't have type se "uc" as default

    if (itemId.indexOf("home") === 0) {
      itemId = "";
    }

    if (itemId === "planact") {
      type = "pl";
    }

    var previousUc = this.model.get("usecase");
    this.model.set("usecase", itemId);
    this.model.set("type", type);
    // Set focus to the first element in the UC.
    $(ui.newTab).focus();
    // Change the url if it's a click event. Only navigate to url if usecase has changed. This is done in order to avoid that
    // navigation to the same usecase is made more than once when accesing to a uc from a link in another uc.
    if (this.ready === true && previousUc !== itemId) {
      NAV.navigate(this.zJSON["id"], itemId, type, false);
    }
  },

  _createTab: function(uc, module) {
    var tempUc = _.clone(uc);
    if (STR.isBlank(uc.typ)) {
      tempUc.typ = "uc";
    }
    if (tempUc.id != module) {
      tempUc.id = module;
    }
    var ulDom = $("ul", this.tabSelector).eq(0);
    var newTab = $("<div id=\"" + module + "\" class='phx-proto-resizable '></div>");
    newTab.prop("fonctions", this.zJSON["fonctions"]);
    ulDom.append(this.templateItem(tempUc));
    ulDom.after(newTab);
    this.tabSelector.tabs("refresh");

    // Set WAI-ARIA property
    ulDom.parent().find("#" + uc.id).attr("aria-live", "off");

    this._openTabs.push(module);
    if (objs[module + "Rt"]) {
      $("#" + module).append(objs[module + "Rt"].layout.el);
      if (objs[module + "Rt"]._applyResizes) {
        objs[module + "Rt"]._applyResizes();
      }
    }
    //Notify tab addition
    this.model.trigger("add:tab", this.zJSON.id);
  },

  _destroyTab: function(ucid, omittedCheck) {
    var tabIndex = _.indexOf(this._openTabs, ucid);
    if (tabIndex >= 0) {
      var targetLi = $("ul li", this.tabSelector).eq(tabIndex);
      var targetDiv = $("#" + ucid, this.el);
      targetLi.remove();
      targetDiv.remove();
      this._openTabs.splice(tabIndex, 1);

      var type = $("ul li", this.tabSelector).eq(tabIndex - 1).attr("uc-type"); // If the tab doesn't have type se "uc" as default
      if (!omittedCheck) {
        this.tabSelector.tabs("refresh");
        //Change the url to the active tab without navigate
        NAV.navigate(this.zJSON["id"], this._openTabs[tabIndex - 1], type, false);
      } else {
        var l_module = this._openTabs[tabIndex - 1];

        if (!STR.isBlank(l_module)) {
          var l_zone = objs.appRt.workflow._searchZoneFromUc(l_module);
          if (l_zone !== this.zJSON.id) {
            this.zJSON.id = l_zone;
          }
        }
        this.model.set("selectedTab-" + this.zJSON.id, { "module": l_module, "type": type }, { silent: true });
      }

      //Notify tab remove
      this.model.trigger("remove:tab", this.zJSON.id);
    }
  },

  render: function() {
    this.$el.html(this.template(this.zJSON));
    // Create TabSheet
    var self = this;
    this.tabSelector = $("#phx-tabs-" + this.zJSON["id"], this.el);
    this.tabSelector.tabs({
      activate: function(event, ui) {
        self._selectTab(event, ui);
        $(ui.newPanel).off("keydown").on("keydown", function(event) {
          self._keyupHandler(event);
        });
        self.ready = true;
      },
      beforeLoad: function(event, ui) {
        ui.jqXHR.omit = true;
        event.preventDefault();
        return;
      },
      create: function(event, ui) {
        $(ui.panel).attr("aria-live", "off");
      }
    });

    this.home = new HomeView({
      el: $("#home" + this.zJSON["id"], this.el),
      zJSON: this.zJSON,
      model: this.model
    });
    this.home.render();
    return this;
  },

  _keyupHandler: function(event) {
    var itemId = event.delegateTarget.id;
    var isHome = itemId == ("home" + this.zJSON["id"]);
    var key = event.which || event.keyCode;
    // Alt + Delete close the actual tab
    if (!isHome && event.altKey == true && key == 46) {
      this.model.trigger("predestroy:usecase", itemId);
    }
  }
});