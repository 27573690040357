export var DataGridCellThFakeView = Backbone.View.extend({

  dummy: "",
  tagName: "th",

  /**
   * View of the Headers Fake Cells, only for resize an fill no data headers.
   */
  initialize: function(options) {
    this.hIndex = options.hIndex;
    this.cellIndex = options.cellIndex;
    this.last = options.last;
  },

  /**
   * Makes the columns resizeables.
   */
  resizable: function(brother) {
    var column = $(this.el).find("div");
    column.resizable({
      handles: "e",
      minWidth: 25,
      resize: function(event, ui) {
        var newWidth = ui.size.width + "px";
        ui.helper.parent().css("width", newWidth);
        $(brother.el).width(newWidth);
      }
    });
  },

  /**
   * Renders the View.
   */
  render: function() {
    $(this.el).addClass("phx-th" + this.hIndex + this.cellIndex + " ui-grid-th");
    if (this.last !== true) {
      $(this.el).addClass("ui-grid-th-RightBorder");
    }

    return this;
  }

});