import { ActivateModel } from '../models/activate.model.js';
import { BaseModel } from 'core/models/base.model.js';
import { ButtonBarView } from 'core/controls/buttonBar.view.js';
import { CompteursTableColl } from '../models/compteursTable.collection.js';
import { CompteursTableModel } from '../models/compteursTable.model.js';

import { DeplacerCompteursModel } from '../models/deplacerCompteurs.model.js';
import { EditableGridModel } from 'core/grids/editablegrid/editableGrid.model.js';
import { EditableGridView } from 'core/grids/editablegrid/editableGrid.view.js';
import { EditedRowCompteursView } from './editedRowCompteurs.view.js';
import { FORMS } from '../../../../utils/forms.js';
import { FormView } from 'core/views/form.view.js';
import { GLOBAL_DATA } from '../../../../globalData.js';
import { HABILITATION } from '../../../../utils/habilitation.js';
import { HabilitationContext } from 'core/models/habilitationContext.js';
import { i18n } from '../../../../i18n.js';
import { objs } from '../../../../objectsRepository.js';
import { ParametrageModel } from '../models/parametrage.model.js';
import { RadioBoxView } from 'core/views/radioBox.view.js';
import { STR } from '../../../../utils/str.js';
import { ParametragebresultatsWorkflow } from '../models/parametragebresultats.workflow.js';
import TPL_parametragebresultats_detail from '../detail.tpl.html';

/**
 * Render gerer parametrage of bresultats view
 */

export var ParametrageView = FormView.extend({

  initialize: function(options) {
    var self = this;
    this.id = null;
    this.isContextInitialized = false;
    this.template = TPL_parametragebresultats_detail;

    this.model = new BaseModel({
      value: null,
      mode: "C"
    });

    this.workflow = new ParametragebresultatsWorkflow();
    this.workflow.formModel = this.model;
    this.btnBar = new ButtonBarView({ id: "parametrageResultats_btnbar" });
    this.workflow.btnBarModel = this.btnBar.model;
    this.workflow.parametrageResultats = this;

    this.changeDetected = false;
    this.model.on("change:value", this._mapToForm, this);

    if (options) {
      if (options && options.context) {
        /**
        // Context
        // context.ctxEcran
        // context.ctxHabilitation.HabilitationAcces
        // context.ctxHabilitation.HabilitationGestion
        // context.espaceid
        // context.zone
        // context.zoneName
        // context.ctxActionsPossibles
        // context.ctxModeInitialisation
        // context.briqueName
        // context.typebrique
        // context.userid
        // context.codebrique
        **/
        this.context = options.context;
        this.model.setHabContext(new HabilitationContext({
          onglet: this.context.ctxEcran,
          fonc_cour: this.context.ctxHabilitation.HabilitationAcces
        }));
      }
      if (options && options.parent) {
        this.parent = options.parent;
      }
    }

    if (STR.isBlank(this.model.getHabContext())) {
      this.model.setHabContext(new HabilitationContext({
        onglet: "",
        fonc_cour: ""
      }));
    }
    this.btnBar.model.on("change:mode", this._modeChanged, this);

    this.table = this._initTable();

    this.table.model.btnBarModel.off("btn:click");
    this.listenTo(this.table.model.btnBarModel, "btn:click", this._customTableClick);
    this.listenTo(this.table.model.coll, "sync", function() {
      self._checkParametresDivButtons();
    }, this);

    //TODO use correct parametres divers
    this.BRCnbElt = GLOBAL_DATA.paramDivers.get("BRCnbElt");

    this._initCombos();
    this.pkSelectors = ["codebrique"];
  },
  /**
   * Initializes the edit table view.
   */
  _initTable: function() {
    var self = this;
    var tableModel = new EditableGridModel({
      coll: new CompteursTableColl()
    });

    // set habilitations context that the table collection will use
    var hcColl = new HabilitationContext({
      onglet: this.context.ctxEcran,
      fonc_cour: this.context.ctxHabilitation.HabilitationAcces
    });
    hcColl.update({ nat_gest: "" });
    tableModel.coll.setHabContext(hcColl);

    var tblColumns = [{
      title: i18n.t('common:parametragebresultats.table_col_compteur'),
      property: "compteur.code",
      pk: true,
      width: 300
    }, {
      title: i18n.t('common:parametragebresultats.table_col_type'),
      property: "typeresultat.code",
      width: 250
    }, {
      title: i18n.t('common:parametragebresultats.table_col_periodicite'),
      property: "periodicite.code",
      width: 250
    }, {
      title: i18n.t('common:parametragebresultats.table_col_periode'),
      property: "periodevalo.code",
      width: 250
    }, {
      title: i18n.t('common:parametragebresultats.table_col_format'),
      property: "format",
      width: 70
    }];

    var viewRow = new EditedRowCompteursView({
      context: this.context
    });

    var table = new EditableGridView({
      id: "liste_de_compteurs",
      columns: tblColumns,
      model: tableModel,
      viewRow: viewRow,
      title: i18n.t('common:parametragebresultats.table_title'),
      showFilteredRowsInTitle: true,
      habilitations: this.context.ctxHabilitation.HabilitationGestion,
      omitHabilitationAttributes: true
    });

    table.height = 180;

    // Render the Cells for formated the text presentation
    table.cellRenderers["compteur.code"] = function(model) {
      if (model.get("compteur").code < 0) {
        return i18n.t('common:parametragebresultats.table_ajout_ligne');
      } else {
        return model.get("compteur").libelle + " (" + model.get("compteur").code + ")";
      }
    };
    table.cellRenderers["typeresultat.code"] = function(model) {
      if (model.get("compteur").code > 0) {
        return model.get("typeresultat").libelle + "(" + model.get("typeresultat").code + ")";
      } else {
        return ""; // Blank Line.
      }
    };
    table.cellRenderers["periodicite.code"] = function(model) {
      if (STR.getElValue(model, "typeresultat.code") != "A" && STR.getElValue(model, "typeresultat.code") != "R") {
        return "";
      } else {

        if (model.get("compteur").code > 0) {
          return model.get("periodicite").libelle + "(" + model.get("periodicite").code + ")";
        } else {
          return ""; // Blank Line.
        }
      }
    };
    table.cellRenderers["periodevalo.code"] = function(model) {
      if (STR.getElValue(model, "typeresultat.code") != "A" && STR.getElValue(model, "typeresultat.code") != "R") {
        return "";
      } else {
        if (model.get("compteur").code > 0) {
          return model.get("periodevalo").libelle + "(" + model.get("periodevalo").code + ")";
        } else {
          return ""; // Blank Line.
        }
      }
    };
    table.cellRenderers["format"] = function(model) {
      return !STR.isBlank(model.get("compteur").format) ? model.get("compteur").format : "";
    };
    table.setSortableColumns([""]);

    table.model.btnBarModel.trigger("hab.hide:copy");

    // create a copy of the habilitation context to be used by the actions (add/update/remove) originating from the grid
    var hc = new HabilitationContext({
      onglet: this.context.ctxEcran,
      fonc_cour: this.context.ctxHabilitation.HabilitationGestion
    });
    hc.update({ nat_gest: "M" });
    table.model.setHabContext(hc);

    //Enable row ordering
    table.model.btnBarModel.set("rowOrdering", true);

    //titles -Infobulle
    table.buttonBar.setTitle("monter", i18n.t('common:parametragebresultats.button_remonter'));
    table.buttonBar.setTitle("descendre", i18n.t('common:parametragebresultats.button_descendre'));

    return table;
  },

  _initTableMenu: function() {
    if (HABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion) && this._checkPublicHab()) {
      this.table.buttonBar.addButton(i18n.t('common:parametragebresultats.table_ajout_ligne'), "ajoutLigne", false, null, i18n.t('common:parametragebresultats.button_ligne_separation'));
      this.table.buttonBar.setButtonVisibleInModeC("ajoutLigne", false);
    }
  },
  _checkPublicHab: function() {
    var valid = false;
    if (STR.getElValue(this.model.get("value"), "visibilite") != true) {
      valid = true;
    } else if (STR.getElValue(this.model.get("value"), "visibilite") == true &&
      HABILITATION.canView("PER_BRACCTOUS.G")) {
      valid = true;
    }
    valid &= HABILITATION.canUpdate(this.context.ctxHabilitation.HabilitationGestion);
    return valid;
  },
  _modeChanged: function() {
    if (this.model.get("value") != null && this.model.get("value").isNew()) {
      this._enableGrid(false);
      this.btnBar.model.trigger("disable:activer");
      this.btnBar.model.trigger("disable:desactiver");
    } else {
      if (this.btnBar.model.get("mode") == "E") {
        this._enableGrid(false);
        this.btnBar.model.trigger("disable:activer");
        this.btnBar.model.trigger("disable:desactiver");
      } else {
        this._enableGrid(true);
        this.btnBar.model.trigger("enable:activer");
        this.btnBar.model.trigger("enable:desactiver");
      }
    }
    if (!HABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion) || !this._checkPublicHab()) {
      this._enableGrid(false);
    }

  },
  _enableGrid: function(enabled) {
    if (enabled == false) {
      //Disable grid when Create or Copy
      this.table.enabled(false);
    } else {
      //Enable Grid when Not Create or Copy
      this.table.enabled(true);
    }
  },
  _initCombos: function() {
    var self = this;
    this.RadioOptions = [];
    if (this.context.zone == "coll") {
      this.RadioOptions.push({ code: "J", libelle: i18n.t('common:parametragebresultats.radio_date') });
      this.RadioOptions.push({ code: "V", libelle: i18n.t('common:parametragebresultats.radio_derniere') });
    } else if (this.context.zone == "resp") {
      this.RadioOptions.push({ code: "J", libelle: i18n.t('common:parametragebresultats.radio_date') });
      this.RadioOptions.push({ code: "H", libelle: i18n.t('common:parametragebresultats.radio_hier') });
    }

    this.radioDate = new RadioBoxView({
      options: this.RadioOptions,
      name: "datereference",
      required: true,
      selectedOption: "J"
    });

  },

  _formEdited: function() {
    this.model.trigger("form:edited");
  },

  _getmodel: function() {
    return this.model.get("value");
  },

  _panelStateIsReadonly: function() {
    var model = this._getModel();
    var isReadOnly = false;

    if (model && model.context && model.context.ctxHabilitation && model.context.ctxHabilitation.HabilitationGestion) {
      var canUpdate = HABILITATION.canView(model.context.ctxHabilitation.HabilitationGestion) && this._checkPublicHab();
      isReadOnly = !canUpdate && !model.isNew();
    }
    return isReadOnly;
  },

  _manageModeInitialisation: function(callback) {
    var initMode = this.context.ctxModeInitialisation;
    var parametrage = new ParametrageModel();

    switch (initMode) {
      case "Consultation":
        this._initModeConsultation(parametrage, callback);
        break;
      case "Ajout":
        this._initModeAjout(parametrage, callback);
        break;
      default:
        break;
    }
  },

  _initModeConsultation: function(parametrage, callback) {
    var self = this;
    this.model.updateHabContext({
      fonc_cour: this.context.ctxHabilitation.HabilitationAcces,
      nat_gest: ""
    });
    parametrage.setHabContext(this.model.getHabContext());

    this.btnBar.model.set("mode", "R");
    parametrage.id = this.context.userid + "," + this.context.codebrique;
    parametrage.espaceid = this.context.espaceid;
    parametrage.action = "GET";
    parametrage.fetch({
      success: function(fresh) {
        self.table.model.coll.userid = self.context.userid;
        self.table.model.coll.codebrique = self.context.codebrique;
        self.table.model.coll.fetch({
          success: function(freshColl) {
            fresh.action = "";
            fresh.store();
            freshColl.store();
            self.model.set("value", parametrage); //This will trigger a _setContextGMSituation, and this a _mapToForm
            self.model.store();
            self.model.get("value").store();
            self.original = fresh.clone();

            if (callback) {
              callback();
            }
          }
        });
      }
    });
  },

  _initModeAjout: function(parametrage, callback) {
    this.model.updateHabContext({
      fonc_cour: this.context.ctxHabilitation.HabilitationGestion,
      nat_gest: "A"
    });

    this.btnBar.model.trigger("show:save");
    this.btnBar.model.trigger("show:revert");

    parametrage.store();
    this.model.set("value", parametrage);
    this.model.get("value").store();
    this.original = parametrage.clone();

    if (callback) {
      callback();
    }

    this.btnBar.model.set("mode", "E");
    if (HABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion)) {
      this.btnBar.model.trigger("btn:click", "new");
    }

  },

  _manageActionsPossibles: function() {
    //["Creer","Supprimer","Modifier","Valider"]
    if (this.context.ctxActionsPossibles) {
      if (this.context.ctxModeInitialisation === "Consultation") {
        if (this.context.ctxActionsPossibles.indexOf("Creer") != -1) {
          this.btnBar.model.trigger("show:new");
        }
        if (this.context.ctxActionsPossibles.indexOf("Supprimer") != -1) {
          this.btnBar.model.trigger("show:delete");
        }
        if (this.context.ctxActionsPossibles.indexOf("Dupliquer") != -1) {
          this.btnBar.model.trigger("show:copy");
        }
        if (this.context.ctxActionsPossibles.indexOf("Modifier") == -1) {
          this._disableForm(false);
        }
        if (this.context.ctxActionsPossibles.indexOf("Creer") != -1 || this.context.ctxActionsPossibles.indexOf("Modifier") != -1) {
          this.btnBar.model.trigger("show:save");
          this.btnBar.model.trigger("show:revert");
        }
        if (this.context.ctxActionsPossibles.indexOf("Activer") == -1) {
          this.btnBar.model.trigger("show:activer");
          this.btnBar.model.trigger("show:desactiver");
        }
      } else {
        if (this.context.ctxActionsPossibles && this.context.ctxActionsPossibles.indexOf("Creer") != -1) {
          this.btnBar.model.trigger("show:new");
        }
        this.btnBar.model.trigger("hide:activer");
        this.btnBar.model.trigger("hide:desactiver");
      }
    }
  },

  _disableForm: function(isNullable) {
    this.disabled = true;
    FORMS.setFormReadonly(this.$el, true, false);
  },

  _enableForm: function(forced) {
    if (forced != true) {
      forced = false;
    }
    this.disabled = false;
    FORMS.setFormReadonly(this.$el, false, forced);
  },

  _checkParametresDivButtons: function() {
    if (!STR.isBlank(this.BRCnbElt) && this.BRCnbElt.get("valeur") <= this.table.model.coll.models.length) {
      $(this.table.buttonBar.$el).find(".new").hide();
      $(this.table.buttonBar.$el).find(".ajoutLigne").hide();
    } else if (HABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion)) {
      if (this._checkPublicHab()) {
        $(this.table.buttonBar.$el).find(".new").show();
        $(this.table.buttonBar.$el).find(".ajoutLigne").show();
      }
    }
  },

  _manageHabilitation: function() {
    if (!HABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion)) {
      this._disableForm(false);
      this.btnBar.model.trigger("hide:new");
      this.btnBar.model.trigger("hide:copy");
      this.btnBar.model.trigger("hide:delete");
      this.btnBar.model.trigger("hide:revert");
      this.btnBar.model.trigger("hide:save");
    }
    if (!HABILITATION.canCreate(this.context.ctxHabilitation.HabilitationGestion)) {
      this.btnBar.model.trigger("hide:new");
      this.btnBar.model.trigger("hide:copy");
    }
    if (!HABILITATION.canUpdate(this.context.ctxHabilitation.HabilitationGestion) && !HABILITATION.canCreate(this.context.ctxHabilitation.HabilitationGestion)) {
      this.btnBar.model.trigger("hide:save");
    }
    if (!HABILITATION.canDelete(this.context.ctxHabilitation.HabilitationGestion)) {
      this.btnBar.model.trigger("hide:delete");
    }
    if (!this._checkPublicHab()) {
      if (this.btnBar.model.get("mode") != "E") {
        this._disableForm(false);
      }
      this.btnBar.model.trigger("hide:delete");
      //Disable table if it has no habilitation
      this._enableGrid(false);
    }

    if (!HABILITATION.canView(this.context.ctxHabilitation.HabilitationAcces)) {
      this.btnBar.model.trigger("hide:activer");
      this.btnBar.model.trigger("hide:desactiver");

    }
  },

  _visibilitePublic: function(model) {
    var publ = false;
    if (model.get("visibilite") == true) {
      publ = true;
    }
    return publ;
  },

  _manageActiverButtons: function() {
    if (!STR.isBlank(this.model.get("value")) && !this.model.get("value").isNew()) {
      if (this.model.get("value").get("active") == true) {
        this.btnBar.model.trigger("hide:activer");
        if (HABILITATION.canView(this.context.ctxHabilitation.HabilitationAcces)) {
          this.btnBar.model.trigger("show:desactiver");
        }
      } else {
        this.btnBar.model.trigger("hide:desactiver");
        if (HABILITATION.canView(this.context.ctxHabilitation.HabilitationAcces)) {
          this.btnBar.model.trigger("show:activer");
        }
      }
    } else {
      this.btnBar.model.trigger("hide:activer");
      this.btnBar.model.trigger("hide:desactiver");
    }
  },

  events: _.extend({
    "change .parametragebresultats-detail :input": "_manageView",
    "keyup .parametragebresultats-detail :input:not([readonly])": "_notifyEdition"
  }),

  render: function() {
    var json = { "i18n": i18n };
    this.$el.empty();

    this.$el.html(this.template(json));
    this.$el.find(".radioDateReference").html(this.radioDate.render().el);
    if (this.context) {
      this.btnBar.model.off("btn:click");
      this.btnBar.model.on("btn:click", this.workflow._buttonAction, this.workflow);
      this.model.off("form:edited");
      this.model.on("form:edited", this.workflow._formEdited, this.workflow);
      this._setContext(this.context);
    }
    this.$el.find(".tableCompteurs").html(this.table.el);
    this.table.render();

    if (this.model.get("value") != null && this.model.get("value").isNew()) {
      this._enableGrid(false);
    }
    //disable buttons placer and premier
    $(this.table.buttonBar.$el).find(".placer").hide();
    $(this.table.buttonBar.$el).find(".premier").hide();

    return this;
  },
  _setTitlePopUp: function() {
    var title = "";
    if (!this.model.get("value").isNew()) {
      var value = this._getModel();
      this.$el.find(".titreBrique").html(value.get("libelle"));
      var activeText = value.get("active") == true ? i18n.t('common:parametragebresultats.activee') : i18n.t('common:parametragebresultats.desactivee');
      var cssClassText = "ui-phx-ihm-texte-donnees-alternatif-important";
      this.$el.find(".infoCompBrique").addClass("ui-phx-ihm-texte-donnees-alternatif").css("font-size", "12px");
      this.$el.find(".infoCompBrique").html(i18n.t('messages:GT_1798', { "1": "<span class='" + cssClassText + "'>" + this.context.zoneName + "</span>", "2": "<span class='" + cssClassText + "'>" + activeText + "</span>", interpolation: { escapeValue: false } }));

    } else {
      var value = this._getModel();
      var l_title = "";
      if (this.context.typebrique == "CPTC" || this.context.typebrique == "CPTR") {
        //typebrique	= ‘CPTC’ ou ‘CPTR’-->( permet de déterminer BrAccTypeLibelle actuellement fixe = « Résultats » pour les lignes fictives)
        //"Nouvelle brique d’accueil de type "BrAccTypeLibelle" … "
        l_title = i18n.t('common:parametragebresultats.new_title', { "0": i18n.t('common:parametragebresultats.typeLibelle_resultats') });
      }
      this.$el.find(".titreBrique").html(l_title);
      this.$el.find(".infoCompBrique").html("");
    }
    this.parent._setTitle(i18n.t('common:parametragebresultats.title'));
  },
  _generateButtonBar: function() {
    this.$el.find(".btnBar-container").append(this.btnBar.render().el);
    this.$el.find("#parametrageResultats_btnbar").css("float", "left");

    this.btnBar.addButton("activer", i18n.t('common:parametragebresultats.activer'), "", null);
    this.btnBar.addButton("desactiver", i18n.t('common:parametragebresultats.desactiver'), "", null);
  },

  _setContext: function(context) {
    var self = this;
    this.workflow.context = context;

    this.model.updateHabContext({
      onglet: context.ctxEcran,
      fonc_cour: context.ctxHabilitation.HabilitationAcces,
      nat_gest: ""
    });
    this.context = context;

    if (STR.isBlank(this.context)) {
      throw new Error("A context must be defined...");
    }

    this._generateButtonBar();
    self._initTableMenu();
    this._manageModeInitialisation(function() {
      self._setTitlePopUp();
      self._manageActiverButtons();
      self._manageActionsPossibles();
      self._manageHabilitation();
    });

    if (this.context.ctxModeRepresentation === "pop-up") {
      $(this.parent.el).css("height", "auto");
    }

  },

  _mapToForm: function() {
    this._enableForm();
    var parametrage = this.model.get("value");

    if (this.$el === undefined) {
      // this happens if component has been removed before initialization process has finished
      return;
    }
    if (parametrage !== null) {

      var renderers = {};
      var fieldset = this.$el.find("fieldset");
      // Error management
      parametrage.off("invalid");
      parametrage.on("invalid", this._showValidationErrors, this);
      // Clear current validation erros
      this._cleanValidationErrors();

      //FormView mapToForm
      this._mapModelToForm(fieldset, parametrage, renderers);

      if (parametrage.isNew()) {
        fieldset.find(".codebrique").focus();
      }
      if (!STR.isBlank(this.model.get("value")))
        this._enablePk(this.model.get("value").isNew());

      if (!HABILITATION.canView("PER_BRACCTOUS.G")) {
        FORMS.setFieldReadonly(this.$el.find("input.visibilite"), true, false);
      } else {
        FORMS.setFieldReadonly(this.$el.find("input.visibilite"), false, false);
      }
    }
  },

  _manageView: function(event, data) {
    var parametrage = this.model.get("value");
    var className = event.target.className.split(" ")[0];

    this._change(event, data);
  },

  _restoreHabilitation: function() {
    this.model.updateHabContext({
      fonc_cour: this.context.ctxHabilitation.HabilitationGestion,
      nat_gest: "M"
    });
    this.model.get("value").setHabContext(this.model.getHabContext());
  },
  _restoreParametrage: function() {
    if (!STR.isBlank(this.model.get("value").oldAttributes)) {
      this.model.get("value").revert();
      this.table.model.coll.revert();
      this.table.model.coll.trigger("reset");

      this._restoreHabilitation();

    } else if (this.model.get("value").isNew()) {
      var parametrage = new ParametrageModel();
      this.model.updateHabContext({
        fonc_cour: this.context.ctxHabilitation.HabilitationGestion,
        nat_gest: "A"
      });
      parametrage.setHabContext(this.model.getHabContext());

      this.model.set("value", parametrage);
    }

    this.model.trigger("change:value");
  },

  revertView: function() {
    this.model.updateHabContext({ fonc_cour: this.context.ctxHabilitation.HabilitationGestion, nat_gest: "" });
    var fieldset = this.$el.find("fieldset");

    this._restoreParametrage();

    this.workflow.btnBarModel.set("mode", "R");

    this._manageActionsPossibles();
    this._manageActiverButtons();
    this._manageHabilitation();
    this._setTitlePopUp();
    if (this.model.get("value").isNew()) {
      var previous = this.model.previous("value");
      this.btnBar.model.set("mode", "C");
    }
  },

  saveParametrage: function() {
    var self = this;
    var error = false;
    var target = this.model.get("value");
    var saveAction = "update";
    if (target.isNew()) {
      if (!STR.isBlank(target.formerID)) {
        saveAction = "duplicate";
      } else {
        saveAction = "create";
      }

      target.set("userid", objs.appRt.workflow.authModel.get("user"));
      target.set("typebrique", this.context.typebrique);
      this.model.updateHabContext({
        fonc_cour: this.context.ctxHabilitation.HabilitationGestion,
        nat_gest: "A"
      });
      target.setHabContext(this.model.getHabContext());
    } else {
      saveAction = "modification";
      this.model.updateHabContext({
        fonc_cour: this.context.ctxHabilitation.HabilitationGestion,
        nat_gest: "M"
      });
      target.setHabContext(this.model.getHabContext());
    }
    var finalCallback = function(fresh) {
      self.btnBar.model.set("mode", "R");

      self.model.trigger("parametrageChanged", fresh, saveAction);
      self.context.ctxModeInitialisation = "Consultation";

      target.formerID = null;
      target.store();
      self.table.model.coll.userid = target.get("userid");
      self.table.model.coll.codebrique = target.get("codebrique");
      if (saveAction == "create") {
        self.table.model.coll.reset();
      }

      self.table.model.coll.store();

      self._manageActiverButtons();
      self._restoreHabilitation();

      self.model.trigger("change:value"); //Trigger map to form and _enablepk
      self._setTitlePopUp();
      self._manageHabilitation();
    };

    var saveCallback = function(fresh) {
      if (saveAction == "duplicate") {
        self.table.model.coll.userid = fresh.get("userid");
        self.table.model.coll.codebrique = fresh.get("codebrique");
        self.table.model.coll.fetch({
          success: function(fresh) {
            finalCallback(fresh);
          }
        });
      } else {
        finalCallback(fresh);
      }

    };

    target.save(null, {
      success: saveCallback
    });
  },

  deleteParametrage: function(commentaire) {
    var self = this;
    var target = this.model.get("value");

    this.model.updateHabContext({ fonc_cour: this.context.ctxHabilitation.HabilitationGestion, nat_gest: "S" });

    var destroyCallback = function() {
      self.model.trigger("parametrageChanged", target, "delete");
      self.parent.close();
    };
    target.destroy({
      contentType: 'application/json',
      success: destroyCallback,
      error: destroyCallback,
      wait: true
    });
  },

  newParametrage: function() {
    var self = this;
    var modeloldAttributes = "";
    var colloldModels = "";
    if (!this.model.get("value").isNew()) {
      this.model.get("value").store();
      this.table.model.coll.store();
      modeloldAttributes = this.model.get("value").oldAttributes;
      colloldModels = this.table.model.coll.oldModels;
    }

    this.model.updateHabContext({
      fonc_cour: this.context.ctxHabilitation.HabilitationGestion,
      nat_gest: "A"
    });

    this._enableForm();
    var parametrage = new ParametrageModel();

    parametrage.setHabContext(self.model.getHabContext());
    this.table.model.coll.reset();

    this.model.set("value", parametrage);
    //Keep old attributes
    if (!STR.isBlank(modeloldAttributes) && !STR.isBlank(colloldModels)) {
      this.model.get("value").oldAttributes = modeloldAttributes;
      this.table.model.coll.oldModels = colloldModels;
    }
    this._setTitlePopUp();

    this._manageActionsPossibles();

    this.btnBar.model.trigger("show:save");
    this.btnBar.model.trigger("show:revert");
    this.btnBar.model.set("mode", "E");
  },

  activerParametrage: function(action) {
    var self = this;
    var value = this.model.get("value");
    var activateModel = new ActivateModel();
    activateModel.userid = value.get("userid");
    activateModel.codebrique = value.get("codebrique");
    activateModel.action = action == "activer" ? "activate" : "desactivate";
    activateModel.espaceid = this.context.espaceid;
    activateModel.id = "put";
    activateModel.setHabContext(this.model.getHabContext());
    var activateCallback = function(fresh) {
      action == "activer" ? self.model.get("value").set("active", true) : self.model.get("value").set("active", false); //Update active information
      self.model.get("value").store();
      self.btnBar.model.set("mode", "R");
      self.model.trigger("parametrageChanged", fresh, action);
      self.context.ctxModeInitialisation = "Consultation";
      self._manageActiverButtons();
      self._manageHabilitation();

      self._restoreHabilitation();
      self.model.trigger("change:value"); //Trigger map to form and _enablepk
      self._setTitlePopUp();
    };
    activateModel.save(null, {
      success: activateCallback
    });
  },
  _customTableClick: function(buttonId) {
    var self = this;
    var value = this.model.get("value");
    var model = this.table.model.get("value");
    switch (buttonId) {
      case "save":
        if (!STR.isBlank(model.get("compteur"))) { //Format field is not expected in modification and creation WS
          delete model.get("compteur").format;
        }
        if (model.isNew()) { //Set ordre in creation
          model.set("ordre", this.table.model.coll.length);
        }
        this.table.model._buttonAction(buttonId, function(action, model) {
          self.table.model.coll.store();
          self.model.trigger("parametrageChanged", value, "modification");
        });

        break;
      case "delete":
        this.table.model._buttonAction(buttonId, function(action, model) {
          self.table.model.coll.store();
          self.model.trigger("parametrageChanged", value, "modification");
        });

        break;
      case "monter":
        this._movePositionInfoComp(model, "monter");
        break;
      case "descendre":
        this._movePositionInfoComp(model, "descendre");
        break;
      case "ajoutLigne":
        if (!STR.isBlank(model.get("compteur"))) { //Format field is not expected in modification and creation WS
          delete model.get("compteur").format;
        }
        this._ajouterLigneSeparation();
        break;
      default:
        this.table.model._buttonAction(buttonId, function(action, model) {
          self.table.model.coll.store();
        });
    }
  },
  /**
   * Move the position of the information complementaire.
   */
  _movePositionInfoComp: function(model, sens) {
    var self = this;
    var value = this.model.get("value");
    // Calculate the order
    var ordre = model.get("ordre");
    if (sens == "monter") {
      ordre--;
    } else {
      ordre++;
    }

    // Create the model data
    var deplacerAttrModel = new DeplacerCompteursModel(model.attributes);
    deplacerAttrModel.set("id", model.get("rowid"));
    deplacerAttrModel.userid = this.context.userid;
    deplacerAttrModel.codebrique = this.table.model.coll.codebrique;
    deplacerAttrModel.ordre = ordre;
    deplacerAttrModel.setHabContext(this.table.model.getHabContext());

    // Save the modification
    deplacerAttrModel.save(null, {
      success: function(fresh) {
        self.table.model.coll.fetch({
          success: function(fresh) {
            self.table.model.coll.store();
            self.model.trigger("parametrageChanged", value, "modification");
            // Select the record and paginate if required
            self.table.model.set("mode", "R");
            self.table.model.selectRow(model);
          }
        });
      }
    });
  },
  _ajouterLigneSeparation: function() {
    var self = this;
    var modelValue = this.model.get("value");
    var value = this.table.model.get("value");
    var collection = this.table.model.coll;
    var ligneSeparationModel = new CompteursTableModel(value.attributes);
    ligneSeparationModel.set("id", null); //This should be post
    ligneSeparationModel.id = null;
    var url = Configuration.restRoot + "/rest/personnalisation/definition/brique/rescpt/" + encodeURIComponent(collection.userid + "," + collection.codebrique) + "/detail/";
    ligneSeparationModel.url = url;
    ligneSeparationModel.setHabContext(this.table.model.getHabContext());
    STR.setElValue(ligneSeparationModel, "compteur.code", -value.get("ordre"));
    // Create new ligne de separation
    ligneSeparationModel.save(null, {
      success: function(fresh) {
        self.table.model.coll.fetch({
          success: function(fresh) {
            self.table.model.coll.store();
            self.model.trigger("parametrageChanged", modelValue, "modification");
            // Select the record and paginate if required
            self.table.model.set("mode", "R");
            self.table.model.selectRow(value);
          }
        });
      }
    });
  }
});