import { BaseModel } from 'core/models/base.model.js';
import { i18n } from '../../../../i18n.js';
import TPL_suvietat_customBar from '../customBar.tpl.html';

/**
 * Custom Bar View
 */
export var CustomBarView = Backbone.View.extend({

  events: {
    "click a": "_manageLink"
  },

  initialize: function(options) {
    this.model = new BaseModel({
      stopDefil: false
    });
    if (options && options.workflow) {
      this.workflow = options.workflow;
    }

    this.template = TPL_suvietat_customBar;
  },

  render: function() {
    var json = { "i18n": i18n };
    this.$el.html(this.template(json));

    return this;
  },

  _manageLink: function(event) {
    var target = event.target;
    var element = event.target.className.split(" ")[0];
    this.workflow.stream({ interval: this.workflow.paramRafEtat });
  }
});