import { BaseModel } from 'core/models/base.model.js';
import { STR } from '../../../../utils/str.js';

export var DynamicComboModel = BaseModel.extend({

  initialize: function(options) {
    if (!STR.isBlank(options)) {
      this.urlService = options.url;
      this.defaut = options.defaut;
      this.nbrang = options.nbrang;
      this.optionsFiltre = options.filtre;
    }
  },

  url: function() {
    return Configuration.restRoot + "/" + this.urlService + ((!STR.isBlank(this.nbrang) || !STR.isBlank(this.defaut)) ? '?' : '') + (!STR.isBlank(this.defaut) ? 'valeurinit=' + this.defaut : '') + (!STR.isBlank(this.nbrang) ? (!STR.isBlank(this.defaut) ? '&' : '') + 'nbrang=' + this.nbrang : '') + (!STR.isBlank(this.optionsFiltre) ? '&filtre=' + this.optionsFiltre : '');
  },

  parse: function(response) {
    if (response.code !== undefined) {
      response.id = response.code;
    }
    return response;
  }
});