import { LibellesModel } from './libelles.model.js';
import { PaginatedCollection } from '../../../../../core/models/paginated.collection.js';

export var LibellesModelColl = Backbone.Collection.extend({

  model: LibellesModel,

  url: Configuration.restRoot + "/rest/utilbrique/situations",

  initialize: function(options) {
    PaginatedCollection.prototype.initialize.call(this);
    this.sortings = { code: true };
  }
});