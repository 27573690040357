
import { PaginatedCollection } from '../../../../../../core/models/paginated.collection.js';
import { SaisieOptionsModel } from './saisieOptions.model.js';

/**
 * Collection for Authorized type saisies comboColl
 */
export var TypeSaisieOptionsColl = PaginatedCollection.extend({

  model: SaisieOptionsModel,

  initialize: function() {
    PaginatedCollection.prototype.initialize.call(this);
    this.paginated = false;
  },

  url: function() {
    return Configuration.restRoot + "/rest/utilevenement/modesaisie/typesaisie?affectation=" + this.affectation;
  }

});