module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<li aria-controls="'+
((__t=(id))==null?'':__t)+
'" ';
 if(typ){
__p+='uc-type="'+
((__t=(typ))==null?'':__t)+
'"';
}
__p+='>\n  <a href="#'+
((__t=(id))==null?'':__t)+
'">\n    '+
((__t=(onglet))==null?'':__t)+
'\n  </a><span tabindex="0" class="'+
((__t=(id))==null?'':__t)+
' ui-icon ui-icon-close">Remove Tab</span>\n</li>\n';
}
return __p;
};
