module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n  <tr>\n    <td><span class="phx-list-builder-item-text" title="'+
((__t=(label ))==null?'':__t)+
'">'+
((__t=(label ))==null?'':__t)+
'</span></td>\n    <td><span class="phx-list-builder-item-icon ui-icon ui-icon-close"></span></td>\n  </tr>\n</table>';
}
return __p;
};
