import { i18n } from '../../i18n.js';
import { LOG } from '../../utils/log.js';
import { objs } from '../../objectsRepository.js';
import { SplitPanelView } from '../layouts/splitPanel.view.js';
import { STR } from '../../utils/str.js';

export var DialogView = Backbone.View.extend({

  //REVIEW: 	i18n : phx.i18n.common,
  className: "phx-Dialog",

  initialize: function(options) {
    this.dialog = null;
    this.model = new Backbone.Model();
    this.dialogTemplate = "";
    this.dialogClass = "";
    this.height = 400;
    this.width = 300;
    this.minHeight = 150;
    this.minWidth = 150;
    this.data = {};
    this.position = {};
    this.title = "";
    if (options) {
      if (options.view) {
        this.view = options.view;
      }
      if (options.viewData) {
        this.viewData = options.viewData;
      }
      if (options.viewData && !STR.isBlank(options.viewData.modal)) {
        this.modal = options.viewData.modal;
      } else {
        this.modal = true;
      }
      if (options.viewData && !STR.isBlank(options.viewData.appendTo)) {
        this.appendTo = options.viewData.appendTo;
      }
      if (options.dialogClass) {
        this.dialogClass = options.dialogClass;
      }
    }

    this.annuled = false;
    this.internalView = null;

    this.listenTo(this.model, "dlg:open", this.open);
    this.listenTo(this.model, "dlg:close", this.close);
    this.listenTo(this.model, "dlg:resize", this.resize);

  },

  events: {
    "dialogbeforeclose": "_handleEvents",
    "dialogcreate": "_handleEvents",
    "dialogopen": "_handleEvents",
    "dialogfocus": "_handleEvents",
    "dialogdragStart": "_handleEvents",
    "dialogdrag": "_handleEvents",
    "dialogdragStop": "_handleEvents",
    "dialogresizeStart": "_handleEvents",
    "dialogresize": "_handleEvents",
    "dialogresizeStop": "_handleEvents",
    "dialogclose": "_handleEvents"
  },

  setAnnuled: function(annuled) {
    this.annuled = annuled;
  },
  getAnnuled: function() {
    return this.annuled;
  },
  setWidth: function(width) {
    this.width = width;
  },

  setHeight: function(height) {
    this.height = height;
  },

  setMinWidth: function(width) {
    this.minWidth = width;
  },

  setMinHeight: function(height) {
    this.minHeight = height;
  },

  setPosition: function(position) {
    this.position = position;
  },

  hideCloseButton: function() {
    this.dialogClass += " no-close";
  },

  render: function() {

    this.dialog = $(this.el).dialog({
      modal: this.modal,
      height: this.height,
      width: this.width,
      minHeight: this.minHeight,
      minWidth: this.minWidth,
      position: this.position,
      appendTo: this.appendTo,
      closeText: i18n.t('common:close'),
      dialogClass: this.dialogClass,
      title: this.title,
      create: function() {
        var $parent = $(this).parent();
        $parent.removeClass("ui-corner-all");
        $parent.find(".ui-dialog-titlebar").removeClass("ui-corner-all");
      },
    });

    $.ui.dialog.prototype._focusTabbable = function() {
      //empty
    };

    if (!STR.isBlank(this.appendTo)) {
      this.dialog.dialog("widget").draggable("option", "containment", this.appendTo);
    }

    return this;
  },

  resize: function() {
    // Iterate all proto-resizables and change its height.
    var components = $(".phx-proto-resizable", this.el);
    var height = $(this.el).height();
    var dialogOffsetTop = $(this.el).offset().top;

    _.each(components, function(component) {
      var offsetTop = $(component).offset().top - dialogOffsetTop;
      // IF is a panel B4 adjust the size of the scroll
      if ($(component).hasClass("phx-panelB4")) {
        offsetTop += SplitPanelView.prototype.SPLIT_BORDER_WIDTH;
      }
      if (offsetTop > 0) {
        $(component).height(height - offsetTop);
      }
    }, this);

    //CUSTOMER 170849
    if (objs.appRt != undefined) {
      objs.appRt.workflow.trigger("resize");
    }

  },

  fitParent: function() {
    if (this.appendTo) {
      var containerHeight = this.appendTo.outerHeight();
      var dialogHeight = this.dialog.outerHeight();

      if (dialogHeight > (containerHeight - 110)) {
        this.dialog.height(containerHeight - 110);
      }
    }
  },

  open: function(callback) {
    // If the view already exist remove it
    this._removeInternalView();

    // Render the dialog only if it not exist.
    if (this.dialog) {
      this.dialog.dialog("open");
    } else {
      this.render();
    }

    //add callback
    this.callback = callback;

    // render new view
    var args = _.extend({ parent: this }, this.viewData);
    this.internalView = new this.view(args);
    args = null;
    this.listenTo(this.internalView, "all", this._eventsBB);
    $(this.el).html(this.internalView.el);
    this.internalView.render();
  },

  _removeInternalView: function() {
    if (this.internalView) {
      this.stopListening(this.internalView);
      this.internalView.stopListening();
      this.internalView.$el.empty();
      this.internalView.remove();
      delete this.internalView.$el;
      delete this.internalView.el;
      this.internalView = null;
    }
  },

  _close: function() {
    this._removeInternalView();
    delete this.internalView;
    //call callback
    if (this.callback) {
      this.callback();
    }
  },

  close: function() {
    this.dialog.dialog("close");
  },

  isOpen: function() {
    if (STR.isBlank(this.dialog)) {
      return false;
    } else {
      return this.dialog.dialog("isOpen");
    }
  },

  _stopFocus: function() {
    //    	event.preventDefault();
    //    	event.stopPropagation();
  },

  _handleEvents: function(event, ui) {
    LOG.debug("dialog event:" + event.type);
    if (event.type === "dialogclose") {
      this.model.trigger("dlg:close", event, ui);
      this._close();
    }
    if (event.type === "dialogresize") {
      this.model.trigger("dlg:resize", event, ui);
    }
    if (event.type === "dialogdrag") {
      this.model.trigger("dlg:drag", event, ui);
    }
    if (event.type === "dialogfocus") {
      this._stopFocus(event, ui);
    }
    if (event.type === "dialogbeforeclose") {
      this.model.trigger("dlg:beforeclose", event, ui);
      this._removeInternalView();
    }

  },

  remove: function() {
    $(this.dialog).parent().off();
    //$(this.dialog).dialog("destroy");
    Backbone.View.prototype.remove.call(this);
  },

  /**
   * Set the title to the dialog
   */
  _setTitle: function(title) {
    if (this.dialog) {
      this.dialog.dialog("option", "title", title);
    } else {
      this.title = title;
    }
  },

  /**
   * Set the title to the dialog
   */
  _setPosition: function(position) {
    this.dialog.dialog("option", "position", position);
  }
});