import { ComboBoxView2 } from 'core/components/combo/comboBoxView2.js';
import { CombosColl } from 'core/components/combo/combos.collection.js';
import { FormView } from 'core/views/form.view.js';
import { i18n } from '../../../../i18n.js';
import { STR } from '../../../../utils/str.js';
import TPL_lanceetat_filter from '../filter.tpl.html';

/**
 * View for the content of the filter part
 */
export var FilterContentView = FormView.extend({

  initialize: function(options) {
    this.template = TPL_lanceetat_filter;
    if (options && options.workflow) {
      this.workflow = options.workflow;
    }
    this.model = new Backbone.Model();
  },

  events: {
    "change :input:not([readonly])": "_change"
  },

  _getModel: function() {
    return this.model;
  },

  render: function() {
    var json = { 'i18n': i18n };
    this.$el.html(this.template(json));

    this.comboThemes = new ComboBoxView2({
      ws: new CombosColl({
        name: "utiledition",
        comboId: "theme"
      }),
      name: "theme",
      optionsRender: function(value) {
        if (!value || STR.isBlank(value.code)) {
          return "";
        }
        return value.libelle + " (" + value.code + ")";
      },
      habContext: this.workflow.getHabContext()
    });
    $(this.el).find(".cboTheme").html(this.comboThemes.render().el);

    return this;
  }

});