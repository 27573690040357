import TPL_common_simple_panel from './simple_panel.tpl.html';

export var SimplePanelView = Backbone.View.extend({

  dummy: "",

  /**
   * className
   */
  className: "phx-simplePanel",
  /**
   * firstTemplate
   */
  firstTemplate: TPL_common_simple_panel,

  /**
   * Constructor
   * Simple Panel View
   */
  initialize: function() {
    this.template = this.firstTemplate;
  },

  events: {},

  /**
   * Renders this view
   */
  render: function() {
    $(this.el).html(this.template());
    $(this.el).attr("cid", this.cid);

    //Adjust height
    var requiredHeight = this._calculateCenterHeight();
    var elSplitA = $(this.el).find(".phx-splitA");
    elSplitA.height(requiredHeight);
    return this;
  },

  /**
   * Calculates center height
   */
  _calculateCenterHeight: function() {
    return ($("#phx-wrap").height() - $(".phx-header").height());
  }
});