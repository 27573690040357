module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<div class="listerprets-dialogPopupHeader">\n  <fieldset class="listerprets-dialogPopupHeader">\n    <div class="phx-formTable">\n      <div>\n        <div class="phx-listerprets-data">\n          <span class="buttons">\n					<button type="button" class="btnValider" value="btnValider">'+
((__t=(i18n.t("common:listerprets.btnValider")))==null?'':__t)+
'</button>\n					<button type="button" class="btnRevert" value="btnRevert"></button>\n				</span>\n          <br><br>\n          <label for="commentaire" title="'+
((__t=(i18n.t("common:listerprets.commentaires")))==null?'':__t)+
'">'+
((__t=(i18n.t("common:listerprets.commentaires")))==null?'':__t)+
'</label>\n          <br><br>\n          <textarea class="commentaire" rows="6" cols="40" maxlength="60" style="resize:none; margin-left:3em;"></textarea>\n          <br>\n          <span>'+
((__t=(i18n.t("common:listerprets.comment_popup1") + "<br/>" + i18n.t("common:listerprets.comment_popup2")))==null?'':__t)+
'</span> <br><br>\n          <span style="margin-top:1em;">\n					<label for="isForced">\n						<input class="isForced" type="checkbox" value="checkForcer" style="vertical-align: middle;"/>\n						'+
((__t=(i18n.t("common:listerprets.forcer")))==null?'':__t)+
'\n					</label>\n				</span>\n          <br><br>\n        </div>\n      </div>\n    </div>\n  </fieldset>\n</div>\n';
}
return __p;
};
