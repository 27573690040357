import { DelegationsUtilisateurColl } from '../models/delegationsUtilisateur.collection.js';
import { DelegationsUtilisateurModel } from '../models/delegationsUtilisateur.model.js';
import { HabilitationContext } from '../../../../../core/models/habilitationContext.js';
import { i18n } from '../../../../../i18n.js';
import { SelecteurRefDialogView } from './selecteurRefDialog.view.js';
import { SelecteurReferentielView } from '../../../../../core/components/selecteur_referentiel/selecteurReferentiel.view.js';
import { STR } from '../../../../../utils/str.js';
import TPL_referentielutilisateur_detail from '../detail.tpl.html';

export var SelectUtilisateurView = Backbone.View.extend({

  initialize: function(options) {
    this.template = TPL_referentielutilisateur_detail;

    this.host = options.host;
    if (options && options.idComponent) {
      this.idComponent = options.idComponent;
    }

    if (options && options.context) {
      // context for the habilitations
      this.context = options.context;
    }

    this.fieldWidth = options.fieldWidth; // Width of the ref input default is "100%"

    this.model = new DelegationsUtilisateurModel();

    this.habContext = new HabilitationContext({
      onglet: this.context.ctxEcran,
      fonc_cour: this.context.Habilitation
    });
    this.model.setHabContext(this.habContext);
  },

  events: {
    "change :input:not([readonly])": "_change",
    "keyup :input:not([readonly])": "_keyup"
  },

  render: function() {
    var json = { 'i18n': i18n };
    this.$el.html(this.template(json));
    var self = this;
    this.referentiel = new SelecteurReferentielView({
      coll: new DelegationsUtilisateurColl({ workflow: self.host.workflow }),
      name: this.context.name,
      itemRenderer: function(model) {
        var resp = "";
        if (_.isNull(model.code) && !_.isNull(model.libelle)) {
          resp = model.libelle;
        } else {
          if (!STR.isBlank(model.nom)) { // if have a collaborateur
            if (self.host.workflow.paramIdCollab == "matric" || STR.isBlank(self.host.workflow.paramIdCollab)) {
              resp = model.nom + " " + model.prenom + " (" + model.matricule + ")";
            } else if (self.host.workflow.paramIdCollab == "matric_aux") {
              resp = model.nom + " " + model.prenom + " (" + model.matriculeaux + ")";
            }
          } else { // if have a utilisateur
            resp = i18n.t('droitsdel.utilisateur') + " " + model.utilnom;
          }
        }
        return resp;
      },
      view: SelecteurRefDialogView,
      title: i18n.t('droitsdel.legend_selectref_criteres'),
      addItemCallback: function(data) {
        self._responseCallback(data);
      },
      fieldWidth: this.fieldWidth
    });

    if (this.context) {
      this.referentiel.coll.setHabContext(new HabilitationContext({
        onglet: this.context.ctxEcran,
        fonc_cour: this.context.Habilitation
      }));
    }
    this.referentiel._valuesFilter = function(values) {
      var self = this;
      if (this.arrExcludeIds) {
        return _.filter(values, function(value) {
          return !_.contains(self.arrExcludeIds, value.attrs.id);
        });
      }
      return values;
    };

    this.$el.find(".cmbSelectionner").html(this.referentiel.render().el);
    return this;
  },

  _responseCallback: function(data) {
    this.model.set(data);
    if (this.host && this.host._responseCallback) {
      this.host._responseCallback(data, this.idComponent);
    }
  },

  _change: function(event) {
    $(event.target).attr("title", $(event.target).val());
  },

  _keyup: function(event) {
    if ($(event.target).val().length == 0) {
      $(event.target).attr("title", "");
    }
  },

  initWithCode: function(code) {
    var self = this;
    var originalModel = new DelegationsUtilisateurModel();
    var originalCollection = new Backbone.Collection();

    originalModel.code = code;
    originalModel.fetch({
      success: function(fresh) {
        originalCollection.push(fresh);
        self.referentiel.setValues(originalCollection);
      }
    });
  },

  // array of ids to remove from result list
  filterOut: function(arrExcludeIds) {
    this.referentiel.arrExcludeIds = arrExcludeIds;
  },

  remove: function() {
    this.host = null;
    Backbone.View.prototype.remove.call(this); // Remove view from DOM
  }
});