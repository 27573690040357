module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="grid-action-bar">\n  <div class="rowOrdering phx-grid-title-bar-menu">\n    <span tabindex="0" class="placer ui-icon ui-icon-arrowthickstop-1-n phx-hover"></span>\n    <span tabindex="0" class="monter ui-icon ui-icon-arrowthick-1-n phx-hover"></span>\n    <span tabindex="0" class="descendre ui-icon ui-icon-arrowthick-1-s phx-hover"></span>\n    <span tabindex="0" class="premier ui-icon ui-icon-arrowthickstop-1-s phx-hover"></span>\n  </div>\n\n  <div class="delete-menu phx-grid-title-bar-menu phx-hover">\n    <span tabindex="0" class="delete button">'+
((__t=(grid.t("common:grid.del")))==null?'':__t)+
'</span>\n  </div>\n  <div class="new-menu phx-grid-title-bar-menu phx-hover">\n    <span tabindex="0" class="new button nwBtn">'+
((__t=(grid.t("common:grid.nw")))==null?'':__t)+
'</span>\n    <span tabindex="0" class="ui-icon ui-icon-triangle-1-s">'+
((__t=(grid.t("common:grid.menu_ajouter")))==null?'':__t)+
'</span>\n  </div>\n  <div class="new-menu-content phx-grid-title-bar-menu-content ui-menu nwBtn" style="display:none">\n    <ul>\n      <li tabindex="0" class="new button nwBtn phx-hover">\n        '+
((__t=(grid.t("common:grid.nw")))==null?'':__t)+
'\n      </li>\n      <li tabindex="0" class="copy button nwBtn phx-hover">\n        '+
((__t=(grid.t("common:grid.copy")))==null?'':__t)+
'\n      </li>\n    </ul>\n  </div>\n</div>\n';
}
return __p;
};
