import { DialogView } from '../../../../../../core/views/dialog.view.js';
import { GererBadgeagesPopUpView } from '../views/gererBadgeagesPopUp.view.js';
import { GLOBAL_DATA } from '../../../../../../globalData.js';
import { HabilitationContext } from '../../../../../../core/models/habilitationContext.js';
import { i18n } from '../../../../../../i18n.js';
import { MSGS } from '../../../../../../utils/msgs.js';
import { ReadOnlyModel } from '../../../../../../core/models/readOnly.model.js';
import { STR } from '../../../../../../utils/str.js';

export var GererbadgeagesWorkflow = ReadOnlyModel.extend({

  //REVIEW: 	i18n : phx.i18n.common.gererbadgeages,

  //REVIEW: 	i18nCom : phx.i18n.common,
  gererBadgeages: null,

  /**
   * Constructor
   * Workflow model for component gerer badgeages (various together)
   */
  initialize: function() {
    //this.btnBarModel = null;
    this.paramIdCollab = null;
    this.paramProvenanceBadgeage = null;

    this.parametreDiversProvenanceBadgeage = GLOBAL_DATA.paramDivers.get("badg_prov");
    this.parametreDiversIdCollabModel = GLOBAL_DATA.paramDivers.get("idCollab");

    /**
     * Model of the habilitation context
     */
    this.habContext = new HabilitationContext({
      onglet: "gererbadgeages",
      fonc_cour: "COL_BADG.V"
    });
  },

  /**
   * Set up the workflow. Link the models between them
   */
  setUp: function(callback) {
    this.gererBadgeages.paramIdCollab = this.parametreDiversIdCollabModel.get("valeur");
    this.gererBadgeages.paramProvenanceBadgeage = this.parametreDiversProvenanceBadgeage.get("valeur");

    if (callback) {
      callback();
    }
  },

  _confirmDelete: function() {
    var self = this;
    var objModel = this.table.model.get("value");
    var statut = objModel.get("statut").code;
    if (statut === "A" || statut === "H" ||
      (statut === "I" && this.gererBadgeages.context.ctxUtilisateur.toUpperCase() == "COLLABORATEUR")) {
      var dialog = this._initPopUp("suppression", this.gererBadgeages);
      var dialogModel = dialog.model;
      dialog.open(function() {
        if (dialogModel.get("action") == "OK") {
          self.gererBadgeages.deleteBadgeage(dialogModel.get("commentaire"));
        }
      });
      dialog._setTitle(i18n.t('common:gererbadgeages.title_popUp_confirm'));
    } else {
      MSGS.showConfirm(i18n.t('common:gererbadgeages.delconfirm'), function(result) {
        if (result == "Y") {
          self.gererBadgeages.deleteBadgeage("");
        }
      });
    }
  },

  /**
   * Custom action that pressing buttons on the buttonbar
   */
  _customBtnAction: function(buttonId, callback) {
    //Intercept the save action and ignore the others
    //this table has a button menu when each item has the struct accepter_ or refuser_

    var buttonName = buttonId.split("_")[0];
    var self = this;

    switch (buttonName) {
      case "new":
        this.workflow.gererBadgeages.newBadgeage();
        break;
      case "revert":
        this.workflow.gererBadgeages.revertBadgeage(callback);
        break;
      case "save":
        var objModel = this.table.model.get("value");
        var context = this.context;
        var error = false;

        if (!STR.isBlank(context.ctxGestionPeriode)) {
          if (context.ctxGestionPeriode.datedebut < objModel.get("saisie").date &&
            context.ctxGestionPeriode.datefin > objModel.get("saisie").date) {

            if (context.ctxGestionPeriode.heuredebut < objModel.get("saisie").heure &&
              context.ctxGestionPeriode.heurefin > objModel.get("saisie").heure) {
              error = false;
            } else {
              error = true;
            }
          } else {
            error = true;
          }
        }

        if (error) {
          var txtError = i18n.t('messages:GT_1142', {
            "1": context.ctxGestionPeriode.datedebut,
            "2": context.ctxGestionPeriode.heuredebut,
            "3": context.ctxGestionPeriode.datefin,
            "4": context.ctxGestionPeriode.heurefin
          });
          MSGS.showError(txtError);
        } else {
          if (objModel.isNew()) {
            this.workflow.gererBadgeages.saveBadgeage();
          } else {
            if (objModel.get("saisie") && (objModel.get("saisie").sens == "X" || objModel.get("saisie").sens == "x")) {
              this.workflow._confirmDelete();
            } else {
              this.workflow.gererBadgeages.modifiableBadgeage(function(fresh) {
                if (fresh.get("result") == "ok") {
                  self.workflow.gererBadgeages.saveBadgeage();
                }
              });
            }
          }
        }

        break;
      case "delete":
        this.workflow._confirmDelete();
        break;
      case "accepter":
      case "refuser":

        // customer 146291
        var role = buttonId.slice(buttonId.indexOf("_") + 1, buttonId.length);
        // customer 146291

        var dialog = this.workflow._initPopUp(buttonName, this);
        var dialogModel = dialog.model;

        // hide validate button options
        if (this.workflow.table.buttonBar.$el.find(".valider-menu-content")) {
          var validerMenu = this.workflow.table.buttonBar.$el.find(".valider-menu-content");
          if (validerMenu) {
            validerMenu.toggle();
          }
        }
        dialog.open(function() {
          if (dialogModel.get("action") == "OK") {
            if (buttonName == "accepter") {
              self.workflow.gererBadgeages.accepterBadgeage(dialogModel.get("commentaire"), role);
            } else if (buttonName == "refuser") {
              self.workflow.gererBadgeages.refuserBadgeage(dialogModel.get("commentaire"), role);
            }
          }
        });
        dialog._setTitle(i18n.t('common:gererbadgeages.title_popUp_confirm'));
        break;
      case "copy":
        this.workflow.gererBadgeages.copyBadgeage();
        break;
      default:
        this.workflow.tableModel.buttonAction(buttonId);
    }
  },

  /**
   * Initialize the popup to add commentary in 'delete', 'accepter' or 'refuser'
   */
  _initPopUp: function(act, obj) {
    var stat = this.tableModel.get("value").get("statut").code;
    var ctxUtilisateur = obj.context.ctxUtilisateur;
    var dialog = new DialogView({
      view: GererBadgeagesPopUpView,
      viewData: {
        action: act,
        statut: stat,
        utilisateur: ctxUtilisateur
      }
    });

    dialog.setHeight(220);
    dialog.setWidth(450);

    return dialog;
  }

});
