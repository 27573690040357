import { LOG } from './log.js';
import { objs } from '../objectsRepository.js';
import { STR } from './str.js';
import { TYPE } from '../tda/tda.js';

/**
 * Generic Utilities
 */
export var UTILS = {

  dummy: "",
  global_loading_counter: 0,
  usecase_loading_counter: [],
  usecase_loading_exception: [],

  _logear: function() {

    if (objs && objs.appRt && objs.appRt.workflow && objs.appRt.workflow.authModel) {
      objs.appRt.workflow.authModel.clear();
    }
    if (window.location.pathname.indexOf("login.html") == -1) {
      window.location.href = "./login/login.html";
    }
  },

  _isLoged: function() {
    if (!objs || !objs.appRt || !objs.appRt.workflow || !objs.appRt.workflow.authModel ||
      STR.isBlank(objs.appRt.workflow.authModel.get("user"))) {
      return false;
    } else {
      return true;
    }
  },

  /**
   * Check if the application should be showed in a frame or not
   */
  isFramed: function() {
    var location = window.location.pathname;
    var result = false;
    if (location.indexOf("frame.html") > -1) {
      result = true;
    }
    return result;
  },

  /**
   * Returns the language stored as a cookie
   *
   */
  getLanguage: function() {
    var l_rtn = Configuration.userlocale;

    if (objs && objs.appRt && objs.appRt.workflow && objs.appRt.workflow.configuration && objs.appRt.workflow.configuration.get("langue")) {
      l_rtn = objs.appRt.workflow.configuration.get("langue").toLowerCase();
    }
    //		var userLan = this.getCookieValue("phx-lan");
    //		if (STR.isBlank(userLan)) {
    //			userLan = "fr";
    //		}
    //		return userLan;
    return l_rtn;
  },

  /**
   * Returns a value from a cookie
   *
   */
  getCookieValue: function(key) {
    var currentcookie = document.cookie;
    var firstidx, lastidx;
    if (currentcookie.length > 0) {
      firstidx = currentcookie.indexOf(key + "=");
      if (firstidx != -1) {
        firstidx = firstidx + key.length + 1;
        lastidx = currentcookie.indexOf(";", firstidx);
        if (lastidx == -1) {
          lastidx = currentcookie.length;
        }
        return unescape(currentcookie.substring(firstidx, lastidx));
      }
    }
    return "";
  },

  /**
   * Test sequence for authorized values, format: 1,2,5:8,12,... or *
   *
   */
  checkListOfValues: function(valueMin, valueMax, text) {

    var reponse = true;
    var existMin = valueMin != null;
    var existMax = valueMax != null;
    /*
     * Regular expression for sequences separated with comma with ranges
     * (x:y). Tokens could be natural numbers, set of letters or set of
     * numbers and letters Skeleton of RegExp: /^A(:A)?(,A(:A)?)*$/ A is
     * compose of A = B|C, where B is natural numbers (([+-]?\d)+) and C is
     * set of letters or set of numbers and letters ([\dA-Za-z]+)
     */
    var RegExPattern = /^(([+-]?\d)+|[\dA-Za-z]+)(:(([+-]?\d)+|[\dA-Za-z]+))?(,(([+-]?\d)+|[\dA-Za-z]+)(:(([+-]?\d)+|[\dA-Za-z]+))?)*$/;
    // Regular expressions for ranges in tokens
    var RegExPatternRange = /^(([+-]?\d)+|[\dA-Za-z]+):(([+-]?\d)+|[\dA-Za-z]+)$/;

    if (RegExPattern.test(text)) {
      // Obtain each token from the text
      var tokens = text.split(",");

      for (var i = 0; i < tokens.length; i++) {
        // Test if the token is a range (x:y)
        if (RegExPatternRange.test(tokens[i])) {
          // Obtain each endpoint from the range
          var tokensRange = tokens[i].split(":");
          // Convert string to Int to compare
          if (!isNaN(tokensRange[0]) && !isNaN(tokensRange[1])) {
            tokensRange[0] = parseInt(tokensRange[0], 10);
            tokensRange[1] = parseInt(tokensRange[1], 10);
          }
          // Test if the range is ordered and if the endpoints are
          // between min & max
          if (tokensRange[0] > tokensRange[1] || existMin && tokensRange[0] < valueMin || existMax &&
            tokensRange[1] > valueMax) {

            reponse = false;
          }
          // Simple token (not a range)
        } else {
          // Test if the token is between min & max
          if ((existMin && tokens[i] < valueMin) || (existMax && tokens[i] > valueMax)) {
            reponse = false;
          }
        }
      }
      // text could be '*' also, that means everyday, every month, ...
    } else if (text == "*") {
      reponse = true;
    } else {
      reponse = false;
    }

    return reponse;
  },

  /**
   * Add a number of days to the current date
   */
  addDays: function(date, numDays) {
    date.setDate(date.getDate() + numDays);
    return date;
  },

  /**
   * Add a number of days to the current date in BBDD format
   */
  addDaysString: function(date, numDays) {
    var dateAux = TYPE.DATE.strToDate(date);
    UTILS.addDays(dateAux, numDays);
    var newDate = TYPE.DATE.dateToStr(dateAux, TYPE.DATE.DEFAULT_MASK_BBDD);
    return newDate;
  },

  //	showHidder : function(){
  //		if(	UTILS.global_loading_counter <  0){
  //			UTILS.global_loading_counter = 0;
  //		}
  //		var showHidder = true;
  //		var usecase = "";
  //		// check if usecase allow hidder to be visible (specially in usecase where a progression bar exist and use can stop activity)
  ////		if(objs && objs.appRt.workflow){
  ////			usecase = objs.appRt.workflow.get("usecase");
  ////			if(objs[usecase+"Rt"]){
  ////				showHidder = (objs[usecase+"Rt"].showHidder === false ? false:true);
  ////			}
  ////		}
  //
  //		if(	UTILS.global_loading_counter == 0 && objs && showHidder) {
  //			usecase = objs.appRt.workflow.get("usecase");
  //			var hidder = $("<div class='phx-uc-hidder-left phx-icon-hidder'></div>");
  //			var $usecase = $("#"+usecase);
  //			if($usecase && $usecase.offset()){
  //				var topOffset = $usecase.offset().top - $("#phx-zone-"+objs.appRt.workflow.get("zone")).offset().top;
  //				var height = $usecase.height() == 0 ?  $usecase.height()+"px" : "100%";
  //				hidder.css({
  //					height : height,
  //					top : topOffset + "px"
  //				});
  //				$usecase.append(hidder);
  //			}
  //		}
  //		UTILS.global_loading_counter++;
  //		LOG.debug("--> showHidder ("+usecase+"): "+UTILS.global_loading_counter);
  //	},

  disableKeys: function(event) {
    event.preventDefault();
    return false;
  },

  /**
   * Puts the wait icon in usecase
   */
  showUsecaseHidder: function(usecase) {
    var targetUc = "",
      newId = "";
    var l_exist = false;

    try {
      newId = Backbone.history.getFragment().split("/")[3];
      targetUc = newId.split("_")[0];
    } catch (e) {
      // Do nothing
    }
    if (usecase == targetUc && usecase != newId) {
      usecase = newId;
    }
    var $usecase = $("#" + usecase);

    if ($usecase.length == 0) {
      if (!_.isString(usecase) || (_.isString(usecase) && usecase.length == 0)) {
        LOG.error("Error usecase is not defined");
      } else {
        $usecase = $(".phx-Dialog div." + usecase);
      }
    }

    if ($usecase.length == 0) {
      if (!_.isString(usecase) || (_.isString(usecase) && usecase.length == 0)) {
        LOG.error("Error usecase is not defined");
      } else {
        $usecase = $("div." + usecase);
      }
    }
    if ($usecase.length !== 0) {

      UTILS.usecase_loading_counter[usecase] = UTILS.usecase_loading_counter[usecase] || 0;

      var showHidder = (UTILS.usecase_loading_exception[usecase] !== false);

      if (UTILS.usecase_loading_counter[usecase] < 0) {
        UTILS.usecase_loading_counter[usecase] = 0;
      }
      l_exist = ($usecase.find(".phx-uc-hidder-left.phx-icon-hidder") && $usecase.find(".phx-uc-hidder-left.phx-icon-hidder").length > 0) ? true : false;
      if (!STR.isBlank(objs) && showHidder && (UTILS.usecase_loading_counter[usecase] == 0 || !l_exist)) {
        var hidder = $("<div class='phx-uc-hidder-left phx-icon-hidder'></div>");
        if ($usecase && $usecase.offset()) {
          $usecase.find("table").keydown(this.disableKeys);

          var topOffset = $usecase.offset().top;
          if (!STR.isBlank(objs.appRt) && !STR.isBlank(objs.appRt.workflow) && !STR.isBlank(objs.appRt.workflow.get("zone"))) {
            topOffset = $usecase.offset().top - $("#phx-zone-" + objs.appRt.workflow.get("zone")).offset().top;
            if (topOffset < 0) {
              topOffset = 0;
            }
          }

          //On dialogs dont calculate the offset block all the dialog. Idem on briques.
          if ($("#" + usecase).hasClass("phx-portlet") || ($usecase.parents(".phx-Dialog") && $usecase.parents(".phx-Dialog").length > 0)) {
            topOffset = 0;
          }
          var height = $usecase.height() == 0 ? $usecase.height() + "px" : "100%";
          hidder.css({
            height: height,
            top: topOffset + "px"
          });
          $usecase.append(hidder);
        }
      }
      if (!STR.isBlank(objs) && showHidder && $usecase && $usecase.offset()) {
        UTILS.usecase_loading_counter[usecase]++;
      }

      LOG.debug("--> showUsecaseHidder (" + usecase + ") : " + UTILS.usecase_loading_counter[usecase]);
    }
  },

  /**
   * Transform text to a valid string JQuery
   */
  escapeJQueryString: function(expression) {
    var l_expression = expression;
    l_expression = l_expression.replace(/ /g, '_');
    l_expression = l_expression.replace(/[@]/g, '-1-');
    l_expression = l_expression.replace(/["]/g, '-2-');
    l_expression = l_expression.replace(/[#]/g, '-3-');
    l_expression = l_expression.replace(/[$]/g, '-4-');
    l_expression = l_expression.replace(/[%]/g, '-5-');
    l_expression = l_expression.replace(/[&]/g, '-6-');
    l_expression = l_expression.replace(/[']/g, '-7-');
    l_expression = l_expression.replace(/[(]/g, '-8-');
    l_expression = l_expression.replace(/[)]/g, '-9-');
    l_expression = l_expression.replace(/[*]/g, '-10-');
    l_expression = l_expression.replace(/[+]/g, '-11-');
    l_expression = l_expression.replace(/[,]/g, '-12-');
    l_expression = l_expression.replace(/[.]/g, '-13-');
    l_expression = l_expression.replace(/[\/]/g, '-14-');
    l_expression = l_expression.replace(/[:]/g, '-15-');
    l_expression = l_expression.replace(/[;]/g, '-16-');
    l_expression = l_expression.replace(/[<]/g, '-17-');
    l_expression = l_expression.replace(/[=]/g, '-18-');
    l_expression = l_expression.replace(/[>]/g, '-19-');
    l_expression = l_expression.replace(/[?]/g, '-20-');
    l_expression = l_expression.replace(/[!]/g, '-21-');
    l_expression = l_expression.replace(/[\[]/g, '-22-');
    l_expression = l_expression.replace(/[\\]/g, '-23-');
    l_expression = l_expression.replace(/[\]]/g, '-24-');
    l_expression = l_expression.replace(/[\^]/g, '-25-');
    l_expression = l_expression.replace(/[`]/g, '-26-');
    l_expression = l_expression.replace(/[{]/g, '-27-');
    l_expression = l_expression.replace(/[|]/g, '-28-');
    l_expression = l_expression.replace(/[}]/g, '-29-');
    l_expression = l_expression.replace(/[~]/g, '-30-');

    return l_expression;
  },

  /**
   * Escape special chars in selector to form a valid JQuery selector.
   * <p>Escape chars:<p>
   */
  addScapeSlashesForJQuerySelector: function(selector) {
    return selector.replace(/( |@|"|#|\$|%|&|'|\(|\)|\*|\+|,|\.|\/|:|;|<|=|>|\?|!|\[|\\|\]|\^|`|{|\||}|~)/g, "\\$1");
  },

  /**
   * Remove the wait icon in usecase
   */
  hideUsecaseHidder: function(usecase) {
    var targetUc = "",
      newId = "";

    try {
      newId = Backbone.history.getFragment().split("/")[3];
      targetUc = newId.split("_")[0];
    } catch (e) {
      // Do nothing
    }
    if (usecase == targetUc && usecase != newId) {
      usecase = newId;
    }
    if (UTILS.usecase_loading_counter[usecase] !== undefined && UTILS.usecase_loading_counter[usecase] !== "") {
      UTILS.usecase_loading_counter[usecase]--;

      LOG.debug("--> hideUsecaseHidder (" + usecase + ") : " + UTILS.usecase_loading_counter[usecase]);
      if (UTILS.usecase_loading_counter[usecase] <= 0) {
        $("#" + usecase).find("table").unbind("keydown", this.disableKeys);
        $("." + usecase).find("table").unbind("keydown", this.disableKeys);

        $("#" + usecase).find(".phx-uc-hidder-left").remove();
        $("." + usecase).find(".phx-uc-hidder-left").remove();
        UTILS.usecase_loading_counter[usecase] = 0;
      }
    }
  },

  /**
   * Discount token number called webservice
   */
  removeHidder: function() {
    UTILS.global_loading_counter--;
    LOG.debug("--> removeHidder : " + UTILS.global_loading_counter);
    if (UTILS.global_loading_counter <= 0) {
      $(document.body).find(".phx-uc-hidder-left").remove();
      UTILS.global_loading_counter = 0;
    }
  },

  /**
   * Set token number called webservice to 0
   */
  resetHidder: function() {
    UTILS.global_loading_counter = 0;
    UTILS.usecase_loading_counter = [];
    LOG.debug("--> resetHidder : " + UTILS.global_loading_counter);
    $(document.body).find(".phx-uc-hidder-left").remove();

    //CUSTOMER 170849
    if (objs && objs.appRt) {
      $("#" + objs.appRt.workflow.get("usecase")).find("table").unbind("keydown", this.disableKeys);
    }
  },

  /**
   * Clone the one array in other array
   */
  cloneArray: function(obj) {
    if (Object.prototype.toString.call(obj) === '[object Array]') {
      var out = [],
        i = 0,
        len = obj.length;
      for (; i < len; i++) {
        out[i] = UTILS.cloneArray(obj[i]);
      }
      return out;
    }
    if (typeof obj === 'object') {
      var out = {},
        i;
      for (i in obj) {
        out[i] = UTILS.cloneArray(obj[i]);
      }
      return out;
    }
    return obj;
  },
  /**
   * Return the number of weeks of the month for the year and month passed
   */
  getNumWeeksForMonth: function(month, year) {
    var firstOfMonth = new Date(year, month - 1, 1);
    var days = UTILS.daysInMonth(month - 1, year);
    var weeks = 0;
    if (firstOfMonth.getDay() !== 1) {
      weeks++;
    }
    for (var i = 1; i <= days; i++) {
      var loopDate = new Date(year, month - 1, i);
      var newWeek = loopDate.getDay();
      if (newWeek === 1) {
        weeks++;
      }
    }
    return weeks;
  },
  /**
   * Return the number of week in a Date passed
   */
  getWeekNumOfDate: function(d) {
    var year = d.getFullYear();
    var month = d.getMonth();
    var firstOfMonth = new Date(year, month, 1);
    var week = 0;
    if (firstOfMonth.getDay() !== 1) {
      week++;
    }
    for (var i = 1; i <= d.getDate(); i++) {
      var loopDate = new Date(year, month, i);
      var newWeek = loopDate.getDay();
      if (newWeek === 1) {
        week++;
      }
    }
    return week;
  },
  /**
   * Return the numbers of days of the month for the year and month passed
   */
  daysInMonth: function(month, year) {
    return new Date(year, month + 1, 0).getDate();
  },

  /**
   * Delete the object in memory with the close a usecase
   */
  backboneObjectRecollector: function(obj) {
    this._recursiveRecollector(obj);
  },

  /**
   * Delete the object in memory with the close a usecase (In recursive)
   */
  _recursiveRecollector: function(obj) {
    _.each(obj, function(value, key, parent) {
      if (this._isRecollectableValue(value, key)) {
        if (this._isBackboneObject(parent[key])) {
          parent[key] = undefined;
        }
        this._resetBackboneObject(value);
      }
    }, this);
  },

  _isBackboneObject: function(value) {
    var isBackb = false;
    if (value instanceof Backbone.Model) {
      isBackb = true;
    } else if (value instanceof Backbone.Collection) {
      isBackb = true;
    } else if (value instanceof Backbone.View) {
      isBackb = true;
    }
    return isBackb;
  },

  /**
   * Decides if an object should be recollected
   */
  _isRecollectableValue: function(value, key) {
    var recollectable = false;
    if ((_.isObject(value) || _.isArray(value)) && value.recollected != true) {
      if (!_.contains(this.backboneProperties, key)) {
        recollectable = true;
      }
    }
    return recollectable;
  },

  backboneProperties: ["_events", "_listeners", "_listeningTo", "_byId", "$el", "el", "i18n", "i18nCom"],

  /**
   * Reset a objects of BackBone (Backbone.Model, Backbone.Collection, Backbone.View, Array...)
   */
  _resetBackboneObject: function(value) {
    if (value && value.recollected != true) {
      if (value instanceof Backbone.Model) {
        // added to don't treat the same obj again
        value.recollected = true;
        value.off();
        value.stopListening();
        if (value.clear) {
          value.clear({ silent: true });
        }
        this._recursiveRecollector(value);
      } else if (value instanceof Backbone.Collection) {
        // added to don't treat the same obj again
        value.recollected = true;
        value.off();
        value.stopListening();
        this._recursiveRecollector(value);
      } else if (value instanceof Backbone.View) {
        // added to don't treat the same obj again
        value.recollected = true;
        value.remove();
        this._recursiveRecollector(value);
      } else if (_.isArray(value)) {
        for (var i in value) {
          var obj = value[i];
          value[i] = null;
          this._resetBackboneObject(obj);
        }
      } else if (_.isObject(value)) {
        this._recursiveRecollector(value);
      }
    }
  },

  /**
   *
   */
  stringToBoolean: function(original) {
    var stringValue = String(original).toLowerCase();
    if (stringValue === "true") {
      return true;
    } else if (stringValue === "false") {
      return false;
    } else {
      return NaN;
    }
  },

  /**
   * Clones a object
   */
  cloneObj: function(obj) {
    var jsonObj = JSON.stringify(obj);
    //var newObj=mObj=JSON.parse(jsonObj);
    var newObj = JSON.parse(jsonObj);
    return newObj;
  },

  /**
   * getTextWidth
   */
  getTextWidth: function(width) {
    if (width && !_.isNumber(width) && width.indexOf("ch") > 0 && UTILS.isIE()) {
      var number = width.replace("ch", "");
      number *= 1.21;
      return number + "ch";
    }

    return width;
  },

  /**
   * getTextWidth
   */
  isIE: function() {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf('MSIE ') > 0;
    var ie11 = ua.indexOf('Trident/') > 0;
    var ie12 = ua.indexOf('Edge/') > 0;
    return msie || ie11 || ie12;
  },

  isFirefox: function() {
    var ua = window.navigator.userAgent;
    return ua.indexOf('Firefox/') > 0
  },

  isInteger: function(value) {
    return typeof value === "number" &&
      isFinite(value) &&
      Math.floor(value) === value;
  },

  /**
   * Performs a binary search on the host array. This method can either be
   * injected into Array.prototype or called with a specified scope like this:
   * binaryIndexOf.call(someArray, searchElement);
   *
   */
  binaryIndexOf: function(someArray, searchElement, f_comparator) {
    if (_.isArray(someArray) && !STR.isBlank(searchElement)) {
      var minIndex = 0;
      var maxIndex = someArray.length - 1;
      var currentIndex = null;
      var currentElement = null;
      var previousElement = null;
      var l_rtn = null;

      var l_func_aux = function(currentElement, searchElement) {
        if (currentElement < searchElement) {
          return -1;
        } else if (currentElement > searchElement) {
          return 1
        } else {
          return 0;
        }
      };

      while (minIndex <= maxIndex) {
        currentIndex = (minIndex + maxIndex) / 2 | 0;

        currentElement = someArray[currentIndex];
        if (currentIndex > 0) {
          previousElement = someArray[currentIndex - 1];
        } else {
          previousElement = null;
        }
        l_rtn = null;
        if (f_comparator) {
          l_rtn = f_comparator(currentElement, searchElement, previousElement);
        } else {
          l_rtn = l_func_aux(currentElement, searchElement, previousElement)
        }
        if (l_rtn === -1) {
          minIndex = currentIndex + 1;
        } else if (l_rtn === 1) {
          maxIndex = currentIndex - 1;
        } else {
          return currentIndex;
        }
      }
    }
    return -1;
  }
};