import { DialogView } from 'core/views/dialog.view.js';
import { GLOBAL_DATA } from '../../../../globalData.js';
import { HABILITATION } from '../../../../utils/habilitation.js';
import { STR } from '../../../../utils/str.js';
import { IntermediateSituationView } from './intermediateSituation.view.js';
import { objs } from '../../../../objectsRepository.js';

export var GmSituationView = Backbone.View.extend({
  className: "gmsituation",

  open: function(paramCollabId) {
    var self = this;
    var valoHab = "COL_SITUVAL.V";

    this.theme = _.clone(GLOBAL_DATA.theme);

    if (this.module && this.module === "agenda_R") {
      valoHab = "RES_RESCPT.V";
    }
    if (HABILITATION.canView(valoHab) === true) {
      // load dependencies to component
      var collab = (paramCollabId) ? paramCollabId : objs.appRt.workflow.authModel.get("matricule");

      // set context
      var context = {};
      context.resultatsCollab = collab;
      context.resultatsGroupe = 33;
      context.resultatsDate = null;
      context.resultatsPRDde = GLOBAL_DATA.paramDivers.get("demabs_sit");
      context.resultatsPRSht = GLOBAL_DATA.paramDivers.get("SouhAbs");
      context.resultatsMode = "collaborateur";
      context.resultatsAuto = GLOBAL_DATA.paramDivers.get("affCpt_sit");
      context.resultatsTitre = false;
      /*Customer 200953*/
      context.ctxHabilitation = _.isEqual(valoHab, "RES_RESCPT.V") ?
        "RES_RESCPT.V" : "COL_SITUVAL.V"
      context.ctxEcran = "gmsituation";
      context.ctxModule = self.module;
      context.ctxUser = self.objuser;

      if (context.resultatsAuto.get("valeur") === "1") {
        context.resultatsAuto = true;
      } else {
        context.resultatsAuto = false;
      }
      if (context.resultatsPRSht.get("valeur") === "1") {
        context.resultatsPRSht = true;
      } else {
        context.resultatsPRSht = false;
      }
      if (context.resultatsPRDde.get("valeur") === "1") {
        context.resultatsPRDde = true;
      } else {
        context.resultatsPRDde = false;
      }

      if (paramCollabId) {
        context.resultatsPRDde = context.resultatsPRSht = context.resultatsDate = null;
      }

      self.dialogView = new DialogView({
        view: IntermediateSituationView,
        viewData: {
          context: context,
          modal: false
        }
      });

      //self.dialogView.

      // RGAA development
      if(this.theme.get("preferences") && !STR.isBlank(this.theme.get("preferences")[0]) && this.theme.get("preferences")[0].varchar1 === 'contrast.theme'){
        self.dialogView.setWidth(450);
      } else {
        self.dialogView.setWidth(350);
      }
      self.dialogView.setHeight(150);

      // open popup
      self.dialogView.open();
    }
  },

  close: function() {
    if (this.dialogView) {
      this.dialogView.close();
      this.dialogView.remove();
    }
    this.$el.remove();
  },

  setContext: function(context) {
    // set context
    if (this.dialogView && this.dialogView.internalView !== null && this.dialogView.internalView !== undefined) {
      this.dialogView.internalView.setContext(context);
    }

    if (context && context.module) {
      this.module = context.module;
    }

    if (context && context.userobj) {
      this.objuser = context.userobj;
    }
  }
});
