import { BaseModel } from '../../core/models/base.model';

import { STR } from '../../utils/str.js'

export var respDesigModel = BaseModel.extend({

  habilitationV: "COL_SPE_MRESP.V",
  habilitationG: "N",
  usecase: "app",

  defaults: {
    "existValideur": null
  },

  url: function() {

    return Configuration.restRoot + "/rest/ficheperso/existevalideurjour"

  },

});
