import { BaseModel } from './base.model.js';


/**
 * Model for Combos
 */
export var DesactivateMessageModel = BaseModel.extend({
  url: function() {
    var url = Configuration.restRoot + "/rest/utilitaire/messages/famille/" + encodeURIComponent(this.famille) + "/code/" + encodeURIComponent(this.numero) + "/desactive";
    return url;
  }
});