module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<fieldset class="droitsdel-detail">\n  <fieldset class="droitsdel-detail-panel">\n    <div class="phx-formTable" style="min-width : 700px">\n      <div class="phx-formErrors ui-state-error"></div>\n      <div class="phx-column1 colum-pos">\n        <label for="datedeb" class="required">'+
((__t=(i18n.t("droitsdel.datedeb")))==null?'':__t)+
'</label>\n        <input type="text" class="datedeb typeDate periodStart" periodId="1" value="" size="19" />\n        <span class="datedeb-error-container"></span>\n        <br/>\n        <label for="datefin" class="required">'+
((__t=(i18n.t("droitsdel.datefin")))==null?'':__t)+
'</label>\n        <input type="text" class="datefin typeDate periodEnd infinityDate" periodId="1" value="" size="19" />\n        <span class="datefin-error-container"></span>\n        <br/>\n        <label for="retro" class="droitsdel-label-lines">'+
((__t=(i18n.t("droitsdel.retrocession")))==null?'':__t)+
'</label>\n        <input type="checkbox" class="retro" value="true" title="'+
((__t=(i18n.t("droitsdel.retrocession_title")))==null?'':__t)+
'" />\n        <br />\n        <span class="phrase-info">'+
((__t=(i18n.t("droitsdel.phrase_info")))==null?'':__t)+
'</span>\n      </div>\n      <div class="phx-column2 colum-pos">\n        <label for="delegue.matricule" class="droitsdel-label-lines required">'+
((__t=(i18n.t("droitsdel.droitsDelegues")))==null?'':__t)+
'</label>\n        <span class="droisDeleguesA"></span>\n        <span class="delegue.matricule-error-container"></span>\n        <label for="commentaire">'+
((__t=(i18n.t("droitsdel.commentaire")))==null?'':__t)+
'</label>\n        <textarea class="commentaire" maxlength="200" cols="" rows="4" style="width: 335px;"></textarea>\n        <br/>\n      </div>\n    </div>\n  </fieldset>\n</fieldset>\n<br />\n<div class="table_droits"></div>\n';
}
return __p;
};
