module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<span id="phx-menus-transversal-right" class="phx-menus-zone">\n	<span tabindex="0" class="phx-menus-transversal-refresh">'+
((__t=(i18n.t('common:refresh')))==null?'':__t)+
'</span>\n<span tabindex="0" class="phx-menus-transversal-calend">'+
((__t=(i18n.t('common:calendar')))==null?'':__t)+
'</span>\n<span tabindex="0" class="phx-menus-transversal-print">'+
((__t=(i18n.t('common:print')))==null?'':__t)+
'</span>\n<span tabindex="0" class="phx-menus-transversal-help">'+
((__t=(i18n.t('common:help')))==null?'':__t)+
'</span>\n</span>\n';
}
return __p;
};
