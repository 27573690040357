import { AuthModel } from './auth.model.js';
import { ConfigModel } from './config.model.js';
import { GLOBAL_DATA } from '../../globalData.js';
import { GroupeMenuColl } from './groupeMenu.collection.js';
import { i18n } from '../../i18n.js';
import { LOG } from '../../utils/log.js';
import { LoginLicenseModel } from 'core/components/login/loginLicense.model.js';
import { objs } from '../../objectsRepository.js';
import { ParametresDiversColl } from './parametresDivers.collection.js';
import { ProfilesModel } from 'common/profilutilisateur/models/profiles.model.js';
import { ReadOnlyModel } from 'core/models/readOnly.model.js';
import { SESSION } from '../../utils/session.js';
import { STR } from '../../utils/str.js';
import { SubRoute } from 'core/routers/subRoute.js';
import { SYNC } from '../../utils/sync.js';
import { ThemeRollConfigModel } from './themeRollConfig.model.js';
import { TransversalSyncModel } from './transversalSync.model.js';
import { TypoEvtGenColl } from './typoEvtGen.collection.js';
import { TypoStandardColl } from './typoStandard.collection.js';
import { UTILS } from '../../utils/utils.js';
import { Observable } from 'rxjs/Observable';
import { HABILITATION } from '../../utils/habilitation.js';
import { forkJoin } from 'rxjs';
import { ContexteActifModel } from './contexteActif.model.js';
import { TypeStructureModel } from '../../uc/common/briques/bmonresp/models/typeStructure.model';
import { respDesigModel } from '../models/respDesig.model';
import { TYPE } from '../../tda/tda.js';

export var WorkFlowModel = ReadOnlyModel.extend({

  defaults: {
    ready: false,
    fullscreen: false,
    zone: "",
    usecase: "",
    operation: "",
    uri: ""
  },

  /**
   * Constructor
   * Workflow model for App
   * Links to: authModel, navModel
   * Attributes: zone, usecase, operation, uri,
   * 			   tabs-[zoneId] = number of open tabs, "lastuc-[zoneId]" last uc open in zone
   * Events: user:logout, change:zone, change:usecase, change:operation (operation is categories/[list] / [8] ... )
   *         add:tab, remove:tab
   */
  initialize: function() {
    this.ZONES = {};
    this.BRIQUES = {};
    this.TRANSVERSES = {};
    this.paramEcranDef = null;
    /**
     * Contains a model with the authentication information
     */
    this.authModel = new AuthModel({ id: "session" });
    this.configuration = new ConfigModel();
    GLOBAL_DATA.paramDivers = new ParametresDiversColl();
    GLOBAL_DATA.typologies = new TypoStandardColl();
    GLOBAL_DATA.typoevtgen = new TypoEvtGenColl();
    GLOBAL_DATA.licences = {};
    GLOBAL_DATA.licences = new LoginLicenseModel();
    GLOBAL_DATA.theme = {};
    GLOBAL_DATA.theme = new ThemeRollConfigModel();
    GLOBAL_DATA.profiles = new ProfilesModel();
    GLOBAL_DATA.groupemenu = new GroupeMenuColl();
    GLOBAL_DATA.respDesig = new respDesigModel();
    this.syncModel = new TransversalSyncModel();
    this.set("ready", false);
  },

  /**
   * Set up the workflow. Link the models between them
   */
  setUp: function(callback) {

    // declare event consumers
    this.on("user:prelogout", this._prelogout, this);
    this.on("user:logout", this._logout, this);
    this.on("change:usecase", this._usecaseChange, this);
    this.on("add:tab", this._addTab, this);
    this.on("remove:tab", this._removeTab, this);
    this.on("themeConfiguration", this._themeConfiguration, this);

    // register sync events
    this._registerSyncEvents();

    var self = this;
    // Data of the connected user
    this.authModel.fetch({
      success: function(model) {

        self._themeConfiguration();

        // Launch time synchronization
        SYNC.calculateDelay();

        var userid = model.get("login");
        LOG.debug("User logged is : " + userid);

        self.configuration.fetch({
          success: function() {

            GLOBAL_DATA.types = self.configuration.get("typeapplicatif");
            GLOBAL_DATA.rights = self.configuration.get("droit");

            var calls = [];
            var language = self.configuration.get("langue").toLowerCase();

            if (STR.isBlank(language)) {
              language = "fr";
            }

            var profilesDefer = null;
            var languageDefer = $.Deferred();
            calls.push(languageDefer);
            i18n.changeLanguage(language, function(err) {
              if (err) {
                languageDefer.reject();
              } else {
                languageDefer.resolve();
              }

            });
            //
            //Pas utiliser ici ou en ce point HABILITATION.canView-> il n'y a pas de donnés encore
            if (self.canViewBefore("UTI_SELPROFCOUR") && self.canViewBefore("UTI_SELPROFHIER")) {
              profilesDefer = GLOBAL_DATA.profiles.fetch({
                error: function() {
                  self._logout();
                }
              });
              calls.push(profilesDefer);
            }

            var paramRespDesig = GLOBAL_DATA.respDesig.fetch();
            calls.push(paramRespDesig);
            var paramDiversDefer = GLOBAL_DATA.paramDivers.fetch();
            calls.push(paramDiversDefer);

            var typologiesDefer = GLOBAL_DATA.typologies.fetch();
            calls.push(typologiesDefer);

            var licencesDefer = GLOBAL_DATA.licences.fetch();
            calls.push(licencesDefer);

            var groupemenuDefer = GLOBAL_DATA.groupemenu.fetch();
            calls.push(groupemenuDefer);

            //Contexte actif des évènements
            GLOBAL_DATA.contextActif = [];
            //Absences et souhaits
            GLOBAL_DATA.contextActif.contextActifAbs = new ContexteActifModel({}, { "contexteActif": "INDORI", "typeEvt": "ABSENCE", "typologie": "null" });
            calls.push(GLOBAL_DATA.contextActif.contextActifAbs.fetch());
            //activités prevues
            GLOBAL_DATA.contextActif.contextActifActPre = new ContexteActifModel({}, { "contexteActif": "INDORI", "typeEvt": "ACTPREV", "typologie": "null" });
            calls.push(GLOBAL_DATA.contextActif.contextActifActPre.fetch());
            //Activités realisées
            GLOBAL_DATA.contextActif.contextActifActRea = new ContexteActifModel({}, { "contexteActif": "INDORI", "typeEvt": "ACTREAL", "typologie": "null" });
            calls.push(GLOBAL_DATA.contextActif.contextActifActRea.fetch());
            //Horaire excepcionnel
            GLOBAL_DATA.contextActif.contextActifHorExcep = new ContexteActifModel({}, { "contexteActif": "INDORI", "typeEvt": "HOREXCEP", "typologie": "null" });
            calls.push(GLOBAL_DATA.contextActif.contextActifHorExcep.fetch());
            //Pret
            GLOBAL_DATA.contextActif.contextActifPret = new ContexteActifModel({}, { "contexteActif": "INDORI", "typeEvt": "PRET", "typologie": "null" });
            calls.push(GLOBAL_DATA.contextActif.contextActifPret.fetch());
            //Regularisation
            GLOBAL_DATA.contextActif.contextActifReg = new ContexteActifModel({}, { "contexteActif": "INDORI", "typeEvt": "REGULARISATION", "typologie": "null" });
            calls.push(GLOBAL_DATA.contextActif.contextActifReg.fetch());
            GLOBAL_DATA.contextActif.length = _.keys(GLOBAL_DATA.contextActif).length;

            var sub = forkJoin(calls);
            sub.subscribe(function() {
                self._menuConfiguration();
                //les événement génériques ont besoin de savoir les "rights". Par eux, ils doivent aller après de _menuConfiguration
                self._loadEvenementsGeneriques(callback);
              },
              function() {
                self._logout();
              });
          },

          error: function() {
            self._logout();
          }
        });
      }
    });

    // Screen model is Ready
    this.set("ready", true);
  },

  /**
   * Set up the recorded themplate (themeroll) EVO 569
   */
  _themeConfiguration: function() {
    var model = GLOBAL_DATA.theme;
    model.fetch({
      success: function(model) {
        var theme = model.get("preferences")[0].varchar1;

        if (!Configuration.themes || Configuration.themes.indexOf(theme) < 0) {
          theme = "gfi.theme";
        }
        var element = document.createElement("link");
        element.href = "css/" + theme + ".css?_=" + _.random(1, 1000000000000000);
        element.id = "ui-theme";
        element.rel = "stylesheet";
        document.head.appendChild(element);

      }
    });
  },

  _menuConfiguration: function() {
    var self = this;

    // gestion transverses
    var numTransversalMenus = self.configuration.get("confignav").get("transverses").length;
    LOG.debug("Number of transversal menus for the user : " + numTransversalMenus);

    self.TRANSVERSES["user"] = [];

    var transverses = self.configuration.get("confignav").get("transverses");
    _.each(transverses, function(transverse) {
      if (transverse["id"] === "ppmpd") {
        self.TRANSVERSES["user"].push(transverse["id"]);
      }

      // ....
    });

    self.RESP_POPULATION = {};

    // gestion zones
    var numZones = self.configuration.get("confignav").get("zones").length;
    LOG.debug("Number of zones for the user : " + numZones);
    if (_.isEmpty(self.ZONES)) {
      var zones = self.configuration.get("confignav").get("zones");

      // To manage the numbers of tabs
      self.set("tabstotal", 0);
      _.each(zones, function(zone) {
        self.ZONES[zone["id"]] = [];
        self.BRIQUES[zone["id"]] = [];
        self.set("tabs-" + zone["id"], 0);
        _.each(zone.menus, function(menu) {
          if (menu.id) {
            self.ZONES[zone["id"]].push(menu["id"]);
          }
          if (menu.emid === "EM_00026") {
            self.RESP_POPULATION.showMenu = true;
          }
          _.each(menu.ecrans, function(uc) {
            if (uc.emid === "EM_00027") {
              self.RESP_POPULATION.showGerer = true;
            }
            _.each(uc.ecrans, function(uc2) {
              self.ZONES[zone["id"]].push(uc2["id"]);
            });
            if (uc["id"]) {
              self.ZONES[zone["id"]].push(uc["id"]);
            }
          });
        });
        // briques
        _.each(zone.briques, function(brique) {
          if (brique["id"]) {
            self.BRIQUES[zone["id"]].push(brique);

          }
        });

      });
    }
    self.MODULES_ZONES = [];
    _.each(_.keys(self.ZONES), function(a_zone) {
      _.each(self.ZONES[a_zone], function(a_module) {
        if (STR.isBlank(self.MODULES_ZONES[a_module])) {
          self.MODULES_ZONES[a_module] = [];
        }
        self.MODULES_ZONES[a_module].push(a_zone);
      });
    });

    if (_.contains(_.keys(self.ZONES), "resp")) {
      if (!self.MODULES_ZONES["agenda_R"]) {
        self.MODULES_ZONES["agenda_R"] = [];
      }
      self.MODULES_ZONES["agenda_R"].push("resp");
    }

    self.paramEcranDef = GLOBAL_DATA.paramDivers.get("ecranDef");
    // pers_numer
    self.paramPers_numer = GLOBAL_DATA.paramDivers.get("pers_numer");
  },

  _reloadBriquesInfo: function(callback, usecase) {
    var self = this;
    var configurationModel = new ConfigModel();
    configurationModel.usecase = usecase;
    UTILS.showUsecaseHidder(usecase);
    configurationModel.fetch({
      success: function(freshConfiguration) {
        UTILS.hideUsecaseHidder(usecase);
        var zones = freshConfiguration.get("confignav").get("zones");
        self.set("tabstotal", 0);
        _.each(zones, function(zone) {
          self.BRIQUES[zone["id"]] = [];
          // briques
          _.each(zone.briques, function(brique) {
            if (brique["id"]) {
              self.BRIQUES[zone["id"]].push(brique);

            }
          });

        });
        callback();
      }
    });
  },

  isGoingToZoneHome: function() {
    // Check if there are only one in the uri.
    var isGoing = false;

    var match = this.get("uri").match(/\//g);
    if (match !== null) {
      isGoing = match.length === 1;
    }

    return isGoing;
  },

  // register listener to syncEvents type
  _registerSyncEvents: function() {
    this.pendingPopulationRefresh = false;

    this.listenTo(objs.appRt.workflow, "change:uri", this._managePendingSyncEvents, this);
    this.listenTo(objs.appRt.workflow.syncModel, "change:population", this._manageChangePopulationEvent, this);
  },

  // execute pending syncEvents
  _managePendingSyncEvents: function() {
    if (this.pendingPopulationRefresh === true) {
      this._manageChangePopulationEvent();
    }
  },

  // syncEvent : populations
  _manageChangePopulationEvent: function() {
    if (objs.appRt.workflow.get("zone") === "resp" && objs.appRt.workflow.get("usecase") === "") {
      this._changePopulation();
    } else {
      this.pendingPopulationRefresh = true;
    }
  },

  _changePopulation: function() {
    this.trigger("refresh:Briques");
    this.pendingPopulationRefresh = false;
  },

  /**
   * Sets the uri when the zone changes
   */
  _zoneChange: function() {
    var zoneId = this.get("zone");
    if (zoneId === "") {
      this.set("uri", "");
    } else {
      this.set("uri", "z/" + zoneId);
    }
  },

  _usecaseChange: function() {
    var zoneId = this.get("zone");
    var ucId = this.get("usecase");
    // Stores the last zone id
    this.set("lastuc-" + zoneId, ucId);
  },

  _operationChange: function() {
    var zoneId = this.get("zone");
    var ucId = this.get("usecase");
    var operation = this.get("operation");
    if (ucId === "") {
      this.set("uri", "z/" + zoneId);
      return;
    }
    if (!_.contains(this.ZONES[zoneId], ucId)) {
      LOG.error("The required zone/usecase is not valid : [" + zoneId + "," + ucId + "]");
      return;
    }
    this.set("uri", "z/" + zoneId + "/uc/" + ucId + "/" + operation);
  },

  /**
   * Realize actions before logout
   */
  _prelogout: function() {
    var self = this;

    var subRouters = _.filter(objs, function(obj) {
      return (obj instanceof SubRoute) && (!STR.isBlank(obj._treatDesconnection));
    });

    var treatDesconnection = function(index) {
      subRouters[index]._treatDesconnection(function(cancel) {
        if (!cancel && index < (subRouters.length - 1)) {
          treatDesconnection(index + 1);
        }
        if (index >= (subRouters.length - 1) && !cancel) {
          self.trigger("user:logout");
        }
      });
    };
    if (subRouters.length > 0) {
      treatDesconnection(0);
    } else {
      self.trigger("user:logout");
    }
  },

  _logout: function(isAsync, notLogin) {
    LOG.debug("logout required");
    // Clear user rights
    GLOBAL_DATA.rights = {};
    // Clear session data
    SESSION.clear();

    // async -> The script is executed asynchronously with the rest of the page (the script will be executed while the page continues the parsing)
    var async = _.isBoolean(isAsync) ? isAsync : true;
    var self = this;
    this.authModel.destroy({
      success: function() {
        LOG.debug("logout successful");
        self.authModel.clear();
        if (!notLogin) {
          self._goToLoginPage();
        }
      },
      error: function() {
        LOG.debug("logout failed");
        var urlTmp = '../chronotime/rest/authentification/session';

        if (Configuration.restRoot.indexOf('http') != -1) {
          urlTmp = Configuration.restRoot + '/rest/authentification/session';
        }
        //Custo #151085
        $.ajax({
          url: urlTmp,
          dataType: 'json',
          type: 'DELETE',
          headers: {
            'Content-Type': 'application/json'
          },
          success: function() {
            // Do something with the result
          },
          error: function() {
            // Do something with the result
          }
        });

        if (self.authModel) {
          self.authModel.clear();
        }
      },
      async: async
    });
  },

  _addTab: function(zoneId) {
    var attrName = "tabs-" + zoneId;
    var currentNumber = this.get(attrName);
    currentNumber = currentNumber + 1;
    this.set(attrName, currentNumber);
    this.set("tabstotal", this.get("tabstotal") + 1);
  },

  _removeTab: function(zoneId) {
    var attrName = "tabs-" + zoneId;
    var currentNumber = this.get(attrName);
    currentNumber = currentNumber - 1;
    this.set(attrName, currentNumber);
    this.set("tabstotal", this.get("tabstotal") - 1);
  },

  _goToLoginPage: function() {
    if (STR.isBlank(this.configuration.get("logouturl")) || _.isEqual(this.configuration.get("logouturl"), "")) {
      window.location.href = "login/login.html";
    } else {
      window.location.href = this.configuration.get("logouturl");
    }

  },
  _loadPreferedUc: function() {
    var navigated = false;
    var param = this.paramEcranDef;
    // When there is a parameter for prefered uc in start up
    if (param != undefined && param.get("valeur") != null && param.get("valeur").length > 0) {
      var uc = param.get("valeur");

      if (this.ZONES.hasOwnProperty(uc)) //its a zone
      {
        objs.appRt.navigate("z/" + uc, true);
        navigated = true;
      } else {
        var zone = this._searchZoneFromUc(uc);

        if (!STR.isBlank(zone)) { // When it's possible to load the prefered uc
          if (uc === "planresp") {
            objs.appRt.navigate("z/" + zone + "/pl/" + uc, true);
          } else {
            objs.appRt.navigate("z/" + zone + "/uc/" + uc, true);
          }
          navigated = true;
        }
      }
    }
    return navigated;
  },
  _searchZoneFromUc: function(ucToFind) {
    var foundZone = null;

    if (!STR.isBlank(ucToFind)) {
      /*customer 209130, checking to see whether the user has access to a certain UC,
      so they cant copy and paste a URL to a UC they dont have permissions to see.
      if not it returns them to the login screen*/
      if (this.MODULES_ZONES[ucToFind.split("/")[0]] !== undefined) {
        foundZone = this.MODULES_ZONES[ucToFind.split("/")[0]][0];
      } else {
        this._goToLoginPage();
      }
    }
    return foundZone;
  },
  getBriqueUniqueId: function(object) {
    var uniqueId;
    if (!STR.isBlank(object.personnalisation)) {
      uniqueId = object.id + "," + object.emid + "," + object.personnalisation.userid + "," + object.personnalisation.codebrique;
    } else {
      uniqueId = object.id + "," + object.emid;
    }
    return uniqueId;
  },

  _loadEvenementsGeneriques: function(callback) {
    var _this = this;

    if (HABILITATION.canView("PAR_EVTGEN.V")) {
      GLOBAL_DATA.typoevtgen.fetch({
        success: function(fresh) {
          if (!_.isEmpty(fresh)) {
            var calls = [];
            var sub = null;

            //Ëvènements génériques
            GLOBAL_DATA.contextActif.contextActifEvtgen = [];
            for (var i = 0; i < fresh.length; i++) {
              GLOBAL_DATA.contextActif.contextActifEvtgen[fresh.models[i].get("code")] = new ContexteActifModel({}, { "contexteActif": "INDORI", "typeEvt": "EVTTYPOGEN", "typologie": fresh.models[i].get("code") });
              calls.push(GLOBAL_DATA.contextActif.contextActifEvtgen[fresh.models[i].get("code")].fetch());
            }
            if (!_.isEmpty(calls)) {
              GLOBAL_DATA.contextActif.contextActifEvtgen.length = _.keys(GLOBAL_DATA.contextActif.contextActifEvtgen).length;
              sub = forkJoin(calls);
              sub.subscribe(function() {
                  if (callback) {
                    callback();
                  }
                },
                function() {
                  _this._logout();
                });
            } else {
              if (callback) {
                callback();
              }
            }
          } else {
            if (callback) {
              callback();
            }
          }
        },
        error: function() {
          _this._logout();
        }
      });

    } else {
      if (callback) {
        callback();
      }
    }
  },

  canViewBefore: function(fonction) {
    //Pour êtree utilisé avant d'avoir l'information dans
    //GLOBAL_DATA.rights....->et pouvoir utiliser HABILITATION.canView(..)
    try {
      var right = "";
      var fonctions = fonction.split(",");
      var l_rights = this.configuration.get("droit");

      for (var i = 0, l = fonctions.length; i < l; i++) {
        right = right || l_rights.get(fonctions[i]);
      }
      return !STR.isBlank(right);
    } catch (err) {
      return false;
    }
  },

});
