import { HABILITATION } from '../../../../utils/habilitation.js';
import { i18n } from '../../../../i18n.js';
import { objs } from '../../../../objectsRepository.js';
import { STR } from '../../../../utils/str.js';

export var DelegantRenderView = Backbone.View.extend({
  initialize: function(options) {

    this.model = null;
    if (options && options.model) {
      this.model = options.model;
    }
    this.workflow = null;
    if (options && options.workflow) {
      this.workflow = options.workflow;
    }
    if (options && options.ctxEcran) {
      this.ctxEcran = options.ctxEcran;
    }
  },

  render: function() {

    var text = "";

    if (!STR.isBlank(this.model.get("delegant"))) {
      if (!STR.isBlank(this.model.get("delegant").get("matricule"))) { // if have a collaborateur
        if (this.workflow.paramIdCollab === "matric" || STR.isBlank(this.workflow.paramIdCollab)) {
          text = this.model.get("delegant").get("nom") + " " + this.model.get("delegant").get("prenom") + " (" + this.model.get("delegant").get("matriculeaux") + ")";
          this.$el.html(text).attr("title", text);
        } else if (this.workflow.paramIdCollab === "matric_aux") {
          text = this.model.get("delegant").get("nom") + " " + this.model.get("delegant").get("prenom") + " (" + this.model.get("delegant").get("matriculeaux") + ")";
          this.$el.html(text).attr("title", text);
        }
      } else { // if have a utilisateur
        text = i18n.t('droitsdel.utilisateur') + " <span style='font-weight: bold'>" + this.model.get("delegant").get("utilnom") + "</span>";
        this.$el.html(text).attr("title", text);
      }
    }

    // ::::::: buttonBarcontrol ::::::::::::::::::::::::

    var USECASE = this.ctxEcran;

    this.workflow.btnBarModel.trigger("show:save");
    this.workflow.btnBarModel.trigger("show:revert");
    this.workflow.btnBarModel.trigger("show:new");
    this.workflow.btnBarModel.trigger("show:delete");
    this.workflow.btnBarModel.trigger("show:copy");

    if (USECASE === "utilisateur") {
      if (!HABILITATION.canCreate("ADM_DELEG")) {
        this.workflow.btnBarModel.trigger("hide:new");
        this.workflow.btnBarModel.trigger("hide:copy");
      }
      if (!HABILITATION.canUpdate("ADM_DELEG") && !HABILITATION.canCreate("ADM_DELEG")) {
        this.workflow.btnBarModel.trigger("hide:save");
        this.workflow.btnBarModel.trigger("hide:revert");
      }
      if (!HABILITATION.canDelete("ADM_DELEG")) {
        this.workflow.btnBarModel.trigger("hide:delete");
      }
    } else if (USECASE === "suivicollab") {
      if (!HABILITATION.canCreate("RES_DELEG")) {
        this.workflow.btnBarModel.trigger("hide:new");
        this.workflow.btnBarModel.trigger("hide:copy");
      }
      if (!HABILITATION.canUpdate("RES_DELEG") && !HABILITATION.canCreate("RES_DELEG")) {
        this.workflow.btnBarModel.trigger("hide:save");
        this.workflow.btnBarModel.trigger("hide:revert");
      }
      if (!HABILITATION.canDelete("RES_DELEG")) {
        this.workflow.btnBarModel.trigger("hide:delete");
      }
    } else {
      if (!HABILITATION.canCreate("COL_DELEG")) {
        this.workflow.btnBarModel.trigger("hide:new");
        this.workflow.btnBarModel.trigger("hide:copy");
      }
      if (!HABILITATION.canUpdate("COL_DELEG") && !HABILITATION.canCreate("COL_DELEG")) {
        this.workflow.btnBarModel.trigger("hide:save");
        this.workflow.btnBarModel.trigger("hide:revert");
      }
      if (!HABILITATION.canDelete("COL_DELEG")) {
        this.workflow.btnBarModel.trigger("hide:delete");
      }
    }
    return this;
  }

});