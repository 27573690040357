import { BriquesLineView } from './briquesLine.view.js';
import { DialogView } from '../../../../../core/views/dialog.view.js';
import { GLOBAL_DATA } from '../../../../../globalData.js';
import { HABILITATION } from '../../../../../utils/habilitation.js';
import { HabilitationContext } from '../../../../../core/models/habilitationContext.js';
import { i18n } from '../../../../../i18n.js';
import { ListeBriquesColl } from '../models/listeBriques.collection.js';
import { ListeBriquesModel } from '../models/listeBriques.model.js';
import { MSGS } from '../../../../../utils/msgs.js';
import { ParametrageView } from '../../../parametragebresultats/views/parametrage.view.js';
import { PersoBriquesDeleteModel } from '../models/persoBriquesDelete.model.js';
import { STR } from '../../../../../utils/str.js';
import TPL_persobriques_detail from '../detail.tpl.html';
/**
 * View for the personnalisation of the liste of brique
 */
export var BriquesListeView = Backbone.View.extend({

  initialize: function(options) {
    this.template = TPL_persobriques_detail;

    /**
    // Context
    // context.ctxEcran
    // context.ctxHabilitation.HabilitationAcces
    // context.ctxHabilitation.HabilitationGestion
    // context.espaceid
    // context.zone
    // context.zoneName
    **/
    if (options && options.context) {
      this.context = options.context;
      this.context.ctxHabilitation = {};

      this.context.ctxHabilitation.HabilitationAcces = this.context.zone == "coll" ? "PER_BRACC_C.V" : "PER_BRACC_R.V";
      this.context.ctxHabilitation.HabilitationGestion = this.context.zone == "coll" ? "PER_BRACC_C.G" : "PER_BRACC_R.G";
      this.context.ctxHabilitation.HabilitationAcces =
        this.model.setHabContext(new HabilitationContext({
          onglet: this.context.ctxEcran,
          fonc_cour: this.context.ctxHabilitation.HabilitationAcces
        }));
      this.model.get("value").setHabContext(this.model.getHabContext());
    }

    if (STR.isBlank(this.model.getHabContext())) {
      this.model.setHabContext(new HabilitationContext({
        onglet: "",
        fonc_cour: ""
      }));
      this.model.get("value").setHabContext(this.model.getHabContext());
    }
    //TODO use correct parametres divers
    this.bresRmax = GLOBAL_DATA.paramDivers.get("bresR_max");
    this.bresCmax = GLOBAL_DATA.paramDivers.get("bresC_max");
  },

  events: _.extend({
    "click .persobriques-brique-line-check": "_manageActivation"
  }),

  canAccess: function() {
    return this.context && this.context.ctxHabilitation && this.context.ctxHabilitation.HabilitationAcces &&
      HABILITATION.canView(this.context.ctxHabilitation.HabilitationAcces);
  },

  setContext: function(context) {
    this.context = context;
    this.context.ctxHabilitation = {};

    this.context.ctxHabilitation.HabilitationAcces = this.context.zone == "coll" ? "PER_BRACC_C.V" : "PER_BRACC_R.V";
    this.context.ctxHabilitation.HabilitationGestion = this.context.zone == "coll" ? "PER_BRACC_C.G" : "PER_BRACC_R.G";
    this.model.updateHabContext({
      onglet: context.ctxEcran,
      fonc_cour: context.ctxHabilitation.HabilitationAcces,
      nat_gest: ""
    });

    if (this.canAccess()) {
      this._loadData(null, null, true);
    }

  },

  _loadData: function(refreshData, briquesToRefresh, silentLoad) {
    var value = this.model.get("value");
    value.setHabContext(this.model.getHabContext());

    if (this.canAccess()) {
      var self = this;
      value.espaceid = this.context.espaceid; // ‘EM_00009’ pour ‘Mon Compte’
      //	‘EM_00022’ pour ‘Mon Equipe’
      value.fetch({
        success: function(fresh) {
          self._generateContent(self.model.get("value"), function(html) {
            self.setContent(html);
            if (silentLoad != true) {
              self.model.trigger("reload", self._modelToColl(self.model.get("value")), refreshData, briquesToRefresh);
            }
          });
        }
      });
    }
  },

  _modelToColl: function(model) {
    var self = this;
    var coll = new ListeBriquesColl();
    _.each(model.get("briques"), function(briqueObject) {
      var briqueModel = new ListeBriquesModel(briqueObject);
      //			briqueObject.typeBrique=briqueObject.id;
      //			briqueObject.id=self.getUniqueId(briqueObject);
      briqueModel.id = briqueModel.getUniqueId();
      briqueModel.typeBrique = briqueObject.id;
      coll.add(briqueModel);
    });
    return coll;
  },

  getUniqueId: function(obj) {
    var uniqueId = "";
    if (!STR.isBlank(obj.personnalisation)) {
      uniqueId = obj.id + "," + obj.emid + "," + obj.personnalisation.userid + "," + obj.personnalisation.codebrique;
    } else {
      uniqueId = obj.id + "," + obj.emid;
    }
    return uniqueId;
  },

  _generateContent: function(value, callbackContent) {
    this.briqueLineViews = [];
    var collection = this._modelToColl(value);
    if (collection.length > 0) {
      this._buildBriqueLine(collection, 0, $("<span>"), callbackContent);
    }
  },

  _buildBriqueLine: function(collection, i, htmlContent, contentCallback) {
    if (collection.length > i) {
      var model = collection.at(i);
      var activesLength = this._totalActivesLength();
      this.briqueLineViews[model.getUniqueId()] = new BriquesLineView({ model: model, context: this.context, position: i, totalActivesLength: activesLength });
      this.briqueLineViews[model.getUniqueId()].model.on("hideButtons", this._hideButtonLines, this);
      this.listenTo(model, "buttonAction", function(buttonId, position) {
        this._buttonAction(buttonId, position);
      });
      if (model.get("emid") === "SP_00006") {
        if (GLOBAL_DATA.paramDivers.get("SPEValideu").get("valeur") == 1) {
          htmlContent.append(this.briqueLineViews[model.getUniqueId()].render().el);
        }
      } else {
        htmlContent.append(this.briqueLineViews[model.getUniqueId()].render().el);
      }
      this._buildBriqueLine(collection, ++i, htmlContent, contentCallback);

    } else {
      contentCallback(htmlContent);
    }
  },
  //Hide buttons of all lines when  we are going to show one button line to avoid showing two buttons lines (because of bad browser management of hover button)
  _hideButtonLines: function() {
    //this.$el.find(".persobriques-brique-line-btns .phx-buttonBar div").trigger("mouseleave"); // made to hide tooltips
    this.$el.find(".persobriques-brique-line-btns").hide();
  },
  _totalActivesLength: function() {
    var value = this.model.get("value");
    var activeCount = 0;
    _.each(value.get("briques"), function(briqueObj) {
      if (briqueObj.active == true) {
        activeCount++;
      }
    });
    return activeCount;
  },
  setContent: function(html) {
    if (this.canAccess()) {
      this.$el.find(".persobriques-content").html(html);
    }
  },

  render: function() {
    var json = { "i18n": i18n };
    this.$el.append(this.template(json));

    return this;
  },
  _manageActivation: function(event) {
    var self = this;
    var target = this.model.get("value");
    var checkBox = event.target;
    var className = checkBox.className.split(" ")[0];
    var position = $(checkBox).attr("position");
    if (target && target.get("briques") && target.get("briques").length >= position && target.get("briques")[position]) {
      target.get("briques")[position].active = $(checkBox).prop("checked");
    }
    var howManyBResultats = this._tooManybresultats();
    if (target.get("briques")[position].id == "bresultats" && target.get("briques")[position].active == true && !STR.isBlank(howManyBResultats)) {
      var nBriques = 0;
      target.get("briques")[position].active = false;
      $(checkBox).prop("checked", 0);
      if (this.context.zone == "coll" && !STR.isBlank(this.bresCmax.get("valeur"))) {
        nBriques = this.bresCmax.get("valeur");
      } else if (this.context.zone == "resp" && !STR.isBlank(this.bresRmax.get("valeur"))) {
        nBriques = this.bresRmax.get("valeur");
      }
      MSGS.showError(i18n.t('messages:GT_1823', { "1": nBriques }));
    } else {
      this._updatePersonnalisation();
    }

  },
  _tooManybresultats: function() {
    var tooMany = false;
    var bResultatsActive = 0;
    _.each(this.model.get("value").get("briques"), function(briqueObj) {
      if (briqueObj.id == "bresultats" && briqueObj.active == true) {
        bResultatsActive++;
      }
    });
    if (this.context.zone == "coll" && !STR.isBlank(this.bresCmax.get("valeur")) && bResultatsActive > this.bresCmax.get("valeur")) {
      tooMany = true;
    } else if (this.context.zone == "resp" && !STR.isBlank(this.bresRmax.get("valeur")) && bResultatsActive > this.bresRmax.get("valeur")) {
      tooMany = true;
    }
    if (tooMany == true) {
      return bResultatsActive;
    } else {
      return null;
    }
  },
  _updatePersonnalisation: function() {
    var self = this;
    var target = this.model.get("value");
    this._deleteFictives(target);
    target.set("id", "briques"); //Force PUT

    target.setHabContext(new HabilitationContext({
      onglet: this.context.ctxEcran,
      fonc_cour: this.context.ctxHabilitation.HabilitationAcces,
      nat_gest: ""
    }));

    target.save(null, {
      success: function() {
        self._loadData();
      }
    });
  },
  _deleteFictives: function(target) {
    var self = this;
    var briquesArray = target.get("briques");
    for (var i = briquesArray.length; i <= 0; i--) {
      if (self._isObjectFictive(briquesArray[i]) == true) {
        briquesArray.splice(i, 1);
      }
    }

  },
  _isObjectPublic: function(obj) {
    var publ = false;
    if (!STR.isBlank(obj.personnalisation) && obj.personnalisation.visibilite == true) {
      publ = true;
    }
    return publ;
  },
  _isObjectFictive: function(obj) {
    var fictive = false;
    if (!STR.isBlank(obj.personnalisation) && STR.isBlank(obj.personnalisation.userid) && STR.isBlank(obj.personnalisation.codebrique)) {
      fictive = true;
    }
    return fictive;
  },
  /**
   * Function that manages button clicked in right form
   */
  _buttonAction: function(buttonId, position) {
    var self = this;
    var target = this.model.get("value");
    switch (buttonId) {
      case "monter":
        //UP
        if (target && target.get("briques") && target.get("briques").length >= position && target.get("briques")[position]) {
          target.get("briques")[position].ordre = target.get("briques")[position].ordre - 1;
        }

        //DOWN
        if (target && target.get("briques") && target.get("briques").length >= (position - 1) && target.get("briques")[position - 1]) {
          target.get("briques")[position - 1].ordre = target.get("briques")[position - 1].ordre + 1;
        }

        //Swap array positions
        var aux = target.get("briques")[position];
        target.get("briques")[position] = target.get("briques")[position - 1];
        target.get("briques")[position - 1] = aux;

        this._updatePersonnalisation();
        break;
      case "descendre":
        var target = this.model.get("value");
        //DOWN
        if (target && target.get("briques") && target.get("briques").length >= position && target.get("briques")[position]) {
          target.get("briques")[position].ordre = target.get("briques")[position].ordre + 1;
        }

        //UP
        if (target && target.get("briques") && target.get("briques").length >= (position + 1) && target.get("briques")[position + 1]) {
          target.get("briques")[position + 1].ordre = target.get("briques")[position + 1].ordre - 1;
        }

        //Swap array positions
        var aux = target.get("briques")[position];
        target.get("briques")[position] = target.get("briques")[position + 1];
        target.get("briques")[position + 1] = aux;
        this._updatePersonnalisation();
        break;
      case "detail":
        var context = this._getParametrageContext(position);
        context.ctxModeInitialisation = "Consultation";
        this._openParametragePopUp(context);

        break;

      case "creer":
        var context = this._getParametrageContext(position);
        context.ctxModeInitialisation = "Ajout";
        this._openParametragePopUp(context);
        break;
      case "supprimer":
        var deleteObj = target.get("briques")[position];
        var messageConfirm = i18n.t('common:delconfirm');
        MSGS.showConfirm(messageConfirm, function(result) {
          if (result == "Y") {
            // set habilitation for the formview
            var deleteTarget = new PersoBriquesDeleteModel();
            //					deleteTarget.userid=deleteObj.personnalisation.userid;
            //					deleteTarget.codebrique=deleteObj.personnalisation.codebrique;
            var id = deleteObj.personnalisation.userid + "," + deleteObj.personnalisation.codebrique;
            deleteTarget.id = id;
            deleteTarget.set("id", id);
            deleteTarget.setHabContext(new HabilitationContext({
              onglet: self.context.ctxEcran,
              fonc_cour: self.context.ctxHabilitation.HabilitationGestion,
              nat_gest: "S"
            }));
            deleteTarget.destroy({
              success: function() {
                self._loadData();
              },
              wait: true
            });
          }
        });
        break;

    }
  },
  _getParametrageContext: function(position) {
    var currentBrique = this.model.get("value").get("briques")[position];
    var userid = !STR.isBlank(currentBrique.personnalisation) ? currentBrique.personnalisation.userid : "";
    var codebrique = !STR.isBlank(currentBrique.personnalisation) ? currentBrique.personnalisation.codebrique : "";
    var typebrique = !STR.isBlank(currentBrique.personnalisation) ? currentBrique.personnalisation.typebrique : "";

    var ctxHabilitation = this.context.zone == "coll" ? { HabilitationAcces: "PER_BRACC_C.V", HabilitationGestion: "PER_BRACC_C.G" } : { HabilitationAcces: "PER_BRACC_R.V", HabilitationGestion: "PER_BRACC_R.G" };
    var context = {
      ctxEcran: this.context.ctxEcran,
      ctxHabilitation: ctxHabilitation,
      currentBrique: currentBrique,
      espaceid: this.context.espaceid,
      zone: this.context.zone,
      zoneName: this.context.zoneName,
      ctxActionsPossibles: ["Creer", "Modifier", "Supprimer", "Dupliquer", "Activer"],
      ctxModeInitialisation: "",
      briqueName: currentBrique.nom,
      typebrique: typebrique,
      userid: userid,
      codebrique: codebrique
    };
    return context;
  },
  _openParametragePopUp: function(context) {
    var self = this;

    this.briquesToRefresh = [];

    if (this.dialogParametrage === undefined) {
      this.dialogParametrage = new DialogView({
        view: ParametrageView,
        viewData: {
          context: context
        }
      });

    } else {
      this.dialogParametrage.viewData = {
        context: context
      };
    }
    this.dialogParametrage.setHeight(620);
    this.dialogParametrage.setWidth(1200);

    this.changeDialogDetected = false;
    this.dialogParametrage.open(function() {
      //What to do on close
      if (self.changeDialogDetected == true) {
        self._loadData(true, self.briquesToRefresh);
      }
    });
    this.dialogParametrage.$el.parent().css("overflow", "visible"); //To show combo
    this.dialogParametrage.internalView.model.off("parametrageChanged");
    this.dialogParametrage.internalView.model.on("parametrageChanged",
      function(fresh, action) {
        if (action == "modification") {
          self.briquesToRefresh.push(self._getPersoId(fresh, context));
        }
        self.changeDialogDetected = true;
      }, this);
  },
  _getPersoId: function(briqueModel, context) {
    var uniqueId = context.currentBrique.id + "," + context.currentBrique.emid + "," + briqueModel.get("userid") + "," + briqueModel.get("codebrique");
    return uniqueId;
  }
});
