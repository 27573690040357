module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="'+
((__t=( divClass ))==null?'':__t)+
'">\r\n    <span class="phx-treenode-leaf-icon ui-icon ui-icon-document-b" style="display:none"></span>\r\n    <span class="phx-treenode-icon phx-treenode-icon-next" data-next-index="'+
((__t=( next ))==null?'':__t)+
'"></span>\r\n    <div class="phx-treenode-wrap">\r\n      <div style="float:left;">\r\n        <input type="checkbox" class="phx-treenode-check" value="true" style="display:none" /><span class="phx-treenode-label">...</span>\r\n      </div>\r\n      <div>\r\n        <div class="tree_arrows">\r\n                <button type="button" class="phx-treenode-prev ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only" role="button" style="display:none">\r\n                    <span class="ui-button-icon-primary ui-icon ui-icon-triangle-1-w" ></span>\r\n                </button>\r\n    \r\n                <button type="button" class="phx-treenode-next ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only" role="button" style="display:none">\r\n                    <span class="ui-button-icon-primary ui-icon ui-icon-triangle-1-e" ></span>\r\n                </button>\r\n            </div>\r\n        <span class="phx-treenode-refresh ui-icon ui-icon-refresh" style="display:none;"></span>\r\n      </div>\r\n    </div>\r\n</div>\r\n';
}
return __p;
};
