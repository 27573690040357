import { STR } from '../../utils/str.js';

export var TreeModel = Backbone.Model.extend({

  dummy: "",

  /**
   * Event when choose a Node with double click.
   */
  /**
   * Event when select a Node with the Ctrl Key pressed.
   */
  /**
   * Event when select a Node.
   */

  defaults: {
    label: "",
    maxLevels: 0
  },

  /**
   * Model of the Tree Component
   *
   */
  initialize: function(params) {
    params || (params = {});

    if (params && params.coll) {
      this.coll = params.coll;
    }
    this.set("label", params.name);
    if (params && params.draggable == true) {
      this.draggable = params.dragable;
    } else {
      this.draggable = false;
    }
    if (params && params.rootModel) {
      this.rootModel = params.rootModel;
    }

    if (params && params.manyRoots === true) {
      this.manyRoots = true;
      if (params && params.firstLevelModels) {
        this.firstLevelModels = params.firstLevelModels;
      }
      if (!STR.isBlank(this.rootModel) && STR.isBlank(this.rootModel.firstLevelColl)) {
        throw new Error("You must inform a firstLevelColl in order to use a fictitious Root : ");
      }
    }

    if (params && params.buildUrlColl) {
      this.buildUrlColl = params.buildUrlColl;
    }
    if (params && params.dragAndDropCallback) {
      this.dragAndDropCallback = params.dragAndDropCallback;
    }
    if (params && params.renderer) {
      this.renderer = params.renderer;
    }
    if (params && params.tooltipRenderer) {
      this.tooltipRenderer = params.tooltipRenderer;
    }
    if (params && params.overLabelTooltipRenderer) {
      this.overLabelTooltipRenderer = params.overLabelTooltipRenderer;
    }
    if (params && params.checkClass) {
      this.checkClass = params.checkClass;
    }
    if (params && params.selectableNode) {
      this.selectableNode = params.selectableNode;
    }
  }
});