
import { ParametresDiversModel } from './parametresDivers.model.js';
import { StoreParamDiversModel } from './storeParamDivers.model.js';
import { STR } from '../../utils/str.js';
/**
 * Parametres Divers Collection
 */
export var ParametresDiversColl = Backbone.Collection.extend({

  dummy: "",
  /**
   * habilitationV
   */
  habilitationV: "N",
  /**
   * habilitationG
   */
  habilitationG: "N",
  /**
   * usecase
   */
  usecase: "app",
  /**
   * url
   */
  url: Configuration.restRoot + "/rest/utilitaire/divers",
  /**
   * model
   */
  model: ParametresDiversModel,
  /**
   * Get a ParametresDiversModel by code
   */
  get: function(id) {
    var element = Backbone.Collection.prototype.get.call(this, id);
    if (!element && _.isString(id)) {
      element = new ParametresDiversModel({ code: id }, { parse: true });
      this.add(element, { merge: false });
    }
    var result = element;
    if (element instanceof Backbone.Model) {
      result = element.clone();
    }
    return result;
  },

  update: function(id) {
    if (STR.isBlank(id) && Configuration.development) {
      throw Error("The first parameter should be the diver identifier");
    } else {
      var diverModel = Backbone.Collection.prototype.get.call(this, id);
      if (diverModel) {
        diverModel.fetched = false;
        diverModel.fetch();
      }
    }
  },

  modifyParamForUser: function(code, value, ecran, callback) {
    var self = this;
    var storeModel = new StoreParamDiversModel();
    storeModel.usecase = ecran;
    storeModel.paramCode = code;
    storeModel.set("valeur", value);
    storeModel.save(null, {
      success: function() {
        var element = Backbone.Collection.prototype.get.call(self, code);
        if (!STR.isBlank(element)) {
          element.set("valeur", value);
        }
        if (callback) {
          callback();
        }
      }
    });
  }

});