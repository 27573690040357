module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="phx-tabs-'+
((__t=(id))==null?'':__t)+
'" class="phx-tabs-container notranslate">\n  <ul>\n    <li aria-controls="home'+
((__t=(id))==null?'':__t)+
'">\n      <a href="#home'+
((__t=(id))==null?'':__t)+
'">\n        '+
((__t=(nom))==null?'':__t)+
'\n      </a><span class="ui-icon ui-icon-extlink"></span></li>\n  </ul>\n  <div id="home'+
((__t=(id))==null?'':__t)+
'" class="phx-proto-resizable phx-simple-panel">\n    <div class="phx-details-header-text">\n      '+
((__t=(nom))==null?'':__t)+
' Accueil</div>\n  </div>\n</div>';
}
return __p;
};
