import { ActionBarView } from '../../controls/actionBar.view.js';
import { BaseGridView } from '../basegrid/baseGrid.view.js';

export var MenuGridView = BaseGridView.extend({

  /**
   * Add this to have Outline
   *
   */
  dummy: "",
  /**
   * DEFAULT_HEIGHT
   */
  DEFAULT_HEIGHT: 305,
  /**
   * DEFAULT_NANE
   */
  DEFAULT_NANE: "MenuGrid",

  /**
   * Constructor
   * MenuGridView - Consultation BaseGrid with a button menu bar
   */
  initialize: function(options) {
    BaseGridView.prototype.initialize.call(this, options);

    //columns definition
    if (!options.model) {
      throw Error("You must define an MenuGridModel to use a MenuGridView");
    }
    this.model = options.model;

    //Search Pk definition if exist and initializes toggle
    _.each(this.currentVue.columns, function(column) {
      if (_.has(column, "pk") && column.pk === true) {
        this.model.propertyPK.push(column.property);
      }
    }, this);

    this._showButtonBar = true;
    //ButtonBar
    this.buttonBar = new ActionBarView({ iconsOnly: true });
    this.model.btnBarModel = this.buttonBar.model;
    this.model.btnBarModel.set("mode", "R");

    //Btn bar reset
    this.model.coll.on("reset", this._resetBtnBar, this);

    // Set Up the model
    this.model.setUp();
  },
  /**
   * Renders a button bar for this grid
   */
  _renderButtonBar: function() {
    if (this._showButtonBar) {
      $(".phx-grid-title-bar-action", this.el).append(this.buttonBar.render().el);
      // This is needed because when you use nested views, if you initializes the view in the constructor,
      // the delegate events is called before the dom is ready.
      this.buttonBar.delegateEvents();
    }
  },
  /**
   * Render this grid
   */
  render: function() {
    var self = this;

    if ($(this.el).children().length === 0) { // Render the Button bar and the header only one time
      $(this.el).html(this.template());
      // Header and button bar
      this._renderHead();
      this._renderButtonBar();
      if (Object.keys(this.vues).length > 1 && this.showButtonsMultipleVues === true) {
        this._renderVuesButtonBar();
      }
    }

    //Configure scroll
    var tableScroll = $(this.el).find(".phx-grid-scroll");
    tableScroll.height(this.height);
    tableScroll.css("overflow-y", "auto");
    tableScroll.css("overflow-x", "auto");

    //Width:
    $(this.el).width(this.width);

    var tbody = $(this.el).find("tbody");
    if (tbody.children().length === 0) {
      // Patch because the first time the collection is filled the
      // DataGrid is not yet visible
      this.model.coll.trigger("reset", this.model.coll);

      $(".phx-grid-scroll", this.el).scroll(function(event) {
        self._scrollControl(event);
        $(".phx-grid-header-scroll", self.el).scrollLeft($(this).scrollLeft());
      });
    }

    this.applyHabilitations();

    this._configureButtonBar();

    this.positionHeader();

    return this;
  },
  /**
   * Sets button bar auto render
   */
  setButtonBarAutoRender: function(renderBtnBar) {
    this._showButtonBar = renderBtnBar;
  },
  /**
   * Resets the button bar
   */
  _resetBtnBar: function() {
    this.model.set("mode", "C");
  },
  /**
   * Configures the buttonbar
   */
  _configureButtonBar: function() {
    this.buttonBar.buttons.habilitation["delete"] = false;
    this.buttonBar.buttons.habilitation["copy"] = false;
    this.buttonBar.buttons.habilitation["new"] = false;
    this.buttonBar.buttons.habilitation["moveUp"] = false;
    this.buttonBar.buttons.habilitation["moveDown"] = false;
    this.buttonBar._manageMode();
  },
  /**
   * OverrideMenu
   */
  overrideMenu: function(customButtonActionCallback, callingView) {
    if (customButtonActionCallback) {
      this.buttonBar.model.off("btn:click");
      this.buttonBar.model.on("btn:click", customButtonActionCallback, callingView);
    }
  }
});