import { CollaborateursModel } from './collaborateurs.model.js';

import { PaginatedCollection } from '../../../../../core/models/paginated.collection.js';
import { STR } from '../../../../../utils/str.js';

/**
 * Collection of collaborateurs
 */
export var CollaborateursColl = PaginatedCollection.extend({

  model: CollaborateursModel,

  url: function() {
    var next = false;

    var url = Configuration.restRoot + "/rest/population/collaborateurs?";

    if (!STR.isBlank(this.nonaffectes)) {
      url += "nonaffectes=" + this.nonaffectes;
      if (STR.isBlank(this.selectcode)) {
        this.selectcode = 0
      }
      url += "&selectcode=" + this.selectcode;
      next = true;
    }
    if (!STR.isBlank(this.type_collab) && !STR.isBlank(this.exclure_fusionencours)) {
      url += (next ? '&' : '') + "type_collab=" + this.type_collab;
      url += "&exclure_fusionencours=" + this.exclure_fusionencours;
    }
    if (!STR.isBlank(this.id_simulation) && !STR.isBlank(this.aff_collabsimu)) {
      url += (next ? '&' : '') + "aff_collabsimu=" + this.aff_collabsimu;
    }
    return url;
  },

  initialize: function(options) {
    PaginatedCollection.prototype.initialize.call(this);
    this.sortings = { nom: true, prenom: true, matric: true };

    if (options) {
      this.nonaffectes = options.nonaffectes;
      this.selectcode = options.selectcode;
      this.type_collab = options.type_collab;
      this.exclure_fusionencours = options.exclure_fusionencours;
      this.id_simulation = options.id_simulation;
      this.aff_collabsimu = options.aff_collabsimu;
    }
  },

  fetch: function(p_options) {
    var options = p_options || {};
    return PaginatedCollection.prototype.fetch.call(this, options);
  }
});