import { CollaborateursColl } from '../models/collaborateurs.collection.js';
import { CollaborateursModel } from '../models/collaborateurs.model.js';
import { GLOBAL_DATA } from '../../../../../globalData.js';
import { HabilitationContext } from '../../../../../core/models/habilitationContext.js';
import { i18n } from '../../../../../i18n.js';
import { SelecteurRefDialogView } from './selecteurRefDialog.view.js';
import { SelecteurReferentielView } from '../../../../../core/components/selecteur_referentiel/selecteurReferentiel.view.js';
import { STR } from '../../../../../utils/str.js';
import TPL_referentielcollaborateur_detail from '../detail.tpl.html';

/**
 * Select collab view
 */
export var SelectCollabView = Backbone.View.extend({

  initialize: function(options) {
    this.template = TPL_referentielcollaborateur_detail;

    //this.diversIdCollab = "matric";
    this.diversIdCollab = GLOBAL_DATA.paramDivers.get("idCollab").get("valeur");

    if (options) {
      this.host = options.host;
      this.context = options.context;
      this.nonaffectes = options.nonaffectes;
      this.selectcode = options.selectcode;
      this.exclure_fusionencours = options.exclure_fusionencours;
      this.type_collab = options.type_collab;
      this.id_simulation = options.id_simulation;

      if (options.required) {
        this.required = options.required;
      } else {
        this.required = false;
      }
      this.fieldWidth = options.fieldWidth; // Width of the ref input default is "100%"
      this.forceContextHabilitation = options.forceContextHabilitation;
    }

    this.model = new CollaborateursModel();

    if (this.host && this.host.workflow && this.host.workflow.formModel) {

      this.habContext = this.host.workflow.formModel.getHabContext();
      if (options.forceContextHabilitation && this.context) {
        this.habContext.set("fonc_cour", this.context.habilitation);
        this.habContext.set("onglet", this.context.usecase);
      }

    } else {

      this.habContext = new HabilitationContext({
        onglet: "referentielcollaborateur",
        fonc_cour: "RES_POPCOLLAB.V",
        nat_gest: ""
      });

    }

    this.model.setHabContext(this.habContext);

    this.listenTo(this.model, "change", this._modelChanged);
  },

  setIdCollab: function() {
    this.diversIdCollab = GLOBAL_DATA.paramDivers.get("idCollab").get("valeur");
  },

  _modelChanged: function() {
    this.model.trigger("referentielChanged");
  },

  events: {
    "change :input:not([readonly])": "_change"
  },

  render: function() {
    var json = { "i18n": i18n };
    this.$el.html(this.template(json));
    var self = this;
    var coll = new CollaborateursColl({
      nonaffectes: self.nonaffectes,
      selectcode: self.selectcode,
      type_collab: self.type_collab,
      id_simulation: self.id_simulation,
      aff_collabsimu: !STR.isBlank(self.id_simulation), // by default: true if mode simulated
      exclure_fusionencours: self.exclure_fusionencours
    });

    // we put the correct nature from the host (usecase)  in the component.
    if (this.host && this.host.workflow && this.host.workflow.formModel) {
      coll.setHabContext(this.host.workflow.formModel.getHabContext());
    } else { // if not exist we use the normal context.
      this.habContextColl = new HabilitationContext({
        onglet: this.context.usecase,
        fonc_cour: this.context.habilitation
      });

      coll.setHabContext(this.habContextColl);
    }

    if (!STR.isBlank(coll.id_simulation)) {
      coll.activeSimulation = true;
    }

    var referentiel = new SelecteurReferentielView({
      coll: coll,
      name: "nom",
      itemRenderer: function(model) {
        if (model.code === null) {
          return model.libelle;
        }
        if (self.diversIdCollab == "matric_aux") {
          return model.nom + " " + model.prenom + " (" + model.matricaux + ")";
        } else {
          return model.nom + " " + model.prenom + " (" + model.matric + ")";
        }
      },
      view: SelecteurRefDialogView,
      title: i18n.t('referentielcollaborateur.title_pop'),
      height: 500,
      width: 500,
      required: this.required,
      addItemCallback: function(data) {
        self._responseCallback(data);
      },
      fieldWidth: this.fieldWidth
    });

    this.referentiel = referentiel;

    this.$el.find(".cmbSelectionner").html(referentiel.render().el);

    return this;
  },

  _responseCallback: function(data) {
    this.model.set(data);

    if (this.host && this.host._responseCallback) {
      this.host._responseCallback(data, this.idComponent);
    }
  },

  initWithCode: function(code) {
    var self = this;
    var originalModel = new CollaborateursModel();
    var originalCollection = new Backbone.Collection();

    originalModel.id = code;

    if (this.habContext) {
      originalModel.setHabContext(this.habContext);
    }

    originalModel.fetch({
      success: function(fresh) {
        originalCollection.push(fresh);
        self.referentiel.setValues(originalCollection);
      }
    });
  },

  remove: function() {
    this.host = null;
    Backbone.View.prototype.remove.call(this); // Remove view from DOM
  },

  setSelectcode: function(selectcode) {
    this.selectcode = selectcode;
    this.referentiel.coll.selectcode = selectcode;
  }
});