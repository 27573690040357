module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<li tabindex="-1" role="menuitem" class="phx-menu-link phx-hover ui-corner-all">\n  <a tabindex="-1" href="'+
((__t=(href))==null?'':__t)+
'" class="'+
((__t=(id))==null?'':__t)+
' ui-corner-all '+
((__t=(emid))==null?'':__t)+
'" target="'+
((__t=(target))==null?'':__t)+
'">\n    '+
((__t=(nom))==null?'':__t)+
'\n  </a>\n</li>';
}
return __p;
};
