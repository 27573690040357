import { i18n } from '../../../../../i18n.js';
import { ReadOnlyModel } from '../../../../../core/models/readOnly.model.js';
import { TYPE } from '../../../../../tda/tda.js';

/**
 * Model for Brique 'Infos / Memos'
 */
export var InfosMemosModel = ReadOnlyModel.extend({

  //REVIEW: 	i18n : phx.i18n.common.binfmem,
  url: Configuration.restRoot + "/rest/brique/message?tri=datedeb;asc,datefin;asc",

  defaults: {
    "index": "",
    "nbrang": "",
    "total": "",
    "message": {
      "message": "",
      "datedeb": null,
      "datefin": null,
      "accueil": null,
      "acquitte": null,
      "genere": null
    }
  },

  parse: function(response) {
    var tooltip = "";
    var self = this;
    _.each(response.message, function(data) {
      if (data.datedeb != data.datefin) {
        tooltip = i18n.t('common:binfmem.messageAfficheDu') + TYPE.DATE.format(data.datedeb) +
          i18n.t('common:binfmem.messageAfficheAu') + TYPE.DATE.format(data.datefin);
        data.tooltip = tooltip;
      }
    });
    return response;
  }
});