import { ReadOnlyModel } from 'core/models/readOnly.model.js';

/**
 * Type de Structure Model
 */
export var TypeStructureModel = ReadOnlyModel.extend({

  defaults: {
    "code": null,
    "codef": "null",
    "libelle": "null",
    "abrege": "null",
    "dated": "null",
    "format": null,
    "indiczonesco": "null",
    "prioritejourex": null,
    "rattcollab": "null",
    "nbelements": null,
    "nbcollab": null,
    "nbcollabnonrattache": null,
    "principal": null
  }
});
