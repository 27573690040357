import { BaseModel } from '../../../../../../core/models/base.model.js';

import { i18n } from '../../../../../../i18n.js';
import { STR } from '../../../../../../utils/str.js';
import { TYPE } from '../../../../../../tda/tda.js';

/**
 * Model for gerer badgeages
 **/
export var ListeBadgeageModel = BaseModel.extend({
  checkComments: false,

  url: function() {
    return Configuration.restRoot + "/rest/badgeage/composant/" + this.action;
  },
  /**
   * Default data model
   *	"id": null,
   *	"code": null,
   *	"matricule": null,
   *	"statut": {
   *		"code" : "",
   *		"libelle" : ""
   *	},
   *	"motif": {
   *		"code" : "",
   *		"libelle" : ""
   *	},
   *	"saisie": {
   *		"date" : "",
   *		"heure" : "",
   *		"sens" : ""
   *	},
   *	"valorise": {
   *		"date" : "",
   *		"heure" : "",
   *		"sens" : ""
   *	},
   *	"initial": {
   *		"date" : "",
   *		"heure" : "",
   *		"sens" : ""
   *	},
   *	"fonction": "",
   *	"commentaire": "",
   *	"droit": null,
   *	"iddemande": null,
   *	"provenance": "",
   *	"origine": null,
   *	"terminal": null,
   *	"genere": null
   *
   *}
   */
  defaults: {
    "id": null,
    "code": null,
    "matricule": null,
    "statut": {
      "code": "",
      "libelle": ""
    },
    "motif": {
      "code": "",
      "libelle": ""
    },
    "saisie": {
      "date": "",
      "heure": "",
      "sens": ""
    },
    "valorise": {
      "date": "",
      "heure": "",
      "sens": ""
    },
    "initial": {
      "date": "",
      "heure": "",
      "sens": ""
    },
    "fonction": "",
    "commentaire": "",
    "droit": null,
    "iddemande": null,
    "provenance": "",
    "origine": null,
    "terminal": null,
    "genere": null

  },

  initialize: function(options) {
    this.utilisateur = "";
    if (options && options.utilisateur) {
      this.utilisateur = options.utilisateur;
    }
  },

  validate: function(attrs) {
    var errors = {};
    errors.saisie = {};
    if (!STR.isBlank(attrs.saisie.date)) {
      var errorMsg = TYPE.DATE.validate(attrs.saisie.date);
      if (!STR.isBlank(errorMsg)) {
        errors.saisie.date = errorMsg;
      }
    } else {
      errors.saisie.date = i18n.t('common:required', { "0": i18n.t('common:gererbadgeages.date') });
    }
    if (!STR.isBlank(attrs.saisie.heure)) {
      var errorMsg = TYPE.HOUR_MINUTE.validate(attrs.saisie.heure);
      if (!STR.isBlank(errorMsg)) {
        errors.saisie.heure = errorMsg;
      }
    } else {
      errors.saisie.heure = i18n.t('common:required', { "0": i18n.t('common:gererbadgeages.heure') });
    }
    if (STR.isBlank(attrs.saisie.sens)) {
      errors.saisie.sens = i18n.t('common:required', { "0": i18n.t('common:gererbadgeages.sens') });
    }
    if (this.utilisateur === "Collaborateur" && this.checkComments) {
      if (STR.isBlank(attrs.commentaire)) {
        errors.commentaireLibelle = i18n.t('common:required', { "0": i18n.t('common:gererbadgeages.commentaire') });
      }
    }

    // if (!_.isEmpty(errors.saisie)) {
    if (!_.isEmpty(errors.saisie) || !_.isEmpty(errors.commentaireLibelle)) {
      var errorMap = {
        errorValidation: STR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }

  },

  /**
   * Parsed the results returned by the REST,
   * Copy the matricule and code in the object id.
   * Initializes saisies values to valorises values in order to show them initially in the form.
   */
  parse: function(response) {
    if (response.matricule != undefined && response.code != undefined) {
      response.id = response.matricule + "," + response.code;
    }
    if (response.valorise != undefined) {
      if (response.valorise.heure != undefined)
        response.saisie.heure = response.valorise.heure;
      if (response.valorise.date != undefined)
        response.saisie.date = response.valorise.date;
      if (response.valorise.sens != undefined)
        response.saisie.sens = response.valorise.sens;
    }

    if (response.result) {
      return null;
    }
    return response;
  }
});
