import { i18n } from '../../i18n.js';
import { LOG } from '../../utils/log.js';
import { objs } from '../../objectsRepository.js';
import { STR } from '../../utils/str.js';
import TPL_common_split_panel from './split_panel.tpl.html';

export var SplitPanelView = Backbone.View.extend({
  /**
   * Event launched when window is resized
   */
  /**
   * Event launched when position is changed
   */

  dummy: "",

  // Border width of the split bar control in pixels. It is used in the
  // functions that recalculte the max width of menu and split panels to
  // avoid the split controls become hidden. See
  // phx.app.AppRouter._updateMaxWidthSplitPanels and _updateMaxWidthLeft
  /**
   * SPLIT_BORDER_WIDTH
   */
  SPLIT_BORDER_WIDTH: 7,
  /**
   * TABS_HEADER_HEIGHT
   */
  TABS_HEADER_HEIGHT: 75,
  MINIMUM_SIZE: 0.3,
  /**
   * className
   */
  className: "phx-splitPanel",
  /**
   * firstTemplate
   */
  firstTemplate: TPL_common_split_panel,

  events: {
    "keydown .ui-resizable-handle": "_keypressHandler"
  },
  /**
   * Constructor
   * Split Panel View
   */
  initialize: function(options) {
    this.template = this.firstTemplate;

    this.model = new Backbone.Model({
      position: 50,
      prevPosition: 30
    });
    if (options && _.isNumber(options.position)) {
      this.model.set("position", options.position);
    }

    if (options && _.isNumber(options.prevPosition)) {
      this.model.set("prevPosition", options.prevPosition);
    }

    this.listenTo(this.model, "change:position", this._manageSplitPosition);
    this.listenTo(Backbone, "resize:" + this.cid, this._updateMaxWidthSplitPanels);

    this.splitterButtonsTimer = null;
    this.resizing = false;
  },

  /**
   * Renders this view
   */
  render: function() {
    $(this.el).html(this.template());
    $(this.el).attr("cid", this.cid);
    //Adjust height
    //		$(".phx-proto-resizable", this.el).height(requiredHeight - this.TABS_HEADER_HEIGHT);

    // Set IDs
    this.$el.find(".phx-splitA").attr("id", this.cid + "-splitA");
    this.$el.find(".phx-splitB").attr("id", this.cid + "-splitB");
    // Set WAI-ARIA role
    this.$el.find(".phx-splitB").attr("role", "region");
    if (!STR.isBlank(this.labelledby)) {
      this.$el.find(".phx-splitB").attr("aria-labelledby", this.labelledby);
    }

    //Adjust split position
    /**
     * Left panel
     */
    this.elSplitA = $(".phx-splitA", this.el);
    var splitPosition = $("#phx-wrap").width() * (this.model.get("position") / 100);
    this.elSplitA.width(splitPosition);
    var self = this;
    //Make resizable
    this.elSplitA.resizable({
      handles: "e",
      minWidth: objs.appRt.borderWidth,
      create: function(event, ui) {
        // Create elements
        var rigthArrow = $("<span>").addClass("ui-resizable-goto-right  " + self.cid).css({ "z-index": "91", "position": "absolute" }).html("&raquo;");
        var leftArrow = $("<span>").addClass("ui-resizable-goto-left " + self.cid).css({ "z-index": "91", "position": "absolute" }).html("&laquo;");
        // add events
        rigthArrow.mouseenter(function(event) {
          clearTimeout(self.splitterButtonsTimer);
        });
        rigthArrow.mouseleave(function(event) {
          self._hideActionsDelayed(event);
        });
        rigthArrow.click(function() {
          var currentPosition = self.model.get("position");
          var previousPosition = self.model.get("prevPosition");

          if (currentPosition === self.MINIMUM_SIZE) {
            self.model.set("position", previousPosition);
          } else {
            self.model.set("prevPosition", currentPosition);
            self.model.set("position", 100);
          }
          self.model.trigger("click:arrow", "right");
          self._hideActions();
        });
        leftArrow.mouseenter(function(event) {
          clearTimeout(self.splitterButtonsTimer);
        });
        leftArrow.mouseleave(function(event) {
          self._hideActionsDelayed(event);
        });
        leftArrow.click(function() {
          var currentPosition = self.model.get("position");
          var previousPosition = self.model.get("prevPosition");

          if (currentPosition === 100) {
            self.model.set("position", previousPosition);
          } else {
            self.model.set("prevPosition", currentPosition);
            self.model.set("position", self.MINIMUM_SIZE);
          }
          self._hideActions();
          self.model.trigger("click:arrow", "left");
        });
        $(event.target).append(rigthArrow);
        $(event.target).append(leftArrow);
        rigthArrow.hide();
        leftArrow.hide();
        rigthArrow.position({
          my: "left center",
          of: event.target,
          collision: "fit"
        });
        leftArrow.position({
          my: "right-7 center",
          of: event.target,
          collision: "fit"
        });
        // WAI-ARIA properties to omit buttons
        rigthArrow.attr("aria-hidden", "true");
        leftArrow.attr("aria-hidden", "true");
        // Set splitter as focusable
        $(event.target).find(".ui-resizable-handle").attr("tabindex", "0");
        // Set WAI-ARIA properties
        $(event.target).find(".ui-resizable-handle").attr("role", "separator");
        $(event.target).find(".ui-resizable-handle").attr("aria-label", i18n.t('wai_aria.panelSplitter'));
        $(event.target).find(".ui-resizable-handle").attr("aria-controls", self.cid + "-splitA " + self.cid + "-splitB");

        self._manageAria_expanded();
      },
      start: function() {
        self.resizing = true;
        self._hideActions();
      },
      stop: function(event, ui) {
        self.resizing = false;
        var pos = event.pageX * 100 / $("#phx-wrap").width();
        self.model.set("position", pos, { silent: true });
        self._manageAria_expanded();
      },
      resize: function(event, ui) {
        objs.appRt.workflow.trigger("resize");
      }
    });
    //Set mouseover event
    this.elSplitA.find(".ui-resizable-handle").mouseenter(function(event) {
      self._showActions(event);
    });
    this.elSplitA.find(".ui-resizable-handle").mouseleave(function(event) {
      self._hideActionsDelayed(event);
    });
    return this;
  },

  _keypressHandler: function(event) {
    var key = event.which || event.keyCode;
    var displacement = 1;
    var currentPosition = this.model.get("position");
    var prevPosition = this.model.get("prevPosition");

    // The key hava a custom action
    if (key === 13 || key === 35 || key === 36 || key === 37 || key === 39) {
      event.stopPropagation();
      event.preventDefault();
    }

    if (key === 13) {
      this.model.set("position", prevPosition);
    } else if (key === 35) {
      this.model.set("prevPosition", currentPosition);
      this.model.set("position", 100);
    } else if (key === 36) {
      this.model.set("prevPosition", currentPosition);
      this.model.set("position", this.MINIMUM_SIZE);
    } else if (key === 37 && currentPosition > 0) {
      this.model.set("prevPosition", currentPosition);
      this.model.set("position", currentPosition - displacement);
    } else if (key === 39 && currentPosition < 100) {
      this.model.set("prevPosition", currentPosition);
      this.model.set("position", currentPosition + displacement);
    }
  },

  /**
   * Resize the maxwidth of split panels and the width of partB to adjust
   * scroll
   */
  _updateMaxWidthSplitPanels: function() {
    LOG.debug("resizing:" + this.cid);
    //This border width is needed to adjust the min and maximum width of the menu
    var borderWidth = SplitPanelView.prototype.SPLIT_BORDER_WIDTH;

    var maxWidthAllowed = $("#phx-wrap").width();

    // Configure split panels
    // set size of left panel
    var elSplitA = this.$el.find(".phx-splitA").eq(0);
    // If is a liste detail
    if (elSplitA.length > 0) {
      maxWidthAllowed -= borderWidth;
      elSplitA.resizable("option", "maxWidth", maxWidthAllowed);
      if (maxWidthAllowed < elSplitA.width()) {
        elSplitA.width(maxWidthAllowed);
      }

      // set size of right panel
      var elSplitB = this.$el.find(".phx-splitB").eq(0);
      if (elSplitB.length > 0) { // exists SplitPanel B ?
        LOG.debug(this.$el.parent().width() + " - " + elSplitA.width() + " - " + (borderWidth / 2) + " : " + ($(document).width() - this.$el.position().left - elSplitA.width() - (borderWidth / 2)));
        var widthB = this.$el.parent().width() - elSplitA.width() - (borderWidth / 2); //elSplitB.offset().left;
        elSplitB.width(Math.floor(widthB)); // Floor to delete decimals
      }
    }
  },
  /**
   * Show action left and right arrows
   */
  _showActions: function(event) {
    if (!this.resizing) {
      clearTimeout(this.splitterButtonsTimer);
      if (this.model.get("position") < 98.4) {
        var rigthArrow = this.$el.find(".phx-splitA .ui-resizable-goto-right." + this.cid);
        rigthArrow.show().position({
          my: "left center",
          of: event.currentTarget,
          collision: "fit"
        });
      }

      if (this.model.get("position") > 2) {
        var leftArrow = this.$el.find(".phx-splitA .ui-resizable-goto-left." + this.cid);
        leftArrow.show().position({
          my: "right-6 center",
          of: event.currentTarget,
          collision: "fit"
        });
      }
    }
  },
  /**
   * Hide Actions delayed
   */
  _hideActionsDelayed: function() {
    if (!this.resizing) {
      var self = this;
      this.splitterButtonsTimer = setTimeout(function() {
        self.$el.find(".phx-splitA .ui-resizable-goto-right." + self.cid).hide("slide", { direction: 'left' });
        self.$el.find(".phx-splitA .ui-resizable-goto-left." + self.cid).hide("slide", { direction: 'right' });
      }, 2000);
    }
  },
  /**
   * Hide left and right arrow actionsactions
   */
  _hideActions: function() {
    this.$el.find(".phx-splitA .ui-resizable-goto-right." + this.cid).hide();
    this.$el.find(".phx-splitA .ui-resizable-goto-left." + this.cid).hide();
  },
  /**
   * Manages split positioning
   */
  _manageSplitPosition: function() {
    this._manageAria_expanded();
    if (STR.isBlank(this.model.get("prevPosition"))) {
      this.model.set("prevPosition", this.model.get("position"));
    }
    var splitPosition = $(this.el).parent().width() * (this.model.get("position") / 100);
    this.elSplitA.width(splitPosition);
    // Force to update width.
    objs.appRt._updateMaxWidthSplitPanels();
    objs.appRt._resizeProtoResizables();
  },
  /**
   * Calculates center height
   */
  _calculateCenterHeight: function() {
    return $(this.el).parent().height() - $(".phx-header").height();
  },

  _manageAria_expanded: function() {
    if (this.model.get("position") <= 0.3) {
      this.$el.find(".ui-resizable-handle").attr("aria-expanded", "false");
    } else {
      this.$el.find(".ui-resizable-handle").attr("aria-expanded", "true");
    }
  },

  setLabelIds: function(labelIds) {
    this.labelledby = labelIds;
  },

  enableResizable: function() {
    this.elSplitA.resizable("enable");
  },

  disableResizable: function() {
    this.elSplitA.resizable("disable");
  },

  disableSplitter: function() {
    this.$el.find('.phx-splitA > .ui-resizable-handle').hide();
    this.$el.find('.phx-splitA').addClass('phx-hideSplitter');
  },

  enableSplitter: function() {
    this.$el.find('.phx-splitA > .ui-resizable-handle').show();
    this.$el.find('.phx-splitA').removeClass('phx-hideSplitter');
  },

  hideLeft: function() {
    this.$el.find('.phx-splitA').hide();
  },

  showLeft: function() {
    this.$el.find('.phx-splitA').show();
  },

  hideRight: function() {
    this.$el.find('.phx-splitB').hide();
  },

  showRight: function() {
    this.$el.find('.phx-splitB').show();
  }

});
