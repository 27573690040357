import { BaseModel } from 'core/models/base.model.js';
import { STR } from '../../../../utils/str.js';
import { TYPE } from '../../../../tda/tda.js';

/**
 * Model to filtered the data
 */
export var FilterModel = BaseModel.extend({

  usecase: "droitsdel",

  initialize: function() {
    this.groupedErrors = {
      datedeb: ["datedeb", "datefin"],
      datefin: ["datedeb", "datefin"]
    };
  },

  /**
   * Default data model
   *		id : null,
   *		datedeb : "",
   * 		datefin : ""
   * }
   */
  defaults: {
    "id": null,
    "datedeb": "",
    "datefin": ""
  },

  validate: function(attrs, options) {
    var errors = {};

    //Period validation
    var datedeb = {
      "name": "datedeb",
      "value": attrs.datedeb,
      "label": undefined
    };
    var datefin = {
      "name": "datefin",
      "value": attrs.datefin,
      "label": undefined,
      "isInfinity": false
    };
    var errorMsg = TYPE.DATE.validatePeriod(datedeb, datefin, options);
    if (!STR.isBlank(errorMsg)) {
      errors.datedeb = errorMsg.datedeb;
      errors.datefin = errorMsg.datefin;
    }

    if (!_.isEmpty(errors)) {
      var errorMap = {
        errorValidation: STR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    }
    return null;
  }

});