import { ScheduleRowView } from './scheduleRow.view.js';

export var ScheduleView = Backbone.View.extend({
  /**
   * Add this to have Outline
   *
   */
  dummy: "",
  /**
   * i18n
   */
  //REVIEW: 	i18n : phx.i18n.common,

  /**
   * tagName
   */
  tagName: "div",
  /**
   * className
   */
  className: "phx-schedule",
  /**
   * Constructor
   * PlagesHoraire graphique component view.
   * It's model supports:
   */
  initialize: function(attributes, options) {
    this.model = options;
    if (_.isBoolean(options.showTitle)) {
      this.showTitle = options.showTitle;
    } else {
      this.showTitle = true;
    }
  },

  /**
   * Renders this view
   */
  render: function() {
    var divText = new ScheduleRowView({ plages: this.model.plages, type: "text" });
    var divPlage = new ScheduleRowView({ plages: this.model.plages, type: "plage", showTitle: this.showTitle });

    $(this.el).html(divText.render().el);
    $(this.el).append(divPlage.render().el);
    return this;
  }
});