import { BaseModel } from 'core/models/base.model.js';

/**
 * Model of etats for the left table
 */
export var EtatsModel = BaseModel.extend({

  parse: function(response) {
    response.id = response.code + "," + response.libelle;
    return response;
  }
});