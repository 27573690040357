import { BadgeageVirtuelDialogView } from '../../badgeage/views/badgeageVirtuelDialog.view.js';
import { BaseModel } from 'core/models/base.model.js';
import { CompteursColl } from '../../badgeage/models/compteurs.collection.js';
import { ConfigResultatsListeCpt } from '../models/configResultatsListeCpt.js';
import { ConsultationDialogView } from './consultationDialog.view.js';
import { DataGridModel } from 'core/grids/data_grid/dataGrid.model.js';
import { DataGridView } from 'core/grids/data_grid/dataGrid.view.js';
import { DialogView } from 'core/views/dialog.view.js';
import { FormView } from 'core/views/form.view.js';
import { GLOBAL_DATA } from '../../../../globalData.js';
import { HABILITATION } from '../../../../utils/habilitation.js';
import { HabilitationContext } from 'core/models/habilitationContext.js';
import { i18n } from '../../../../i18n.js';
import { LOG } from '../../../../utils/log.js';
import { ModeBadgModel } from '../../badgeage/models/modeBadg.model.js';
import { MSGS } from '../../../../utils/msgs.js';
import { objs } from '../../../../objectsRepository.js';
import { Preferences } from '../models/preferences.js';
import { SearchListeGroupesColl } from '../models/searchListeGroupes.collection.js';
import { SearchListeGroupesData } from '../models/searchListeGroupesData.js';
import { SearchResultatsListeCptColl } from '../models/searchResultatsListeCpt.collection.js';
import { SearchResultatsListeCptModel } from '../models/searchResultatsListeCpt.model.js';
import { STR } from '../../../../utils/str.js';
import { SYNC } from '../../../../utils/sync.js';
import { TYPE } from '../../../../tda/tda.js';
import TPL_resultatgrpcompteurs_groupecompteurs from '../groupecompteurs.tpl.html';

/**
 * DialogView of the Groupe Compteurs
 */
export var GroupeCompteursDialogView = FormView.extend({

  events: _.defaults({
    "click .iconConsul": "_showConsultation",
    "click .showCompteurs": "_showBlockCompteurs",
    "click .hideCompteurs": "_hideBlockCompteurs",
  }),

  initialize: function(options) {
    this.template = TPL_resultatgrpcompteurs_groupecompteurs;

    // RGAA development
    this.theme = _.clone(GLOBAL_DATA.theme);

    if (options && options.parent) {
      this._closeOthersDialogs(options.parent.$el.attr("id"));
      this.parent = options.parent;
      if (this.parent._setTitle && options.context.ctxModule === "agenda") {
        this.parent._setTitle(i18n.t('common:resultatgrpcompteurs.dialogGroupeCompteurs'));
      } else if (this.parent._setTitle && options.context.ctxModule !== "agenda") {
        this.parent._setTitle(i18n.t('common:resultatgrpcompteurs.situationcollab'));
      }
    }

    if (this.parent) {
      // Flag icon to open Valorisation in agenda
      this.origin = "header_valorisation";
    } else {
      // Click in "Situation" section in "Complements" right view of agenda
      this.origin = "complements_situation";
    }

    this.context = options.context;

    if (STR.isBlank(this.context)) {
      throw new Error("A context must be specified for this component.");
    }

    this.model = new BaseModel({
      value: new SearchResultatsListeCptModel()
    });
    this.model.setHabContext(new HabilitationContext({
      onglet: this.context.ctxEcran,
      fonc_cour: this.context.ctxHabilitation
    }));

    this.table = null;
    this.newMatricule = options.context.resultatsCollab;
    this.newTypeGroupe = options.context.resultatsGroupe;
    this.newGroupe = null;
    this.newDate = null;
    this.newDemande = null;
    this.newSouhait = null;

    this.configDate = null;
    this.configSouhait = null;
    this.isVisible = false;

    var self = this;
    this._getGroupe(self.newTypeGroupe, function() {
      var configModel = new ConfigResultatsListeCpt();
      configModel.setHabContext(self.model.getHabContext());

      if (self.context.ctxModule == "agenda_R") {
        configModel.matric = self.context.resultatsCollab;
      }

      configModel.fetch({
        success: function(fresh) {
          self.newDate = fresh.get("date");
          self.newDemande = fresh.get("demande");
          self.newSouhait = fresh.get("souhait");

          if (self.context) {
            if (!STR.isBlank(self.context.resultatsDate)) {
              self.newDate = self.context.resultatsDate;
            }

            self.newDemande = self.context.resultatsPRDde;
            self.newSouhait = self.context.resultatsPRSht;
          }

          self.table = self._initTable();

          // Show/hide columns
          self.table.model.on("toggle:column", self._showIcon, self);

          self.table.model.coll.matricule = self.newMatricule;
          self.table.model.coll.groupe = self.newGroupe;
          self.table.model.coll.date = self.newDate;
          self.table.model.coll.demande = self.newDemande;
          self.table.model.coll.souhait = self.newSouhait;

          // remove row selection
          self.table.model.coll.off("row:click");

          //updateTable
          if (self.context.resultatsAuto === true) {
            self.table.model.coll.setHabContext(self.model.getHabContext());
            self.table.model.coll.fetch({
              success: function() {
                self._renderTable();
                //								self.table.model.trigger("dataLoaded",self.table.model.coll.length);
                //								self.trigger("dataLoaded",self.table.model.coll.length);
                self._adjustPoputpToContent(self.table.model.coll.length);
                self.trigger("resizeDialog");
              }
            });
          } else {
            self._renderTable();
            self._showConsultation();
          }
        }
      });
    });
    this.numSeparators = 0;
  },

  _adjustPoputpToContent: function(rows) {
    var newHeight = (rows < 20) ? (((rows) * 26) + 141) : 615;
    if (this.parent) {
      this.parent.$el.height(newHeight);
    }
  },

  _closeOthersDialogs: function(thisdialogid) {
    $(".ui-dialog .gmsituation").each(function(index, element) {
      var idhtml = $(element).parent(".ui-dialog-content").attr("id");
      if (idhtml !== thisdialogid) {
        $("#" + idhtml).dialog("close");
      }
    });
  },

  _showIcon: function(columnName) {
    if (columnName == "type") {
      this.isVisible = !this.isVisible;
      this.table.model.trigger("toggle:column", "type_icon");
    }
  },

  /**
   * 	resultatsMode = this.workflow.get("utilisateur"); //no
  	resultatsCollab = this.workflow.get("collabId");
  	resultatsGroupe = 33;//TODO fix with right values
  	resultatsDate = null;
  	resultatsPRDde = null;
  	resultatsPRSht = null;
  	resultatsAuto = true; //no
  	resultatsTitre = false; //no
   */
  setContext: function(context) {
    var self = this;

    // remove row selection
    if (this.table) {
      this.table.model.coll.off("row:click");
    }

    if (context.ctxEcran !== "agenda") {
      MSGS.showConfirm(i18n.t('common:resultatgrpcompteurs.confirm'), function(result) {
        if (result === "Y") {
          self._setContext(context);
        }
      });
    } else {
      this._setContext(context);
    }

    // Hide table footer
    if (this.table) {
      this.table.setTotalRowsVisible(false);
    }
  },

  _setContext: function(context) {
    var self = this;
    this.newMatricule = context.resultatsCollab;
    this.newTypeGroupe = context.resultatsGroupe;

    this._getGroupe(this.newTypeGroupe, function() {
      if (context.resultatsDate === null) {
        self.newDate = self.configDate;
      }
      /*else {
				self.newDate = context.resultatsDate;
			}*/

      if (self.newDate === null) {
        self.newDate = context.resultatsDate;
      }
      self.newDemande = context.resultatsPRDde;
      self.newSouhait = context.resultatsPRSht;

      self._updateTable();
    });
  },

  _updateTable: function() {
    var self = this;

    this.numSeparators = 0;

    this.table.model.coll.matricule = this.newMatricule;
    this.table.model.coll.groupe = this.newGroupe;
    this.table.model.coll.date = this.newDate;
    this.table.model.coll.demande = this.newDemande;
    this.table.model.coll.souhait = this.newSouhait;

    this.table.model.coll.setHabContext(this.model.getHabContext());

    self._changeTitle(); //aqui ya me llega la fecha mal
    this.table.model.coll.fetch();
  },

  _getGroupe: function(typegroupe, callback) {
    var self = this;
    var listeGroupes = new SearchListeGroupesColl();
    listeGroupes.typegroupe = typegroupe;
    listeGroupes.setHabContext(this.model.getHabContext());

    if (this.context.ctxModule == "agenda_R") {
      listeGroupes.params.matric = this.context.resultatsCollab;
    }

    if (!STR.isBlank(listeGroupes.typegroupe)) {
      listeGroupes.fetch({
        success: function(fresh) {
          if (fresh.length > 0) {
            self.newGroupe = fresh.models[0].get("code");
            if (callback) {
              callback();
            }
          }
        }
      });
    }
  },

  _initTable: function() {
    var self = this;
    var tableModel = new DataGridModel({ coll: new SearchResultatsListeCptColl() });
    var libelleWidth = 200;
    var valeurWidth = 50;
    var iconWidth = 25;
    // RGAA development
    if(this.theme.get("preferences") && !STR.isBlank(this.theme.get("preferences")[0]) && this.theme.get("preferences")[0].varchar1 === 'contrast.theme'){
      libelleWidth = 240;
      valeurWidth = 75;
      iconWidth = 65;
    }

    if (this.context.ctxEcran == "agenda") {
      libelleWidth = 55;
      valeurWidth = 56;
    }
    var tblColumns = [
      { title: i18n.t('common:resultatgrpcompteurs.libelle'), property: "libelle", width: libelleWidth },
      { title: i18n.t('common:resultatgrpcompteurs.valeur'), property: "valeur", width: valeurWidth },
      { title: i18n.t('common:resultatgrpcompteurs.type'), property: "type", width: 120 },
      { title: "", property: "type_icon", width: iconWidth }
    ];

    var table = null;
    if (this.context.ctxEcran == "agenda") {
      table = new DataGridView({
        id: "compteurs_table",
        columns: tblColumns,
        model: tableModel,
        gridHeightType: "nolimite",
        title: i18n.t('common:resultatgrpcompteurs.title_compteurs') + " " + TYPE.DATE.format(this.newDate)
      });
    } else {
      table = new DataGridView({
        id: "compteurs_table",
        columns: tblColumns,
        model: tableModel,
        gridHeightType: "nolimite",
        showFilteredRowsInTitle: true,
        parentContainer: this.parent,
        title: i18n.t('common:resultatgrpcompteurs.title_compteurs') + " " + TYPE.DATE.format(this.newDate)
      });
    }

    table.setSortableColumns();

    // Render the cells when a blank line is coming up
    table.cellRenderers["libelle"] = function(model) {
      if (model.get("libelle") === "Saut de ligne") {
        self.numSeparators++;
        return "";
      } else {
        var span = $("<span>").text(model.get("libelle"));
        if (self.context.ctxEcran == "agenda") {
          span.attr("title", model.get("libelle"));
        }
        return span;
      }
    };
    table.cellRenderers["valeur"] = function(model) {
      if (model.get("libelle") === "Saut de ligne") {
        return "";
      } else {
        var span = $("<span>").text(model.get("valeur"));
        if (model.get("valeur").indexOf("-") != -1) {
          span.addClass("ui-phx-valorisation-negative");
        }
        if (self.context.ctxEcran == "agenda") {
          span.attr("title", model.get("valeur"));
        }
        return span;
      }
    };
    table.cellRenderers["type"] = function(model) {
      if (model.get("libelle") === "Saut de ligne") {
        return "";
      } else {
        switch (model.get("type")) {
          case 4:
            return i18n.t('common:resultatgrpcompteurs.type4');
            break;
          case 5:
            return i18n.t('common:resultatgrpcompteurs.type5');
            break;
          default:
            return "";
        }
      }
    };
    table.cellRenderers["type_icon"] = function(model) {
      if (model.get("libelle") === "Saut de ligne") {
        return "";
      } else {
        switch (model.get("type")) {
          case 4:
            var span = $("<span>").addClass("phx-icon phx-icon-resultatgrpcompteurs-window").attr("title", i18n.t('common:resultatgrpcompteurs.type4'));
            return span;
            break;
          case 5:
            var span = $("<span>").addClass("phx-icon phx-icon-resultatgrpcompteurs-windows").attr("title", i18n.t('common:resultatgrpcompteurs.type5'));
            return span;
            break;
          default:
            return "";
        }
      }
    };

    table.setVisibleColumns(["libelle", "valeur"]);

    // override default method to show correct total number of rows
    table.overrideFilteredRowsInTitle = function() {
      return self.table.model.coll.length - self.numSeparators;
    };

    return table;
  },

  _renderTable: function() {
    var self = this;
    var json = { 'i18n': i18n, model: this.model.get("value") };
    $(this.el).html(this.template(json));
    $(this.el).find(".tableresultats").html(this.table.el);
    this.table.render();

    this.table.model.block("type_icon");

    if (this.parent && this.parent.resize) {
      this.parent.resize();
    }

    // Hide table footer
    this.table.setTotalRowsVisible(false);

    var heure = TYPE.DATE.dateToStr(SYNC.getServerDate(), "HH:mm", "", ":");

    // retrieve the matricule from the loged user
    var matricule = objs.appRt.workflow.authModel.get("matricule");

    var title = i18n.t('common:resultatgrpcompteurs.dialogGroupeCompteurs');
    var subtitle = null;

    var pdiver = GLOBAL_DATA.paramDivers.get("idCollab");

    if (self.context.ctxModule === "agenda_R") {
      title = i18n.t('common:resultatgrpcompteurs.situationcollab');

      if (self.context.ctxUser) {
        var user = self.context.ctxUser;
        var mat = user.get("aux");
        if (pdiver.get("valeur") === "matric") {
          mat = user.get("mat");
        }
        subtitle = user.get("nom") + " " + user.get("pre") + " (" + mat + ")";
      }
    }

    if (subtitle !== null) {
      $(self.el).find(".ctxGestionCollab").html(subtitle);
      $(self.el).find(".groupecompteurs-collab").show();
    } else {
      $(self.el).find(".groupecompteurs-collab").hide();
    }

    //Dialog compteurs
    var callbackViewGruopeCompteurs = function() {

      var compteursColl = new CompteursColl({ matricule: matricule });
      compteursColl.setHabContext(new HabilitationContext({
        onglet: "badgeage",
        fonc_cour: "COL_TPSREEL.V",
        nat_gest: ""
      }));

      compteursColl.fetch({
        success: function(fresh) {
          self.compteurInst = new BadgeageVirtuelDialogView({
            "collection": fresh,
            "heure": heure,
            "parent": self.parent,
            "title": title,
            "subtitle": i18n.t('common:resultatgrpcompteurs.compteurs') + " " + TYPE.DATE.dateToStr(SYNC.getServerDate(), "DD/MM/YYYY")
          });

          //Adjust size of dialog
          $(self.el).find(".tableCompteursInstant").html(self.compteurInst.render().el);
          //Force 7 lines max
          $(self.compteurInst.el).find(".phx-grid-scroll").css("height", "auto");
          $(self.compteurInst.el).find(".phx-grid-scroll").css("max-height", 162);
          $(self.compteurInst.el).css("height", "auto");
          $(self.compteurInst.el).css("max-height", 300);

          self._getOpenPreference(function(open) {
            if (open) {
              $(self.el).find(".tableCompteursInstant").show();
              $(self.el).find(".showCompteurs").hide();
              $(self.el).find(".hideCompteurs").show();
            } else {
              $(self.el).find(".tableCompteursInstant").hide();
              $(self.el).find(".hideCompteurs").hide();
              $(self.el).find(".showCompteurs").show();
            }

            self.parent.$el.parent(".ui-dialog").css("height", "auto");
            self.parent.$el.dialog("option", "position", { my: "center", at: "center", of: window });
            self.parent.$el.dialog("option", "resizable", false);
            self.parent.resize = null;
          });

        }
      });
    };

    var callbackNoViewGroupeCompteurs = function() {
      $(self.el).find(".showCompteurs").hide();
      $(self.el).find(".hideCompteurs").hide();
      $(self.el).find(".tableCompteursInstant").hide();
    };

    this._doItIfCanViewCompteurs(matricule, this.context.ctxModule, callbackViewGruopeCompteurs, callbackNoViewGroupeCompteurs);
  },

  _doItIfCanViewCompteurs: function(matricule, module, callbackYes, callbackNo) {
    var pdiver = GLOBAL_DATA.paramDivers.get("cptInsAffR");
    if ((matricule &&
        module !== "agenda_R" &&
        pdiver !== null &&
        parseInt(pdiver.get("valeur"), 10) === 1 &&
        HABILITATION.canView("COL_TPSREEL.V") &&
        !HABILITATION.canView("COL_BADGVIRT.G") &&
        this.origin === "header_valorisation")) {

      var badgeagemode = new ModeBadgModel({ matricule: matricule });
      badgeagemode.setHabContext(new HabilitationContext({
        onglet: "",
        fonc_cour: "",
        nat_gest: ""
      }));

      badgeagemode.fetch({
        success: function(modebadg) {
          //					if ((modebadg.get("modebadg") !== 0)) {
          if (callbackYes) {
            callbackYes();
          }
          //					}
        },
        error: function() {
          if (callbackNo) {
            callbackNo();
          }
        }
      });

    } else {
      if (callbackNo) {
        callbackNo();
      }
    }
  },

  _changeTitle: function() {
    this.table.setTitle(i18n.t('common:resultatgrpcompteurs.title_compteurs') + " " + TYPE.DATE.format(this.newDate));
  },

  _showConsultation: function() {
    var self = this;
    var dialogModel = new SearchListeGroupesColl();
    dialogModel.typegroupe = this.newTypeGroupe;
    dialogModel.setHabContext(this.model.getHabContext());

    this._refreshDivers();

    dialogModel.fetch({
      success: function(fresh) {
        //create the dialogView for change data
        var dialogModel = new SearchListeGroupesData();
        dialogModel.set("groupe", self.newGroupe);
        dialogModel.set("date", self.newDate);
        dialogModel.set("demande", self.newDemande);
        dialogModel.set("souhait", self.newSouhait);
        var dialogConsu = new DialogView({
          view: ConsultationDialogView,
          viewData: {
            model: dialogModel,
            comboOptions: fresh,
            configSouhait: self.configSouhait
          },
          dialogClass: 'dkt'
        });

        dialogConsu.setHeight(250);
        dialogConsu.setWidth(500);
        // set buttons
        var btn = [{
          text: i18n.t('common:resultatgrpcompteurs.appliquer'),
          click: function() {
            if (dialogConsu.viewData.model.isValid()) {
              self.popupResponse = dialogConsu.viewData.model.attributes;
              self._popupResponseCallback("y");
              $(this).dialog("close");
            }
          }
        }, {
          text: i18n.t('common:resultatgrpcompteurs.annuler'),
          click: function() {
            self._popupResponseCallback("n");
            $(this).dialog("close");
          }
        }];
        dialogConsu.open();
        dialogConsu.dialog.dialog("option", "buttons", btn);
      }
    });
  },

  _showBlockCompteurs: function() {
    this.$el.find(".showCompteurs").hide();
    this.$el.find(".hideCompteurs").show();
    this.$el.find(".tableCompteursInstant").show();
    //Resize set fixed height, return it to "auto" for correct visualization
    this.parent.$el.parent(".ui-dialog").css("height", "auto");
    this.parent.$el.dialog("option", "position", { my: "center", at: "center", of: window });

    this._saveOpenPreference(true);
  },

  _hideBlockCompteurs: function() {
    this.$el.find(".hideCompteurs").hide();
    this.$el.find(".showCompteurs").show();
    this.$el.find(".tableCompteursInstant").hide();
    //Resize set fixed height, return it to "auto" for correct visualization
    this.parent.$el.parent(".ui-dialog").css("height", "auto");
    this.parent.$el.dialog("option", "position", { my: "center", at: "center", of: window });

    this._saveOpenPreference(false);
  },

  _getOpenPreference: function(callback) {
    var open = false;
    var found = false;

    this.preferences = new Preferences();
    this.preferences.setHabContext(new HabilitationContext({
      onglet: this.context.ctxEcran,
      fonc_cour: "USERS",
    }));
    this.preferences.fetch({
      success: function(data) {
        var prefsArray = data.get("preferences");
        _.each(prefsArray, function(pref) {
          if (pref.code === "VALO_AFF_CPTINST") {
            found = true;
            open = pref.varint1 === 1;
          }
        });

        if (callback) {
          callback(open);
        }
      }
    });
  },

  _saveOpenPreference: function(openByDefault) {
    var preferencesArr = this.preferences.get("preferences");
    var found = false;
    _.each(preferencesArr, function(pref) {
      if (pref.code === "VALO_AFF_CPTINST") {
        found = true;
        pref.varint1 = openByDefault ? 1 : 0;
      }
    });

    if (!found) {
      var newpref = {
        code: "VALO_AFF_CPTINST",
        varchar1: "",
        varchar2: "",
        varchar3: "",
        varchar4: "",
        varchar5: "",
        vardat1: "",
        vardat2: "",
        vardat3: "",
        vardat4: "",
        vardat5: "",
        varint1: openByDefault ? 1 : 0,
        varint2: null,
        varint3: null,
        varint4: null,
        varint5: null
      }

      preferencesArr.push(newpref);
    }

    this.preferences.set("preferences", preferencesArr);
    this.preferences.save(null, {
      success: function(data) {
        LOG.debug(data);
      },
      error: function(data) {
        LOG.debug(data);
      }
    });
  },

  _popupResponseCallback: function(action) {
    if (action === "y") {
      if (this.popupResponse.date !== "") {
        this.newDate = this.popupResponse.date;
      }
      if (this.popupResponse.groupe !== "") {
        this.newGroupe = this.popupResponse.groupe;
      }

      this.newDemande = this.popupResponse.demande;
      this.newSouhait = this.popupResponse.souhait;

      this._updateTable();
    }
  },

  _refreshDivers: function() {
    if (this.configSouhait == undefined) {
      if (GLOBAL_DATA.paramDivers.get("SouhAbs") && GLOBAL_DATA.paramDivers.get("SouhAbs").get("valeur") === "1") {
        this.configSouhait = true;
      } else {
        this.configSouhait = false;
      }
    }
    if (GLOBAL_DATA.paramDivers.get("demabs_sit") && GLOBAL_DATA.paramDivers.get("demabs_sit").get("valeur") === "1") {
      this.newDemande = true;
      this.newSouhait = true;
    } else {
      this.newDemande = false;
      this.newSouhait = false;
    }
  }

});
