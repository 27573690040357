import { ActivitesModel } from '../models/activites.collection.js';
import { TypologiesDomaineModel } from '../../../../referentiel/gestion_de_activite/activites/typodom/models/typologiesDomaine.model';
import { DemandesCollaborateursModel } from '../models/demandesCollaborateurs.model.js';
import { EvenementsGeneriqueModel } from '../models/evenementsGenerique.collection.js';
import { GLOBAL_DATA } from '../../../../../globalData.js';
import { HABILITATION } from '../../../../../utils/habilitation.js';
import { HabilitationContext } from '../../../../../core/models/habilitationContext.js';
import { i18n } from '../../../../../i18n.js';
import { NAV } from '../../../../../utils/nav.js';
import { STR } from '../../../../../utils/str.js';
import TPL_bdemcoll_briqueDemandesCollaborateurs from '../briqueDemandesCollaborateurs.tpl.html';
import { forkJoin } from 'rxjs';

/**
 * View of Brique 'Demandes Collaborateurs'
 */
export var DemandesCollaborateursView = Backbone.View.extend({

  _maxLines: 10,

  events: {
    "click tbody tr": "_linkToValidation"
  },

  initialize: function(options) {
    this.usePopulation = false;
    if (options && options.usePopulation) {
      this.usePopulation = options.usePopulation;
    }
    this.template = TPL_bdemcoll_briqueDemandesCollaborateurs;

    this.tablebriqueDemandesCollaborateurs = null;
    this.styleLabel = "phx-label-style";
    this.styleDate = "phx-bold-input-style";
    this.styleHoure = "phx-input-style";
    this.styleEnCours = "phx-input-encours-style";
    this.parent = null;

    this.habContextAbsence = new HabilitationContext({
      onglet: "bdemcoll",
      fonc_cour: "RES_VAL_ABS.G",
      nat_gest: ""
    });
    this.modelAbsence = new DemandesCollaborateursModel();
    this.modelAbsence.usePopulation = this.usePopulation;
    this.modelAbsence.typeevenement = "ABSENCE";
    this.modelAbsence.setHabContext(this.habContextAbsence);

    this.habContextSouhaitAbs = new HabilitationContext({
      onglet: "bdemcoll",
      fonc_cour: "RES_VAL_SHT.G",
      nat_gest: ""
    });

    this.modelSouhaitAbs = new DemandesCollaborateursModel();
    this.modelSouhaitAbs.usePopulation = this.usePopulation;
    this.modelSouhaitAbs.typeevenement = "SOUHAITABS";
    this.modelSouhaitAbs.setHabContext(this.habContextSouhaitAbs);

    this.habContextRegularisation = new HabilitationContext({
      onglet: "bdemcoll",
      fonc_cour: "RES_VAL_REG.G",
      nat_gest: ""
    });

    this.modelRegularisation = new DemandesCollaborateursModel();
    this.modelRegularisation.usePopulation = this.usePopulation;
    this.modelRegularisation.typeevenement = "REGULARISATION";
    this.modelRegularisation.setHabContext(this.habContextRegularisation);

    this.habContextBadgeage = new HabilitationContext({
      onglet: "bdemcoll",
      fonc_cour: "RES_VAL_BDG.G",
      nat_gest: ""
    });

    this.modelBadgeage = new DemandesCollaborateursModel();
    this.modelBadgeage.usePopulation = this.usePopulation;
    this.modelBadgeage.typeevenement = "BADGEAGE";
    this.modelBadgeage.setHabContext(this.habContextBadgeage);

    this.habContextRecapitulatif = new HabilitationContext({
      onglet: "bdemcoll",
      fonc_cour: "RES_VAL_RECAP.G",
      nat_gest: ""
    });
    this.modelRecapitulatif = new DemandesCollaborateursModel();
    this.modelRecapitulatif.usePopulation = this.usePopulation;
    this.modelRecapitulatif.typeevenement = "ACTRECAP";
    this.modelRecapitulatif.setHabContext(this.habContextRecapitulatif);

    this.habContextPretsValider = new HabilitationContext({
      onglet: "bdemcoll",
      fonc_cour: "RES_VAL_PRET.G",
      nat_gest: ""
    });
    this.modelPretsValider = new DemandesCollaborateursModel();
    this.modelPretsValider.usePopulation = this.usePopulation;
    this.modelPretsValider.typeevenement = "PRET";
    this.modelPretsValider.setHabContext(this.habContextPretsValider);

    this.habContextEvtgen = new HabilitationContext({
      onglet: "bdemcoll",
      fonc_cour: "RES_VAL_EGEN.G",
      nat_gest: ""
    });

    this.modelEvtgen = new EvenementsGeneriqueModel();
    this.modelEvtgen.usePopulation = this.usePopulation;
    this.modelEvtgen.typeevenement = "EVTGEN";
    this.modelEvtgen.setHabContext(this.habContextEvtgen);

    var activiteHabilitationObj = { actprev: "RES_VAL_ACTPR.G", actreal: "RES_VAL_ACTRE.G" }

    this.typologiesActivites = new HabilitationContext({
      onglet: "bdemcoll",
      fonc_cour: activiteHabilitationObj,
      nat_gest: ""
    });

    this.modelTypologiesActivites = new ActivitesModel();
    this.modelTypologiesActivites.usePopulation = this.usePopulation;
    this.modelTypologiesActivites.setHabContext(this.typologiesActivites);

    this.modelTypologiesDomaines = new TypologiesDomaineModel();
    this.modelTypologiesDomaines.usePopulation = this.usePopulation;
    this.modelTypologiesDomaines.setHabContext(new HabilitationContext({
      onglet: "bdemcoll",
      fonc_cour: "PAR_TYPODOM.V",
      nat_gest: ""
    }));
    if (HABILITATION.canView("PAR_TYPODOM.V") || HABILITATION.canView("PAR_TYPODOM.G")) {
      this.modelTypologiesDomaines.fetch();
    }

    if (options && options.parent) {
      this.parent = options.parent;
    }

    // retrieve parametre divers for souhait  (SouhAbs)
    this.parametreDiversSouhabsModel = GLOBAL_DATA.paramDivers.get("SouhAbs");
  },

  render: function() {
    var json = { i18n: i18n };
    $(this.el).html(this.template(json));
    return this;
  },

  refreshBrique: function() {
    var l_promises = [];
    var self = this;

    $(this.el).find(".table_BriqueDemandesCollaborateurs").html(i18n.t('messages:GT_1098'));
    if (HABILITATION.canView("RES_VAL_ABS.G")) {
      l_promises.push(this.modelAbsence.fetch());
    }
    if (self.parametreDiversSouhabsModel.get("valeur") === "1" && HABILITATION.canView("RES_VAL_SHT.G")) {
      l_promises.push(this.modelSouhaitAbs.fetch());
    }
    if (HABILITATION.canView("RES_VAL_BDG.G")) {
      l_promises.push(this.modelBadgeage.fetch());
    }
    if (HABILITATION.canView("RES_VAL_REG.G")) {
      l_promises.push(this.modelRegularisation.fetch());
    }
    if (HABILITATION.canView("RES_VAL_RECAP.G")) {
      l_promises.push(this.modelRecapitulatif.fetch());
    }
    if (HABILITATION.canView("RES_VAL_PRET.G")) {
      l_promises.push(this.modelPretsValider.fetch());
    }
    if (HABILITATION.canView("RES_VAL_EGEN.G")) {
      if (!STR.isBlank(this.usePopulation) && this.usePopulation) {
        // Join population parameter
        l_promises.push(this.modelEvtgen.fetch({ data: { "filtre": true } }));
      } else {
        l_promises.push(this.modelEvtgen.fetch());
      }
    }
    if (HABILITATION.canView("RES_VAL_ACTPR.G") || HABILITATION.canView("RES_VAL_ACTRE.G")) {
      l_promises.push(this.modelTypologiesActivites.fetch());
    }
    if (!_.isEmpty(l_promises)) {
      forkJoin(l_promises).subscribe(function() {
          self.parent.setTitle(i18n.t('common:bdemcoll.DemandesCollaborateurs'));
          self.renderTable();
          self.delegateEvents();
        },
        function() {
          //   Lorsque il y a des errors , par exemple on peut utiliser  self._logout();
        });
    }
    return this;
  },

  renderTable: function() {
    var coll = this._renderResponse(this.model);

    //create the columns for the table
    var tblColumns = [
      { title: "demandesCollaborateurs", property: "demandesCollaborateurs", width: 45 }
    ];
    //create the table witdh the collection data and collection titles
    if (coll.length > 0) {
      var table = this._createTableOfColl(coll, tblColumns, "tableBriqueDemandesCollaborateurs");

      $(this.el).find(".table_BriqueDemandesCollaborateurs").html(table);
    } else {
      $(this.el).find(".table_BriqueDemandesCollaborateurs").html(i18n.t('messages:GT_1098'));
    }

    var rowHeight = $(this.el).find(".table_BriqueDemandesCollaborateurs tr").outerHeight();

    //Value for Default
    if (rowHeight < 7) {
      rowHeight = 19;

    }
    if (rowHeight !== null && this.parent !== null) {
      this.parent.setContentHeight(rowHeight * this._maxLines);
    }
  },

  _renderResponse: function() {
    var coll = [];
    var i = 0;
    var message = "";
    var absNb = this.modelAbsence.get("valeur");
    var badgNb = this.modelBadgeage.get("valeur");
    var regNb = this.modelRegularisation.get("valeur");
    var recapNb = this.modelRecapitulatif.get("valeur");
    var pretsvalNb = this.modelPretsValider.get("valeur");
    var activitesNb = _.size(this.modelTypologiesActivites.models);
    var evenGenerNb = _.size(this.modelEvtgen.models);
    var evenGener = this.modelEvtgen;
    var souhNb = 0;

    this.tooltip = [];
    if (this.parametreDiversSouhabsModel.get("valeur") === "1") {
      souhNb = this.modelSouhaitAbs.get("valeur");
    }
    //if (absNb + badgNb + regNb + recapNb + pretsvalNb + souhNb + activitesPrevNb + activitesRealNb > 0){
    if (absNb + badgNb + regNb + recapNb + pretsvalNb + souhNb + activitesNb + evenGenerNb > 0) {
      var nbAbsAndSouhait = absNb + souhNb;
      var abs = "<span class='phx-bold-label-style'>" + absNb + "</span>";
      var absAndSouhait = "<span class='phx-bold-label-style'>" + nbAbsAndSouhait + "</span>";
      var souh = "<span class='phx-bold-label-style'>" + souhNb + "</span>";
      var badg = "<span class='phx-bold-label-style'>" + badgNb + "</span>";
      var reg = "<span class='phx-bold-label-style'>" + regNb + "</span>";
      var recap = "<span class='phx-bold-label-style'>" + recapNb + "</span>";
      var pretsval = "<span class='phx-bold-label-style'>" + pretsvalNb + "</span>";
      var listeGenerique = [];
      var index = 0;
      var prevue = null;
      var realisee = null;

      // add link to entries greater than 0
      this.links = []; //["dabsences","dentresort", "dregularisations"];
      for (i = 0; i < evenGenerNb; i++) {
        if (evenGener.models[i]) {
          message = "<span class='phx-bold-label-style'>" + evenGener.models[i].get("valeur") + "</span>";
          listeGenerique.push({
            message: message,
            value: evenGener.models[i]
          });
        }
      }

      // Display for absence and souhait
      if (this.parametreDiversSouhabsModel.get("valeur") === 1) {
        if (HABILITATION.canView("RES_VAL_SHT.G") || HABILITATION.canView("RES_VAL_ABS.G") &&
          nbAbsAndSouhait > 0) {
          this.links[index] = (nbAbsAndSouhait > 0 ? "dabsences" : "");
        }

        if (absNb > 0 && souhNb === 0) {
          this.tooltip[index] = i18n.t('common:bdemcoll.messageAbsenceSouhait');
          coll[index++] = { demandesCollaborateurs: i18n.t('messages:GT_1099', { "1": abs, interpolation: { "escapeValue": false } }) };
        } else if (absNb > 0 && souhNb > 0) {
          this.tooltip[index] = i18n.t('common:bdemcoll.messageAbsenceSouhait');
          coll[index++] = { demandesCollaborateurs: i18n.t('messages:GT_1169', { "1": absAndSouhait, interpolation: { "escapeValue": false } }) };
        } else if (absNb === 0 && souhNb > 0) {
          this.tooltip[index] = i18n.t('common:bdemcoll.messageAbsenceSouhait');
          coll[index++] = { demandesCollaborateurs: i18n.t('messages:GT_1177', { "1": souh, interpolation: { "escapeValue": false } }) };
        }
      } else {
        if (HABILITATION.canView("RES_VAL_ABS.G")) {
          if (absNb > 0) {
            this.links[index] = (absNb > 0 ? "dabsences" : "");
            this.tooltip[index] = i18n.t('common:bdemcoll.messageAbsenceSouhait');
            coll[index++] = { demandesCollaborateurs: i18n.t('messages:GT_1099', { "1": abs, interpolation: { "escapeValue": false } }) };
          }
        }
      }
      // Display for badgeage
      if (HABILITATION.canView("RES_VAL_BDG.G")) {
        if (badgNb > 0) {
          this.links[index] = (badgNb > 0 ? "dentresort" : "");
          this.tooltip[index] = i18n.t('common:bdemcoll.messageBadgeage');
          coll[index++] = { demandesCollaborateurs: i18n.t('messages:GT_1100', { "1": badg, interpolation: { "escapeValue": false } }) };
        }
      }
      // Display for regularisation
      if (HABILITATION.canView("RES_VAL_REG.G") && regNb > 0) {
        this.links[index] = (regNb > 0 ? "dregularisations" : "");
        this.tooltip[index] = i18n.t('common:bdemcoll.messageRegularisation');
        coll[index++] = { demandesCollaborateurs: i18n.t('messages:GT_1101', { "1": reg, interpolation: { "escapeValue": false } }) };
      }
      // Display for recapitulatif
      if (HABILITATION.canView("RES_VAL_RECAP.G") && recapNb > 0) {
        this.links[index] = (recapNb > 0 ? "drecapitulatif" : "");
        this.tooltip[index] = i18n.t('common:bdemcoll.messageRecapitulatif');
        coll[index++] = { demandesCollaborateurs: i18n.t('messages:GT_1372', { "1": recap, interpolation: { "escapeValue": false } }) };
      }
      // Display for Prets a valider
      if (HABILITATION.canView("RES_VAL_PRET.G") && pretsvalNb > 0) {
        this.links[index] = (pretsvalNb > 0 ? "dprets" : "");
        this.tooltip[index] = i18n.t('common:bdemcoll.messagePrets');
        coll[index++] = { demandesCollaborateurs: i18n.t('messages:GT_1399', { "1": pretsval, interpolation: { "escapeValue": false } }) };
      }
      //Display evenements génériques
      if (HABILITATION.canView("RES_VAL_EGEN.G") && listeGenerique.length > 0) {
        for (i = 0; i < listeGenerique.length; i++) {
          this.links[index] = (listeGenerique[i].value.get("valeur") > 0 ? "devenements/" + encodeURIComponent(listeGenerique[i].value.get("code")) : "");
          this.tooltip[index] = i18n.t('common:bdemcoll.messageEvenGeneriques');
          coll[index++] = { demandesCollaborateurs: i18n.t('messages:GT_2012', { "1": listeGenerique[i].message, "2": listeGenerique[i].value.get("libelle"), interpolation: { "escapeValue": false } }) };
        }
      }

      var genre = "";

      for (i = 0; i < activitesNb; i++) {
        var listeActivites = this.modelTypologiesActivites.models;
        var listeTypologiesDomaines = this.modelTypologiesDomaines;
        var messagePrev = "<span class='phx-bold-label-style'>" + listeActivites[i].get("nb_actprev") + "</span>";
        var messageReal = "<span class='phx-bold-label-style'>" + listeActivites[i].get("nb_actreal") + "</span>";

        var j = 0;
        if (HABILITATION.canView("RES_VAL_ACTPR.G") && activitesNb > 0) {
          if (parseInt(listeActivites[i].get("nb_actprev")) > 0) {
            genre = this._getActiviteGenre(listeActivites[i].get("code"));
            let intitule = null;
            for (j = 0; j < _.size(listeTypologiesDomaines.attributes); j++) {
              if (!STR.isBlank(listeTypologiesDomaines.get(j)) && listeActivites[i].get("code") === listeTypologiesDomaines.get(j).code) {
                if (parseInt(listeActivites[i].get("nb_actprev")) > 1) {
                  intitule = listeTypologiesDomaines.get(j).regroupement;
                  if (genre === "F") {
                    prevue = i18n.t('messages:GT_1574'); // prévues
                  } else {
                    prevue = i18n.t('messages:GT_1572'); // prévus
                  }
                } else {
                  intitule = listeTypologiesDomaines.get(j).evenement;
                  if (genre === "F") {
                    prevue = i18n.t('messages:GT_1573'); // prévue
                  } else {
                    prevue = i18n.t('messages:GT_1571'); // prévu
                  }
                }
                // prevue = genre === "F" ? i18n.t('common:bdemcoll.prevue' + plural) : i18n.t('common:bdemcoll.prevu' + plural);
                this.links[index] = (parseInt(listeActivites[i].get("nb_actprev")) > 0 ? "dactivites/" + listeActivites[i].get("code") + "/P" : "");
                this.tooltip[index] = i18n.t('common:bdemcoll.messageActivitesPrevues');
                coll[index++] = { demandesCollaborateurs: i18n.t('messages:GT_2012', { "1": messagePrev, "2": intitule + " " + prevue, interpolation: { "escapeValue": false } }) };
                break;
              }
            }
          }
        }

        var k = 0;
        if (HABILITATION.canView("RES_VAL_ACTRE.G") && activitesNb > 0) {
          if (parseInt(listeActivites[i].get("nb_actreal")) > 0) {
            genre = this._getActiviteGenre(listeActivites[i].get("code"));
            let intitule = null;
            for (k = 0; k < _.size(listeTypologiesDomaines.attributes); k++) {
              if (!STR.isBlank(listeTypologiesDomaines.get(k)) && listeActivites[i].get("code") === listeTypologiesDomaines.get(k).code) {
                if (parseInt(listeActivites[i].get("nb_actreal")) > 1) {
                  intitule = listeTypologiesDomaines.get(k).regroupement;
                  if (genre === "F") {
                    realisee = i18n.t('messages:GT_1578'); //réalisés
                  } else {
                    realisee = i18n.t('messages:GT_1576'); //réalisés
                  }
                } else {
                  intitule = listeTypologiesDomaines.get(k).evenement;
                  if (genre === "F") {
                    realisee = i18n.t('messages:GT_1577'); //réalisée
                  } else {
                    realisee = i18n.t('messages:GT_1575'); //réalisé
                  }
                }
                // realisee = genre === "F" ? i18n.t('common:bdemcoll.realisee' + plural) : i18n.t('common:bdemcoll.realise' + plural);
                this.links[index] = (parseInt(listeActivites[i].get("nb_actreal")) > 0 ? "dactivites/" + listeActivites[i].get("code") + "/R" : "");
                this.tooltip[index] = i18n.t('common:bdemcoll.messageActivitesRealises');
                coll[index++] = { demandesCollaborateurs: i18n.t('messages:GT_2012', { "1": messageReal, "2": intitule + " " + realisee, interpolation: { "escapeValue": false } }) };
                break;
              }
            }
          }
        }
      }

    }
    return coll;
  },

  _createTableOfColl: function(coll, tblColumns, id) {
    var showTitle = false;
    var table = "";

    table = "<table id='" + id + "' class='ui-grid-table phx-grid ui-grid'>";

    //paint the titles
    if (showTitle) {
      table += "<thead class='ui-grid-header'>";
      table += "<tr>";
      _.each(tblColumns, function(column, index) {
        table += "<th class='phx-th" + index + " ui-grid-th " +
          "ui-grid-th-RightBorder'style='width:" + column.width + "px;'>";
        table += "<div style='text-align:left'><span>" + column.title + "</span></div>";
        table += "</th>";

      });
      table += "</tr>";
      table += "</thead>";
    }

    //paint the data
    table += "<tbody class='ui-grid-body'>";
    for (var i = 0; i < coll.length; i++) {
      table += "<tr class='" + this.links[i] + " ui-grid-row'>";
      for (var j = 0; j < tblColumns.length; j++) {

        table += "<td class='" + this.links[i] + "' title='" + this.tooltip[i] + "' phx-td" + j + "' style='width:" + tblColumns[j].width + "px;'>";
        table += coll[i][tblColumns[j].property];
        table += "</td>";
      }
      table += "</tr>";
    }
    table += "</tbody>";

    return table;
  },

  _linkToValidation: function(event) {
    var target = event.currentTarget;
    var ecran = target.className.split(" ")[0];

    NAV.navigate("resp", ecran, "uc", true, true);
  },

  _getActiviteGenre: function(code) {
    var typologies = GLOBAL_DATA.typologies;
    var typo = typologies._byId[code];
    if (typo) {
      return typo.get("genre");
    }
    return "";
  }
});
