import { BaseModel } from '../../../../../core/models/base.model.js';
import { DataGridModel } from '../../../../../core/grids/data_grid/dataGrid.model.js';
import { DataGridView } from '../../../../../core/grids/data_grid/dataGrid.view.js';
import { GLOBAL_DATA } from '../../../../../globalData.js';
import { i18n } from '../../../../../i18n.js';
import { STR } from '../../../../../utils/str.js';
import TPL_referentielcollaborateur_detailSelecteurRefDialog from '../detailSelecteurRefDialog.tpl.html';

/**
 * Selecteur Referentiel Dialog View
 */
export var SelecteurRefDialogView = Backbone.View.extend({

  searchTerm: "",

  events: {
    "change input.collab-recherche-simulation-chk": "_checkboxSimulation"
  },

  initialize: function(options) {
    this.template = TPL_referentielcollaborateur_detailSelecteurRefDialog;

    this.model = new BaseModel();
    this.parent = options.parent;

    if (!_.isUndefined(options) && !_.isUndefined(options.coll)) {
      this.coll = options.coll;
      this.coll.applyFilter({ "search": null });
    }

    this.diversIdCollab = GLOBAL_DATA.paramDivers.get("idCollab").get("valeur");

    this.table = this._initTable();
    this.table.model.on("change:value", this.selection, this);
    this.table.model.coll.on("row:dblclick", this._selectionAndClose, this);
    this.listenTo(this.parent.model, "dlg:beforeclose", this._closeEvent, this);
  },

  _closeEvent: function() {
    // If mode simulated, check by default when complet view is closed
    if (!STR.isBlank(this.coll.id_simulation)) {
      this.coll.aff_collabsimu = true;
    }
  },

  selection: function() {
    var selection = this.table.model.get("value");
    this.trigger("response", selection);

    return selection;
  },

  _selectionAndClose: function() {
    this.trigger("close", "Y");
  },

  _manageTable: function() {
    var currentValue = this.selection();
    this.table.model.resetRowHeight();

    var self = this;
    this.table.model.coll.applyFilter({ "search": this.searchTerm });
    this.table.model.coll.fetch({
      success: function() {
        if (!STR.isBlank(currentValue)) {
          self.table.model.selectRow(currentValue);
        }
      }
    });
  },

  render: function() {
    var json = { "i18n": i18n };
    this.$el.html(this.template(json));

    if (!STR.isBlank(this.coll.id_simulation)) {
      this.coll.activeSimulation = true;

      var type_collab = this.coll.type_collab;
      var label_container = $(this.el).find("label[for=collab-recherche-simulation-chk]");
      switch (type_collab) {
        case "R":
          label_container.html(i18n.t('referentielcollaborateur.simulation_reels'));
          break;
        case "V":
          label_container.html(i18n.t('referentielcollaborateur.simulation_virtuels'));
          break;
        default:
          label_container.html(i18n.t('referentielcollaborateur.simulation_uniquement'));
      }
      $(this.el).find(".collab-recherche-simulation").show();
      $(this.el).find(".collab-recherche-simulation-chk").prop("checked", true);
    } else {
      $(this.el).find(".collab-recherche-simulation").hide();
    }

    $(this.el).find(".referentielcollaborateur-table").html(this.table.render().el);

    this._manageTable();

    var self = this;
    $(this.el).find(".criteres").autocomplete({
      minLength: 0,
      source: function(request) {
        self.searchTerm = request.term;

        self._manageTable();

        return false;
      }
    });
    return this;
  },

  _checkboxSimulation: function(event) {
    var simulated = $(event.currentTarget).prop("checked");
    this.coll.aff_collabsimu = simulated;
    this._manageTable();
  },

  _initTable: function() {
    var self = this;
    var collaborateurColl = this.coll;

    var tableModel = new DataGridModel({
      coll: collaborateurColl,
      enableSelectionOnDblclick: true
    });

    var tblColumns = [
      { title: i18n.t('referentielcollaborateur.nom'), property: "nom", width: 160 },
      { title: i18n.t('referentielcollaborateur.prenom'), property: "prenom", width: 160 },
      { title: i18n.t('referentielcollaborateur.matric'), property: "matric", width: 100 }
    ];

    var table = new DataGridView({
      id: "selectionnercollab_table",
      title: i18n.t('referentielcollaborateur.title_table'),
      columns: tblColumns,
      model: tableModel,
      showFilteredRowsInTitle: true,
      gridHeightType: "auto",
      parentContainer: this.parent
    });

    table.cellRenderers["matric"] = function(model) {
      if (self.diversIdCollab == "matric_aux") {
        return model.get("matricaux");
      } else {
        return model.get("matric");
      }
    };
    return table;
  }
});