/**
 * Intern Object that represents a table column.
 */
export var InternColumnModel = function() {

  /**
   * The view of the Header cell.
   */
  this.header = null;
  /**
   * The view of the aux Header fake cell.
   */
  this.headerFake = null;
  /**
   * View of the current editable cell.
   */
  this.editableCell = null;
  /**
   * Array of the cells related to the column.
   */
  this.cells = [];
  /**
   * If the column is currently visible or not.
   */
  this.visible = true;
  /**
   * If the column is showed on the menu Chooser.
   */
  this.omitMenu = false;
  /**
   * If the column radio in menu is marked or not.
   */
  this.radioOff = false;

  /**
   * If the column is selectable to sortingby it.
   */
  this.sortable = true;
  /**
   * If the grid is currently being sorting by this column.
   */
  this.sort = null;
  /**
   * With of the column
   */
  this.width = 100;
};

_.extend(InternColumnModel.prototype, {
  /**
   * Hide the Column.
   */
  hide: function() {
    $(this.header.el).hide();
    $(this.headerFake.el).hide();
    $(this.editableCell).hide();
    _.each(this.cells, function(cell) {
      $(cell.el).hide();
    });
  },

  /**
   * Hide the Column with Chrome.
   */
  hide_in_chrome: function() {
    $(this.header.el).css({ 'backface-visibility': 'hidden', 'display': 'inherit' });
    $(this.headerFake.el).css({ 'backface-visibility': 'hidden', 'display': 'inherit' });
    $(this.editableCell).css({ 'backface-visibility': 'hidden', 'display': 'inherit' });
    _.each(this.cells, function(cell) {
      $(cell.el).css({ 'backface-visibility': 'hidden', 'display': 'inherit' });
    });
    var self = this;
    window.setTimeout(function() {
      $(self.header.el).css({ 'backface-visibility': 'hidden', 'display': 'none' });
      $(self.headerFake.el).css({ 'backface-visibility': 'hidden', 'display': 'none' });
      $(self.editableCell).css({ 'backface-visibility': 'hidden', 'display': 'none' });
      _.each(self.cells, function(cell) {
        $(cell.el).css({ 'backface-visibility': 'hidden', 'display': 'none' });
      });
    }, 10);
  },

  hideHeader: function() {
    $(this.header.el).hide();
    $(this.headerFake.el).hide();
  },

  /**
   * Reset the Column width to its original value.
   */
  setOriginalWidth: function() {
    // Set column width to auto
    $(this.header.el).css("width", this.width + "px");
    $(this.header.el).find(".ui-resizable").css("width", "100%");
    $(this.headerFake.el).css("width", this.width + "px");
  },

  /**
   * Set column width to auto
   */
  setWidthAuto: function() {
    $(this.header.el).css("width", "auto");
    $(this.header.el).find(".ui-resizable").css("width", "100%");
    $(this.headerFake.el).css("width", "auto");
  },

  /**
   * Show the Column
   */
  show: function() {
    $(this.header.el).show();
    $(this.headerFake.el).show();
    $(this.editableCell).show();
    _.each(this.cells, function(cell) {
      $(cell.el).show();
    });
  },

  /**
   * Show the Column with Chrome.
   */
  show_in_chrome: function() {
    $(this.header.el).css({ 'backface-visibility': '', 'display': 'none' });
    $(this.headerFake.el).css({ 'backface-visibility': '', 'display': 'none' });
    $(this.editableCell).css({ 'backface-visibility': '', 'display': 'none' });
    _.each(this.cells, function(cell) {
      $(cell.el).css({ 'backface-visibility': '', 'display': 'none' });
    });
    var self = this;
    window.setTimeout(function() {
      $(self.header.el).css({ 'backface-visibility': 'visible', 'display': '' });
      $(self.headerFake.el).css({ 'backface-visibility': 'visible', 'display': '' });
      $(self.editableCell).css({ 'backface-visibility': 'visible', 'display': '' });
      _.each(self.cells, function(cell) {
        $(cell.el).css({ 'backface-visibility': 'visible', 'display': '' });
      });
    }, 10);
  },

  /**
   * Show the resizeable div to allow the user resize the column.
   */
  showResizeable: function() {
    // Show resizeable div
    $(this.header.el).find(".ui-resizable-handle").show();
  },

  /**
   * Hide the resizeable div to deny the user to resize the column.
   */
  hideResizeable: function() {
    $(this.header.el).find(".ui-resizable-handle").hide();
  },

  /**
   * Add the ui class that shows the right border to the header.
   */
  showRightMargin: function() {
    $(this.header.el).addClass("ui-grid-th-RightBorder");
  },

  /**
   * Removes the ui class that shows the right border to the header.
   */
  hideRightMargin: function() {
    $(this.header.el).removeClass("ui-grid-th-RightBorder");
  },

  /**
   * Called when backbone removes the view.
   */
  remove: function() {
    if (this.header) {
      this.header.remove();
    }
    if (this.headerFake) {
      this.headerFake.remove();
    }
    delete this.header;
    delete this.headerFake;
    delete this.editableCell;
    var cellsLength = this.cells.length;
    for (var i = 0; i < cellsLength; i++) {
      this.cells[i].remove();
    }
    this.cells.splice(0, cellsLength);
    delete this.cells;
    delete this.visible;
    delete this.sortable;
    delete this.sort;
    delete this.radioOff;
  }
});
