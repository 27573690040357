module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<div class="bvMenu" aria-labeledby="bvMenuHButtonBadge">\n  <span class="bvMenuHeader1 phx-menu" tabindex="0">\n      <button type="button" class="save uix-button-special" value="save" style="margin-right: 12px;">\n        <span class="bvMenuHButtonBadge text-button">'+
((__t=(i18n.t('common:bvMenu_badge_button')))==null?'':__t)+
'</span>\n      </button>\n      <span>à</span> \n      <span class="bvMenuHeader1_hour"></span>\n  </span>\n  <span class="bvMenuHeader2" tabindex="0">\n    <button type="button" class="save uix-button-special" value="save" style="margin-right: 12px;">\n      <span class="bvMenuHButtonRentre text-button">'+
((__t=(i18n.t('common:bvMenu_rentre')))==null?'':__t)+
'</span>\n    </button>\n    <button type="button" class="save uix-button" value="save" style="margin-right: 12px;">\n      <span class="bvMenuHButtonSors text-button">'+
((__t=(i18n.t('common:bvMenu_sors')))==null?'':__t)+
'</span>\n    </button>\n    <span>à</span>\n    <span class="bvMenu_heure"></span>\n  </span>\n  <span class="bvMenuButton phx-menu-icon ui-corner-all ui-icon ui-icon-triangle-1-s phx-hover" tabindex="0" role="menu"></span>\n  <ul class="bvMenuContent"></ul>\n</div>\n';
}
return __p;
};
