import { DataGridModel } from '../../../../../core/grids/data_grid/dataGrid.model.js';
import { DataGridView } from '../../../../../core/grids/data_grid/dataGrid.view.js';
import { i18n } from '../../../../../i18n.js';
import { STR } from '../../../../../utils/str.js';
import TPL_referentielutilisateur_detailSelecteurRefDialog from '../detailSelecteurRefDialog.tpl.html';

/**
 * Selecteur Referentiel Dialog View
 */
export var SelecteurRefDialogView = Backbone.View.extend({

  tagName: "div",
  className: "0",

  initialize: function(options) {
    this.template = TPL_referentielutilisateur_detailSelecteurRefDialog;

    if (!_.isUndefined(options) && !_.isUndefined(options.coll)) {
      this.coll = options.coll;
    }

    this.excludedItems = options.excludedItems;
    // this.excludedMySelf = options.excludedMySelf;

    // Create table
    this.table = this._initTable();
    this.table.model.on("change:value", this.selection, this);
    this.searchTerm = "";
    this.table.model.coll.on("row:dblclick", this._selectionAndClose, this);
  },

  _manageTable: function() {
    var currentValue = this.selection();
    this.table.model.resetRowHeight();

    var self = this;
    this.table.model.coll.applyFilter({ "search": this.searchTerm });

    this.table.model.coll.fetch({
      success: function() {
        if (!STR.isBlank(currentValue)) {
          self.table.model.selectRow(currentValue);
        }
        if (self.excludedItems) {
          var models = _.reject(self.table.model.coll.models, function(value) {
            return !_.contains(self.excludedItems, value.id);
          });
          self.table.model.coll.remove(models);
          self.table.model.coll.trigger("reset");
        }
        /*
        if (self.excludedMySelf) {
          var modelsB = _.filter(self.table.model.coll.models, function(value) {
            return _.contains(self.excludedMySelf, value.get('utilcode'));
          });
          self.table.model.coll.remove(modelsB);
          self.table.model.coll.trigger("reset");
        }
        */
      }
    });
  },

  render: function() {
    var json = { 'i18n': i18n };
    this.$el.html(this.template(json));

    // Render table
    $(this.el).find(".table_selecteurref_utilisateur").html(this.table.render().el);

    this._manageTable();

    var self = this;
    $(this.el).find(".criteres").autocomplete({
      minLength: 0,
      source: function(request) {
        self.searchTerm = request.term;
        self._manageTable();
        return false;
      }
    });

    return this;
  },

  selection: function() {
    var selection = this.table.model.get("value");
    this.trigger("response", selection);
    return selection;
  },

  _selectionAndClose: function() {
    this.trigger("close", "Y");
  },

  _initTable: function() {
    var tableModel = new DataGridModel({
      coll: this.coll,
      enableSelectionOnDblclick: true
    });

    var messCollabMat = i18n.t('droitsdel.collabMatricule1') + "<br/>" + i18n.t('droitsdel.collabMatricule2');
    var messUtilCode = i18n.t('droitsdel.utilCode1') + "<br/>" + i18n.t('droitsdel.utilCode2');
    var tableColumns = [
      { title: messCollabMat, property: "matricule", width: 100 },
      { title: i18n.t('droitsdel.collabMatriculeaux'), property: "matriculeaux", width: 100 },
      { title: i18n.t('droitsdel.collabNom'), property: "nom", width: 100 },
      { title: i18n.t('droitsdel.collabPrenom'), property: "prenom", width: 150 },
      { title: messUtilCode, property: "utilcode", width: 100 },
      { title: i18n.t('droitsdel.utilNom'), property: "utilnom", width: 150 }
    ];

    var table = new DataGridView({
      id: "droitsdel",
      columns: tableColumns,
      model: tableModel,
      title: i18n.t('droitsdel.legend_selectref_table'),
      showFilteredRowsInTitle: true,
      multiselection: false
    });

    table.width = 750;
    table.height = 250;
    return table;
  }
});
