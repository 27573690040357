import { i18n } from '../../../../i18n.js';
import { LOG } from '../../../../utils/log.js';
import { MSGS } from '../../../../utils/msgs.js';
import { ReadOnlyModel } from 'core/models/readOnly.model.js';
import { STR } from '../../../../utils/str.js';

export var ParametragebresultatsWorkflow = ReadOnlyModel.extend({

  initialize: function() {
    this.btnBarModel = null;
    this.setupState = undefined; // 0 --> setUp called, 1 --> setUp finished
    this.context = undefined;
  },

  _buttonAction: function(buttonId, profil) {
    LOG.debug("Button clicked in parametrage bresultats : " + buttonId);
    var value = this.parametrageResultats.model.get("value");
    var self = this;
    switch (buttonId) {
      case "save":
        var oldAttributes = !STR.isBlank(value.oldAttributes) ? JSON.parse(value.oldAttributes) : "";
        if (!value.isNew() && value.get("visibilite") == false && !STR.isBlank(oldAttributes) && oldAttributes.visibilite == true) {
          MSGS.showConfirm(i18n.t('messages:GT_1825'), function(result) {
            if (result == "Y") {
              self.parametrageResultats.saveParametrage();
            }
          });
        } else {
          this.parametrageResultats.saveParametrage();
        }
        break;
      case "revert":
        if (!STR.isBlank(this.formModel.formerID)) {
          value.formerID = null;
        }
        this.parametrageResultats.revertView();
        break;
      case "new":
        this.parametrageResultats.newParametrage();
        break;
      case "delete":
        var messageConfirm = i18n.t('common:delconfirm');
        MSGS.showConfirm(messageConfirm, function(result) {
          if (result == "Y") {
            self.parametrageResultats.deleteParametrage(null);
          }
        });

        break;
      case "copy":
        value.formerID = value.get("id") ? value.get("id") : null;
        value.set("id", null);
        value.set("codebrique", null);
        value.set("visibilite", false);
        this.parametrageResultats.model.trigger("change:value"); //Force map to form
        this.parametrageResultats.table.model.coll.reset();
        this.parametrageResultats._manageActiverButtons();
        this.btnBarModel.trigger("disable:activer");
        this.btnBarModel.trigger("disable:desactiver");

        this.btnBarModel.set("mode", "E");

        break;
      case "activer":
        this.parametrageResultats.activerParametrage("activer");

        break;
      case "desactiver":
        this.parametrageResultats.activerParametrage("desactiver");
        break;
    }
  },

  _formEdited: function() {
    this.btnBarModel.set("mode", "E");
    this.parametrageResultats._manageActiverButtons();
    this.parametrageResultats._manageHabilitation();

    //		if(this.formModel.getHabContext().get("nat_gest") !== "A"){
    //			this.formModel.updateHabContext({fonc_cour :this.parametrageResultats.context.ctxHabilitation.HabilitationGestion, nat_gest : "M"});
    //		}
  }
});